import gql from 'graphql-tag';

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($name: String!) {
    createAccount(input: { name: $name }) {
      id
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($id: ID!, $name: String!) {
    updateAccount(input: { id: $id, name: $name }) {
      id
      name
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount(
    $id: ID!
    $currentPassword: String!
    $captcha: String!
  ) {
    deleteAccount(
      input: { id: $id, currentPassword: $currentPassword, captcha: $captcha }
    ) {
      id
    }
  }
`;
