import { events, icon, text, title } from './defs/common';
import { UI } from '../_helpers';

const {
  properties: { oncomplete, onerror, onready, onstart },
} = events;

const jobTypes = {
  FOREGROUND: 'foreground',
  SIRI_SHORTCUT: 'siri_shortcut',
};

const commonJobProperties = {
  id: { pattern: '^[a-zA-Z0-9]+[-a-zA-Z0-9]*$' },
  type: {
    default: jobTypes.MOBILE_APP,
    enum: [jobTypes.FOREGROUND, jobTypes.SIRI_SHORTCUT],
  },
  title,
  description: text,
};

export const parameter = {
  title: 'Parameter',
  default: {},
  required: ['id', 'type'],
  properties: {
    id: { pattern: '^[a-zA-Z0-9]+[-a-zA-Z0-9]*$' },
    type: {
      oneOf: [{ enum: ['boolean', 'number', 'text'] }, { type: 'string' }],
    },
    source: { type: 'string' },
    dialogs: {
      title: 'Parameter dialogs',
      description: 'Dialogs',
      type: 'object',
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      required: [
        'configuration',
        'disambiguation_introduction',
        'disambiguation_selection',
        'confirmation',
      ],
      properties: {
        configuration: text,
        disambiguation_introduction: text,
        disambiguation_selection: text,
        confirmation: text,
      },
    },
  },
};

export const parameters = {
  title: 'Parameters',
  default: [],
  type: 'array',
  items: { ...parameter },
  additionalProperties: false,
  removeAdditional: true,
};

export const typeProperty = {
  title: 'Type property',
  description: 'Property',
  default: {},
  required: ['id', 'type', 'title'],
  properties: {
    id: { pattern: '^[a-zA-Z0-9]+[-a-zA-Z0-9]*$' },
    type: { enum: ['boolean', 'text'] },
    title: { type: 'string' },
  },
};

export const types = {
  title: 'Types',
  description: 'Intent types',
  default: [],
  type: 'array',
  items: { ...typeProperty },
  additionalProperties: false,
  removeAdditional: true,
};

export const jobs = {
  title: 'Job',
  description: 'Each job of the app',
  default: { id: 'job' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'type'],
  properties: {
    ...commonJobProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [jobTypes.FOREGROUND]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonJobProperties,
        events: { ...events, properties: { oncomplete, onerror, onstart } },
        icon,
        location_updates: {
          title: 'LocationUpdates',
          type: 'object',
          default: {},
          required: ['interval', 'onchanged'],
          additionalProperties: false,
          removeAdditional: true,
          properties: {
            interval: { default: 10, type: 'number' },
            max_updates: { default: 100, type: 'number' },
            min_distance: { default: 1, type: 'number' },
            priority: {
              enum: [
                'high_accuracy',
                'medium_accuracy',
                'low_power',
                'no_power',
              ],
              default: 'high_accuracy',
              ui: UI.DROPDOWN,
            },
            onchanged: { default: [] },
          },
        },
      },
    },
    [jobTypes.SIRI_SHORTCUT]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonJobProperties,
        parameters,
        events: { ...events, properties: { onerror, onready } },
        types,
      },
    },
  },
};

export default jobs;
