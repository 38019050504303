// @flow
import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';

function ColorPicker({ className, value, onChange, onBlur }) {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(value);
  const element = useRef();
  const swatch = useRef();

  return (
    <div
      ref={element}
      className={`color-picker${className ? ` ${className}` : ''}`}
    >
      <div
        ref={swatch}
        className="color-picker-swatch"
        style={{ backgroundColor: color, color }}
        onClick={() => setShow(!show)}
      />
      {show && (
        <>
          <ChromePicker
            className="color-picker-picker"
            color={color}
            onChange={(col) => {
              const { hex, rgb } = col;
              const { r, g, b, a } = rgb;
              const color = a === 1 ? hex : `rgba(${r}, ${g}, ${b}, ${a})`;
              swatch.current.style.backgroundColor = color;
              setColor(color);
              onChange(element.current, color);
            }}
          />
          <div
            className="color-picker-bg"
            onClick={() => {
              onBlur(color);
              setShow(!show);
            }}
          />
        </>
      )}
    </div>
  );
}

export default ColorPicker;
export { ColorPicker };
