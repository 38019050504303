import { useState } from 'react';

export function useClasses(componentId) {
  const [classes, setClasses] = useState({});

  const addClass = (newClasses) => {
    setClasses((classes) => ({ ...classes, ...newClasses }));
  };

  const validStyles = Object.values(classes)
    .filter((style) => style)
    .reduce((styles, style) => ({ ...styles, ...style }), {});
  const style = Object.entries(validStyles)
    .map(
      ([key, value]) =>
        `.preview [data-component-id="${componentId}"].${key.replace(
          ' ',
          '.'
        )},.preview [data-component-id="${componentId}"] .${key.replace(
          ' ',
          '.'
        )}{${value}}`
    )
    .join('');

  return { addClass, style };
}
export default useClasses;
