import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import { ViewerContext } from '../../context';
import { PlaceholderImage, PlaceholderText } from '../../components';
import { scrollToInvalid } from '../../_helpers';
import cardIcons from '../../assets/svg/payment';

const Placeholder = () => {
  return (
    <Card className="d-checkout-none d-inline-block bg-light">
      <Card.Body>
        <Row>
          <Col className="flex-grow-0">
            <PlaceholderImage
              alt="source-type"
              className="text-secondary rounded"
              style={{ width: '64px' }}
            />
          </Col>
          <Col className="text-left">
            <span className="text-uppercase">
              <PlaceholderText
                className="mb-2"
                style={{ width: '120px', opacity: 1 }}
              />
              <span className="d-block mb-2 font-weight-light">
                <PlaceholderText style={{ width: '200px' }} />
              </span>
              <h4 className="d-block mt-3">
                <PlaceholderText
                  size="lg"
                  style={{ width: '100px', opacity: 1 }}
                />
              </h4>
              <span className="text-muted font-weight-normal">
                <PlaceholderText size="sm" style={{ width: '80px' }} />
              </span>
            </span>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="bg-transparent border-top-0 text-end">
        <Button color="link" disabled className="text-uppercase">
          <PlaceholderText
            size="sm"
            className="bg-danger"
            style={{ width: '70px' }}
          />
        </Button>
        <Button color="link" disabled className="text-uppercase">
          <PlaceholderText
            size="sm"
            className="bg-primary"
            style={{ width: '95px' }}
          />
        </Button>
      </Card.Footer>
    </Card>
  );
};

const Empty = () => {
  const { t } = useTranslation();
  return (
    <Card className="d-checkout-none d-inline-block bg-light">
      <div className="position-absolute-top-right-0 position-absolute-bottom-left-0 d-flex justify-content-center align-items-center z-index-2">
        <h6>{t('cards.no_cards')}</h6>
      </div>
      <Card.Body className="opacity-0_2">
        <Row>
          <Col className="flex-grow-0">
            <PlaceholderImage
              alt="source-type"
              className="text-secondary rounded"
              style={{ width: '64px' }}
            />
          </Col>
          <Col className="text-left">
            <span className="text-uppercase">
              <PlaceholderText
                className="mb-2"
                style={{ width: '120px', opacity: 1 }}
              />
              <span className="d-block mb-2 font-weight-light">
                <PlaceholderText style={{ width: '200px' }} />
              </span>
              <h4 className="d-block mt-3">
                <PlaceholderText
                  size="lg"
                  style={{ width: '100px', opacity: 1 }}
                />
              </h4>
              <span className="text-muted font-weight-normal">
                <PlaceholderText size="sm" style={{ width: '80px' }} />
              </span>
            </span>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="bg-transparent border-top-0 text-end opacity-0_2">
        <Button color="link" disabled className="text-uppercase">
          <PlaceholderText
            size="sm"
            className="bg-danger"
            style={{ width: '80px' }}
          />
        </Button>
        <Button color="link" disabled className="text-uppercase">
          <PlaceholderText
            size="sm"
            className="bg-primary"
            style={{ width: '100px' }}
          />
        </Button>
      </Card.Footer>
    </Card>
  );
};

const Sources = () => {
  const { t } = useTranslation();
  let subscriptions = {};
  const { account } = useContext(ViewerContext);
  const { loading, sources = [] } = account;
  const { latest: latestSubscription } = subscriptions;
  const stripeForm = document.forms.StripeForm;
  const Card0 = stripeForm && stripeForm.Card0;

  if (!sources.length) {
    return <Empty />;
  }

  const handleChange = (source) => {
    const stripeForm = document.forms.StripeForm;

    //dispatch(sourcesActions.setDefault(source)).then(() => onChangeDefault());

    stripeForm && (stripeForm.Card0.checked = false);
  };

  const handleDelete = () => {};

  return sources.map((source) => (
    <Card key={source.id} className="d-inline-block">
      <Card.Body>
        <div className="custom-control custom-radio">
          <Input
            id={`Source${source.id}`}
            name="paymentMethod"
            type="radio"
            className="d-none custom-control-input"
            checked={source.default && (!Card0 || !Card0.checked) ? true : ''}
            onChange={() => handleChange(source)}
            disabled={!!loading}
            required
          />
          <label
            htmlFor={`Source${source.id}`}
            className="d-none custom-control-label"
          />
          <Row className="h6 small">
            <Col className="flex-grow-0">
              <img
                alt="source-type"
                src={
                  cardIcons[
                    source.specific.brand.toLowerCase().replace(/ /g, '')
                  ]
                }
                style={{ width: '64px' }}
              />
              {source.default && (
                <Badge
                  color="success"
                  className="text-uppercase d-checkout-none mt-2 p-2"
                >
                  {t('common.default')}
                </Badge>
              )}
            </Col>
            <Col className="text-left">
              <span className="text-content text-uppercase">
                <span className="d-block mb-2">
                  {source.specific.brand} [{source.country}]
                </span>
                <span className="d-block mb-2 font-weight-light">
                  {source.owner_name}
                </span>
                <h4 className="d-block mt-3">· · · · {source.last4}</h4>
                <span className="text-muted font-weight-normal">
                  EXP: {source.specific.exp_month}/{source.specific.exp_year}
                </span>
              </span>
            </Col>
          </Row>
        </div>
      </Card.Body>
      <Card.Footer className="d-checkout-none d-flex justify-content-end bg-transparent border-top-0">
        {(!source.default || !latestSubscription.active) && (
          <Modal
            button={t('common.delete')}
            buttonParameters={{
              color: 'link',
              className: 'text-uppercase text-danger',
            }}
          >
            <Button color="danger" onClick={() => handleDelete(source)}>
              {t('cards.delete_card')}
            </Button>
          </Modal>
        )}
        {!source.default && (
          <Button
            color="link"
            className="text-uppercase"
            onClick={() => handleChange(source)}
          >
            {t('cards.set_default')}
          </Button>
        )}
      </Card.Footer>
    </Card>
  ));
};

export const SourcesForm = (props) => {
  let data;

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.reportValidity() === false) {
      event.stopPropagation();
      scrollToInvalid(form);
      return;
    }
    form.classList.add('was-validated');
  };

  return (
    <Form id="SourcesForm" onSubmit={handleSubmit}>
      <div className="source-list">
        {(!data && <Placeholder />) || <Sources />}
      </div>
    </Form>
  );
};

export default SourcesForm;
