import React from 'react';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import Component from './Component';
import Image from './Image';
import Text from './Text';

export const CarouselCompiler = ({ slides = [], ...props }) => {
  const { height, id } = props;
  return (
    <Carousel {...props} component={BootstrapCarousel}>
      {slides.map((slide, index) => {
        const { image, subtitle, title } = slide;
        return (
          <Carousel.Item key={`${id}-slide-${index}`}>
            {image && (
              <Image
                id={`${id}-slide-${index}`}
                width="100%"
                height={height}
                scale="crop"
                file={image}
              />
            )}
            {(title || subtitle) && (
              <Carousel.Caption>
                {title && <Text text={title} style="title3" />}
                {subtitle && <Text text={subtitle} style="paragraph" />}
              </Carousel.Caption>
            )}
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export const Carousel = ({ children, ...props }) => {
  return (
    <Component {...props} component={BootstrapCarousel}>
      {children}
    </Component>
  );
};

Carousel.Caption = BootstrapCarousel.Caption;
Carousel.Item = BootstrapCarousel.Item;

Carousel.displayName = 'Carousel';
CarouselCompiler.displayName = 'Carousel';
export default CarouselCompiler;
