// @flow
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Icon from '@mdi/react';
import {
  mdiAirplane,
  mdiAlienOutline,
  mdiBarley,
  mdiBasketball,
  mdiBrain,
  mdiBullhornOutline,
  mdiCactus,
  mdiFlask,
  mdiHeartOutline,
  mdiHandPointingDown,
  mdiLightbulbOnOutline,
} from '@mdi/js';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { PAGES } from '../../../_config';
import { DoyoStoriesHeader } from '.';
import { Footer } from '../../../components';
import { waves } from '../../../assets/svg/figures';
import video1 from '../../../assets/video/video1.mp4';
import video2 from '../../../assets/video/video2.mp4';
import video3 from '../../../assets/video/video3.mp4';

function DoyoStoriesPage() {
  const { t } = useTranslation();
  const videos = [video1, video2, video3];
  const video = videos[parseInt(Math.random() * videos.length, 10)];

  const bannerIcons = [
    mdiAirplane,
    mdiAlienOutline,
    mdiBarley,
    mdiBasketball,
    mdiBrain,
    mdiBullhornOutline,
    mdiCactus,
    mdiFlask,
    mdiHeartOutline,
    mdiHandPointingDown,
    mdiLightbulbOnOutline,
  ];
  const bannerIcon =
    bannerIcons[parseInt(Math.random() * bannerIcons.length, 10)];

  useEffect(() => {
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <main className="home stories">
      <Helmet
        title={t('landings.stories.seo.title')}
        meta={[
          {
            name: 'description',
            content: t('landings.stories.seo.description'),
          },
        ]}
      />
      <DoyoStoriesHeader />
      <div className="position-relative">
        <video src={video} autoPlay loop muted />
        <Container>
          <Row className="align-items-center justify-content-center text-center">
            <Col lg={{ span: 7 }} className="p-0">
              <h1 className="display-4 text-white font-size-48--md-down font-weight-bold">
                <Icon path={bannerIcon} />
                <br />
                {t('landings.stories.banner.title')}
              </h1>
              <p className="text-white">
                {t('landings.stories.banner.subtitle')}
              </p>
              <Link to={PAGES.PRICING} className="nav-link text-white">
                <Button color="white" size="lg" className="card-link">
                  {t('landings.stories.banner.button')}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="waves" style={{ backgroundImage: `url(${waves})` }} />
      </div>
      <Footer />
    </main>
  );
}

export default DoyoStoriesPage;
export { DoyoStoriesPage };
