import React from 'react';
import Component from './Component';
import { getRgba } from '../../../_helpers';
import { useColor } from '../../hooks';

export const SwitchCompiler = (props) => {
  let { color_off: colorOff = '#ffffff', color_on: colorOn = '@color.accent' } =
    props;
  const { hex: _colorOff = '' } = useColor(colorOff);
  const { hex: _colorOn = '' } = useColor(colorOn);

  if (colorOff.includes('@')) {
    colorOff = _colorOff;
  }
  if (colorOn.includes('@')) {
    colorOn = _colorOn;
  }

  colorOff = getRgba(colorOff);
  colorOn = getRgba(colorOn);
  return <Switch {...props} colorOff={colorOff} colorOn={colorOn} />;
};

export const Switch = ({ className = '', colorOff, colorOn, ...props }) => {
  const { id } = props;
  return (
    <Component {...props} className={`switch ${className}`.trim()}>
      <>
        <style>{`
            #${id}Switch + label:before {
                background-color: ${colorOff} !important;
            }
            #${id}Switch + label:after {
                background-color: ${colorOff} !important;
            }
            #${id}Switch:checked + label:before {
                background-color: ${colorOn} !important;
            }
            #${id}Switch:checked + label:after {
                background-color: ${colorOn} !important;
            }
        `}</style>
        <div>
          <input id={`${id}Switch`} type="checkbox" />
          <label htmlFor={`${id}Switch`} />
        </div>
      </>
    </Component>
  );
};

export default SwitchCompiler;
