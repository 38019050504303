import React from 'react';
import ReactIcon from '@mdi/react';
import { mdiArrowLeft, mdiMenu } from '@mdi/js';
import Component from '../components/Component';
import useColor from '../../hooks/useColor';
import useMenu from '../../hooks/useMenu';
import Image from '../components/Image';
import Text from '../components/Text';

export const ActionBar = (props) => {
  let {
    back_button: backButton,
    background_color: backgroundColor = '@color.primary',
    color = '@color.textOnPrimary',
    hasDrawer = false,
    menu,
    title: actionBarTitle,
    toggleDrawer = () => {},
    viewTitle,
  } = props;
  const { key, hex = '' } = useColor(color);
  const { items = [] } = useMenu(menu);

  const background = { type: 'solid', color: backgroundColor };
  const text = viewTitle || actionBarTitle;
  const textAttributes = { color, text, font_size: 14 };

  return (
    <Component
      id="ActionBar"
      className="action-bar d-flex align-items-center"
      background={background}
      width="100%"
      height={45}
      padding={{ start: 12, end: 12 }}
    >
      {(hasDrawer && (
        <ReactIcon
          path={mdiMenu}
          className="icon"
          style={{ color: hex }}
          onClick={toggleDrawer}
          size={1}
        />
      )) ||
        (backButton && (
          <ReactIcon
            path={mdiArrowLeft}
            className="icon"
            style={{ color: hex }}
            size={1}
          />
        ))}
      <Text
        {...textAttributes}
        id="ActionBarTitle"
        className="fw-bold"
        font_size={16}
        color={color}
        width="fill"
        padding={{ start: 8, end: 8 }}
      />
      <div className="d-flex justify-content-end">
        {(items.length &&
          items.map(({ icon }, i) => (
            <Image
              id={`ActionBarIcon${i}`}
              key={`actionbar-menu-${i}`}
              file={icon}
              width="wrap"
              height={24}
            />
          ))) ||
          null}
      </div>
    </Component>
  );
};

export default ActionBar;
