import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import Draggable from 'react-draggable';
import Icon from '@mdi/react';
import { mdiClose, mdiResizeBottomRight } from '@mdi/js';
import { useViewer } from '../hooks';

const ResizeButton = ({ draggableRef, scale = true, setModalPosition }) => {
  const startResizing = (event) => {
    event.preventDefault();

    const modal = draggableRef.current;
    const modalBody = modal.querySelector('.ant-modal-body');
    const dragDomRect = modalBody.getBoundingClientRect();
    const { width, height } = dragDomRect;
    const left = event.clientX;
    const top = event.clientY;

    let transform = modalBody.style.transform;
    if (!transform.match(/scale\(.*\)/)) {
      transform = `${transform} scale(1)`.trim();
    }
    let [, prevIndex] = transform.match(/scale\((.*)\)/);
    prevIndex = parseFloat(prevIndex);
    let indexRef = prevIndex;

    document.onmousemove = (event) => {
      event.preventDefault();
      let w = Math.min(width + (event.clientX - left), window.innerWidth);
      let h = Math.min(height + (event.clientY - top) + 75, window.innerHeight);
      if (scale) {
        let distance = event.clientX - left;
        let index = Math.max(
          parseFloat((((width + distance) * prevIndex) / width).toFixed(2)),
          0.5
        );
        w = (width * index) / prevIndex;
        h = (height * index) / prevIndex + 75;

        if (w > window.innerWidth || h > window.innerHeight) {
          index = indexRef;
          w = (width * index) / prevIndex;
          h = (height * index) / prevIndex + 75;
        }

        indexRef = index;

        modalBody.style.transform = transform.replace(
          /scale\(.*\)/,
          `scale(${index})`
        );
      }
      modal.style.width = `${w}px`;
      modal.style.height = `${h}px`;
      setModalPosition();
    };

    document.onmouseup = (event) => {
      document.onmousemove = null;
    };
  };

  return (
    <span className="resize-button" onMouseDown={startResizing}>
      <Icon path={mdiResizeBottomRight} size={1} />
    </span>
  );
};

const DraggableModal = ({
  children,
  draggable: draggableProps,
  onCancel,
  title,
  wrapClassName,
  dataset,
  scale,
  visible: visibleProp,
  ...props
}) => {
  const { defaultPosition = {} } = draggableProps;
  const { preview } = useViewer();
  const [state, setState] = useState({
    visible: true,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    position: { ...defaultPosition },
    ...draggableProps,
  });
  const { visible, disabled, bounds } = state;
  const draggableRef = useRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setState((state) => ({
      ...state,
      bounds: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      },
    }));
  };

  const onDrag = (event, uiData) => {
    const { x, y } = uiData;
    setState((state) => ({ ...state, position: { x, y } }));
  };

  const setModalPosition = () => {
    let {
      left: x,
      top: y,
      width,
      height,
    } = draggableRef.current.getBoundingClientRect();
    x = Math.max(x, 0);
    x = Math.min(x, window.innerWidth - width);
    y = Math.max(y, 0);
    y = Math.min(y, window.innerHeight - height);
    setState((state) => ({ ...state, position: { x, y } }));
  };

  useEffect(() => {
    window.addEventListener('resize', setModalPosition);
    return () => {
      window.removeEventListener('resize', setModalPosition);
    };
  }, []);

  useEffect(() => {
    setModalPosition();
  }, [preview]);

  useEffect(() => {
    setState((state) => ({ ...state, visible: visibleProp }));
  }, [visibleProp]);

  return (
    <Modal
      {...props}
      open={visible}
      wrapClassName={`draggable-modal ${wrapClassName}`}
      closeIcon={<Icon path={mdiClose} size={1} />}
      title={
        <div
          onMouseOver={() =>
            disabled &&
            setState((state) => ({
              ...state,
              disabled: false,
            }))
          }
          onMouseOut={() =>
            setState((state) => ({
              ...state,
              disabled: true,
            }))
          }
        >
          {title}
        </div>
      }
      onCancel={() => {
        setState((state) => ({ ...state, visible: false }));
        onCancel?.();
      }}
      footer={
        <ResizeButton
          draggableRef={draggableRef}
          scale={scale}
          setModalPosition={setModalPosition}
        />
      }
      modalRender={(modal) => (
        <Draggable
          {...state}
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          onDrag={(event, uiData) => onDrag(event, uiData)}
        >
          <div {...dataset} ref={draggableRef}>
            {modal}
          </div>
        </Draggable>
      )}
    >
      {children}
    </Modal>
  );
};

export default DraggableModal;
export { DraggableModal };
