import React, { useCallback, useEffect, useRef } from 'react';
import { autocompleteBlacklist, moveCursorToEnd, UI } from '../_helpers';
import Icons from '../_helpers/icons.json';
import { getRefs } from '../schemas';

const renderSuggestions = (input, { props, value, highlighted, ui }) => {
  const { _key: key, options = [], getAutocompleteRefs = () => {} } = props;
  let optionsToShow = options;
  let innerRef = `${input.innerText.split('(@').pop()}`;
  innerRef = innerRef[0] === '@' ? innerRef : `@${innerRef}`;
  if (input.innerText.match(/(^@|\(@)/)) {
    optionsToShow = [...new Set([...options, ...getAutocompleteRefs()])].filter(
      (ref) => {
        return (
          (innerRef.split('.').length > 1 &&
            ['@assets'].includes(innerRef.split('.')[0])) ||
          ref.split('.').length === innerRef.split('.').length
        );
      }
    );
  }
  let autocomplete = input.nextSibling;
  if (!autocomplete) {
    autocomplete = document.createElement('div');
    autocomplete.className = 'autocomplete';
    input.parentNode.appendChild(autocomplete);
  }

  autocomplete.firstChild && autocomplete.firstChild.remove();
  const suggestions = document.createElement('ul');
  suggestions.className = 'autocomplete-suggestions shadow-sm';
  autocomplete.appendChild(suggestions);
  if (key === 'icon' && input.innerText.length > 0) {
    optionsToShow = [
      ...optionsToShow,
      ...Icons.filter((icon) => icon.indexOf(input.innerText) === 0).map(
        (str) => `<i class="mdi mdi-${str}"></i><span>${str}</span>`
      ),
    ];
  }
  optionsToShow
    .sort((a, b) => (a < b ? -1 : 1))
    .filter((option) => {
      if (autocompleteBlacklist.includes(option)) {
        return false;
      }
      if (ui === UI.DROPDOWN) {
        return true;
      }
      return input.innerText.match(/(^@|\(@)/) !== null
        ? option.includes(innerRef)
        : !value || option.includes(value);
    })
    .forEach((option) => {
      const opt = document.createElement('li');
      opt.className = `suggestions-option${
        option === highlighted ? ' highlighted' : ''
      }`;
      if (input.innerText.match(/(^@|\(@)/) !== null) {
        let items = option.split('.');
        let lastItem = items.pop();
        if (['@assets'].includes(innerRef.split('.')[0])) {
          items = ['@assets'];
          lastItem = option.replace('@assets.', '');
        }
        opt.innerHTML = `<span class="visually-hidden">${
          items.length ? `${items.join('.')}.` : ''
        }</span><span>${lastItem}</span>`;
      } else {
        opt.innerHTML = option;
      }

      opt.onmouseenter = (event) => {
        const highlighted =
          autocomplete && autocomplete.querySelector('.highlighted');
        highlighted && highlighted.classList.remove('highlighted');
        event.currentTarget.classList.add('highlighted');
      };
      opt.onmouseleave = (event) => {
        event.currentTarget.classList.remove('highlighted');
      };
      opt.onmousedown = (event) => {
        if (value.match(/(^@|\(@)/) === null) {
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        let { currentTarget } = event;
        const highlighted = currentTarget.innerText;
        setInnerText(input, { ...props, highlighted });
        renderSuggestions(input, { props, value, highlighted, ui });
        moveCursorToEnd(input);
      };
      suggestions.appendChild(opt);
    });
  if (!suggestions.querySelector('.highlighted')) {
    suggestions.children[0]?.classList.add('highlighted');
  }

  setAutocompleteStyle();
};

const handleFocus = (event, props) => {
  const { onFocus = () => {}, ui } = props;
  const { currentTarget } = event;
  const { innerText: value } = currentTarget;
  event.currentTarget._innerText = value;
  if (onFocus(event) === false) {
    return;
  }
  renderSuggestions(currentTarget, {
    props,
    value,
    highlighted: value,
    ui,
  });
};

const handleKeyDown = (event, props) => {
  const { currentTarget, key, shiftKey } = event;
  let { innerText: value, nextSibling: autocomplete } = currentTarget;
  const { onKeyDown = () => {}, ui } = props;
  event.stopPropagation();

  onKeyDown(event);

  if (key === 'Escape') {
    autocomplete.remove();
    return;
  }

  if (key === 'Enter') {
    event.preventDefault();
    setInnerText(currentTarget, { ...props });
    value = currentTarget.innerText;
    if (value.match(/(^@|\(@)/) === null) {
      currentTarget.blur();
      return;
    }

    moveCursorToEnd(currentTarget);
  }

  if (value && !isNaN(value)) {
    const _value = parseInt(value, 10);
    if (key === 'ArrowUp') {
      currentTarget.innerText = _value + (shiftKey ? 10 : 1);
    } else if (key === 'ArrowDown') {
      currentTarget.innerText = _value - (shiftKey ? 10 : 1);
    }
  }

  let highlighted = autocomplete?.querySelector('.highlighted');
  if (
    autocomplete.querySelector('li') &&
    (key === 'ArrowUp' || key === 'ArrowDown')
  ) {
    event.preventDefault();
    highlighted = highlighted || {};
    const { previousSibling, nextSibling } = highlighted;
    highlighted.classList && highlighted.classList.remove('highlighted');
    highlighted =
      key === 'ArrowUp'
        ? previousSibling ||
          autocomplete.querySelector('.suggestions-option:last-child')
        : nextSibling ||
          autocomplete.querySelector('.suggestions-option:first-child');

    highlighted.classList.add('highlighted');
    highlighted.scrollIntoView({
      block: 'nearest',
    });
    return;
  }

  highlighted =
    highlighted ||
    autocomplete.querySelector('.suggestions-option:first-child');
  renderSuggestions(currentTarget, {
    props,
    value,
    highlighted: highlighted ? highlighted.innerText : null,
    ui,
  });
};

const handleKeyUp = (event, props) => {
  const { currentTarget, key } = event;
  const { innerText: value } = currentTarget;
  const { onKeyUp = () => {}, options, ui } = props;

  onKeyUp(event);

  if (key === 'Escape' || key.match(/Arrow/) !== null) {
    return;
  }
  const { nextSibling: autocomplete } = currentTarget;
  let highlighted = autocomplete?.querySelector('.highlighted') || {};
  highlighted = highlighted.innerText || options[0];

  renderSuggestions(currentTarget, {
    props,
    value,
    highlighted,
    ui,
  });
};

const handlePaste = (event, props) => {
  event.preventDefault();
  const selection = window.getSelection();
  const { anchorOffset, focusOffset } = selection;
  const textToPaste = event.clipboardData.getData('text');
  let text = event.currentTarget.innerText;
  text = `${text.substring(0, anchorOffset)}${textToPaste}${text.substring(
    focusOffset,
    text.length
  )}`;
  event.currentTarget.innerText = text;
};

const setInnerText = (currentTarget, props) => {
  const { innerText: value, nextSibling: autocomplete } = currentTarget;
  let { getAutocompleteRefs = () => [], highlighted } = props;
  highlighted =
    highlighted || autocomplete?.querySelector('.highlighted')?.innerText;
  let preValue = value.split('@').length > 1 ? value : '';
  let postValue = highlighted || value;
  if (value.match(/(^@|\(@)/) !== null) {
    preValue = preValue.split('@');
    preValue.pop();
    preValue = preValue.join('@');
    postValue =
      highlighted?.replace(/[ \n]/g, '') || value.replace(preValue, '');
  }

  let newInnerText = `${preValue}${postValue}`;

  const refs = getAutocompleteRefs().filter(
    (ref) => ref.indexOf(postValue) === 0
  );

  if (newInnerText) {
    if (refs.some((ref) => ref.indexOf(`${postValue}`) === 0)) {
      newInnerText = `${newInnerText}`;
    }
    currentTarget.innerText = newInnerText;
  }
};

const handleBlur = (event, props) => {
  const { currentTarget } = event;
  const { nextSibling: autocomplete } = currentTarget;
  const { onBlur = () => {} } = props;

  const highlighted = autocomplete?.querySelector('.highlighted')?.innerText;
  if (highlighted) {
    setInnerText(currentTarget, { ...props, highlighted });
  }
  window.getSelection?.().removeAllRanges?.();
  autocomplete && autocomplete.remove();
  onBlur(event);
};

const setAutocompleteStyle = (repete = 0) => {
  const contentEditable = document.querySelector('[contentEditable]:focus');
  if (!contentEditable) {
    return;
  }
  const wrapper = contentEditable.parentNode;
  const autocomplete = contentEditable.nextSibling;
  if (!autocomplete) {
    return;
  }
  const content = document.querySelector('.ant-layout-content');
  const suggestions = autocomplete.firstElementChild;
  const contentRect = content.getBoundingClientRect();
  const { bottom: jBottom } = contentRect;
  const {
    bottom: cBottom,
    height: cHeight,
    width: cWidth,
  } = contentEditable.getBoundingClientRect();
  const { height: sHeight, width: sWidth } = suggestions
    ? suggestions.getBoundingClientRect()
    : {};
  let changes = {};
  if (wrapper.dataset.ui !== UI.DROPDOWN) {
    if (suggestions && cBottom + sHeight > jBottom) {
      changes = {
        ...changes,
        bottom: `${cHeight}px`,
        position: 'absolute',
      };
    } else {
      changes = {
        ...changes,
        bottom: '',
        position: '',
      };
    }
    changes = {
      ...changes,
      transform: `translate(${Math.max(
        0,
        parseInt(cWidth - sWidth, 10)
      )}px, 0)`,
    };
  }
  Object.keys(changes).forEach(
    (key) => (autocomplete.style[key] = changes[key])
  );
};

const handleDocumentScroll = (event) => setAutocompleteStyle();

export const Autocomplete = (props) => {
  const {
    className = '',
    getProjectRefs = () => [],
    options = [],
    readOnly = false,
    projectType,
    ui = UI.AUTOCOMPLETE,
    value = '',
  } = props;
  const element = useRef();
  const getAutocompleteRefs = useCallback(() => {
    let projectRefs = getProjectRefs({ needle: element.current.innerText });
    projectRefs = projectRefs.reduce((refs, ref) => {
      let refAux = ref;
      const _refs = [];
      while (refAux) {
        _refs.push(refAux);
        refAux = refAux.split('.');
        refAux.pop();
        refAux = refAux.join('.');
      }
      return [...refs, ..._refs.filter((ref) => !refs.includes(ref))];
    }, projectRefs);
    let refOptions = getRefs({ type: projectType }).map((ref) => `@${ref}`);
    refOptions = [
      ...refOptions,
      ...projectRefs.filter((ref) => !refOptions.includes(ref)),
    ];
    return refOptions;
  }, [getProjectRefs]);
  props = { ...props, getAutocompleteRefs };

  useEffect(() => {
    element.current.blur();
    const autoFocus = Object.keys(props).includes('autoFocus');
    if (
      autoFocus &&
      element.current &&
      !element.current.innerText &&
      !element.current.parentElement?.parentElement?.classList.contains(
        'json-node-new-item'
      ) &&
      !document.querySelector(':focus')
    ) {
      element.current.focus();
    }
    const content = document.querySelector('.ant-layout-content');
    content.addEventListener('scroll', handleDocumentScroll, false);
    return () => {
      document.removeEventListener('scroll', handleDocumentScroll, false);
    };
  }, [options]);

  return (
    <div
      className={`autocomplete-wrapper${className ? ` ${className}` : ''}`}
      data-ui={ui}
    >
      <div
        contentEditable={!readOnly}
        suppressContentEditableWarning={true}
        ref={element}
        onFocus={(event) => handleFocus(event, props)}
        onKeyDown={(event) => handleKeyDown(event, props)}
        onKeyUp={(event) => handleKeyUp(event, props)}
        onPaste={(event) => handlePaste(event, props)}
        onBlur={(event) => handleBlur(event, props)}
      >
        {ui === UI.PASSWORD ? '******' : `${value}`}
      </div>
    </div>
  );
};

export default Autocomplete;
