import { projectTypes } from './project';

export const allRefs = {
  [projectTypes.BACKEND]: {},
  [projectTypes.FLOW]: {
    app: {
      date: true,
      time: true,
      timestamp: true,
    },
    firebase: {
      firestore: true,
    },
    window: {
      document: {
        referrer: true,
      },
      location: {
        hash: true,
        host: true,
        hostname: true,
        href: true,
        origin: true,
        pathname: true,
      },
      navigator: {
        language: true,
        onLine: true,
        platform: true,
        userAgent: true,
      },
    },
    cookie: true,
    property: true,
    step: {
      id: true,
      title: true,
    },
    flow: {
      id: true,
      title: true,
    },
  },
  [projectTypes.MOBILE_APP]: {
    api: {
      stripe: {
        cart: true,
        checkout: true,
        products: true,
      },
    },
    app: {
      admob_device_id: true,
      gaid: true,
      lang: {
        iso: true,
        iso3: true,
        region: true,
      },
      location: {
        latitude: true,
        longitude: true,
      },
      time: true,
      timestamp: true,
      version: true,
    },
    assets: true,
    color: true,
    cookie: true,
    database: true,
    disk: true,
    element: true,
    firebase: {
      fcm: {
        token: true,
      },
      firestore: true,
      storage: true,
      user: {
        email: true,
        id: true,
        password: true,
      },
    },
    property: true,
    wrapper: {
      actionbar: {
        title: true,
      },
      drawer: {
        icon: true,
        subtitle: true,
        title: true,
      },
    },
  },
  [projectTypes.WEB]: {
    api: {
      stripe: {
        cart: true,
        checkout: true,
        products: true,
      },
    },
    app: {
      language: true,
      timestamp: true,
    },
    color: true,
    cookie: true,
    database: true,
    element: true,
    firebase: {
      firestore: true,
      storage: true,
      user: {
        displayName: true,
        email: true,
        emailVerified: true,
        photoURL: true,
        id: true,
      },
    },
    property: true,
    theme: true,
    window: {
      document: {
        referrer: true,
      },
      history: {
        length: true,
      },
      location: {
        hash: true,
        host: true,
        hostname: true,
        href: true,
        origin: true,
        pathname: true,
      },
      navigator: {
        language: true,
        online: true,
        platform: true,
        userAgent: true,
      },
    },
    wrapper: {
      actionbar: {
        title: true,
      },
      drawer: {
        icon: true,
        subtitle: true,
        title: true,
      },
    },
  },
};

export const getRefs = ({ refs = allRefs, type } = {}) => {
  refs = refs[type] || refs;
  return Object.keys(refs).reduce((result, ref) => {
    let childRefs = refs[ref];
    childRefs =
      childRefs === true
        ? [ref]
        : [
            ref,
            ...getRefs({ refs: childRefs }).map((ref2) => `${ref}.${ref2}`),
          ];
    return [...result, ...childRefs];
  }, []);
};

export default allRefs;
