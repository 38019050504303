import React, { createContext, useEffect, useRef } from 'react';
import { useImmerReducer } from 'use-immer';
import { RefsReducer, REFS } from './reducers';

// Custom imports:
import useDatabases from '../hooks/useDatabases';
import { useFirebase } from '../hooks';

export const refTypes = [
  '@database',
  '@firebase',
  '@cookie',
  '@element',
  '@property',
];
export const getKeys = (ref) => {
  if (!ref) {
    return [];
  }
  const refType =
    refTypes.find((rt) => {
      const regExp = new RegExp(`^${rt}`);
      return `${ref}`.match(regExp);
    }) || '';
  const mainKey = refType.split('.').pop().replace('@', '');
  const keys = `${ref}`.replace(`${refType}.`, '').split('.');
  return [mainKey, ...keys];
};

export const RefsContext = createContext({});

export const RefsProvider = ({ refs: refsProp = {}, ...props }) => {
  const refsRef = useRef({});
  const [state, dispatch] = useImmerReducer(RefsReducer, REFS.INITIAL_STATE);
  // Custom consts:

  const { firebase = {} } = useFirebase();
  const { database = {} } = useDatabases();

  //Custom code:
  useEffect(() => {
    const refs = Object.entries(refsProp).reduce((refs, [key, value]) => {
      const [, refKey, rest] = key.match(/@([^.]+)\.(.+)/);
      const refValue = rest
        .split('.')
        .reverse()
        .reduce((value, key) => {
          return { [key]: value };
        }, value);
      return { ...refs, [refKey]: refValue };
    }, {});

    if (!refsRef.current._equals(refsProp)) {
      refsRef.current = refsProp;
      setRefs(refs);
    }
  }, [refsProp]);

  const setRefs = (refs) => {
    dispatch({ type: REFS.SET, payload: { refs } });
  };

  if (process.env.NODE_ENV === 'development') {
    console.log('REFS >>>', { ...state, firebase, database });
  }
  return (
    <RefsContext.Provider
      value={{
        ...state,
        firebase: {
          ...firebase,
          user: state.firebase?.user,
        },
        database,
        setRefs,
      }}
    >
      {props.children}
    </RefsContext.Provider>
  );
};

export const RefsConsumer = RefsContext.Consumer;
export default RefsContext;
