import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import ls from 'local-storage';
import { mobileCheck } from '../../_helpers';
import { getSchema } from '../../schemas';
import {
  Actions,
  DropWrapper,
  JsonEditorWrapper,
  RegisterModal,
  Sidebar,
  VerificationModal,
} from '../../components';
import { PreviewModal } from '../../preview';
import { useProjects, useSockets, useViewer } from '../../hooks';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

export const StylePage = ({ match }) => {
  const {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  } = match;
  const history = useHistory();
  const { applyJson, socket } = useSockets();
  const { mode } = useViewer();
  const {
    project = {},
    projects = [],
    getProjectRefs,
    selectProject = () => {},
    unselectProject = () => {},
    updateProject = () => {},
  } = useProjects();
  const { edges: allProjects = [] } = projects;
  let { data: projectData = {} } = project;
  let path = decodeURIComponent(`/${projectNamePath}/${fileNamePath}`);
  const filePath = fileNamePath.split('/');
  filePath.shift();
  let key = 'app';
  const { app = {} } = projectData;
  const { type: projectType } = app;
  let value = projectData[key] || {};
  let index;
  let data = { key, value, index };
  const schema = getSchema({
    path: 'style',
    projectType,
    value,
  });
  const { properties = {} } = schema;

  const handleJsonChange = useCallback(
    (json, options = {}) => {
      const { value } = json;
      let { data: projectData = {} } = project;
      const newProjectData = projectData._clone();
      newProjectData.app = Object.entries(newProjectData.app)
        .filter(([key, value]) => !Object.keys(properties).includes(key))
        .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
      newProjectData.app = { ...newProjectData.app, ...value };
      if (newProjectData._equals(projectData)) {
        return;
      }
      updateProject({ data: newProjectData });
      applyJson(projectData);
    },
    [key, index, history, project, socket, value]
  );

  useEffect(() => {
    const { node: projectToSelect } =
      allProjects.find(({ node: { data = {} } }) => {
        const { app = {} } = data;
        const { project_name: name } = app;
        return name === projectNamePath;
      }) || {};
    if (projectToSelect) {
      projectToSelect.id !== project.id && selectProject(projectToSelect);
    } else {
      unselectProject();
    }
  }, [projectNamePath, fileNamePath, allProjects]);

  // Momentaneo
  //------------
  const _value = value._clone();
  value = Object.entries(_value)
    .filter(([key, value]) =>
      ['type', 'colors', 'fonts', 'theme'].includes(key)
    )
    .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
  path = `${path}style`;
  data = { ...data, value };
  // -----------
  const user = ls('user') || {};
  const { _state = {} } = user;
  const currentPath = (
    Object.keys(_state).find((key) => _state[key].current) || ''
  )
    .split('/')
    .splice(2);
  const [currentKey, currentId] = currentPath;
  const { [currentKey]: currentKeyContent = [] } = projectData;
  const previewValue =
    currentKeyContent.find(({ id }) => id === currentId) ||
    currentKeyContent[0];

  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar match={match} path={path} />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ height: '40px' }}>
            <Actions data={data} />
          </Layout.Header>
          <Layout.Content id="WorkingArea">
            {project.id && key && value && (
              <>
                <PreviewModal
                  main={key}
                  currentId={previewValue?.id}
                  project={project}
                  value={previewValue}
                />
                <JsonEditorWrapper
                  mode={mode}
                  parent={{ path }}
                  data={data}
                  onChange={handleJsonChange}
                  getProjectRefs={getProjectRefs}
                  projectType={projectType}
                />
              </>
            )}
          </Layout.Content>
        </Layout>
      </Layout>

      <DropWrapper />
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default StylePage;
