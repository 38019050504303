import ls from 'local-storage';

const user = ls('user') || {};
const { _notifications: alerts = [] } = user;

export const ALERT = {
  INITIAL_STATE: { alerts, update: Date.now() },
  ERROR: 'ALERT_ERROR',
  INFO: 'ALERT_INFO',
  SET_VIEWED: 'SET_VIEWED',
  SUCCESS: 'ALERT_SUCCESS',
  UPDATE: 'ALERT_UPDATE',
};

export const AlertReducer = (draft, action) => {
  const { payload, type } = action;
  const user = ls('user') || {};

  switch (type) {
    case ALERT.ERROR:
    case ALERT.INFO:
    case ALERT.SUCCESS:
      draft.alerts = [
        ...draft.alerts,
        {
          ...payload,
          id: Date.now(),
        },
      ].sort((a, b) => (a.id > b.id ? -1 : 1));
      ls('user', { ...user, _notifications: draft.alerts });
      break;

    case ALERT.CLEAR:
      if (!payload.id) {
        draft.alerts = [];
      }
      draft.alerts = draft.alerts.filter(({ id }) => id !== payload.id);
      ls('user', { ...user, _notifications: draft.alerts });
      break;

    case ALERT.SET_VIEWED:
      draft.alerts = draft.alerts.map((alert) =>
        payload.alerts.some(({ id }) => id === alert.id)
          ? { ...alert, viewed: true }
          : alert
      );
      ls('user', { ...user, _notifications: draft.alerts });
      break;

    default:
      return draft;
  }
};
