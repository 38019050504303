import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Footer } from '../../components';
import { logoWhite } from '../../assets/svg/logos';
import { PAGES } from '../../_config';

export const TermsPage = () => {
  const { t } = useTranslation();
  const terms = t('terms.entries', { returnObjects: true });

  return (
    <main className="terms font-size-14">
      <header className="position-absolute-top-0 z-index-2">
        <Navbar expand="sm" className="flex-wrap">
          <Navbar.Toggle />
          <Navbar.Collapse navbar>
            <Nav>
              <Nav.Item>
                <Link to={PAGES.LEGAL_TERMS} className="nav-link">
                  {t('header.terms_and_conditions')}
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link to={PAGES.LEGAL_POLICIES} className="nav-link">
                  {t('header.privacy_policies')}
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <Link to="/" className="w-100 text-center">
            <img alt="logo Doyo" src={logoWhite} height="42px" />
          </Link>
        </Navbar>
      </header>
      <div className="position-relative bg-primary space-4-top space-3-bottom">
        <Container>
          <div className="w-md-80 w-lg-60 text-center mx-auto">
            <h1 className="text-white">{t('terms.title')}</h1>
            <p style={{ color: 'rgba(0, 0, 0, 0.5)' }}>{t('terms.subtitle')}</p>
          </div>
        </Container>
      </div>
      <Container>
        {terms.map((term, i) => (
          <div key={i}>
            <div className="space-2 active">
              <div className="mb-5">
                <h3 className="text-primary font-weight-bold">
                  {parse(term.title)}
                </h3>
              </div>
              <Row>
                <Col>{parse(term.text)}</Col>
                <Col
                  lg={{ span: 5 }}
                  xl={{ span: 4 }}
                  className="font-weight-bold mt-5 mt-lg-0"
                >
                  <h6>{t('terms.wich_means')}</h6>
                  <p className="text-primary">{parse(term.summary)}</p>
                </Col>
              </Row>
            </div>
            <hr className="my-0" />
          </div>
        ))}
      </Container>
      <Footer />
    </main>
  );
};

export default TermsPage;
