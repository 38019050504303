import { URL as _URL } from '../_config';
import { i18n } from '../i18n';
import ls from 'local-storage';
import dayjs from 'dayjs';
import { fileBlackList } from '.';
import mimeTypes from './mimeTypes.json';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const camelToKebabCase = (str) => {
  return str.replace(/([A-Z])/g, (upper) => `-${upper.toLowerCase()}`);
};
export const highlightComponent = (event, type) => {
  const className = type === 'check' ? 'active' : 'hover';
  const dataAttr = 'data-component-id';
  const datasetKey = 'componentId';
  let { currentTarget, target } = event;
  currentTarget = currentTarget || target;

  let componentId = currentTarget?.dataset?.componentId;
  let node = document.querySelector(
    `.node-item[data-component-id="${componentId}"] > .node-value > .node-type`
  );
  while (currentTarget && (!componentId || !node)) {
    currentTarget = currentTarget.parentElement;
    componentId = currentTarget?.dataset?.componentId;
    node = document.querySelector(
      `.node-item[data-component-id="${componentId}"] > .node-value > .node-type`
    );
  }

  document
    .querySelectorAll(`[data-component-id].${className}`)
    .forEach((el) => el.classList.remove(className));

  const getElements = (wrapper, { dataAttr, datasetKey }) => {
    if (!wrapper || !wrapper.dataset) {
      return [];
    }
    const datasetValue = wrapper.dataset[datasetKey];
    const elements = [
      ...(document.querySelectorAll(
        `.node-wrapper [${dataAttr}="${datasetValue}"][data-key="${currentTarget.dataset.key}"]`
      ) || []),
      ...(document.querySelectorAll(
        `.preview-wrapper [${dataAttr}="${datasetValue}"]`
      ) || []),
    ];
    return datasetValue && elements.length
      ? elements
      : getElements(wrapper.parentNode, { dataAttr, datasetKey });
  };

  const elements = getElements(currentTarget, { dataAttr, datasetKey });
  elements.forEach((el) => {
    el.classList.toggle(className, type === 'over');
    if (type === 'check') {
      el.classList.add('active');
      if (el !== currentTarget) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  });
};

export const highlightEvent = (event, type) => {
  const className = type === 'check' ? 'active' : 'hover';
  let { currentTarget, target } = event;
  currentTarget = currentTarget || target;

  let eventId = currentTarget?.dataset?.eventId;
  let node = document.querySelector(
    `.node-item[data-event-id="${eventId}"] > .node-value > .node-type`
  );
  while (currentTarget && (!eventId || !node)) {
    currentTarget = currentTarget.parentElement;
    eventId = currentTarget?.dataset?.eventId;
    node = document.querySelector(
      `.node-item[data-event-id="${eventId}"] > .node-value > .node-type`
    );
  }

  document
    .querySelectorAll(`[data-event-id].${className}`)
    .forEach((el) => el.classList.remove(className));
  currentTarget?.classList.toggle(className, type === 'over');
};

export const deleteFromLS = (project) => {
  const { name: projectName } = project;
  if (!projectName) {
    return;
  }
  const user = ls('user') || {};
  const { _state = {} } = user;
  const regExp = new RegExp(`^/${projectName}(/[^/]*){0,}$`);
  Object.keys(_state).forEach((path) => {
    if (regExp.test(path)) {
      delete _state[path];
    }
  });
  ls('user', { ...user, _state });
};

export const downloadBlob = (blob, name) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.setAttribute('download', name);
  a.click();
};

export const fromNow = (date) => {
  const lang = getLanguage();
  import(`dayjs/locale/${lang}.js`).then(() => {
    dayjs.locale(lang);
  });

  return dayjs(date) < dayjs().subtract(1, 'week')
    ? new Date(date).toLocaleDateString()
    : dayjs(date).fromNow();
};

export const getAvatar = (parameters = {}) => {
  const { hash, size = 200, d = 'retro' } = parameters;
  return `${_URL.GRAVATAR}${hash}?d=${d}&size=${size}`;
};

let prevColor = '';
let colors = {};
const getRandomColor = ({ dark, light }) => {
  let color = Math.floor(Math.random() * 16777215).toString(16);
  if (dark && !isDark(color)) {
    return getRandomColor({ dark });
  }
  if (light && isDark(color)) {
    return getRandomColor({ light });
  }
  return `#${color}`;
};
function hashCode(str) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '#' + '00000'.substring(0, 6 - c.length) + c;
}
export const getBorderColor = (key = 'content') => {
  const hash = hashCode(key);
  colors[key] = colors[key] || intToRGB(hash);
  return colors[key];
};

export const getDirectories = (
  dataTransferItems,
  { withFullPath = true } = {}
) => {
  if (!dataTransferItems) {
    return [];
  }
  const traverseFileTreePromise = (item, path = '') => {
    return new Promise((resolve) => {
      const { isDirectory, isFile, name } = item;
      if (isFile) {
        item.file((file) => {
          file.filepath = path + file.name; // save full path
          resolve(file);
        });
      } else if (isDirectory) {
        const dirReader = item.createReader();
        dirReader.readEntries((entries) => {
          const entriesPromises = Object.values(entries).map((entry) =>
            traverseFileTreePromise(entry, `${path}${name}/`)
          );
          Promise.all(entriesPromises).then((children = []) => {
            children = children.filter(
              ({ name }) => !fileBlackList.includes(name)
            );
            resolve({ name: `${path}${name}`, children });
          });
        });
      }
    });
  };

  return Promise.all(
    Object.values(dataTransferItems)
      .filter((dataTransferItem) =>
        dataTransferItem.webkitGetAsEntry()?.createReader?.()
      )
      .map((dataTransferItem) => {
        const item = dataTransferItem.webkitGetAsEntry();
        const dirReader = item.createReader?.();
        return new Promise((resolve) => {
          !dirReader && resolve();
          dirReader.readEntries((entries) => {
            const entriesPromises = Object.values(entries).map((entry) => {
              return traverseFileTreePromise(
                entry,
                withFullPath ? `${item.fullPath.substring(1)}/` : ''
              );
            });
            Promise.all(entriesPromises).then((children = []) => {
              children = children.filter(
                ({ name }) => !fileBlackList.includes(name)
              );
              resolve({ name: item.fullPath.substring(1), children });
            });
          });
        });
      })
  );
};

export const getDescription = ({ schema, value }) => {
  let { description = '', properties = {} } = schema;
  return Object.keys(properties)
    .reduce((res, prop) => {
      const labelPattern = new RegExp(`\\{${prop}}`);
      const label = typeof value[prop] !== 'undefined' ? prop : '';
      const pattern = new RegExp(`\\{{${prop}}}`);
      const _value =
        typeof value[prop] !== 'undefined'
          ? (isObject(value[prop]) &&
              (value[prop].default || Object.values(value[prop])[0])) ||
            value[prop]
          : '';
      return res
        .replace(pattern, `${_value}`)
        .replace(labelPattern, `${label}`);
    }, description)
    .trim();
};

export const getLanguage = () => {
  return i18n.language.split('-')[0];
};

export const getMimeType = (ext) => {
  return mimeTypes[ext] || '';
};

export const getTextToShow = (obj) => {
  try {
    obj = JSON.parse(obj);
  } catch (e) {}
  const language = getLanguage();
  let textToShow = obj;
  if (isObject(textToShow)) {
    textToShow =
      typeof textToShow[language] !== 'undefined'
        ? textToShow[language]
        : textToShow.default;
  }
  if (typeof textToShow === 'undefined' || textToShow === '') {
    textToShow = '';
  }
  return textToShow;
};

export const isValidHex = (hex) =>
  hex.match(/^#([A-Fa-f0-9]{3,4}){1,2}$/) !== null;
const getChunksFromString = (st, chunkSize) =>
  st.match(new RegExp(`.{${chunkSize}}`, 'g'));
const convertHexUnitTo256 = (hexStr) =>
  parseInt(hexStr.repeat(2 / hexStr.length), 16);
const getAlphafloat = (a, alpha) => {
  if (typeof alpha !== 'undefined') {
    if (alpha > 1 && alpha <= 100) {
      return alpha / 100;
    }
    if (alpha >= 0 && alpha <= 1) {
      return alpha;
    }
  }
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  return 1;
};
const hexToRGBA = (hex, alpha) => {
  if (!isValidHex(hex)) {
    return '';
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  let hexArr = getChunksFromString(hex.slice(1), chunkSize);
  hexArr = hexArr.length === 3 ? ['ff', ...hexArr] : hexArr;
  const [a, r, g, b] = hexArr.map(convertHexUnitTo256);
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
};
const rgbaToHex = (color) => {
  let [r, g, b, a = 1] = color
    .replace(/[ rgba?()]/g, '')
    .split(',')
    .map((s) => parseFloat(s));
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  a = Math.round(a * 255).toString(16);

  if (a.length === 1) a = '0' + a;
  if (r.length === 1) r = '0' + r;
  if (g.length === 1) g = '0' + g;
  if (b.length === 1) b = '0' + b;

  a = a === 'ff' ? '' : a;

  return `#${a}${r}${g}${b}`;
};
export const getHex = (color) => {
  if (!color) {
    return '';
  }
  if (color.includes('#')) {
    return color;
  }
  return rgbaToHex(color);
};
export const getRgba = (color, alpha) => {
  if (!color) {
    return '';
  }
  if (!color.includes('#')) {
    return color;
  }
  return hexToRGBA(color, alpha);
};

export const isDark = (color) => {
  const chunkSize = Math.floor((color.length - 1) / 3);
  let hexArr = getChunksFromString(color.slice(1), chunkSize);
  hexArr = hexArr.length === 3 ? ['ff', ...hexArr] : hexArr;
  const [, r, g, b] = hexArr.map(convertHexUnitTo256);

  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179;
};

export const getTextColor = (
  bgColor,
  lightColor = '#ffffff',
  darkColor = '#000000'
) => {
  if (!bgColor) {
    return;
  }
  return isDark(bgColor) ? darkColor : lightColor;
};

export const getSelectionText = () => {
  let text = '';
  if (window.getSelection) {
    text = window.getSelection().toString();
  } else if (document.selection && document.selection.type !== 'Control') {
    text = document.selection.createRange().text;
  }
  return text;
};

export const getUnique = (arr, attribute = 'id') => {
  return arr
    .map((e) => e[attribute])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e])
    .map((e) => arr[e]);
};

export const getUniqueName = (name, options = {}) => {
  const { isId = false, names = [] } = options;
  let uniqueName = name;
  if (isId) {
    uniqueName = uniqueName
      .replace(/ /g, '_')
      .replace(/[()]*/g, '')
      .toLowerCase();
  }
  if (!names.includes(uniqueName)) {
    return uniqueName;
  }
  const extensionMatch = name.match(/\.(.+)$/);
  const extension = extensionMatch ? `.${extensionMatch[1]}` : '';
  if (isId) {
    const num = parseInt((name.match(/_(\d+)(\..)?$/) || [null, 0])[1], 10);
    uniqueName = `${name.replace(/(_\d+)*(\..+)?$/, '')}_${
      num + 1
    }${extension}`;
  } else {
    const num = parseInt((name.match(/\((\d+)\)/) || [null, 0])[1], 10);
    uniqueName = `${name.replace(/( \(\d+\))*(\..+){0,1}$/, '')} (${
      num + 1
    })${extension}`;
  }

  return getUniqueName(uniqueName, options);
};

export const humanFileSize = (bytes) => {
  const thresh = 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

export const isColor = (str) => {
  str = `${str}`;
  return str && (isValidHex(str) || str.includes('rgb'));
};

export const isDate = (d) => d instanceof Date;

export const isEmpty = (o) => Object.keys(o).length === 0;

export const isExternalFile = (name) => {
  return (
    name.match(
      /(google-services|GoogleService-Info)( *\(\d+\))*(\.json|\.plist)/
    ) !== null
  );
};

export const isFont = (file) => {
  const { name } = file;
  const ext = name.split('.').pop();
  return ['eot', 'otf', 'ttf', 'woff', 'woff2'].includes(ext);
};

export const isObject = (o) => o !== null && typeof o === 'object';

export const isPercentage = (str) => {
  const num = parseInt(str, 10);
  return str && str[str.length - 1] === '%' && num >= 0 && num <= 100;
};

export const isProjectFile = (file) => {
  const { name = '' } = file;
  const ext = name.split('.').pop();
  return ext.match(/^cdz/) !== null;
};

export const isRootFile = (name) => {
  return ['app.json', 'landing.json'].includes(name);
};

export const isText = (str) => typeof str === 'string';

export const isTextFile = (ext) => ['json', 'txt', 'xml'].includes(ext);

export const isZip = (file) => {
  const { name = '', type = '' } = file;
  const ext = name.split('.').pop();
  return type === 'application/zip' || ext === 'zip';
};

export const languages = ['de', 'en', 'es', 'fr', 'hi', 'it', 'pt', 'ru', 'zh'];

export const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      a.match(
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
      ) !== null ||
      a
        .substr(0, 4)
        .match(
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i
        ) !== null
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const moveCursorToEnd = (el) => {
  if (el.innerText && document.createRange) {
    window.setTimeout(() => {
      let selection = document.getSelection();
      let range = document.createRange();

      range.setStart(el.childNodes[0], el.innerText.length);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }, 1);
  }
};

export const parseValue = (value) => {
  const floatValue = parseFloat(value);
  if (value === 'null') {
    value = null;
  } else if (value === 'true') {
    value = true;
  } else if (value === 'false') {
    value = false;
  } else if (!isNaN(floatValue) && `${floatValue}`.length === value.length) {
    value = floatValue;
  }
  return value;
};

export const scrollToInvalid = (form) => {
  form.reportValidity();
  const elements = form.querySelectorAll('input,select,textarea,checkbox');
  let scrollToElement = null;
  Object.keys(elements).some((i) => {
    const el = elements[i];
    if (!el.validity.valid) {
      scrollToElement = el;
      return true;
    }
    return false;
  });
  scrollToElement && scrollToElement.scrollIntoView({ behavior: 'smooth' });
};

export const selectAll = (element) => {
  setTimeout(() => {
    if (!element.innerHTML) {
      return;
    }
    let sel, range;
    if (window.getSelection && document.createRange) {
      range = document.createRange();
      range.selectNodeContents(element);
      sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    }
  }, 1);
};

export const uniq = () => {
  const n = Math.floor(Math.random() * 11);
  const k = Math.floor(Math.random() * 1000000);
  return String.fromCharCode(n) + k;
};
