import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { Button, Input, Modal } from 'antd';
import { VERIFICABLE_IDENTIFIER_TYPE, VERIFICATION_TYPE } from '../context';
import { useIdentifiers, useVerifications } from '../hooks';

const PullVerification = ({ identifier, setIsOpen }) => {
  const { t } = useTranslation();
  const { pullVerification, verified } = useVerifications();
  const { subject, type, verifications = [], verifiedAt } = identifier;
  const { node: { id, token } = {} } = verifications[0] || {};

  const verificate = () => {
    pullVerification({
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    let mounted = true;
    if (!verified) {
      verificate();
    }
    if (mounted) {
      if (verified && verifiedAt) {
        setIsOpen(false);
      }
    }
    return () => {
      mounted = false;
    };
  }, [verified, verifiedAt]);

  return (
    <>
      {parse(
        t(`identifiers.${type.toLowerCase()}_verification_text`, {
          subject,
          token,
        })
      )}
      <div className="d-flex justify-content-end">
        <Button type="primary" onClick={verificate}>
          {t('common.verificate')}
        </Button>
      </div>
    </>
  );
};

const PushVerification = ({ identifier, setIsOpen }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(['', '', '', '', '', '']);
  const { createVerification, pushVerification, verified } = useVerifications();
  const { id: identifierId, subject, type } = identifier;

  const verificate = (token) => {
    pushVerification({
      variables: {
        identifierId,
        token,
      },
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (verified) {
        setIsOpen(false);
      } else {
        setToken(['', '', '', '', '', '']);
        const focusedInput = window.$(':focus');
        focusedInput && focusedInput.blur();
      }
    }
    return () => {
      mounted = false;
    };
  }, [verified]);

  return (
    <>
      <p>
        {parse(
          t(`identifiers.${type.toLowerCase()}_verification_text`, {
            subject,
          })
        )}
      </p>
      <div className="d-flex justify-content-center align-items-center mx-0 mx-md-5 my-5">
        {token.map((c, i) => (
          <Input
            key={i}
            value={c}
            className="rounded-0 border-top-0 border-end-0 border-bottom border-start-0 font-size-32 mx-2 p-0 text-center"
            placeholder="X"
            onKeyDown={(event) => {
              const { key, metaKey } = event;
              const regex = /\d|Backspace/;
              if (
                (metaKey && !['v', 'V', 'Meta'].includes(key)) ||
                (!metaKey && !regex.test(key))
              ) {
                return false;
              }
            }}
            onKeyUp={(event) => {
              event.preventDefault();
              const { currentTarget, key, metaKey } = event;
              const regex = /\d|Backspace/;
              if (
                (metaKey && !['v', 'V', 'Meta'].includes(key)) ||
                (!metaKey && !regex.test(key))
              ) {
                return false;
              }
              const pos = window.$(currentTarget).index();
              const _token = token._clone();
              _token[pos] = key === 'Backspace' ? '' : key;
              setToken(_token);
              currentTarget.value = _token[pos];
              if (_token[pos] === '' && currentTarget.previousSibling) {
                window.$(currentTarget.previousSibling).focus();
              } else if (_token[pos] !== '' && currentTarget.nextSibling) {
                window.$(currentTarget.nextSibling).focus();
              } else if (_token.join('').length === 6) {
                verificate(_token.join(''));
              }
            }}
            onPaste={(event) => {
              event.preventDefault();
              let token = event.clipboardData
                .getData('text/plain')
                .substr(0, 6);
              if (token.match(/[^\d]/)) {
                return false;
              }
              setToken(token.split(''));
              verificate(token);
            }}
          />
        ))}
      </div>
      <div className="d-flex justify-content-end">
        <Button
          color="link"
          onClick={(e) => {
            createVerification({ variables: { identifierId } });
            e.currentTarget.classList.add('disabled');
            setToken(['', '', '', '', '', '']);
          }}
        >
          {t(`identifiers.${type.toLowerCase()}_resend_verification`)}
        </Button>
        <Button
          className="ms-2"
          type="primary"
          disabled={token.join('').length !== 6}
          onClick={() =>
            pushVerification({
              variables: { identifierId, token: token.join('') },
            })
          }
        >
          {t('common.verificate')}
        </Button>
      </div>
    </>
  );
};

export const VerificationModal = ({ className }) => {
  const { t } = useTranslation();
  const { identifiers = [] } = useIdentifiers();
  const { createVerification } = useVerifications();
  const identifiersToVerificate = identifiers
    .map(({ node }) => node)
    .filter(
      ({ type, verifiedAt }) =>
        VERIFICABLE_IDENTIFIER_TYPE.includes(type) && !verifiedAt
    );
  const [identifierToVerificate, setIdentifierToVerificate] = useState(
    identifiersToVerificate[0]
  );
  const [isCancelable, setIsCancelable] = useState(
    identifiers.some(({ node }) => !!node.verifiedAt)
  );
  const [isOpen, setIsOpen] = useState(!!identifierToVerificate);

  useMemo(() => {
    if (identifierToVerificate !== identifiersToVerificate[0]) {
      let { id: identifierId, verifications = [] } =
        identifierToVerificate || identifiersToVerificate[0] || {};
      if (!verifications.length) {
        createVerification({ variables: { identifierId } });
      }
      setIdentifierToVerificate(identifiersToVerificate[0]);
      setIsCancelable(identifiers.some(({ node }) => !!node.verifiedAt));
      setIsOpen(!!identifiersToVerificate[0]);
    }
  }, [identifiersToVerificate]);

  const toggle = () => setIsOpen(!isOpen);

  if (!identifierToVerificate) {
    return '';
  }

  const { type, verifications = [] } = identifierToVerificate;
  const { type: verificationType = VERIFICATION_TYPE.PUSH } =
    (verifications[0] && verifications[0].node) || {};
  const modalProps = { isOpen };
  const headerProps = {};
  if (isCancelable) {
    modalProps.toggle = toggle;
    headerProps.toggle = toggle;
  }

  return (
    <Modal
      title={t(`identifiers.${type.toLowerCase()}_verification_title`)}
      className={`verification-modal ${className || ''}`}
      closeIcon={<Icon path={mdiClose} size={1} />}
      open={isOpen}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setIsOpen(false)}
    >
      {(verificationType === VERIFICATION_TYPE.PUSH && (
        <PushVerification
          identifier={identifierToVerificate}
          setIsOpen={setIsOpen}
        />
      )) || (
        <PullVerification
          identifier={identifierToVerificate}
          setIsOpen={setIsOpen}
        />
      )}
    </Modal>
  );
};

export default VerificationModal;
