import React from 'react';
import { useTranslation } from 'react-i18next';
import { Affix, Button, Card, Divider, List } from 'antd';
import { palettes } from '../../schemas/app';
import { useNewProject } from '../../hooks';

export const Style = () => {
  const { t } = useTranslation();
  const { setData = () => {}, setStep = () => {}, style } = useNewProject();

  const handleNext = () => {
    setStep((step) => step + 1);
  };

  return (
    <>
      <div className="px-5 py-3">
        <h3>{t('new_project.select_style')}</h3>
        <List
          className="new-project-style"
          dataSource={palettes}
          renderItem={(palette) => {
            const { accent, primary } = palette;
            const active = palette._equals(style?.palette);
            return (
              <Card
                type={active ? 'inner' : ''}
                hoverable
                onClick={() => setData({ style: { ...style, palette } })}
              >
                <div className="palette">
                  <div>
                    {[accent, primary].map((color, colorIndex) => (
                      <div
                        key={`Palette-${accent + primary}-Colors-${colorIndex}`}
                        style={{ color }}
                      />
                    ))}
                  </div>
                </div>
              </Card>
            );
          }}
        />
      </div>
      <Affix
        offsetBottom={0}
        target={() => document.getElementById('WorkingArea')}
      >
        <div className="bg-white">
          <Divider className="mt-0 mb-2" />
          <div className="bg-white px-5 py-3">
            <Button size="large" type="primary" onClick={handleNext}>
              {t('common.next')}
            </Button>
          </div>
        </div>
      </Affix>
    </>
  );
};

export default Style;
