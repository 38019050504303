import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { PAGES, SECRETS } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { useReferrer, useUser } from '../../hooks';
import { CirclePreloader } from '../../components';
import { logo, logoWhite } from '../../assets/svg/logos';
import { forget } from '../../assets/svg/figures';

export const ForgotPage = () => {
  const { t } = useTranslation();
  const { createRecovery, loading } = useUser();
  const { base = '/', key, logo: referrerLogo = {} } = useReferrer();
  const recaptchaRef = useRef();
  const recoveryForm = useRef();
  const submitted = useRef(false);
  const [state, setState] = useState({ email: '', captcha: '' });
  const { email } = state;

  useEffect(() => {
    if (submitted.current) {
      createRecovery({ variables: { ...state } });
      ls('user', { identifier: email });
    }
  }, [state]);

  const handleSubmit = (event) => {
    event && event.preventDefault();
    if (submitted.current) {
      return;
    }

    submitted.current = true;
    const form = recoveryForm.current;

    if (form.reportValidity() === false) {
      scrollToInvalid(form);
      return;
    }

    if (!state.captcha) {
      recaptchaRef.current.execute();
    }
  };

  return (
    <main className="forgot min-height-100vh--lg">
      <header className="position-absolute-top-0 z-index-2">
        <nav className="d-flex justify-content-between align-items-center">
          <div className="col-lg-5 col-xl-4 text-lg-center px-0">
            <a
              className="d-inline-block p-3 p-sm-5"
              href={base}
              aria-label="Doyo"
            >
              <img
                className="d-none d-lg-inline-block"
                src={referrerLogo.white || logoWhite}
                height="42px"
                alt="Logo"
              />
              <img
                className="d-inline-block d-lg-none"
                src={referrerLogo.color || logo}
                height="42px"
                alt="Logo"
              />
            </a>
          </div>
        </nav>
      </header>
      <Row>
        <Col lg={{ span: 6 }} xl={{ span: 5 }} className="d-none d-lg-flex">
          <img src={forget} className="w-100" alt="welcome" />
          <div className="gradient-half-secondary" />
        </Col>
        <Col>
          <Container>
            <Row>
              <Col
                md={{ span: 8, offset: 2 }}
                lg={{ span: 7, offset: 2 }}
                xl={{ span: 6, offset: 3 }}
                className="space-3 space-0--lg"
              >
                <form
                  id="ForgotForm"
                  className="js-validate mt-5"
                  onSubmit={handleSubmit}
                  ref={recoveryForm}
                >
                  <div className="mb-5">
                    <h2 className="h3 text-primary font-weight-normal mb-0">
                      {t('forgot.title')}
                    </h2>
                    <p>{t('forgot.subtitle')}</p>
                  </div>
                  <Form.Group>
                    <Form.Label
                      htmlFor="InputIdentifier"
                      className="h6 small d-block text-uppercase"
                    >
                      {t('common.email')}
                    </Form.Label>
                    <Form.Control
                      id="InputIdentifier"
                      type="email"
                      name="email"
                      data-cy="InputIdentifier"
                      aria-describedby="emailHelp"
                      placeholder={t('common.email_placeholder')}
                      onChange={(event) => {
                        const { value: email } = event.currentTarget;
                        setState((state) => ({ ...state, email }));
                      }}
                      required
                      size="lg"
                      autoFocus
                    />
                  </Form.Group>

                  <Form.Group className="mt-3">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
                      size="invisible"
                      onChange={(captcha) =>
                        setState((state) => ({ ...state, captcha }))
                      }
                    />
                  </Form.Group>

                  <Row className="align-items-center mb-5 mt-3">
                    <Col xs={6}>
                      <span className="text-sm-right text-muted">
                        <span className="d-block">
                          {t('forgot.remember_password')}
                        </span>
                        <Link
                          to={{ pathname: PAGES.SIGN_IN, state: { key } }}
                          data-cy="signin"
                        >
                          {t('common.sign_in')}
                        </Link>
                      </span>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        className="position-relative text-uppercase font-weight-bold"
                        disabled={submitted.current}
                        data-cy="submit"
                        size="lg"
                        type="submit"
                      >
                        {loading && <CirclePreloader />}
                        {t('forgot.request_reset_link')}
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </main>
  );
};

export default ForgotPage;
