import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { MockedProvider } from '@apollo/react-testing';
import IntlProvider from './i18n/IntlProvider';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ErrorBoundary } from './components';
import { initializeCookieConsent, initializeDefines } from './_helpers';
import { createApolloClient } from './graphql';
import { mocks } from './graphql/mocks';
import App from './App';

const client = createApolloClient();

initializeCookieConsent();
initializeDefines();

ReactDOM.render(
  <MockedProvider mocks={mocks} addTypename={false}>
    <ApolloProvider client={client}>
      <Router>
        <IntlProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </IntlProvider>
      </Router>
    </ApolloProvider>
  </MockedProvider>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
