import { useEffect, useState } from 'react';
import { useProjects } from '../hooks';

export const allKeys = ['@app', '@color', '@menu'];

const replaceRefs = async (str, { project, findProjectRef } = {}) => {
  const { data = {}, firebase = {}, refs = {} } = project;
  let value;

  if (!str.includes('@')) {
    return str;
  }

  const promises = [];
  str.replace(/\((@([^()]+))\)/g, (str, ref) => {
    promises.push(replaceRefs(ref, { project, findProjectRef }));
  });

  if (promises.length) {
    const results = await Promise.all(promises);
    str = str.replace(/\((@([^()]+))\)/g, () => results.shift());
  }

  if (str.includes('@app.')) {
    str = str.replace('@app.', '@app.android.');
  } else if (str.includes('@color.')) {
    str = str.replace('@color.', '@app.colors.');
  } else if (str.includes('@database.')) {
    str = str.replace('@database.', '@app.databases.');
  } else if (str.includes('@font.')) {
    str = str.replace('@font.', '@app.fonts.');
  } else if (str.includes('@menu.')) {
    str = str.replace('@menu.', '@menus.');
  }

  if (str.match(/@(cookie|property|firebase\.user)\.[A-Za-z0-9]+/g)) {
    return refs[str] || null;
  }
  value = findProjectRef(str);
  if (value) {
    value = await replaceRefs(value, { project, findProjectRef });
    return value;
  }

  if (str.indexOf('@firebase.firestore.') === 0) {
    let { firestore: docRef } = firebase;
    if (str.indexOf('@firebase.firestore.') !== 0 || !docRef) {
      return str;
    }

    let _str = str.replace('@firebase.firestore.', '');
    try {
      while (_str.includes('.')) {
        _str = _str.split('.');
        const col = _str.shift();
        const doc = _str.shift();

        docRef = docRef.collection(col).doc(doc);

        _str = _str.join('.');
      }
      let entry;
      try {
        entry = (await docRef?.get?.())?.data?.();
      } catch (e) {
        entry = await docRef?.data?.();
      }

      if (docRef.collection) {
        const { docs = [] } = (await docRef.collection(_str)?.get?.()) || {};
        const collection = {};
        (await Promise.all(docs.map((e) => e.data?.()))).forEach((value, i) => {
          const key = docs[i].id;
          collection[key] = value;
        });
        if (Object.keys(collection).length) {
          return collection;
        }
      }

      let { [_str]: replacedStr = entry } = entry;
      replacedStr = isNaN(replacedStr) ? replacedStr : parseFloat(replacedStr);
      replacedStr = replacedStr === 'null' ? null : replacedStr;
      replacedStr = replacedStr === 'true' ? true : replacedStr;
      replacedStr = replacedStr === 'false' ? false : replacedStr;

      return replacedStr;
    } catch (error) {
      return;
    }
  } else if (str.indexOf('@firebase.storage.') === 0) {
    const { storage } = firebase;
    if (!storage) {
      return null;
    }
    const ref = storage.ref();
    const imageRef = ref.child(str.replace('@firebase.storage.', ''));
    return await imageRef.getDownloadURL();
  }

  let subKeys = str.replace('@', '').split('.');

  let database;
  if (str.includes('@app.databases.')) {
    const databaseName = str.split('.')[2];
    const { source } =
      data.app?.databases?.find(({ id }) => id === databaseName) || {};
    try {
      const databaseFile = data.res?.find(({ id }) => id === source) || {};
      const { blob } = databaseFile;
      database = JSON.parse(await blob?.text?.());
    } catch (e) {
      database = [];
    }
  }

  value = subKeys.reduce(
    (value, subKey, i) =>
      (subKey === 'index' && parseInt(subKeys[i - 1], 10) + 1) ||
      (value && value[subKey]) ||
      (Array.isArray(value) && value.find(({ id }) => id === subKey)) ||
      (database && database[subKey]) ||
      (value && value.items && value.items[subKey]),
    data
  );

  return value;
};

export function useSource(key = '') {
  const { findProjectRef = () => {}, project = {} } = useProjects();
  const { data = {} } = project;
  const { app = {}, views = {} } = data;
  const keys = key ? allKeys.filter((k) => k.includes(key)) : allKeys;
  let _key = key;

  if (_key.includes('@app.')) {
    _key = _key.replace('@app.', '@app.android.');
  } else if (_key.includes('@color.')) {
    _key = _key.replace('@color.', '@app.colors.');
  } else if (_key.includes('@font.')) {
    _key = _key.replace('@font.', '@app.fonts.');
  } else if (_key.includes('@menu.')) {
    _key = _key.replace('@menu.', '@menus.');
  }
  let subKeys = _key.replace('@', '').split('.');
  const initValue = subKeys.reduce(
    (value, subKey, i) =>
      (value && value[subKey]) ||
      (Array.isArray(value) && value.find(({ id }) => id === subKey)) ||
      (value && value.items && value.items[subKey]),
    data
  );
  const [value, setValue] = useState(initValue);

  useEffect(() => {
    let mounted = true;
    (async () => {
      let val = await replaceRefs(key, { project, findProjectRef });
      mounted && setValue(val);
    })();

    return () => {
      mounted = false;
    };
  }, [app, key === '@views' && views]);

  return { value, keys };
}
