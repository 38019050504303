import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PAGES } from '../../_config';
import { projectTypes } from '../../schemas/project';
import { useAlerts, useNewProject, useProjects, useViewer } from '../../hooks';
import mobileAppPath from '../../assets/defaults/projects/mobile_app.cdz';
import flowPath from '../../assets/defaults/projects/flow.cdz';
import webPath from '../../assets/defaults/projects/web.cdz';
import backendPath from '../../assets/defaults/projects/backend.cdz';

const projectsPath = {
  [projectTypes.BACKEND]: backendPath,
  [projectTypes.MOBILE_APP]: mobileAppPath,
  [projectTypes.FLOW]: flowPath,
  [projectTypes.WEB]: webPath,
};

export const NewProjectCreation = () => {
  const { t } = useTranslation();
  const { alertError } = useAlerts();
  const history = useHistory();
  const {
    icon = null,
    external = [],
    settings: { json: settings } = {},
    info = {},
    style = {},
    template = {},
    type = projectTypes.MOBILE_APP,
  } = useNewProject();
  const { name, description } = info;
  const { palette } = style;
  const { addProjects, createProjectFromZip } = useProjects();
  const { viewer = {} } = useViewer();

  const createProject = async (zip, properties = {}) => {
    try {
      const node = await createProjectFromZip(zip, properties);
      addProjects({ edges: [{ node }] });
      const { data = {} } = node;
      const { app, flows, views } = data;
      const { project_name: projectName } = app;
      let to = '';
      if (type === projectTypes.BACKEND) {
        to = '/settings/integrations';
      } else if (views) {
        const view = views[0]?.id;
        to = `/views/${view}`;
      } else if (flows) {
        const flow = flows[0]?.id;
        to = `/flows/${flow}`;
      }

      window.gtag?.('event', 'createProject', {
        category: viewer.id,
        label: projectName,
      });
      history.push(`${PAGES.APP}/${projectName}${to}`);
    } catch ({ message }) {
      alertError({ message: t('projects.invalid_project'), open: true });
    }
  };

  useEffect(() => {
    (async () => {
      const cdzPath = template?.url || projectsPath[type];
      const cdz = await (await fetch(cdzPath)).blob();
      createProject(cdz, {
        name,
        description,
        palette,
        external,
        icon,
        settings,
        type,
      });
    })();
  }, []);

  return (
    <div className="p-3">
      <h3>{t('new_project.creating_project')}...</h3>
    </div>
  );
};

export default NewProjectCreation;
