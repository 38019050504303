import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { scrollToInvalid } from '../_helpers';
import { ALERT, AlertReducer } from './reducers';

export const ALERT_TYPE = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};
export const AlertContext = createContext(ALERT.INITIAL_STATE);

export const AlertProvider = ({ children }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useImmerReducer(AlertReducer, ALERT.INITIAL_STATE);
  const { alerts } = state;

  const alertClear = (id) => dispatch({ type: ALERT.CLEAR, payload: { id } });

  const alertError = (errors) => {
    errors = Array.isArray(errors) ? errors : [errors];
    errors.forEach((error) => {
      let { extensions = {}, message, path } = error;
      const { category, validation } = extensions;

      if (validation) {
        const keys = Object.keys(validation);
        const form = window
          .$(`[name="${keys[0].replace('input.', '')}"]`)
          .parents('form')[0];
        keys.forEach((key) => {
          const value = validation[key];
          key = key.replace('input.', '');
          const input = document.querySelector(`[name="${key}"]`);
          if (!input) {
            return;
          }
          const validate = input.dataset.validate;
          if (validate !== 'false') {
            const message = t(`errors.${path[0]}.${category}.${value}`);
            input.setCustomValidity(message);
          }
          input.dataset.validate = false;
        });
        if (form.reportValidity() === false) {
          scrollToInvalid(form);
        }
        return;
      }
      message =
        !path || !category ? message : t(`errors.${path[0]}.${category}`);
      dispatch({
        type: ALERT.ERROR,
        payload: { ...error, message, type: ALERT_TYPE.ERROR },
      });
    });
  };

  const alertInfo = (data) =>
    dispatch({ type: ALERT.INFO, payload: { ...data, type: ALERT_TYPE.INFO } });

  const alertSuccess = (data) =>
    dispatch({
      type: ALERT.SUCCESS,
      payload: { ...data, type: ALERT_TYPE.SUCCESS },
    });

  const alertWarning = (data) =>
    dispatch({
      type: ALERT.SUCCESS,
      payload: { ...data, type: ALERT_TYPE.WARNING },
    });

  const getUnviewed = () =>
    alerts
      .filter(({ viewed }) => !viewed)
      .sort((alert1, alert2) => (alert1.id < alert2.id ? -1 : 1));

  const setViewed = (alerts) =>
    dispatch({ type: ALERT.SET_VIEWED, payload: { alerts } });

  return (
    <AlertContext.Provider
      value={{
        ...state,
        alertClear,
        alertError,
        alertInfo,
        alertSuccess,
        alertWarning,
        getUnviewed,
        setViewed,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export const AlertConsumer = AlertContext.Consumer;
export default AlertContext;
