import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { useHistory } from 'react-router-dom';
import ls from 'local-storage';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SHOW_VIEWER } from '../graphql/queries';
import {
  DELETE_USER,
  UPDATE_PASSWORD,
  UPDATE_USER,
} from '../graphql/mutations';
import { PAGES } from '../_config';
import { useAlerts, useUser } from '../hooks';
import { AccountProvider } from './Account';
import { ViewerReducer, VIEWER } from './reducers';

export const IDENTIFIER_TYPE = {
  ALIAS: 'ALIAS',
  DOMAIN: 'DOMAIN',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

export const VERIFICABLE_IDENTIFIER_TYPE = [
  IDENTIFIER_TYPE.DOMAIN,
  IDENTIFIER_TYPE.EMAIL,
  IDENTIFIER_TYPE.PHONE,
];

export const ViewerContext = createContext(VIEWER.INITIAL_STATE);

export const ViewerProvider = ({ children }) => {
  const { alertError } = useAlerts();
  const history = useHistory();
  const [state, dispatch] = useImmerReducer(
    ViewerReducer,
    VIEWER.INITIAL_STATE
  );
  const { deleteAuthentication } = useUser();

  let { loading, refetch } = useQuery(SHOW_VIEWER, {
    context: { passport: true },
    skip: !ls('user')?.auth,
    onCompleted: ({ viewer } = {}) => {
      if (viewer) {
        window.gtag?.('identify', {
          viewer,
        });
        dispatch({ type: VIEWER.GET, payload: { viewer } });
      }
    },
  });

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(
    DELETE_USER,
    {
      context: { passport: true },
      onCompleted: ({ deleteUser }) =>
        dispatch({ type: VIEWER.DELETE, payload: { ...deleteUser } }),
      onError: (error) => dispatch({ type: VIEWER.ERROR, payload: { error } }),
    }
  );

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      context: { passport: true },
      onCompleted: () =>
        refetch().then(({ data }) =>
          dispatch({ type: VIEWER.GET, payload: { ...data } })
        ),
      onError: (error) => dispatch({ type: VIEWER.ERROR, payload: { error } }),
    }
  );

  const [updateUserPassword, { loading: updateUserPasswordLoading }] =
    useMutation(UPDATE_PASSWORD, {
      context: { passport: true },
      onError: ({ graphQLErrors }) => alertError(graphQLErrors),
    });

  const logout = () => {
    const { base = '/', url = PAGES.SIGN_IN } = ls('referrer') || {};
    const { auth = {} } = ls('user') || {};
    const { id } = auth;
    ls.remove('user');
    if (id) {
      deleteAuthentication({ variables: { id } });
    }
    window.indexedDB.deleteDatabase('Doyo');
    if (base !== '/') {
      window.location.href = url;
    }
    history.push(PAGES.HOME);
  };

  const setWelcome = (welcome) => {
    dispatch({ type: VIEWER.WELCOME, payload: { welcome } });
  };

  const showSubscriptionModal = (show = true) => {
    dispatch({ type: VIEWER.SUBSCRIPTION_MODAL, payload: { show } });
  };

  const toggleAutoSave = (autoSave) => {
    dispatch({ type: VIEWER.AUTOSAVE, payload: { autoSave } });
  };

  const toggleJsonEditorMode = (jsonEditorMode) => {
    dispatch({ type: VIEWER.JSON_EDITOR_MODE, payload: { jsonEditorMode } });
  };

  const toggleMode = (mode) => {
    dispatch({ type: VIEWER.MODE, payload: { mode } });
  };

  const togglePreview = (preview) => {
    dispatch({ type: VIEWER.PREVIEW, payload: { preview } });
  };

  const togglePreviewResolution = (resolution) => {
    dispatch({ type: VIEWER.RESOLUTION, payload: { resolution } });
  };

  if (process.env.NODE_ENV === 'development') {
    console.log('VIEWER >>>', state);
  }

  loading =
    loading ||
    deleteUserLoading ||
    updateUserLoading ||
    updateUserPasswordLoading;

  return (
    <ViewerContext.Provider
      value={{
        ...state,
        deleteUser,
        loading,
        logout,
        refetch,
        updateUser,
        updateUserPassword,
        setWelcome,
        showSubscriptionModal,
        toggleAutoSave,
        toggleJsonEditorMode,
        toggleMode,
        togglePreview,
        togglePreviewResolution,
      }}
    >
      <AccountProvider>{children}</AccountProvider>
    </ViewerContext.Provider>
  );
};

export const ViewerConsumer = ViewerContext.Consumer;
export default ViewerContext;
