import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { PAGES, SECRETS } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { useReferrer, useUser } from '../../hooks';
import { CirclePreloader, PasswordInput } from '../../components';

export const RegisterForm = ({ callback = () => {} }) => {
  const { t } = useTranslation();
  const { createAuthentication, createUser, data, error, loading } = useUser();
  const { key } = useReferrer();
  const recaptchaRef = useRef();
  const submitted = useRef(false);

  const [user, setUser] = useState({
    email: '',
    password: '',
    captcha: '',
    announcements: null,
  });

  useEffect(() => {
    submitted.current = submitted.current && !error;
    if (submitted.current && data) {
      let { email: identifier, password } = user;
      createAuthentication({ variables: { identifier, password } });
    }
  }, [data, error]);

  useEffect(() => {
    if (submitted.current) {
      createUser({ variables: { ...user } });
    }
  }, [user]);

  const handleChange = (event) => {
    const target = event.currentTarget;
    let { name, value } = target.attributes;
    name = name ? name.nodeValue : target.name;
    value = value ? value.nodeValue === 'true' : target.value;
    target.dataset.validate === 'false' && delete target.dataset.validate;
    target.setCustomValidity && target.setCustomValidity('');
    setUser((user) => ({ ...user, [name]: value }));
  };
  const handleCaptchaChange = (captcha) => {
    setUser((user) => ({ ...user, captcha }));
  };

  const handleSubmit = (event) => {
    event && event.preventDefault();
    if (submitted.current) {
      return;
    }

    submitted.current = true;
    const form = event.currentTarget;

    if (form.reportValidity() === false) {
      scrollToInvalid(form);
      return;
    }

    if (!user.captcha) {
      recaptchaRef.current.execute();
      return;
    }

    createUser({ variables: { ...user } });
  };

  return (
    <form id="RegisterForm" className="js-validate" onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label
          htmlFor="InputIdentifier"
          className="h6 small d-block text-uppercase"
        >
          {t('common.email')}
        </Form.Label>
        <Form.Control
          id="InputIdentifier"
          type="email"
          name="email"
          data-cy="InputIdentifier"
          aria-describedby="emailHelp"
          placeholder={t('common.email_placeholder')}
          onChange={handleChange}
          size="lg"
          maxLength="250"
          required
        />
      </Form.Group>

      <Form.Group className="mt-3">
        <Form.Label
          htmlFor="InputPassword"
          className="h6 small d-block text-uppercase"
        >
          {t('common.password')}
        </Form.Label>
        <PasswordInput
          id="InputPassword"
          name="password"
          data-cy="InputPassword"
          placeholder={t('common.promise_not_to_tell')}
          aria-describedby="passwordHelp"
          onChange={handleChange}
          size="lg"
          minLength="8"
          maxLength="72"
          required
        />
      </Form.Group>

      <Form.Group className="mt-3">
        <Form.Label className="h6 small d-block text-uppercase">
          {t('common.recieve_communications')}
        </Form.Label>
        <ButtonGroup data-toggle="buttons">
          <Form.Label
            className={`btn btn-outline-primary${
              user.announcements === true ? ' active' : ''
            }`}
            htmlFor="CommunicationYes"
            name="announcements"
            value={true}
            onClick={handleChange}
          >
            <Form.Check
              id="CommunicationYes"
              type="radio"
              name="announcements"
              value={true}
              onChange={handleChange}
              required
            />
            <span>{t('common.yes')}</span>
          </Form.Label>
          <Form.Label
            className={`btn btn-outline-primary${
              user.announcements === false ? ' active' : ''
            }`}
            htmlFor="CommunicationNo"
            name="announcements"
            value={false}
            onClick={handleChange}
          >
            <Form.Check
              id="CommunicationNo"
              type="radio"
              name="announcements"
              value={false}
              onChange={handleChange}
              required
            />
            <span>{t('common.no')}</span>
          </Form.Label>
        </ButtonGroup>
      </Form.Group>

      <Form.Group className="mt-3 d-flex">
        <Form.Check
          id="Terms"
          className="custom-control-input"
          aria-describedby="agreeTermsHelp"
          required
        />{' '}
        <Form.Label
          htmlFor="Terms"
          className="small custom-control-label ms-2 mb-3 w-100 text-muted"
        >
          {t('register.i_agree')}{' '}
          <Link
            to={PAGES.LEGAL_TERMS}
            target="_blank"
            className="link-muted"
            data-cy="terms"
          >
            {t('register.doyo_terms')}
          </Link>
        </Form.Label>
      </Form.Group>

      <Row className="sign-buttons align-items-center mb-5 mt-3">
        <Col xs={6}>
          <span className="text-sm-right text-muted">
            <span className="d-block">{t('register.have_account')}</span>
            <Link
              to={{ pathname: PAGES.SIGN_IN, state: { key } }}
              data-cy="signin"
            >
              {t('common.sign_in')}
            </Link>
          </span>
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Button
            color="primary"
            className="position-relative text-uppercase font-weight-bold"
            disabled={submitted.current}
            data-cy="submit"
            size="lg"
            type="submit"
          >
            {loading && <CirclePreloader />}
            {t('common.sign_up_long')}
          </Button>
        </Col>
      </Row>

      <Form.Group className="mb-5">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
          size="invisible"
          onChange={handleCaptchaChange}
          data-cy="recaptcha"
        />
      </Form.Group>
    </form>
  );
};

export default RegisterForm;
