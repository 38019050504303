import React from 'react';
import Component from './Component';
import { RangeSlider } from '../../../components/RangeSlider';
import { getRgba } from '../../../_helpers';
import { useColor } from '../../hooks';

export const RangeCompiler = ({
  min = 0,
  max = 100,
  step = 1,
  value = 0,
  bar_color: barColorProp = '#26000000',
  progress_color: progressColorProp = '@color.accent',
  thumb_color: thumbColorProp = '@color.accent',
  thumb_size: thumbSize = 16,
  ...props
}) => {
  let { hex: barColor = '' } = useColor(barColorProp);
  let { hex: progressColor = '' } = useColor(progressColorProp);
  let { hex: thumbColor = '' } = useColor(thumbColorProp);
  barColor = getRgba(barColor);
  progressColor = getRgba(progressColor);
  thumbColor = getRgba(thumbColor);

  return (
    <Range
      {...props}
      barColor={barColor}
      progressColor={progressColor}
      range={{ min, max }}
      start={value}
      step={step}
      thumbColor={thumbColor}
      thumbSize={thumbSize}
    />
  );
};

export const Range = ({
  barColor,
  progressColor,
  range,
  start,
  step,
  thumbColor,
  thumbSize,
  ...props
}) => {
  let { id } = props;

  return (
    <>
      <style>{`
          #${id}Range.noUi-target {
              background: ${barColor} !important;
          }
          #${id}Range .noUi-connect {
              background: ${progressColor} !important;
          }
          #${id}Range .noUi-handle {
              background: ${thumbColor} !important;
              width: ${thumbSize}px !important;
              height: ${thumbSize}px !important;
              right: -${thumbSize / 2}px !important;
          }
      `}</style>
      <Component
        {...props}
        id={`${id}Range`}
        range={range}
        start={start}
        step={step}
        connect="lower"
        component={RangeSlider}
      />
    </>
  );
};

export default RangeCompiler;
