import React, { useState } from 'react';
import BootstrapAlert from 'react-bootstrap/Alert';
import Component from './Component';
import { TextCompiler } from './Text';

export const AlertCompiler = ({ text, ...props }) => {
  return (
    <Alert {...props}>
      <TextCompiler text={text} />
    </Alert>
  );
};

export const Alert = ({ children, ...props }) => {
  const [show, setShow] = useState(true);

  return (
    <Component
      {...props}
      show={show}
      onClose={() => setShow(false)}
      component={BootstrapAlert}
    >
      {children}
    </Component>
  );
};

Alert.Heading = BootstrapAlert.Heading;
Alert.Link = BootstrapAlert.Link;

Alert.displayName = 'Alert';
AlertCompiler.displayName = 'Alert';
export default AlertCompiler;
