import { getUnique } from '../../_helpers';

export const PROJECT = {
  INITIAL_STATE: {
    projects: { edges: [], init: true },
    project: {},
    status: undefined,
  },

  ADD: 'PROJECT_ADD',
  CREATE: 'PROJECT_CREATE',
  DELETE: 'PROJECT_DELETE',
  ERROR: 'PROJECT_ERROR',
  FIREBASE: 'PROJECT_FIREBASE',
  GET: 'PROJECT_GET',
  LIST: 'PROJECT_LIST',
  REFERENCE: 'PROJECT_REFERENCE',
  SELECT: 'PROJECT_SELECT',
  STATUS: 'PROJECT_STATUS',
  UPDATE: 'PROJECT_UPDATE',
};

export const ProjectReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case PROJECT.ADD:
    case PROJECT.CREATE:
      if (payload.projects?.edges?.length) {
        let uniqueProjecs = getUnique([
          ...payload.projects.edges.map(({ node }) => node),
          ...draft.projects.edges.map(({ node }) => node),
        ]);
        uniqueProjecs = uniqueProjecs.map((node) => ({ node }));
        draft.projects = {
          edges: uniqueProjecs,
        };
      }
      break;

    case PROJECT.DELETE:
      draft.projects = {
        ...draft.projects,
        edges: draft.projects.edges
          .map(({ node }) => ({
            node,
            loading: node.id === payload.id && payload.loading,
          }))
          .filter(({ node, loading }) => node.id !== payload.id || loading),
      };
      if (draft.project?.id) {
        draft.project = draft.project.id === payload.id ? {} : draft.project;
      }
      break;

    case PROJECT.ERROR:
      draft.error = payload.error;
      break;

    case PROJECT.FIREBASE:
      draft.projects = {
        ...draft.projects,
        edges: draft.projects.edges.map(({ node }) =>
          node.id === payload.project.id
            ? { node: { ...node, firebase: payload.firebase } }
            : { node }
        ),
      };
      if (draft.project?.id && draft.project?.id === payload.project?.id) {
        draft.project = { ...draft.project, firebase: payload.firebase };
      }
      break;

    case PROJECT.GET:
      draft.projects = draft.projects.map((project) => {
        const { node } = project;
        const { id } = node;
        return id !== payload.project.id ? project : payload.project;
      });

      break;

    case PROJECT.LIST:
      draft.projects = payload.projects;
      draft.pageInfo = payload.projects.pageInfo;
      break;

    case PROJECT.REFERENCE:
      draft.projects = {
        ...draft.projects,
        edges: draft.projects.edges.map(({ node }) =>
          node.id === payload.project.id
            ? {
                node: {
                  ...node,
                  refs: { ...(node.refs || {}), ...payload.ref },
                },
              }
            : { node }
        ),
      };
      if (draft.project?.id && draft.project?.id === payload.project?.id) {
        draft.project = {
          ...draft.project,
          refs: { ...(draft.project.refs || {}), ...payload.ref },
        };
      }

      break;

    case PROJECT.SELECT:
      draft.project = payload.project.node;
      break;

    case PROJECT.STATUS:
      draft.status = payload.status;
      break;

    case PROJECT.UPDATE:
      if (
        draft.project?.id === payload.project.id ||
        draft.project?.id === `k${payload.project.kustodio_id}`
      ) {
        draft.project = { ...draft.project, ...payload.project };
      }
      draft.projects = {
        edges: draft.projects.edges.map(({ node }) => {
          const { id } = node;
          if (
            id === payload.project.id ||
            id === `k${payload.project.kustodio_id}`
          ) {
            return { node: payload.project };
          }
          return { node };
        }),
      };
      break;

    default:
      return draft;
  }
};
