export const CLIPBOARD = {
  INITIAL_STATE: {
    clipboard: [],
  },

  RESET: 'CLIPBOARD_RESET',
  STORE: 'CLIPBOARD_STORE',
};

export const ClipboardReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case CLIPBOARD.RESET:
      draft.clipboard = [];
      break;

    case CLIPBOARD.STORE:
      draft.clipboard = [payload.object, ...draft.clipboard];
      break;

    default:
      return draft;
  }
};
