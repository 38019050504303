export const FIREBASE = {
  INITIAL_STATE: {
    // Custom states:
		user: {},
		firestore: {},
		storage: {},
  },
  // Custom consts:
		LOGOUT: 'FIREBASE_LOGOUT',
		LOGIN: 'FIREBASE_LOGIN',
		SET: 'FIREBASE_SET',
		GET: 'FIREBASE_GET',
		STORAGE_GET: 'FIREBASE_STORAGE_GET',
  LOGIN: 'FIREBASE_LOGIN',
  LOGOUT: 'FIREBASE_LOGOUT',
};

export const FirebaseReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    // Custom case:
    case FIREBASE.LOGIN:
      draft.user = payload.user;
      break;

    case FIREBASE.LOGOUT:
      draft.user = FIREBASE.INITIAL_STATE.user;
      break;

    case FIREBASE.GET:
        const firestoreObj1 = { ...draft.firestore };
        const firestoreObj2 = { ...(payload.data || {}) };
        draft.firestore = firestoreObj1._merge(firestoreObj2);
        break;
    case FIREBASE.STORAGE_GET:
      const storageObj1 = { ...draft.storage };
      const storageObj2 = { ...(payload.data || {}) };
      const draftStorage = storageObj1._merge(storageObj2, 'path');
      if (!storageObj1._equals(draftStorage)) {
        draft.storage = draftStorage;
      }
      break;
    
    default:
      return draft;
  }
};
