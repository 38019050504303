import { useEffect, useState, useRef } from 'react';

export const useNearScreen = ({
  distance = '100px',
  externalRef,
  once = true,
} = {}) => {
  const [isNearScreen, setShow] = useState(false);
  const fromRef = useRef();

  useEffect(() => {
    let mounted = true;
    let observer;

    const element = externalRef ? externalRef.current : fromRef.current;

    const onChange = (entries, observer) => {
      const el = entries[0];
      if (el.isIntersecting) {
        mounted && setShow(true);
        once && observer.disconnect();
      } else {
        mounted && !once && setShow(false);
      }
    };

    Promise.resolve(
      typeof IntersectionObserver !== 'undefined'
        ? IntersectionObserver
        : import('intersection-observer')
    ).then(() => {
      observer = new IntersectionObserver(onChange, {
        rootMargin: distance,
      });

      if (element) observer.observe(element);
    });

    return () => {
      mounted = false;
      observer && observer.disconnect();
    };
  });

  return { isNearScreen, fromRef };
};
export default useNearScreen;
