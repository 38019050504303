import React from 'react';

export const PlaceholderImage = (props) => {
  const { className, color, size } = props;
  const classNameString = `placeholder placeholder-image${
    size ? ` ${size}-placeholder` : ''
  }${color ? ` bg-${color}` : ''}${className ? ` ${className}` : ''}`;
  return <div {...props} className={classNameString} />;
};

export const PlaceholderText = (props) => {
  const { className, color, size } = props;
  const classNameString = `placeholder placeholder-text${
    size ? ` ${size}-placeholder` : ''
  }${color ? ` bg-${color}` : ''}${className ? ` ${className}` : ''}`;
  return <span {...props} className={classNameString} />;
};
