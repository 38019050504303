import React, { createContext, useContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { useMutation } from '@apollo/react-hooks';
import { IdentifierContext } from '.';
import { IDENTIFIER, VERIFICATION, VerificationReducer } from './reducers';
import {
  CREATE_VERIFICATION,
  PULL_VERIFICATION,
  PUSH_VERIFICATION,
} from '../graphql/mutations';

export const VERIFICATION_TYPE = {
  PULL: 'PULL',
  PUSH: 'PUSH',
};

export const VerificationContext = createContext();

export const VerificationProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(
    VerificationReducer,
    VERIFICATION.INITIAL_STATE
  );

  const { dispatch: dispatchIdentifiers, refetch: refetchIdentifiers } =
    useContext(IdentifierContext);

  const [createVerification, { loading: createVerificationLoading }] =
    useMutation(CREATE_VERIFICATION, {
      context: { passport: true },
      onCompleted: () => {
        dispatch({ type: VERIFICATION.CREATE });
        refetchIdentifiers().then(({ data }) => {
          const { account = {} } = data;
          dispatchIdentifiers({
            type: IDENTIFIER.LIST,
            payload: { identifiers: account.identifiers },
          });
        });
      },
      onError: (error) =>
        dispatch({ type: VERIFICATION.ERROR, payload: { error } }),
    });

  const [pullVerification, { loading: pullVerificationLoading }] = useMutation(
    PULL_VERIFICATION,
    {
      context: { passport: true },
      onCompleted: ({ pullVerification }) =>
        dispatch({ type: VERIFICATION.PULL, payload: { pullVerification } }),
      onError: (error) =>
        dispatch({ type: VERIFICATION.ERROR, payload: { error } }),
    }
  );

  const [pushVerification, { loading: pushVerificationLoading }] = useMutation(
    PUSH_VERIFICATION,
    {
      context: { passport: true },
      onCompleted: ({ pushVerification }) => {
        dispatch({ type: VERIFICATION.PUSH, payload: { pushVerification } });
      },
      onError: (error) =>
        dispatch({ type: VERIFICATION.ERROR, payload: { error } }),
    }
  );

  let loading =
    createVerificationLoading ||
    pullVerificationLoading ||
    pushVerificationLoading;

  if (process.env.NODE_ENV === 'development') {
    console.log('VERIFICATION >>>', state);
  }

  return (
    <VerificationContext.Provider
      value={{
        ...state,
        createVerification,
        loading,
        pullVerification,
        pushVerification,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
};

export const VerificationConsumer = VerificationContext.Consumer;
export default VerificationContext;
