import React from 'react';
import Dialog from './Dialog';
import View from './View';
import Wrapper from './wrapper';
import './styles/main.scss';

export const orientationTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const Android = ({ json }) => {
  const { __type } = json;

  let code = null;
  switch (__type) {
    case 'view_wrappers':
      code = <Wrapper {...json} />;
      break;
    case 'views':
      code = <View {...json} />;
      break;
    case 'dialogs':
      code = <Dialog {...json} />;
      break;
    default:
      code = null;
  }

  return code;
};

export default Android;
