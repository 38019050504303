import { currencyCodes, languages, UI } from '../../_helpers';
import {
  _if,
  backgroundTypes,
  colors as defColors,
  data_source,
  events as defEvents,
  file,
  font,
  fontSize,
  filter as commonFilter,
  halign,
  id,
  label,
  marginTypes,
  multiLanguageText,
  paddingTypes,
  position,
  radius,
  scale,
  source,
  text,
  title,
  valign,
} from './common';

export const componentTypes = {
  ACCORDION: 'accordion',
  ALERT: 'alert',
  BUTTON: 'button',
  CARD: 'card',
  CAROUSEL: 'carousel',
  CHART: 'chart',
  CHECKBOX: 'checkbox',
  COLORPICKER: 'colorpicker',
  DATEPICKER: 'datepicker',
  DROPDOWN: 'dropdown',
  GROUP: 'group',
  FORM: 'form',
  ICON: 'icon',
  IMAGE: 'image',
  INPUTFILE: 'inputfile',
  INPUTTEXT: 'inputtext',
  NAV: 'nav',
  LIST: 'list',
  LOTTIE: 'lottie',
  PDFVIEWER: 'pdfviewer',
  PROGRESSBAR: 'progress_bar',
  RADIO: 'radio',
  RANGE: 'range',
  SELECT: 'select',
  STRIPEPRICINGTABLE: 'stripe_pricing_table',
  SWITCH: 'switch',
  TABLE: 'table',
  TEXT: 'text',
  TIMELINE: 'timeline',
  VIDEO: 'video',
  WEB: 'web',
  YOUTUBE: 'youtube',
};

export const color = {
  title: 'Color',
  default: '',
  key: 'color',
  oneOf: [{ pattern: '@color..+' }],
};

export const hexColor = {
  title: 'Hexadecimal Color',
  default: '#ffffff',
  pattern:
    '^(#([A-Fa-f0-9]{3,4}){1,2}|rgb(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){2}([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))))|(rgba(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){3}(0|1|0.\\d+))))$',
};

export const colors = {
  ...defColors,
  required: [...defColors.required, 'darken', 'lighten', 'transparent'],
  properties: {
    ...defColors.properties,
    darken: { ...color, default: '#66000000' },
    lighten: { ...color, default: '#66ffffff' },
    transparent: { ...color, default: '#00000000' },
  },
};

export const filter = {
  ...commonFilter,
  properties: {
    ...commonFilter.properties,
    not_in: { type: 'string' },
    case_sensitive: { type: 'boolean', default: true },
  },
};

export const icon = {
  title: 'Icon',
  type: 'string',
  key: 'image',
};

export const orientationTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  AUTO: 'auto',
};

export const orientation = {
  title: 'Orientation',
  default: 'vertical',
  enum: [
    orientationTypes.AUTO,
    orientationTypes.HORIZONTAL,
    orientationTypes.VERTICAL,
  ],
  ui: UI.DROPDOWN,
};

export const width = {
  title: 'Width',
  default: '',
  oneOf: [
    { enum: ['fill', 'wrap'] },
    { pattern: '[0-9]+%' },
    { pattern: '[0-9]+px' },
    { pattern: '[0-9]+rem' },
    { pattern: '[0-9]+vw' },
    { type: 'number' },
  ],
};

export const height = {
  title: 'Height',
  default: '',
  oneOf: [
    { enum: ['fill', 'wrap'] },
    { pattern: '[0-9]+%' },
    { pattern: '[0-9]+px' },
    { pattern: '[0-9]+rem' },
    { pattern: '[0-9]+vh' },
    { type: 'number' },
  ],
};

export const numberRefPercentage = [
  { type: 'number' },
  { type: 'string', pattern: '^@.+$' },
  { type: 'string', pattern: '[0-9]+%' },
];

export const commonBackgroundProperties = {
  type: {
    enum: [
      backgroundTypes.TRANSPARENT,
      backgroundTypes.SOLID,
      backgroundTypes.GRADIENT,
      backgroundTypes.IMAGE,
    ],
    ui: UI.DROPDOWN,
  },
};

export const background = {
  title: 'Background',
  description: '{{type}}',
  default: {},
  required: ['type'],
  properties: {
    ...commonBackgroundProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [backgroundTypes.TRANSPARENT]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonBackgroundProperties,
      },
    },
    [backgroundTypes.SOLID]: {
      description: '{{type}} {{color}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['color'],
      properties: {
        ...commonBackgroundProperties,
        color,
      },
    },
    [backgroundTypes.GRADIENT]: {
      description: '{{type}} {{direction}} {{from}} {{to}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['direction', 'from', 'to'],
      properties: {
        ...commonBackgroundProperties,
        direction: {
          default: 'to_bottom',
          enum: ['to_bottom', 'to_right', 'to_bottom_right', 'to_bottom_left'],
        },
        from: { ...color, default: '@color.primaryLight' },
        to: { ...color, default: '@color.primaryDark' },
      },
    },
    [backgroundTypes.IMAGE]: {
      description: '{{type}} {{image}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['image'],
      properties: {
        ...commonBackgroundProperties,
        image: { type: 'string' },
      },
    },
  },
};

export const border = {
  title: 'Border',
  description: '{{color}}',
  default: {},
  required: ['top', 'bottom', 'start', 'end'],
  properties: {
    color,
    top: { type: 'boolean', default: true },
    bottom: { type: 'boolean', default: true },
    start: { type: 'boolean', default: true },
    end: { type: 'boolean', default: true },
  },
};

export const margin = {
  title: 'Margin',
  description: `{${[marginTypes.TOP]}} {{${[marginTypes.TOP]}}} {${[
    marginTypes.BOTTOM,
  ]}} {{${[marginTypes.BOTTOM]}}} {${[marginTypes.START]}} {{${[
    marginTypes.START,
  ]}}} {${[marginTypes.END]}} {{${[marginTypes.END]}}}`,
  default: 0,
  oneOf: [
    ...numberRefPercentage,
    {
      type: 'object',
      default: { [marginTypes.TOP]: 0 },
      properties: {
        [marginTypes.BOTTOM]: {
          oneOf: numberRefPercentage,
        },
        [marginTypes.END]: {
          oneOf: numberRefPercentage,
        },
        [marginTypes.START]: {
          oneOf: numberRefPercentage,
        },
        [marginTypes.TOP]: {
          oneOf: numberRefPercentage,
        },
      },
    },
  ],
};

export const limit = {
  oneOf: [{ type: 'number', default: 10 }, { pattern: '^@.+$' }],
};

export const order = {
  default: {},
  required: ['type'],
  properties: {
    type: {
      oneOf: [{ enum: ['asc', 'desc'] }, { pattern: '^@.+$' }],
      default: 'asc',
    },
    field: { type: 'string' },
  },
};

export const padding = {
  title: 'Padding',
  description: `{${[paddingTypes.TOP]}} {{${[paddingTypes.TOP]}}} {${[
    paddingTypes.BOTTOM,
  ]}} {{${[paddingTypes.BOTTOM]}}} {${[paddingTypes.START]}} {{${[
    paddingTypes.START,
  ]}}} {${[paddingTypes.END]}} {{${[paddingTypes.END]}}}`,
  default: 0,
  oneOf: [
    ...numberRefPercentage,
    {
      type: 'object',
      default: { [paddingTypes.TOP]: 0 },
      properties: {
        [paddingTypes.BOTTOM]: {
          oneOf: numberRefPercentage,
        },
        [paddingTypes.END]: {
          oneOf: numberRefPercentage,
        },
        [paddingTypes.START]: {
          oneOf: numberRefPercentage,
        },
        [paddingTypes.TOP]: {
          oneOf: numberRefPercentage,
        },
      },
    },
  ],
};

export const commonWebFunctionProperties = {
  function: {
    enum: [
      'abort',
      'add',
      'api',
      'back',
      'browser',
      'clone',
      'collection',
      'delete',
      'do',
      'download',
      'file',
      'goto',
      'hide',
      'login',
      'logout',
      'open',
      'pause',
      'play',
      'popup',
      'request',
      'scroll',
      'set',
      'signup',
      'stop',
      'track',
      'wait',
    ],
    reset: true,
  },
  id: { type: 'string', default: '', readOnly: true },
  comments: { default: '' },
  if: _if,
};

export const _function = {
  title: 'Function',
  description: '{{function}}',
  required: ['function'],
  default: { function: '' },
  properties: {
    ...commonWebFunctionProperties,
  },
  select: { $data: '0/function' },
  selectCases: {
    abort: {
      properties: {
        ...commonWebFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    add: {
      description: '{{to}}',
      required: ['what', 'to'],
      properties: {
        ...commonWebFunctionProperties,
        what: {
          default: '',
          oneOf: [
            { pattern: '^@.+$' },
            {
              type: 'object',
              default: {},
              removeAdditional: true,
            },
          ],
          additionalProperties: true,
        },
        key: {
          oneOf: [
            { pattern: '^[a-zA-Z]+[_a-zA-Z0-9]*$' },
            { pattern: '^@.+$' },
            { pattern: '^[a-z(@][a-zA-Z()@._0-9]*' },
          ],
        },
        to: { type: 'string' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    api: {
      description: '{{api}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['api'],
      properties: {
        ...commonWebFunctionProperties,
        api: {
          default: {},
          required: ['method'],
          properties: {
            method: { type: 'string' },
          },
          select: { $data: '0/method' },
          selectCases: {},
        },
        into: { type: 'string', pattern: '^@.+$' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
    },
    back: {
      properties: {
        ...commonWebFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    browser: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonWebFunctionProperties,
        url: { type: 'string' },
        target: {
          default: '_self',
          oneOf: [{ type: 'string' }, { enum: ['_self', '_blank'] }],
        },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    clone: {
      description: '{{what}} -> {{into}}',
      required: ['what', 'into'],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string', default: '@database' },
        into: { type: 'string', pattern: '^@.+$' },
        filter,
        order,
        limit,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    collection: {
      description: '{{operation}} {{into}}',
      required: ['what', 'into'],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string', default: '@database' },
        filter,
        into: {
          oneOf: [
            { type: 'string', pattern: '^[A-Za-z_0-9]*' },
            { type: 'string', pattern: '^@.+$' },
          ],
        },
        operation: {
          enum: ['count', 'field_to_array', 'filter', 'set_field', 'sum'],
          ui: UI.DROPDOWN,
        },
        parameter: { type: 'string' },
        field: { type: 'string', pattern: '^[A-Za-z_0-9]*' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    delete: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    do: {
      description: '{{function}}',
      required: ['what'],
      properties: {
        ...commonWebFunctionProperties,
        what: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    download: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonWebFunctionProperties,
        url: { type: 'string' },
        name: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    file: {
      description: '{{into}}',
      required: ['into'],
      properties: {
        ...commonWebFunctionProperties,
        into: {
          default: '@property.tempFile',
          type: 'string',
          pattern: '^@.+$',
        },
        multiple: { type: 'boolean', default: true },
        type: { type: 'string' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    goto: {
      description: '{{view}}',
      required: ['view'],
      properties: {
        ...commonWebFunctionProperties,
        view: { type: 'string', key: 'view' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    hide: {
      description: '{{dialog}}',
      required: ['dialog'],
      properties: {
        ...commonWebFunctionProperties,
        dialog: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    login: {
      description: '{{provider}} {{email}} {{pass}}',
      required: ['provider'],
      properties: {
        ...commonWebFunctionProperties,
        provider: {
          enum: [
            'firebase.email',
            'firebase.facebook',
            'firebase.google',
            'firebase.github',
            'firebase.phone',
            'firebase.twitter',
          ],
          default: 'firebase.email',
        },
        email: { type: 'string' },
        pass: { type: 'string' },
        forgot: { type: 'boolean', default: true },
        onvalid: { default: [] },
        oninvalid: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    logout: {
      description: '{{provider}}',
      required: ['provider'],
      properties: {
        ...commonWebFunctionProperties,
        provider: { enum: ['firebase'], default: 'firebase' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    open: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string', key: 'database' },
        filter,
        order,
        limit,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    pause: {
      description: '{{what}}',
      required: [],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string', default: null },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    play: {
      description: '{{what}}',
      required: ['what', 'loop'],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string', key: 'audio' },
        loop: { type: 'boolean', default: false },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    popup: {
      description: '{{dialog}}',
      required: ['dialog'],
      properties: {
        ...commonWebFunctionProperties,
        dialog: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    request: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonWebFunctionProperties,
        url: { type: 'string' },
        method: {
          enum: ['delete', 'get', 'patch', 'post'],
          default: 'get',
          ui: UI.DROPDOWN,
        },
        response: {
          enum: ['plain', 'json-api'],
          default: 'plain',
          ui: UI.DROPDOWN,
        },
        headers: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
        params: {
          default: {},
          patternProperties: {
            '.*': {
              oneOf: [
                { type: 'string' },
                {
                  type: 'object',
                  patternProperties: {
                    '.*': { type: 'string' },
                  },
                  additionalProperties: true,
                },
              ],
              additionalProperties: true,
            },
          },
          additionalProperties: true,
        },
        into: { type: 'string' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    scroll: {
      description: '-> {{to}}',
      required: ['to'],
      properties: {
        ...commonWebFunctionProperties,
        to: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    set: {
      description: '{{what}} = {{value}}',
      required: ['what', 'value'],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string' },
        value: { type: 'string' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    signup: {
      description: '{{provider}} {{email}} {{pass}}',
      required: ['provider', 'email', 'pass'],
      properties: {
        ...commonWebFunctionProperties,
        provider: { enum: ['firebase'], default: 'firebase' },
        email: { type: 'string' },
        pass: { type: 'string' },
        onvalid: { default: [] },
        oninvalid: { default: [] },
        onexists: { default: [] },
        onweakpassword: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    stop: {
      description: '{{what}}',
      required: [],
      properties: {
        ...commonWebFunctionProperties,
        what: { type: 'string', key: 'audio' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    track: {
      description: '{{action}}',
      required: ['action'],
      properties: {
        ...commonWebFunctionProperties,
        action: { type: 'string' },
        category: { type: 'string' },
        label: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    wait: {
      description: '{{seconds}}',
      required: ['seconds'],
      properties: {
        ...commonWebFunctionProperties,
        seconds: { type: 'number', default: 1 },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
  },
};

export const database = {
  title: 'Database',
  description: '{{type}} {{id}}',
  default: { id: 'database' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'source', 'type'],
  properties: {
    id,
    source: { ...file, key: 'database' },
    type: { enum: ['json'], default: 'json', ui: UI.DROPDOWN },
    readonly: { type: 'boolean', default: true },
  },
};

export const databases = {
  title: 'Databases',
  default: [],
  additionalProperties: false,
  removeAdditional: true,
  type: 'array',
  uniqueItemProperties: ['id'],
  items: database,
};

export const firebase = {
  additionalProperties: false,
  removeAdditional: true,
  default: {},
  required: ['apiKey', 'projectId', 'appId'],
  properties: {
    apiKey: { type: 'string', default: '' },
    projectId: { type: 'string', default: '' },
    appId: { type: 'string', default: '' },
    analytics: { type: 'boolean', default: true },
    authentication: { type: 'boolean', default: true },
    firestore: { type: 'boolean', default: true },
    storage: { type: 'boolean', default: true },
  },
};

export const arrayOfFunctions = {
  title: 'arrayOfFunctions',
  default: [],
  type: 'array',
  items: { ..._function },
};

export const and = _if;
export const or = _if;
export const _else = arrayOfFunctions;
export const onchanged = arrayOfFunctions;
export const onclick = arrayOfFunctions;
export const onerror = arrayOfFunctions;
export const onexists = arrayOfFunctions;
export const onexit = arrayOfFunctions;
export const onfinished = arrayOfFunctions;
export const oninvalid = arrayOfFunctions;
export const onloaded = arrayOfFunctions;
export const onlostfocus = arrayOfFunctions;
export const onpressedenter = arrayOfFunctions;
export const onsubmit = arrayOfFunctions;
export const onsuccess = arrayOfFunctions;
export const onvalid = arrayOfFunctions;
export const onweakpassword = arrayOfFunctions;

export const events = {
  ...defEvents,
  properties: {
    ...defEvents.properties,
    else: _else,
    onchanged,
    onclick,
    onerror,
    onexists,
    onexit,
    onfinished,
    oninvalid,
    onloaded,
    onlostfocus,
    onpressedenter,
    onsubmit,
    onsuccess,
    onvalid,
    onweakpassword,
    what: arrayOfFunctions,
  },
};

export const commonComponentProperties = {
  id,
  type: {
    enum: [
      componentTypes.ACCORDION,
      componentTypes.ALERT,
      componentTypes.BUTTON,
      componentTypes.CARD,
      componentTypes.CAROUSEL,
      componentTypes.CHART,
      componentTypes.CHECKBOX,
      componentTypes.COLORPICKER,
      componentTypes.DATEPICKER,
      componentTypes.DROPDOWN,
      componentTypes.FORM,
      componentTypes.GROUP,
      componentTypes.ICON,
      componentTypes.IMAGE,
      componentTypes.INPUTFILE,
      componentTypes.INPUTTEXT,
      componentTypes.NAV,
      componentTypes.LIST,
      componentTypes.LOTTIE,
      componentTypes.PDFVIEWER,
      componentTypes.PROGRESSBAR,
      componentTypes.RADIO,
      componentTypes.RANGE,
      componentTypes.SELECT,
      componentTypes.STRIPEPRICINGTABLE,
      componentTypes.SWITCH,
      componentTypes.TABLE,
      componentTypes.TEXT,
      componentTypes.TIMELINE,
      componentTypes.VIDEO,
      componentTypes.WEB,
      componentTypes.YOUTUBE,
    ],
  },
  background,
  border,
  comments: { default: '' },
  halign,
  height: { ...height, default: 'wrap' },
  if: _if,
  margin,
  max_height: { ...height, default: '100%' },
  max_width: { ...width, default: '100%' },
  min_height: { ...height, default: 0 },
  min_width: { ...width, default: 0 },
  onclick: { default: [] },
  padding,
  position,
  radius,
  valign,
  visible: { type: 'boolean', default: true },
  width: { ...width, default: '100%' },
  __expanded: { type: 'boolean' },
};

export const commonComponentParameters = [
  'backgroundColor',
  'opacity',
  'visible',
];

export const multilanguageUrl = {
  description: '{{default}}',
  oneOf: [
    { ...source },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: source,
        ...languages.reduce((lngs, key) => ({ ...lngs, [key]: source }), {}),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const multiLanguageImage = {
  description: '{{default}}',
  key: 'image',
  oneOf: [
    { ...file },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: { ...file, key: 'image' },
        ...languages.reduce(
          (lngs, key) => ({ ...lngs, [key]: { ...file, key: 'image' } }),
          {}
        ),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const multiLanguageVideo = {
  description: '{{default}}',
  key: 'video',
  oneOf: [
    { ...file },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: { ...file, key: 'video' },
        ...languages.reduce(
          (lngs, key) => ({ ...lngs, [key]: { ...file, key: 'video' } }),
          {}
        ),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const multiLanguagePdf = {
  description: '{{default}}',
  key: 'pdf',
  oneOf: [
    { ...file },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: { ...file, key: 'pdf' },
        ...languages.reduce(
          (lngs, key) => ({ ...lngs, [key]: { ...file, key: 'pdf' } }),
          {}
        ),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const size = {
  enum: ['small', 'medium', 'large'],
  default: 'large',
  ui: UI.DROPDOWN,
};

export const option = {
  title: 'Option',
  description: '{{label}}',
  default: { label: '', value: '' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['label', 'value'],
  properties: {
    label,
    value: { type: 'string' },
  },
};

export const options = {
  title: 'Options',
  default: [],
  type: 'array',
  items: {
    ...option,
  },
};

export const redirects = {
  title: 'Redirects',
  default: [],
  additionalProperties: false,
  removeAdditional: true,
  type: 'array',
  uniqueItemProperties: ['path'],
  items: {
    title: 'Redirect',
    description: '{{path}} >> {{view}}',
    default: {},
    additionalProperties: false,
    removeAdditional: true,
    required: ['path', 'view'],
    properties: {
      path: { pattern: '^[a-z]+[_a-z0-9/]*$' },
      view: { type: 'string', key: 'view' },
    },
  },
};

export const scripts = {
  title: 'Scripts',
  default: [],
  additionalProperties: false,
  removeAdditional: true,
  type: 'array',
  uniqueItemProperties: ['name'],
  items: {
    title: 'Script',
    description: '{{name}}',
    default: { location: 'head', name: '', script: '' },
    additionalProperties: false,
    removeAdditional: true,
    required: ['name', 'script'],
    properties: {
      location: { enum: ['head', 'body'], default: 'head', ui: UI.DROPDOWN },
      name: { type: 'string' },
      script: { type: 'string' },
    },
  },
};

const commonServerProperties = {
  type: {
    enum: ['managed', 'ftp'],
    default: 'managed',
    ui: UI.DROPDOWN,
  },
};

export const server = {
  title: 'Server',
  description: '{{type}}',
  default: {},
  required: ['type'],
  properties: {
    ...commonServerProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    managed: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['domain'],
      properties: {
        ...commonServerProperties,
        domain: { type: 'string', default: '' },
      },
    },
    ftp: {
      additionalProperties: false,
      removeAdditional: true,
      required: [
        'host',
        'server_type',
        'logon_type',
        'user',
        'password',
        'port',
      ],
      properties: {
        ...commonServerProperties,
        folder: { type: 'string', default: 'public_html' },
        host: { type: 'string' },
        server_type: { enum: ['FTP', 'SFTP'], default: 'FTP', ui: UI.DROPDOWN },
        logon_type: { enum: ['normal'], default: 'normal', ui: UI.DROPDOWN },
        user: { type: 'string' },
        password: { type: 'string', ui: UI.PASSWORD },
        port: { type: 'number' },
      },
    },
  },
};

export const slide = {
  title: 'Slide',
  description: '{{title}}',
  default: { image: '', title: '', subtitle: '' },
  properties: {
    color,
    image: { type: 'string' },
    title,
    subtitle: title,
  },
};

export const slides = {
  title: 'Slides',
  default: [],
  type: 'array',
  items: {
    ...slide,
  },
};

export const accordionItem = {
  title: 'AccordionItem',
  description: '{{header}}',
  default: {},
  required: ['header', 'content'],
  properties: {
    header: title,
    content: { default: [] },
  },
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
    },
  },
};

export const accordionItems = {
  title: 'AccordionItems',
  default: [],
  type: 'array',
  items: {
    ...accordionItem,
  },
};

export const button = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'color', 'text'],
  default: { id: 'button', type: 'button' },
  properties: {
    ...commonComponentProperties,
    color: { ...color, default: '@color.primary' },
    outline: { type: 'boolean', default: true },
    text,
    height: { ...height, default: 'wrap' },
    width: { ...width, default: 'wrap' },
    size,
  },
  parameters: [...commonComponentParameters],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
    },
  },
};

export const checkbox = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'checkbox', type: 'checkbox' },
  properties: {
    ...commonComponentProperties,
    label,
    default_value: { type: 'boolean', default: true },
    name: { type: 'string', default: 'checkbox' },
    source,
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'checked'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};
export const componentSwitch = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'switch', type: 'switch' },
  properties: {
    ...commonComponentProperties,
    label,
    default_value: { type: 'boolean', default: true },
    name: { type: 'string', default: 'switch' },
    source,
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'checked'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};

export const colorPicker = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'colorpicker', type: 'colorpicker' },
  properties: {
    ...commonComponentProperties,
    floating: { type: 'boolean', default: true },
    label,
    default_value: hexColor,
    name: { type: 'string', default: 'color' },
    source,
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'value'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};

export const datePicker = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'datepicker', type: 'datepicker' },
  properties: {
    ...commonComponentProperties,
    floating: { type: 'boolean', default: true },
    label,
    max: {
      oneOf: [{ pattern: '^@.+$' }, { pattern: '^\\d{4}-\\d{2}-\\d{2}$' }],
    },
    min: {
      oneOf: [{ pattern: '^@.+$' }, { pattern: '^\\d{4}-\\d{2}-\\d{2}$' }],
    },
    name: { type: 'string', default: 'date' },
    source,
    step: { type: 'number', default: 1 },
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'value'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};

const {
  width: _w,
  height: _h,
  ...iconComponentProperties
} = commonComponentProperties;

export const componentIcon = {
  title: 'ComponentIcon',
  description: '{{type}} {{id}} {{icon}}',
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'icon'],
  default: { id: 'icon', type: 'icon' },
  properties: {
    ...iconComponentProperties,
    color,
    icon: { type: 'string' },
    size: { type: 'number', default: 1 },
  },
  parameters: [
    ...commonComponentParameters,
    'horizontal',
    'vertical',
    'rotate',
    'spin',
  ],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
    },
  },
};

export const componentImage = {
  title: 'ComponentImage',
  description: '{{type}} {{id}} {{file}}',
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'height', 'width'],
  default: { id: 'image', type: 'image' },
  properties: {
    ...commonComponentProperties,
    online: { default: false, type: 'boolean' },
    field: { type: 'string' },
    file: {
      ...multiLanguageImage,
      requiredGroup: true,
    },
    scale,
    source: { ...source, requiredGroup: true },
  },
  parameters: [...commonComponentParameters],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
    },
  },
};

const commonFormatProperties = {
  type: {
    enum: ['amount', 'currency', 'date', 'html', 'markdown'],
    ui: UI.DROPDOWN,
  },
};

export const componentText = {
  title: 'ComponentText',
  description: '{{type}} {{id}} {{text}}',
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'height', 'width'],
  default: { id: 'text', type: 'text' },
  properties: {
    ...commonComponentProperties,
    color: { ...color, default: '@color.primaryText' },
    format: {
      required: ['type'],
      default: { type: '' },
      properties: {
        ...commonFormatProperties,
      },
      select: { $data: '0/type' },
      selectCases: {
        amount: {
          additionalProperties: false,
          removeAdditional: true,
          required: [],
          properties: {
            ...commonFormatProperties,
            thousands: { type: 'boolean', default: true },
            decimals: { type: 'number', default: 2 },
          },
        },
        currency: {
          additionalProperties: false,
          removeAdditional: true,
          required: [],
          properties: {
            ...commonFormatProperties,
            currency: { enum: currencyCodes, default: 'EUR' },
            lang: {
              oneOf: [{ type: 'string' }, { enum: ['local'] }],
              default: 'local',
            },
            min_decimals: { type: 'number', default: 0 },
          },
        },
        date: {
          additionalProperties: false,
          removeAdditional: true,
          required: [],
          properties: {
            ...commonFormatProperties,
            lang: {
              oneOf: [{ type: 'string' }, { enum: ['local'] }],
              default: 'local',
            },
            year: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            month: {
              enum: ['2-digit', 'numeric', 'narrow', 'short', 'long'],
              default: 'numeric',
            },
            day: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            hour: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            minute: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            second: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            weekday: { enum: ['narrow', 'short', 'long'], default: 'short' },
          },
        },
        html: {
          additionalProperties: false,
          removeAdditional: true,
          required: [],
          properties: {
            ...commonFormatProperties,
          },
        },
        markdown: {
          additionalProperties: false,
          removeAdditional: true,
          required: [],
          properties: {
            ...commonFormatProperties,
          },
        },
      },
    },
    field: { type: 'string' },
    font,
    font_size: fontSize,
    selectable: { type: 'boolean', default: true },
    text: { ...text, requiredGroup: true },
    source: { ...source, requiredGroup: true },
    style: {
      enum: [
        'title1',
        'title2',
        'title3',
        'paragraph',
        'bold',
        'italic',
        'underlined',
        'deleted',
        'subscript',
        'superscript',
        'small',
        'marked',
      ],
    },
    text_align: halign,
    text_valign: valign,
  },
  parameters: [...commonComponentParameters],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
    },
  },
};

export const inputFile = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'inputfile', type: 'inputfile' },
  properties: {
    ...commonComponentProperties,
    floating: { type: 'boolean', default: true },
    label,
    name: { type: 'string', default: 'file' },
    source,
    multiple: { type: 'boolean', default: true },
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'files', 'url'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};

export const inputText = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'inputtext', type: 'inputtext' },
  properties: {
    ...commonComponentProperties,
    area: { type: 'boolean', default: true },
    autocomplete: { type: 'boolean', default: true },
    floating: { type: 'boolean', default: true },
    format: { enum: ['date', 'email', 'number', 'password', 'text'] },
    hint: multiLanguageText,
    label,
    name: { type: 'string', default: 'text' },
    pattern: { type: 'string', default: '' },
    size,
    source,
    onchanged: { default: [] },
    onlostfocus: { default: [] },
    onpressedenter: { default: [] },
  },
  parameters: [...commonComponentParameters, 'value'],
  events: {
    ...events,
    properties: {
      onchanged: { default: [] },
      onclick: { default: [] },
      onlostfocus: { default: [] },
      onpressedenter: { default: [] },
    },
  },
};

export const radio = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'radio', type: 'radio' },
  properties: {
    ...commonComponentProperties,
    label,
    name: { type: 'string', default: 'radio' },
    source,
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'value'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};

export const range = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name'],
  default: { id: 'range', type: 'range' },
  properties: {
    ...commonComponentProperties,
    label,
    min: { type: 'number', default: 0 },
    max: { type: 'number', default: 100 },
    name: { type: 'string', default: 'range' },
    source,
    step: { type: 'number', default: 1 },
    value: { type: 'number', default: 0 },
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'value'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};

export const select = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'name', 'options'],
  default: { id: 'select', type: 'select' },
  properties: {
    ...commonComponentProperties,
    data_source,
    filter,
    order,
    limit,
    map_source: {
      default: { label: '', value: '' },
      properties: {
        label: { type: 'string' },
        value: { type: 'string' },
      },
    },
    floating: { type: 'boolean', default: true },
    hint: multiLanguageText,
    label,
    name: { type: 'string', default: 'select' },
    options,
    size,
    source,
    value: { type: 'string', default: '' },
    onchanged: { default: [] },
  },
  parameters: [...commonComponentParameters, 'value'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
      onchanged: { default: [] },
    },
  },
};

export const chartDataSet = {
  title: 'DataSet',
  description: '{{label}}',
  additionalProperties: false,
  removeAdditional: true,
  default: { label: '', data: [] },
  required: ['label', 'data'],
  properties: {
    backgroundColor: {
      oneOf: [
        { type: 'string', pattern: hexColor.pattern },
        {
          default: [],
          additionalProperties: true,
          type: 'array',
          items: {
            type: 'string',
            additionalProperties: true,
          },
        },
      ],
      additionalProperties: true,
    },
    backgroundGradient: { type: 'boolean', default: true },
    borderColor: {
      oneOf: [
        { type: 'string', pattern: hexColor.pattern },
        {
          default: [],
          additionalProperties: true,
          type: 'array',
          items: {
            type: 'string',
            additionalProperties: true,
          },
        },
      ],
      additionalProperties: true,
    },
    borderWidth: { default: 1, type: 'number' },
    clip: { type: 'number', default: 5 },
    fill: { type: 'boolean', default: true },
    data: {
      default: [],
      additionalProperties: false,
      removeAdditional: true,
      type: 'array',
      items: {
        additionalProperties: true,
        default: '',
        type: 'number',
      },
    },
    hidden: { type: 'boolean', default: true },
    hoverOffset: { type: 'number', default: 5 },
    label: { type: 'string' },
    parsing: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        xAxisKey: { type: 'string', default: '' },
        yAxisKey: { type: 'string', default: '' },
      },
    },
    type: {
      type: 'string',
      enum: [
        'bar',
        'line',
        'scatter',
        'bubble',
        'pie',
        'doughnut',
        'polar',
        'radar',
      ],
      default: 'bar',
      ui: UI.DROPDOWN,
    },
  },
};

export const commonAxisProperties = {
  alignToPixels: { default: false, type: 'boolean' },
  bounds: { enum: ['data', 'ticks'], default: 'ticks', ui: UI.DROPDOWN },
  backgroundColor: hexColor,
  beginAtZero: { default: true, type: 'boolean' },
  display: { default: false, type: 'boolean' },
  grid: {
    additionalProperties: false,
    removeAdditional: true,
    default: {},
    properties: {
      beginAtZero: { default: true, type: 'boolean' },
      borderColor: hexColor,
      borderWidth: { default: 1, type: 'number' },
      borderDash: {
        title: 'BorderDash',
        default: [],
        type: 'array',
        items: {
          additionalProperties: true,
          type: 'number',
        },
      },
      borderDashOffset: { default: 0, type: 'number' },
      circular: { default: false, type: 'boolean' },
      color: hexColor,
      display: { default: false, type: 'boolean' },
      drawBorder: { default: false, type: 'boolean' },
      drawOnChartArea: { default: false, type: 'boolean' },
      drawTicks: { default: false, type: 'boolean' },
      lineWidth: { default: 1, type: 'number' },
      offset: { default: false, type: 'boolean' },
      tickBorderDash: {
        title: 'BorderDash',
        default: [],
        type: 'array',
        items: {
          additionalProperties: true,
          type: 'number',
        },
      },
      tickBorderDashOffset: { default: 1, type: 'number' },
      tickColor: hexColor,
      tickLength: { default: 8, type: 'number' },
      tickWidth: { default: 1, type: 'number' },
      z: { default: 0, type: 'number' },
    },
  },
  max: { default: 5, type: 'number' },
  min: { default: 0, type: 'number' },
  reverse: { default: false, type: 'boolean' },
  stacked: { default: false, type: 'boolean' },
  suggestedMax: { default: 5, type: 'number' },
  suggestedMin: { default: 0, type: 'number' },
  ticks: {
    additionalProperties: false,
    removeAdditional: true,
    default: {},
    properties: {
      backdropColor: hexColor,
      backdropPadding: { default: 1, type: 'number' },
      display: { default: false, type: 'boolean' },
      color: hexColor,
      padding: { default: 1, type: 'number' },
      showLabelBackdrop: { default: false, type: 'boolean' },
      textStrokeColor: hexColor,
      textStrokeWidth: { default: 1, type: 'number' },
      z: { default: 0, type: 'number' },
    },
  },
  title: {
    additionalProperties: false,
    removeAdditional: true,
    default: {},
    properties: {
      align: {
        enum: ['start', 'center', 'end'],
        default: 'center',
        ui: UI.DROPDOWN,
      },
      color: hexColor,
      display: { default: false, type: 'boolean' },
      padding: { default: 1, type: 'number' },
      text: {
        oneOf: [
          { type: 'string' },
          {
            default: [],
            additionalProperties: true,
            type: 'array',
            items: {
              type: 'string',
              additionalProperties: true,
            },
          },
        ],
      },
    },
  },
  weight: { default: 0, type: 'number' },
};

export const xAxisProperties = {};
export const yAxisProperties = {};
export const rAxisProperties = {
  angleLines: {
    additionalProperties: false,
    removeAdditional: true,
    default: {},
    properties: {
      display: { default: false, type: 'boolean' },
      color: hexColor,
      lineWidth: { default: 1, type: 'number' },
      borderDash: { default: 0, type: 'number' },
      borderDashOffset: { default: 0, type: 'number' },
    },
  },
  pointLabels: {
    additionalProperties: false,
    removeAdditional: true,
    default: {},
    properties: {
      backdropColor: hexColor,
      backdropPadding: { default: 1, type: 'number' },
      display: { default: false, type: 'boolean' },
      color: hexColor,
      padding: { default: 1, type: 'number' },
    },
  },
  ticks: {
    additionalProperties: false,
    removeAdditional: true,
    default: {},
    properties: {
      stepSize: { default: 1, type: 'number' },
    },
  },
};

export const easing = {
  enum: [
    'linear',
    'easeInQuad',
    'easeOutQuad',
    'easeInOutQuad',
    'easeInCubic',
    'easeOutCubic',
    'easeInOutCubic',
    'easeInQuart',
    'easeOutQuart',
    'easeInOutQuart',
    'easeInQuint',
    'easeOutQuint',
    'easeInOutQuint',
    'easeInSine',
    'easeOutSine',
    'easeInOutSine',
    'easeInExpo',
    'easeOutExpo',
    'easeInOutExpo',
    'easeInCirc',
    'easeOutCirc',
    'easeInOutCirc',
    'easeInElastic',
    'easeOutElastic',
    'easeInOutElastic',
    'easeInBack',
    'easeOutBack',
    'easeInOutBack',
    'easeInBounce',
    'easeOutBounce',
    'easeInOutBounce',
  ],
  default: 'easeOutQuart',
  ui: UI.DROPDOWN,
};

export const pointStyle = {
  enum: [
    'circle',
    'cross',
    'crossRot',
    'dash',
    'line',
    'rect',
    'rectRounded',
    'rectRot',
    'star',
    'triangle',
  ],
  default: 'circle',
  ui: UI.DROPDOWN,
};

export const chartOptions = {
  additionalProperties: false,
  removeAdditional: true,
  default: {},
  properties: {
    animation: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        duration: { default: 1000, type: 'number' },
        easing,
        delay: { default: 0, type: 'number' },
        loop: { default: true, type: 'boolean' },
      },
    },
    elements: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        point: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            backgroundColor: hexColor,
            borderColor: hexColor,
            borderWidth: { default: 1, type: 'number' },
            hitRadius: { default: 1, type: 'number' },
            hoverRadius: { default: 4, type: 'number' },
            hoverBorderWidth: { default: 1, type: 'number' },
            pointStyle,
            radius: { default: 3, type: 'number' },
            rotation: { default: 0, type: 'number' },
          },
        },
        line: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            tension: { default: 0.5, type: 'number' },
            backgroundColor: {
              oneOf: [
                { type: 'string', pattern: hexColor.pattern },
                {
                  default: [],
                  additionalProperties: true,
                  type: 'array',
                  items: {
                    type: 'string',
                    additionalProperties: true,
                  },
                },
              ],
              additionalProperties: true,
            },
            borderCapStyle: {
              enum: ['butt', 'round', 'square'],
              default: 'butt',
              ui: UI.DROPDOWN,
            },
            borderColor: hexColor,
            borderDashOffset: { default: 0.0, type: 'number' },
            borderJoinStyle: {
              enum: ['bevel', 'round', 'miter'],
              default: 'miter',
              ui: UI.DROPDOWN,
            },
            borderWidth: { default: 3, type: 'number' },
            capBezierPoints: { type: 'boolean', default: true },
            cubicInterpolationMode: {
              enum: ['default', 'monotone'],
              default: 'default',
              ui: UI.DROPDOWN,
            },
            fill: { type: 'boolean', default: true },
            stepped: { type: 'boolean', default: false },
          },
        },
        bar: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            backgroundColor: {
              oneOf: [
                { type: 'string', pattern: hexColor.pattern },
                {
                  default: [],
                  additionalProperties: true,
                  type: 'array',
                  items: {
                    type: 'string',
                    additionalProperties: true,
                  },
                },
              ],
              additionalProperties: true,
            },
            borderWidth: { default: 3, type: 'number' },
            borderColor: hexColor,
            borderRadius: { default: 0, type: 'number' },
            borderSkipped: {
              enum: [
                'start',
                'end',
                'middle',
                'bottom',
                'left',
                'top',
                'right',
              ],
              default: 'start',
              ui: UI.DROPDOWN,
            },
            inflateAmount: {
              default: '',
              oneOf: [{ enum: ['auto'] }, { type: 'number' }],
            },
            pointStyle,
          },
        },
        arc: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            angle: { default: 0, type: 'number' },
            backgroundColor: hexColor,
            borderAlign: {
              enum: ['start', 'center', 'end'],
              default: 'center',
              ui: UI.DROPDOWN,
            },
            borderColor: hexColor,
            borderWidth: { default: 2, type: 'number' },
          },
        },
      },
    },
    indexAxis: { enum: ['x', 'y', 'r'], default: 'x', ui: UI.DROPDOWN },
    interaction: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        intersect: { type: 'boolean', default: false },
        mode: { default: 'index', enum: ['index'] },
      },
    },
    layout: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        autoPadding: { type: 'boolean', default: false },
        padding: { default: 0, type: 'number' },
      },
    },

    legend: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        align: {
          enum: ['start', 'center', 'end'],
          default: 'center',
          ui: UI.DROPDOWN,
        },
        display: { type: 'boolean', default: false },
        position: {
          enum: ['top', 'left', 'bottom', 'right'],
          default: 'top',
          ui: UI.DROPDOWN,
        },
        maxHeight: { default: '', type: 'number' },
        maxWidth: { default: '', type: 'number' },
        fullSize: { type: 'boolean', default: false },
        onClick: onclick,
        onHover: arrayOfFunctions,
        onLeave: arrayOfFunctions,
        reverse: { type: 'boolean', default: true },
        labels: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            boxWidth: { default: 40, type: 'number' },
            boxHeight: { default: 16, type: 'number' },
            color: hexColor,
            padding: { default: 10, type: 'number' },
            pointStyle,
            textAlign: {
              enum: ['left', 'center', 'right'],
              default: 'center',
              ui: UI.DROPDOWN,
            },
            usePointStyle: { type: 'boolean', default: true },
          },
        },
        rtl: { type: 'boolean', default: false },
        title: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            color: hexColor,
            display: { type: 'boolean', default: true },
            padding: { default: 0, type: 'number' },
            text: title,
          },
        },
      },
    },
    maintainAspectRatio: { type: 'boolean', default: false },
    parsing: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        xAxisKey: { type: 'string', default: '' },
        yAxisKey: { type: 'string', default: '' },
      },
    },
    plugins: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        legend: { type: 'boolean', default: false },
        subtitle: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            align: { enum: ['start', 'center', 'end'], default: 'center' },
            display: { type: 'boolean', default: true },
            position: {
              enum: ['top', 'left', 'bottom', 'right'],
              default: 'top',
              ui: UI.DROPDOWN,
            },
            text: title,
          },
        },
        title: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            align: { enum: ['start', 'center', 'end'], default: 'center' },
            display: { type: 'boolean', default: true },
            position: {
              enum: ['top', 'left', 'bottom', 'right'],
              default: 'top',
              ui: UI.DROPDOWN,
            },
            text: title,
          },
        },
        tooltip: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            enabled: { type: 'boolean', default: true },
            mode: {
              enum: ['point', 'nearest', 'index', 'dataset', 'x', 'y'],
              default: 'nearest',
              ui: UI.DROPDOWN,
            },
          },
        },
      },
    },
    pointRadius: { type: 'number', default: 4 },
    radius: { type: 'number', default: 0 },
    responsive: { type: 'boolean', default: false },
    scales: {
      additionalProperties: false,
      removeAdditional: true,
      default: {},
      properties: {
        x: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            ...commonAxisProperties,
            ...xAxisProperties,
          },
        },
        y: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            ...commonAxisProperties,
            ...yAxisProperties,
          },
        },
        r: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          properties: {
            ...commonAxisProperties,
            ...rAxisProperties,
          },
        },
      },
    },
    showLine: { type: 'boolean', default: false },
  },
};

export const chartTypes = {
  BAR: 'bar',
  BUBBLE: 'bubble',
  DOUGHNUT: 'doughnut',
  LINE: 'line',
  PIE: 'pie',
  POLAR: 'polar',
  RADAR: 'radar',
  SCATTER: 'scatter',
};

export const commonChartProperties = {
  chart_type: {
    type: 'string',
    enum: [
      chartTypes.BAR,
      chartTypes.BUBBLE,
      chartTypes.DOUGHNUT,
      chartTypes.LINE,
      chartTypes.PIE,
      chartTypes.POLAR,
      chartTypes.RADAR,
      chartTypes.SCATTER,
    ],
    default: chartTypes.BAR,
    ui: UI.DROPDOWN,
  },
  datasets: {
    default: [],
    type: 'array',
    items: { ...chartDataSet },
  },
  labels: {
    default: [],
    additionalProperties: true,
    type: 'array',
    items: {
      type: 'string',
      additionalProperties: true,
    },
  },
  options: chartOptions,
};

export const components = {
  title: 'Component',
  description: '{{type}} {{id}}',
  default: { id: 'component', type: '' },
  required: ['type'],
  properties: {
    ...commonComponentProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [componentTypes.ACCORDION]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'color'],
      default: { id: 'accordion', type: 'accordion' },
      properties: {
        ...commonComponentProperties,
        color: { ...color, default: '@color.primary' },
        outline: { type: 'boolean', default: true },
        items: { ...accordionItems, requiredGroup: true },
        data_source: { ...data_source, requiredGroup: true },
        data_content: { ...accordionItem, requiredGroup: true },
        filter,
        order,
        limit,
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.ALERT]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'color', 'text'],
      default: { id: 'alert', type: 'alert' },
      properties: {
        ...commonComponentProperties,
        color: { ...color, default: '@color.primary' },
        dismissible: { type: 'boolean', default: true },
        header: text,
        text,
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.BUTTON]: button,
    [componentTypes.CARD]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id'],
      default: { id: 'card', type: 'card' },
      properties: {
        ...commonComponentProperties,
        border: color,
        color,
        image: { type: 'string' },
        background: color,
        title,
        subtitle: title,
        text,
        content: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.CAROUSEL]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'slides', 'width'],
      default: { id: 'carousel', type: 'carousel' },
      properties: {
        ...commonComponentProperties,
        fade: { type: 'boolean', default: true },
        height: { ...height, default: 500 },
        interval: { type: 'number' },
        slides,
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.CHART]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'chart', 'height', 'width'],
      default: { id: 'chart', type: 'chart' },
      properties: {
        ...commonComponentProperties,
        chart: {
          default: {},
          required: ['chart_type', 'datasets', 'labels'],
          properties: {
            ...commonChartProperties,
          },
          select: { $data: '0/chart_type' },
          selectCases: {
            [chartTypes.BAR]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                  },
                },
              },
            },
            [chartTypes.BUBBLE]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                  },
                },
              },
            },
            [chartTypes.DOUGHNUT]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                    properties: {
                      ...chartDataSet.properties,
                      circumference: { type: 'number', default: 360 },
                      rotation: { type: 'number', default: 0 },
                    },
                  },
                },
              },
            },
            [chartTypes.LINE]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                    properties: {
                      ...chartDataSet.properties,
                      tension: { type: 'number', default: 0.5 },
                      borderDash: {
                        default: [],
                        additionalProperties: false,
                        removeAdditional: true,
                        type: 'array',
                        items: {
                          additionalProperties: true,
                          default: '',
                          type: 'number',
                        },
                      },
                      cubicInterpolationMode: {
                        enum: ['monotone'],
                        default: 'monotone',
                      },
                    },
                  },
                },
              },
            },
            [chartTypes.PIE]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                    properties: {
                      ...chartDataSet.properties,
                      circumference: { type: 'number', default: 360 },
                      rotation: { type: 'number', default: 0 },
                    },
                  },
                },
              },
            },
            [chartTypes.POLAR]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                  },
                },
              },
            },
            [chartTypes.RADAR]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                  },
                },
              },
            },
            [chartTypes.SCATTER]: {
              additionalProperties: false,
              removeAdditional: true,
              properties: {
                ...commonChartProperties,
                datasets: {
                  default: [],
                  type: 'array',
                  items: {
                    ...chartDataSet,
                  },
                },
              },
            },
          },
        },
        data_source,
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.CHECKBOX]: checkbox,
    [componentTypes.COLORPICKER]: colorPicker,
    [componentTypes.DATEPICKER]: datePicker,
    [componentTypes.DROPDOWN]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'toggler'],
      default: { id: 'dropdown', type: 'dropdown' },
      properties: {
        ...commonComponentProperties,
        menu_align: {
          enum: ['start', 'end'],
          default: 'start',
          ui: UI.DROPDOWN,
        },
        arrow: { type: 'boolean', default: false },
        color,
        content: { default: [] },
        drop: {
          enum: ['up', 'down', 'start', 'end'],
          default: 'down',
          ui: UI.DROPDOWN,
        },
        menu: { type: 'string' },
        outline: { type: 'boolean', default: true },
        size,
        toggler: {
          additionalProperties: false,
          removeAdditional: true,
          default: {
            title: { height: 'wrap', width: 'wrap', text: 'Dropdown' },
          },
          properties: {
            icon: {
              title: 'DropdownIcon',
              description: '{{type}} {{id}}',
              default: { type: 'icon', icon: 'home' },
              required: ['type'],
              properties: {
                ...commonComponentProperties,
                type: {
                  enum: [componentTypes.ICON, componentTypes.IMAGE],
                  ui: UI.DROPDOWN,
                },
              },
              select: { $data: '0/type' },
              selectCases: {
                [componentTypes.ICON]: {
                  ...componentIcon,
                  required: ['icon'],
                  default: { icon: 'home' },
                  properties: {
                    ...componentIcon.properties,
                    id: { default: '', readOnly: true },
                    type: {
                      default: 'icon',
                      enum: [componentTypes.ICON, componentTypes.IMAGE],
                      ui: UI.DROPDOWN,
                    },
                  },
                },
                [componentTypes.IMAGE]: {
                  ...componentImage,
                  required: ['height', 'width'],
                  properties: {
                    ...componentImage.properties,
                    id: { default: '', readOnly: true },
                    type: {
                      default: 'image',
                      enum: [componentTypes.ICON, componentTypes.IMAGE],
                      ui: UI.DROPDOWN,
                    },
                    width: { ...width, default: 40 },
                  },
                },
              },
            },
            title: {
              ...componentText,
              required: ['height', 'width'],
              default: {
                type: 'text',
                height: 'wrap',
                width: 'wrap',
                text: 'Dropdown',
              },
              properties: {
                ...componentText.properties,
                id: { default: '', readOnly: true },
                type: { default: 'text', readOnly: true },
                width: { ...width, default: 'wrap' },
              },
            },
          },
        },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.FORM]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'action', 'form_groups'],
      default: { id: 'form', type: 'form' },
      properties: {
        ...commonComponentProperties,
        action: { type: 'string', default: 'https://target-url' },
        form_groups: { default: [] },
        submit_button: {
          ...button,
          required: ['color', 'text'],
          properties: {
            ...button.properties,
            text: { ...button.properties.text, default: 'Submit' },
          },
        },
        onsubmit: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onsubmit: { default: [] },
        },
      },
    },
    [componentTypes.GROUP]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'content', 'height', 'orientation', 'scroll', 'width'],
      default: { id: 'group', type: 'group' },
      properties: {
        ...commonComponentProperties,
        scroll: { default: false, type: 'boolean' },
        orientation,
        container: { default: true, type: 'boolean' },
        content: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.ICON]: componentIcon,
    [componentTypes.IMAGE]: componentImage,
    [componentTypes.INPUTFILE]: inputFile,
    [componentTypes.INPUTTEXT]: inputText,
    [componentTypes.NAV]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'menu'],
      default: { id: 'nav', type: 'nav' },
      properties: {
        ...commonComponentProperties,
        color,
        menu: { type: 'string' },
        menu_style: {
          default: 'title',
          enum: ['icon', 'title', 'icon_title'],
          ui: UI.DROPDOWN,
        },
        menu_type: {
          default: 'pills',
          enum: ['pills', 'tabs'],
          ui: UI.DROPDOWN,
        },
        orientation,
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.LIST]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'content', 'data_source', 'height', 'width'],
      default: { id: 'list', type: 'list' },
      properties: {
        ...commonComponentProperties,
        content: { default: [] },
        columns: {
          default: 2,
          oneOf: [{ enum: ['auto'] }, { type: 'number', minimum: 1 }],
        },
        data_source,
        filter,
        order,
        limit,
        orientation,
        selectable: { type: 'boolean', default: true },
        selection_background: { ...color, default: '@color.primaryLight' },
        sticky: { default: true, type: 'boolean' },
      },
      parameters: [
        ...commonComponentParameters,
        'count',
        'index',
        'selected',
        'value',
      ],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.LOTTIE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'lottie', type: 'lottie' },
      properties: {
        ...commonComponentProperties,
        autoplay: { type: 'boolean', default: true },
        field: { type: 'string' },
        file: { ...file, requiredGroup: true, key: ['json', 'zip'] },
        loop: { type: 'boolean', default: true },
        repeat_count: { default: 1, type: 'number', minimum: 1, ui: UI.RANGE },
        repeat_mode: {
          type: 'string',
          default: 'restart',
          enum: ['restart', 'reverse'],
          ui: UI.DROPDOWN,
        },
        scale,
        source: { ...source, requiredGroup: true },
        speed: {
          default: 1,
          type: 'number',
          minimum: 0.1,
          maximum: 10,
          ui: UI.RANGE,
        },
        onfinished: { default: [] },
      },
      parameters: [...commonComponentParameters, 'progress', 'play', 'speed'],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.PDFVIEWER]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['file', 'id', 'height', 'width'],
      default: { id: 'pdfviewer', type: 'pdfviewer' },
      properties: {
        ...commonComponentProperties,
        file: multiLanguagePdf,
        height: { ...height, default: 'fill' },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.PROGRESSBAR]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'source'],
      default: { id: 'progress_bar', type: 'progress_bar' },
      properties: {
        ...commonComponentProperties,
        animated: { type: 'boolean', default: true },
        color,
        default_value: { default: 0, type: 'number', ui: UI.RANGE },
        label,
        source,
        striped: { type: 'boolean', default: true },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.RADIO]: radio,
    [componentTypes.RANGE]: range,
    [componentTypes.SELECT]: select,
    [componentTypes.STRIPEPRICINGTABLE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'pricing_table_id', 'publishable_key', 'width'],
      default: { id: 'stripe_pricing_table', type: 'stripe_pricing_table' },
      properties: {
        ...commonComponentProperties,
        pricing_table_id: { type: 'string' },
        publishable_key: { type: 'string' },
        client_reference_id: { type: 'string' },
        customer_email: { type: 'string' },
      },
      parameters: [...commonComponentParameters],
      events: {},
    },
    [componentTypes.SWITCH]: componentSwitch,
    [componentTypes.TABLE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'data_source'],
      default: { id: 'table', type: 'table' },
      properties: {
        ...commonComponentProperties,
        bordered: { type: 'boolean', default: true },
        dark: { type: 'boolean', default: true },
        header: {
          default: [],
          additionalProperties: true,
          type: 'array',
          items: {
            type: 'string',
            additionalProperties: true,
          },
        },
        hover: { type: 'boolean', default: true },
        indexed: { type: 'boolean', default: true },
        size,
        data_source,
        filter,
        order,
        limit,
        striped: { type: 'boolean', default: true },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.TEXT]: componentText,
    [componentTypes.TIMELINE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'data_source'],
      default: { id: 'timeline', type: 'timeline' },
      properties: {
        ...commonComponentProperties,
        content: { default: [] },
        data_source,
        filter,
        order,
        limit,
        fields: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          required: [],
          properties: {
            color: { type: 'string', key: 'none' },
            date: { type: 'string' },
            title: { type: 'string' },
            text: { type: 'string' },
          },
        },
        orientation: {
          ...orientation,
          enum: [orientationTypes.HORIZONTAL, orientationTypes.VERTICAL],
        },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.VIDEO]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'video', type: 'video' },
      properties: {
        ...commonComponentProperties,
        autoplay: { type: 'boolean', default: true },
        controls: { type: 'boolean', default: true },
        field: { type: 'string' },
        file: { ...multiLanguageVideo, requiredGroup: true },
        height: { ...height, default: 300 },
        loop: { type: 'boolean', default: true },
        muted: { type: 'boolean', default: true },
        scale,
        source: { ...source, requiredGroup: true },
        onfinished: { default: [] },
        onready: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onfinished: { default: [] },
          onready: { default: [] },
        },
      },
    },
    [componentTypes.WEB]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'web', type: 'web' },
      properties: {
        ...commonComponentProperties,
        url: multilanguageUrl,
        height: { ...height, default: 'fill' },
        width: { ...width, default: 'fill' },
        zoom: { type: 'boolean', default: true },
        file: { ...file, key: 'zip' },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.YOUTUBE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width', 'video_id'],
      default: { id: 'youtube', type: 'youtube' },
      properties: {
        ...commonComponentProperties,
        video_id: { ...multiLanguageText, default: 'PniHTw479uA' },
        allow_fullscreen: { type: 'boolean', default: true },
        autoplay: { type: 'boolean', default: true },
        controls: { enum: ['default', 'minimal', 'none'] },
        onerror: { default: [] },
        onfinished: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onerror: { default: [] },
          onfinished: { default: [] },
        },
      },
    },
  },
};

const commonFormComponentProperties = {
  ...commonComponentProperties,
  type: {
    enum: [
      componentTypes.BUTTON,
      componentTypes.CHECKBOX,
      componentTypes.COLORPICKER,
      componentTypes.INPUTFILE,
      componentTypes.INPUTTEXT,
      componentTypes.RADIO,
      componentTypes.RANGE,
      componentTypes.SELECT,
      componentTypes.SWITCH,
    ],
    ui: UI.DROPDOWN,
  },
};

const formComponents = {
  ...components,
  properties: {
    ...commonFormComponentProperties,
  },
  selectCases: {
    [componentTypes.BUTTON]: {
      ...button,
      properties: {
        ...button.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.CHECKBOX]: {
      ...checkbox,
      properties: {
        ...checkbox.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.COLORPICKER]: {
      ...colorPicker,
      properties: {
        ...colorPicker.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.INPUTFILE]: {
      ...inputFile,
      properties: {
        ...inputFile.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.INPUTTEXT]: {
      ...inputText,
      properties: {
        ...inputText.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.RADIO]: {
      ...radio,
      properties: {
        ...radio.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.RANGE]: {
      ...range,
      properties: {
        ...range.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.SELECT]: {
      ...select,
      properties: {
        ...select.properties,
        ...commonFormComponentProperties,
      },
    },
    [componentTypes.SWITCH]: {
      ...checkbox,
      properties: {
        ...checkbox.properties,
        ...commonFormComponentProperties,
      },
    },
  },
};

export const form_groups = {
  title: 'FormGroups',
  default: [
    {
      type: componentTypes.INPUTTEXT,
      format: 'email',
      label: 'E-mail',
      name: 'email',
      placeholder: 'email@doyo.email',
    },
    {
      type: componentTypes.PASSWORD,
      format: 'password',
      label: 'Password',
      name: 'password',
      placeholder: 'Password',
    },
  ],
  type: 'array',
  items: { ...formComponents },
};

export const content = {
  title: 'Content',
  default: [],
  type: 'array',
  uniqueItemProperties: ['id'],
  items: { ...components },
  removeNull: true,
};

export const buttons = {
  title: 'Buttons',
  default: {},
  type: 'object',
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    custom: {
      default: [],
      items: {
        ...button,
        required: [...button.required, 'type'],
        properties: {
          ...button.properties,
          type: { default: 'button', readOnly: true },
        },
      },
    },
    negative: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        label,
        onclick: { default: [] },
      },
    },
    positive: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        label,
        onclick: { default: [] },
      },
    },
  },
};

export const dialogTypes = {
  DRAWER: 'drawer',
  MODAL: 'modal',
  TOAST: 'toast',
};

const commonDialogProperties = {
  id: { pattern: '^[a-z]+[_a-z0-9]*$' },
  title,
  text,
  type: {
    default: 'modal',
    enum: [dialogTypes.DRAWER, dialogTypes.MODAL, dialogTypes.TOAST],
    ui: UI.DROPDOWN,
  },
  events: {
    ...events,
    properties: { onloaded },
  },
};

export const dialogs = {
  title: 'Dialog',
  description: 'Each dialog of the app',
  default: { id: 'dialog', type: '' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['type'],
  properties: {
    ...commonDialogProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [dialogTypes.DRAWER]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'content'],
      properties: {
        ...commonDialogProperties,
        backdrop: { type: 'boolean', default: false },
        closable: { type: 'boolean', default: false },
        content,
        placement: {
          default: 'start',
          enum: ['start', 'end', 'top', 'bottom'],
          ui: UI.DROPDOWN,
        },
        scroll: { type: 'boolean', default: true },
      },
    },
    [dialogTypes.MODAL]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'buttons', 'content'],
      properties: {
        ...commonDialogProperties,
        animation: { type: 'boolean', default: false },
        backdrop: { type: 'boolean', default: false },
        buttons,
        centered: { type: 'boolean', default: true },
        closable: { type: 'boolean', default: false },
        content,
        fullscreen: {
          enum: [
            'true',
            'sm-down',
            'md-down',
            'lg-down',
            'xl-down',
            'xxl-down',
          ],
          default: 'true',
          ui: UI.DROPDOWN,
        },
        size: {
          enum: ['small', 'medium', 'large'],
          default: 'large',
          ui: UI.DROPDOWN,
        },
      },
    },
    [dialogTypes.TOAST]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id'],
      properties: {
        ...commonDialogProperties,
        animation: { type: 'boolean', default: false },
        autohide: { type: 'boolean', default: true },
        delay: { type: 'number', default: 5 },
        icon,
        placement: {
          default: 'top-end',
          enum: [
            'top-start',
            'top-center',
            'top-end',
            'bottom-start',
            'bottom-center',
            'bottom-end',
          ],
          ui: UI.DROPDOWN,
        },
      },
    },
  },
};

export const header = {
  default: {},
  required: ['content'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    background_color: { ...color, default: '@color.white' },
    bordered: { type: 'boolean', default: true },
    content,
    responsive: { type: 'boolean', default: true },
  },
};

export const footer = {
  default: {},
  required: ['content'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    background_color: { ...color, default: '@color.white' },
    bordered: { type: 'boolean', default: true },
    content,
  },
};

export const badge = {
  default: {},
  required: ['source'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    source,
    filter,
  },
};

export const menuItems = {
  title: 'Menu Item',
  description: '{{title}}',
  default: { id: 'menu' },
  required: ['id', 'title'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    id,
    title,
    icon,
    badge,
    onclick: { default: [] },
    items: { default: [] },
  },
};

export const items = {
  title: 'Items',
  default: [],
  type: 'array',
  uniqueItemProperties: ['id'],
  items: {
    ...menuItems,
  },
};

export const menus = {
  title: 'Menu',
  description: 'Each menu of the app',
  default: { id: 'menu' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'items'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    items,
  },
};

export const actionbar = {
  default: {},
  required: [],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    background_color: { ...color, default: '@color.primary' },
    bordered: { type: 'boolean', default: true },
    content,
    content_responsive: { type: 'boolean', default: false },
    logo: icon,
    menu: { type: 'string' },
    menu_responsive: { type: 'boolean', default: false },
    width: { default: 'fill', enum: ['centered', 'fill'], ui: UI.DROPDOWN },
  },
};

export const react = {
  default: {},
  required: ['version'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    version: { type: 'string', default: '1.0.0' },
  },
};

export const sidebar = {
  default: {},
  required: [],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    background_color: { ...color, default: '@color.light' },
    bordered: { type: 'boolean', default: true },
    content,
    logo: {
      ...componentImage,
      default: {
        type: 'image',
        height: 'wrap',
        width: '100%',
        file: '',
      },
      required: ['height', 'width'],
      properties: {
        ...componentImage.properties,
        id: { default: '', readOnly: true },
        type: { default: 'image', readOnly: true },
      },
    },
    menu: { type: 'string' },
    menu_style: {
      default: 'icons',
      enum: ['icon', 'title', 'icon_title'],
      ui: UI.DROPDOWN,
    },
    menu_type: {
      default: 'pills',
      enum: ['pills', 'tabs'],
      ui: UI.DROPDOWN,
    },
    width: {
      ...width,
      default: 280,
      oneOf: [
        { enum: ['wrap'] },
        { pattern: '[0-9]+%' },
        { pattern: '[0-9]+px' },
        { pattern: '[0-9]+rem' },
        { pattern: '[0-9]+vw' },
        { type: 'number' },
      ],
    },
  },
};

export const view_wrappers = {
  title: 'ViewWrapper',
  description: 'Each wrapper of the app',
  default: { id: 'wrapper' },
  additionalProperties: false,

  removeAdditional: true,
  required: ['id'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    actionbar,
    header,
    sidebar,
    footer,
  },
};

export const views = {
  title: 'View',
  description: 'Each page of the app',
  default: { id: 'view' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'title', 'wrapper', 'content'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    icon,
    title,
    description: title,
    wrapper: { type: 'string', key: 'wrapper' },
    events: {
      ...events,
      properties: { onexit, onloaded },
    },
    history: { type: 'boolean', default: true },
    content: { default: [] },
    private: { type: 'boolean', default: true },
  },
};

export default {
  and,
  buttons,
  or,
  background,
  components,
  content,
  database,
  databases,
  dialogs,
  else: _else,
  events,
  form_groups,
  function: _function,
  icon,
  items,
  margin,
  menus,
  padding,
  react,
  redirects,
  scripts,
  server,
  view_wrappers,
  views,
};
