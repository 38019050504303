import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Component from './Component';
import { getLanguage, isObject } from '../../../_helpers';
import { useClasses, useColor } from '../../hooks';

const paddingMap = {
  top: 'top',
  end: 'right',
  bottom: 'bottom',
  start: 'left',
};

export const SelectCompiler = ({
  className = '',
  dropdown_font: dropdownFont,
  font,
  font_size: fontSize = 10,
  color: colorProp = '@color.primaryText',
  dropdown_background: dropdownBackgroundProp = '#ffffff',
  dropdown_color: dropdownColorProp = '@color.primaryText',
  dropdown_font_size: dropdownFontSize,
  dropdown_padding: dropdownPadding,
  padding,
  source,
  ...props
}) => {
  const { id } = props;
  const { addClass, style } = useClasses(id);
  const { hex: color = '' } = useColor(colorProp);
  const { hex: dropdownBackground = dropdownBackgroundProp } = useColor(
    dropdownBackgroundProp
  );
  const { hex: dropdownColor = dropdownColorProp } =
    useColor(dropdownColorProp);
  let classes = {};
  let dropdownClasses = {};

  // font
  let ff;
  if (font) {
    ff = { [`ff-${font.replace('@font.', '')}`]: '' };
    classes = { ...classes, ...ff };
  }

  // font_size
  let f;
  if (typeof fontSize !== 'undefined') {
    if (typeof fontSize === 'number') {
      f = { [`f-${fontSize}px`]: `font-size: ${fontSize}px` };
    } else if (fontSize?.match(/%$/)) {
      f = { [`f-${fontSize.replace('%', '')}`]: `font-size: ${fontSize}` };
    } else if (fontSize?.match(/rem$/)) {
      f = { [`f-${fontSize.replace('.', '_')}`]: `font-size: ${fontSize}` };
    }
    classes = { ...classes, ...f };
  }

  // dropdown_font
  let ddff;
  if (dropdownFont) {
    ddff = { [`ff-${dropdownFont.replace('@font.', '')}`]: '' };
    classes = { ...classes, ...ddff };
  }

  // dropdown_font_size
  let ddf;
  if (typeof dropdownFontSize !== 'undefined') {
    if (typeof dropdownFontSize === 'number') {
      ddf = { [`f-${dropdownFontSize}px`]: `font-size: ${dropdownFontSize}px` };
    } else if (dropdownFontSize?.match(/%$/)) {
      ddf = {
        [`f-${dropdownFontSize.replace(
          '%',
          ''
        )}`]: `font-size: ${dropdownFontSize}`,
      };
    } else if (dropdownFontSize?.match(/rem$/)) {
      ddf = {
        [`f-${dropdownFontSize.replace(
          '.',
          '_'
        )}`]: `font-size: ${dropdownFontSize}`,
      };
    }
    dropdownClasses = { ...dropdownClasses, ...ddf };
  }

  // dropdownBackground
  let ddb;
  if (dropdownBackground) {
    ddb = {
      [`bg-${dropdownBackground.replace(
        '#',
        ''
      )}`]: `background-color: ${dropdownBackground}`,
    };
    dropdownClasses = { ...dropdownClasses, ...ddb };
  }

  // dropdownColor
  let ddc;
  if (dropdownColor) {
    ddc = {
      [`text-${dropdownColor.replace('#', '')}`]: `color: ${dropdownColor}`,
    };
    dropdownClasses = { ...dropdownClasses, ...ddc };
  }

  // dropdownPadding
  let ddp;
  if (typeof dropdownPadding !== 'undefined') {
    ddp = {};
    if (typeof dropdownPadding === 'object') {
      const { bottom, end, start, top } = dropdownPadding;
      if (top === bottom) {
        if (typeof top === 'number') {
          ddp[
            `py-${top}px`
          ] = `padding-top:${top}px;padding-bottom:${bottom}px`;
        } else if (top?.match(/%$/)) {
          ddp[
            `py-${top.replace('%', '')}`
          ] = `padding-top:${top};padding-bottom:${bottom}`;
        }
      }
      if (start === end) {
        if (typeof start === 'number') {
          ddp[
            `px-${bottom}px`
          ] = `padding-left:${start}px;padding-right:${end}px`;
        } else if (start?.match(/%$/)) {
          ddp[
            `px-${bottom.replace('%', '')}`
          ] = `padding-left:${start};padding-right:${end}`;
        }
      }
      Object.keys(dropdownPadding).forEach((key) => {
        if (typeof dropdownPadding[key] === 'number') {
          ddp[
            `p${key[0]}-${dropdownPadding[key]}px`
          ] = `padding-${paddingMap[key]}:${dropdownPadding[key]}px`;
        } else if (dropdownPadding[key]?.match(/%$/)) {
          ddp[
            `p${key[0]}-${dropdownPadding[key].replace('%', '')}`
          ] = `padding-${paddingMap[key]}:${dropdownPadding[key]}`;
        }
      });
    } else if (typeof dropdownPadding === 'number') {
      ddp[`p-${dropdownPadding}px`] = `padding:${dropdownPadding}px`;
    } else if (dropdownPadding?.match(/%$/)) {
      ddp[
        `p-${dropdownPadding.replace('%', '')}`
      ] = `padding: ${dropdownPadding}`;
    }
    dropdownClasses = { ...dropdownClasses, ...ddp };
  }

  useEffect(() => {
    addClass({ ff });
  }, [font]);

  useEffect(() => {
    addClass({ f });
  }, [fontSize]);

  useEffect(() => {
    addClass({ ddff });
  }, [dropdownFont]);

  useEffect(() => {
    addClass({ ddf });
  }, [dropdownFontSize]);

  useEffect(() => {
    addClass({ ddb });
  }, [dropdownBackground]);

  useEffect(() => {
    addClass({ ddc });
  }, [dropdownColor]);

  useEffect(() => {
    addClass({ ddp });
  }, [dropdownPadding]);

  return (
    <>
      <style>{style}</style>
      <Select
        {...props}
        className={`${className} ${Object.keys(classes).join(' ')}`.trim()}
        classNameDropdown={Object.keys(dropdownClasses).join(' ').trim()}
        color={color}
      />
    </>
  );
};

export const Select = ({
  className = '',
  classNameDropdown = '',
  color,
  options = [],
  mode,
  source = 'Select',
  ...props
}) => {
  const language = getLanguage();
  let { id } = props;

  return (
    <Component
      {...props}
      data-mode={mode}
      className={className}
      component={DropdownButton}
      style={{ color }}
      title={options?.[0]?.label || source}
    >
      <div className={classNameDropdown}>
        {options.map(({ label, value }) => {
          let labelToShow = label;
          if (isObject(labelToShow)) {
            labelToShow =
              typeof labelToShow[language] !== 'undefined'
                ? labelToShow[language]
                : labelToShow.default;
          }
          return (
            <Dropdown.Item key={`Select${id}Option${value}`}>
              {labelToShow}
            </Dropdown.Item>
          );
        })}
      </div>
    </Component>
  );
};

export default Select;
