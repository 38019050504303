import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiInformationVariant } from '@mdi/js';
import { Button, Form, FormGroup, Input, Table, Tooltip } from 'antd';
import countries from 'i18n-iso-countries';
import { scrollToInvalid } from '../../_helpers';
import { ViewerContext } from '../../context';

const initializeLanguage = (lng) => {
  const language = lng.split('-')[0];
  countries.registerLocale(
    require(`i18n-iso-countries/langs/${language}.json`)
  );
};

export const CustomerForm = ({ reduced = false }) => {
  const { i18n, t } = useTranslation();
  const { account = {} } = useContext(ViewerContext);
  const { customer = {} } = account;
  const { address, city, country, name, legal_id, postal_code, region } =
    customer;
  const [attributes, setAttributes] = useState({
    name: name || '',
    legal_id: legal_id || '',
    address: address || '',
    postal_code: postal_code || '',
    city: city || '',
    region: region || '',
    country: country || '',
  });
  const coupon = {};
  const plan = {};
  //const [retry, setRetry] = useState(false);
  const location = {};
  const { sources = [] } = account;
  //const source =
  sources.find((source) => source.default); //|| {};
  const language = i18n.language ? i18n.language.split('-')[0] : 'en';
  const countryList = countries && language ? countries.getNames(language) : {};
  const countryWhiteList =
    location.country !== country
      ? [location.country, country]
      : [attributes.country];

  //!location.country && dispatch(generalActions.location());

  i18n.on('languageChanged', initializeLanguage);
  i18n && i18n.language && initializeLanguage(i18n.language);

  /*const handleCustomerSuccessChange = (state) => {
        const { accounts } = state;
        const { all: allAccounts } = accounts;
        const account = allAccounts.find((account) => account.current) || {};
        const { customer = {} } = account;
        const { address, city, country, name, legal_id, postal_code, region } = customer;
        const { attributes } = state;
        setAttributes({
            name: name || attributes.name,
            legal_id: legal_id || attributes.legal_id,
            address: address || attributes.address,
            postal_code: postal_code || attributes.postal_code,
            city: city || attributes.city,
            region: region || attributes.region,
            country: country || attributes.country,
        });
    };

    const handleCustomerErrorChange = (state) => {
        const { accounts } = state;
        const { done, error } = accounts;
        const { detail = [] } = error || {};
        const customerForm = document.forms.CustomerForm;

        Object.keys(detail).forEach((key) => {
            const validate = customerForm[key].dataset.validate;
            if (validate !== 'false') {
                customerForm[key].setCustomValidity(detail[key]);
            }
            customerForm[key].dataset.validate = false;
        });

        if (customerForm.reportValidity() === false) {
            scrollToInvalid(customerForm);
        }
        if (done && retry) {
            handleSubmit();
            setRetry(false);
        }
    };*/

  const handleChange = (event) => {
    const target = event.currentTarget;
    const { name } = target.attributes;
    let { value } = target.attributes;

    value = !(typeof target.value === 'undefined')
      ? target.value
      : value.nodeValue;
    target.dataset.validate === 'false' && delete target.dataset.validate;
    target.setCustomValidity && target.setCustomValidity('');

    setAttributes({
      ...attributes,
      [name.nodeValue]: value,
    });
  };

  const handleCountryChange = (event) => {
    handleChange(event);
    if (!window.location.pathname.endsWith('/checkout')) {
      return;
    }

    const previewAttributes = {
      pending_plan_id: plan.id,
      proration_date: new Date(Date.now()).toISOString().replace(/\.[\d]*/, ''),
    };
    if (coupon) {
      previewAttributes.coupon = coupon;
    }

    //dispatch(purchasesActions.preview(account, previewAttributes));
  };

  const handleSubmit = (event) => {
    event && event.preventDefault();

    const customerForm = document.forms.CustomerForm;

    if (customerForm.reportValidity() === false) {
      scrollToInvalid(customerForm);
      return;
    }

    //dispatch(accountsActions.updateCustomer(account, attributes)).then(() => callback());
  };

  return (
    <Form id="CustomerForm" onSubmit={handleSubmit}>
      <Table className="mb-0">
        <tbody>
          {!reduced && (
            <tr>
              <td className="align-middle">
                <span>{t('customer.company_name')}</span>
                <Tooltip title={t('customer.company_name_description')}>
                  <Icon
                    path={mdiInformationVariant}
                    id="CompanyNameInfo"
                    className="icon text-info"
                  />
                </Tooltip>
              </td>
              <td className="align-middle">
                <Input
                  name="name"
                  aria-describedby="company_nameHelp"
                  placeholder={t('customer.company_name_placeholder')}
                  onChange={handleChange}
                  value={attributes.name}
                  maxLength="100"
                />
              </td>
            </tr>
          )}
          {!reduced && (
            <tr>
              <td className="align-middle">
                <span>{t('customer.legal_id')}</span>
                <Tooltip title={t('customer.legal_id_description')}>
                  <Icon
                    path={mdiInformationVariant}
                    id="LegalIdInfo"
                    className="icon text-info"
                  />
                </Tooltip>
              </td>
              <td className="align-middle">
                <Input
                  name="legal_id"
                  aria-describedby="legal_idHelp"
                  placeholder={t('customer.legal_id_placeholder')}
                  onChange={handleChange}
                  value={attributes.legal_id}
                  maxLength="20"
                />
              </td>
            </tr>
          )}

          <tr>
            <td className="align-middle">
              <span>{t('customer.address')}</span>
              <Tooltip title={t('customer.address_description')}>
                <Icon
                  path={mdiInformationVariant}
                  id="AddressInfo"
                  className="icon text-info"
                />
              </Tooltip>
            </td>
            <td className="align-middle">
              <Input
                name="address"
                aria-describedby="addressHelp"
                placeholder={t('customer.address_placeholder')}
                onChange={handleChange}
                value={attributes.address}
                maxLength="100"
                required
              />
            </td>
          </tr>

          <tr>
            <td className="align-middle">
              <span>{t('customer.postal_code')}</span>
              <Tooltip title={t('customer.postal_code_description')}>
                <Icon
                  path={mdiInformationVariant}
                  id="PostalCodeInfo"
                  className="icon text-info"
                />
              </Tooltip>
            </td>
            <td className="align-middle">
              <Input
                name="postal_code"
                aria-describedby="postal_codeHelp"
                placeholder={t('customer.postal_code_placeholder')}
                onChange={handleChange}
                value={attributes.postal_code}
                maxLength="10"
                required
              />
            </td>
          </tr>

          <tr>
            <td className="align-middle">
              <span>{t('customer.city')}</span>
              <Tooltip title={t('customer.city_description')}>
                <Icon
                  path={mdiInformationVariant}
                  id="CityInfo"
                  className="icon text-info"
                />
              </Tooltip>
            </td>
            <td className="align-middle">
              <Input
                name="city"
                aria-describedby="cityHelp"
                placeholder={t('customer.city_placeholder')}
                onChange={handleChange}
                value={attributes.city}
                maxLength="58"
                required
              />
            </td>
          </tr>

          <tr>
            <td className="align-middle">
              <span>{t('customer.region')}</span>
              <Tooltip title={t('customer.region_description')}>
                <Icon
                  path={mdiInformationVariant}
                  id="RegionInfo"
                  className="icon text-info"
                />
              </Tooltip>
            </td>
            <td className="align-middle">
              <Input
                name="region"
                aria-describedby="regionHelp"
                placeholder={t('customer.region_placeholder')}
                onChange={handleChange}
                value={attributes.region}
                maxLength="58"
                required
              />
            </td>
          </tr>

          <tr>
            <td className="align-middle">
              <span>{t('customer.country')}</span>
              <Tooltip title={t('customer.country_description')}>
                <Icon
                  path={mdiInformationVariant}
                  id="CountryInfo"
                  className="icon text-info"
                />
              </Tooltip>
            </td>
            <td className="align-middle">
              <Input
                type="select"
                name="country"
                value={attributes.country}
                onChange={handleCountryChange}
                required
              >
                <option disabled value="">
                  {t('customer.select_country')}
                </option>
                {Object.keys(countryList)
                  .filter((iso) => countryWhiteList.includes(iso))
                  .map((iso) => (
                    <option key={iso} value={iso}>
                      {countryList[iso]}
                    </option>
                  ))}
              </Input>
            </td>
          </tr>
        </tbody>
      </Table>

      <hr className="mt-0 mb-5" />

      <Form.Item>
        <Button
          id="CustomerSubmitButton"
          color="primary"
          className="position-relative"
          onClick={handleSubmit}
        >
          {t('common.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CustomerForm;
