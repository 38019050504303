import React, { createContext, useState } from 'react';

export const ComponentContext = createContext({});

export const ComponentProvider = ({
  children,
  data,
  handleJsonChange,
  getProjectRefs,
  projectType,
}) => {
  const [currentComponent, setCurrentComponent] = useState();

  if (process.env.NODE_ENV === 'development') {
    console.log('COMPONENT >>>', currentComponent);
  }

  return (
    <ComponentContext.Provider
      value={{
        currentComponent,
        setCurrentComponent,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const ComponentConsumer = ComponentContext.Consumer;
export default ComponentContext;
