import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactIcon from '@mdi/react';
import {
  mdiAt,
  mdiImageSizeSelectLarge,
  mdiPhoneRotateLandscape,
  mdiPhoneRotatePortrait,
} from '@mdi/js';
import {
  Button as AntButton,
  Dropdown as AntDropdown,
  Input as AntInput,
  Menu as AntMenu,
  Popover,
  Space,
  Tooltip,
} from 'antd';
import { PAGES } from '../_config';
import { camelToKebabCase, getRgba } from '../_helpers';
import { DraggableModal } from '../components';
import { PREVIEW, RESOLUTION } from '../context/reducers';
import { useComponents, useEvents, useProjects, useViewer } from '../hooks';
import { ContextProviders } from './context';
import { useFile, useProject } from './hooks';
import Flow from './flow';
import Web from './web';
import Mobile from './mobile';
import { projectTypes } from '../schemas/project';

export const PreviewContent = ({ currentId, project: projectToPreview }) => {
  const { data = {} } = projectToPreview;
  const { app = {} } = data;
  const { type } = app;
  const { get, setProject } = useProject();
  const json = get(currentId);

  useEffect(() => {
    setProject(projectToPreview);
  }, [projectToPreview]);

  switch (type) {
    case projectTypes.FLOW:
      return <Flow json={json} />;

    case projectTypes.MOBILE_APP:
      return <Mobile json={json} />;

    case projectTypes.WEB:
      return <Web json={json} />;

    default:
      return null;
  }
};

const Colors = ({ colors }) => {
  return (
    <style>
      {`main.preview{${Object.entries(colors)
        .filter(([, value]) => value)
        .map(([key, value]) => {
          const kebabKey = camelToKebabCase(key);
          let hex = value;
          let hexNoHashtag = hex?.replace('#', '');
          hexNoHashtag =
            hexNoHashtag?.length % 4 === 0
              ? hexNoHashtag.substr(2) + hexNoHashtag.substr(0, 2)
              : hexNoHashtag;
          hex = hex && `#${hexNoHashtag}`;
          return `--${kebabKey}: ${hex};
                  --bs-${kebabKey}: ${hex};
                  --bs-${kebabKey}-rgb: ${getRgba(hex)
            .replace('rgba(', '')
            .replace(', 1)', '')};`;
        })
        .join(';')}}
      .form-control:focus {
          box-shadow: 0 0 0 0.2rem ${getRgba(colors.primary, 25)};
      }
      .progress-bar {
        background-color: var(--primary);
      }
    ${Object.entries(colors)
      .filter(([, value]) => value)
      .map(([key, value]) => {
        const kebabKey = camelToKebabCase(key);
        let hex = value;
        let hexNoHashtag = hex?.replace('#', '');
        hexNoHashtag =
          hexNoHashtag?.length % 4 === 0
            ? hexNoHashtag.substr(2) + hexNoHashtag.substr(0, 2)
            : hexNoHashtag;
        hex = hex && `#${hexNoHashtag}`;
        return `.border-${kebabKey}{border-color:${hex} !important}`;
      })
      .join('')}`}
    </style>
  );
};

const FontFace = ({ name, font }) => {
  const { url } = useFile(font);
  return `
    @font-face {
      font-family: ${name};
      src: url('${url}');
    }
    .ff-${name} {
      font-family: ${name};
    }
  `;
};

const FontsFace = ({ fonts }) => {
  return (
    <style>
      {Object.keys(fonts).map((key, index) => (
        <FontFace key={`Font-${index}`} name={key} font={fonts[key]} />
      ))}
    </style>
  );
};

export const Preview = ({ className, ...props }) => {
  const { project = {} } = props;
  const { data = {} } = project;
  const { app = {} } = data;
  const { colors = {}, fonts = {} } = app;

  return (
    <ContextProviders project={project} className={className}>
      <FontsFace fonts={fonts} />
      <Colors colors={colors} />
      <PreviewContent {...props} />
    </ContextProviders>
  );
};

export const ReferenceForm = ({ refs }) => {
  const { project = {}, setReference } = useProjects();
  const { refs: projectRefs = {} } = project;

  const handleChange = (event) => {
    const { target = {} } = event;
    const { name, value = '' } = target;
    setReference({ [name]: value });
  };
  return (
    <Space direction="vertical">
      {refs.map((ref) => (
        <AntInput
          key={`Ref-${ref}`}
          addonBefore={ref}
          defaultValue={projectRefs[ref]}
          name={ref}
          onPressEnter={(event) => event.target.blur()}
          onBlur={handleChange}
        />
      ))}
    </Space>
  );
};

export const PreviewModal = (props) => {
  const { project, value = {} } = props;
  const { data = {} } = project;
  const { app = {} } = data;
  const { type } = app;
  const { t } = useTranslation();
  const {
    togglePreview,
    togglePreviewResolution,
    showPreview,
    preview,
    resolution,
  } = useViewer();
  const location = useLocation();
  const { pathname } = location;
  const appExp = new RegExp(`^${PAGES.APP}`);
  const scale = true;
  const qrSize = 100;
  const previewUrl = `${URL.FACE}${pathname.replace(appExp, PAGES.PREVIEW)}#${
    project?.id
  }`;
  let refsInView =
    JSON.stringify(value).match(
      /@(cookie|property|firebase\.user)\.[A-Za-z0-9]+/g
    ) || [];
  refsInView = [...new Set(refsInView)];

  return (
    <DraggableModal
      dataset={{
        'data-preview': preview || PREVIEW.MOBILE_PORTRAIT,
        'data-project-type': type,
        'data-resolution': resolution || RESOLUTION.R1920,
      }}
      open={showPreview}
      draggable={{
        defaultPosition: {
          x: window.innerWidth - 354,
          y: window.innerHeight - 705,
        },
      }}
      scale={scale}
      wrapClassName="draggable-modal preview-modal"
      mask={false}
      onCancel={() => togglePreview()}
      title={
        <>
          <span className="flex-grow-1">{t('projects.preview')}</span>
          {!!refsInView.length && (
            <Popover
              placement="bottom"
              trigger="click"
              content={<ReferenceForm refs={refsInView} />}
              title={t('projects.references')}
              style={{ minWidth: '492px' }}
            >
              <Tooltip title={t('projects.preview_references')}>
                <AntButton
                  shape="circle"
                  type="text"
                  icon={<ReactIcon path={mdiAt} size={0.8} />}
                />
              </Tooltip>
            </Popover>
          )}
          {type === projectTypes.WEB && (
            <AntDropdown
              trigger={['click']}
              overlay={
                <AntMenu selectedKeys={[resolution]}>
                  {Object.values(RESOLUTION).map((value) => (
                    <AntMenu.Item
                      key={value}
                      onClick={() => togglePreviewResolution(value)}
                    >
                      {value}
                    </AntMenu.Item>
                  ))}
                </AntMenu>
              }
              placement="bottom"
            >
              <Tooltip title={t('projects.preview_resolution')}>
                <AntButton
                  shape="circle"
                  type="text"
                  icon={<ReactIcon path={mdiImageSizeSelectLarge} size={0.8} />}
                />
              </Tooltip>
            </AntDropdown>
          )}
          <Tooltip title={t('projects.preview_orientation')}>
            <AntButton
              shape="circle"
              type="text"
              icon={
                <ReactIcon
                  path={
                    preview === PREVIEW.MOBILE_PORTRAIT
                      ? mdiPhoneRotateLandscape
                      : mdiPhoneRotatePortrait
                  }
                  size={0.8}
                />
              }
              onClick={() => {
                togglePreview(
                  preview === PREVIEW.MOBILE_PORTRAIT
                    ? PREVIEW.MOBILE_LANDSCAPE
                    : PREVIEW.MOBILE_PORTRAIT
                );
                const modal = document.querySelector(
                  '.preview-modal .react-draggable'
                );
                const modalBody = modal.querySelector('.ant-modal-body');
                let { width, height } = modalBody?.getBoundingClientRect();
                modal.style.width = `${height}px`;
                modal.style.height = `${width + 75}px`;
                modal.style.transform = `translate(${
                  window.outerWidth - height
                }px, ${window.outerHeight - (width + 187)}px)`;
              }}
            />
          </Tooltip>
          {/*<AntDropdown
            trigger={['click']}
            overlay={
              <AntMenu>
                <AntMenu.Item>
                  <QRCode
                    value={previewUrl}
                    size={qrSize}
                    includeMargin={false}
                    imageSettings={{
                      src: logoMin,
                      height: (qrSize * 16) / 100,
                      width: (qrSize * 16) / 100,
                      excavate: true,
                    }}
                  />
                </AntMenu.Item>
                <AntMenu.Item onClick={() => window.open(previewUrl)}>
                  Open in new window
                </AntMenu.Item>
              </AntMenu>
            }
            placement="bottom"
          >
            <AntButton
              shape="circle"
              type="text"
              icon={<ReactIcon path={mdiOpenInNew} size={0.8} />}
            />
          </AntDropdown>*/}
        </>
      }
    >
      <Preview {...props} />
    </DraggableModal>
  );
};

export const PreviewWrapper = ({ externalRef, onClick, ...props }) => {
  const { setCurrentComponent } = useComponents();
  const { setCurrentEvent } = useEvents();
  const { project } = props;
  const { data = {} } = project;
  const { app = {} } = data;
  const { type } = app;
  let { preview, resolution } = useViewer();

  if ([projectTypes.FLOW, projectTypes.WEB].includes(type)) {
    preview = PREVIEW.MOBILE_LANDSCAPE;
  }
  return (
    <div
      className="preview-wrapper"
      data-preview={preview || PREVIEW.MOBILE_PORTRAIT}
      data-project-type={type}
      data-resolution={resolution || RESOLUTION.R1920}
      ref={externalRef}
      onClick={() => {
        setCurrentComponent(null);
        setCurrentEvent(null);
        onClick?.();
      }}
    >
      <div>
        <Preview {...props} className="border-start border-end" />
      </div>
    </div>
  );
};

export default Preview;
