import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import parse from 'html-react-parser';
import { Alert } from 'react-bootstrap';
import { IDENTIFIER_TYPE } from '../../context';
import { useAccounts, useIdentifiers } from '../../hooks';

export const Success = ({ sessionId }) => {
  const { t } = useTranslation();
  const { account = {} } = useAccounts();
  const { identifiers = [] } = useIdentifiers();

  useEffect(() => {
    (async () => {
      try {
        const { node: { subject: customerEmail } = {} } =
          identifiers.find(
            ({ node }) => node.default && node.type === IDENTIFIER_TYPE.EMAIL
          ) || {};
        await fetch(
          'https://us-central1-doyo-c4526.cloudfunctions.net/addUser',
          {
            method: 'POST',
            body: JSON.stringify({
              hash: CryptoJS.SHA256(customerEmail).toString(),
              account_id: account.id,
              sessionId,
            }),
          }
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div>
      <div className="text-center py-3">
        <Alert color="success" className="d-inline-block">
          <h5 className="m-0">
            <span role="img" aria-label="Party Popper">
              🎉
            </span>{' '}
            {t('checkout.payment_succeeded')}{' '}
            <span role="img" aria-label="Raising Hands">
              🙌
            </span>
          </h5>
        </Alert>
      </div>
    </div>
  );
};

export default Success;
