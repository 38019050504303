import React from 'react';
import Component from './Component';
import { useFile, useSource } from '../../hooks';

const buttonSizes = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

export const ButtonCompiler = ({ icon: iconProp, ...props }) => {
  const { url: icon = iconProp } = useFile(iconProp);
  return <Button {...props} icon={icon} />;
};
export const Button = ({ color, icon, ...props }) => {
  return (
    <Component
      {...props}
      className="button"
      background={{ type: 'solid', color }}
    >
      <div style={{ backgroundImage: `url(${icon})` }}></div>
    </Component>
  );
};

export default ButtonCompiler;
