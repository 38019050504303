import React from 'react';
import {
  Card,
  Modal as BootstrapModal,
  Offcanvas as BootstrapOffcanvas,
  Toast as BootstrapToast,
} from 'react-bootstrap';
import { getLanguage } from '../../_helpers';
import { componentTypes, dialogTypes } from '../../schemas/defs/web';
import { Content } from './components/Component';
import Button from './components/Button';
import Group from './components/Group';
import Icon from './components/Icon';
import Image from './components/Image';
import Text from './components/Text';

const Modal = ({ text, title, ...props }) => {
  const { backdrop = true, buttons = {}, closable = true, content, id } = props;
  const headerProps = {};
  if (closable) {
    headerProps.closeButton = true;
  }
  return (
    <>
      <div className={`fade show ${backdrop ? 'modal-backdrop' : ''}`} />
      <div className="fade modal show d-block position-absolute p-3">
        <div className="modal-dialog">
          <div className="modal-content">
            <BootstrapModal.Header {...headerProps}>
              {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
            </BootstrapModal.Header>
            {(text || content) && (
              <BootstrapModal.Body>
                {text}
                {content && <Content content={content} />}
              </BootstrapModal.Body>
            )}
            {Object.keys(buttons) && (
              <BootstrapModal.Footer>
                {buttons.custom?.map((attributes, i) => (
                  <Button
                    key={`ModalButton${id}${i}`}
                    id={`ModalButton${id}${i}`}
                    {...attributes}
                  />
                ))}
                {buttons.negative && (
                  <Button
                    key={`ModalButton${id}negative`}
                    id={`ModalButton${id}negative`}
                    text={buttons.negative.label}
                  />
                )}
                {buttons.positive && (
                  <Button
                    key={`ModalButton${id}positive`}
                    id={`ModalButton${id}positive`}
                    color="primary"
                    text={buttons.positive.label}
                  />
                )}
              </BootstrapModal.Footer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Offcanvas = ({ text, title, ...props }) => {
  const {
    backdrop = true,
    closable = true,
    content,
    placement = 'start',
  } = props;
  const headerProps = {};
  if (closable) {
    headerProps.closeButton = true;
  }
  return (
    <>
      <div className={`fade show ${backdrop ? 'offcanvas-backdrop' : ''}`} />
      <div className={`offcanvas offcanvas-${placement} show visible`}>
        <BootstrapOffcanvas.Header {...headerProps}>
          <Text
            text={title}
            component={BootstrapOffcanvas.Title}
            font_size={null}
          />
        </BootstrapOffcanvas.Header>
        <BootstrapOffcanvas.Body>
          <Text text={text} font_size={null} />
          {content && <Group content={content} />}
        </BootstrapOffcanvas.Body>
      </div>
    </>
  );
};

const Toast = ({ text, title, ...props }) => {
  const { icon = '', placement } = props;

  const toastIcon = icon.includes('.') ? (
    <Image file={icon} className="rounded me-2" />
  ) : (
    <Icon icon={icon} className="rounded me-2" />
  );

  return (
    <BootstrapToast data-placement={placement}>
      <BootstrapToast.Header closeButton>
        {icon && toastIcon}
        {title && (
          <Text
            text={title}
            component="strong"
            font_size={null}
            className="me-auto"
          />
        )}
      </BootstrapToast.Header>
      <BootstrapToast.Body>
        <Text text={text} font_size={null} />
      </BootstrapToast.Body>
    </BootstrapToast>
  );
};

const dialog = {
  [dialogTypes.DRAWER]: Offcanvas,
  [dialogTypes.MODAL]: Modal,
  [dialogTypes.TOAST]: Toast,
};

export const Dialog = (props) => {
  const language = getLanguage();
  const { buttons, steps, type } = props;
  let { text, title } = props;
  if ((!buttons && !type) || steps) {
    return null;
  }

  text = (text && (text[language] || text.default)) || text;
  title = (title && (title[language] || title.default)) || title;

  const DialogType = dialog[type] || 'div';
  return (
    <main className="preview dialog">
      <DialogType {...props} text={text} title={title} />
    </main>
  );
};

export default Dialog;
