import { useCallback, useRef } from 'react';
import Ajv from 'ajv';
import AjvKeywords from 'ajv-keywords';
const ajv = new Ajv({
  $data: true,
  allErrors: true,
  jsonPointers: true,
  removeAdditional: true,
  uniqueItems: true,
});
AjvKeywords(ajv, 'select');

export function useValidation({ value: propsValue = {} } = {}) {
  const count = useRef(0);

  const validate = useCallback(
    (_value, schema) => {
      const value = (_value || propsValue)._clone();
      const validate = ajv.compile(schema);
      const isValid = validate(value);
      const errors = validate.errors;

      if (errors) {
        errors.forEach((error) => {
          const { dataPath, params, schemaPath } = error;
          const { missingProperty } = params;
          if (missingProperty && !dataPath && !schemaPath.includes('select')) {
            const { properties = {} } = schema;
            let { default: _default = '' } = properties[missingProperty] || {};
            _default =
              missingProperty === 'default'
                ? Object.values(value)[0]
                : _default;
            missingProperty && (value[missingProperty] = _default);
          }
        });
        if (count.current < 3) {
          count.current++;
        }
      } else {
        count.current = 0;
      }
      return { isValid, errors, value };
    },
    [propsValue]
  );

  return { validate };
}
