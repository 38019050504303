import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiArrowRight, mdiCheck, mdiClose } from '@mdi/js';
import { Button, Col, Row } from 'antd';
import { PAGES } from '../../_config';
import { CirclePreloader } from '../../components';
import { useIdentifiers, useVerifications } from '../../hooks';
import { logo } from '../../assets/svg/logos/index';
import bg from '../../assets/svg/email-checked.svg';

const CountdownTimer = ({ from = 0, callback = () => {} }) => {
  const [timeLeft, setTimeLeft] = useState(from);

  useEffect(() => {
    if (timeLeft === 0) {
      callback();
      return;
    }
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  });
  return <span>{timeLeft}</span>;
};

let init = false;
export const VerifyEmailPage = ({
  match: {
    params: { identifierId = '', token = '' },
  },
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { identifier = {} } = useIdentifiers();
  const { node = {} } = identifier;
  const { verifiedAt } = node;
  let { createVerification, error, loading, pushVerification, verified } =
    useVerifications();

  verified = verified || !!verifiedAt || undefined;
  if (typeof verified === 'undefined' && !error) {
    loading = true;
  }

  useEffect(() => {
    if (!init && identifier.node && !verifiedAt) {
      init = true;
      pushVerification({
        variables: {
          identifierId,
          token,
        },
      });
    }
  }, [identifier, verifiedAt]);

  return (
    <main className="verify-email" style={{ backgroundImage: `url(${bg})` }}>
      <Row className="justify-content-between align-items-center">
        <Col>
          <Link className="d-inline-block p-3 p-sm-5" to="/" aria-label="Doyo">
            <img
              className="d-inline-block"
              src={logo}
              height="42px"
              alt="Logo"
            />
          </Link>
        </Col>
      </Row>
      {(loading && <CirclePreloader className="position-absolute" />) ||
        (verified && (
          <div className="position-absolute top-0 w-100 h-100 p-3 d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-center text-primary font-weight-normal">
              <Icon path={mdiCheck} size={3} />
            </h1>
            <h4 className="text-center text-muted font-weight-light mb-5">
              {t('verify_email.email_verified')}
            </h4>
            <div>
              <Link to={PAGES.DASHBOARD} className="ms-2">
                <Button type="primary">
                  <span className="me-2">
                    {t('verify_email.go_to_dashboard')}
                  </span>
                  (
                  <CountdownTimer
                    from={5}
                    callback={() => history.replace(PAGES.DASHBOARD)}
                  />
                  )
                  <Icon path={mdiArrowRight} size={1} className="ms-2" />
                </Button>
              </Link>
            </div>
          </div>
        )) || (
          <div className="position-absolute top-0 w-100 h-100 p-3 d-flex flex-column justify-content-center align-items-center">
            <h1 className="text-center text-danger font-weight-normal">
              <Icon path={mdiClose} size={3} />
            </h1>
            <h4 className="text-center text-muted font-weight-light mb-4">
              {t('verify_email.email_not_verified')}
            </h4>
            <div>
              <Button
                onClick={(e) => {
                  createVerification({ variables: { identifierId } });
                  e.currentTarget.classList.add('disabled');
                }}
              >
                {t(`identifiers.email_resend_verification`)}
              </Button>
              <Link to={PAGES.DASHBOARD} className="ms-2">
                <Button type="primary">
                  {t('verify_email.go_to_dashboard')}
                  <Icon path={mdiArrowRight} size={1} className="ms-2" />
                </Button>
              </Link>
            </div>
          </div>
        )}
    </main>
  );
};

export default VerifyEmailPage;
