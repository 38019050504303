import React, { useState } from 'react';
import { Collapse, Navbar, Nav } from 'react-bootstrap';
import { HOST } from '../../../_config';
import { AuthorizedHeader, UnauthorizedHeader } from '../../../components';
import { useAccounts } from '../../../hooks';
import { logoWhite } from '../../../assets/svg/logos';

function DoyoFilesHeader() {
  const [collapsed, setCollapse] = useState(false);
  const { account } = useAccounts();

  return (
    <header className="fixed-top">
      <Navbar variant="kustodio" expand="sm">
        <a
          href={HOST.KUSTODIO}
          className="navbar-brand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="logo DoyoFiles" src={logoWhite} height="42px" />
        </a>
        <Navbar.Toggle onClick={() => setCollapse(!collapsed)} />
        <Collapse isOpen={collapsed} navbar>
          <Nav>
            {(account && <AuthorizedHeader />) || <UnauthorizedHeader />}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
}

export default DoyoFilesHeader;
export { DoyoFilesHeader };
