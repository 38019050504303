import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parseHtml from 'html-react-parser';
import ReactIcon from '@mdi/react';
import { mdiArrowTopRight, mdiWindowMaximize } from '@mdi/js';
import { message } from 'antd';
import { useRefs, useFirebase } from '../hooks';
import { i18n } from '../../i18n';
import { parse } from '../util';

export const modifiers = [
  '\\.\\+',
  '\\.\\-',
  '\\.\\*',
  '\\.\\/',
  '\\.\\d+\\-\\d+',
  '\\.\\d+',
  '\\.json',
  '\\.length',
  '\\.lower',
  '\\.md5',
  '\\.sha256',
  '\\.trim',
  '\\.upper',
  '\\.urldecode',
  '\\.urlencode',
];

export const modifiersMap = {
  json: '.toJson()',
  length: '.length',
  lower: '.toLowerCase()',
  md5: '.toMd5()',
  sha256: '.toSha256()',
  trim: '.trim()',
  upper: '.toUpperCase()',
  urldecode: '.decodeUrl()',
  urlencode: '.encodeUrl()',
};

export const FunctionsContext = createContext({});

export const FunctionsProvider = (props) => {
  const [messages, setMessages] = useState([]);
  const { t } = useTranslation();
  const firebase = useFirebase();
  const {
    clearMessages: clearFirebaseMessages,
    getFileUrlFromStorage,
    getFolderChildrenFromStorage,
    getFromFirestore,
    messages: firebaseMessages,
  } = firebase;
  let { setRefs, ...refs } = useRefs();

  useEffect(() => {
    firebaseMessages?.length &&
      setMessages((messages) => [...messages, ...firebaseMessages]);
  }, [firebaseMessages]);

  const clearMessages = () => {
    messages.forEach((m) => m.destroy());
    clearFirebaseMessages?.();
  };

  const handlers = {
    replaceStorage: async function ({ str }) {
      if (str.indexOf('@firebase.storage.') !== 0) {
        return str;
      }
      try {
        if (str.replace('@firebase.storage.', '').includes('.')) {
          return await getFileUrlFromStorage(str);
        }
        return await getFolderChildrenFromStorage(str);
      } catch (error) {
        return null;
      }
    },
    replaceFirestore: async function ({
      str,
      filter,
      isCollection,
      order,
      limit,
    }) {
      if (str.indexOf('@firebase.firestore.') !== 0) {
        return str;
      }
      try {
        return await getFromFirestore(str, {
          filter,
          isCollection,
          order,
          limit,
        });
      } catch (error) {
        return null;
      }
    },
    set: async function ({ what, value }, { data } = {}) {
      what = await this.replaceRefs(what, { data, onlyParanthesis: true });
      value = await this.replaceRefs(value, { data });
      value =
        what
          ?.replace('@', '')
          .split('.')
          .reverse()
          .reduce((value, key) => ({ [key]: value }), value) || {};
      const mainKey = Object.keys(value)[0];
      if (value?.app?.language) {
        i18n.changeLanguage(value.app?.language);
      }

      refs = {
        ...refs,
        [mainKey]: {
          ...refs[mainKey],
          ...value[mainKey],
        },
      };
      setRefs({ ...value });
    },
    goto: async function ({ view }) {},
    popup: async function ({ dialog }) {},
    hide: async function ({ dialog }) {
      return;
    },
    delete: async function ({ what }) {
      return;
    },
    add: async function ({
      what = '',
      key = '',
      to = '',
      onsuccess: onSuccess = [],
      onerror: onError = [],
    }) {
      return;
    },

    clone: function (props) {
      return;
    },

    collection: function () {
      return;
    },

    request: async function ({
      headers = {},
      into,
      method = 'get',
      onerror = [],
      onsuccess = [],
      params = {},
      response: responseType = 'plain',
      url = '',
    }) {
      return;
    },
    login: async function () {
      return;
    },

    logout: async function () {
      return;
    },
    signup: async function () {
      return;
    },

    file: async function ({
      into,
      multiple = false,
      onsuccess: onSuccess = [],
      onerror: onError = [],
      type,
    }) {
      return;
    },

    functions: async function (functions = []) {
      try {
        for (const entry of functions) {
          const { function: f, if: _if = true, ...rest } = entry;
          const handleIf = await this.if(_if);
          if (_if === true || handleIf) {
            await (this[f]?.bind(this) || (() => {}))(rest);
          } else {
            const { else: _else = [] } = _if;
            _else.length && (await this.functions(_else));
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    if: async function (
      {
        what,
        is,
        is_not,
        less_than,
        more_than,
        in: _in,
        includes,
        not_in,
        not_into,
        ends_with,
        starts_with,
        and,
        or,
      } = {},
      { data } = {}
    ) {
      let _what = await this.replaceRefs(what, { data });
      is =
        typeof is !== 'undefined'
          ? await this.replaceRefs(is, { data })
          : undefined;
      is_not =
        typeof is_not !== 'undefined'
          ? await this.replaceRefs(is_not, { data })
          : undefined;
      less_than =
        typeof less_than !== 'undefined'
          ? await this.replaceRefs(less_than, { data })
          : undefined;
      more_than =
        typeof more_than !== 'undefined'
          ? await this.replaceRefs(more_than, { data })
          : undefined;
      _in =
        typeof _in !== 'undefined'
          ? await this.replaceRefs(_in, { data })
          : undefined;
      includes =
        typeof includes !== 'undefined'
          ? await this.replaceRefs(includes, { data })
          : undefined;
      not_in =
        typeof _in !== 'undefined'
          ? await this.replaceRefs(not_in, { data })
          : undefined;
      not_into =
        typeof not_into !== 'undefined'
          ? await this.replaceRefs(not_into, { data })
          : undefined;
      ends_with =
        typeof ends_with !== 'undefined'
          ? await this.replaceRefs(ends_with, { data })
          : undefined;
      starts_with =
        typeof starts_with !== 'undefined'
          ? await this.replaceRefs(starts_with, { data })
          : undefined;
      try {
        return (
          (((typeof is !== 'undefined' && _what === is) ||
            (typeof is_not !== 'undefined' && _what !== is_not) ||
            (typeof less_than !== 'undefined' && _what < less_than) ||
            (typeof more_than !== 'undefined' && _what > more_than) ||
            (typeof _in !== 'undefined' &&
              (Array.isArray(_in)
                ? _in.includes(_what)
                : (JSON.stringify(_in) || `${_in}`).includes(_what))) ||
            (typeof includes !== 'undefined' &&
              _what?.match(new RegExp(`${includes}`)) !== null) ||
            (typeof not_in !== 'undefined' &&
              !(Array.isArray(not_in)
                ? not_in.includes(_what)
                : (JSON.stringify(not_in) || `${not_in}`).includes(_what))) ||
            (typeof not_into !== 'undefined' &&
              _what?.match(new RegExp(`${not_into}`)) === null) ||
            (typeof starts_with !== 'undefined' &&
              _what?.match(new RegExp(`^${starts_with}`)) !== null) ||
            (typeof ends_with !== 'undefined' &&
              _what?.match(new RegExp(`${ends_with}$`)) !== null)) &&
            (typeof and === 'undefined' || (await this.if(and)))) ||
          (typeof or !== 'undefined' && (await this.if(or)))
        );
      } catch (error) {
        console.error(error);
      }
    },

    get: async function (
      { what, filter, isCollection, order, limit },
      { data } = {}
    ) {
      const replaceFilterRefs = async (filter) => {
        let { what, and, or, ...condition } = filter;
        what = await this.replaceRefs(what, { data });
        let value = Object.values(condition)[0];
        value = await this.replaceRefs(value, { data });
        condition = Object.keys(condition)[0];
        let result = { ...filter, what, [condition]: value };
        if (and) {
          and = await replaceFilterRefs(and);
          result = { ...result, and };
        }
        if (or) {
          or = await replaceFilterRefs(or);
          result = { ...result, or };
        }
        return result;
      };
      const replaceOrderRefs = async (order) => {
        let { field, type } = order;
        field = await this.replaceRefs(field, { data });
        type = await this.replaceRefs(type, { data });
        return { field, type };
      };
      const replaceLimitRefs = async (limit) => {
        limit = await this.replaceRefs(limit, { data });
        return limit;
      };
      filter = filter && (await replaceFilterRefs(filter));
      order = order && (await replaceOrderRefs(order));
      limit = limit && (await replaceLimitRefs(limit));
      const value = await this.replaceRefs(what, {
        data,
        filter,
        isCollection,
        order,
        limit,
      });
      return value;
    },

    open: function (props) {
      return;
    },

    replaceRef: function (str, parenthesis, type, key) {
      const exp = new RegExp(`(${modifiers.join('|')})(\\.|$)[^.]*`);
      return key.split('.').reduce(
        function (resp, _key) {
          let operation = (`.${_key}`.match(exp)?.[0] || '').substr(1);
          key = operation ? key.replace(`.${operation}`, '') : key;
          if (operation) {
            let operand =
              typeof resp?.[_key] !== 'undefined' ? resp[_key] : resp;
            operand = typeof operand !== 'undefined' ? operand : '';
            operand = typeof operand === 'string' ? `"${operand}"` : operand;
            if (operation.match(/^\d+-\d+$/)) {
              operation = `.substring(${operation.replace('-', ',')})`;
            } else if (operation.match(/^\d+$/)) {
              operation = `.charAt(${operation})`;
            } else if (operation.match(/^[+\-*/]+/)) {
              operand = operand || 0;
            }
            operation = modifiersMap[operation] || operation;
            try {
              return eval(`${operand}${operation}`);
            } catch (e) {}
          }
          return typeof resp?.[_key] === 'object'
            ? resp?.[_key]
            : typeof resp?.[_key] === 'undefined'
            ? null
            : typeof resp[_key] === 'function'
            ? resp[_key]()
            : resp[_key];
        },
        { ...refs[type] }
      );
    },

    replaceRefs: async function (
      str,
      { data, filter, isCollection, order, limit, onlyParanthesis = false } = {}
    ) {
      refs.field = data;
      if (typeof str !== 'string') {
        return str;
      }

      if (data) {
        str = `${str}`.replace(
          /(^@|\(@)(field)\.([^)]+)\)?/g,
          this.replaceRef.bind(this)
        );
      }

      if (str.indexOf('@app.timestamp') === 0) {
        return Date.now();
      }

      if (str.indexOf('@window.') === 0) {
        return eval(str.substring(1));
      }

      if (false) {
      }
      // Custom replaceRefs:
      else if (!onlyParanthesis && str.indexOf('@database.') === 0) {
        if (str.indexOf('@database.') !== 0) {
          return str;
        }
        return null;
      } else if (!onlyParanthesis && str.indexOf('@firebase.storage.') === 0) {
        str = `${str}`.replace(
          /(\(@)([^.]+)\.([^)]+)\)?/g,
          this.replaceRef.bind(this)
        );
        const exp = new RegExp(`(${modifiers.join('|')})(\\.|$)[^.]*`);
        let operation = (str.match(exp)?.[0] || '').substr(1);
        str = operation ? str.replace(`.${operation}`, '') : str;
        let resp = await this.replaceStorage({ str });
        if (operation) {
          let operand = typeof resp !== 'undefined' ? resp : '';
          operand = typeof operand === 'string' ? `"${operand}"` : operand;
          if (operation.match(/^\d+-\d+$/)) {
            operation = `.substring(${operation.replace('-', ',')})`;
          } else if (operation.match(/^\d+$/)) {
            operation = `.charAt(${operation})`;
          } else if (operation.match(/^[+\-*/]+/)) {
            operand = operand || 0;
          }
          operation = modifiersMap[operation] || operation;
          try {
            return eval(`${operand}${operation}`);
          } catch (e) {}
        }
        return resp;
      } else if (
        !onlyParanthesis &&
        str.indexOf('@firebase.firestore.') === 0
      ) {
        str = `${str}`.replace(
          /(\(@)([^.]+)\.([^)]+)\)?/g,
          this.replaceRef.bind(this)
        );
        const exp = new RegExp(`(${modifiers.join('|')})(\\.|$)[^.]*`);
        let operation = (str.match(exp)?.[0] || '').substr(1);
        str = operation ? str.replace(`.${operation}`, '') : str;
        let resp = await this.replaceFirestore({
          str,
          filter,
          isCollection,
          order,
          limit,
        });
        if (operation) {
          let operand = typeof resp !== 'undefined' ? resp : '';
          operand = typeof operand === 'string' ? `"${operand}"` : operand;
          if (operation.match(/^\d+-\d+$/)) {
            operation = `.substring(${operation.replace('-', ',')})`;
          } else if (operation.match(/^\d+$/)) {
            operation = `.charAt(${operation})`;
          } else if (operation.match(/^[+\-*/]+/)) {
            operand = operand || 0;
          }
          operation = modifiersMap[operation] || operation;
          try {
            return eval(`${operand}${operation}`);
          } catch (e) {}
        }
        return resp;
      }

      let strWithReplacedRefs = `${str}`.replace(
        onlyParanthesis
          ? /(\(@)([^.]+)\.([^)]+)\)?/g
          : /(^@|\(@)([^.]+)\.([^)]+)\)?/g,
        this.replaceRef.bind(this)
      );

      if (strWithReplacedRefs.match(/\[object/)) {
        const replacedRef = str
          .substring(1)
          .split('.')
          .reduce(
            (resp, key) => {
              if (!resp) {
                return resp;
              }
              return typeof resp?.[key] === 'undefined'
                ? null
                : typeof resp[key] === 'function'
                ? resp[key]()
                : resp[key];
            },
            { ...refs }
          );
        return replacedRef;
      }

      strWithReplacedRefs = parse(strWithReplacedRefs);
      return Promise.resolve(strWithReplacedRefs);
    },
  };

  const get = async (props, options) => {
    return await handlers.get?.(props, options);
  };

  const _if = async (props, options) => {
    return await handlers.if(props, options);
  };

  const set = async (props, options) => {
    return await handlers.set?.(props, options);
  };

  const run = async (_functions) => {
    return await handlers.functions(_functions);
  };

  return (
    <FunctionsContext.Provider
      value={{
        clearMessages,
        messages,
        run,
        get,
        if: _if,
        set,
      }}
    >
      {props.children}
    </FunctionsContext.Provider>
  );
};

export const FunctionsConsumer = FunctionsContext.Consumer;
export default FunctionsContext;
