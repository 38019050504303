import { URL } from '../../_config';
import { languages, UI } from '../../_helpers';

export const id = { pattern: '^[a-z]+[_a-zA-Z0-9]*$' };

export const analytics = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  required: ['tracking_id'],
  properties: {
    tracking_id: { type: 'string' },
  },
};
export const bounds = {
  title: 'Bounds',
  default: {},
  properties: {
    north: { type: 'number' },
    south: { type: 'number' },
    east: { type: 'number' },
    west: { type: 'number' },
  },
};

export const hexColor = {
  title: 'Hexadecimal Color',
  default: '#ffffff',
  pattern:
    '^(#([A-Fa-f0-9]{3,4}){1,2}|rgb(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){2}([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))))|(rgba(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){3}(0|1|0.\\d+))))$',
};

export const color = {
  title: 'Color',
  default: '',
  key: 'color',
  oneOf: [
    { pattern: '@color..+' },
    {
      pattern:
        '^(#([A-Fa-f0-9]{3,4}){1,2}|rgb(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){2}([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))))|(rgba(((([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]),\\s*){3}(0|1|0.\\d+))))$',
    },
  ],
};

export const backgroundTypes = {
  TRANSPARENT: 'transparent',
  SOLID: 'solid',
  GRADIENT: 'gradient',
  IMAGE: 'image',
};
const commonBackgroundProperties = {
  type: {
    enum: [
      backgroundTypes.TRANSPARENT,
      backgroundTypes.SOLID,
      backgroundTypes.IMAGE,
    ],
    ui: UI.DROPDOWN,
  },
};

export const background = {
  title: 'Background',
  description: '{{type}}',
  default: {},
  required: ['type'],
  properties: {
    ...commonBackgroundProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    transparent: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonBackgroundProperties,
      },
    },
    solid: {
      description: '{{type}} {{color}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['color'],
      properties: {
        ...commonBackgroundProperties,
        color,
      },
    },
    image: {
      description: '{{type}} {{image}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['image'],
      properties: {
        ...commonBackgroundProperties,
        image: { type: 'string' },
      },
    },
  },
};

// eslint-disable-next-line
export const data_source = {
  title: 'Data source',
  default: '',
  type: 'string',
  oneOf: [
    { pattern: '^res/.+$' },
    { pattern: '^(http|https)://.+$' },
    { pattern: '^@.+$' },
  ],
};

export const fontRes = {
  title: 'Font resource',
  default: '',
  type: 'string',
  pattern: 'fonts/[^.]*.(ttf|otf)',
};

export const font = {
  title: 'Font',
  default: '',
  type: 'string',
  oneOf: [
    { pattern: '^res/.+$' },
    { pattern: '^(http|https)://.+$' },
    { pattern: '^@font.+$' },
  ],
  key: 'font',
};

export const halignTypes = {
  START: 'start',
  CENTER: 'center',
  END: 'end',
};
export const halign = {
  title: 'Halign',
  default: '',
  enum: [halignTypes.START, halignTypes.CENTER, halignTypes.END],
  ui: UI.DROPDOWN,
};

export const height = {
  title: 'Height',
  default: '',
  oneOf: [{ enum: ['wrap', 'fill'] }, { pattern: '\\d+%' }, { type: 'number' }],
};

export const and = {
  title: 'And',
  description:
    'and {{what}} {is} {{is}} {is_not} {{is_not}} {less_than} {{less_than}} {more_than} {{more_than}}',
  type: 'object',
  default: { what: '', is: '' },
  required: ['what'],
  properties: {
    what: { type: 'string' },
    ends_with: { type: ['string', 'null'] },
    in: { type: ['string', 'null'] },
    includes: { type: ['string', 'null'] },
    is: { type: ['string', 'null'] },
    is_not: { type: ['string', 'null'] },
    less_than: { type: ['string', 'null'] },
    more_than: { type: ['string', 'null'] },
    not_int: { type: ['string', 'null'] },
    starts_with: { type: ['string', 'null'] },
    and: { default: {} },
    or: { default: {} },
    else: { default: [] },
  },
};
export const or = {
  ...and,
  title: 'Or',
  description:
    'or {{what}} {is} {{is}} {is_not} {{is_not}} {less_than} {{less_than}} {more_than} {{more_than}}',
};

export const _if = {
  title: 'If',
  description:
    'if {{what}} {is} {{is}} {is_not} {{is_not}} {less_than} {{less_than}} {more_than} {{more_than}} {ends_with} {{ends_with}} {in} {{in}} {includes} {{includes}} {not_in} {{not_in}} {not_into} {{not_into}} {starts_with} {{starts_with}}',
  type: 'object',
  default: { what: '', is: '' },
  required: ['what'],
  properties: {
    what: { type: 'string' },
    ends_with: { type: ['string', 'null'] },
    in: { type: ['string', 'null'] },
    includes: { type: ['string', 'null'] },
    is: { type: ['string', 'null'] },
    is_not: { type: ['string', 'null'] },
    less_than: { type: ['string', 'null'] },
    more_than: { type: ['string', 'null'] },
    not_in: { type: ['string', 'null'] },
    not_into: { type: ['string', 'null'] },
    starts_with: { type: ['string', 'null'] },
    and,
    or,
    else: { default: [] },
  },
};

export const _while = {
  ..._if,
  title: 'While',
  description:
    'while {{what}} {is} {{is}} {is_not} {{is_not}} {less_than} {{less_than}} {more_than} {{more_than}}',
};

const commonFunctionProperties = {
  function: {
    enum: [
      'abort',
      'add',
      'address',
      'api',
      'back',
      'browser',
      'calculate',
      'calendar',
      'call',
      'camera',
      'collection',
      'delete',
      'divide',
      'do',
      'download',
      'end',
      'file',
      'finish',
      'goto',
      'increase',
      'interstitial',
      'listen',
      'login',
      'logout',
      'loop',
      'multiply',
      'noop',
      'open',
      'play',
      'popup',
      'purchase',
      'reduce',
      'resize',
      'reward',
      'round',
      'refresh',
      'regex',
      'request',
      'restart',
      'set',
      'share',
      'signup',
      'start',
      'stop',
      'track',
      'wait',
    ],
    reset: true,
  },
  id: { type: 'string', default: '', readOnly: true },
  comments: { default: '' },
  if: _if,
};

export const filter = {
  default: { what: '', is: '' },
  required: ['what'],
  properties: {
    what: { type: 'string' },
    is: { type: ['string', 'null'] },
    is_not: { type: ['string', 'null'] },
    less_equal_than: { type: 'string' },
    less_than: { type: 'string' },
    more_equal_than: { type: 'string' },
    more_than: { type: 'string' },
    starts_with: { type: 'string' },
    ends_with: { type: 'string' },
    includes: { type: 'string' },
    and,
    or,
  },
};

export const _function = {
  title: 'Function',
  description: '{{function}}',
  required: ['function'],
  default: { function: '' },
  properties: {
    ...commonFunctionProperties,
  },
  select: { $data: '0/function' },
  selectCases: {
    abort: {
      properties: {
        ...commonFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    add: {
      description: '{{to}}',
      required: ['what', 'to'],
      properties: {
        ...commonFunctionProperties,
        what: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
        key: {
          oneOf: [
            { pattern: '^[a-zA-Z]+[_a-zA-Z0-9]*$' },
            { pattern: '^@.+$' },
            { pattern: '^[a-z(@][a-zA-Z()@._0-9]*' },
          ],
        },
        to: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    address: {
      description: '{{into}} = {{format}}',
      required: ['from', 'format', 'into'],
      properties: {
        ...commonFunctionProperties,
        from: { type: 'string' },
        format: { type: 'string' },
        into: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    api: {
      description: '{{api}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['api'],
      properties: {
        ...commonFunctionProperties,
        api: {
          default: {},
          required: ['method'],
          properties: {
            method: { type: 'string' },
          },
          select: { $data: '0/method' },
          selectCases: {},
        },
        into: { type: 'string', pattern: '^@.+$' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
    },
    back: {
      properties: {
        ...commonFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    browser: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonFunctionProperties,
        url: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    calculate: {
      description: '{{operation}} {{into}}',
      required: ['collection', 'field', 'operation'],
      properties: {
        ...commonFunctionProperties,
        collection: { type: 'string', default: '@database' },
        into: {
          oneOf: [
            { type: 'string', pattern: '^[A-Za-z_0-9]*' },
            { type: 'string', pattern: '^@.+$' },
          ],
        },
        operation: {
          enum: [
            'average',
            'distance',
            'distanceTo',
            'length',
            'max',
            'max_value',
            'min',
            'min_value',
            'sum',
            'to_array',
          ],
          ui: UI.DROPDOWN,
        },
        parameter: { type: 'string' },
        field: { type: 'string', pattern: '^[A-Za-z_0-9]*' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    calendar: {
      description: '{{pick}} {{format}} {{into}}',
      required: ['into', 'pick'],
      properties: {
        ...commonFunctionProperties,
        default: { type: 'string' },
        into: {
          default: '@property.tempDate',
          type: 'string',
          pattern: '^@.+$',
        },
        pick: {
          oneOf: [
            { enum: ['date', 'time', 'datetime', 'now'], ui: UI.DROPDOWN },
            { type: 'number' },
            { type: 'string', pattern: '^@.+$' },
          ],
        },
        format: { type: 'string', default: 'YYYY-MM-dd' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    call: {
      description: '{{number}}',
      required: ['number'],
      properties: {
        ...commonFunctionProperties,
        number: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    camera: {
      description: '{{into}}',
      required: ['into'],
      properties: {
        ...commonFunctionProperties,
        into: {
          default: '@property.tempFile',
          type: 'string',
          pattern: '^@.+$',
        },
        onsuccess: { default: [] },
        onfailed: { default: [] },
        max_size: { type: 'number', default: 1920 },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    collection: {
      description: '{{operation}} {{into}}',
      required: ['what', 'into'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string', default: '@database' },
        into: {
          oneOf: [
            { type: 'string', pattern: '^[A-Za-z_0-9]*' },
            { type: 'string', pattern: '^@.+$' },
          ],
        },
        operation: {
          enum: ['sync', 'set_field'],
          ui: UI.DROPDOWN,
        },
        parameter: { type: 'string' },
        field: { type: 'string', pattern: '^[A-Za-z_0-9]*' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    delete: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    divide: {
      description: '{{what}} / {{by}}',
      required: ['what', 'by'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        by: { oneOf: [{ type: 'number' }, { pattern: '^@.+$' }] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    do: {
      description: '{{function}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    download: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonFunctionProperties,
        url: { type: 'string' },
        name: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    end: {
      description: '{{job}}',
      required: ['job'],
      properties: {
        ...commonFunctionProperties,
        job: { type: 'string', key: 'job' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    file: {
      description: '{{into}}',
      required: ['into'],
      properties: {
        ...commonFunctionProperties,
        into: {
          default: '@property.tempFile',
          type: 'string',
          pattern: '^@.+$',
        },
        multiple: { type: 'boolean', default: true },
        type: { type: 'string' },
        onsuccess: { default: [] },
        onfailed: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    finish: {
      properties: {
        ...commonFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    goto: {
      description: '{{view}}',
      required: ['view'],
      properties: {
        ...commonFunctionProperties,
        view: { type: 'string', key: 'view' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    increase: {
      description: '{{what}} + {{by}}',
      required: ['what', 'by'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        by: { oneOf: [{ type: 'number' }, { pattern: '^@.+$' }] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    interstitial: {
      description: '{{placement}} -> {{view}}',
      required: ['placement', 'view'],
      properties: {
        ...commonFunctionProperties,
        placement: { type: 'string' },
        view: { type: 'string', key: 'view' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    listen: {
      description: 'to {{what}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        onchanged: { default: [] },
        after: { enum: ['stop', 'keep'], ui: UI.DROPDOWN },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    login: {
      description: '{{provider}} {{email}} {{pass}}',
      required: ['provider', 'email'],
      properties: {
        ...commonFunctionProperties,
        provider: { enum: ['firebase'], default: 'firebase' },
        email: { type: 'string' },
        pass: { type: 'string' },
        forgot: { type: 'boolean', default: true },
        onvalid: { default: [] },
        oninvalid: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    logout: {
      description: '{{provider}}',
      required: ['provider'],
      properties: {
        ...commonFunctionProperties,
        provider: { enum: ['firebase'], default: 'firebase' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    loop: {
      description: '{{iterations}} {{while}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { default: [] },
        blocker: { type: 'boolean', default: true },
        interval: { oneOf: [{ type: 'number' }, { pattern: '^@.+$' }] },
        iterations: { type: 'number', default: 1 },
        while: _while,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    multiply: {
      description: '{{what}} x {{by}}',
      required: ['what', 'by'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        by: { oneOf: [{ type: 'number' }, { pattern: '^@.+$' }] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    noop: {
      properties: {
        ...commonFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    open: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string', key: 'database' },
        filter,
        order: {
          default: {},
          required: ['type'],
          properties: {
            type: {
              oneOf: [
                { enum: ['asc', 'desc', 'random'] },
                { pattern: '^@.+$' },
              ],
              default: 'asc',
            },
            field: { type: 'string' },
          },
        },
        cache: { type: 'boolean', default: true },
        limit: { type: 'number', default: 10 },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    play: {
      description: '{{what}}',
      required: ['what', 'loop'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string', key: 'audio' },
        loop: { type: 'boolean', default: false },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    popup: {
      description: '{{dialog}}',
      required: ['dialog'],
      properties: {
        ...commonFunctionProperties,
        dialog: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    purchase: {
      description: '{{product}}',
      required: ['product'],
      properties: {
        ...commonFunctionProperties,
        product: { type: 'string', key: 'product' },
        onowned: { default: [] },
        onerror: { default: [] },
        onsuccess: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    reduce: {
      description: '{{what}} - {{by}}',
      required: ['what', 'by'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        by: { oneOf: [{ type: 'number' }, { pattern: '^@.+$' }] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    regex: {
      description: '{{what}}',
      required: ['what', 'expression'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        expression: { type: 'string' },
        index: { type: 'number' },
        into: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    request: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonFunctionProperties,
        url: { type: 'string' },
        method: {
          enum: ['delete', 'get', 'patch', 'post'],
          default: 'get',
          ui: UI.DROPDOWN,
        },
        response: {
          enum: ['plain', 'json-api'],
          default: 'plain',
          ui: UI.DROPDOWN,
        },
        headers: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
        params: {
          default: {},
          patternProperties: {
            '.*': {
              oneOf: [
                { type: 'string' },
                {
                  type: 'object',
                  patternProperties: {
                    '.*': { type: 'string' },
                  },
                  additionalProperties: true,
                },
              ],
              additionalProperties: true,
            },
          },
          additionalProperties: true,
        },
        into: { type: 'string' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    resize: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        max_width: { oneOf: [{ type: 'number' }, { pattern: '[0-9]+%' }] },
        quality: { pattern: '[0-9]+%' },
        width: { oneOf: [{ type: 'number' }, { pattern: '[0-9]+%' }] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    restart: {
      properties: {
        ...commonFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    reward: {
      description: '{{placement}} {{source}}',
      required: ['placement', 'source'],
      properties: {
        ...commonFunctionProperties,
        placement: { type: 'string', key: 'placement' },
        source: { type: 'string' },
        onreward: { default: [] },
        ondismiss: { default: [] },
        onnotready: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    round: {
      description: '{{what}} {{decimals}}',
      required: ['what', 'decimals'],
      properties: {
        ...commonFunctionProperties,
        what: { oneOf: [{ type: 'number' }, { type: 'string' }] },
        decimals: { type: 'number' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    set: {
      description: '{{what}} = {{value}}',
      required: ['what', 'value'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
        value: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    share: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    signup: {
      description: '{{provider}} {{email}} {{pass}}',
      required: ['provider', 'email', 'pass'],
      properties: {
        ...commonFunctionProperties,
        provider: { enum: ['firebase'], default: 'firebase' },
        email: { type: 'string' },
        pass: { type: 'string' },
        onvalid: { default: [] },
        oninvalid: { default: [] },
        onexists: { default: [] },
        onweakpassword: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    start: {
      description: '{{job}}',
      required: ['job'],
      properties: {
        ...commonFunctionProperties,
        job: { type: 'string', key: 'job' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    stop: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonFunctionProperties,
        what: { type: 'string', default: null },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    track: {
      description: '{{name}}',
      required: ['name', 'params'],
      properties: {
        ...commonFunctionProperties,
        name: { type: 'string' },
        params: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    wait: {
      description: '{{seconds}}',
      required: ['seconds'],
      properties: {
        ...commonFunctionProperties,
        seconds: {
          oneOf: [
            { type: 'number', default: 1 },
            { type: 'string', pattern: '^@.+$' },
          ],
        },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
  },
};

const arrayOfFunctions = {
  title: 'arrayOfFunctions',
  default: [],
  type: 'array',
  items: { ..._function },
};

const onbackpressed = arrayOfFunctions;
const onchanged = arrayOfFunctions;
const onclick = arrayOfFunctions;
const oncancelled = arrayOfFunctions;
const oncomplete = arrayOfFunctions;
const ondismiss = arrayOfFunctions;
const onerror = arrayOfFunctions;
const onexists = arrayOfFunctions;
const onexit = arrayOfFunctions;
const onfailed = arrayOfFunctions;
const onfinished = arrayOfFunctions;
const onforbidden = arrayOfFunctions;
const oninvalid = arrayOfFunctions;
const onloaded = arrayOfFunctions;
const onlocationchanged = arrayOfFunctions;
const onmarkerclick = arrayOfFunctions;
const onnotready = arrayOfFunctions;
const onowned = arrayOfFunctions;
const onpreload = arrayOfFunctions;
const onpurchased = arrayOfFunctions;
const onready = arrayOfFunctions;
const onrefused = arrayOfFunctions;
const onreward = arrayOfFunctions;
const onreturned = arrayOfFunctions;
const onstart = arrayOfFunctions;
const onsuccess = arrayOfFunctions;
const onvalid = arrayOfFunctions;
const onweakpassword = arrayOfFunctions;
const _else = arrayOfFunctions;

export const api = {
  title: 'API',
  type: 'object',
  additionalProperties: false,
  removeAdditional: true,
  default: {},
  required: ['api_key', 'active_providers'],
  properties: {
    api_key: { type: 'string', readOnly: true, copyable: true },
    active_providers: {
      type: 'array',
      default: [],
      items: {
        type: 'string',
        readOnly: true,
      },
    },
  },
};

export const events = {
  title: 'Events',
  type: 'object',
  additionalProperties: false,
  removeAdditional: true,
  default: {},
  properties: {
    else: _else,
    onbackpressed,
    oncancelled,
    onchanged,
    onclick,
    oncomplete,
    ondismiss,
    onerror,
    onexists,
    onexit,
    onfailed,
    onfinished,
    onforbidden,
    oninvalid,
    onloaded,
    onlocationchanged,
    onmarkerclick,
    onnotready,
    onowned,
    onpreload,
    onpurchased,
    onready,
    onrefused,
    onreward,
    onreturned,
    onstart,
    onsuccess,
    onvalid,
    onweakpassword,
    what: arrayOfFunctions,
  },
};

export const file = {
  title: 'File',
  type: 'string',
  oneOf: [{ pattern: '^.+..+$' }, { pattern: '^(http|https)://.+$' }],
};

export const icon = {
  title: 'Icon',
  type: 'string',
  key: 'image',
  oneOf: [
    { pattern: '^res/.+$' },
    { pattern: '^(http|https)://.+$' },
    { pattern: '^@.+$' },
  ],
};

export const marginTypes = {
  BOTTOM: 'bottom',
  END: 'end',
  START: 'start',
  TOP: 'top',
};

export const margin = {
  title: 'Margin',
  description: `{${[marginTypes.TOP]}} {{${[marginTypes.TOP]}}} {${[
    marginTypes.BOTTOM,
  ]}} {{${[marginTypes.BOTTOM]}}} {${[marginTypes.START]}} {{${[
    marginTypes.START,
  ]}}} {${[marginTypes.END]}} {{${[marginTypes.END]}}}`,
  default: 0,
  oneOf: [
    { type: 'number' },
    {
      type: 'object',
      default: { [marginTypes.TOP]: 0 },
      properties: {
        [marginTypes.BOTTOM]: { type: 'number' },
        [marginTypes.END]: { type: 'number' },
        [marginTypes.START]: { type: 'number' },
        [marginTypes.TOP]: { type: 'number' },
      },
    },
  ],
};

export const multiLanguageText = {
  description: '{{default}}',
  oneOf: [
    { type: 'string' },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: { type: 'string' },
        ...languages.reduce((lngs, key) => ({ ...lngs, [key]: {} }), {}),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};
export const label = {
  title: 'Label',
  ...multiLanguageText,
};
export const name = {
  title: 'Name',
  ...multiLanguageText,
};
export const text = {
  title: 'Text',
  ...multiLanguageText,
};
export const title = {
  title: 'Title',
  ...multiLanguageText,
};
export const titleNoDefault = {
  title: 'Title',
  ...multiLanguageText,
  oneOf: [
    { type: 'string' },
    {
      type: 'object',
      properties: {
        default: { type: 'string' },
        ...languages.reduce((lngs, key) => ({ ...lngs, [key]: {} }), {}),
      },
      additionalProperties: true,
    },
  ],
};

export const orientationTypes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const orientation = {
  title: 'Orientation',
  default: 'vertical',
  enum: [orientationTypes.HORIZONTAL, orientationTypes.VERTICAL],
  ui: UI.DROPDOWN,
};

export const paddingTypes = {
  BOTTOM: 'bottom',
  END: 'end',
  START: 'start',
  TOP: 'top',
};

export const padding = {
  title: 'Padding',
  description: `{${[paddingTypes.TOP]}} {{${[paddingTypes.TOP]}}} {${[
    paddingTypes.BOTTOM,
  ]}} {{${[paddingTypes.BOTTOM]}}} {${[paddingTypes.START]}} {{${[
    paddingTypes.START,
  ]}}} {${[paddingTypes.END]}} {{${[paddingTypes.END]}}}`,
  default: 0,
  oneOf: [
    { type: 'number' },
    {
      type: 'object',
      properties: {
        top: { type: 'number' },
        bottom: { type: 'number' },
        start: { type: 'number' },
        end: { type: 'number' },
      },
    },
  ],
};

export const borderTypes = {
  BOTTOM: 'bottom',
  END: 'end',
  START: 'start',
  TOP: 'top',
};

export const border = {
  title: 'Border',
  description: `{${[borderTypes.TOP]}} {{${[borderTypes.TOP]}}} {${[
    borderTypes.BOTTOM,
  ]}} {{${[borderTypes.BOTTOM]}}} {${[borderTypes.START]}} {{${[
    borderTypes.START,
  ]}}} {${[borderTypes.END]}} {{${[borderTypes.END]}}}`,
  default: '@color.primaryText',
  key: 'color',
  oneOf: [
    color,
    {
      type: 'object',
      properties: {
        top: color,
        bottom: color,
        start: color,
        end: color,
      },
    },
  ],
};

export const radiusTypes = {
  TOP_LEFT: 'top_left',
  TOP_RIGHT: 'top_right',
  BOTTOM_LEFT: 'bottom_left',
  BOTTOM_RIGHT: 'bottom_right',
};

export const radius = {
  title: 'Radius',
  description: `{${[radiusTypes.TOP_LEFT]}} {{${[radiusTypes.TOP_LEFT]}}} {${[
    radiusTypes.TOP_RIGHT,
  ]}} {{${[radiusTypes.TOP_RIGHT]}}} {${[radiusTypes.BOTTOM_LEFT]}} {{${[
    radiusTypes.BOTTOM_LEFT,
  ]}}} {${[radiusTypes.BOTTOM_RIGHT]}} {{${[radiusTypes.BOTTOM_RIGHT]}}}`,
  default: 0,
  oneOf: [
    { type: 'number' },
    {
      type: 'object',
      properties: {
        top_left: { type: 'number' },
        top_right: { type: 'number' },
        bottom_left: { type: 'number' },
        bottom_right: { type: 'number' },
      },
    },
  ],
};

export const position = {
  title: 'Position',
  default: 'relative',
  enum: ['relative', 'absolute'],
  ui: UI.DROPDOWN,
};

export const fontSize = {
  title: 'Font size',
  default: '',
  type: 'number',
};

export const scale = {
  enum: ['contain', 'crop', 'fill'],
  default: 'contain',
  ui: UI.DROPDOWN,
};

export const source = {
  title: 'Source',
  type: 'string',
  oneOf: [
    { pattern: '^res/.+$' },
    { pattern: '^(http|https)://.+$' },
    { pattern: '^@.+$' },
    { pattern: '.+.html$' },
  ],
};

export const style = {
  title: 'Style',
  default: '',
  enum: ['regular', 'bold', 'italic', 'bold|italic'],
  ui: UI.DROPDOWN,
};

export const valignTypes = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

export const valign = {
  title: 'Valign',
  default: '',
  enum: [valignTypes.TOP, valignTypes.MIDDLE, valignTypes.BOTTOM],
  ui: UI.DROPDOWN,
};

export const width = {
  title: 'Width',
  default: '',
  oneOf: [
    { enum: ['wrap', 'fill'] },
    { pattern: '[0-9]+%' },
    { type: 'number' },
  ],
};

export const actionbar = {
  default: {},
  required: ['back_button'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    back_button: { type: 'boolean', default: false },
    background_color: { ...color, default: '@color.primary' },
    color,
    title,
    menu: { type: 'string' },
  },
};

export const drawer = {
  default: {},
  required: ['menu'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    icon: { type: 'string', key: 'image' },
    title,
    subtitle: title,
    menu: { type: 'string' },
  },
};

export const footer = {
  default: {},
  required: ['type', 'content', 'orientation', 'width', 'height'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    background,
    content: { default: [] },
    height: { ...height, default: 'wrap' },
    type: { default: 'group', readOnly: true },
    if: _if,
    orientation: { ...orientation, default: orientationTypes.HORIZONTAL },
    radius,
    scroll: { default: true, type: 'boolean' },
    visible: { type: 'boolean', default: false },
    width: { default: '100%', readOnly: true },
  },
};

export const header = {
  default: {},
  required: ['type', 'content', 'orientation', 'width', 'height'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    background,
    content: { default: [] },
    height: { ...height, default: 'wrap' },
    type: { default: 'group', readOnly: true },
    if: _if,
    orientation: { ...orientation, default: orientationTypes.HORIZONTAL },
    radius,
    scroll: { default: true, type: 'boolean' },
    visible: { type: 'boolean', default: false },
    width: { default: '100%', readOnly: true },
  },
};

export const pager = {
  default: {},
  required: ['tabs', 'views'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    background_color: { ...color, default: '@color.primary' },
    color,
    tabs: { type: 'boolean', default: false },
    tab_style: {
      default: 'title',
      enum: ['icon', 'icon_title', 'title'],
      ui: UI.DROPDOWN,
    },
    tabs_position: {
      default: 'top',
      enum: ['bottom', 'top'],
      ui: UI.DROPDOWN,
    },
    views: {
      default: [],
      additionalProperties: false,
      removeAdditional: true,
      type: 'array',
      items: {
        key: 'view',
        type: 'string',
      },
    },
  },
};

export const menuItems = {
  title: 'Menu Item',
  description: '{{title}}',
  default: { id: 'menu' },
  required: ['id', 'title'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    id,
    title,
    icon,
    onclick: { default: [] },
    items: { default: [] },
  },
};

export const items = {
  title: 'Items',
  default: [],
  type: 'array',
  uniqueItemProperties: ['id'],
  items: {
    ...menuItems,
  },
};

export const menus = {
  title: 'Menu',
  description: 'Each menu of the app',
  default: { id: 'menu' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'items'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    items,
  },
};

export const componentTypes = {
  BANNER: 'banner',
  BUTTON: 'button',
  CAMERA: 'camera',
  CANVAS: 'canvas',
  CROPPER: 'cropper',
  GROUP: 'group',
  IMAGE: 'image',
  INPUTTEXT: 'inputtext',
  LIST: 'list',
  LOTTIE: 'lottie',
  MAP: 'map',
  PDFVIEWER: 'pdfviewer',
  PLAYER: 'player',
  RANGE: 'range',
  SCANNER: 'scanner',
  SELECT: 'select',
  STRIPECHECKOUT: 'stripe_checkout',
  SWITCH: 'switch',
  TEXT: 'text',
  VIDEO: 'video',
  WEB: 'web',
  YOUTUBE: 'youtube',
};

const commonComponentProperties = {
  id,
  type: {
    enum: [
      componentTypes.BANNER,
      componentTypes.BUTTON,
      componentTypes.CAMERA,
      componentTypes.CANVAS,
      componentTypes.CROPPER,
      componentTypes.GROUP,
      componentTypes.IMAGE,
      componentTypes.INPUTTEXT,
      componentTypes.LIST,
      componentTypes.LOTTIE,
      componentTypes.MAP,
      componentTypes.PDFVIEWER,
      componentTypes.PLAYER,
      componentTypes.RANGE,
      componentTypes.SCANNER,
      componentTypes.SELECT,
      componentTypes.STRIPECHECKOUT,
      componentTypes.SWITCH,
      componentTypes.TEXT,
      componentTypes.VIDEO,
      componentTypes.WEB,
      componentTypes.YOUTUBE,
    ],
    ui: UI.DROPDOWN,
  },
  background,
  comments: { default: '' },
  halign,
  height: { ...height, default: 'wrap' },
  if: _if,
  margin,
  onclick: { default: [] },
  padding,
  position,
  valign,
  visible: { type: 'boolean', default: true },
  width: { ...width, default: '100%' },
  __expanded: { type: 'boolean' },
};

const commonComponentParameters = ['alpha', 'backgroundColor', 'visible'];

const commonFormatProperties = {
  type: {
    enum: ['date'],
    ui: UI.DROPDOWN,
  },
};

export const componentText = {
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'height', 'width'],
  default: { id: 'text', type: 'text' },
  properties: {
    ...commonComponentProperties,
    allow_online: { type: 'boolean', default: false },
    color: { ...color, default: '@color.primaryText' },
    decimals: { type: 'number' },
    field: { type: 'string' },
    font,
    font_size: fontSize,
    format: {
      required: ['type'],
      default: { type: '' },
      properties: {
        ...commonFormatProperties,
      },
      select: { $data: '0/type' },
      selectCases: {
        date: {
          additionalProperties: false,
          removeAdditional: true,
          required: [],
          properties: {
            ...commonFormatProperties,
            lang: {
              oneOf: [{ type: 'string' }, { enum: ['local'] }],
              default: 'local',
            },
            year: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            month: {
              enum: ['2-digit', 'numeric', 'narrow', 'short', 'long'],
              default: 'numeric',
            },
            day: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            hour: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            minute: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            second: { enum: ['2-digit', 'numeric'], default: 'numeric' },
            weekday: { enum: ['narrow', 'short', 'long'], default: 'short' },
          },
        },
      },
    },
    html: { type: 'boolean', default: true },
    selectable: { type: 'boolean', default: true },
    text: { ...text, requiredGroup: true },
    radius,
    source: { ...source, requiredGroup: true },
    text_align: halign,
    text_valign: valign,
  },
  parameters: [...commonComponentParameters, 'color', 'font', 'font_size'],
  events: {
    ...events,
    properties: {
      onclick: { default: [] },
    },
  },
};

export const components = {
  title: 'Component',
  description: '{{type}} {{id}}',
  default: { id: 'component', type: '' },
  required: ['type'],
  properties: {
    ...commonComponentProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [componentTypes.BANNER]: {
      description: '{{type}} {{id}} {{format}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['id'],
      default: { id: 'banner', type: 'banner' },
      properties: {
        ...commonComponentProperties,
        width: { default: '100%', readOnly: true },
        height: { default: 'wrap', readOnly: true },
        format: {
          default: 'regular',
          enum: ['regular', 'smart', 'large', 'rectangle', 'leaderboard'],
          ui: UI.DROPDOWN,
        },
        placement: { type: 'string', key: 'placement' },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.BUTTON]: {
      additionalProperties: false,
      removeAdditional: true,
      required: [
        'id',
        'color',
        'halign',
        'height',
        'icon',
        'margin',
        'position',
        'valign',
        'width',
      ],
      default: { id: 'button', type: 'button' },
      properties: {
        ...commonComponentProperties,
        color: { ...color, default: '@color.accent' },
        halign: { ...halign, default: halignTypes.END },
        height: { ...height, default: 'wrap' },
        icon,
        margin: { ...margin, default: 16 },
        position: { ...position, default: 'absolute' },
        valign: { ...valign, default: valignTypes.BOTTOM },
        width: { ...width, default: 'wrap' },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.CAMERA]: {
      description: '{{type}} {{id}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'camera', type: 'camera' },
      properties: {
        ...commonComponentProperties,
        height: { ...height, default: 300 },
        lens: { default: 'back', enum: ['back', 'front'], ui: UI.DROPDOWN },
        max_resolution: { default: 1024, type: 'number' },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.CANVAS]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'canvas', type: 'canvas' },
      properties: {
        ...commonComponentProperties,
        color: { ...color, default: '@color.primaryText' },
        stroke_width: {
          default: 2,
          oneOf: [{ pattern: '[0-9]+%' }, { type: 'number' }],
        },
        onchanged: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.CROPPER]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width', 'source'],
      default: { id: 'cropper', type: 'cropper' },
      properties: {
        ...commonComponentProperties,
        source,
        color_frame: { ...color, default: '@color.primaryDark' },
        color_guide: { ...color, default: '@color.primary' },
        color_handle: { ...color, default: '@color.primaryDark' },
        color_overlay: { ...color, default: '#96000000' },
        mode: {
          default: 'square',
          enum: [
            'fit',
            '4:3',
            '3:4',
            '16:9',
            '9:16',
            'free',
            'circle',
            'circle_square',
            'square',
          ],
          ui: UI.DROPDOWN,
        },
        format: {
          default: 'JPEG',
          enum: ['JPEG', 'PNG'],
          ui: UI.DROPDOWN,
        },
        quality: {
          default: 100,
          type: 'number',
          minimum: 10,
          maximum: 100,
          ui: UI.RANGE,
        },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.GROUP]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'content', 'height', 'orientation', 'scroll', 'width'],
      default: { id: 'group', type: 'group' },
      properties: {
        ...commonComponentProperties,
        scroll: { default: false, type: 'boolean' },
        orientation,
        radius,
        content: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.IMAGE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'image', type: 'image' },
      properties: {
        ...commonComponentProperties,
        online: { default: false, type: 'boolean' },
        field: { type: 'string' },
        file: { ...file, requiredGroup: true, key: 'image' },
        radius,
        scale,
        source: { ...source, requiredGroup: true },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.INPUTTEXT]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'inputtext', type: 'inputtext' },
      properties: {
        ...commonComponentProperties,
        border_color: { ...color, default: '@color.primaryText' },
        border_color_focus: { ...color, default: '@color.primary' },
        color,
        field: { type: 'string' },
        font,
        font_size: fontSize,
        format: { enum: ['plain', 'email', 'number', 'decimal', 'password'] },
        hint: multiLanguageText,
        hint_color: { ...color, default: '@color.secondaryText' },
        lines: { type: 'number' },
        max_length: { type: 'number' },
        radius,
        source,
        text,
        text_align: halign,
        text_valign: valign,
        onchanged: { default: [] },
        onlostfocus: { default: [] },
      },
      parameters: [
        ...commonComponentParameters,
        'color',
        'font',
        'font_size',
        'hint_color',
      ],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onchanged: { default: [] },
          onlostfocus: { default: [] },
        },
      },
    },
    [componentTypes.LIST]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'content', 'data_source', 'height', 'width'],
      default: { id: 'list', type: 'list' },
      properties: {
        ...commonComponentProperties,
        autoscroll: { default: true, type: 'boolean' },
        content: { default: [] },
        columns: { default: 2, type: 'number', minimum: 1 },
        data_source,
        orientation,
        selection_background: { ...color, default: '@color.primaryLight' },
        sticky: { default: true, type: 'boolean' },
      },
      parameters: [...commonComponentParameters, 'selected', 'value'],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.LOTTIE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'lottie', type: 'lottie' },
      properties: {
        ...commonComponentProperties,
        autoplay: { type: 'boolean', default: true },
        field: { type: 'string' },
        file: { ...file, requiredGroup: true, key: ['json', 'zip'] },
        loop: { type: 'boolean', default: true },
        repeat_count: { default: 1, type: 'number', minimum: 1, ui: UI.RANGE },
        repeat_mode: {
          type: 'string',
          default: 'restart',
          enum: ['restart', 'reverse'],
          ui: UI.DROPDOWN,
        },
        scale,
        source: { ...source, requiredGroup: true },
        speed: {
          default: 1,
          type: 'number',
          minimum: 0.1,
          maximum: 10,
          ui: UI.RANGE,
        },
        onfinished: { default: [] },
      },
      parameters: [...commonComponentParameters, 'progress', 'play', 'speed'],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onfinished: { default: [] },
        },
      },
    },
    [componentTypes.MAP]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'center', 'height', 'width', 'zoom'],
      default: { id: 'map', type: 'map' },
      properties: {
        ...commonComponentProperties,
        height: { ...height, default: 300 },
        map_type: {
          type: 'string',
          default: 'normal',
          enum: ['normal', 'hybrid', 'satellite', 'terrain'],
          ui: UI.DROPDOWN,
        },
        zoom: {
          default: 12,
          oneOf: [
            { type: 'number' },
            { pattern: '^(-?\\d+(.\\d+)?), ?s*(-?\\d+(.\\d+)?)$' },
            { pattern: '^@.*$' },
          ],
        },
        center: {
          default: '@app.location',
          oneOf: [
            { enum: ['@app.location'] },
            { pattern: '^(-?\\d+(.\\d+)?), ?s*(-?\\d+(.\\d+)?)$' },
            { pattern: '^@.*$' },
          ],
        },
        compass: { default: true, type: 'boolean' },
        allow_rotation: { default: true, type: 'boolean' },
        allow_scroll: { default: true, type: 'boolean' },
        allow_tilt: { default: true, type: 'boolean' },
        allow_zoom: { default: true, type: 'boolean' },
        buildings: { default: true, type: 'boolean' },
        data_source: { type: 'string' },
        data_naming: {
          additionalProperties: false,
          removeAdditional: true,
          type: 'object',
          default: {},
          properties: {
            type: { type: 'string', pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_.]*' },
            marker: { type: 'string', pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_]*' },
            circle: { type: 'string', pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_]*' },
            label: { type: 'string', pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_]*' },
            lat: { type: 'string', pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_.]*' },
            lng: { type: 'string', pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_.]*' },
            radius: { type: 'string', pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_.]*' },
            rotation: {
              type: 'string',
              pattern: '^[a-zA-Z0-9_][a-zA-Z0-9_.]*',
            },
          },
        },
        enable_location: { default: true, type: 'boolean' },
        indoor: { default: true, type: 'boolean' },
        map_toolbar: { default: true, type: 'boolean' },
        marker_icon: { type: 'string', key: 'image' },
        marker_label_h: { default: 0.5, type: 'number' },
        marker_label_v: { default: 0.5, type: 'number' },
        max_zoom: { default: 18, type: 'number' },
        min_zoom: { default: 12, type: 'number' },
        traffic: { default: true, type: 'boolean' },
        zoom_controls: { default: true, type: 'boolean' },
        onready: { default: [] },
        onchanged: { default: [] },
        onmarkerclick: { default: [] },
      },
      parameters: [
        ...commonComponentParameters,
        'clickedLat',
        'clickedLng',
        'lat',
        'lng',
        'location',
        'selected',
        'zoom',
      ],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onready: { default: [] },
          onchanged: { default: [] },
          onmarkerclick: { default: [] },
        },
      },
    },
    [componentTypes.PDFVIEWER]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['file', 'id', 'height', 'width'],
      default: { id: 'pdfviewer', type: 'pdfviewer' },
      properties: {
        ...commonComponentProperties,
        file: { ...file, key: 'pdf' },
        height: { ...height, default: 'fill' },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.PLAYER]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'player', type: 'player' },
      properties: {
        ...commonComponentProperties,
        artwork: { type: 'boolean', default: true },
        autoplay: { type: 'boolean', default: true },
        field: { type: 'string' },
        file: { ...file, requiredGroup: true, key: 'audio' },
        height: { ...height, default: 300 },
        loop: { type: 'boolean', default: true },
        source: { ...source, requiredGroup: true },
        onfinished: { default: [] },
        onready: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onfinished: { default: [] },
          onready: { default: [] },
        },
      },
    },
    [componentTypes.RANGE]: {
      description: '{{type}} {{id}} {{min}} {{max}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'range', type: 'range' },
      properties: {
        ...commonComponentProperties,
        field: { type: 'string' },
        min: { type: 'number', default: 0 },
        max: { type: 'number', default: 100 },
        step: { type: 'number', default: 1 },
        value: { type: 'number', default: 0 },
        source,
        thumb_color: { ...color, default: '@color.accent' },
        thumb_size: { type: 'number', default: 16 },
        bar_color: { ...color, default: '#26000000' },
        progress_color: { ...color, default: '@color.accent' },
        orientation: { ...orientation, default: orientationTypes.HORIZONTAL },
        onchanged: { default: [] },
      },
      parameters: [...commonComponentParameters, 'value'],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onchanged: { default: [] },
        },
      },
    },
    [componentTypes.SCANNER]: {
      description: '{{type}} {{id}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'scanner', type: 'scanner' },
      properties: {
        ...commonComponentProperties,
        height: { ...height, default: 300 },
        format: {
          default: {},
          properties: {
            code_128: { type: 'boolean', default: true },
            code_39: { type: 'boolean', default: true },
            code_93: { type: 'boolean', default: true },
            codabar: { type: 'boolean', default: true },
            ean_13: { type: 'boolean', default: true },
            ean_8: { type: 'boolean', default: true },
            itf: { type: 'boolean', default: true },
            upc_a: { type: 'boolean', default: true },
            upc_e: { type: 'boolean', default: true },
            qr_code: { type: 'boolean', default: true },
            aztec: { type: 'boolean', default: true },
            pdf417: { type: 'boolean', default: true },
            data_matrix: { type: 'boolean', default: true },
          },
        },
        onchanged: { default: [] },
      },
      parameters: [
        ...commonComponentParameters,
        'code',
        'content',
        'format',
        'type',
      ],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onchanged: { default: [] },
        },
      },
    },
    [componentTypes.SELECT]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width', 'options'],
      default: { id: 'select', type: 'select' },
      properties: {
        ...commonComponentProperties,
        border,
        color: { ...color, default: '@color.primaryText' },
        dropdown_background: { ...color, default: '#ffffff' },
        dropdown_color: { ...color, default: '@color.primaryText' },
        dropdown_font: font,
        dropdown_font_size: { type: 'number' },
        dropdown_padding: padding,
        font,
        font_size: { type: 'number' },
        mode: {
          enum: ['dropdown', 'dialog'],
          default: 'dropdown',
          ui: UI.DROPDOWN,
        },
        options: {
          default: [],
          additionalProperties: false,
          removeAdditional: true,
          type: 'array',
          items: {
            default: {},
            required: ['label', 'value'],
            properties: {
              label,
              value: { type: 'string' },
            },
          },
        },
        source,
        onchanged: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onchanged: { default: [] },
        },
      },
    },
    [componentTypes.STRIPECHECKOUT]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'stripe_checkout', type: 'stripe_checkout' },
      properties: {
        ...commonComponentProperties,
        email: { type: 'string' },
        height: { ...height, default: 'fill' },
        promotion_codes: { type: 'boolean', default: true },
        billing_address_collection: {
          default: 'required',
          enum: ['auto', 'required'],
        },
        onpurchased: { default: [] },
        oncancelled: { default: [] },
      },
      parameters: [...commonComponentParameters, 'value'],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onpurchased: { default: [] },
          oncancelled: { default: [] },
        },
      },
    },
    [componentTypes.SWITCH]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'switch', type: 'switch' },
      properties: {
        ...commonComponentProperties,
        color_on: { ...color, default: '@color.accent' },
        color_off: { ...color, default: '#ffffff' },
        field: { type: 'string' },
        source,
        onchanged: { default: [] },
      },
      parameters: [...commonComponentParameters, 'value'],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onchanged: { default: [] },
        },
      },
    },
    [componentTypes.TEXT]: componentText,
    [componentTypes.VIDEO]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'video', type: 'video' },
      properties: {
        ...commonComponentProperties,
        autoplay: { type: 'boolean', default: true },
        field: { type: 'string' },
        file: { ...file, requiredGroup: true, key: 'video' },
        height: { ...height, default: 300 },
        loop: { type: 'boolean', default: true },
        scale,
        source: { ...source, requiredGroup: true },
        onfinished: { default: [] },
        onready: { default: [] },
      },
      parameters: [
        ...commonComponentParameters,
        'duration',
        'play',
        'position',
        'volume',
      ],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onfinished: { default: [] },
          onready: { default: [] },
        },
      },
    },
    [componentTypes.WEB]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      default: { id: 'web', type: 'web' },
      properties: {
        ...commonComponentProperties,
        url: source,
        height: { ...height, default: 'fill' },
        width: { ...width, default: 'fill' },
        zoom: { type: 'boolean', default: true },
        file: { ...file, key: 'zip' },
        onerror: { default: [] },
        onloaded: { default: [] },
        onlocationchanged: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onerror: { default: [] },
          onloaded: { default: [] },
          onlocationchanged: { default: [] },
        },
      },
    },
    [componentTypes.YOUTUBE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width', 'video_id'],
      default: { id: 'youtube', type: 'youtube' },
      properties: {
        ...commonComponentProperties,
        video_id: { type: 'string', default: 'PniHTw479uA' },
        allow_fullscreen: { type: 'boolean', default: true },
        autoplay: { type: 'boolean', default: true },
        controls: { enum: ['default', 'minimal', 'none'] },
        onerror: { default: [] },
        onfinished: { default: [] },
      },
      parameters: [...commonComponentParameters],
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onerror: { default: [] },
          onfinished: { default: [] },
        },
      },
    },
  },
};

export const buttons = {
  title: 'Buttons',
  default: {},
  type: 'object',
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    negative: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        label,
        onclick: { default: [] },
      },
    },
    positive: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        label,
        onclick: { default: [] },
      },
    },
  },
};

export const content = {
  title: 'Content',
  default: [],
  type: 'array',
  uniqueItemProperties: ['id'],
  items: {
    ...components,
  },
  removeNull: true,
};

export const messaging = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    default_channel: { type: 'string', default: 'default' },
    icon: { type: 'string', key: 'image' },
    icon_color: color,
  },
};

export const remote_config = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    default_values: {
      default: {},
      additionalProperties: true,
      removeAdditional: true,
    },
  },
};

export const firebase = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    analytics: { type: 'boolean', default: true },
    authentication: { type: 'boolean', default: true },
    inapp_messaging: { type: 'boolean', default: false },
    firestore: { type: 'boolean', default: true },
    messaging,
    remote_config,
    storage: { type: 'boolean', default: true },
  },
};

export const permissions = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    internet: {
      default: true,
      type: 'boolean',
    },
    external_storage: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        onrefused: { default: [] },
      },
    },
    location: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        onrefused: { default: [] },
      },
    },
    phone_calls: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        onrefused: { default: [] },
      },
    },
  },
};

export const android = {
  default: {},
  required: ['package_name', 'version', 'version_name'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    certificate: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      required: ['file', 'store_password', 'key_alias', 'key_password'],
      properties: {
        file: { ...file, key: 'certificate' },
        store_password: { type: 'string', default: '', ui: UI.PASSWORD },
        key_alias: { type: 'string', default: '' },
        key_password: { type: 'string', default: '', ui: UI.PASSWORD },
      },
    },
    maps_api_key: { type: 'string' },
    package_name: { pattern: 'com\\..*', default: 'com.doyo.' },
    permissions,
    version: { type: 'number', default: 1 },
    version_name: { type: 'string', default: '1.0.0' },
    youtube_api_key: { type: 'string' },
  },
};

export const colors = {
  title: 'Colors',
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  required: [
    'accent',
    'primary',
    'primaryLight',
    'primaryDark',
    'primaryText',
    'secondaryText',
    'textOnPrimary',
    'textOnAccent',
  ],
  properties: {
    accent: { ...hexColor, default: '#facb3c' },
    primary: { ...hexColor, default: '#99cc66' },
    primaryLight: { ...hexColor, default: '#ccff96' },
    primaryDark: { ...hexColor, default: '#689b38' },
    primaryText: { ...hexColor, default: '#212121' },
    secondaryText: { ...hexColor, default: '#757575' },
    textOnPrimary: { ...hexColor, default: '#000000' },
    textOnAccent: { ...hexColor, default: '#000000' },
  },
  patternProperties: {
    '^[a-z][a-zA-Zd_]*$': hexColor,
  },
};

export const database = {
  title: 'Database',
  description: '{{type}} {{id}}',
  default: { id: 'database' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'source', 'type'],
  properties: {
    id,
    source: { ...file, key: 'database' },
    type: { enum: ['csv', 'json'], default: 'csv', ui: UI.DROPDOWN },
  },
};

export const databases = {
  title: 'Databases',
  default: [],
  additionalProperties: false,
  removeAdditional: true,
  type: 'array',
  uniqueItemProperties: ['id'],
  items: database,
};

export const dialogs = {
  title: 'Dialog',
  description: 'Each dialog of the app',
  default: { id: 'dialog' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    title,
    text,
    buttons,
    events: {
      ...events,
      properties: { onloaded },
    },
    content,
  },
};

export const fonts = {
  default: {},
  additionalProperties: true,
  patternProperties: {
    '^\\D*$': { ...fontRes, key: 'fontRes' },
  },
};

export const ios = {
  default: {},
  required: ['bundle_id', 'version', 'version_name'],
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    bundle_id: { pattern: 'com\\..*', default: 'com.doyo.' },
    version: { type: 'number', default: 1 },
    version_name: { type: 'string', default: '1.0.0' },
    privacy: {
      default: {},
      properties: {
        camera: {
          default: { purpose: '' },
          properties: {
            purpose: text,
          },
        },
        location: {
          default: { purpose: '' },
          properties: {
            purpose: text,
          },
        },
      },
    },
  },
};

export const langs = {
  title: 'Languages',
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  required: ['default'],
  properties: {
    default: { default: 'en', pattern: '^[a-z]{2}' },
    rules: { type: 'string', default: 'Use colloquial language' },
  },
  patternProperties: {
    '^[a-z]{2}': { type: 'boolean', default: true },
  },
};

export const networks = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    admob: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      required: ['android_app_id'],
      properties: {
        android_app_id: { pattern: '^[^ ]+$' },
        android_test_devices: { type: 'string' },
        ios_app_id: { pattern: '^[^ ]+$' },
      },
    },
    huawei: { type: 'boolean', default: true },
  },
};

export const placements = {
  title: 'Content',
  default: [],
  type: 'array',
  uniqueItemProperties: ['id'],
  items: {
    default: {},
    required: ['id', 'network', 'format', 'android_unit_id'],
    properties: {
      id,
      network: { type: 'string', key: 'network' },
      format: {
        type: 'string',
        enum: ['banner', 'interstitial', 'rewarded'],
        ui: UI.DROPDOWN,
      },
      android_unit_id: { pattern: '^[^ ]+$' },
      ios_unit_id: { pattern: '^[^ ]+$' },
    },
  },
};

export const monetization = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    ads: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        networks,
        placements,
      },
    },
  },
};

export const purchases = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    googleplay: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      required: ['products'],
      properties: {
        products: {
          default: [],
          additionalProperties: false,
          removeAdditional: true,
          type: 'array',
          items: {
            key: 'id',
            default: {},
            required: ['id', 'type'],
            properties: {
              id,
              type: { enum: ['eternal'], default: 'eternal' },
            },
          },
        },
      },
    },
  },
};

export const templates = {
  default: {
    url: '',
    type: 'mobile_app',
    subject: 'project',
    title: {
      en: '',
      es: '',
    },
    description: {
      en: '',
      es: '',
    },
    screenshots: [''],
  },
  additionalProperties: false,
  removeAdditional: true,
  required: ['url', 'type', 'subject', 'title', 'description', 'screenshots'],
  properties: {
    url: { type: 'string' },
    type: {
      enum: ['backend', 'flow', 'mobile_app', 'web'],
      default: 'mobile_app',
      ui: UI.DROPDOWN,
    },
    subject: {
      enum: ['project', 'views'],
      default: 'project',
      ui: UI.DROPDOWN,
    },
    title: titleNoDefault,
    description: titleNoDefault,
    screenshots: {
      default: [],
      additionalProperties: false,
      removeAdditional: false,
      type: 'array',
      items: {
        key: 'screenshots',
        type: 'string',
      },
    },
  },
};

export const theme = {
  default: {},
  additionalProperties: false,
  removeAdditional: true,
  properties: {
    border_radius: { type: 'number', default: 4 },
    body_bg: { ...color, default: '#ffffff' },
    body_text: { ...color, default: '#000000' },
    font_family: { type: 'string', default: '', key: 'font' },
  },
  patternProperties: {
    '^[a-z][a-zA-Zd_]*$': { oneOf: [{ type: 'number' }, { type: 'string' }] },
  },
};

export const view_wrappers = {
  title: 'ViewWrapper',
  description: 'Each wrapper of the app',
  default: { id: 'wrapper' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    actionbar,
    animation: { type: 'boolean', default: false },
    drawer,
    footer,
    header,
    orientation: {
      enum: ['portrait', 'landscape', 'auto'],
      default: 'portrait',
      ui: UI.DROPDOWN,
    },
    pager,
  },
};

export const views = {
  title: 'View',
  description: 'Each page of the app',
  default: { id: 'view' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'title', 'wrapper', 'content'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    icon: { ...file, key: 'image' },
    title,
    description: title,
    wrapper: { type: 'string', key: 'wrapper' },
    events: {
      ...events,
      properties: { onbackpressed, onexit, onloaded, onpreload, onreturned },
    },
    history: { type: 'boolean', default: true },
    private: { type: 'boolean', default: true },
    content: { default: [] },
  },
};

export const defs = {
  actionbar,
  analytics,
  and,
  android,
  api,
  background,
  buttons,
  color,
  colors,
  content,
  database,
  databases,
  dialogs,
  drawer,
  events,
  firebase,
  font,
  fonts,
  fontSize,
  footer,
  function: _function,
  _function,
  halign,
  header,
  height,
  if: _if,
  _if,
  ios,
  items,
  langs,
  margin,
  menus,
  messaging,
  monetization,
  multiLanguageText,
  name,
  networks,
  onweakpassword,
  or,
  orientation,
  padding,
  pager,
  permissions,
  placements,
  position,
  purchases,
  radius,
  scale,
  source,
  style,
  templates,
  text,
  title,
  valign,
  view_wrappers,
  views,
  width,
  while: _while,
  _while,
};

export default {
  actionbar,
  analytics,
  and,
  android,
  api,
  background,
  buttons,
  color,
  colors,
  content,
  database,
  dialogs,
  drawer,
  events,
  firebase,
  font,
  fonts,
  fontSize,
  footer,
  function: _function,
  _function,
  halign,
  header,
  height,
  if: _if,
  _if,
  ios,
  items,
  langs,
  margin,
  menus,
  messaging,
  monetization,
  multiLanguageText,
  name,
  networks,
  or,
  orientation,
  padding,
  pager,
  permissions,
  placements,
  position,
  purchases,
  radius,
  scale,
  source,
  style,
  templates,
  text,
  title,
  valign,
  view_wrappers,
  views,
  width,
  while: _while,
  _while,
};
