import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Form, Input } from 'antd';
import { ResizablePanel } from '../components';
import { useTranslation } from 'react-i18next';
import { getTextToShow } from '../_helpers';
import { useApi, useProjects } from './../hooks';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiClose } from '@mdi/js';

export const ProviderPanel = ({ activeKeys, data, listWrapper, onResize }) => {
  const { currentProvider, setCurrentProvider, updateProviderConfig } =
    useApi();
  // const location = useLocation();

  const userValues = {};

  const handleChange = (event, { provider }) => {
    const { target = {} } = event;
    const { name, value } = target;
    if (value.includes('*')) {
      return;
    }

    userValues[name] = value;

    updateProviderConfig({
      provider: provider?.name,
      data: userValues,
    });
  };

  const handleProviderPanelClose = () => {
    setCurrentProvider(null);
  };

  /*
  useEffect(() => {
    handleProviderPanelClose();
  }, [location]);
  
  useEffect(() => {
    listWrapper?.current && setTimeout(onResize, 300);
  }, [currentProvider]);
  */
  if (currentProvider === null) {
    return null;
  }

  return (
    <ResizablePanel
      name="provider"
      className={`h-100 ${!!currentProvider ? 'opened' : ''}`.trim()}
      axis="x"
      handle="w"
      minConstraints={[305, 0]}
      maxConstraints={[800, 0]}
      onResize={onResize}
    >
      <>
        {!!currentProvider && (
          <ProviderPanelContent
            activeKeys={activeKeys}
            provider={currentProvider}
            change={handleChange}
            close={handleProviderPanelClose}
          />
        )}
      </>
    </ResizablePanel>
  );
};

export const ProviderPanelContent = ({
  activeKeys,
  change: handleChange = () => {},
  close: handleClose = () => {},
  provider,
}) => {
  const { t } = useTranslation();
  let { config, urlHelp, name, label, description, headline, ready } = provider;
  description = getTextToShow(description);
  config = JSON.parse(provider.config);

  const isActive = activeKeys.includes(name);

  return (
    <div className="provider-parameters">
      {provider && (
        <div>
          {/* Information */}
          <div className="header border-0">
            <small>{label}</small>
            <div className="flex-grow-1" />
            <div className="close hoverable" onClick={handleClose}>
              <Icon path={mdiClose} size={1} />
            </div>
          </div>
          <div id="provider-info" className="p-3">
            <p>{description}</p>
            {provider.url && (
              <div>
                <div className="provider-info-label">
                  {t('projects.api_provider_url')}
                </div>
                <div>
                  <a href={provider.url} target="_doyoProvider">
                    {provider.url}
                  </a>
                </div>
              </div>
            )}
            {urlHelp && (
              <div>
                <div className="provider-info-label">
                  {t('projects.api_provider_url_help')}
                </div>
                <div>
                  <a href={urlHelp} target="_doyoProvider">
                    {provider.urlHelp}
                  </a>
                </div>
              </div>
            )}
          </div>
          {/* Configuration */}
          {config && isActive && (
            <div id="provider-config">
              <div className="header border-0">
                <small>{t('projects.api_provider_config_title')}</small>
                <div className="flex-grow-1" />
              </div>
              <div id="provider-config" className="p-3">
                <div className="mb--3">
                  {config.map?.(({ key, label, required }) => (
                    <Form.Item
                      key={`Provider-${name}`}
                      label={required ? label + ' *' : label}
                      labelCol={{ span: 24 }}
                      className="provider-info-label"
                    >
                      <Input
                        key={`Provider-${name}${key}${ready}`}
                        defaultValue={ready ? '************' : ''}
                        name={key}
                        placeholder="XXXXXXXXXXXX"
                        onBlur={(event) => handleChange(event, { provider })}
                      />
                      {ready && (
                        <Icon
                          path={mdiCheckCircle}
                          className="text-success position-absolute ms--4 mt-1"
                          size={1}
                        />
                      )}
                    </Form.Item>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
