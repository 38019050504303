import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { message } from 'antd';
import { getHeaders } from '.';
import { ProjectReducer, PROJECT } from './reducers';
import { PAGES, SECRETS, URL } from '../_config';
import {
  deleteFromLS,
  fileBlackList,
  getMimeType,
  getUniqueName,
  getTextToShow,
  isFont,
  isObject,
  isRootFile,
} from '../_helpers';
import {
  useAccounts,
  useAlerts,
  useDexie,
  useKustodio,
  useTimeline,
} from '../hooks';
import { getSchema, project as projectSchema } from '../schemas';
import defs from '../schemas/defs';
import {
  android as androidSchema,
  ios as iosSchema,
} from '../schemas/defs/common';
import { projectTypes } from '../schemas/project';
import mimeTypes from '../_helpers/mimeTypes.json';

const fileFolders = ['certificates', 'external', 'res'];

export const ProjectContext = createContext({});

const getMergeableFiles = (files) => {
  const projectFiles =
    /^(certificates\/|dialogs\/|external\/|jobs\/|menus\/|res\/|view_wrappers\/|views\/|flows\/|app\.json)/;
  return Object.values(files).filter(
    ({ name }) =>
      projectFiles.test(name) &&
      !fileBlackList.some((fname) => name.includes(fname))
  );
};

export const ProjectProvider = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { account: { id: accountId = 0 } = {} } = useAccounts();
  const files = useRef({ certificates: [], external: [], res: [] });
  const zips = useRef({});
  const apiRefs = useRef([]);
  const { addToDB, db, deleteFromDB, updateDB } = useDexie();
  const { deleteFromKustodio, listTags, projectFiles = [] } = useKustodio();
  const [state, dispatch] = useImmerReducer(
    ProjectReducer,
    PROJECT.INITIAL_STATE
  );
  const { project = {}, projects, status } = state;
  const {
    now = {},
    past = [],
    future = [],
    undo,
    redo,
    record,
    reset,
  } = useTimeline();
  const timeline = { now, past, future, undo, redo };
  const { alertSuccess } = useAlerts();

  const createProjectInBrain = useCallback(
    async (data = {}) => {
      try {
        if (!accountId) {
          return;
        }
        const existingProject = projects.edges.find(
          ({ node }) => node.name === data.name && node.uid
        );

        if (existingProject) {
          const updatedProject = await updateProject({
            brain: data,
            project: existingProject.node,
          });
          return { data: updatedProject };
        }
        if (data.description) {
          data.description = JSON.stringify(data.description);
        }
        if (data.title) {
          data.title = JSON.stringify(data.title);
        }
        if (data.type) {
          data.type = Object.values(projectTypes).includes(data.type)
            ? data.type
            : projectTypes.MOBILE_APP;
        }
        const response = await fetch(
          `${URL.API}/accounts/${accountId}/projects`,
          {
            body: JSON.stringify(data),
            headers: getHeaders({ auth: true }),
            method: 'POST',
          }
        );
        return await response.json();
      } catch (error) {
        return error;
      }
    },
    [accountId, projects.edges]
  );

  const getProjectName = useCallback(
    (name, { count = 0 } = {}) => {
      const projectNames = projects.edges.map(({ node }) => node.name);
      return getUniqueName(name, {
        names: projectNames,
        isId: true,
      }).normalize();
    },
    [projects]
  );

  const projectToZip = useCallback(async (project, options = {}) => {
    const { config } = options;
    const { data } = project;
    const jszip = new JSZip();

    const appendFolder = (directory, { parent, title }) => {
      const folder = parent.folder(title);
      Object.keys(directory).forEach((key) => {
        const item = directory[key];
        let { blob = {}, id = 'google-services' } = item;
        if (Array.isArray(item)) {
          appendFolder(item, { parent: folder, title: id });
          return;
        }
        let ext = id.split('.').pop();
        ext = ext === id ? 'json' : ext;
        const type = blob.type || 'application/json';
        blob = blob.size
          ? blob
          : new Blob([JSON.stringify(item, null, 1)], { type });
        folder.file(`${id.replace(`.${ext}`, '')}.${ext}`, blob);
      });
    };

    Object.keys(data).forEach((key) => {
      const item = data[key];
      if (Array.isArray(item)) {
        appendFolder(item, { parent: jszip, title: key });
        return;
      }
      const blob = new Blob([JSON.stringify(item, null, 1)], {
        type: 'application/json',
      });
      jszip.file(`${key}.json`, blob);
    });

    if (config) {
      const configJson = new Blob([JSON.stringify(config, null, 1)], {
        type: 'application/json',
      });
      jszip.file('config.json', configJson);
    }
    return jszip.generateAsync({ type: 'blob' });
  }, []);

  const getIdsToReplace = async ({ data, files, keyId, remote }) => {
    const idsToReplace = {};
    await Promise.all(
      files.map((file) => {
        const { name: filename } = file;
        const filenameArray = filename.split('/');
        const folder = filenameArray.shift();

        if (fileFolders.includes(folder)) {
          return true;
        } else {
          return file.async('string').then((str) => {
            const dir = filename.split('/');
            const key = dir[0].replace(/\.[\d\D]*/, '');
            let fileData;
            try {
              fileData = JSON.parse(str);
              if (!isRootFile(filename)) {
                let id = `${fileData.id || filename.split('/').pop()}`;
                if (keyId) {
                  id = id.replace(/\{\{[^}]*\}\}/g, () => keyId);
                }
                const confirmedId = getUniqueName(id, {
                  names: (data[key] || []).map(({ id }) => id),
                  isId: true,
                });
                fileData = {
                  ...fileData,
                  id,
                };
                if (!remote && id !== confirmedId) {
                  idsToReplace[id] = confirmedId;
                }
              }
            } catch (e) {}
          });
        }
      })
    );
    return idsToReplace;
  };
  const createProjectFromZip = useCallback(
    (
      zip,
      {
        external,
        icon,
        id: projectId,
        keyId,
        name,
        description,
        palette = {},
        project = state.project,
        merge = false,
        remote = false,
        settings,
        title,
        type,
      } = {}
    ) =>
      new Promise((resolve, reject) => {
        (async () => {
          let idsToReplace = {};
          let isInvalid = false;
          const uncompressedZip = await JSZip.loadAsync(zip);
          let { files } = uncompressedZip;
          merge = merge && !!project.id;
          files = getMergeableFiles(files);
          let data = merge ? project.data._clone() : {};
          if (merge) {
            if (!files.length) {
              reject(new Error(t('projects.invalid_merge')));
            }

            idsToReplace = await getIdsToReplace({
              data,
              files,
              keyId,
              remote,
            });
          }

          await Promise.all(
            files.map((file) => {
              const { name: filename } = file;
              const filenameArray = filename.split('/');
              const folder = filenameArray.shift();
              const name = filenameArray.join('/');

              if (fileFolders.includes(folder)) {
                if (
                  data[folder] &&
                  data[folder].some(
                    ({ id }) => id === filename.replace(`${folder}/`, '')
                  )
                ) {
                  return Promise.resolve();
                }
                return file.async('blob').then((blob) => {
                  if (!blob.size) {
                    return;
                  }
                  const ext = filename.split('.').pop();
                  blob = blob.slice(0, blob.size, getMimeType(ext));
                  const fileData = {
                    id: name,
                    blob,
                    url: window.URL.createObjectURL(blob),
                  };
                  if (isFont(file)) {
                    const alias = name
                      .replace('fonts/', '')
                      .toLowerCase()
                      .split('.')[0]
                      .replace(/[ _-]/g, '');
                    if (!data.app) {
                      data.app = {};
                    }
                    data.app.fonts = {
                      ...(data.app.fonts || {}),
                      [alias]: name,
                    };
                  }
                  data[folder] = [...(data[folder] || []), fileData];
                });
              } else {
                return file.async('string').then((str) => {
                  const dir = filename.split('/');
                  const key = dir[0].replace(/\.[\d\D]*/, '');
                  let fileData;
                  try {
                    fileData = JSON.parse(str);

                    if (!isRootFile(filename)) {
                      let id = `${fileData.id || filename.split('/').pop()}`;
                      if (keyId) {
                        id = id.replace(/\{\{[^}]*\}\}/g, () => keyId);
                      }
                      fileData = {
                        ...fileData,
                        id,
                      };

                      if (keyId) {
                        try {
                          fileData = JSON.stringify(fileData);
                          const count =
                            idsToReplace[id]?.match(/\d+/)?.[0] || '';
                          const idsToReplaceStr = `"id":"[^"]*"`;
                          const exp = new RegExp(idsToReplaceStr, 'gm');
                          fileData = fileData.replace(exp, (str) => {
                            return str.replace(
                              /\{\{[^}]*\}\}/,
                              `${keyId.replace(
                                /\/(.)/g,
                                (str, char) => `${char.toUpperCase()}`
                              )}${count}`
                            );
                          });
                          fileData = fileData.replace(
                            /\{\{[^}]*\}\}/gm,
                            () => keyId
                          );
                          fileData = JSON.parse(fileData);
                        } catch (e) {
                          console.error(e);
                        }
                      }

                      if (Object.keys(idsToReplace).length) {
                        try {
                          fileData = JSON.stringify(fileData);
                          const idsToReplaceStr = `"(dialog|id|menu|view|wrapper)":"(${Object.keys(
                            idsToReplace
                          ).join('|')})"`;
                          const exp = new RegExp(idsToReplaceStr, 'gm');
                          fileData = fileData.replace(exp, (str, key, id) => {
                            return str.replace(id, idsToReplace[id] || id);
                          });
                          fileData = JSON.parse(fileData);
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    }

                    if (
                      merge &&
                      key === 'app' &&
                      (fileData.type || projectTypes.MOBILE_APP) !==
                        project.data.app.type
                    ) {
                      isInvalid = true;
                      return;
                    }
                    data[key] = dir[1]
                      ? [...(data[key] || []), fileData]
                      : { ...fileData, ...(data[key] || {}) };
                  } catch (e) {
                    if (fileData && merge) {
                      delete fileData.name;
                      delete fileData.project_name;
                    }
                    data[key] =
                      typeof dir[1] !== 'undefined'
                        ? data[key] || []
                        : data[key]._merge(fileData);
                  }
                });
              }
            })
          );

          if (!isInvalid && merge) {
            let titleToShow = title;
            try {
              titleToShow = getTextToShow(JSON.parse(title));
            } catch (error) {}
            titleToShow &&
              alertSuccess({
                message: t('projects.merged', { name: titleToShow }),
                description: `<ul>${files
                  .map(({ name }) => `<li>${name}</li>`)
                  .join('')}</ul>`,
                open: true,
              });
            resolve({ data });
            return;
          }
          if (isInvalid || !data.app) {
            const { app = {} } = data;
            let { name } = app;
            reject(new Error(`${t('projects.invalid_project')}: ${name}`));
            return;
          }
          const id = projectId || `c${Date.now()}`;
          const { app = {} } = data;
          let {
            android = {},
            ios = {},
            name: defaultName,
            description: defaultDescription,
            project_name: defaultProjectName,
          } = app;
          let { package_name: packageName } = android;
          let { bundle_id: bundleId } = ios;
          const projectName = getProjectName(name || defaultProjectName);

          if (!packageName) {
            const { properties = {} } = androidSchema;
            const { package_name: packageNameProp = {} } = properties;
            const { default: defaultPackageName } = packageNameProp;
            packageName = `${defaultPackageName}${projectName}`;
          }
          if (!bundleId) {
            const { properties = {} } = iosSchema;
            const { bundle_id: bundleIdProp = {} } = properties;
            const { default: defaultBundleName } = bundleIdProp;
            bundleId = `${defaultBundleName}${projectName}`;
          }
          if ([projectTypes.MOBILE_APP].includes(type)) {
            data = {
              ...data,
              app: {
                ...data.app,
                android: {
                  ...(data.app.android || {}),
                  package_name: packageName,
                },
                ios: {
                  ...(data.app.ios || {}),
                  bundle_id: bundleId,
                },
              },
            };
            delete data.app.ios;
          }
          if ([projectTypes.WEB].includes(type)) {
            data = {
              ...data,
              app: {
                ...data.app,
                react: {
                  ...(data.app.react || {}),
                },
              },
            };
          }
          if ([projectTypes.BACKEND].includes(type)) {
            data = {
              ...data,
              app: {
                ...data.app,
              },
            };
          }

          if (external?.length) {
            external.forEach((file) => {
              const { name } = file;
              const fileData = {
                id: name,
                blob: file,
                url: window.URL.createObjectURL(file),
              };
              data.external = data.external.filter(({ id }) => id !== name);
              data.external.push(fileData);
            });
          }
          if (icon) {
            const fileData = {
              id: 'icon/icon_512.png',
              blob: icon,
              url: window.URL.createObjectURL(icon),
            };
            data.res = data.res.filter(({ id }) => id !== fileData.id);
            data.res.push(fileData);
          }

          if (settings) {
            data = data._merge(settings);
          }

          // backward compatibility
          data.app.type =
            data.app.type === 'android_app'
              ? projectTypes.MOBILE_APP
              : data.app.type;
          // -----------------------

          let uid = id,
            kustodio_id = id.match(/^[k][^-]*$/)
              ? parseInt(id.replace('k', ''))
              : project?.kustodio_id || null;
          name = name || defaultName;
          description = description || defaultDescription;
          let brainData = {};
          if (!merge) {
            const { data: _brainData = {} } = await createProjectInBrain({
              name: projectName,
              description: description || data.app.description,
              kustodio_id,
              title: name || data.app.name,
              type: type || data.app.type,
            });
            brainData = _brainData;
            const { uid: _uid, kustodio_id: kustodioId } = brainData;
            uid = _uid || uid;
            kustodio_id = kustodioId || kustodio_id;
          }

          const node = {
            ...brainData,
            id: uid,
            data: {
              ...data,
              app: {
                ...data.app,
                id: uid,
                name,
                description,
                project_name: projectName,
                colors: {
                  ...data.app.colors,
                  ...palette,
                },
              },
            },
          };

          resolve(node);
        })();
      }),
    [state.project, getProjectName, alertSuccess, t]
  );

  const addProjects = useCallback(
    async (projectsToAdd, { store = true } = {}) => {
      let { edges: projectsEdges } = projectsToAdd;
      projectsEdges = (
        await Promise.all(
          projectsEdges.map(async ({ node }) => {
            const { node: sameNode } =
              projects.edges.find(
                ({ node: { id, kustodio_id: kustodioId } }) =>
                  id === node.id ||
                  id === `k${node.kustodio_id}` ||
                  (kustodioId && kustodioId === node.kustodio_id)
              ) || {};
            if (sameNode) {
              let brain;
              if (!node.title) {
                brain = {
                  ...(brain || {}),
                  title: sameNode.data.app.name,
                };
              }
              if (!node.description) {
                brain = {
                  ...(brain || {}),
                  title: sameNode.data.app.description,
                };
              }
              if (!node.type) {
                brain = {
                  ...(brain || {}),
                  title: sameNode.data.app.type,
                };
              }
              await updateProject({
                ...(brain ? { brain } : {}),
                project: {
                  ...sameNode,
                  ...node,
                  id: node.uid,
                  oldId: sameNode.id,
                },
              });
              return;
            }
            node.data && addToDB(node);
            return { node };
          })
        )
      ).filter((project) => project);
      dispatch({
        type: PROJECT.ADD,
        payload: { projects: { edges: projectsEdges } },
      });
    },
    [addToDB, dispatch, projects.edges]
  );

  const deleteFromBrain = useCallback(
    async (project) => {
      if (!accountId) {
        return false;
      }
      const { id } = project;
      await fetch(`${URL.API}/accounts/${accountId}/projects/${id}`, {
        headers: getHeaders({ auth: true }),
        method: 'DELETE',
      });
      return true;
    },
    [accountId]
  );

  const deleteProject = useCallback(
    async (projectToDelete = {}) => {
      const { id } = projectToDelete;
      dispatch({
        type: PROJECT.DELETE,
        payload: { id, loading: true },
      });
      await deleteFromLS(projectToDelete);
      await deleteFromDB(projectToDelete);
      await deleteFromBrain(projectToDelete);
      await deleteFromKustodio(projectToDelete);

      if (project.id === id) {
        history.replace(PAGES.DASHBOARD);
      }

      dispatch({
        type: PROJECT.DELETE,
        payload: { id },
      });
    },
    [deleteFromDB, dispatch, project]
  );

  const findProjectRef = useCallback(
    (refToSearch) => {
      const { data = {} } = project;
      let result;
      const findRefs = ({ object = {}, parent = {} } = {}) => {
        if (object.function === 'set' && object.what === refToSearch) {
          result = object.value;
        }
        Object.keys(object).some((key) => {
          let value = object[key];
          if (isObject(value)) {
            findRefs({ object: value, parent: { key, value } });
          }
          return typeof result !== 'undefined';
        });
      };
      findRefs({ object: data });
      return result;
    },
    [project]
  );

  const getContent = (entry, resource) => {
    return new Promise((resolve) => {
      if (!entry) {
        resolve(null);
        return;
      }
      if (entry.content) {
        resolve(entry.content);
        return;
      }
      const { id } = resource;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', entry.url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const content = xhr.response;
          zips.current = {
            ...zips.current,
            [id]: zips.current[id].map((e) =>
              e.name === entry.name ? { ...e, content } : e
            ),
          };
          resolve(content);
        }
      };
      xhr.send();
    });
  };

  const getContentReplaced = useCallback(
    (entry, resource) => {
      return new Promise((resolve) => {
        if (!entry) {
          resolve();
          return;
        }
        const { contentReplaced } = entry;
        if (contentReplaced) {
          resolve(contentReplaced);
          return;
        }
        getContent(entry, resource).then((content) => {
          if (!content) {
            return;
          }
          const { entries = [], id } = resource;
          const replaceStr = {};
          entries.forEach((entry) => {
            replaceStr[entry.name] = entry.url;
          });
          const mimetypes = Object.keys(mimeTypes).reverse().join('|');
          const regex = new RegExp(
            `['"]*[./]*([\\w\\-/]+\\.(${mimetypes}))[?]*[#\\w]*(['"]*)`,
            'ig'
          );
          const contentReplaced = content.replace(
            regex,
            (s, key, type, quote) => {
              const resource = project.data.res.find(
                ({ name }) => name === key
              );
              if (replaceStr[key]) {
                return `${quote}${replaceStr[key]}${quote}`;
              } else if (resource) {
                const { url } = resource;
                return `"${url}"`;
              }
              return s;
            }
          );
          zips.current = {
            ...zips.current,
            [id]: zips.current[id].map((e) =>
              e.name === entry.name ? { ...e, contentReplaced } : e
            ),
          };
          resolve(contentReplaced);
        });
      });
    },
    [project.data?.res]
  );

  const getProjectIcon = useCallback(
    (_projectName) => {
      let res = [];
      try {
        let { name: projectName } = project;
        projectName = _projectName || projectName;
        const { node = {} } =
          projects.edges.find(({ node }) => node.name === projectName) || {};
        res = node.data.res || [];
      } catch (e) {
        res = [];
      }
      return (
        res.find(({ id }) => id === 'icon/icon_512.png') ||
        res
          .filter(({ id }) => id.includes('icon/icon_'))
          .sort(({ id: id1 }, { id: id2 }) => (id1 > id2 ? -1 : 1))[0] ||
        res.find(({ id }) => id.includes('icon/')) ||
        {}
      ).url;
    },
    [project, projects]
  );

  const getProjectRefs = useCallback(
    ({ needle = '', ...params }) => {
      const { data = {} } = project;
      const { app = {} } = data;
      const { theme = {}, type } = app;
      const { selectCases } = projectSchema;
      const { properties: typeProperties } = selectCases[type];
      const { data: dataSchema = {} } = typeProperties;
      const { properties: dataSchemaProperties } = dataSchema;
      const rootItems = Object.keys(dataSchemaProperties);
      const refs = [
        ...apiRefs.current.map((t) => `@api.${t}`),
        ...Object.keys(theme).map((t) => `@theme.${t}`),
      ];
      const findRefs = ({ object = {}, parent = {}, root = {} } = {}) => {
        const { key: parentKey, value: parentValue = [] } = parent;
        if (parentKey === 'content' || parentKey === 'form_groups') {
          const mainSchema = defs[type]?.views || {};
          const schema = getSchema(
            { path: parentKey, projectType: type, value: parentValue },
            mainSchema
          );
          const { items = {} } = schema;
          const { selectCases = {} } = items;
          parentValue
            .filter((component) => component)
            .forEach((component = {}) => {
              const { type: componentType, id, source } = component;
              const { parameters = [] } = selectCases[componentType] || {};

              needle.match(/@element\..+/) &&
                `@element.${id}`.includes(needle) &&
                refs.push(`@element.${id}`);

              if (needle.includes(`@element.${id}.`)) {
                refs.push(...parameters.map((key) => `@element.${id}.${key}`));
              }
              if (source && !refs.includes(source)) {
                refs.push(`${source}`);
              }
            });
        } else if (parentKey === 'colors') {
          refs.push(...Object.keys(parentValue).map((key) => `@color.${key}`));
        } else if (parentKey === 'databases') {
          refs.push(...parentValue.map(({ id }) => `@database.${id}`));
        } else if (parentKey === 'res') {
          refs.push(
            ...parentValue
              .filter(({ id }) => /^assets/.test(`${id}`))
              .map(({ id }) => `@assets.${id.replace('assets/', '')}`)
          );
        }
        if (object.function === 'set' && object.what) {
          if (
            (`${object.what}`.includes('@property.') ||
              `${object.what}`.includes('@firebase.') ||
              `${object.what}`.includes('@cookie.')) &&
            !refs.includes(object.what)
          ) {
            refs.push(`${object.what}`);
          }
          return;
        }
        if (object.into) {
          if (
            (`${object.into}`.includes('@property.') ||
              `${object.into}`.includes('@cookie.')) &&
            !refs.includes(object.into)
          ) {
            refs.push(`${object.into}`);
          }
          return;
        }

        Object.keys(object).forEach((key) => {
          let value = object[key];
          if (isObject(value)) {
            root = rootItems.includes(parent.key) ? { ...parent, value } : root;
            findRefs({ object: value, parent: { key, value }, root });
          }
        });
      };
      findRefs({ object: data });

      return refs;
    },
    [project]
  );

  const getProjects = useCallback(async () => {
    if (!accountId) {
      return;
    }
    try {
      dispatch({ type: PROJECT.STATUS, payload: { status: 'brain_loading' } });
      const { data = [] } = await (
        await fetch(`${URL.API}/accounts/${accountId}/projects`, {
          headers: getHeaders({ auth: true }),
        })
      ).json();
      let brainEdges = data.map(({ uid, ...node }) => ({
        node: {
          ...node,
          uid,
          id: uid,
        },
      }));
      await addProjects({ edges: brainEdges });
      dispatch({ type: PROJECT.STATUS, payload: { status: 'brain_loaded' } });
    } catch (error) {
      dispatch({ type: PROJECT.STATUS, payload: { status: 'brain_error' } });
    }
  }, [accountId, addProjects, dispatch]);

  const getProjectScreenshots = useCallback(
    (_projectName) => {
      let res = [];
      try {
        const { data = {} } = project;
        const { app = {} } = data;
        let { project_name: projectName } = app;
        projectName = _projectName || projectName;
        const { node = {} } =
          projects.edges.find(({ node }) => node.name === projectName) || {};
        res = node.data.res || [];
      } catch (e) {
        res = [];
      }
      return res.filter(({ id }) => id.includes('screenshots/')) || {};
    },
    [project, projects]
  );

  const loadApiRefs = (refs) => {
    apiRefs.current = refs;
  };

  const loadKustodioProjects = useCallback(async () => {
    dispatch({ type: PROJECT.STATUS, payload: { status: 'kustodio_loading' } });
    const kustodioEdges = projectFiles.map(({ id, file, name }) => ({
      node: {
        name: name.replace(/\.cdz$/, ''),
        id: `k${id}`,
      },
      file,
    }));
    const kustodioUnsyncEdges = (
      await Promise.all(
        kustodioEdges.map(async ({ file, node }) => {
          const { node: sameNode } =
            projects.edges.find(
              ({ node: { id, kustodio_id: kustodioId } }) =>
                `k${kustodioId}` === node.id
            ) || {};
          let project = { node: sameNode || {} };
          if (!sameNode?.data) {
            try {
              const cdz = await (await fetch(file.url)).blob();
              project = await loadDataFromCdz(cdz, node);
            } catch (error) {
              console.error(error);
              return;
            }
          }
          let brain;
          if (
            sameNode?.description === null &&
            project?.node?.data?.app?.description
          ) {
            brain = {
              ...(brain || {}),
              description: project.node.data.app.description,
            };
          }
          if (sameNode?.title === null) {
            brain = {
              ...(brain || {}),
              title: project.node.data.app.name,
            };
          }
          if (!sameNode?.type === null) {
            brain = {
              ...(brain || {}),
              type: project.node.data.app.type,
            };
          }
          if (brain || (sameNode && !sameNode.data)) {
            await updateProject({
              ...(brain ? { brain } : {}),
              data: project.node.data,
              project: {
                ...project.node,
                ...(sameNode || {}),
              },
            });
          }
          return !sameNode && project;
        })
      )
    ).filter((project) => project);
    kustodioUnsyncEdges.length && (await syncProjects(kustodioUnsyncEdges));
    dispatch({ type: PROJECT.STATUS, payload: { status: 'kustodio_loaded' } });
  }, [projectFiles, projects.edges]);

  const loadDataFromCdz = useCallback(
    (cdz, project) =>
      new Promise((resolve) => {
        (async () => {
          let files = await unzip({ blob: cdz, id: project.name });
          files = getMergeableFiles(files);
          let data = {};

          await Promise.all(
            files.map(async (file) => {
              let { blob, name: filename } = file;
              const filenameArray = filename.split('/');
              const folder = filenameArray.shift();
              const name = filenameArray.join('/');

              if (fileFolders.includes(folder)) {
                if (
                  data[folder] &&
                  data[folder].some(
                    ({ id }) => id === filename.replace(`${folder}/`, '')
                  )
                ) {
                  return Promise.resolve();
                }
                if (!blob.size) {
                  return false;
                }
                const ext = filename.split('.').pop();
                blob = blob.slice(0, blob.size, getMimeType(ext));
                const fileData = {
                  id: name,
                  blob,
                  url: window.URL.createObjectURL(blob),
                };
                if (isFont(file)) {
                  const alias = name
                    .replace('fonts/', '')
                    .toLowerCase()
                    .split('.')[0]
                    .replace(/[ _-]/g, '');
                  if (!data.app) {
                    data.app = {};
                  }
                  data.app.fonts = {
                    ...(data.app.fonts || {}),
                    [alias]: name,
                  };
                }
                data[folder] = [...(data[folder] || []), fileData];
                return true;
              } else {
                const str = await blob.text();
                const dir = filename.split('/');
                const key = dir[0].replace(/\.[\d\D]*/, '');
                let fileData;
                try {
                  fileData = JSON.parse(str);

                  if (!isRootFile(filename)) {
                    let id = `${fileData.id || filename.split('/').pop()}`;
                    fileData = {
                      ...fileData,
                      id,
                    };
                  }
                  data[key] = dir[1]
                    ? [...(data[key] || []), fileData]
                    : { ...fileData, ...(data[key] || {}) };
                } catch (e) {
                  data[key] =
                    typeof dir[1] !== 'undefined'
                      ? data[key] || []
                      : data[key]._merge(fileData);
                }
              }
            })
          );

          resolve({
            node: {
              ...project,
              data: {
                ...data,
                app: {
                  ...data.app,
                  id: project.id,
                  name: project.title
                    ? JSON.parse(project.title)
                    : data.app.name,
                  description: project.description
                    ? JSON.parse(project.description)
                    : data.app.description,
                  project_name: project.name || data.app.project_name,
                  type: project.type || data.app.type,
                },
              },
            },
          });
        })();
      }),
    []
  );

  const loadLocalDB = useCallback(async () => {
    let mounted = true;
    if (status !== 'init') {
      return;
    }
    let edges = [];
    try {
      edges = await db.projects.toArray();
    } catch (e) {}
    edges = edges
      .map((project) => {
        try {
          if (!project.data) {
            deleteFromDB(project);
            return null;
          }
          const certificates = project.data.certificates
            ? project.data.certificates.map((file) => ({
                ...file,
                url: window.URL.createObjectURL(file.blob),
              }))
            : [];
          const external = project.data.external
            ? project.data.external.map((file) => ({
                ...file,
                url: window.URL.createObjectURL(file.blob),
              }))
            : [];
          const res = project.data.res
            ? project.data.res.map((file) => ({
                ...file,
                url: window.URL.createObjectURL(file.blob),
              }))
            : [];
          const data = { ...project.data };
          certificates.length && (data.certificates = certificates);
          external.length && (data.external = external);
          res.length && (data.res = res);
          return {
            node: {
              ...project,
              data,
            },
          };
        } catch (e) {
          return {
            node: {
              ...project,
            },
          };
        }
      })
      .filter((node) => node);
    const projects = { edges };
    mounted && dispatch({ type: PROJECT.LIST, payload: { projects } });

    return () => {
      mounted = false;
    };
  }, [db.projects, deleteFromDB, status]);

  const selectProject = useCallback(
    async ({ id }) => {
      let project = projects.edges.find(({ node }) => node.id === id);
      if (!project?.node?.data) {
        project.node && (await deleteProject(project.node));
        history.replace(PAGES.DASHBOARD);
        message.error(t('errors.projects.corrupted'), 3);
        return;
      }
      updateCacheFiles(project.node.data);
      let { node = {} } = project;
      let { data = {} } = node;
      let { app = {} } = data;
      let { type = projectTypes.MNOBILE_APP, android = {} } = app;
      type = Object.values(projectTypes).includes(type)
        ? type
        : projectTypes.MOBILE_APP;
      const { firebase } = android;
      if (firebase) {
        project = {
          ...project,
          node: {
            ...project.node,
            data: {
              ...project.node.data,
              app: {
                ...project.node.data.app,
                firebase,
              },
            },
          },
        };
      }
      project = {
        ...project,
        node: {
          ...project.node,
          data: {
            ...project.node.data,
            app: {
              ...project.node.data.app,
              type,
            },
          },
        },
      };
      reset(project.node);
      dispatch({
        type: PROJECT.SELECT,
        payload: { project },
      });
    },
    [dispatch, projects.edges, reset]
  );

  const setProjectStatus = useCallback((status) => {
    dispatch({
      type: PROJECT.STATUS,
      payload: { status },
    });
  }, []);

  const setReference = useCallback(
    (ref) => {
      dispatch({
        type: PROJECT.REFERENCE,
        payload: { project, ref },
      });
    },
    [project.id]
  );

  const syncProjects = useCallback(
    async (unsyncProjects) => {
      if (projects.init || !accountId) {
        return;
      }
      unsyncProjects =
        unsyncProjects ||
        projects.edges.filter(({ node }) => node.id.match(/^[ck][^-]*$/));
      const syncProjects = (
        await Promise.all(
          unsyncProjects.map(({ node }) => {
            const title = node.data?.app?.name || node.title;
            const description = node.data?.app?.description || node.description;
            const type = node.data?.app?.type || node.type;
            const data = {
              name: node.data?.app?.project_name || node.name,
              kustodio_id: node.id.match(/^[k][^-]*$/)
                ? parseInt(node.id.replace('k', ''))
                : null,
            };
            if (title) {
              data.title = title;
            }
            if (description) {
              data.description = description;
            }
            if (type) {
              data.type = type;
            }
            return createProjectInBrain(data);
          })
        )
      )
        .map(({ data: brain }, index) => {
          const prevProject = unsyncProjects[index].node;
          return {
            node: {
              ...prevProject,
              ...brain,
              id: brain?.uid,
              oldId: prevProject.id,
            },
          };
        })
        .filter(({ node }) => node.id);
      await addProjects({ edges: syncProjects });
    },
    [accountId, createProjectInBrain, projects]
  );

  const unselectProject = () => {
    dispatch({ type: PROJECT.SELECT, payload: { project: {} } });
  };

  const unzip = async (resource) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const { blob: zip, id } = resource;
          const uncompressedZip = await JSZip.loadAsync(zip);
          const { files = {} } = uncompressedZip;
          const entries = (
            await Promise.all(
              Object.values(files).map(async (file) => {
                const { name } = file;
                return file.async('blob').then(async (blob) => {
                  if (!blob.size) {
                    return;
                  }
                  const ext = name.split('.').pop();
                  blob = blob.slice(0, blob.size, getMimeType(ext));
                  const url = window.URL.createObjectURL(blob);
                  return {
                    name,
                    blob,
                    url,
                  };
                });
              })
            )
          ).filter((entry) => entry);
          zips.current = { ...zips.current, [id]: entries };
          resolve(entries);
        } catch (e) {
          console.error('>>>', e);
        }
      })();
    });
  };

  const updateCacheFiles = (_files) => {
    if (!_files) {
      return;
    }
    if (
      _files.certificates &&
      !files.current.certificates._equals(_files.certificates)
    ) {
      const newFiles = _files.certificates.filter(({ blob }) => blob.size);
      files.current.certificates = [
        ...files.current.certificates.filter(
          ({ id }) => !newFiles.some((newFile) => newFile.id === id)
        ),
        ...newFiles,
      ];
    }
    if (_files.external && !files.current.external._equals(_files.external)) {
      const newFiles = _files.external.filter(({ blob }) => blob.size);
      files.current.external = [
        ...files.current.external.filter(
          ({ id }) => !newFiles.some((newFile) => newFile.id === id)
        ),
        ...newFiles,
      ];
    }
    if (_files.res && !files.current.res._equals(_files.res)) {
      const newFiles = _files.res.filter(({ blob }) => blob.size);
      files.current.res = [
        ...files.current.res.filter(
          ({ id }) => !newFiles.some((newFile) => newFile.id === id)
        ),
        ...newFiles,
      ];
    }
  };

  const updateProject = useCallback(
    async ({ data: dataProp, brain, project: projectProp }) => {
      const projectToUpdate = projectProp || project;
      const projectId = projectToUpdate?.oldId || projectToUpdate?.id;
      delete projectToUpdate.oldId;
      if (!projectId) {
        return;
      }
      if (brain && accountId) {
        if (brain.description) {
          brain.description = JSON.stringify(brain.description);
        }
        if (brain.title) {
          brain.title = JSON.stringify(brain.title);
        }
        if (brain.type) {
          brain.type = Object.values(projectTypes).includes(brain.type)
            ? brain.type
            : projectTypes.MOBILE_APP;
        }
        fetch(
          `${URL.API}/accounts/${accountId}/projects/${projectToUpdate.id}`,
          {
            body: JSON.stringify(brain),
            headers: getHeaders({ auth: true }),
            method: 'PATCH',
          }
        );
      }
      let updatedProject = { ...projectToUpdate, ...(brain || {}) };
      let data = dataProp || projectToUpdate.data;
      if (dataProp) {
        const isCurrentProject = projectId === project.id;
        if (isCurrentProject) {
          updateCacheFiles(data);
          if (data.certificates) {
            data.certificates = data.certificates.map((_certificates) => {
              const { blob } =
                files.current.certificates.find(
                  ({ id }) => id === _certificates.id
                ) || _certificates;
              return {
                ..._certificates,
                blob,
              };
            });
          }
          if (data.external) {
            data.external = data.external.map((_external) => {
              const { blob } =
                files.current.external.find(({ id }) => id === _external.id) ||
                _external;
              return {
                ..._external,
                blob,
              };
            });
          }
          if (data.res) {
            data.res = data.res.map((_res) => {
              const { blob } =
                files.current.res.find(({ id }) => id === _res.id) || _res;
              return {
                ..._res,
                blob,
              };
            });
          }
          updatedProject = { ...updatedProject, data };
        }
      }

      updateDB(projectId, updatedProject);
      dispatch({
        type: PROJECT.UPDATE,
        payload: { project: updatedProject },
      });
      if (brain?.name && history.location.pathname.includes(PAGES.SETTINGS)) {
        const pathArray = history.location.pathname.split('/');
        pathArray[2] = brain.name;
        const pathname = pathArray.join('/');
        history.replace(pathname);
      }
      return updatedProject;
    },
    [accountId, dispatch, project.id, updateDB]
  );

  useMemo(() => {
    const _now = now._clone();
    const _project = project._clone();
    if (_now._equals({}) || _now._equals(_project)) {
      return;
    }
    const { data } = _now;
    updateProject({ data, project: _project });
  }, [now]);

  useMemo(() => {
    const _project = project._clone();
    const _now = now._clone();
    if (_project._equals(_now)) {
      return;
    }
    if (_project._equals({})) {
      files.current = { certificates: [], external: [], res: [] };
      return;
    }
    updateCacheFiles(project.data);
    record(_project);
  }, [project]);

  useEffect(() => {
    switch (status) {
      case 'init':
        loadLocalDB();
        break;
      case 'db_loaded':
        getProjects();
        break;
      case 'brain_loaded':
        listTags();
        break;
      case 'kustodio_loaded':
        syncProjects();
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    accountId &&
      dispatch({ type: PROJECT.STATUS, payload: { status: 'init' } });
  }, [accountId]);

  useEffect(() => {
    projectFiles.length && loadKustodioProjects();
  }, [projectFiles]);

  useEffect(() => {
    !projects.init &&
      dispatch({ type: PROJECT.STATUS, payload: { status: 'db_loaded' } });
  }, [projects.init]);

  useEffect(() => {
    if (!projects.edges.length) {
      return;
    }
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: SECRETS.INTERCOM_APP_ID,
      projects_count: projects.edges.length,
    });
    window.Intercom('update');
  }, [projects.edges.length]);

  if (process.env.NODE_ENV === 'development') {
    console.log('PROJECT >>>', state);
  }

  return (
    <ProjectContext.Provider
      value={{
        ...state,
        ...timeline,
        addProjects,
        createProjectFromZip,
        deleteProject,
        findProjectRef,
        getContent,
        getContentReplaced,
        getProjectIcon,
        getProjectName,
        getProjectScreenshots,
        getProjectRefs,
        loadApiRefs,
        projectToZip,
        selectProject,
        setProjectStatus,
        setReference,
        unselectProject,
        updateProject,
        unzip,
        zips,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export const ProjectConsumer = ProjectContext.Consumer;
export default ProjectContext;
