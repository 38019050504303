import React, { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import {
  AccountContext,
  GeneralContext,
  IdentifierContext,
  IDENTIFIER_TYPE,
} from '../../context';
import { CirclePreloader } from '../../components';
import IntlTelInput from 'react-intl-tel-input';

function clearError() {
  const target = document.forms.NewIdentifierForm.subject;
  target.dataset.validate === 'false' && delete target.dataset.validate;
  target.setCustomValidity && target.setCustomValidity('');
}

const Alias = ({ setNewIdentifier }) => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t('common.alias')}>
      <Input
        type="alias"
        name="subject"
        minLength="3"
        maxLength="48"
        required
        onChange={(event) => {
          clearError();
          const { value: subject } = event.currentTarget;
          setNewIdentifier({ type: IDENTIFIER_TYPE.ALIAS, subject });
        }}
      />
    </Form.Item>
  );
};

const Domain = ({ setNewIdentifier }) => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t('common.domain')}>
      <Input
        type="domain"
        name="subject"
        maxLength="253"
        onChange={(event) => {
          clearError();
          const { value: subject } = event.currentTarget;
          setNewIdentifier({ type: IDENTIFIER_TYPE.DOMAIN, subject });
        }}
      />
    </Form.Item>
  );
};

const Email = ({ setNewIdentifier }) => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t('common.email')}>
      <Input
        type="email"
        name="subject"
        maxLength="254"
        placeholder={t('common.email_placeholder')}
        onChange={(event) => {
          clearError();
          const { value: subject } = event.currentTarget;
          setNewIdentifier({ type: IDENTIFIER_TYPE.EMAIL, subject });
        }}
      />
    </Form.Item>
  );
};

const Phone = ({ setNewIdentifier }) => {
  const { t } = useTranslation();
  const phone = useRef();
  const { account = {} } = useContext(AccountContext);
  const { location } = useContext(GeneralContext);
  let { customer = {} } = account;
  let { country } = customer;
  country = country || location.country;
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const preferredCountries = [];
  location.country && preferredCountries.push(location.country);
  country && location.country !== country && preferredCountries.push(country);

  useMemo(() => {
    setPhoneNumber('');
  }, [countryCode]);

  useMemo(() => {
    if (!countryCode) {
      const element =
        phone.current && phone.current.querySelector('.selected-dial-code');
      if (element) {
        setCountryCode(element.innerText);
      }
    }
  }, []);

  const handleCountryChange = (currentNumber, selectedCountryData) => {
    clearError();
    const { dialCode } = selectedCountryData;
    const countryCode = `+${dialCode}`;
    setCountryCode(countryCode);
  };

  const handlePhoneChange = (event, value) => {
    clearError();
    const phoneNumber = value && parseInt(value.replace(/[^\d]/g, ''), 10);
    const subject = `${countryCode}${phoneNumber}`;
    setPhoneNumber(phoneNumber);
    setNewIdentifier({
      type: IDENTIFIER_TYPE.PHONE,
      subject,
    });
  };

  return (
    <div ref={phone}>
      <Form.Item label={t('common.phone')}>
        <IntlTelInput
          fieldName="subject"
          separateDialCode={true}
          preferredCountries={preferredCountries}
          onPhoneNumberChange={handlePhoneChange}
          onPhoneNumberBlur={handlePhoneChange}
          onSelectFlag={handleCountryChange}
          defaultCountry={country.toLowerCase()}
          value={window.intlTelInputUtils.formatNumber(
            `${phoneNumber}`,
            country,
            2
          )}
          css={['intl-tel-input', 'form-control']}
          utilsScript="libphonenumber.js"
          format
        />
      </Form.Item>
    </div>
  );
};

export const NewIdentifierWrapper = ({ type: propsType }) => {
  const { t } = useTranslation();
  const { account = {} } = useContext(AccountContext);
  const { createIdentifier, loading } = useContext(IdentifierContext);
  let { id: accountId } = account;
  const [newIdentifier, setNewIdentifier] = useState({
    type: propsType,
    subject: '',
  });
  const { type } = newIdentifier;

  return (
    <Form
      id="NewIdentifierForm"
      onFinish={async () => {
        await createIdentifier({
          variables: { accountId, ...newIdentifier },
        });
      }}
    >
      {loading && <CirclePreloader />}
      {!propsType && (
        <Form.Item label={t('common.type')}>
          <Input
            type="select"
            onChange={(event) => {
              clearError();
              setNewIdentifier({
                type: event.currentTarget.value,
                subject: '',
              });
            }}
          >
            <option value={IDENTIFIER_TYPE.ALIAS}>{t('common.alias')}</option>
            <option value={IDENTIFIER_TYPE.DOMAIN}>{t('common.domain')}</option>
            <option value={IDENTIFIER_TYPE.EMAIL}>{t('common.email')}</option>
            <option value={IDENTIFIER_TYPE.PHONE}>{t('common.phone')}</option>
          </Input>
        </Form.Item>
      )}

      {(type === IDENTIFIER_TYPE.ALIAS && (
        <Alias setNewIdentifier={setNewIdentifier} />
      )) ||
        (type === IDENTIFIER_TYPE.DOMAIN && (
          <Domain setNewIdentifier={setNewIdentifier} />
        )) ||
        (type === IDENTIFIER_TYPE.EMAIL && (
          <Email setNewIdentifier={setNewIdentifier} />
        )) ||
        (type === IDENTIFIER_TYPE.PHONE && (
          <Phone setNewIdentifier={setNewIdentifier} />
        ))}

      <Form.Item className="mb-0 text-end">
        <Button type="primary" htmlType="submit">
          {t('common.add')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NewIdentifierWrapper;
