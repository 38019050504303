export const VERIFICATION = {
  INITIAL_STATE: {
    error: undefined,
    verifications: undefined,
    verified: undefined,
  },

  CREATE: 'VERIFICATION_CREATE',
  PUSH: 'VERIFICATION_PUSH',
  PULL: 'VERIFICATION_PULL',
  ERROR: 'VERIFICATION_ERROR',
};

export const VerificationReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case VERIFICATION.CREATE:
      break;
    case VERIFICATION.PULL:
      draft.verified = payload.pullVerification;
      break;
    case VERIFICATION.PUSH:
      draft.verified = payload.pushVerification;
      break;

    case VERIFICATION.ERROR:
      draft.error = payload.error;
      break;

    default:
      return draft;
  }
};
