import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import { Button, Layout } from 'antd';
import { Container } from 'react-bootstrap';
import { DOYO_PLANS, PAGES } from '../../_config';
import { useAccounts } from '../../hooks';
import { PlaceholderImage, PlaceholderText, Sidebar } from '../../components';
import planIcons from '../../assets/svg/plans';
import features from '../PricingPage/features.json';

const Placeholders = () => {
  return (
    <React.Fragment>
      <h1 className="pt-2">
        <PlaceholderImage
          className="mx-auto rounded-circle"
          style={{ width: '100px', height: '100px' }}
        />
      </h1>
      <pre className="list-unstyled mt-2 pt-3 mb-4">
        <PlaceholderText
          size="lg"
          className="mx-auto mb-2"
          style={{ width: '150px' }}
        />
        <PlaceholderText
          size="lg"
          className="mx-auto mb-2"
          style={{ width: '400px' }}
        />
      </pre>
      <p>
        <PlaceholderText className="mx-auto mb-3" style={{ width: '200px' }} />
        <PlaceholderText
          size="sm"
          className="mx-auto mb-2"
          style={{ width: '180px' }}
        />
        <PlaceholderText
          size="sm"
          className="mx-auto mb-2"
          style={{ width: '250px' }}
        />
        <PlaceholderText
          size="sm"
          className="mx-auto mb-2"
          style={{ width: '320px' }}
        />
        <PlaceholderText
          size="sm"
          className="mx-auto mb-2"
          style={{ width: '150px' }}
        />
        <PlaceholderText
          size="sm"
          className="mx-auto mb-2"
          style={{ width: '200px' }}
        />
      </p>
      <Button disabled className="mt-5">
        <PlaceholderText
          size="sm"
          className="bg-white mx-auto my-1"
          style={{ width: '140px' }}
        />
      </Button>
    </React.Fragment>
  );
};

const Free = () => {
  const { t } = useTranslation();
  const { account } = useAccounts();
  const { subscription = {} } = account;
  const { balance = false } = subscription;

  return (
    <React.Fragment>
      <h1 className="pt-2">
        <img src={planIcons.planfree} width="100" alt="Plan free" />
      </h1>
      <h3>Plan FREE</h3>
      {balance !== false && (
        <h5 className="text-muted">{t('subscription.balance', { balance })}</h5>
      )}

      <Link to={PAGES.UPGRADE_PLANS}>
        <Button type="primary">{t('header.upgrade_account')}</Button>
      </Link>
    </React.Fragment>
  );
};

const Subscription = () => {
  const { t } = useTranslation();
  const { account } = useAccounts();
  const { subscription = {} } = account;
  const { balance, plan: planQuantity } = subscription;
  const plan =
    DOYO_PLANS.find(({ quantity }) => quantity >= parseInt(planQuantity, 10)) ||
    {};
  const planIndex =
    DOYO_PLANS.findIndex(
      ({ quantity }) => quantity >= parseInt(planQuantity, 10)
    ) || {};
  const { quantity = planQuantity } = plan;
  const includes = Object.entries(features).map(([key, value]) => {
    if (!Array.isArray(value)) {
      return [
        key,
        Object.entries(value).map(([key, value]) => [key, value[planIndex]]),
      ];
    }
    return [key, value[planIndex]];
  });
  return (
    <React.Fragment>
      <h1 className="pt-2">
        <img
          src={planIcons[`plan${quantity}`]}
          width="100"
          alt={`Plan ${quantity}`}
        />
      </h1>
      <h3>Plan {quantity} DC</h3>
      <h5 className="text-muted">{t('subscription.balance', { balance })}</h5>
      <div className="mt-5 d-flex flex-column align-items-center">
        <Link to={PAGES.UPGRADE_PLANS}>
          <Button type="primary">{t('header.upgrade_account')}</Button>
        </Link>
        <a href="mailto:sales@doyo.email?subject=Cancel%20subscription">
          <Button type="link" className="mt-2 mx-auto">
            {t('subscription.cancel_current_subscription')}
          </Button>
        </a>
      </div>
      <hr className="my-5 w-25 mx-auto" />
      <p className="text-uppercase">{t('plans.whats_included')}</p>
      <ul className="list-unstyled mb-5 pl-4 font-size-15">
        {includes
          .filter(([, value]) => !Array.isArray(value))
          .map(([key, value] = {}) => (
            <li key={`Include–${key}`} className="mt-2">
              {value === true && (
                <Icon className="text-success me-2" path={mdiCheck} size={1} />
              )}
              <strong className="small">{t(`features.${key}`)}</strong>
              {value !== true && (
                <>
                  : <strong className="text-primary small">{value}</strong>
                </>
              )}
            </li>
          ))}
        {includes
          .filter(([, value]) => Array.isArray(value))
          .reduce(
            (all, [key, value]) => [
              ...all,
              ...value.map(([k, v]) => [key, k, v]),
            ],
            []
          )
          .map(([rootKey, key, value] = {}) => {
            return (
              <li key={`Include–${key}`} className="mt-2">
                {value === true && (
                  <Icon
                    className="text-success me-2"
                    path={mdiCheck}
                    size={1}
                  />
                )}
                <strong className="me-2 small">
                  {t(`features.${rootKey}`)}:
                </strong>
                <small>{t(`features.${key}`)}</small>
                {value !== true && (
                  <>
                    : <strong className="text-primary small">{value}</strong>
                  </>
                )}
              </li>
            );
          })}
      </ul>
    </React.Fragment>
  );
};

export const SubscriptionPage = () => {
  const { t } = useTranslation();
  const { account = {} } = useAccounts();
  const { customer } = account;
  const { subscription } = customer || {};
  const { plan } = subscription || {};

  return (
    <Layout className="account">
      <Layout.Sider className="sidebar-main" width={100}>
        <Sidebar />
      </Layout.Sider>
      <Layout>
        <Layout.Content className="p-3">
          <Container>
            <h4 className="mt-2 mb-3">{t('header.subscription')}</h4>
            <hr className="mb-5" />
            <div className="text-center">
              {(typeof customer === 'undefined' && <Placeholders />) ||
                (plan && <Subscription />) || <Free />}
            </div>
          </Container>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default SubscriptionPage;
