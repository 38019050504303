import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toBlob } from 'html-to-image';
import ls from 'local-storage';
import Icon from '@mdi/react';
import {
  mdiFlag,
  mdiFlagOutline,
  mdiUnfoldMoreHorizontal,
  mdiUnfoldLessHorizontal,
} from '@mdi/js';
import { Button, Layout, message, Tooltip } from 'antd';
import { PAGES, URL } from '../../_config';
import { deleteFromLS, isObject, mobileCheck, selectAll } from '../../_helpers';
import {
  Actions,
  CirclePreloader,
  DropWrapper,
  EditorWrapper,
  EventPanel,
  JsonEditorWrapper,
  RegisterModal,
  ResizablePanel,
  Sidebar,
  VerificationModal,
} from '../../components';
import { ComponentPanel } from '../../components/Editor';
import { ComponentProvider } from '../../context/Components';
import { EventProvider } from '../../context/Events';
import { PREVIEW } from '../../context/reducers';
import { PreviewWrapper } from '../../preview';
import { getSchema } from '../../schemas';
import { projectTypes } from '../../schemas/project';
import {
  useEvents,
  useProjects,
  useSockets,
  useValidation,
  useViewer,
} from '../../hooks';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

function storeCurrent({ id, path }) {
  const user = ls('user') || {};
  let { _state = {} } = user;

  Object.keys(_state).forEach((path) => {
    const value = _state[path];
    const { current } = value;
    _state[path] = current
      ? { ..._state[path], expanded: true, current: false }
      : value;
  });

  let parentPaths = path.split('/');
  while (parentPaths.join('/')) {
    const parentPath = parentPaths.join('/');
    const currentPathData = parentPath === path ? { current: true, id } : {};
    _state[parentPath] = {
      ...(_state[parentPath] || {}),
      expanded: true,
      ...currentPathData,
    };
    parentPaths.pop();
  }
  ls('user', { ...user, _state });
}

const getComponentsState = ({ key, value }) => {
  if (Array.isArray(value)) {
    return value.some(
      (item) => getComponentsState({ value: item }) === 'collapsed'
    )
      ? 'collapsed'
      : 'expanded';
  }
  if (value?.content) {
    let state = getComponentsState({ value: value.content });
    if (typeof key === 'undefined') {
      state =
        state === 'expanded' && value.__expanded ? 'expanded' : 'collapsed';
    }
    return state;
  }
  return 'expanded';
};

const ComponentEditor = ({
  _key: key,
  value,
  path,
  getProjectRefs,
  projectType,
  handleContentChange,
  i,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState();
  const sync = useRef(true);

  const syncState = () => {
    const state = getComponentsState({ key, value });
    setState(state);
  };

  const handleToggleExpanded = () => {
    const _state = state === 'expanded' ? 'collapsed' : 'expanded';
    const expanded = _state === 'expanded';
    setState(_state);

    let _value = value._clone();

    function toggleExpanded(value, { expanded }) {
      if (Array.isArray(value)) {
        return value.map((item) => toggleExpanded(item, { expanded }));
      }
      if (value?.content) {
        value = {
          ...value,
          content: toggleExpanded(value.content, { expanded }),
          __expanded: true,
        };
        if (!expanded) {
          delete value.__expanded;
        }
      }
      return value;
    }
    _value = toggleExpanded(_value, { expanded });
    handleContentChange({ value: _value });
  };

  const handleChange = (json, options = {}) => {
    const { expanded } = options;
    if (typeof expanded !== 'undefined') {
      sync.current = true;
    }
    handleContentChange(json, { ...options, key });
  };

  useMemo(() => {
    if (sync) {
      syncState();
      sync.current = false;
    }
  }, [value]);

  return (
    <>
      <div className="header" style={{ top: `40px` }}>
        <small className="flex-grow-1">
          {t('common.content')} {key}
        </small>

        <Button
          className="p-0"
          icon={
            <Tooltip
              title={t(
                `common.${state === 'expanded' ? 'collapse_all' : 'expand_all'}`
              )}
            >
              <Icon
                path={
                  state === 'expanded'
                    ? mdiUnfoldLessHorizontal
                    : mdiUnfoldMoreHorizontal
                }
                size={0.8}
              />
            </Tooltip>
          }
          shape="circle"
          size="small"
          type="text"
          onClick={handleToggleExpanded}
        />
      </div>
      <EditorWrapper
        parent={{ path }}
        data={{ key, value }}
        onChange={handleChange}
        getProjectRefs={getProjectRefs}
        projectType={projectType}
        className="p-3"
      />
    </>
  );
};

const LeftPanel = ({
  data,
  path,
  handleJsonChange,
  getProjectRefs,
  projectType,
  onResize,
}) => {
  const { t } = useTranslation();
  const { addEventObject, currentEvent, setCurrentEvent, setEventHistory } =
    useEvents();
  const { value } = data;
  const mainSchema = getSchema({
    path: path.match(/^\/[^/]*\/([^/]*)\/[^/]*/)?.[1] || '',
    projectType,
    value,
  });
  const { properties: mainProperties = {} } = mainSchema;
  const { events = {} } = mainProperties;
  const { properties } = events;
  let defaultEvents = {};
  if (properties) {
    defaultEvents = Object.entries(properties)
      .map(([key, { default: value }]) => [key, value])
      .reduce(
        (defaultEvents, [key, value]) => ({ ...defaultEvents, [key]: value }),
        {}
      );
  }

  const getContents = ({ key, value }) => {
    if (value?.content) {
      return { [key]: value };
    }
    if (value && isObject(value) && Object.keys(value).length) {
      return Object.entries(value)
        .map(([key, value]) => getContents({ key, value }))
        .reduce((result, res) => {
          if (!res) {
            return result;
          }
          return { ...result, ...res };
        }, {});
    }
    return false;
  };
  const contents = Object.entries(getContents({ value }) || {});

  const handleEventClick = (key) => {
    const data = {
      key,
      value: value?.events?.[key] || [],
    };
    const parent = {
      path: 'events',
    };
    const eventObject = { data, parent };
    setTimeout(() => {
      addEventObject(eventObject);
    }, 0);
    setCurrentEvent(eventObject);
    setEventHistory([]);
  };

  const handleContentChange = useCallback(
    (json, contentOptions = {}) => {
      let { key, ...options } = contentOptions;
      let { value: contentValue } = json;

      if (projectType === projectTypes.FLOW) {
        let { steps = [] } = value;
        steps = steps.map((step) => (step.id === key ? contentValue : step));
        key = 'steps';
        contentValue = steps;
      }

      if (key === value?.id) {
        key = null;
      }

      if (!key) {
        json = { value: { ...value, ...contentValue } };
      } else {
        json = { value: { ...value, [key]: contentValue } };
      }

      handleJsonChange(json, options);
    },
    [value]
  );

  return (
    <ResizablePanel
      name="left"
      axis="x"
      handle="e"
      className="h-100"
      minConstraints={[305, 0]}
      maxConstraints={[window.outerWidth / 3, 0]}
      onResize={onResize}
    >
      <div className="overflow-auto h-100">
        <div className="header border-0">
          <small>{t('common.info')}</small>
        </div>
        <div className="p-3">
          <JsonEditorWrapper
            parent={{ path }}
            data={data}
            onChange={handleJsonChange}
            getProjectRefs={getProjectRefs}
            projectType={projectType}
            keyBlacklist={['events']}
          />
        </div>
        {contents.map(([key, value], i) => {
          key = key === 'undefined' ? '' : key;
          return (
            <ComponentEditor
              key={`Content-${key}-${i}`}
              _key={value?.id || key}
              value={value}
              path={path}
              getProjectRefs={getProjectRefs}
              projectType={projectType}
              handleContentChange={handleContentChange}
              i={i}
            />
          );
        })}
        <div
          className="header"
          style={{
            top: `${40 + (contents.length ? 40 : 0)}px`,
          }}
        >
          <small>{t('common.events')}</small>
        </div>
        <div className="p-3">
          {Object.keys(defaultEvents).map((key) => {
            const hasActions = !!value?.events?.[key]?.[0];
            return (
              <Button
                key={key}
                className="m-1"
                type={
                  key === currentEvent?.data?.key
                    ? 'primary'
                    : (hasActions && 'default') || 'dashed'
                }
                icon={
                  <Icon
                    className="me-2"
                    path={hasActions ? mdiFlag : mdiFlagOutline}
                    size={0.8}
                  />
                }
                onClick={() => handleEventClick(key)}
              >
                {key}
              </Button>
            );
          })}
        </div>
      </div>
    </ResizablePanel>
  );
};

const EditContent = ({
  data,
  file,
  getProjectRefs,
  handleJsonChange,
  path,
  project,
  wrapper,
}) => {
  const previewWrapper = useRef();
  let { preview, resolution } = useViewer();
  const { key, value } = data;
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { type: projectType } = app;

  const handlePanelResize = () => {
    const wrapper = previewWrapper.current;
    const { width: wrapperWidth = 1000, height: wrapperHeight = 800 } =
      wrapper?.getBoundingClientRect?.() || {};
    let mainWidth = parseInt(resolution);
    let mainHeight = parseInt(resolution.replace(/\d*x/, ''));
    if (projectType === projectTypes.MOBILE_APP) {
      mainWidth = preview === PREVIEW.MOBILE_PORTRAIT ? 354 : 630;
      mainHeight = preview === PREVIEW.MOBILE_PORTRAIT ? 630 : 354;
    }
    const scaleW = Math.min((wrapperWidth - 64) / mainWidth, 1);
    const scaleH = Math.min((wrapperHeight - 64) / mainHeight, 1);
    const scale = Math.min(scaleW, scaleH);
    const main = wrapper?.children[0];
    if (main) {
      main.style.transform = `scale(${scale})`;
    }
  };

  useEffect(() => {
    previewWrapper.current && setTimeout(handlePanelResize, 300);
  }, [preview, resolution, value]);

  if (!project.id || !key || !value) {
    return null;
  }
  return (
    <>
      <div className="d-flex h-fill">
        {(key === 'menus' && (
          <div className="p-3 w-100">
            <JsonEditorWrapper
              parent={{ path }}
              data={data}
              onChange={handleJsonChange}
              getProjectRefs={getProjectRefs}
              projectType={projectType}
            />
          </div>
        )) || (
          <>
            <LeftPanel
              data={data}
              path={path}
              handleJsonChange={handleJsonChange}
              getProjectRefs={getProjectRefs}
              projectType={projectType}
              onResize={handlePanelResize}
            />
            <PreviewWrapper
              main={key}
              currentId={file}
              project={project}
              value={value}
              className="flex-grow-1"
              externalRef={previewWrapper}
            />
            <ComponentPanel
              data={data}
              previewWrapper={previewWrapper}
              onResize={handlePanelResize}
            />
          </>
        )}
      </div>
      <EventPanel
        data={data}
        previewWrapper={previewWrapper}
        onResize={handlePanelResize}
      />
    </>
  );
};

export const MainPage = ({ match }) => {
  const {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  } = match;
  const { t } = useTranslation();
  const history = useHistory();
  const wrapper = useRef();
  const { applyJson, socket } = useSockets();
  const { validate } = useValidation();
  const {
    project = {},
    projects = [],
    getProjectRefs,
    selectProject = () => {},
    unselectProject = () => {},
    updateProject = () => {},
  } = useProjects();
  const { edges: allProjects = [] } = projects;
  let { data: projectData = {} } = project;
  const path = decodeURIComponent(`/${projectNamePath}/${fileNamePath}`);
  const filePath = fileNamePath.split('/');
  let key = filePath.shift();
  const file = filePath.join('/');
  const { app = {} } = projectData;
  const { type: projectType } = app;
  let value = projectData[key] || {};
  let index;
  if (Array.isArray(value)) {
    const val =
      value.find(({ id }) => id === file) ||
      value.find(({ id }) => id === file.split('/').shift());
    index = value.indexOf(val);
    value = val;
  } else if (['views_wrappers', 'views'].includes(key)) {
    value = { ...value, content: [], events: {} };
  }
  let data = { key, value, index };

  const handleJsonChange = useCallback(
    (json, options = {}) => {
      const { value } = json;
      const { event, changes = {} } = options;
      const { key, index } = data;
      let { data: projectData = {} } = project;
      projectData = projectData._clone();
      if (index >= 0) {
        projectData[key][index] = value;
      } else {
        projectData[key] = value;
      }
      const {
        key: changedKey,
        value: from,
        newValue: to,
        type: changedType,
      } = changes;

      if (changedKey === 'project_name') {
        if (
          allProjects.some(({ node }) => {
            const { data = {}, id } = node;
            const { app = {} } = data;
            const { project_name: projectName } = app;
            return id !== project.id && projectName === to;
          })
        ) {
          const { currentTarget } = event;
          currentTarget.innerText = from;
          selectAll(currentTarget);
          currentTarget.focus();
          message.warning(t(`projects.same_project_name`));
          return;
        }
        deleteFromLS({ name: from });
        history.replace(PAGES.DASHBOARD);
      }

      if (changedKey === 'id') {
        const appearancesTypes = {
          dialog: [`dialog":"${from}"`],
          menu: [`menu":"${from}"`],
          flow: [`flow":"${from}"`],
          step: [`step":"${from}"`],
          view: [`view":"${from}"`],
          wrapper: [`wrapper":"${from}"`],
          component: [`@element.${from}"`],
        };
        const appearances = appearancesTypes[changedType];
        if (appearances) {
          const regExp = new RegExp(`(${appearances.join('|')})`, 'g');
          projectData = JSON.parse(
            JSON.stringify(projectData).replace(regExp, (appearance) =>
              appearance.replace(from, to)
            )
          );
        }
        const { pathname } = history.location;
        const newPathname = pathname.replace(from, to);
        if (pathname !== newPathname) {
          history.replace(newPathname);
        }
      }

      if (['view_wrappers', 'views'].includes(key)) {
        setTimeout(async () => {
          const el = document.querySelector(
            '.preview-wrapper > .content-wrapper > .content'
          );
          //createScreenshot(id, { data: projectData, el });
        }, 1000);
      }

      updateProject({ data: projectData });
      applyJson(projectData);
    },
    [key, index, history, project, socket, value]
  );

  const createScreenshot = async (id, { data: projectData = {}, el }) => {
    let data = projectData._clone();
    const hoverEls = el.querySelectorAll('.hover');
    hoverEls.forEach((el) => el.classList.remove('hover'));
    const screenshotBlob = await toBlob(el);
    hoverEls.forEach((el) => el.classList.add('hover'));
    const screenshot = {
      id: `screenshots/${id}.jpg`,
      blob: screenshotBlob,
      url: window.URL.createObjectURL(screenshotBlob),
    };
    data = {
      ...data,
      res: [...data.res.filter(({ id }) => id !== screenshot.id), screenshot],
    };
    updateProject({ data });
    applyJson(data);
  };

  useMemo(() => {
    storeCurrent({ id: file, path });
  }, [path, file]);

  useEffect(() => {
    const projectSchema = getSchema({
      path: 'project',
      projectType,
      value: { type: projectType, ...project },
    });
    const { properties = {} } = projectSchema;
    const { data: dataSchema = {} } = properties;
    const { isValid, value: validatedData } = validate(projectData, dataSchema);
    if (!isValid) {
      updateProject({ data: validatedData });
      applyJson(validatedData);
    }
  }, [project.id]);

  useEffect(() => {
    if (key && !value) {
      history.push(encodeURI(`${PAGES.APP}/${projectNamePath}`));
    }
  }, [key, value]);

  useEffect(() => {
    const { loading, node: projectToSelect } =
      allProjects.find(({ node: { name } }) => name === projectNamePath) || {};
    if (projectToSelect) {
      projectToSelect.id !== project.id &&
        !loading &&
        selectProject(projectToSelect);
    } else {
      unselectProject();
    }

    if (!projectNamePath || !projectToSelect) {
      return;
    }
    let to;
    if (fileNamePath && !['settings', 'style'].includes(fileNamePath)) {
      const [key, value, , objId] = fileNamePath.split('/');
      const { data = {} } = projectToSelect;
      if (data[key]) {
        setTimeout(() => {
          document
            .querySelector(`.json-node-item[data-component-id="${objId}"]`)
            ?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
        return;
      }

      const mainKey = Object.keys(data).find((mainKey) => {
        return data[mainKey]?.find?.((obj) => {
          return obj[key]?.some?.(({ id }) => id === value);
        });
      });
      const mainObjs = Object.values(data);
      const { id } = mainObjs.reduce((mainObjSelected, mainObj) => {
        const val = mainObj.find?.((obj) => {
          return obj[key]?.some?.(({ id }) => id === value);
        });
        if (val) {
          mainObjs.splice(0, mainObjs.length);
          return val;
        }
      }, {});

      to = `${PAGES.APP}/${projectNamePath}/${mainKey}/${id}/${key}/${value}`;
    }
    to && history.push(encodeURI(to));
  }, [projectNamePath, fileNamePath, allProjects]);

  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar match={match} path={path} />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ height: '40px' }}>
            <Actions data={data} />
          </Layout.Header>
          <Layout.Content
            id="WorkingArea"
            className="d-flex flex-column"
            ref={wrapper}
          >
            {!data.key && <CirclePreloader />}
            <EventProvider
              data={data}
              handleJsonChange={handleJsonChange}
              getProjectRefs={getProjectRefs}
              projectType={projectType}
            >
              <ComponentProvider
                handleJsonChange={handleJsonChange}
                getProjectRefs={getProjectRefs}
                projectType={projectType}
              >
                <EditContent
                  data={data}
                  file={file}
                  getProjectRefs={getProjectRefs}
                  handleJsonChange={handleJsonChange}
                  project={project}
                  path={path}
                  wrapper={wrapper.current}
                />
              </ComponentProvider>
            </EventProvider>
          </Layout.Content>
        </Layout>
      </Layout>

      <DropWrapper />
      <RegisterModal />
      {/*} <VerificationModal /> */}
    </>
  );
};

export default MainPage;
