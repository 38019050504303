import React from 'react';
import Component from './Component';

export const YoutubeCompiler = ({ ...props }) => {
  return <Youtube {...props} />;
};

export const Youtube = ({ className, title, v, ...props }) => {
  const { id } = props;

  return (
    <Component {...props} className={`youtube ${className}`.trim()}>
      <iframe
        src={`https://www.youtube.com/embed/${v}`}
        title={title || `Youtube ${id || v || Date.now()}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Component>
  );
};

Youtube.displayName = 'Youtube';
YoutubeCompiler.displayName = 'Youtube';
export default YoutubeCompiler;
