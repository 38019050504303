import React, {
  createContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import io from 'socket.io-client';
import { PAGES, URL } from '../_config';
import { useProjects } from '../hooks';

export const SocketContext = createContext();

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const SocketProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [socket, setSocket] = useState();
  const { project = {}, addProjects, updateProject } = useProjects();
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { id: projectId } = app;
  const appExp = new RegExp(`^${PAGES.APP}`);
  const previewExp = new RegExp(`^${PAGES.PREVIEW}`);

  const applyJson = useCallback(
    async (json = projectData) => {
      return;
      if (!projectId || !socket || !json) {
        return;
      }
      const { res = [] } = json;
      const resBase64 = await Promise.all(
        res.map(async (r) => {
          const { blob, id } = r;
          const base64 = await blobToBase64(blob);
          return { id, base64 };
        })
      );
      let jsonBase64 = {
        ...json._clone(),
        res: resBase64,
      };

      socket?.emit('applyJson', {
        projectId,
        projectData: JSON.stringify(jsonBase64),
      });
    },
    [projectId, projectData, socket]
  );

  const applyPath = useCallback(() => {
    return;
    if (!projectId || !socket || pathname.match(previewExp)) {
      return;
    }
    socket.emit('applyPath', { projectId, pathname });
  }, [projectId, socket, pathname]);

  const joinProject = useCallback(() => {
    return;
    if (!projectId || !socket?.connected) {
      return;
    }
    console.log('joinProject >>>', projectId);
    socket.emit('project', { projectId });
  }, [projectId, socket]);

  const requestProject = useCallback(
    (projectId) => {
      return;
      if (!projectId) {
        return;
      }
      console.log('requestProject >>>', projectId);
      socket?.emit('requestProject', { projectId });
    },
    [socket]
  );

  useMemo(() => {
    return;
    if (!socket?.connected) {
      setSocket(io.connect(`${URL.SERVER}/`));
    }
  }, []);

  useEffect(() => {
    return;
    applyPath();
  }, [pathname]);

  useEffect(() => {
    return;
    joinProject();
  }, [socket, projectId]);

  useEffect(() => {
    return;
    if (!socket) {
      return;
    }

    socket.on('connect', () => {
      console.log('connect!!!', socket);
    });

    socket.on('json', async (data) => {
      let parsedData = JSON.parse(data);
      const { res = [] } = parsedData;
      const resBlob = await Promise.all(
        res.map(async (r) => {
          const { id, base64 } = r;
          const blob = await (await fetch(base64)).blob();
          const url = window.URL.createObjectURL(blob);
          return {
            id,
            blob,
            url,
          };
        })
      );
      parsedData = {
        ...parsedData,
        res: resBlob,
      };

      const { app = {} } = parsedData;
      const { id: projectId } = app;
      const newProject = {
        id: projectId,
        data: parsedData,
      };

      console.log('json >>>', parsedData);
      if (!project.id) {
        addProjects({ edges: [{ node: newProject }] }, { store: false });
        socket?.emit('requestPath', { projectId });
      } else {
        updateProject({ data: parsedData });
      }
    });

    socket.on('returnJson', (data) => {
      if (pathname.match(appExp)) {
        applyJson();
      }
    });

    socket.on('returnPath', (data) => {
      if (pathname.match(appExp)) {
        applyPath();
      }
    });

    socket.on('path', (data) => {
      if (pathname.match(previewExp)) {
        const path = data.replace(appExp, PAGES.PREVIEW);
        console.log('>>>', path);
        history.push(path);
      }
    });

    socket.on('message', (message) => {
      console.log('message >>>', message);
    });

    socket.on('disconnect', (err) => {
      console.error('disconnect >>>', err);
      socket.connect();
    });

    return () => {
      socket.off('connect');
      socket.off('update');
      socket.off('message');
      socket.off('disconnect');
    };
  }, [projectId, socket]);

  return (
    <SocketContext.Provider
      value={{ applyJson, applyPath, joinProject, requestProject, socket }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const SocketConsumer = SocketContext.Consumer;
export default SocketContext;
