import useProject from './useProject';

export const useMenu = (menuId) => {
  const { project = {} } = useProject();
  const { data = {} } = project;
  const { menus = [] } = data;
  return menus.find(({ id }) => id === menuId) || {};
};

export default useMenu;
