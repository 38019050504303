import Dexie from 'dexie';

const db = new Dexie('Doyo-Web');

db.version(1).stores({ databases: 'id,data' });
export const useDexie = () => {
    const add = (obj, key) => {
        db.transaction('rw', db.databases, async () => {
            await db.databases.add(obj, key);
        }).catch(async ({ name }) => {
            if (name === 'ConstraintError') {
                return await update(obj.id, obj);
            }
        });
    };

    const _delete = (key) => {
        db.transaction('rw', db.databases, async () => {
            await db.databases.delete(key);
        });
    };

    const get = async (key) => {
        const result = await db.databases.toArray();
        return result.find(({ id }) => id === key) || result;
    };

    const update = (id, obj) => {
        db.transaction('rw', db.databases, async () => {
            await db.databases.update(id, obj);
        });
    };
    return { add, db, delete: _delete, get, update };
};

export default useDexie;
