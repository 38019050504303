import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiArrowDownBox,
  mdiArrowUpBox,
  mdiBackspace,
  mdiCheck,
  mdiClipboardArrowDownOutline,
  mdiContentCopy,
  mdiContentPaste,
  mdiFlag,
  mdiFlagOutline,
  mdiLink,
  mdiMenuDown,
  mdiMenuRight,
  mdiTranslate,
} from '@mdi/js';
import { Button, Modal, Typography } from 'antd';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Sortable } from 'sortablejs';
import { getSchema } from '../schemas';
import { PAGES } from '../_config';
import {
  autocompleteBlacklist,
  getHex,
  getRgba,
  isColor,
  isObject,
  isPercentage,
  parseValue,
  UI,
} from '../_helpers';
import { Autocomplete, ColorPicker } from '../components';
import { EDIT_MODE } from '../context/reducers';
import {
  useAi,
  useAutocomplete,
  useClipboard,
  useEvents,
  useExpanded,
  useProjects,
  useValidation,
} from './../hooks';

const { Paragraph } = Typography;

const linkPaths = {
  dialog: 'dialogs',
  job: 'jobs',
  menu: 'menus',
  step: 'steps',
  view: 'views',
  wrapper: 'view_wrappers',
};

let checkedComponents = [];
const areCheckedComponentsSiblings = () => {
  const activeElements = Object.values(
    document.querySelectorAll(`.json-node-item.active`)
  );
  if (activeElements.length === 1) {
    return true;
  }
  const { areSiblings } = activeElements.reduce((parent, el) => {
    return {
      el: parent.el || el.parentNode,
      areSiblings:
        parent.areSiblings === false
          ? false
          : parent.el && parent.el === el.parentNode,
    };
  }, {});
  return areSiblings;
};

const CheckboxHelper = ({ value, changeItem }) => {
  return (
    <input
      type="checkbox"
      checked={value}
      className="json-node-value-helper me-1"
      onChange={(event) => {
        const { currentTarget } = event;
        const { checked: value } = currentTarget;
        changeItem({ value }, { event });
      }}
    />
  );
};

const ColorHelper = ({ value, changeItem }) => {
  return (
    <ColorPicker
      className="json-node-value-helper me-1"
      value={getRgba(value)}
      onChange={(el, value) => {
        el.nextSibling.firstChild.textContent = getHex(value);
      }}
      onBlur={(value) => changeItem({ value: getHex(value) })}
    />
  );
};

const IconHelper = ({ value }) => {
  return <i className={`json-node-value-helper mdi mdi-${value}`} />;
};

const RangeHelper = ({ value, changeItem, min = 0, max = 100 }) => {
  value = parseInt(value, 10);
  const input = useRef();
  if (input.current) {
    input.current.value = value;
  }
  return (
    <input
      ref={input}
      type="range"
      className="json-node-value-helper me-1"
      defaultValue={value}
      max={max}
      min={min}
      step={min}
      onChange={(event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        currentTarget.value = value;
        const input = currentTarget.nextSibling.firstElementChild;
        const { innerText } = input;
        input.innerText = `${innerText}`.includes('%') ? `${value}%` : value;
      }}
      onMouseUp={(event) => {
        const { currentTarget } = event;
        let { value } = currentTarget;
        const input = currentTarget.nextSibling.firstElementChild;
        const { innerText } = input;
        value = `${innerText}`.includes('%') ? `${value}%` : value;
        changeItem({ value: parseValue(value) }, { event });
      }}
    />
  );
};

const RemoveButton = ({ deleteItem }) => {
  const { t } = useTranslation();
  return (
    <BootstrapButton
      name="remove"
      className="item-button remove-item-button"
      variant="link"
      size="sm"
      title={t('common.delete')}
      onMouseEnter={(event) => {
        const {
          currentTarget: {
            parentNode: { parentNode },
          },
        } = event;
        parentNode.classList.add('line-through');
      }}
      onMouseLeave={(event) => {
        const {
          currentTarget: {
            parentNode: { parentNode },
          },
        } = event;
        parentNode.classList.remove('line-through');
      }}
      onClick={() => deleteItem()}
    >
      <Icon path={mdiBackspace} />
    </BootstrapButton>
  );
};

const CloneButton = ({ cloneItem }) => {
  const { t } = useTranslation();
  return (
    <BootstrapButton
      name="clone"
      className="item-button clone-item-button"
      variant="link"
      onClick={() => cloneItem()}
      title={t('common.clone')}
    >
      <Icon path={mdiClipboardArrowDownOutline} />
    </BootstrapButton>
  );
};

const CopyButton = ({ value }) => {
  const { t } = useTranslation();
  const { store } = useClipboard();
  return (
    <Paragraph
      className="item-button clone-item-button"
      copyable={{
        text: isObject(value) ? `${value}` : value,
        icon: [
          <Icon
            key="timelineCopyIcon"
            path={mdiContentCopy}
            size={0.8}
            onClick={() => store(value._clone())}
          />,
          <Icon key="timelineCopiedIcon" path={mdiCheck} size={0.8} />,
        ],
        tooltips: [t('common.copy'), t('common.copied')],
      }}
    />
  );
};

const PasteButton = ({ createItem }) => {
  const { t } = useTranslation();
  const { clipboard, reset } = useClipboard();
  let value = clipboard[0];
  value = value?._clone();
  value?._resetIds();
  return (
    <BootstrapButton
      name="paste"
      className="clone-item-button"
      variant="link"
      onClick={() => {
        reset();
        createItem({ value });
      }}
      title={t('common.paste')}
    >
      <Icon path={mdiContentPaste} size={0.8} />
    </BootstrapButton>
  );
};

const UpButton = ({ moveItem }) => {
  const { t } = useTranslation();
  return (
    <BootstrapButton
      name="moveUp"
      className="item-button up-item-button"
      variant="link"
      onClick={() => moveItem(-1)}
      title={t('common.up')}
    >
      <Icon path={mdiArrowUpBox} />
    </BootstrapButton>
  );
};

const DownButton = ({ moveItem }) => {
  const { t } = useTranslation();
  return (
    <BootstrapButton
      name="moveDown"
      className="item-button down-item-button"
      variant="link"
      onClick={() => moveItem(1)}
      title={t('common.down')}
    >
      <Icon path={mdiArrowDownBox} />
    </BootstrapButton>
  );
};

/*

  const { properties: eventProperties = {} } = events;
  const { default: def } =
    oneOf.find(({ type }) => ['array'].includes(type)) || {};
  const isParentArray = Array.isArray(parentValue);
  const showSymbol = isParentArray && !def && type === 'object';
  const autocompleteOptions = useAutocomplete(key);
  const options = Object.keys(properties).filter((prop) => {
    return (
      !autocompleteBlacklist.includes(prop) &&
      !keyBlacklist.includes(prop) &&
      !Object.keys(parentValue).includes(prop) &&
      !Object.keys(eventProperties).includes(prop)
    );
  });

*/

const TranslateButton = ({
  parent,
  getParent,
  changeItem,
  path,
  type,
  value,
}) => {
  const { translateText, translateProject } = useAi();
  const { project = {}, updateProject = () => {} } = useProjects();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  parent = parent || getParent();
  let { schema: parentSchema = {} } = parent;
  const { items } = parentSchema;
  parentSchema = items || parentSchema;
  const { oneOf = [] } = parentSchema;

  const handleSingle = async () => {
    const newValue = await translateText(path, value);

    if (changeItem && newValue !== null) {
      const objectSchema = oneOf.find(({ type }) =>
        ['array', 'object'].includes(type)
      );
      const { default: def = {} } = objectSchema;
      const _value = def._clone();

      for (let key in newValue) {
        if (newValue.hasOwnProperty(key)) {
          _value[key] = newValue[key];
        }
      }
      changeItem({ value: _value });
    }
    setIsModalOpen(false);
  };

  const handleAll = () => {
    translateProject(project, updateProject, false);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMissing = () => {
    translateProject(project, updateProject, true);
    setIsModalOpen(false);
  };

  return (
    <>
      <BootstrapButton
        name="moveDown"
        className="item-button down-item-button"
        variant="link"
        onClick={() => setIsModalOpen(true)}
        title={t('common.down')}
      >
        <Icon path={mdiTranslate} />
      </BootstrapButton>
      {type === 'langs' && (
        <Modal
          title={t('translation.modal.title')}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              {t('common.cancel')}
            </Button>,
            <Button key="all" onClick={handleAll}>
              {t('translation.modal.translate_all')}
            </Button>,
            <Button key="pending" onClick={handleMissing} type="primary">
              {t('translation.modal.translate_missing')}
            </Button>,
          ]}
        >
          <p>{t('translation.modal.instructions_a')}</p>
          <p>{t('translation.modal.instructions_b')}</p>
        </Modal>
      )}
      {type !== 'langs' && (
        <Modal
          title={t('translation.modal.title')}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel}>
              {t('common.cancel')}
            </Button>,
            <Button key="all" onClick={handleSingle} type="primary">
              {t('translation.modal.translate')}
            </Button>,
          ]}
        >
          <p>{t('translation.modal.singletext_instructions')}</p>
        </Modal>
      )}
    </>
  );
};

const JsonItemKey = ({
  _key: key,
  changeItem,
  deleteItem,
  getParent,
  readOnly,
  schema = {},
  keyBlacklist = [],
}) => {
  const parent = getParent();
  const { schema: parentSchema = {}, value: parentValue } = parent;
  const { properties = {} } = parentSchema;
  let options = Object.keys(properties).filter(
    (prop) => !Object.keys(parentValue).includes(prop)
  );
  options = options.length ? [key, ...options] : options;
  options = options.filter((key) => !keyBlacklist.includes(key));

  return (
    <Autocomplete
      className="json-node-key"
      options={options}
      value={key}
      autoFocus
      readOnly={readOnly}
      onBlur={(event) => {
        const { currentTarget } = event;
        const { _innerText, innerText: newKey } = currentTarget;
        if (!newKey) {
          deleteItem();
          return;
        }
        if (_innerText === newKey) {
          return;
        }
        changeItem({ key: newKey }, { event });
      }}
    />
  );
};

const JsonItemValue = React.memo(
  ({
    _key: key,
    changeItem,
    getItemSchema,
    getProjectRefs = () => [],
    projectType,
    path = '/',
    readOnly,
    value,
    keyBlacklist,
  }) => {
    const schema = getItemSchema();
    const { key: schemaKey } = schema;
    const autocompleteOptions = useAutocomplete(schemaKey || key);
    const {
      accept = {},
      enum: options = autocompleteOptions,
      reset,
      ui,
    } = schema;
    let { patterns: acceptedPatterns = [], types: acceptedTypes = [] } = accept;
    acceptedPatterns = acceptedPatterns.filter((pattern) => pattern);
    acceptedTypes = Array.isArray(acceptedTypes)
      ? acceptedTypes
      : [acceptedTypes];
    acceptedTypes = acceptedTypes.filter((type) => type !== 'string');
    if (!acceptedPatterns.length && acceptedTypes.length) {
      acceptedPatterns = acceptedTypes.reduce((patterns, type) => {
        if (type === 'boolean') return [...patterns, '^(true|false)$'];
        if (type === 'number') return [...patterns, '^d$'];
        return patterns;
      }, []);
    }
    acceptedPatterns = acceptedPatterns.length
      ? new RegExp(`(${acceptedPatterns.join('|')})`)
      : /./;

    const canBeObject = acceptedTypes.some((type) =>
      ['array', 'object'].includes(type)
    );

    let Helper = null;
    let helperProps = {};
    if (key === 'icon') {
      Helper = IconHelper;
    } else if (isColor(value)) {
      Helper = ColorHelper;
    } else if (ui === UI.RANGE || isPercentage(value)) {
      Helper = RangeHelper;
      helperProps = { min: schema.minimum, max: schema.maximum };
    } else if (typeof value === 'boolean') {
      Helper = CheckboxHelper;
    }

    const linkPath =
      (typeof value === 'string' && value && linkPaths[key]) || undefined;

    return (
      <>
        {!readOnly && !!Helper && (
          <Helper {...helperProps} value={value} changeItem={changeItem} />
        )}
        <Autocomplete
          _key={key}
          ui={ui}
          getProjectRefs={({ needle }) =>
            getProjectRefs({
              needle,
              [path.split('/')[2]]: window.location.pathname.split(
                `/${path.split('/')[2]}/`
              )[1],
            })
          }
          projectType={projectType}
          options={options}
          value={value}
          autoFocus
          readOnly={readOnly}
          onKeyDown={(event) => {
            const { currentTarget, key } = event;
            let { innerText: value } = currentTarget;
            value = parseValue(value);
            const selection = window.getSelection();
            if (
              key === 'project_name' &&
              /^[\d\D ]$/.test(key) &&
              acceptedPatterns &&
              !acceptedPatterns.test(
                `${`${value}`.slice(
                  0,
                  selection.focusOffset
                )}${key}${`${value}`.slice(
                  selection.focusOffset,
                  `${value}`.length
                )}`
              )
            ) {
              event.preventDefault();
              return false;
            }
            return true;
          }}
          onKeyUp={(event) => {
            const { currentTarget } = event;
            let {
              innerText: value,
              parentNode: { parentNode },
            } = currentTarget;
            value = parseValue(value);
            parentNode.dataset.type = value === null ? 'null' : typeof value;
          }}
          onBlur={(event) => {
            const { currentTarget } = event;
            let {
              _innerText,
              innerText: value,
              parentNode: { parentNode },
            } = currentTarget;
            value = parseValue(value);

            if (
              _innerText === value ||
              (!options.includes(value) &&
                !acceptedPatterns.test(value) &&
                !acceptedTypes.includes(value === null ? 'null' : typeof value))
            ) {
              const parsedInitialValue = parseValue(_innerText);
              parentNode.dataset.type =
                parsedInitialValue === null
                  ? 'null'
                  : typeof parsedInitialValue;
              event.currentTarget.innerText = _innerText;
              return;
            }

            changeItem({ value }, { event, reset });
          }}
        />

        {canBeObject && (
          <NewJsonItem
            parent={{ value, schema }}
            changeItem={changeItem}
            keyBlacklist={keyBlacklist}
          />
        )}
        {linkPath && (
          <LinkItem
            to={`${PAGES.APP}/${
              window.location.pathname.split('/')[2]
            }/${linkPath}/${value.replace(/^@[^.]+\./, '')}`}
          />
        )}
      </>
    );
  },
  (a, b) => a.value?._equals(b.value)
);
JsonItemValue.displayName = 'JsonItemValue';

export const JsonItem = React.memo(
  ({
    _key: key,
    createItem,
    getValue,
    getParent,
    changeValue: changeParent,
    deleteItem: parentDeleteItem,
    getProjectRefs,
    projectType,
    mode,
    keyBlacklist = [],
  }) => {
    const { t } = useTranslation();
    const { currentEvent, setCurrentEvent, setEventHistory } = useEvents();
    const [isConfirmOpened, setConfirmOpened] = useState({});
    const currentEventPath = `${currentEvent?.parent?.path}/${currentEvent?.data?.key}`;
    let value = getValue(key);
    const parent = getParent();
    const {
      path: parentPath = '',
      value: parentValue = {},
      schema: parentSchema,
    } = parent;
    const {
      additionalProperties = false,
      default: { id: defaultParentId } = {},
      events = {},
      required: parentRequired = [],
    } = parentSchema || {};
    const { properties: eventProperties = {} } = events;
    const path = `${parentPath}/${key}`;
    let { expanded, toggleExpanded } = useExpanded(path);
    const isParentArray = Array.isArray(parentValue);
    const schema = getSchema({ path: key, projectType, value }, parentSchema);
    const {
      copyable,
      description = '',
      properties = {},
      readOnly,
      required = [],
      title: schemaTitle,
      visible = true,
    } = schema;
    const isArray = Array.isArray(value);
    const icon = expanded ? mdiMenuDown : mdiMenuRight;
    const isTranslatable =
      key === 'title' ||
      key === 'text' ||
      key === 'description' ||
      key === 'hint' ||
      key === 'label' ||
      key === 'name' ||
      key === 'titleNoDefault';

    if (mode === EDIT_MODE.SORT && (isParentArray || isArray)) {
      expanded = true;
    }
    if (schemaTitle === 'arrayOfFunctions') {
      expanded = false;
    }

    const getItemSchema = useCallback(() => {
      return schema;
    }, [schema]);

    const changeItem = useCallback(
      (
        { key: newKey = key, value: newValue },
        { event, type, changes, reset } = {}
      ) => {
        changeParent(
          { key, newKey, value: newValue },
          {
            event,
            type,
            changes: changes || {
              key,
              value,
              newValue,
              type: defaultParentId,
            },
            reset,
          }
        );
      },
      [changeParent, defaultParentId, key, value]
    );

    const requiredGroupKeys = Object.keys(properties).filter(
      (propKey) => properties[propKey].requiredGroup
    );
    const requiredGroupValues = requiredGroupKeys.map(
      (propKey) => properties[propKey]
    );
    if (
      requiredGroupKeys.length &&
      !Object.keys(value).some((valueKey) =>
        requiredGroupKeys.includes(valueKey)
      )
    ) {
      const defaultKey = requiredGroupKeys[0];
      const { default: defaultValue = '' } = requiredGroupValues[0];
      value = {
        ...value,
        [defaultKey]: defaultValue,
      };
    }

    const cloneItem = useCallback(() => {
      const index = parentValue.indexOf(value);
      const newValue = value._clone();
      newValue._resetIds();
      const _value = [
        ...parentValue.slice(0, index + 1),
        newValue,
        ...parentValue.slice(index + 1),
      ];
      changeParent({ value: _value });
    }, [changeParent, parentValue, value]);

    const deleteItem = useCallback(() => {
      parentDeleteItem({ key });
    }, [key, parentDeleteItem]);

    const moveItem = useCallback(
      (count) => {
        const newKey = parseInt(key, 10) + count;

        if (newKey < 0 || newKey > parentValue.length) {
          return;
        }
        const _value = parentValue._clone();
        _value._move(parseInt(key, 10), newKey);
        changeParent({ value: _value });
      },
      [changeParent, key, parentValue]
    );

    const { id: componentId } = value || {};
    const active = document.querySelector(
      `[data-component-id="${componentId}"].active`
    );

    if (
      keyBlacklist.includes(key) ||
      (!!parentValue.function && key === 'id') ||
      key === '__expanded' ||
      key === 'content' ||
      !visible ||
      Object.keys(eventProperties).includes(key)
    ) {
      return null;
    }

    const comments = isParentArray && !expanded && value?.comments;

    return (
      <>
        {comments && <span className="comments">{comments}</span>}
        <div
          className={`json-node-item${active ? ' active' : ''}`}
          data-component-id={componentId}
          data-content={key === 'content' || undefined}
          data-key={key}
        >
          {isObject(value) && schemaTitle !== 'arrayOfFunctions' && (
            <BootstrapButton
              className="json-node-expand-button"
              variant="link"
              onClick={() => mode === EDIT_MODE.EDIT && toggleExpanded()}
            >
              <Icon path={icon} />
            </BootstrapButton>
          )}
          {!isParentArray && (
            <>
              <JsonItemKey
                _key={key}
                changeItem={changeItem}
                deleteItem={deleteItem}
                getParent={getParent}
                readOnly={!additionalProperties}
                keyBlacklist={keyBlacklist}
              />
              <span className="json-node-dots">:</span>
            </>
          )}
          <div
            className="json-node-value"
            data-type={
              value === null ? 'null' : isArray ? 'array' : typeof value
            }
            data-expanded={expanded}
          >
            {!readOnly && !parentRequired.includes(key) && (
              <RemoveButton deleteItem={deleteItem} />
            )}
            <div className="item-actions">
              {!readOnly && isParentArray && parseInt(key, 10) > 0 && (
                <UpButton moveItem={moveItem} />
              )}
              {!readOnly &&
                isParentArray &&
                parseInt(key, 10) < parentValue.length - 1 && (
                  <DownButton moveItem={moveItem} />
                )}
              {isParentArray && typeof value === 'object' && (
                <CloneButton cloneItem={cloneItem} />
              )}
              {(copyable ||
                ((isParentArray || schemaTitle === 'arrayOfFunctions') &&
                  typeof value === 'object')) && <CopyButton value={value} />}
              {isTranslatable && (
                <TranslateButton
                  getParent={getParent}
                  createItem={createItem}
                  changeItem={changeItem}
                  path={path}
                  parent={{ value, schema }}
                  type={key}
                  value={value}
                />
              )}
            </div>
            {(isObject(value) && (
              <>
                {schemaTitle !== 'arrayOfFunctions' && (
                  <>
                    <span className="json-node-wrapper-symbol">
                      {(isArray && ' [') || ' {'}
                    </span>
                    {(!expanded || mode === EDIT_MODE.SORT) && (
                      <small
                        className="json-node-type"
                        onClick={() =>
                          mode === EDIT_MODE.EDIT && toggleExpanded()
                        }
                      >
                        {isArray
                          ? required[0] || ` Array [${value.length}]`
                          : Object.keys(properties)
                              .reduce((res, prop) => {
                                const labelPattern = new RegExp(`\\{${prop}}`);
                                const label =
                                  typeof value[prop] !== 'undefined'
                                    ? prop
                                    : '';
                                const pattern = new RegExp(`\\{{${prop}}}`);
                                const _value =
                                  typeof value[prop] !== 'undefined'
                                    ? (isObject(value[prop]) &&
                                        (value[prop].default ||
                                          Object.values(value[prop])[0])) ||
                                      value[prop]
                                    : '';
                                return res
                                  .replace(pattern, `${_value}`)
                                  .replace(labelPattern, `${label}`);
                              }, description)
                              .trim() ||
                            ` Object {${Object.keys(value || {}).length}}`}
                      </small>
                    )}
                  </>
                )}
                {(schemaTitle === 'arrayOfFunctions' && (
                  <Button
                    className="mt--1"
                    type={
                      path === currentEventPath
                        ? 'primary'
                        : (!!value?.length && 'default') || 'dashed'
                    }
                    icon={
                      <Icon
                        className="me-2"
                        path={!!value?.length ? mdiFlag : mdiFlagOutline}
                        size={0.8}
                      />
                    }
                    onClick={() => {
                      let [, path = ''] =
                        parentPath.match(/\/[^/]*\/[^/]*\/[^/]*\/(.*)/) ||
                        parentPath.match(/\/[^/]*\/[^/]*\/(.*)/) ||
                        [];
                      setCurrentEvent({
                        data: { key, value: value || [] },
                        parent: { path, value: parentValue },
                        getProjectRefs,
                        projectType,
                      });
                      setEventHistory([]);
                    }}
                  >
                    {key}
                  </Button>
                )) ||
                  (expanded && (
                    <JsonEditor
                      _key={key}
                      mode={mode}
                      value={value}
                      parent={{ ...parent, path, schema }}
                      onChange={changeItem}
                      getProjectRefs={getProjectRefs}
                      projectType={projectType}
                      keyBlacklist={keyBlacklist}
                    />
                  ))}
                {schemaTitle !== 'arrayOfFunctions' && (
                  <span className="json-node-wrapper-symbol">
                    {(isArray && '],') || '},'}
                  </span>
                )}
              </>
            )) || (
              <>
                <JsonItemValue
                  _key={key}
                  changeItem={changeItem}
                  getItemSchema={getItemSchema}
                  getProjectRefs={getProjectRefs}
                  projectType={projectType}
                  path={path}
                  value={value}
                  readOnly={readOnly}
                  keyBlacklist={keyBlacklist}
                />
                <span className="json-node-wrapper-symbol">,</span>
              </>
            )}
          </div>
        </div>
      </>
    );
  },
  (a, b) => a._key === b._key && a.value?._equals(b.value)
);
JsonItem.displayName = 'JsonItem';

const NewJsonItem = ({
  parent,
  getParent,
  changeItem,
  createItem,
  keyBlacklist = [],
}) => {
  const { clipboard } = useClipboard();
  parent = parent || getParent();
  let { schema: parentSchema = {}, value: parentValue = '' } = parent;
  const { items } = parentSchema;
  parentSchema = items || parentSchema;
  const {
    additionalProperties = false,
    events = {},
    key,
    oneOf = [],
    patternProperties,
    properties = {},
    title,
    type,
  } = parentSchema;
  const { properties: eventProperties = {} } = events;
  const { default: def } =
    oneOf.find(({ type }) => ['array'].includes(type)) || {};
  const isParentArray = Array.isArray(parentValue);
  const showSymbol = isParentArray && !def && type === 'object';
  const autocompleteOptions = useAutocomplete(key);
  const options = Object.keys(properties).filter((prop) => {
    return (
      !autocompleteBlacklist.includes(prop) &&
      !keyBlacklist.includes(prop) &&
      !Object.keys(parentValue).includes(prop) &&
      !Object.keys(eventProperties).includes(prop)
    );
  });

  if (
    !options.length &&
    !autocompleteOptions.length &&
    !additionalProperties &&
    !patternProperties
  ) {
    return null;
  }

  const canPase =
    isParentArray &&
    ((title !== 'Function' && clipboard?.[0]) ||
      (title === 'Function' &&
        (clipboard?.[0]?.function || clipboard?.[0]?.[0]?.function)));

  return (
    <div className="json-node-item json-node-new-item">
      {showSymbol && <span className="json-node-wrapper-symbol">{' {'}</span>}
      <Autocomplete
        className="json-node-key"
        options={options.length ? options : autocompleteOptions}
        onFocus={(event) => {
          if (!isParentArray || type === 'object') {
            if (changeItem) {
              const objectSchema = oneOf.find(({ type }) =>
                ['array', 'object'].includes(type)
              );
              const { default: def = {} } = objectSchema;
              const _value = def._clone();
              const key = Object.keys(_value)[0] || options[0];
              if (Array.isArray(def)) {
                changeItem({ value: [parentValue] }, { event });
              } else {
                changeItem({ value: { [key]: parentValue } }, { event });
              }
            }
            return;
          }
          const { currentTarget } = event;
          const { default: def = '' } = parentSchema;
          const _value = def._clone();
          if (typeof _value.id !== 'undefined') {
            _value.id = `${_value.id}${Date.now()}`;
          }
          createItem({ value: _value });
          currentTarget.blur();
          return false;
        }}
        onBlur={(event) => {
          const { currentTarget } = event;
          const { innerText: key } = currentTarget;
          if (
            !key ||
            (patternProperties &&
              !Object.keys(patternProperties).some((pattern) => {
                const regExp = new RegExp(pattern);
                return regExp.test(key);
              }))
          ) {
            event.currentTarget.innerText = '';
            return;
          }
          const { default: def = '' } = properties[key] || {};
          let value = def._clone();
          if (autocompleteOptions.includes(key)) {
            value = key;
          }
          createItem?.({ key, value });
          event.currentTarget.innerText = '';
        }}
      />
      {canPase && <PasteButton createItem={createItem} />}
      {showSymbol && <span className="json-node-wrapper-symbol">{'}'}</span>}
    </div>
  );
};

export const LinkItem = ({ to }) => {
  return (
    <Link to={to}>
      <Icon path={mdiLink} />
    </Link>
  );
};

const isImportant = (key) => {
  return [
    'function',
    'id',
    'is',
    'is_not',
    'less_than',
    'more_than',
    'name',
    'pass',
    'project_name',
    'target',
    'title',
    'text',
    'type',
    'what',
    'wrapper',
  ].includes(key);
};

const avoidToSort = (key) => {
  return ['buttons', 'padding', 'margin'].includes(key);
};

export const JsonEditor = React.memo(
  ({
    _key: key,
    value,
    mode,
    getProjectRefs,
    projectType,
    onChange = () => {},
    parent = {},
    keyBlacklist = [],
  }) => {
    const { path = '', schema } = parent;
    const sortable = useRef();
    const wrapper = useRef();
    const { toggleExpanded } = useExpanded(path);
    const { validate } = useValidation();

    useEffect(() => {
      const { isValid, value: validatedValue } = validate(value, schema);
      if (!isValid && !value._equals(validatedValue)) {
        onChange({ value: validatedValue }, 'change');
      }
    }, [value]);

    const getValue = useCallback((key) => value[key], [value]);

    const createItem = useCallback(
      ({ key: newKey, value: newValue }, type = 'create') => {
        let _value;
        if (Array.isArray(value)) {
          newValue = Array.isArray(newValue) ? newValue : [newValue];
          _value = [...value, ...newValue];
          toggleExpanded({ path, child: value.length, expanded: true });
        } else {
          _value = Object.assign({}, value);
          _value[newKey] = newValue;
          if (typeof newValue === 'object') {
            toggleExpanded({ path, child: newKey, expanded: true });
          }
        }
        onChange({ value: _value }, type);
      },
      [onChange, path, toggleExpanded, value]
    );

    const changeValue = useCallback(
      (
        { key, newKey, value: newValue },
        { event, type = 'change', changes, reset } = {}
      ) => {
        newKey = typeof newKey !== 'undefined' ? newKey : key;
        let _value = !reset ? value._clone() : Array.isArray(value) ? [] : {};
        if (newKey) {
          _value[newKey] =
            typeof newValue !== 'undefined' ? newValue : _value[key];
        } else if (newValue) {
          _value = newValue;
        }
        if (key !== newKey) {
          if (type === 'change') {
            delete _value[key];
          }
        }
        if (
          key &&
          typeof _value[key] !== 'object' &&
          typeof newValue === 'object'
        ) {
          toggleExpanded({ path, child: newKey, expanded: true });
        }
        onChange({ value: _value }, { event, type, changes });
      },
      [value, onChange, toggleExpanded, path]
    );

    const deleteItem = useCallback(
      ({ key }, type = 'delete') => {
        let _value = value._clone();
        if (Array.isArray(_value)) {
          key = parseInt(key, 10);
          _value = _value.filter((item, i) => i !== key);
        } else {
          delete _value[key];
        }
        onChange({ value: _value }, type);
      },
      [onChange, value]
    );

    const getParent = useCallback(() => {
      return { ...parent, value, schema };
    }, [parent, value, schema]);

    const onKeyDown = useCallback(
      (event) => {
        const { key } = event;
        let _value;

        if (Array.isArray(value)) {
          if (
            checkedComponents.length &&
            areCheckedComponentsSiblings() &&
            !document.querySelector('.autocomplete-wrapper > div:focus')
          ) {
            _value = value;
            if (key === 'ArrowUp') {
              for (let i = 0; i < _value.length; i++) {
                checkedComponents.includes(_value[i].id) &&
                  i > 0 &&
                  _value._move(i, i - 1);
              }
              onChange({ value: _value });
            }
            if (key === 'ArrowDown') {
              for (let i = _value.length - 1; i >= 0; i--) {
                checkedComponents.includes(_value[i].id) &&
                  i < _value.length - 1 &&
                  _value._move(i, i + 1);
              }

              onChange({ value: _value });
            }
          }
        }
      },
      [onChange, value]
    );

    useEffect(() => {
      document.addEventListener('keydown', onKeyDown, false);
      return () => {
        document.removeEventListener('keydown', onKeyDown, false);
      };
    }, [value]);

    useEffect(() => {
      if (mode !== EDIT_MODE.SORT || !Array.isArray(value)) {
        sortable.current && sortable.current.destroy();
        return;
      }
      sortable.current && sortable.current.destroy();
      sortable.current = Sortable.create(wrapper.current, {
        multiDrag: false,
        selectedClass: 'selected',
        fallbackTolerance: 3,
        group: 'nested',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        setData: (dataTransfer) => {
          dataTransfer.setData('value', JSON.stringify(value));
        },
        onAdd: (event) => {
          const { from, newIndex, oldIndex, originalEvent, to } = event;
          const { dataTransfer } = originalEvent;
          const { items } = dataTransfer;
          if (!items[0]) {
            return;
          }
          items[0].getAsString((str) => {
            const fromValue = JSON.parse(str);
            const item = fromValue[oldIndex];

            let aux = from;
            while (aux && aux !== to) {
              aux = aux.parentElement;
            }
            const goDeeper = !aux;
            if (goDeeper) {
              return;
            }
            const cleanValue = (changes) => {
              if (!changes) {
                return changes;
              }
              if (changes.content) {
                return { ...changes, content: cleanValue(changes.content) };
              }
              if (Array.isArray(changes)) {
                return changes
                  .filter(({ id }) => id !== item.id)
                  .map((change) => cleanValue(change));
              }
              return changes;
            };
            const _value = cleanValue(value);

            setTimeout(() => {
              _value.splice(newIndex, 0, item);
              onChange({ value: _value });
            }, 0);
          });
        },
        onRemove: (event) => {
          const { from, newIndex, oldIndex, to } = event;
          let aux = to;
          let toId = '';
          while (!toId && aux) {
            const { componentId } = aux.dataset;
            toId = componentId;
            aux = aux.parentElement;
          }
          aux = from;
          while (aux && aux !== to) {
            aux = aux.parentElement;
          }
          const goDeeper = !aux;
          if (!goDeeper) {
            return;
          }

          const cleanValue = (changes) => {
            if (!changes) {
              return changes;
            }
            if (changes.content) {
              if (changes.id === toId) {
                const content = changes.content._clone();
                content.splice(newIndex, 0, value[oldIndex]);
                changes = {
                  ...changes,
                  content,
                };
                return changes;
              }
              return {
                ...changes,
                content: cleanValue(changes.content),
              };
            }
            if (Array.isArray(changes)) {
              return changes.map((change) => cleanValue(change));
            }
            return changes;
          };
          let _value = cleanValue(value);

          setTimeout(() => {
            _value = _value.filter((item, index) => index !== oldIndex);
            onChange({ value: _value });
          }, 100);
        },
        onUpdate: (event) => {
          const { oldIndex, newIndex } = event;
          const _value = value._clone();
          _value._move(oldIndex, newIndex);
          onChange({ value: _value });
        },
      });

      return () => {
        sortable.current && sortable.current.destroy();
      };
    }, []);

    if (
      mode === EDIT_MODE.SORT &&
      !Array.isArray(value) &&
      !value.content &&
      !value.items
    ) {
      return null;
    }

    let valueKeys = Object.keys(value);
    if (!avoidToSort(key) && !Array.isArray(value)) {
      valueKeys = valueKeys.sort((a, b) => {
        const { required = [] } = schema;
        if (required.includes(a) && !required.includes(b)) return -1;
        if (!required.includes(a) && required.includes(b)) return 1;
        if (isImportant(a) && !isImportant(b)) return -1;
        if (!isImportant(a) && isImportant(b)) return 1;
        return a < b ? -1 : 1;
      });
    }

    return (
      <div className="json-node-wrapper" ref={wrapper} role="tree">
        {valueKeys.map(
          (itemKey, i) =>
            (itemKey === 'content' ||
              itemKey === 'items' ||
              mode === EDIT_MODE.EDIT ||
              (mode === EDIT_MODE.SORT && Array.isArray(value))) && (
              <JsonItem
                _key={itemKey}
                checkedComponents={[]}
                createItem={createItem}
                getValue={getValue}
                getParent={getParent}
                getProjectRefs={getProjectRefs}
                projectType={projectType}
                changeValue={changeValue}
                deleteItem={deleteItem}
                key={`JsonItem-${itemKey}-${i}-${Date.now()}`}
                mode={mode}
                keyBlacklist={keyBlacklist}
              />
            )
        )}
        <NewJsonItem
          getParent={getParent}
          createItem={createItem}
          keyBlacklist={keyBlacklist}
        />
      </div>
    );
  },
  (a, b) =>
    a.value?._equals(b.value) &&
    a.mode === b.mode &&
    a.onChange === b.onChange &&
    a.getProjectRefs === b.getProjectRefs
);
JsonEditor.displayName = 'JsonEditor';

export const JsonEditorWrapper = React.memo(
  ({
    className = '',
    data = {},
    mode = 'edit',
    parent = {},
    onChange = () => {},
    getProjectRefs,
    projectType,
    keyBlacklist,
  }) => {
    const { value = {} } = data;
    const { path = '/test/app', schema: parentSchema } = parent;
    const [isConfirmOpened, setConfirmOpened] = useState({});

    let schemaPath = path.replace(/^\/[^/]+\//, '');
    schemaPath = schemaPath.split('/').slice(0, 2).join('/');
    schemaPath =
      value && value.id
        ? schemaPath.replace(`/${value.id.split('/').shift()}`, '')
        : schemaPath;
    const schema = getSchema(
      { path: schemaPath, projectType, value },
      parentSchema
    );
    parent = { ...parent, schema };

    return (
      <div className={`json-node-wrapper ${className}`.trim()} data-mode={mode}>
        <JsonEditor
          value={value}
          mode={mode}
          parent={parent}
          onChange={onChange}
          getProjectRefs={getProjectRefs}
          projectType={projectType}
          keyBlacklist={keyBlacklist}
        />
      </div>
    );
  },
  (a, b) => {
    if (typeof a.value === 'undefined') {
      return false;
    }
    return a.value?._equals(b.value) && a.mode === b.mode;
  }
);
JsonEditorWrapper.displayName = 'JsonEditorWrapper';

export default JsonEditor;
