import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PAGES } from '../../_config';
import { mobileCheck } from '../../_helpers';
import { Preview } from '../../preview';
import { useProjects, useSockets } from '../../hooks';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

export const PreviewPage = ({
  match: {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  },
}) => {
  const history = useHistory();
  const location = useLocation();
  const { requestProject } = useSockets();
  const {
    project = {},
    projects = {},
    selectProject = () => {},
    unselectProject = () => {},
  } = useProjects();
  const { edges: allProjects = [] } = projects;
  const path = decodeURIComponent(`/${projectNamePath}/${fileNamePath}`);
  const filePath = fileNamePath.split('/');
  const main = filePath.shift();
  const currentId = filePath.join('/');

  useEffect(() => {
    const { hash } = location;
    const projectId = hash.substring(1);
    requestProject(projectId);
  }, []);

  useEffect(() => {
    const { node: projectToSelect } =
      allProjects.find(({ node: { data = {} } }) => {
        const { app = {} } = data;
        const { project_name: name } = app;
        return name === projectNamePath;
      }) || {};
    if (projectToSelect) {
      projectToSelect.id !== project.id && selectProject(projectToSelect);
    } else {
      unselectProject();
    }

    if (!projectNamePath || !projectToSelect) {
      return;
    }
    let to;
    if (!fileNamePath) {
      to = `${PAGES.APP}${path}/app`;
      if (path.length > 1) {
        to = `${PAGES.APP}/${projectNamePath}/app`;
      }
    }
    if (fileNamePath) {
      const [key, value, , objId] = fileNamePath.split('/');
      const { data = {} } = projectToSelect;
      if (data[key]) {
        setTimeout(() => {
          document
            .querySelector(`.json-node-item[data-component-id="${objId}"]`)
            ?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
        return;
      }

      const mainKey = Object.keys(data).find((mainKey) => {
        return data[mainKey]?.find((obj) => {
          return obj[key]?.some?.(({ id }) => id === value);
        });
      });
      const mainObjs = Object.values(data);
      const { id } = mainObjs.reduce((mainObjSelected, mainObj) => {
        const val = mainObj.find?.((obj) => {
          return obj[key]?.some?.(({ id }) => id === value);
        });
        if (val) {
          mainObjs.splice(0, mainObjs.length);
          return val;
        }
      }, {});

      to = `${PAGES.APP}/${projectNamePath}/${mainKey}/${id}/${key}/${value}`;
    }
    to && history.push(encodeURI(to));
  }, [projectNamePath, fileNamePath, allProjects]);

  if (!project.id || !currentId) {
    return null;
  }

  return <Preview main={main} currentId={currentId} project={project} />;
};

export default PreviewPage;
