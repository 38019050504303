import React from 'react';
import BootstrapProgressBar from 'react-bootstrap/ProgressBar';
import Component from './Component';
import { useColor, useSource } from '../../hooks';

export const ProgressBarCompiler = ({
  color = 'primary',
  default_value: defaultValue = 0,
  source,
  ...props
}) => {
  const { key: variant } = useColor(color);
  return (
    <ProgressBar
      {...props}
      defaultValue={defaultValue}
      src={source}
      variant={variant}
    />
  );
};

export const ProgressBar = ({ defaultValue = 0, src, ...props }) => {
  let { value = defaultValue } = useSource(src);
  if (isNaN(value) || value === null) {
    value = defaultValue;
  }
  return <Component {...props} now={value} component={BootstrapProgressBar} />;
};

ProgressBar.displayName = 'ProgressBar';
ProgressBarCompiler.displayName = 'ProgressBar';
export default ProgressBarCompiler;
