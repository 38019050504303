import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiCellphoneScreenshot, mdiDelete } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Button,
  Carousel,
  List,
  Popconfirm,
  Space,
  Tooltip,
} from 'antd';
import { PAGES } from '../../_config';
import { getLanguage, isObject } from '../../_helpers';
import { CirclePreloader } from '../../components';
import { projectTypes, projectTypeIcon } from '../../schemas/project';
import { useKustodio, useProjects } from '../../hooks';

const Project = ({ loading, project, language }) => {
  const { t } = useTranslation();
  const {
    getProjectIcon,
    getProjectScreenshots,
    deleteProject = () => {},
    project: currentProject = {},
  } = useProjects();

  let {
    id,
    data = {},
    description,
    kustodio_id: kustodioId,
    name: projectName,
    title,
    type,
  } = project;
  const { flows = [], views = [] } = data;
  let key = 'views';
  let currentKey = views[0]?.id;
  if (type === projectTypes.FLOW) {
    key = 'flows';
    currentKey = flows[0]?.id;
  } else if (type === projectTypes.BACKEND) {
    key = 'settings';
    currentKey = 'integrations';
  }

  const icon = getProjectIcon(projectName);
  const screenshots = getProjectScreenshots(projectName);

  let titleToShow = title;
  try {
    titleToShow = JSON.parse(title);
  } catch (error) {}
  if (isObject(titleToShow)) {
    titleToShow =
      typeof titleToShow[language] !== 'undefined'
        ? titleToShow[language]
        : titleToShow.default;
  }
  if (typeof titleToShow === 'undefined' || titleToShow === null) {
    titleToShow = '';
  }

  let descriptionToShow = description;
  try {
    descriptionToShow = JSON.parse(description);
  } catch (error) {}
  if (isObject(descriptionToShow)) {
    descriptionToShow =
      typeof descriptionToShow[language] !== 'undefined'
        ? descriptionToShow[language]
        : descriptionToShow.default;
  }
  if (typeof descriptionToShow === 'undefined' || descriptionToShow === null) {
    descriptionToShow = '';
  }

  const handleClickDelete = useCallback(
    (projectToDelete) => {
      deleteProject(projectToDelete);
    },
    [deleteProject]
  );

  return (
    <List.Item
      className={`project${
        currentProject.id === id ? ' project--current' : ''
      }`}
      actions={[
        ...((type && [
          <Space key={`type-${type}-${id}`} align="center">
            <Icon path={projectTypeIcon[type]} />
            <span>{t(`projects.${type}`)}</span>
          </Space>,
        ]) ||
          []),
      ]}
      extra={[
        <Popconfirm
          key={`delete-${id}`}
          destroyTooltipOnHide={true}
          title={
            kustodioId ? (
              <p>
                {t('projects.delete_from_kustodio', {
                  name: `${titleToShow} (${projectName})`,
                })}
              </p>
            ) : (
              ''
            )
          }
          onConfirm={() => handleClickDelete(project)}
          okButtonProps={{
            danger: true,
            size: 'middle',
          }}
          cancelButtonProps={{
            size: 'middle',
            type: 'text',
          }}
          okText={t('common.delete')}
          cancelText={t('common.cancel')}
        >
          <Button
            icon={<Icon path={mdiDelete} size={1} />}
            type="text"
            shape="circle"
            size="large"
            danger
          />
        </Popconfirm>,
        ...(screenshots.length
          ? [
              <Tooltip
                key={`screenshots-${id}`}
                placement="right"
                destroyTooltipOnHide={true}
                title={
                  <Carousel
                    autoplay
                    autoplaySpeed={2000}
                    fade={true}
                    dots={false}
                  >
                    {screenshots.map(({ id, url }) => (
                      <div key={`screenshot-${id}`}>
                        <img src={url} width={226} alt={id} />
                      </div>
                    ))}
                  </Carousel>
                }
                style={{ width: '226px', minHeight: '226px' }}
              >
                <Link
                  className="border-left"
                  disabled={!screenshots.length}
                  to={`${PAGES.APP}/${projectName}/res/${screenshots[0]?.id}`}
                >
                  <Button
                    icon={<Icon path={mdiCellphoneScreenshot} size={1} />}
                    type="text"
                    shape="circle"
                    size="large"
                  />
                </Link>
              </Tooltip>,
            ]
          : []),
      ]}
    >
      {loading && <CirclePreloader />}
      <List.Item.Meta
        avatar={
          icon ? (
            <Avatar shape="square" src={icon} />
          ) : (
            <div className="icon-placeholder ant-avatar ant-avatar-square">
              <svg viewBox="0 0 256 256">
                <line x1="0" y1="128" x2="256" y2="128" />
                <line x1="128" y1="0" x2="128" y2="256" />
                <line x1="0" y1="0" x2="256" y2="256" />
                <line x1="0" y1="256" x2="256" y2="0" />
                <circle cx="128" cy="128" r="112" />
                <circle cx="128" cy="128" r="56" />
              </svg>
            </div>
          )
        }
        title={
          <Link
            to={`${PAGES.APP}/${projectName}${
              currentKey ? `/${key}/${currentKey}` : ''
            }`}
          >{`${titleToShow} (${projectName})`}</Link>
        }
        description={descriptionToShow}
      />
    </List.Item>
  );
};

export const MyProjectsContent = ({
  isFromScratch: isFromScratchProp = false,
}) => {
  const { t } = useTranslation();
  const language = getLanguage();
  const { listTags = () => {} } = useKustodio();
  const { projects = {}, status } = useProjects();
  const { edges: projectEdges = [] } = projects;
  const projectList = projectEdges
    ._clone()
    .map((project) => {
      const { node } = project;
      let title = '';
      let description = '';
      try {
        title = JSON.parse(node.title);
        description = JSON.parse(node.description);
      } catch (error) {
        title = node.data?.app?.name || '';
        description = node.data?.app?.description || '';
      }
      return {
        ...project,
        node: {
          ...node,
          id: node.uid || node.id,
          title,
          description,
        },
      };
    })
    .sort((p1, p2) => {
      let name1 = p1.node.title,
        name2 = p2.node.title;
      if (isObject(name1)) {
        name1 =
          typeof name1[language] !== 'undefined'
            ? name1[language]
            : name1.default;
      }
      if (isObject(name2)) {
        name2 =
          typeof name2[language] !== 'undefined'
            ? name2[language]
            : name2.default;
      }
      return `${name1}`.toLowerCase() < `${name2}`.toLowerCase() ? -1 : 1;
    });

  useEffect(() => {
    if (!['brain_loaded', 'kustodio_loaded'].includes(status)) {
      return;
    }
    listTags();
  }, []);

  return (
    <div className="my-projects">
      <h3>{t('projects.my_projects')}</h3>
      <List
        dataSource={projectList}
        itemLayout="vertical"
        loading={!!status?.match(/_loading$/)}
        renderItem={({ loading, node }) => (
          <Project
            key={`Project-${node.id}`}
            loading={loading}
            project={node}
            language={language}
          />
        )}
      />
    </div>
  );
};

export default MyProjectsContent;
