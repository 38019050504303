import React from 'react';
import {
  Button,
  Card,
  Modal as BootstrapModal,
  Offcanvas as BootstrapOffcanvas,
  Toast as BootstrapToast,
} from 'react-bootstrap';
import { getLanguage } from '../../_helpers';
import { componentTypes, dialogTypes } from '../../schemas/defs/web';
import { Content } from './components/Component';
import Group from './components/Group';
import Icon from './components/Icon';
import Image from './components/Image';
import Text from './components/Text';

const Modal = ({ text, title, value }) => {
  const { backdrop = true, buttons = {}, closable = true, content, id } = value;
  const headerProps = {};
  if (closable) {
    headerProps.closeButton = true;
  }
  return (
    <main className="preview dialog">
      <div className={`fade show ${backdrop ? 'modal-backdrop' : ''}`} />
      <div className="fade modal show d-block position-fixed">
        <div className="modal-dialog">
          <div className="modal-content">
            <BootstrapModal.Header {...headerProps}>
              {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
            </BootstrapModal.Header>
            {(text || content) && (
              <BootstrapModal.Body>
                {text}
                {content && <Content content={content} />}
              </BootstrapModal.Body>
            )}
            {Object.keys(buttons) && (
              <BootstrapModal.Footer>
                {buttons.custom?.map((attributes, i) => (
                  <Button key={`ModalButton-${id}-${i}`} {...attributes} />
                ))}
                {buttons.negative && (
                  <Button
                    key={`ModalButton-${id}-negative`}
                    text={buttons.negative.label}
                  />
                )}
                {buttons.positive && (
                  <Button
                    key={`ModalButton-${id}-positive`}
                    text={buttons.positive.label}
                    color="primary"
                  />
                )}
              </BootstrapModal.Footer>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

const Offcanvas = ({ text, title, value }) => {
  const {
    backdrop = true,
    closable = true,
    content,
    placement = 'start',
  } = value;
  const headerProps = {};
  if (closable) {
    headerProps.closeButton = true;
  }
  return (
    <>
      <div className={`fade show ${backdrop ? 'offcanvas-backdrop' : ''}`} />
      <div className={`offcanvas offcanvas-${placement} show visible`}>
        <BootstrapOffcanvas.Header {...headerProps}>
          <Text
            text={title}
            component={BootstrapOffcanvas.Title}
            font_size={null}
          />
        </BootstrapOffcanvas.Header>
        <BootstrapOffcanvas.Body>
          <Text text={text} font_size={null} />
          {content && <Group content={content} />}
        </BootstrapOffcanvas.Body>
      </div>
    </>
  );
};

const Toast = ({ text, title, value }) => {
  const { icon = '', placement } = value;

  const toastIcon = icon.includes('.') ? (
    <Image file={icon} className="rounded me-2" />
  ) : (
    <Icon icon={icon} className="rounded me-2" />
  );

  return (
    <BootstrapToast data-placement={placement}>
      <BootstrapToast.Header closeButton>
        {icon && toastIcon}
        {title && (
          <Text
            text={title}
            component="strong"
            font_size={null}
            className="me-auto"
          />
        )}
      </BootstrapToast.Header>
      <BootstrapToast.Body>
        <Text text={text} font_size={null} />
      </BootstrapToast.Body>
    </BootstrapToast>
  );
};

const dialog = {
  [dialogTypes.DRAWER]: Offcanvas,
  [dialogTypes.MODAL]: Modal,
  [dialogTypes.TOAST]: Toast,
};

export const Dialog = (props) => {
  const language = getLanguage();
  const { buttons, content, steps, type } = props;
  let { text, title } = props;
  if ((!buttons && !type) || steps) {
    return null;
  }

  text = (text && (text[language] || text.default)) || text;
  title = (title && (title[language] || title.default)) || title;

  if (type) {
    const DialogType = dialog[type] || 'div';
    return <DialogType {...props} text={text} title={title} />;
  }

  return (
    <div className="dialog">
      <Card>
        <Card.Body>
          {(title && <Card.Title>{title}</Card.Title>) || null}
          {(text && <Card.Text>{text}</Card.Text>) || null}
          {(content && <Group content={content} />) || null}
        </Card.Body>
        <Card.Body className="d-flex flex-row-reverse">
          {Object.keys(buttons || []).map((key) => {
            const button = buttons[key];
            if (Array.isArray(button)) {
              return (
                <Content
                  key={`Button-${key}`}
                  content={button.map((b) => ({
                    ...b,
                    type: componentTypes.BUTTON,
                  }))}
                />
              );
            }

            let { label } = button;
            label = (label && (label[language] || label.default)) || label;
            return (
              <Button key={`Button-${key}`} color="link">
                {label}
              </Button>
            );
          })}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Dialog;
