import React, { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import parseHtml from 'html-react-parser';
import ReactIcon from '@mdi/react';
import { mdiFirebase } from '@mdi/js';
import {
  getAuth,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  GithubAuthProvider,
  PhoneAuthProvider,
  TwitterAuthProvider,
  linkWithPopup,
  linkWithRedirect,
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from 'firebase/storage';
import {
  FirebaseAppProvider,
  useFirebaseApp,
  AuthProvider,
  useSigninCheck,
  FirestoreProvider,
} from 'reactfire';
import { useImmerReducer } from 'use-immer';
import { FirebaseReducer, FIREBASE } from './reducers';
import { parse } from '../util';

export const FirebaseContext = createContext({});

const firestoreCondition = {
  is: '==',
  is_not: '!=',
  less_equal_than: '<=',
  less_than: '<',
  more_equal_than: '>=',
  more_than: '>',
  includes: 'in',
};

// Custom code:
const getProvider = (providerId) => {
  switch (providerId) {
    case 'email':
    case 'password':
      return new EmailAuthProvider();
    case 'google':
    case 'google.com':
      return new GoogleAuthProvider();
    case 'facebook':
    case 'facebook.com':
      return new FacebookAuthProvider();
    case 'github':
    case 'github.com':
      return new GithubAuthProvider();
    case 'phone':
      return new PhoneAuthProvider();
    case 'twitter':
    case 'twitter.com':
      return new TwitterAuthProvider();
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
};

const Auth = ({ dispatch, user: userProps }) => {
  const { status, data = {} } = useSigninCheck();
  let { user } = data;
  user = user || {};
  const { providerData = [] } = user;
  const { displayName, photoURL } =
    providerData.find(({ displayName, photoURL }) => displayName && photoURL) ||
    {};
  const { email, emailVerified, uid: id } = user;
  user = { displayName, email, emailVerified, photoURL, id };

  useEffect(() => {
    if (!userProps._equals(user))
      dispatch({
        type: FIREBASE.LOGIN,
        payload: { status, user },
      });
  }, [user]);

  return null;
};

export const FirebaseProvider = (props) => {
  const { children, project = {} } = props;
  const { data = {} } = project;
  const { app = {}, external = [] } = data;
  let { firebase = {}, project_name: appName = '' } = app;
  const { url } =
    external.find(({ id }) => id === 'google-services.json') || {};
  const [googleServices, setGoogleServices] = useState();

  useEffect(() => {
    let mounted = true;
    url &&
      fetch(url)
        .then((resp) => resp.json())
        .then((json) => mounted && setGoogleServices(json));
    return () => {
      mounted = false;
    };
  }, [url]);

  if (!googleServices && !firebase.apiKey) {
    return children;
  }

  let firebaseConfig = {
    ...firebase,
  };

  if (googleServices) {
    const { client = [], project_info: projectInfo = {} } = googleServices;
    const { api_key: apiKeys = [], client_info: clientInfo } = client[0] || {};
    const { current_key: apiKey } = apiKeys[0] || {};
    const { mobilesdk_app_id: appId } = clientInfo;
    const { project_id: projectId, storage_bucket: storageBucket } =
      projectInfo;
    firebaseConfig = {
      apiKey,
      appId,
      projectId,
      storageBucket,
    };
  }

  return (
    <FirebaseAppProvider
      appName={`${appName}_${Date.now()}`}
      firebaseConfig={firebaseConfig}
    >
      <FirebaseProviderChildren {...props} />
    </FirebaseAppProvider>
  );
};

export const FirebaseProviderChildren = (props) => {
  const [messages, setMessages] = useState([]);
  const { t } = useTranslation();
  const app = useFirebaseApp();
  const [state, dispatch] = useImmerReducer(
    FirebaseReducer,
    FIREBASE.INITIAL_STATE
  );

  const clearMessages = () => {
    messages.forEach((m) => m.destroy());
  };

  // provider code:
  const auth = getAuth(app);

  const logInWithFirebase = async ({ provider: providerId, email, pass }) => {
    let data = {};
    providerId = providerId.replace('firebase.', '');
    const provider = getProvider(providerId);
    try {
      if (providerId === 'email') {
        data = await signInWithEmailAndPassword(auth, email, pass);
      } else {
        if (state?.user?.uid) {
          data = linkWithPopup(auth.currentUser, provider);
        } else {
          data = await signInWithPopup(auth, provider);
        }
      }
      const { _tokenResponse: user } = data;
      return { ...data, user };
    } catch (error) {
      if (
        error.customData?._tokenResponse &&
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        const { email } = error.customData;
        if (state?.user?.uid) {
          provider.setCustomParameters({ login_hint: email });
          const result = await linkWithRedirect(auth.currentUser, provider);
          return result;
        }
        return await linkWithRedirect(auth, provider);
      }
      return { error };
    }
  };

  const logOutWithFirebase = async () => await auth.signOut();

  const signUpWithFirebase = async ({ email, pass }) => {
    try {
      const data = await createUserWithEmailAndPassword(auth, email, pass);
      return data;
    } catch (error) {
      return { error };
    }
  };
  const firestore = getFirestore(app);

  const addToFirestore = async ({ key, to, what }) => {
    let keys = to.replace('@firebase.firestore.', '').split('.');
    if (!key) {
      return await addDoc(collection(firestore, ...keys), what);
    }
    keys = [...keys, key];
    const ref = doc(firestore, ...keys);
    return await setDoc(ref, what, { merge: true });
  };

  const deleteFromFirestore = async (what) => {
    const keys = what.replace('@firebase.firestore.', '').split('.');
    try {
      const ref = doc(firestore, ...keys);
      await deleteDoc(ref);
    } catch (e) {
      const field = keys.pop();
      const ref = doc(firestore, ...keys);
      // Remove field from the document
      await updateDoc(ref, {
        [field]: deleteField(),
      });
    }
  };

  const getFromFirestore = async (
    str,
    { filter: f, isCollection, limit: l = 10, order = {} } = {}
  ) => {
    const { field: oField, type: oType } = order;
    let _str = str.replace('@firebase.firestore.', '');

    const getFilter = ({ what, and, or, ...condition }) => {
      let filter = [];
      const value = Object.values(condition)[0];
      condition = Object.keys(condition)[0];
      condition = firestoreCondition[condition] || '==';
      filter = [...filter, where(what, condition, value)];
      if (and) {
        filter = [...filter, ...getFilter(and)];
      }
      return filter;
    };
    const _filter = f && getFilter(f);
    const _order = (oField || oType) && orderBy(oField, oType || 'asc');
    const _limit = l && limit(l);
    const options = [];
    _filter && options.push(..._filter);
    _order && options.push(_order);
    _limit && options.push(_limit);
    const keys = _str.split('.');
    let ref;
    let field = '';
    if (str.includes('null')) {
      return null;
    }
    try {
      ref =
        keys.length === 1 || isCollection
          ? collection(firestore, ...keys)
          : doc(firestore, ...keys);
    } catch (e) {
      field = keys.pop();
      ref = doc(firestore, ...keys);
    }
    try {
      let queryRef = query(ref, ...options);
      let replacedStr;
      if (ref.type === 'collection') {
        let replacedOrStr = {};
        if (f?.or) {
          replacedOrStr = await getFromFirestore(str, {
            filter: f.or,
            order,
            limit: l,
          });
        }
        const docsRef = await getDocs(queryRef);
        replacedStr = {
          ...replacedOrStr,
          ...docsRef.docs
            .map(
              (docRef) => ({
                id: docRef.id,
                NO_ID_FIELD: docRef.id,
                ...docRef.data(),
              }),
              {}
            )
            .reduce((result, item) => {
              const { NO_ID_FIELD } = item;
              return {
                ...result,
                [NO_ID_FIELD]: item,
              };
            }, {}),
        };
      } else if (ref.type === 'document') {
        const docRef = await getDoc(queryRef);
        replacedStr = docRef.data();
        replacedStr = replacedStr && {
          id: docRef.id,
          NO_ID_FIELD: docRef.id,
          ...replacedStr,
        };
      }

      replacedStr = field ? replacedStr?.[field] : replacedStr;
      replacedStr = parse(replacedStr);
      return replacedStr;
    } catch (error) {
      const isAnyInputFocused = document.querySelector(
        '.autocomplete-wrapper :focus'
      );
      console.error(error, { isAnyInputFocused });
      if (isAnyInputFocused) {
        return null;
      }
      let { message: errorMessage, name } = error;
      errorMessage = errorMessage.replace(
        /(http[^ ]+)/g,
        (str, g) => `<a href="${g}" target="_blank">${t('common.solve')}</a>`
      );
      const content = parseHtml(errorMessage).map((fragment) => {
        if (fragment.type === 'a') {
          return {
            ...fragment,
            props: {
              ...fragment.props,
              children: <Button>{fragment.props.children}</Button>,
            },
          };
        }
        return fragment;
      });
      message.warning({
        key: 'firebaseError',
        icon: (
          <ReactIcon
            path={mdiFirebase}
            className="ant-message-info anticon"
            size={0.7}
          />
        ),
        title: name,
        content,
        duration: 0,
      });
      setMessages((messages) => [...messages, message]);
      return null;
    }
  };

  const updateFirestore = async ({ what, value }) => {
    try {
      let keys = what.replace('@firebase.firestore.', '').split('.');
      let field = keys.pop();
      let docRef = doc(firestore, ...keys);
      if (docRef && field) {
        return await setDoc(docRef, { [field]: value }, { merge: true });
      }
    } catch (error) {
      return null;
    }
  };

  const storage = getStorage(app);

  const addToStorage = async ({ key, to, what }) => {
    const name = to.replace('@firebase.storage.', '');
    const storageRef = ref(storage, name);
    const snapshots = await Promise.all(
      Object.values(what).map((value, index) => {
        let { name } = value;
        name = key ? `${key}_${index}` : name;
        const what = `${to}/${name}`;
        return uploadFileToStorage({ what, value });
      })
    );
    const files = await Promise.all(
      snapshots.map(async ({ ref }) => {
        const { fullPath: path, name } = ref;
        const url = await getFileUrlFromStorage(`@firebase.storage.${path}`, {
          ref,
        });
        return { name, path, url };
      })
    );
    let data = { [storageRef.name]: files };
    let parentRef = storageRef.parent;
    while (parentRef.parent !== null) {
      data = {
        [parentRef.name]: data,
      };
      parentRef = parentRef.parent;
    }
    dispatch({
      type: FIREBASE.STORAGE_GET,
      payload: { data },
    });
  };

  const deleteFileFromStorage = async (str) => {
    const name = str.replace('@firebase.storage.', '');
    const storageRef = ref(storage, name);
    return deleteObject(storageRef);
  };

  const getFolderChildrenFromStorage = async (str) => {
    const name = str.replace('@firebase.storage.', '');
    const storageRef = ref(storage, name);
    const { prefixes, items } = await listAll(storageRef);
    const folders = await Promise.all(
      prefixes.map(async ({ fullPath: path, name }) => ({ path, name }))
    );
    const files = await Promise.all(
      items.map(async (itemRef) => {
        const { fullPath: path, name } = itemRef;
        const url = await getDownloadURL(itemRef);
        return { path, name, url };
      })
    );
    const response = [...folders, ...files];
    let data = { [storageRef.name]: response };
    let parentRef = storageRef.parent;
    while (parentRef.parent !== null) {
      data = {
        [parentRef.name]: data,
      };
      parentRef = parentRef.parent;
    }
    dispatch({
      type: FIREBASE.STORAGE_GET,
      payload: { data },
    });
    return response;
  };

  const getFileUrlFromStorage = async (str) => {
    const name = str.replace('@firebase.storage.', '');
    const storageRef = ref(storage, name);
    return getDownloadURL(storageRef);
  };

  const uploadFileToStorage = async ({ what, value }) => {
    const name = what.replace('@firebase.storage.', '');
    const storageRef = ref(storage, name);
    const metadata = { size: value.size };
    return uploadBytes(storageRef, value, metadata);
  };

  return (
    <FirebaseContext.Provider
      value={{
        clearMessages,
        messages,
        firebase: state,
        addToStorage,
        logOutWithFirebase,
        logInWithFirebase,
        signUpWithFirebase,
        getFromFirestore,
        deleteFromFirestore,
        addToFirestore,
        uploadFileToStorage,
        getFolderChildrenFromStorage,
        getFileUrlFromStorage,
        deleteFileFromStorage,
        updateFirestore,
      }}
    >
      {/* return code */}
      <AuthProvider sdk={auth}>
        <Auth dispatch={dispatch} user={state?.user} />
      </AuthProvider>
      <FirestoreProvider sdk={firestore}>{props.children}</FirestoreProvider>
    </FirebaseContext.Provider>
  );
};

export const FirebaseConsumer = FirebaseContext.Consumer;
export default FirebaseContext;
