import React from 'react';
import Container from 'react-bootstrap/Container';
import Component, { Content } from './Component';

export const GroupCompiler = ({
  itemData,
  scroll = false,
  orientation = 'vertical',
  container,
  content = [],
  ...props
}) => {
  const tag = container ? Container : 'div';
  return (
    <Group {...props} direction={orientation} scrollable={scroll} tag={tag}>
      <Content content={content} itemData={itemData} />
    </Group>
  );
};

export const Group = ({
  children,
  className = '',
  itemData,
  direction = 'vertical',
  scrollable = false,
  tag: Tag = 'div',
  ...props
}) => {
  children = Array.isArray(children) ? children : [children];
  if (scrollable) {
    className = `${className} scrollable`;
  }
  return (
    <Component
      {...props}
      className={`group direction-${direction} ${className}`.trim()}
      component={Tag}
    >
      {itemData
        ? children
            .filter((child) => child)
            .map(({ props, ...child }) => ({
              ...child,
              props: {
                ...props,
                itemData,
                itemDataSrc: props?.src ? itemData[props?.src] : props?.src,
              },
            }))
        : children}
    </Component>
  );
};

Group.displayName = 'Group';
GroupCompiler.displayName = 'Group';
export default GroupCompiler;
