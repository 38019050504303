import React from 'react';
import Container from 'react-bootstrap/Container';
import Component, { Content } from '../components/Component';

export const Footer = ({ id, background_color: color, content }) => {
  return (
    <Component
      id={id}
      margin={{ top: '5%' }}
      padding={20}
      background={{ type: 'solid', color }}
      component="footer"
    >
      <Container>
        <Content content={content} />
      </Container>
    </Component>
  );
};

export default Footer;
