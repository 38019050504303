export const DATABASES = {
  INITIAL_STATE: {
    database: {},
  },

  SET: 'DATABASES_SET',
};

export const DatabasesReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case DATABASES.SET:
      draft.database = {
        ...draft.database,
        ...payload,
      };
      break;

    default:
      return draft;
  }
};
