import React from 'react';
import Component from './Component';
import beach from '../../../assets/img/beach.jpg';

export const CameraCompiler = (props) => {
  return <Camera {...props} />;
};

export const Camera = (props) => {
  return (
    <Component {...props} className="camera">
      <div className="img" style={{ backgroundImage: `url(${beach})` }} />
    </Component>
  );
};

export default CameraCompiler;
