import gql from 'graphql-tag';

export const LIST_TAGS = gql`
  query ListTags($accountId: ID!, $cursor: String, $first: Int!) {
    tags(accountId: $accountId, first: $first, after: $cursor) {
      edges {
        node {
          id
          accountId
          name
          createdAt
          updatedAt
          taggings(first: $first) {
            edges {
              node {
                id
                name
                tag {
                  id
                }
                file {
                  id
                  hash
                  extension
                  mimeType
                  size
                  url
                  createdAt
                }
                sharings(first: $first) {
                  edges {
                    node {
                      id
                      token
                    }
                  }
                }
                createdAt
                updatedAt
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const SHOW_TAG = gql`
  query ShowTag($id: ID!, $first: Int!) {
    tag(id: $id) {
      id
      name
      taggings(first: $first) {
        edges {
          node {
            id
            name
            file {
              id
            }
          }
        }
      }
    }
  }
`;
