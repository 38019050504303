import { useMemo, useState } from 'react';
import ls from 'local-storage';

export function useExpanded(path = '') {
  let _path = `${path}`.replace(
    window.location.pathname.split('/').slice(5).join('/'),
    ''
  );
  const user = ls('user') || {};
  const { _state = {} } = user;
  const pathData = _state[_path] || {};
  const { expanded: localExpanded = false } = pathData;
  const [expanded, setExpanded] = useState(localExpanded);

  useMemo(() => {
    setExpanded(localExpanded);
  }, [localExpanded]);

  const toggleExpanded = (params = {}) => {
    const user = ls('user') || {};
    let { _state = {} } = user;
    const pathData = _state[_path] || {};
    let { path: __path = _path, expanded = !localExpanded, child } = params;
    __path = `${__path}`.replace(
      window.location.pathname.split('/').slice(5).join('/'),
      ''
    );
    __path = `${__path}${typeof child !== 'undefined' ? `/${child}` : ''}`;
    _state = {
      ..._state,
      [__path]: { ...pathData, expanded },
    };
    ls('user', { ...user, _state });
    !child && setExpanded(expanded);
  };

  return { expanded, toggleExpanded };
}
