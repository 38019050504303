import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { PAGES } from './_config';
import { PrivateRoute, SubscriptionAlerts } from './components';
import { ContextProviders } from './context';
import {
  AccountPage,
  CustomerPage,
  DashboardPage,
  DoyoFilesPage,
  DoyoStoriesPage,
  ExitPage,
  FlowsPage,
  ForgotPage,
  HomePage,
  IntegrationsPage,
  LoginPage,
  MainPage,
  MyProjectsPage,
  NewProjectPage,
  NotFoundPage,
  PoliciesPage,
  PreviewPage,
  PricingPage,
  PublishPage,
  ReceiptsPage,
  RegisterPage,
  ResetPasswordPage,
  ResourcesPage,
  SettingsPage,
  SourcesPage,
  StylePage,
  SubscriptionPage,
  TemplatesPage,
  TermsPage,
  UpgradePage,
  UnsubscribePage,
  VerifyEmailPage,
} from './pages';
import './assets/scss/main.scss';

function App() {
  const location = useLocation();

  useEffect(() => {
    window.gtag?.('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return (
    <ContextProviders>
      <SubscriptionAlerts />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path={PAGES.HOME} component={HomePage} />
        <Route exact path={PAGES.EXIT} component={ExitPage} />
        <Route
          exact
          path={`${PAGES.EXIT}/:referrer(.+)`}
          component={ExitPage}
        />
        <Route exact path={PAGES.FORGOT} component={ForgotPage} />
        <Route
          exact
          path={`${PAGES.PASSWORD_RESET}/:token`}
          component={ResetPasswordPage}
        />
        <Route exact path={`/t/:token/:redirect(.+)`} component={LoginPage} />
        <Route exact path={PAGES.SIGN_IN} component={LoginPage} />
        <Route
          exact
          path={`${PAGES.SIGN_IN}/:referrer(.+)`}
          component={LoginPage}
        />
        <Route exact path={PAGES.SIGN_UP} component={RegisterPage} />
        <Route
          exact
          path={`${PAGES.SIGN_UP}/:referrer(.+)`}
          component={RegisterPage}
        />
        <Route exact path={PAGES.FLOWS} comp onent={FlowsPage} />
        <Route exact path={PAGES.BUY_DOYOCOINS} component={PricingPage} />
        <Route exact path={PAGES.PRICING} component={PricingPage} />
        <Route
          exact
          path={`${PAGES.PRICING}/:status`}
          component={PricingPage}
        />
        <Route
          exact
          path={`${PAGES.PRICING}/:status/:sessionId`}
          component={PricingPage}
        />
        <Route exact path={PAGES.LEGAL_POLICIES} component={PoliciesPage} />
        <Route exact path={PAGES.LEGAL_TERMS} component={TermsPage} />
        <Route exact path={PAGES.PREVIEW} component={PreviewPage} />
        <Route
          exact
          path={`${PAGES.PREVIEW}/:project`}
          component={PreviewPage}
        />
        <Route
          exact
          path={`${PAGES.PREVIEW}/:project/:file(.+)`}
          component={PreviewPage}
        />
        <Route exact path={PAGES.NEW_PROJECT} component={NewProjectPage} />
        <Route
          exact
          path={`${PAGES.NEW_PROJECT}/:type`}
          component={NewProjectPage}
        />
        <Route exact path={PAGES.MY_PROJECTS} component={MyProjectsPage} />
        <Route exact path={PAGES.DASHBOARD} component={DashboardPage} />
        <Route exact path={PAGES.APP} component={MainPage} />
        <Route exact path={`${PAGES.APP}/:project`} component={MainPage} />
        <Route
          exact
          path={`${PAGES.APP}/:project/:key(certificates|external|res)`}
          component={ResourcesPage}
        />
        <Route
          exact
          path={`${PAGES.APP}/:project/:key(certificates|external|res)/:file(.+)`}
          component={ResourcesPage}
        />
        <Route
          exact
          path={`${PAGES.APP}/:project${PAGES.STYLE}`}
          component={StylePage}
        />
        <Route
          exact
          path={`${PAGES.APP}/:project${PAGES.INTEGRATIONS}`}
          component={IntegrationsPage}
        />
        <Route
          exact
          path={`${PAGES.APP}/:project${PAGES.SETTINGS}`}
          component={SettingsPage}
        />
        <Route
          exact
          path={`${PAGES.APP}/:project${PAGES.PUBLISH}`}
          component={PublishPage}
        />
        <Route
          exact
          path={`${PAGES.APP}/:project/:file(.+)`}
          component={MainPage}
        />
        <PrivateRoute exact path={PAGES.ACCOUNT} component={AccountPage} />
        <PrivateRoute
          exact
          path={PAGES.BILLING_HISTORY}
          component={ReceiptsPage}
        />
        <PrivateRoute
          exact
          path={PAGES.BILLING_DATA}
          component={CustomerPage}
        />
        <PrivateRoute
          exact
          path={PAGES.BILLING_PAYMENT}
          component={SourcesPage}
        />
        <PrivateRoute
          exact
          path={`${PAGES.VERIFY}/:identifierId/:token`}
          component={VerifyEmailPage}
        />
        <PrivateRoute
          exact
          path={PAGES.SUBSCRIPTION}
          component={SubscriptionPage}
        />
        <Route exact path={PAGES.LEGAL_TERMS} component={TermsPage} />
        <Route exact path={PAGES.LEGAL_POLICIES} component={PoliciesPage} />
        <PrivateRoute
          exact
          path={PAGES.UPGRADE_PLANS}
          component={UpgradePage}
        />
        <PrivateRoute exact path={PAGES.TEMPLATES} component={TemplatesPage} />
        <Route exact path={PAGES.UNSUBSCRIBE} component={UnsubscribePage} />
        <Route exact path={PAGES.CODOOZER} component={HomePage} />
        <Route exact path={PAGES.KUSTODIO} component={DoyoFilesPage} />
        <Route exact path={PAGES.STORIES} component={DoyoStoriesPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </ContextProviders>
  );
}

export default App;
