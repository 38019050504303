import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiAirplane,
  mdiAlienOutline,
  mdiBarley,
  mdiBasketball,
  mdiBrain,
  mdiBullhornOutline,
  mdiCactus,
  mdiFlask,
  mdiHeartOutline,
  mdiHandPointingDown,
  mdiLightbulbOnOutline,
} from '@mdi/js';
import Helmet from 'react-helmet';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { PAGES, URL } from '../../_config';
import { Footer, Header } from '../../components';
import {
  balloon,
  chart,
  chat,
  devices,
  marker,
  picture,
  rocket,
} from '../../assets/svg/home';
import { waves } from '../../assets/svg/figures';

function HomePage() {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  if (pathname !== '/') {
    return;
  }

  const bannerIcons = [
    mdiAirplane,
    mdiAlienOutline,
    mdiBarley,
    mdiBasketball,
    mdiBrain,
    mdiBullhornOutline,
    mdiCactus,
    mdiFlask,
    mdiHeartOutline,
    mdiHandPointingDown,
    mdiLightbulbOnOutline,
  ];
  const bannerIcon =
    bannerIcons[parseInt(Math.random() * bannerIcons.length, 10)];
  return (
    <main className="home">
      <Helmet
        title={t('seo.titles.home')}
        meta={[
          {
            name: 'description',
            content: t('seo.descriptions.home'),
          },
        ]}
      />
      <Header />
      <div className="jumbotron jumbotron-fluid position-relative">
        <Container className="position-relative">
          <Row className="align-items-center justify-content-center text-center">
            <Col lg={{ span: 6 }} className="p-0">
              <h1 className="display-4 text-white font-size-48--md-down font-weight-bold">
                <Icon path={bannerIcon} size={4} />
                <br />
                {t('home.banner.title')}
              </h1>
              <p className="text-black-50">{t('home.banner.subtitle')}</p>
              <a
                className="d-inline-block m-2"
                href={`${URL.DOYO}${PAGES.SIGN_UP}/${URL.FACE}`}
              >
                <Button variant="white" size="lg" className="card-link">
                  {t('home.banner.button')}
                </Button>
              </a>
              <Link className="d-inline-block m-2" to={PAGES.DASHBOARD}>
                <Button variant="yellow" size="lg" className="card-link">
                  {t('home.banner.button2')}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <img className="waves" src={waves} alt="waves" />
      </div>
      <Container className="marketing">
        <Row>
          <Col lg={{ span: 4 }}>
            <img src={picture} alt="Icon 1" width="140" height="140" />
            <h2>{t('home.block1.title1')}</h2>
            <p>{t('home.block1.text1')}</p>
          </Col>
          <Col lg={{ span: 4 }}>
            <img src={chart} alt="Icon 3" width="140" height="140" />
            <h2>{t('home.block1.title2')}</h2>
            <p>{t('home.block1.text2')}</p>
          </Col>
          <Col lg={{ span: 4 }}>
            <img src={rocket} alt="Icon 3" width="140" height="140" />
            <h2>{t('home.block1.title3')}</h2>
            <p>{t('home.block1.text3')}</p>
          </Col>
        </Row>

        <hr className="featurette-divider" />

        <Row className="featurette">
          <Col md={{ span: 7 }}>
            <h2 className="featurette-heading">{t('home.features.title1')}</h2>
            <p className="lead">{t('home.features.text1')}</p>
          </Col>
          <Col md={{ span: 5 }}>
            <img
              alt="feature 1"
              className="featurette-image img-fluid mx-auto h-auto"
              src={balloon}
            />
          </Col>
        </Row>
        <hr className="featurette-divider" />
        <Row className="featurette">
          <Col md={{ span: 7 }}>
            <h2 className="featurette-heading">{t('home.features.title2')}</h2>
            <p className="lead">{t('home.features.text2')}</p>
          </Col>
          <Col md={{ span: 5, order: 1 }}>
            <img
              alt="feature 2"
              className="featurette-image img-fluid mx-auto h-auto"
              src={chat}
            />
          </Col>
        </Row>
        <hr className="featurette-divider" />
        <Row className="featurette">
          <Col md={{ span: 7 }}>
            <h2 className="featurette-heading">{t('home.features.title3')}</h2>
            <p className="lead">{t('home.features.text3')}</p>
          </Col>
          <Col md={{ span: 5 }}>
            <img
              alt="feature 3"
              className="featurette-image img-fluid mx-auto h-auto"
              src={devices}
            />
          </Col>
        </Row>
        <hr className="featurette-divider" />
        <Row className="featurette">
          <Col md={{ span: 7 }}>
            <h2 className="featurette-heading">{t('home.features.title4')}</h2>
            <p className="lead">{t('home.features.text4')}</p>
          </Col>
          <Col md={{ span: 5 }}>
            <img
              alt="feature 4"
              className="featurette-image img-fluid mx-auto h-auto"
              src={marker}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </main>
  );
}

export default HomePage;
export { HomePage };
