import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, es } from './locales';

i18next.use(LanguageDetector).init({
  resources: {
    en,
    es,
  },
  fallbackLng: 'en',
  whitelist: ['en', 'es'],
  nonExplicitWhitelist: true,
  load: 'languageOnly',
  defaultNS: 'translation',
  fallbackNS: ['translation'],
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['querystring', 'localStorage', 'navigator'],

    lookupLocalStorage: 'language',
    lookupQuerystring: 'hl',

    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
  },
  wait: true,
  parseMissingKeyHandler: (missing) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn('MISSING TRANSLATION:', missing);
    }
    return missing;
  },
});

class I18N extends React.PureComponent {
  componentDidMount() {
    i18next.changeLanguage(this.props.locale);
  }

  componentDidUpdate(prevProps) {
    const { locale: newLocale } = this.props;
    const { locale: oldLocale } = prevProps;

    if (oldLocale !== newLocale) {
      i18next.changeLanguage(newLocale);
    }
  }

  render() {
    return (
      <I18nextProvider i18n={i18next}>{this.props.children}</I18nextProvider>
    );
  }
}

export default I18N;
export { i18next };
