import React from 'react';
import BootstrapForm from 'react-bootstrap/Form';
import Component, { Content } from './Component';

export const FormCompiler = ({
  action,
  form_groups: formGroups,
  itemData,
  onsubmit,
  submit_button: submitButton,
  ...props
}) => {
  return (
    <Form {...props} component={BootstrapForm}>
      <Content content={formGroups} itemData={itemData} />
    </Form>
  );
};

export const Form = ({ children, ...props }) => {
  return (
    <Component {...props} component={BootstrapForm}>
      {children}
    </Component>
  );
};

Form.Group = BootstrapForm.Group;

Form.displayName = 'Form';
FormCompiler.displayName = 'Form';
export default FormCompiler;
