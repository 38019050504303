import React, { createContext, useCallback, useEffect, useState } from 'react';
import { getHeaders } from '.';

import { URL } from '../_config';

import { useAccounts, useProjects } from '../hooks';

export const AiContext = createContext({});

export const AiProvider = (props) => {
  const { project = {}, updateProject = () => {} } = useProjects();
  const { account = {} } = useAccounts();

  const chatCompletion = async (messages) => {
    const result = await fetch(`${URL.AI}/accounts/${account.id}/openai`, {
      headers: getHeaders({ auth: true }),
      method: 'POST',
      body: JSON.stringify({
        method: 'chat.completion.create',
        model: 'gpt-3.5-turbo',
        max_tokens: 1000,
        temperature: 0.1,
        messages: messages,
      }),
    });
    const ready = await result.json();
    console.log(ready);
    return ready.choices[0].message.content;
  };

  const getMessages = (text, langs) => {
    let prompt = 'Translate "' + text + '" from ' + langs.default + ' to ';

    const keys = Object.keys(langs);
    const selectedKeys = keys.filter(
      (key) => typeof langs[key] === 'boolean' && langs[key]
    );
    prompt += selectedKeys.join(', ');
    prompt += '.';

    const messages = [];

    if ('rules' in langs) {
      messages.push({
        role: 'system',
        content: langs.rules,
      });
    }

    messages.push({
      role: 'user',
      content:
        prompt +
        ' Give the answer as a json object, using the iso code of each language as key. Do not include the key "default" nor the key "' +
        langs.default +
        '" in that json.',
    });

    return messages;
  };

  const translateText = async (path, value) => {
    // Must have langs config in app.json
    if (!('langs' in project.data.app)) {
      return false;
    }

    const defaultText = typeof value === 'object' ? value['default'] : value;

    const messages = getMessages(defaultText, project.data.app.langs);

    const translated = await chatCompletion(messages);
    try {
      const json = JSON.parse(translated);
      json['default'] = defaultText;
      return json;
    } catch (error) {}

    return null;
  };
  const translateProject = async (project, updateProject, onlyMissing) => {
    const { data = {} } = project;
    // const { app = {}, views = [] } = data;
    // const { type } = app;

    const _data = { ...data };
    // _data.title = await translate(_data.title);

    //updateProject(data);
    return true;
  };

  return (
    <AiContext.Provider
      value={{
        translateText,
        translateProject,
      }}
    >
      {props.children}
    </AiContext.Provider>
  );
};

export const AiConsumer = AiContext.Consumer;
export default AiContext;
