import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Row } from 'react-bootstrap';
import parse from 'html-react-parser';
import { Canceled, Plans, Success } from '.';
import { PAGES } from '../../_config';
import { Footer, Header } from '../../components';
import { waves } from '../../assets/svg/figures';

const BuyDoyoCoins = ({ match: { params: { status, sessionId } = {} } }) => {
  if (status === 'success') {
    return (
      <div className="mb-3">
        <Success sessionId={sessionId} />
      </div>
    );
  }

  return (
    <div className="mb-3">
      {status === 'canceled' && <Canceled />}
      <Plans />
    </div>
  );
};

export const PricingPage = (props) => {
  const { t } = useTranslation();
  return (
    <main className="pricing">
      <Header variant="primary" />
      <div className="jumbotron jumbotron-fluid position-relative">
        <Container className="position-relative">
          <Row className="align-items-center justify-content-center text-center">
            <Col lg={{ span: 8 }} className="p-0">
              <h1 className="pt-5 font-weight-bolder text-white">
                {t('pricing.title')}
              </h1>
              <h5 className="text-gray pb-5">{t('pricing.subtitle')}</h5>
              <Link className="d-inline-block m-2" to={PAGES.SIGN_UP}>
                <Button variant="white" size="lg">
                  {t('home.banner.button')}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
        <img className="waves" src={waves} alt="waves" />
      </div>
      <Container>
        <BuyDoyoCoins {...props} />
        <hr className="mb-5" />
      </Container>
      <Footer />
    </main>
  );
};

export default PricingPage;
