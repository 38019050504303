import React, { useEffect, useRef, useState } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Component from './Component';
import Label from './Label';
import Text from './Text';
import { useFunctions, useSource } from '../../hooks';
import { getTextToShow, parse } from '../../util';

const InputCheckbox = (props) => {
  return <FormCheck {...props} type="checkbox" />;
};
const InputSwitch = (props) => {
  return <FormCheck {...props} type="switch" />;
};
const InputRadio = (props) => {
  return <FormCheck {...props} type="radio" />;
};
const FormCheck = ({
  className = '',
  width,
  height,
  padding,
  margin,
  floating,
  label,
  ...props
}) => {
  const { background } = props;
  if (!label) {
    return (
      <Input
        checked={false}
        type="checkbox"
        {...props}
        background={background}
        className={className}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        component={Form.Control}
        valueField="checked"
      />
    );
  }
  const Wrapper = floating ? FloatingLabel : 'div';
  return (
    <Component
      id={`${props.id}Wrapper`}
      background={background}
      className={`${className} d-flex flex-column`.trim()}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      label={label}
      component={Wrapper}
    >
      {label && !floating && (
        <Label id={`${props.id}Label`} htmlFor={props.id} label={label} />
      )}
      <Input
        checked={false}
        type="checkbox"
        value=""
        {...props}
        component={Form.Check}
        valueField="checked"
      />
    </Component>
  );
};

const InputColor = ({ className, label, ...props }) => {
  if (!label) {
    return (
      <Input
        value=""
        {...props}
        className={className}
        type="color"
        component={Form.Control}
      />
    );
  }
  return (
    <div className={className}>
      {label && (
        <Label id={`${props.id}Label`} htmlFor={props.id} label={label} />
      )}
      <Input value="" {...props} type="color" component={Form.Control} />
    </div>
  );
};

const InputDate = ({
  className,
  width,
  height,
  padding,
  margin,
  label,
  ...props
}) => {
  const { background } = props;
  if (!label) {
    return (
      <Input
        value=""
        {...props}
        className={className}
        type="date"
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        component={Form.Control}
      />
    );
  }
  return (
    <Component
      id={`${props.id}Wrapper`}
      background={background}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
    >
      {label && (
        <Label id={`${props.id}Label`} htmlFor={props.id} label={label} />
      )}
      <Input value="" {...props} type="date" component={Form.Control} />
    </Component>
  );
};

const InputFile = ({
  className,
  width,
  height,
  padding,
  margin,
  label,
  ...props
}) => {
  const { background } = props;
  if (!label) {
    return (
      <Input
        value=""
        {...props}
        className={className}
        type="file"
        component={Form.Control}
        valueField="files"
        width={width}
        height={height}
        padding={padding}
        margin={margin}
      />
    );
  }
  return (
    <Component
      id={`${props.id}Wrapper`}
      background={background}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
    >
      {label && (
        <Label id={`${props.id}Label`} htmlFor={props.id} label={label} />
      )}
      <Input
        value=""
        {...props}
        type="file"
        component={Form.Control}
        valueField="files"
      />
    </Component>
  );
};

const InputRange = ({
  className,
  width,
  height,
  padding,
  margin,
  label,
  ...props
}) => {
  const { background } = props;
  if (!label) {
    return (
      <Input
        value={0}
        {...props}
        className={className}
        component={Form.Range}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
      />
    );
  }
  return (
    <Component
      id={`${props.id}Wrapper`}
      background={background}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
    >
      {label && (
        <Label id={`${props.id}Label`} htmlFor={props.id} label={label} />
      )}
      <Input value={0} {...props} component={Form.Range} />
    </Component>
  );
};

const InputSelect = ({
  className,
  dataSrc,
  filter,
  floating,
  width,
  height,
  padding,
  margin,
  label,
  mapSrc,
  options = [],
  order,
  ...props
}) => {
  const { background, id, itemData: data } = props;
  const { value: sourceData } = useSource(dataSrc, {
    data,
    isCollection: true,
    filter,
    order,
  });

  let children = options.map(({ label, value }, index) => (
    <option key={`${id}-option-${index}`} value={value || '-'}>
      {getTextToShow(label || '')}
    </option>
  ));

  let sourceChildren;
  if (sourceData) {
    const label = mapSrc?.label || 'label';
    const value = mapSrc?.value || 'value';
    sourceChildren = Object.values(sourceData).map((data, index) => (
      <option key={`${id}-option-${index}`} value={data[value] || '-'}>
        {getTextToShow(data[label])}
      </option>
    ));
    children = Array.isArray(children) ? children : [children];
    children = [...children, ...sourceChildren];
  }

  if (!label) {
    return (
      <Input
        value=""
        {...props}
        aria-label={props.id}
        className={className}
        background={background}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        component={Form.Select}
      >
        {children}
      </Input>
    );
  }
  const Wrapper = floating ? FloatingLabel : 'div';
  return (
    <Component
      id={`${props.id}Wrapper`}
      background={background}
      className={`${className} d-flex flex-column`.trim()}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      component={Wrapper}
    >
      {label && !floating && (
        <Label id={`${props.id}Label`} htmlFor={props.id} label={label} />
      )}
      <Input value="" {...props} aria-label={label} component={Form.Select}>
        {children}
      </Input>
    </Component>
  );
};

const InputEmail = (props) => {
  return <FormControl {...props} type="email" />;
};
const InputNumber = (props) => {
  return <FormControl {...props} type="number" />;
};
const InputPassword = (props) => {
  return <FormControl {...props} type="password" />;
};
const TextArea = (props) => {
  return <FormControl {...props} as="textarea" />;
};
const FormControl = ({
  className = '',
  width,
  height,
  padding,
  margin,
  floating,
  label,
  ...props
}) => {
  const { background } = props;
  if (props.as === 'textarea') {
    props.className = `${className} flex-grow-1`.trim();
  }
  if (!label) {
    return (
      <Input
        type="text"
        value=""
        {...props}
        background={background}
        className={className}
        width={width}
        height={height}
        padding={padding}
        margin={margin}
        component={Form.Control}
      />
    );
  }
  const Wrapper = floating ? FloatingLabel : 'div';
  return (
    <Component
      id={`${props.id}Wrapper`}
      background={background}
      className={`${className} d-flex flex-column`.trim()}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      label={label}
      component={Wrapper}
    >
      {label && !floating && (
        <Label id={`${props.id}Label`} htmlFor={props.id} label={label} />
      )}
      <Input
        placeholder=" "
        type="text"
        value=""
        {...props}
        component={Form.Control}
      />
    </Component>
  );
};

const sizes = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

const inputByType = {
  checkbox: InputCheckbox,
  colorpicker: InputColor,
  date: InputDate,
  datepicker: InputDate,
  email: InputEmail,
  inputfile: InputFile,
  number: InputNumber,
  password: InputPassword,
  radio: InputRadio,
  range: InputRange,
  select: InputSelect,
  switch: InputSwitch,
};

export const InputCompiler = ({
  area,
  format,
  onchanged,
  size,
  source,
  type,
  ...props
}) => {
  const InputByType =
    inputByType[format] ||
    inputByType[type] ||
    (area && TextArea) ||
    FormControl;

  return <InputByType {...props} size={sizes[size]} />;
};

export const Input = ({
  component = Form.Control,
  default_value: defaultInputValue,
  onBlur,
  onChange,
  src,
  valueField = 'value',
  ...mainProps
}) => {
  const { [valueField]: defaultValue, ...props } = mainProps;
  const changing = useRef(false);
  const { run } = useFunctions();
  const { id } = props;
  const { value: elementValue = defaultValue } = useSource(
    `@element.${id}.${valueField}`
  );
  let { value: srcValue = elementValue } = useSource(src);
  if (srcValue === null) {
    srcValue = '';
  }
  const [value, setValue] = useState(srcValue);
  const inputProps = {
    ...props,
    defaultValue: defaultInputValue,
    [valueField]: value !== src ? value : defaultValue,
  };

  const handleBlur = async () => {
    await onBlur?.();
  };

  const handleChange = (event) => {
    const { currentTarget } = event;
    let { [valueField]: value } = currentTarget;
    const { multiple, type } = props;
    if (type === 'file' && !multiple) {
      value = value[0];
    }
    setValue(parse(value));
  };

  useEffect(() => {
    if (value === srcValue || value._equals(srcValue)) {
      return;
    }
    if (!src) {
      onChange?.();
      return;
    }
    run([
      { function: 'set', what: src, value },
      {
        function: 'set',
        what: `@element.${id}`,
        value: { [valueField]: value },
      },
    ]);
  }, [value]);

  useEffect(() => {
    setValue(srcValue);
  }, [srcValue]);

  useEffect(() => {
    if (!changing.current) {
      return;
    }
    onChange?.();
    changing.current = false;
  }, [changing, srcValue]);

  return (
    <Component
      onBlur={handleBlur}
      onChange={handleChange}
      {...inputProps}
      component={component}
    />
  );
};

Input.Checkbox = InputCheckbox;
Input.Color = InputColor;
Input.Date = InputDate;
Input.Email = InputEmail;
Input.File = InputFile;
Input.Number = InputNumber;
Input.Password = InputPassword;
Input.Radio = InputRadio;
Input.Range = InputRange;
Input.Select = InputSelect;
Input.Switch = InputSwitch;
Input.Text = FormControl;
Input.TextArea = TextArea;

Input.displayName = 'Input';
InputCompiler.displayName = 'Input';
export default InputCompiler;
