// @flow
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { Button, Form } from 'react-bootstrap';

export const PasswordInput = ({ ...rest }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="password-input position-relative">
      <Form.Control
        type={show ? 'text' : 'password'}
        name="password"
        placeholder={t('common.promise_not_to_tell')}
        aria-describedby="passwordHelp"
        minLength="8"
        required
        {...rest}
      />
      <Button variant="link" className="eye" onClick={() => setShow(!show)}>
        <Icon path={show ? mdiEye : mdiEyeOff} />
      </Button>
    </div>
  );
};

export default PasswordInput;
