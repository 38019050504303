export const API = {
  INITIAL_STATE: {
    providers: [],
  },

  CHECK_PROVIDER: 'API_CHECK_PROVIDER',
  PROVIDERS_LIST: 'API_PROVIDERS_LIST',
};

export const ApiReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case API.CHECK_PROVIDER:
      draft.providers = draft.providers.map((provider) => {
        return provider.name === payload.provider.name
          ? { ...provider, ready: payload.ready }
          : provider;
      });
      break;
    case API.CREATE_PROJECT:
      draft.projectApi = payload.projectApi;
      break;
    case API.PROVIDERS_LIST:
      draft.providers = payload.providers;
      break;
    default:
      return draft;
  }
};
