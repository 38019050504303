import React, { useEffect, useState } from 'react';
import ReactIcon from '@mdi/react';
import Component from './Component';
import { kebabToCamel } from '../../util';

export const IconCompiler = ({ color, ...props }) => {
  return <Icon {...props} />;
};

export const Icon = ({ className = '', size = 1, src, ...props }) => {
  const [path, setPath] = useState();

  useEffect(() => {
    let mounted = true;
    import('@mdi/js').then((icons) => {
      mounted && setPath(icons[kebabToCamel(`mdi-${src}`)]);
    });
    return () => {
      mounted = false;
    };
  }, [src]);

  if (!path) {
    return null;
  }
  return (
    <Component
      {...props}
      className={`icon ${className}`.trim()}
      path={path}
      size={size}
      component={ReactIcon}
    />
  );
};

Icon.displayName = 'Icon';
IconCompiler.displayName = 'Icon';
export default IconCompiler;

/*
Prop          PropTypes       Default       Details
---------------------------------------------------------
path          string	        required	    SVG path data. Usually from @mdi/js
title         string, null	  null	        A11y <title>{title}</title>
description   string, null	  null	        A11y <desc>{desc}</desc>
size          number, string	null	        {size * 1.5}rem, '1em', '24px'
horizontal    bool	          false	        Flip Horizontal
vertical      bool	          false	        Flip Vertical
rotate        number	        0	    
        degrees 0 to 360
color         string	        currentColor	CSS color values, such as rgb(), rgba(), #000 etc
spin          bool, number	  false	        true = 2s, {spin}s
*/
