import React from 'react';
import { getLanguage, isObject } from '../../util';
import { useFile } from '../../hooks';
import Component from './Component';

export const PdfViewerCompiler = ({ ...props }) => {
  return <PdfViewer {...props} />;
};

export const PdfViewer = ({ ...props }) => {
  const language = getLanguage();
  const { id, file } = props;

  let fileToShow = file;
  if (isObject(fileToShow)) {
    fileToShow =
      typeof fileToShow[language] !== 'undefined'
        ? fileToShow[language]
        : fileToShow.default;
  }
  if (typeof fileToShow === 'undefined' || fileToShow === '') {
    fileToShow = '';
  }

  const { url = fileToShow } = useFile(fileToShow);

  return (
    <Component {...props}>
      <iframe title={id} src={url} />
    </Component>
  );
};

PdfViewer.displayName = 'PdfViewer';
PdfViewerCompiler.displayName = 'PdfViewer';
export default PdfViewerCompiler;
