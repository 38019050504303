import { mdiCellphone, mdiCommentArrowRightOutline, mdiLaptop, mdiServer } from '@mdi/js';

export const projectTypes = {
  FLOW: 'flow',
  MOBILE_APP: 'mobile_app',
  WEB: 'web',
  BACKEND: 'backend',
};

export const projectTypeIcon = {
  [projectTypes.MOBILE_APP]: mdiCellphone,
  [projectTypes.FLOW]: mdiCommentArrowRightOutline,
  [projectTypes.WEB]: mdiLaptop,
  [projectTypes.BACKEND]: mdiServer,
};

const commonProjectProperties = {
  id: { default: 'c1', type: 'string' },
};

export const project = {
  title: 'Project',
  default: { id: 'c1' },
  required: ['id'],
  properties: {
    ...commonProjectProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [projectTypes.MOBILE_APP]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonProjectProperties,
        data: {
          default: {},
          required: [
            'app',
            'certificates',
            'dialogs',
            'external',
            'jobs',
            'menus',
            'res',
            'view_wrappers',
            'views',
          ],
          additionalProperties: false,
          removeAdditional: true,
          properties: {
            app: { default: {} },
            certificates: { default: [], type: 'array' },
            dialogs: { default: [], type: 'array' },
            external: { default: [], type: 'array' },
            jobs: { default: [], type: 'array' },
            menus: { default: [], type: 'array' },
            res: { default: [], type: 'array' },
            view_wrappers: { default: [], type: 'array' },
            views: { default: [], type: 'array' },
          },
        },
      },
    },
    [projectTypes.FLOW]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonProjectProperties,
        data: {
          default: {},
          required: ['app', 'res', 'flows'],
          additionalProperties: false,
          removeAdditional: true,
          properties: {
            app: { default: {} },
            res: { default: [], type: 'array' },
            flows: { default: [], type: 'array' },
          },
        },
      },
    },
    [projectTypes.WEB]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonProjectProperties,
        data: {
          default: {},
          required: [
            'app',
            'dialogs',
            'external',
            'menus',
            'res',
            'view_wrappers',
            'views',
          ],
          additionalProperties: false,
          removeAdditional: true,
          properties: {
            app: { default: {} },
            certificates: { default: [], type: 'array' },
            dialogs: { default: [], type: 'array' },
            external: { default: [], type: 'array' },
            menus: { default: [], type: 'array' },
            res: { default: [], type: 'array' },
            view_wrappers: { default: [], type: 'array' },
            views: { default: [], type: 'array' },
          },
        },
      },
    },
    [projectTypes.BACKEND]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonProjectProperties,
        data: {
          default: {},
          required: [
            'app'
          ],
          additionalProperties: false,
          removeAdditional: true,
          properties: {
            app: { default: {} },
          },
        },
      },
    },
  },
};

export default project;
