import useProject from './useProject';

export const useColor = (colorRef = '') => {
  const { project = {} } = useProject();
  const { data = {} } = project;
  const { app = {} } = data;
  const { colors = {} } = app;
  const colorKey = colorRef.replace('@color.', '');
  let hex = colors[colorKey];
  let hexNoHashtag = hex?.replace('#', '');
  hexNoHashtag =
    hexNoHashtag?.length % 4 === 0
      ? hexNoHashtag.substr(2) + hexNoHashtag.substr(0, 2)
      : hexNoHashtag;
  hex = hex && `#${hexNoHashtag}`;
  return { key: colorKey, hex };
};

export default useColor;
