import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { HOST } from '../../../_config';
import { AuthorizedHeader, UnauthorizedHeader } from '../../../components';
import { useAccounts } from '../../../hooks';
import { logoWhite } from '../../../assets/svg/logos';

function DoyoStoriesHeader() {
  const { account } = useAccounts();

  return (
    <header className="fixed-top">
      <Navbar variant="primary" expand="sm">
        <a
          href={HOST.STORIES}
          className="navbar-brand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="logo Stories" src={logoWhite} height="42px" />
          <span>stories</span>
        </a>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav>
            {(account && <AuthorizedHeader />) || <UnauthorizedHeader />}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default DoyoStoriesHeader;
export { DoyoStoriesHeader };
