import React, { useEffect } from 'react';
import Component from './Component';
import { getLanguage } from '../../../_helpers';
import { isObject } from '../../util';
import { useClasses, useColor } from '../../hooks';

export const InputTextCompiler = ({
  border_color: borderColorProp = '@color.primaryText',
  border_color_focus: borderColorFocusProp = '@color.accent',
  className = '',
  font,
  font_size: fontSize,
  format = 'text',
  hint = '',
  hint_color: hintColorProp = '@color.secondaryText',
  value,
  ...props
}) => {
  const language = getLanguage();
  const { id } = props;
  const { addClass, style } = useClasses(id);
  const { hex: borderColor = '' } = useColor(borderColorProp);
  const { hex: borderColorFocus = '' } = useColor(borderColorFocusProp);
  const { hex: hintColor = '' } = useColor(hintColorProp);
  let classes = {};

  // font
  let ff;
  if (font) {
    ff = { [`ff-${font.replace('@font.', '')}`]: '' };
    classes = { ...classes, ...ff };
  }

  // font_size
  let f;
  if (typeof fontSize !== 'undefined') {
    if (typeof fontSize === 'number') {
      f = { [`f-${fontSize}px`]: `font-size: ${fontSize}px` };
    } else if (fontSize?.match(/%$/)) {
      f = { [`f-${fontSize.replace('%', '')}`]: `font-size: ${fontSize}` };
    } else if (fontSize?.match(/rem$/)) {
      f = { [`f-${fontSize.replace('.', '_')}`]: `font-size: ${fontSize}` };
    }
    classes = { ...classes, ...f };
  }

  let hintToShow = hint;
  if (isObject(hintToShow)) {
    hintToShow =
      typeof hintToShow[language] !== 'undefined'
        ? hintToShow[language]
        : hintToShow.default;
  }
  if (typeof hintToShow === 'undefined' || hintToShow === '') {
    hintToShow = '';
  }

  useEffect(() => {
    addClass({ ff });
  }, [font]);

  useEffect(() => {
    addClass({ f });
  }, [fontSize]);

  return (
    <>
      <style>{style}</style>
      <InputText
        {...props}
        borderColor={borderColor}
        borderColorFocus={borderColorFocus}
        className={`${className} ${Object.keys(classes).join(' ')}`.trim()}
        hint={hintToShow}
        hintColor={hintColor}
      />
    </>
  );
};
export const InputText = ({
  borderColor,
  borderColorFocus,
  className = '',
  format,
  hint,
  hintColor,
  value,
  ...props
}) => {
  const { id } = props;

  return (
    <Component {...props} className={`input-text ${className}`.trim()}>
      <style>{`
                #${id}Input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: ${hintColor} !important;
                }
                #${id}Input::-moz-placeholder { /* Firefox 19+ */
                    color: ${hintColor} !important;
                }
                #${id}Input:-ms-input-placeholder { /* IE 10+ */
                    color: ${hintColor} !important;
                }
                #${id}Input:-moz-placeholder { /* Firefox 18- */
                    color: ${hintColor} !important;
                }
                #${id}Input {
                    border-color: ${borderColor} !important;
                }
                #${id}Input:focus {
                    border-color: ${borderColorFocus} !important;
                }
            `}</style>
      <input id={`${id}Input`} type={format} value={value} placeholder={hint} />
    </Component>
  );
};

export default InputText;
