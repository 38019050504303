import React from 'react';
import { Layout } from 'antd';
import { getUnique } from '../../_helpers';
import { useProjects } from '../../hooks';
import { SearchBox, Tree } from '../../components';

export const ProjectTree = (props) => {
  const {
    handleItemClone = () => {},
    handleItemCreation = () => {},
    handleItemDelete = () => {},
    handleItemReplace = () => {},
    match = {},
    path,
  } = props;
  const {
    params: { file: fileNamePath = '' },
  } = match;
  const { project = {} } = useProjects();
  const { data = {} } = project;
  const { app = {} } = data;
  const { project_name: projectName } = app;
  const { certificates = [], external = [], res = [] } = data;
  const options = Object.values([...certificates, ...external, ...res])
    .map(({ id }) => `${projectName}/res/${id}`)
    .map((op) => ({ label: op, value: op, to: `/app/${op}` }))
    .sort((op1, op2) => (op1 < op2 ? -1 : 1));

  const getChildren = (data = []) => {
    if (!data.length) {
      return [];
    }
    const uniqueFolders = getUnique(
      data
        .filter(({ id = '' }) => id.includes('/'))
        .map(({ id = '' }) => ({ id: id.split('/')[0] }))
    );
    const folders = uniqueFolders.map(({ id }) => {
      return {
        id,
        children: getChildren(
          data
            .filter(({ id: _id }) => _id.includes(`${id}/`))
            .map((attrs) => ({
              ...attrs,
              id: attrs.id.replace(`${id}/`, ''),
            })),
          id
        ),
      };
    });

    const files = data
      .filter(({ id = '' }) => !id.includes('/'))
      .map(({ id = '' }) => ({ id }));

    return [...folders, ...files];
  };

  let value = Object.keys(data).map((key) => {
    const item = { id: key };
    const value = data[key];
    if (Array.isArray(value)) {
      item.children = getChildren(value);
    }
    return item;
  });

  if (!project.id || ['settings', 'style'].includes(fileNamePath)) {
    return null;
  }
  value = value.filter(({ id }) =>
    ['certificates', 'external', 'res'].includes(id)
  );

  return (
    <Layout.Sider className="sidebar-menu" width={240}>
      <div className="search-box-wrapper">
        <SearchBox options={options} />
      </div>
      <div
        id="ProjectTree"
        className="tree-wrapper"
        style={{ overflow: 'auto' }}
      >
        {value && (
          <Tree
            current={path}
            data={{ value }}
            project={project}
            parent={{ path: `/${projectName}` }}
            handleItemClone={handleItemClone}
            handleItemCreation={handleItemCreation}
            handleItemDelete={handleItemDelete}
            handleItemReplace={handleItemReplace}
          />
        )}
      </div>
    </Layout.Sider>
  );
};

export default ProjectTree;
