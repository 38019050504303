import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import ls from 'local-storage';
import { message } from 'antd';
import { PAGES } from '../_config';
import {
  APPLY_RECOVERY,
  CREATE_AUTHENTICATION,
  CREATE_RECOVERY,
  CREATE_USER,
  DELETE_AUTHENTICATION,
} from '../graphql/mutations';
import { useAlerts, useReferrer } from '../hooks';
import { ViewerProvider } from './Viewer';
import { UserReducer, USER } from './reducers';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [state, dispatch] = useImmerReducer(UserReducer, USER.INITIAL_STATE);
  const { alertError } = useAlerts();
  const { base = '/', pathname } = useReferrer();
  const { auth = {} } = ls('user') || {};
  const { token } = auth;

  const [applyRecovery, { loading: applyRecoveryLoading }] = useMutation(
    APPLY_RECOVERY,
    {
      context: { passport: true },
      onError: ({ graphQLErrors }) => alertError(graphQLErrors),
    }
  );

  const [
    createAuthentication,
    { error: createAuthenticationError, loading: createAuthenticationLoading },
  ] = useMutation(CREATE_AUTHENTICATION, {
    context: { passport: true },
    onCompleted: ({ createAuthentication }) => {
      window.gtag?.('event', 'createAuthentication', {
        ...createAuthentication,
        base,
      });
      const { id, token } = createAuthentication;
      ls('user', { auth: { id, token } });
      if (base !== '/') {
        window.location.href = `${base}/t/${token}/${pathname}`;
        ls.remove('referrer');
        return;
      }
      history.push(PAGES.DASHBOARD);
    },
    onError: ({ graphQLErrors }) => alertError(graphQLErrors),
  });

  const [createRecovery, { loading: createRecoveryLoading }] = useMutation(
    CREATE_RECOVERY,
    {
      context: { passport: true },
      onCompleted: ({ createRecovery }) => {
        window.gtag?.('event', 'createRecovery', {
          ...createRecovery,
          base,
        });
        message.success(t('forgot.request_sent'));
      },
      onError: ({ graphQLErrors }) => alertError(graphQLErrors),
    }
  );

  const [
    createUser,
    { data, error: createUserError, loading: createUserLoading },
  ] = useMutation(CREATE_USER, {
    context: { passport: true },
    onCompleted: ({ createUser }) => {
      window.gtag?.('event', 'createUser', { ...createUser, base });
    },
    onError: ({ graphQLErrors }) => alertError(graphQLErrors),
  });

  const [
    deleteAuthentication,
    { error: deleteAuthenticationError, loading: deleteAuthenticationLoading },
  ] = useMutation(DELETE_AUTHENTICATION, {
    context: { passport: true },
    onCompleted: () => {
      const { url = '/' } = ls('referrer') || {};
      ls.remove('user');
      window.location.href = url;
    },
    onError: ({ graphQLErrors }) => {
      alertError(graphQLErrors);
    },
  });

  let error =
    createAuthenticationError || createUserError || deleteAuthenticationError;
  let loading =
    applyRecoveryLoading ||
    createAuthenticationLoading ||
    createRecoveryLoading ||
    createUserLoading ||
    deleteAuthenticationLoading;

  const showRegisterModal = () => {
    dispatch({ type: USER.SHOW_REGISTER });
  };

  const hideRegisterModal = () => {
    dispatch({ type: USER.HIDE_REGISTER });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        applyRecovery,
        createAuthentication,
        createRecovery,
        createUser,
        data,
        deleteAuthentication,
        error,
        loading,
        showRegisterModal,
        hideRegisterModal,
      }}
    >
      {(token && <ViewerProvider>{children}</ViewerProvider>) || children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
export default UserContext;
