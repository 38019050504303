import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Affix, Button, Card, Divider, List, Space } from 'antd';
import { getTextToShow } from '../../_helpers';
import { projectTypes } from '../../schemas/project';
import blankMobileAppScreenshot from '../../assets/defaults/projects/mobile_app.png';
import blankFlowScreenshot from '../../assets/defaults/projects/flow.png';
import blankWebScreenshot from '../../assets/defaults/projects/web.png';
import { useNewProject, useTemplates } from '../../hooks';

const blankScreenshots = {
  [projectTypes.MOBILE_APP]: {
    screenshot: blankMobileAppScreenshot,
  },
  [projectTypes.FLOW]: {
    screenshot: blankFlowScreenshot,
  },
  [projectTypes.WEB]: {
    screenshot: blankWebScreenshot,
  },
};

export const Templates = () => {
  const { t } = useTranslation();
  const { getTemplates, loading, templates = [] } = useTemplates();
  const {
    type = '',
    setData = () => {},
    setStep = () => {},
    template,
  } = useNewProject();
  const filteredTemplates = templates.filter(({ type: t }) => {
    t = Array.isArray(t) ? t : [t];
    return t.includes(type);
  });
  const data = [blankScreenshots[type], ...filteredTemplates].filter((t) => t);

  const handleNext = () => {
    setStep((step) => step + 1);
  };

  const handleOpenTemplate = () => {
    window.open(template?.preview_url);
  };

  useEffect(() => {
    getTemplates({ subject: 'project', type });
  }, []);

  return (
    <>
      <div className="px-5 py-3">
        <h3>{t('new_project.select_template')}</h3>
        <List
          className="new-project-templates"
          dataSource={data}
          loading={loading}
          renderItem={(item) => {
            const {
              title: titleObj,
              description: descriptionObj,
              screenshot: defaultScreenshot,
              screenshots: screenshotsObj,
            } = item;
            const active = item._equals(template);
            let title = t('projects.from_scratch');
            let description = '';
            let screenshots = [];
            try {
              title = getTextToShow(JSON.parse(titleObj));
              description = getTextToShow(JSON.parse(descriptionObj));
              screenshots = JSON.parse(screenshotsObj);
            } catch (error) {}
            const screenshot = screenshots[0] || defaultScreenshot;
            return (
              <Card
                type={active ? 'inner' : ''}
                hoverable
                style={{ width: 200 }}
                data-type={type}
                cover={<img src={screenshot} alt={title} />}
                onClick={() => setData({ template: item })}
              >
                <Card.Meta title={title} description={description} />
              </Card>
            );
          }}
        />
      </div>
      <Affix
        offsetBottom={0}
        target={() => document.getElementById('WorkingArea')}
      >
        <div className="bg-white">
          <Divider className="mt-0 mb-2" />
          <Space className="px-5 py-3">
            <Button
              size="large"
              disabled={!template?.preview_url}
              onClick={handleOpenTemplate}
            >
              {t('common.preview')}
            </Button>
            <Button
              size="large"
              type="primary"
              disabled={!template}
              onClick={handleNext}
            >
              {t('common.next')}
            </Button>
          </Space>
        </div>
      </Affix>
    </>
  );
};

export default Templates;
