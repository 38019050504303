import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { Button, Container, Dropdown, Navbar, Nav } from 'react-bootstrap';
import { PAGES, URL } from '../_config';
import { getAvatar } from '../_helpers';
import { useAccounts, useViewer } from '../hooks';
import { logo, logoWhite } from '../assets/svg/logos';

export const AuthorizedHeader = (props) => {
  const { color = 'primary' } = props;
  const { t } = useTranslation();
  const { account = {} } = useAccounts();
  const { logout } = useViewer();
  let { avatar: hash, customer } = account;
  let { email: customerEmail = '' } = customer || {};
  const textColor = color === 'primary' ? 'text-white' : 'text-dark';

  return (
    <>
      <Nav.Item>
        <Link to="/" className={`nav-link ${textColor}`}>
          {t('header.home')}
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to={PAGES.PRICING} className={`nav-link ${textColor}`}>
          {t('header.pricing')}
        </Link>
      </Nav.Item>

      <Dropdown nav className="w-0 rounded-pill">
        <Dropdown.Toggle nav className="d-inline-block p-0">
          <Button
            variant="white"
            className="icon border-0 bg-white p-0"
            data-cy="sidebar-avatar"
          >
            <img
              width="42"
              alt="avatar"
              src={getAvatar({ hash })}
              className="rounded-circle"
            />
          </Button>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Link
            to={PAGES.DASHBOARD}
            className="dropdown-item text-body px-3 py-2"
          >
            <span>{t('header.dashboard')}</span>
          </Link>
          <Link
            to={PAGES.ACCOUNT}
            className="dropdown-item text-body px-3 py-2"
          >
            <span>{t('header.view_profile')}</span>
          </Link>
          <a
            href={`${URL.SUBSCRIPTION}?prefilled_email=${customerEmail}`}
            className="dropdown-item text-body px-3 py-2"
            target="_blank"
            rel="noreferrer"
          >
            {t('header.subscription')}
          </a>
          <Dropdown.Divider />
          <Dropdown.Item onClick={() => logout()} className="px-3 py-2">
            {t('common.sign_out')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export const UnauthorizedHeader = (props) => {
  const { color = 'primary' } = props;
  const { t } = useTranslation();
  const textColor = color === 'primary' ? 'text-white' : 'text-dark';

  return (
    <>
      <Nav.Item>
        <Link to="/" className={`nav-link ${textColor}`}>
          {t('header.home')}
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to={PAGES.PRICING} className={`nav-link ${textColor}`}>
          {t('header.pricing')}
        </Link>
      </Nav.Item>

      <Nav.Item>
        <Link to={PAGES.SIGN_IN} className="nav-link">
          <Button
            variant="outline-white"
            className="text-uppercase font-weight-bold"
          >
            {t('common.sign_in')}
          </Button>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to={PAGES.SIGN_UP} className="nav-link">
          <Button variant="white" className="text-uppercase font-weight-bold">
            {t('common.sign_up_long')}
          </Button>
        </Link>
      </Nav.Item>
    </>
  );
};

export const Header = ({ children, ...props }) => {
  const { color = 'primary' } = props;
  const { t } = useTranslation();
  const { account } = useAccounts();

  return (
    <>
      <Helmet>
        <title>{t('seo.titles.home')}</title>
        <meta name="description" content={t('seo.descriptions.home')} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`%PUBLIC_URL%/favicon/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`%PUBLIC_URL%/favicon/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`%PUBLIC_URL%/favicon/favicon-16x16.png`}
        />
        <link
          rel="mask-icon"
          href={`%PUBLIC_URL%/favicon/safari-pinned-tab.svg`}
          color="#75b49c"
        />
        <link rel="shortcut icon" href={`%PUBLIC_URL%/favicon/favicon.ico`} />
        <script type="application/ld+json">
          {`{
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Doyo",
                        "url": "https://doyo.tech",
                        "alternateName": "Doyo Tech",
                        "logo": "https://www.doyo.tech/favicon/android-chrome-512x512.png",
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "email": "support@doyo.email",
                                "contactType": "customer support"
                            }
                        ],
                        "founders": [
                            { "@type": "Person", "name": "Ignacio Roda Segrelles" },
                            { "@type": "Person", "name": "Victor Díaz Marco" },
                            { "@type": "Person", "name": "Carlos Hermoso Sierra" }
                        ]
                    }`}
        </script>
      </Helmet>
      <Navbar bg="primary" sticky="top" expand="sm">
        <Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Link to="/" className="navbar-brand">
            <img
              alt="logo Doyo"
              src={color === 'white' ? logo : logoWhite}
              height="42px"
            />
          </Link>
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Nav navbarScroll className="align-items-center">
              {(account && <AuthorizedHeader {...props} />) || (
                <UnauthorizedHeader {...props} />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
