import React from 'react';
import Component, { Content } from '../components/Component';

export const Header = ({ content, ...props }) => {
  return (
    <Component
      {...props}
      className="header d-flex align-items-center"
      width="100%"
      height="wrap"
    >
      <Content content={content} />
    </Component>
  );
};

export default Header;
