import React, { useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Content } from '../components/Component';
import { Group } from '../components/Group';
import Image from '../components/Image';
import Nav from '../components/Nav';
import { useClasses, useColor } from '../../hooks';

export const Sidebar = ({
  background_color: backgroundColorProp,
  bordered,
  content = [],
  logo,
  menu,
  menu_style: menuStyle,
  menu_type: menuType,
  width = 280,
}) => {
  const { addClass, style } = useClasses('sidebar');
  const { key: backgroundColor } = useColor(backgroundColorProp);
  let classes = {};

  // padding
  const p = {
    'p-16px': 'padding:16px',
  };
  // width
  let w;
  if (typeof width !== 'undefined') {
    if (typeof width === 'number') {
      w = { [`w-${width}px`]: `width:${width}px!important` };
    } else if (width?.match(/%$/)) {
      w = { [`w-${width.replace('%', '')}`]: `width:${width}!important` };
    } else if (width === 'fill') {
      w = { [`w-fill`]: `width:0;flex-grow:1!important` };
    } else if (width === 'wrap') {
      w = { [`w-auto`]: `width:auto!important` };
    }
    classes = { ...classes, ...w };
  }

  useEffect(() => {
    addClass({ p });
  }, []);
  useEffect(() => {
    addClass({ w });
  }, [width]);

  return (
    <>
      <style>{style}</style>
      <Group
        data-component-id="sidebar"
        className={`bg-${backgroundColor} scrollable h-100 ${Object.keys(
          classes
        ).join(' ')}`.trim()}
      >
        {logo && (
          <Navbar.Brand className="w-100 p-16px">
            <Image type="image" width="100%" {...logo} />
          </Navbar.Brand>
        )}
        <Nav
          type="nav"
          width="100%"
          menu={menu}
          menu_type="pills"
          orientation="vertical"
        />
        <Content content={content} />
      </Group>
    </>
  );
};

export default Sidebar;
