import gql from 'graphql-tag';

export const SHOW_LOCATION = gql`
  query ShowLocation {
    location {
      city
      region
      country
      currency
    }
  }
`;
