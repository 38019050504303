// @flow
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { PAGES } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { useReferrer, useUser } from '../../hooks';
import { CirclePreloader, PasswordInput } from '../../components';

export const LoginForm = ({ callback = () => {} }) => {
  const { t } = useTranslation();
  const { createAuthentication, loading } = useUser();
  const { key } = useReferrer();
  const submitted = useRef(false);
  const [credentials, setCredentials] = useState({});
  const { identifier, password } = credentials;

  const handleSubmit = (event) => {
    event.preventDefault();
    const loginForm = event.currentTarget;
    if (loginForm.reportValidity() === false) {
      scrollToInvalid(loginForm);
      return;
    }
    createAuthentication({ variables: { ...credentials } });
  };

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <Form id="LoginForm" className="js-validate" onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label
          htmlFor="InputIdentifier"
          className="h6 small d-block text-uppercase"
        >
          {t('common.identifier')}
        </Form.Label>
        <Form.Control
          id="InputIdentifier"
          name="identifier"
          data-cy="InputIdentifier"
          aria-describedby="identifierHelp"
          placeholder={t('common.email_placeholder')}
          defaultValue={identifier}
          onChange={handleChange}
          tabIndex="1"
          required
          size="lg"
          autoFocus
        />
      </Form.Group>
      <Form.Group className="mt-3">
        <div className="d-flex justify-content-between align-items-center">
          <Form.Label
            htmlFor="InputPassword"
            className="h6 small d-block text-uppercase"
          >
            {t('common.password')}
          </Form.Label>
          <div className="mb-2 small">
            <Link
              to={{ pathname: PAGES.FORGOT, state: { key } }}
              className="link-muted"
              data-cy="forgot"
            >
              {t('login.forgot_password')}
            </Link>
          </div>
        </div>
        <PasswordInput
          id="InputPassword"
          data-cy="InputPassword"
          defaultValue={password}
          tabIndex="2"
          onChange={handleChange}
          size="lg"
        />
      </Form.Group>
      <Row className="align-items-center mt-3">
        <Col xs={6}>
          {[PAGES.SIGN_IN, PAGES.ADDONS_SIGN_IN].includes(
            window.location.pathname
          ) && (
            <span className="text-sm-right text-muted">
              <span className="d-block">{t('login.dont_have_account')}</span>
              <Link
                to={{ pathname: PAGES.SIGN_UP, state: { key } }}
                target={
                  window.location.pathname === PAGES.ADDONS_SIGN_IN
                    ? '_blank'
                    : ''
                }
                className="clearfix"
                data-cy="signup"
              >
                {t('common.sign_up_long')}
              </Link>
            </span>
          )}
        </Col>
        <Col xs={6} className="d-flex justify-content-end">
          <Button
            color="primary"
            className="position-relative text-uppercase font-weight-bold"
            disabled={submitted.current}
            data-cy="submit"
            size="lg"
            type="submit"
          >
            {loading && <CirclePreloader />}
            {t('common.sign_in')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
