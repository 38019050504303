import React from 'react';
import Component from './Component';
import { useSource } from '../../hooks';
import { isValidHex } from '../../util';

export const TimelineEntry = ({
  fields = {
    color: 'color',
    date: 'date',
    title: 'title',
    text: 'text',
  },
  ...props
}) => {
  let {
    [fields.color]: color = 'primary',
    [fields.date]: date,
    [fields.title]: title,
    [fields.text]: text,
  } = props;
  color = isValidHex(color) ? color : `var(--${color.replace('@color.', '')})`;
  return (
    <li>
      <strong>{title}</strong>
      <i style={{ color }} />
      <small className="timeline-date">
        {new Date(date).toLocaleDateString()}
      </small>
      <p>{text}</p>
    </li>
  );
};

export const TimelineCompiler = ({
  data_source: dataSource,
  orientation,
  ...props
}) => {
  return (
    <Timeline {...props} direction={orientation} src={dataSource}></Timeline>
  );
};

export const Timeline = ({
  children,
  className = '',
  direction = 'vertical',
  fields,
  src,
  ...props
}) => {
  const { value } = useSource(src);
  return (
    <Component
      {...props}
      className={`timeline ${direction} ${className}`.trim()}
    >
      <ul>
        {(value &&
          value.map((entryProps, index) => (
            <TimelineEntry
              key={`${props.id}-entry-${index}`}
              {...entryProps}
              fields={fields}
              index={index}
            />
          ))) ||
          children}
      </ul>
    </Component>
  );
};

Timeline.displayName = 'Timeline';
TimelineCompiler.displayName = 'Timeline';
export default TimelineCompiler;
