import { _function } from './defs/common';

export const appEvents = {
  title: 'AppEvents',
  additionalProperties: false,
  removeAdditional: true,
  default: {},
  properties: {
    onstart: {
      default: [],
      type: 'array',
      items: { ..._function },
    },
  },
};

export default appEvents;
