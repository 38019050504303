import app from './app';
import appEvents from './appEvents';
import defs from './defs';
import flows from './flows';
import jobs from './jobs';
import project from './project';
import refs from './refs';
import settings from './settings';
import style from './style';

export { app, appEvents, defs, flows, jobs, project, refs, settings, style };
export * from './refs';

const allSchemas = {
  app,
  appEvents,
  defs,
  flows,
  jobs,
  project,
  settings,
  style,
};

export const commonApiProperties = {
  method: {
    enum: [],
  },
};

let apiFunctions = {};
export function loadApiFunctions(schema) {
  apiFunctions = Object.entries(schema).reduce((schema, [key, value]) => {
    return {
      ...schema,
      [key]: {
        additionalProperties: false,
        removeAdditional: true,
        ...(value || {}),
        properties: {
          ...commonApiProperties,
          ...Object.entries(value?.properties || {}).reduce(
            (properties, [key, value]) => {
              const { type } = value;
              const extra =
                type === 'object'
                  ? {
                      default: value.default || {},
                      ...(value.properties
                        ? {}
                        : {
                            additionalProperties: true,
                            removeAdditional: true,
                          }),
                    }
                  : {};
              return { ...properties, [key]: { ...value, ...extra } };
            },
            {}
          ),
        },
      },
    };
  }, {});
  commonApiProperties.method.enum = Object.keys(apiFunctions);
  Object.keys(defs).forEach((key) => {
    if (allSchemas.defs[key].function) {
      allSchemas.defs[key].function.selectCases.api.properties.api.properties =
        commonApiProperties;
      allSchemas.defs[key].function.selectCases.api.properties.api.selectCases =
        apiFunctions;
    }
  });
}

export default allSchemas;

export const getSchema = (
  { path: _path, projectType, value },
  _parentSchema
) => {
  const parentSchema = _parentSchema || allSchemas;
  const {
    items,
    properties: parentProperties = {},
    patternProperties: parentPatternProperties = {},
  } = parentSchema;
  const arrPath = _path.split('/');
  const key = arrPath.shift();
  const path = arrPath.join('/');

  const originalSchema =
    (!Object.keys(parentProperties).length && parentSchema[key]) ||
    (parentSchema.properties &&
      parentSchema.properties[key]?.schema &&
      allSchemas[parentSchema.properties[key].schema]?.properties[key]) ||
    (!{ default: [] }._equals(parentProperties[key]) &&
      !{ default: {} }._equals(parentProperties[key]) &&
      parentProperties[key]) ||
    (Object.keys(parentPatternProperties).length &&
      parentPatternProperties[
        Object.keys(parentPatternProperties).find(
          (str) => key.match(new RegExp(str)) !== null
        )
      ]) ||
    (defs[projectType] &&
      (defs[projectType][key] || defs[projectType].events?.properties[key])) ||
    defs.common[key] ||
    defs.common.events.properties[key] ||
    items ||
    {};
  const { oneOf = [], pattern, type } = originalSchema;
  const {
    enum: oneOfEnum,
    events: oneOfEvents,
    pattern: patterns = pattern ? [pattern] : [],
    properties: oneOfProperties = {},
    required: oneOfRequired = [],
    type: types = [],
  } = oneOf.reduce(
    (result, entry) => {
      const {
        enum: e,
        pattern: pa,
        properties: pr = {},
        required: r = [],
        type: t,
      } = result;
      let {
        enum: e2,
        pattern: pa2,
        properties: pr2 = {},
        required: r2 = [],
        type: t2,
      } = entry;
      e2 = Array.isArray(e2) || !e2 ? e2 : [e2];
      pa2 = Array.isArray(pa2) || !pa2 ? pa2 : [pa2];
      t2 = Array.isArray(t2) || !t2 ? t2 : [t2];
      return {
        enum: (e && e2 && [...e, ...e2]) || e || e2,
        pattern: (pa && pa2 && [...pa, ...pa2]) || pa || pa2,
        properties: { ...pr, ...pr2 },
        required: [...r, ...r2],
        type: (t && t2 && [...t, ...t2]) || t || t2,
      };
    },
    { type }
  ) || {};
  let {
    description,
    enum: _enum = oneOfEnum,
    events = oneOfEvents,
    properties = oneOfProperties,
    required = oneOfRequired,
    select = {},
    selectCases = {},
  } = originalSchema;
  const { $data = '' } = select;
  const selectCaseKey = value && (value[key] || value)[$data.split('/')[1]];
  const selectCase = (selectCaseKey && selectCases[selectCaseKey]) || {};
  const {
    description: selectCaseDescription,
    icon,
    properties: selectCaseProps = {},
    events: selectCaseEvents = {},
    required: selectCaseRequiredProps = [],
  } = selectCase;
  description = selectCaseDescription || description;
  properties = { ...properties, ...selectCaseProps };
  events = { ...events, ...selectCaseEvents };
  required = [...required, ...selectCaseRequiredProps];
  delete properties.select;
  delete properties.selectCases;

  const schema = {
    ...originalSchema,
    default: originalSchema.default || (type === 'object' ? {} : undefined),
    accept: { patterns, types },
    description,
    enum: _enum,
    events,
    icon,
    properties,
    required,
    type,
  };

  // console.log('schema >>>', _path, schema);
  if (!arrPath.length) {
    return schema;
  }

  return getSchema({ path, projectType, value }, originalSchema);
};

export const getDataPath = (error) => {
  const { dataPath, schemaPath } = error;
  return (
    dataPath ||
    `/${schemaPath
      .split('/')
      .reduce((result, item) => {
        if (!['#', 'anyOf', 'oneOf', 'properties', 'select'].includes(item)) {
          result = [...result, item];
        }
        return result;
      }, [])
      .join('/')}`
  );
};
