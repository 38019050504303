import React, { useEffect, useState } from 'react';
import useFunctions from '../hooks/useFunctions';
import useWrapper from '../hooks/useWrapper';
import { Content } from './components/Component';
import Wrapper from './wrapper';

export const View = ({
  content,
  description,
  events: _events = {},
  history,
  icon,
  id,
  private: _private,
  title,
  wrapper,
}) => {
  const [functionsCalled, setFunctionsCalled] = useState([]);
  const [events, setEvents] = useState({});
  const { clearMessages, run } = useFunctions();
  const wrapperData = useWrapper(wrapper);

  useEffect(() => {
    clearMessages();
    setFunctionsCalled([]);
  }, [id]);

  useEffect(() => {
    let mounted = true;
    if (
      _events._equals(events) &&
      (_events._equals({}) || functionsCalled.length)
    ) {
      return;
    }
    clearMessages();
    setEvents(_events);
    const {
      onbackpressed = [],
      onexit = [],
      onloaded = [],
      onpreload = [],
      onreturned = [],
    } = events;
    let functions = [
      ...onbackpressed,
      ...onexit,
      ...onloaded,
      ...onpreload,
      ...onreturned,
    ];
    functions = functions.filter(
      (f) => !functionsCalled.includes(JSON.stringify(f))
    );
    if (!mounted || !functions.length) {
      return;
    }
    run(functions);
    setFunctionsCalled((functionsCalled) => [
      ...functionsCalled,
      ...functions.map((f) => JSON.stringify(f)),
    ]);
    return () => {
      mounted = false;
    };
  }, [_events, functionsCalled]);

  return (
    <Wrapper {...wrapperData} view={{ id, title }} className="view">
      <Content content={content} />
    </Wrapper>
  );
};

export default View;
