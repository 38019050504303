import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Container, Table } from 'react-bootstrap';
import { PAGES } from '../../_config';
import { PlaceholderText, Sidebar } from '../../components';

const Placeholder = () => {
  return (
    <Table striped className="mb-0">
      <thead>
        <tr>
          <th>
            <PlaceholderText style={{ width: '60px', opacity: 0.2 }} />
          </th>
          <th>
            <PlaceholderText style={{ width: '100px', opacity: 0.2 }} />
          </th>
          <th>
            <PlaceholderText style={{ width: '70px', opacity: 0.2 }} />
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="align-middle">
            <PlaceholderText />
          </td>
          <td className="align-middle">
            <PlaceholderText />
          </td>
          <td className="align-middle">
            <PlaceholderText />
          </td>
          <td>
            <PlaceholderText color="primary" />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const Empty = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Table striped className="mb-0">
        <thead>
          <tr>
            <th>{t('common.date')}</th>
            <th>{t('common.description')}</th>
            <th>{t('customer.amount')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>{t('customer.no_receips_yet')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="mt-4 text-center">
        <Link to={PAGES.UPGRADE_PLANS}>
          <Button color="primary">{t('header.upgrade_account')}</Button>
        </Link>
      </div>
    </React.Fragment>
  );
};

const Purchases = () => {
  const { t } = useTranslation();
  const purchases = [];

  if (!purchases.length) {
    return <Empty />;
  }

  return (
    <Table striped className="mb-0">
      <thead>
        <tr>
          <th>{t('common.date')}</th>
          <th>{t('common.description')}</th>
          <th>{t('customer.amount')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {purchases.length &&
          purchases.map((purchase) => (
            <tr key={purchase.id}>
              <td className="align-middle">
                {new Date(purchase.created_at).toLocaleDateString()}
              </td>
              <td className="align-middle">
                Doyo {t(`plans.plan${purchase.items[0].plan_id}.name`)}
              </td>
              <td className="align-middle">
                {(purchase.total / 100).toLocaleString('es-ES', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </td>
              <td>
                {purchase.invoice && (
                  <Button color="link">
                    <small>{t('customer.invoice')}</small>
                  </Button>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export const ReceiptsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  history.replace(PAGES.SUBSCRIPTION);
  let data;
  /*account.id &&
        !purchases.loading &&
        !purchases.done &&
        dispatch(purchasesActions.getAll(account));*/

  return (
    <main className="account">
      <Sidebar />
      <div className="container-wrapper">
        <Container>
          <h4 className="mt-2 mb-3">{t('header.receipts')}</h4>
          {(!data && <Placeholder />) || <Purchases />}
        </Container>
      </div>
    </main>
  );
};

export default ReceiptsPage;
