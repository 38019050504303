export * from './constants';

export const PAGES = {
  ACCOUNT: '/account',
  APP: '/app',
  BILLING_DATA: '/billing/data',
  BILLING_HISTORY: '/billing/history',
  BILLING_PAYMENT: '/billing/payment',
  BUY_DOYOCOINS: '/buy/doyocoins',
  CODOOZER: '/codoozer',
  CONFIGURATION: '/config',
  DASHBOARD: '/dashboard',
  EXIT: '/exit',
  FLOWS: '/flows',
  FORGOT: '/forgot',
  HOME: '/',
  KUSTODIO: '/kustodio',
  LEGAL_POLICIES: '/legal/policies',
  LEGAL_TERMS: '/legal/terms',
  MY_PROJECTS: '/app/open',
  NEW_PROJECT: '/app/new',
  NOT_FOUND: '/404',
  PASSWORD_RESET: '/password/reset',
  PREVIEW: '/v',
  PRICING: '/pricing',
  PUBLISH: '/publish',
  SIGN_IN_WITH_TOKEN: '/t',
  SETTINGS: '/settings',
  INTEGRATIONS: '/settings/integrations',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  STORIES: '/stories',
  STYLE: '/style',
  SUBSCRIPTION: '/subscription',
  UNSUBSCRIBE: '/notifications/unsubscribe',
  UPGRADE_PLANS: '/upgrade/plans',
  VERIFY: '/verify',
};

export const DOYO_PLANS = [
  {
    id: 'free',
    name: 'Free',
    price: 0,
    quantity: 0,
  },
  {
    id: 'basic',
    name: 'Basic',
    price: { month: 2400, year: 2400 * 10 },
    quantity: 10,
  },
  {
    id: 'startup',
    name: 'Start Up',
    price: { month: 6000, year: 6000 * 10 },
    quantity: 30,
  },
  {
    id: 'reseller',
    name: 'Reseller',
    price: { month: 30000, year: 30000 * 10 },
    quantity: 250,
  },
];
