import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiBackspace,
  mdiCheck,
  mdiClipboardArrowDownOutline,
  mdiClose,
  mdiContentCopy,
  mdiContentPaste,
  mdiFlag,
  mdiFlagOutline,
  mdiLink,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js';
import { Button, Tooltip, Typography } from 'antd';
import { Button as BootstrapButton } from 'react-bootstrap';
import { Sortable } from 'sortablejs';
import { JsonEditor } from '.';
import { getSchema } from '../schemas';
import { projectTypes } from '../schemas/project';
import { components as mobileComponents } from '../schemas/defs/common';
import { components as webComponents } from '../schemas/defs/web';
import { getBorderColor, highlightComponent, isObject } from '../_helpers';
import { Autocomplete, ResizablePanel } from '../components';
import { EDIT_MODE } from '../context/reducers';
import { useComponents, useEvents, useClipboard } from './../hooks';

const { Paragraph } = Typography;

const handleComponentOver = (event) => highlightComponent(event, 'over');
const handleComponentLeave = (event) => highlightComponent(event, 'leave');
const handleComponentCheck = (event, { value, force }) => {
  const { id } = value;
  if (force) {
    document
      .querySelectorAll('[data-component-id].active')
      .forEach((el) => el.classList.remove('active'));
  }
  if (!id) {
    return;
  }
  highlightComponent(event, 'check');
};

const RemoveButton = ({ deleteItem }) => {
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.stopPropagation();
    document.querySelector('.ant-drawer-close')?.click();
    deleteItem();
  };
  return (
    <BootstrapButton
      name="remove"
      className="item-button remove-item-button"
      variant="link"
      size="sm"
      title={t('common.delete')}
      onMouseEnter={(event) => {
        const {
          currentTarget: {
            parentNode: { parentNode },
          },
        } = event;
        parentNode.classList.add('line-through');
      }}
      onMouseLeave={(event) => {
        const {
          currentTarget: {
            parentNode: { parentNode },
          },
        } = event;
        parentNode.classList.remove('line-through');
      }}
      onClick={handleClick}
    >
      <Icon path={mdiBackspace} />
    </BootstrapButton>
  );
};

const CloneButton = ({ cloneItem }) => {
  const { t } = useTranslation();
  return (
    <Paragraph
      className="item-button clone-item-button"
      copyable={{
        icon: [
          <Icon
            key="timelineCopyIcon"
            path={mdiClipboardArrowDownOutline}
            size={0.8}
            onClick={(event) => {
              event.stopPropagation();
              cloneItem();
            }}
          />,
          <Icon key="timelineCopiedIcon" path={mdiCheck} size={0.8} />,
        ],
        tooltips: [t('common.clone'), t('common.clone')],
      }}
    />
  );
};

const CopyButton = ({ value }) => {
  const { t } = useTranslation();
  const { store } = useClipboard();
  return (
    <Paragraph
      className="item-button clone-item-button"
      copyable={{
        icon: [
          <Icon
            key="timelineCopyIcon"
            path={mdiContentCopy}
            size={0.8}
            onClick={() => store(value._clone())}
          />,
          <Icon key="timelineCopiedIcon" path={mdiCheck} size={0.8} />,
        ],
        tooltips: [t('common.copy'), t('common.copied')],
      }}
    />
  );
};

const PasteButton = ({ createItem }) => {
  const { t } = useTranslation();
  const { clipboard, reset } = useClipboard();
  let value = clipboard[0];
  value = value._clone();
  value._resetIds();
  return (
    <div
      name="paste"
      className="clone-item-button"
      onClick={(event) => {
        event.stopPropagation();
        reset();
        createItem({ value });
      }}
      title={t('common.paste')}
    >
      <Icon path={mdiContentPaste} size={0.8} />
    </div>
  );
};

const NodeItem = React.memo(
  ({
    _key: key,
    getValue,
    getParent,
    changeValue: changeParent,
    deleteItem: parentDeleteItem,
    getProjectRefs,
    projectType,
    mode,
    color: parentColor,
  }) => {
    const { currentComponent, setCurrentComponent } = useComponents();
    let value = getValue(key);
    const element = useRef();
    const parent = getParent();
    const {
      path: parentPath = '',
      value: parentValue = {},
      schema: parentSchema,
    } = parent;
    const {
      default: { id: defaultParentId } = {},
      required: parentRequired = [],
    } = parentSchema || {};
    const path = `${parentPath}/${key}`;
    const isParentArray = Array.isArray(parentValue);
    const schema = getSchema({ path: key, projectType, value }, parentSchema);
    const {
      description = '',
      events = [],
      icon,
      properties = {},
      visible = true,
    } = schema;

    const { properties: eventProperties = {} } = events;
    const eventKeys = Object.keys(eventProperties);

    const isArray = Array.isArray(value);
    let { __expanded = false } = !isArray ? value : {};
    let [expanded, setExpanded] = useState(__expanded);
    const expandedIcon = expanded ? mdiMenuDown : mdiMenuRight;
    expanded = expanded || key === 'content';
    const color = isArray ? parentColor : getBorderColor(value?.id);

    useEffect(() => {
      expanded !== __expanded && setExpanded(__expanded);
    }, [__expanded]);

    const toggleExpanded = ({ expanded: _expanded = !expanded } = {}) => {
      setExpanded(_expanded);
      setTimeout(() => {
        let _value = value._clone();
        if (value.content) {
          _value = { ...value, __expanded: _expanded };
          if (!_expanded) {
            delete _value.__expanded;
          }
        }
        changeItem({ key, value: _value }, { expanded: _expanded });
      }, 0);
    };

    const changeItem = useCallback(
      ({ key: newKey = key, value: newValue }, options = {}) => {
        const { changes, expanded } = options;
        if (expanded && !Array.isArray(newValue)) {
          newValue = { ...newValue, __expanded: true };
        }
        changeParent(
          { key, newKey, value: newValue },
          {
            ...options,
            changes: changes || {
              key,
              value,
              newValue,
              type: defaultParentId,
            },
          }
        );
      },
      [changeParent, defaultParentId, key, value]
    );

    const requiredGroupKeys = Object.keys(properties).filter(
      (propKey) => properties[propKey].requiredGroup
    );
    const requiredGroupValues = requiredGroupKeys.map(
      (propKey) => properties[propKey]
    );
    if (
      requiredGroupKeys.length &&
      !Object.keys(value).some((valueKey) =>
        requiredGroupKeys.includes(valueKey)
      )
    ) {
      const defaultKey = requiredGroupKeys[0];
      const { default: defaultValue = '' } = requiredGroupValues[0];
      value = {
        ...value,
        [defaultKey]: defaultValue,
      };
    }

    const cloneItem = useCallback(() => {
      const index = parentValue.indexOf(value);
      const newValue = value._clone();
      newValue._resetIds();
      const _value = [
        ...parentValue.slice(0, index + 1),
        newValue,
        ...parentValue.slice(index + 1),
      ];
      changeParent({ value: _value });
    }, [changeParent, parentValue, value]);

    const deleteItem = useCallback(() => {
      parentDeleteItem({ key });
    }, [key, parentDeleteItem]);

    const { id: componentId } = value || {};
    const active = currentComponent?.path === path;

    const handleSelectComponent = (event) => {
      handleComponentCheck(event, { value, force: true });
      setCurrentComponent({
        _key: key,
        changeValue: changeParent,
        events: eventKeys,
        getProjectRefs,
        parentSchema: schema,
        path,
        projectType,
        deleteItem,
        mode,
        onChange: changeItem,
        parent,
        value,
      });
    };

    useEffect(() => {
      active &&
        !value._equals(currentComponent?.value) &&
        setCurrentComponent({
          _key: key,
          changeValue: changeParent,
          events: eventKeys,
          getProjectRefs,
          parentSchema: schema,
          path,
          projectType,
          deleteItem,
          mode,
          onChange: changeItem,
          parent,
          value,
        });
    }, [active, value]);

    if (!visible) {
      return null;
    }

    const comments = isParentArray && value?.comments;

    return (
      <div
        className={`node-item${active ? ' active' : ''}`}
        data-component-id={componentId}
        data-key={key}
        ref={element}
        onClick={(event) => {
          if (value.type) {
            event.stopPropagation();
            handleSelectComponent(event);
          }
        }}
        style={{ color: parentColor }}
      >
        <div
          className="node-external-expand-button d-none"
          onClick={() => {
            toggleExpanded({ expanded: true });
          }}
        />
        {value?.content && (
          <div
            className="node-expand-button"
            onClick={(event) => {
              event.stopPropagation();
              toggleExpanded();
            }}
            style={{ color }}
          >
            <Icon path={expandedIcon} size={1} />
          </div>
        )}
        <div
          className="node-value"
          data-type={value === null ? 'null' : isArray ? 'array' : typeof value}
          data-expanded={expanded}
        >
          {isParentArray && (
            <div
              className="item-actions"
              onMouseEnter={handleComponentOver}
              onMouseLeave={handleComponentLeave}
            >
              {typeof value === 'object' && (
                <CloneButton cloneItem={cloneItem} />
              )}
              {typeof value === 'object' && <CopyButton value={value} />}
              {!parentRequired.includes(key) && (
                <RemoveButton deleteItem={deleteItem} />
              )}
            </div>
          )}
          <small
            className="node-type d-flex drag-handler"
            onMouseEnter={handleComponentOver}
            onMouseLeave={handleComponentLeave}
          >
            {!isArray && (
              <>
                <Tooltip title={comments}>
                  <span>
                    {' '}
                    {Object.keys(properties)
                      .reduce((res, prop) => {
                        const labelPattern = new RegExp(`\\{${prop}}`);
                        const label =
                          typeof value?.[prop] !== 'undefined' ? prop : '';
                        const pattern = new RegExp(`\\{{${prop}}}`);
                        const _value =
                          typeof value?.[prop] !== 'undefined'
                            ? (isObject(value[prop]) &&
                                (value[prop].default ||
                                  Object.values(value[prop])[0])) ||
                              value[prop]
                            : '';
                        return res
                          .replace(pattern, `${_value}`)
                          .replace(labelPattern, `${label}`);
                      }, description)
                      .trim() || ` Object {${Object.keys(value || {}).length}}`}
                  </span>
                </Tooltip>
              </>
            )}
          </small>
          {(isArray || value?.content) && (
            <NodeEditor
              _key={key}
              mode={mode}
              value={value}
              parent={{ ...parent, path, schema }}
              onChange={changeItem}
              getProjectRefs={getProjectRefs}
              projectType={projectType}
              color={color}
            />
          )}
        </div>
      </div>
    );
  },
  (a, b) => a._key === b._key && a.value?._equals(b.value)
);
NodeItem.displayName = 'NodeItem';

const NewComponent = ({
  parent,
  changeItem,
  createItem,
  projectType,
  value,
  color,
}) => {
  const { clipboard } = useClipboard();
  const schema =
    projectType === projectTypes.MOBILE_APP ? mobileComponents : webComponents;
  const { properties = {}, selectCases = {} } = schema;
  const { type: { enum: options = [] } = {} } = properties;

  const canPaste = !!clipboard?.[0]?.type;

  return (
    <div
      className="node-item node-new-item"
      style={{ color }}
      onClick={(e) => e.stopPropagation()}
    >
      <Autocomplete
        className="node-key"
        options={options}
        onBlur={(event) => {
          const { currentTarget } = event;
          const { innerText: key } = currentTarget;
          if (!key) {
            return;
          }
          const { default: def = {} } = selectCases[key] || {};
          let value = def._clone();
          value.id = `${value.id}${Date.now()}`;
          createItem({ key, value });
          event.currentTarget.innerText = '';
          setTimeout(
            () =>
              document
                .querySelector(`.node-item[data-component-id="${value.id}"]`)
                ?.click(),
            10
          );
        }}
      />
      {canPaste && <PasteButton createItem={createItem} />}
    </div>
  );
};

export const LinkItem = ({ to }) => {
  return (
    <Link to={to}>
      <Icon path={mdiLink} />
    </Link>
  );
};

export const NodeEditor = React.memo(
  ({
    _key: key,
    value,
    mode,
    getProjectRefs,
    projectType,
    onChange = () => {},
    parent = {},
    color = '#cccccc',
  }) => {
    const { path = '', schema } = parent;
    const sortable = useRef();
    const wrapper = useRef();
    const getValue = useCallback((key) => value[key], [value]);

    const createItem = useCallback(
      ({ key: newKey, value: newValue }, type = 'create') => {
        let _value;
        if (Array.isArray(value)) {
          _value = [...value, newValue];
        } else {
          _value = Object.assign({}, value);
          _value[newKey] = newValue;
          if (typeof newValue === 'object') {
          }
        }
        onChange({ value: _value }, type);
      },
      [onChange, path, value]
    );

    const changeValue = useCallback(
      ({ key, newKey, value: newValue }, options = {}) => {
        const { type = 'change', sortable = {} } = options;
        const { item, path: itemPath } = sortable;
        newKey = typeof newKey !== 'undefined' ? newKey : key;
        let _value = value._clone();
        if (newKey) {
          _value[newKey] =
            typeof newValue !== 'undefined' ? newValue : _value[key];
        } else if (newValue) {
          _value = newValue;
        }
        if (key !== newKey) {
          if (type === 'change') {
            delete _value[key];
          }
        }
        if (itemPath) {
          const arr = itemPath.split('/');
          arr.pop();
          const index = arr.pop();
          const auxPath = arr.join('/');
          if (auxPath === path) {
            _value[index].content = _value[index].content.filter(
              ({ id }) => id !== item.id
            );
          }
        }
        onChange({ value: _value }, options);
      },
      [value, onChange, path]
    );

    const deleteItem = useCallback(
      ({ key }, type = 'delete') => {
        let _value = value._clone();
        if (Array.isArray(_value)) {
          key = parseInt(key, 10);
          _value = _value.filter((item, i) => i !== key);
        } else {
          delete _value[key];
        }
        onChange({ value: _value }, type);
      },
      [onChange, value]
    );

    const getParent = useCallback(() => {
      return { ...parent, value, schema };
    }, [parent, value, schema]);

    useEffect(() => {
      if (!Array.isArray(value)) {
        sortable.current && sortable.current.destroy();
        return;
      }
      sortable.current && sortable.current.destroy();
      sortable.current = Sortable.create(wrapper.current, {
        multiDrag: false,
        delay: 100,
        handle: '.drag-handler',
        selectedClass: 'selected',
        fallbackTolerance: 3,
        group: { name: 'nested', pull: true, put: ['components', 'nested'] },
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        setData: (dataTransfer) => {
          dataTransfer.setData('value', JSON.stringify({ path, value }));
        },
        onStart: () => {
          document.body.classList.add('content-sorting');
        },
        onEnd: async (event) => {
          document.body.classList.remove('content-sorting');
        },
        onAdd: async (event) => {
          let {
            from,
            item: clonedElement,
            newIndex,
            oldIndex,
            originalEvent,
            to,
          } = event;
          const isFromComponent = from.classList.contains('components-wrapper');
          const { dataTransfer } = originalEvent;
          const { items } = dataTransfer;
          if (!items[0]) {
            return;
          }

          let { path: fromPath, value: fromValue } = await new Promise(
            (resolve) =>
              items[0]?.getAsString((str) => resolve(JSON.parse(str)))
          );
          const item = isFromComponent ? fromValue : fromValue[oldIndex];
          const isSibling =
            from.parentElement.parentElement.parentElement.parentElement
              .parentElement ===
            to.parentElement.parentElement.parentElement.parentElement
              .parentElement;

          let aux = from;
          while (aux && aux !== to && !isSibling) {
            aux = aux.parentElement;
          }
          const goDeeper = !aux;
          if (goDeeper && !isFromComponent) {
            return;
          }
          const cleanValue = (changes) => {
            if (!changes) {
              return changes;
            }
            if (changes.content) {
              return { ...changes, content: cleanValue(changes.content) };
            }
            if (Array.isArray(changes)) {
              return changes
                .filter(({ id }) => id !== item.id)
                .map((change) => cleanValue(change));
            }
            return changes;
          };
          const _value = cleanValue(value);

          setTimeout(() => {
            clonedElement?.remove();
            _value.splice(newIndex, 0, item);
            onChange({ value: _value }, { sortable: { item, path: fromPath } });
          }, 0);
        },
        onRemove: (event) => {
          const { from, newIndex, oldIndex, to } = event;
          let aux = to;
          let toId = '';
          while (!toId && aux) {
            const { componentId } = aux.dataset;
            toId = componentId;
            aux = aux.parentElement;
          }
          aux = from;
          while (aux && aux !== to) {
            aux = aux.parentElement;
          }
          const goDeeper = !aux;
          const isSibling =
            from.parentElement.parentElement.parentElement.parentElement
              .parentElement ===
            to.parentElement.parentElement.parentElement.parentElement
              .parentElement;
          if (!goDeeper || isSibling) {
            return;
          }

          const cleanValue = (changes) => {
            if (!changes) {
              return changes;
            }
            if (changes.content) {
              if (changes.id === toId) {
                const content = changes.content._clone();
                content.splice(newIndex, 0, value[oldIndex]);
                changes = {
                  ...changes,
                  content,
                };
                return changes;
              }
              return {
                ...changes,
                content: cleanValue(changes.content),
              };
            }
            if (Array.isArray(changes)) {
              return changes.map((change) => cleanValue(change));
            }
            return changes;
          };
          let _value = cleanValue(value);

          setTimeout(() => {
            _value = _value.filter((item, index) => index !== oldIndex);
            onChange({ value: _value });
          }, 100);
        },
        onMove: function (event) {
          const { dragged, to } = event;
          const { children } = to;
          dragged.style.color = children[0]?.style?.color;
        },
        onUpdate: (event) => {
          const { oldIndex, newIndex } = event;
          const _value = value._clone();
          _value._move(oldIndex, newIndex);
          onChange({ value: _value });
        },
      });

      return () => {
        sortable.current && sortable.current.destroy();
      };
    }, []);

    let valueKeys = value ? Object.keys(value) : [];

    if (value.content) {
      return (
        <NodeItem
          _key="content"
          getValue={getValue}
          getParent={getParent}
          getProjectRefs={getProjectRefs}
          projectType={projectType}
          changeValue={changeValue}
          deleteItem={deleteItem}
          key={`NodeItem-content-${Date.now()}`}
          mode={mode}
          color={color}
        />
      );
    }

    return (
      <div className="node-wrapper" ref={wrapper} role="tree">
        {Array.isArray(value) && (
          <>
            {valueKeys.map((itemKey, i) => (
              <NodeItem
                _key={itemKey}
                getValue={getValue}
                getParent={getParent}
                getProjectRefs={getProjectRefs}
                projectType={projectType}
                changeValue={changeValue}
                deleteItem={deleteItem}
                key={`NodeItem-${itemKey}-${i}-${Date.now()}`}
                mode={mode}
                color={color}
              />
            ))}
            <NewComponent
              parent={parent}
              createItem={createItem}
              projectType={projectType}
              value={value}
              color={color}
            />
          </>
        )}
      </div>
    );
  },
  (a, b) =>
    a.value?._equals(b.value) &&
    a.mode === b.mode &&
    a.onChange === b.onChange &&
    a.getProjectRefs === b.getProjectRefs
);
NodeEditor.displayName = 'NodeEditor';

export const EditorWrapper = React.memo(
  ({
    className = '',
    data = {},
    mode = EDIT_MODE.SORT,
    parent = {},
    onChange = () => {},
    getProjectRefs,
    projectType,
  }) => {
    const element = useRef();
    const { value: mainValue = {} } = data;
    const { path = '' } = parent;
    let schemaPath = path.replace(/^\/[^/]+\//, '');
    schemaPath = schemaPath.split('/').slice(0, 2).join('/');
    schemaPath =
      mainValue && mainValue.id
        ? schemaPath.replace(`/${mainValue.id.split('/').shift()}`, '')
        : schemaPath;
    const schema = getSchema({
      path: schemaPath,
      projectType,
      value: mainValue,
    });
    parent = { ...parent, schema };

    return (
      <div
        className={`node-wrapper ${className}`.trim()}
        data-mode={mode}
        ref={element}
      >
        <NodeEditor
          value={mainValue}
          mode={mode}
          parent={parent}
          onChange={onChange}
          getProjectRefs={getProjectRefs}
          projectType={projectType}
        />
      </div>
    );
  },
  (a, b) => {
    if (typeof a.value === 'undefined') {
      return false;
    }
    return a.value?._equals(b.value) && a.mode === b.mode;
  }
);
EditorWrapper.displayName = 'EditorWrapper';

export const ComponentPanelContent = ({
  _key: key,
  changeValue,
  close: handleClose = () => {},
  getProjectRefs,
  parentSchema,
  projectType,
  deleteItem,
  mode,
  onChange,
  parent,
  value,
}) => {
  const { t } = useTranslation();
  const { addEventObject, currentEvent, setCurrentEvent, setEventHistory } =
    useEvents();
  const { path: parentPath = '' } = parent;
  const path = `${parentPath}/${key}`;
  const currentEventPath = `${currentEvent?.parent?.path}/${currentEvent?.data?.key}`;
  const schema = getSchema({ path, projectType, value: value }, parentSchema);
  const { type } = value;
  const { selectCases = {} } = schema;
  const componentSchema = selectCases[type];
  const { events: componentEvents = {} } = componentSchema;
  const eventKeys = Object.keys(componentEvents.properties || {});
  const valueWithoutEvents = value._clone();
  eventKeys.forEach((key) => {
    delete valueWithoutEvents?.[key];
  });

  return (
    <div className="component-parameters">
      <div className="header border-0">
        <small>{t(`components.${type}`)}</small>
        <div className="flex-grow-1" />
        <div className="close" onClick={handleClose}>
          <Icon path={mdiClose} size={1} />
        </div>
      </div>
      <div className="p-3">
        <JsonEditor
          _key={key}
          mode="edit"
          value={value}
          parent={{ ...parent, path: parentPath, schema }}
          onChange={onChange}
          getProjectRefs={getProjectRefs}
          projectType={projectType}
        />
      </div>
      {!!eventKeys.length && (
        <>
          <div className="header" style={{ top: '40px' }}>
            <small>{t(`components.${type}_events`)}</small>
          </div>
          <div className="mb-4 p-3">
            {eventKeys.map((eventKey) => {
              const hasActions = !!value?.[eventKey]?.length;
              let [, _path = ''] =
                path.match(/\/[^/]*\/[^/]*\/[^/]*\/(.*)/) ||
                path.match(/\/[^/]*\/[^/]*\/(.*)/) ||
                [];
              const changeEvent = (
                { key: newKey = key, value: newValue },
                options
              ) => {
                let _value = value._clone();
                if (options === 'delete') {
                  _value = { ..._value, [eventKey]: newValue };
                } else {
                  _value = {
                    ..._value,
                    [eventKey]: Object.values(newValue),
                  };
                }
                onChange({ key, value: _value }, options);
              };
              addEventObject({
                data: { key: eventKey, value: value[eventKey] },
                parent: { path: _path, schema, value },
                getProjectRefs,
                projectType,
                onChange: changeEvent,
              });
              return (
                <Button
                  key={eventKey}
                  className="m-1"
                  data-event={eventKey}
                  type={
                    `${_path}/${eventKey}` === currentEventPath
                      ? 'primary'
                      : (hasActions && 'default') || 'dashed'
                  }
                  icon={
                    <Icon
                      className="me-2"
                      path={hasActions ? mdiFlag : mdiFlagOutline}
                      size={0.8}
                    />
                  }
                  onClick={() => {
                    setCurrentEvent({
                      data: { key: eventKey, value: value[eventKey] },
                      parent: { path: _path, schema, value },
                      getProjectRefs,
                      projectType,
                    });
                    setEventHistory([]);
                  }}
                >
                  {eventKey}
                </Button>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const ComponentPanel = ({ data, previewWrapper, onResize }) => {
  const { currentComponent, setCurrentComponent } = useComponents();
  const { currentEvent, setCurrentEvent } = useEvents();
  const location = useLocation();

  const handleComponentPanelClose = () => {
    document
      .querySelectorAll(`[data-component-id].active`)
      ?.forEach((el) => el.classList.remove('active'));
    setCurrentComponent(null);
  };

  useEffect(() => {
    handleComponentPanelClose();
  }, [location]);

  useEffect(() => {
    previewWrapper?.current && setTimeout(onResize, 300);
    const { events = [] } = currentComponent || {};
    const { data = {}, parent = {} } = currentEvent || {};
    const { path } = parent;
    if (!path?.includes('content')) {
      return;
    }
    const { key } = data;
    const eventKey = `${path}/${key}`
      .split('/')
      .reverse()
      .find((key) => key.match(/^on/) && events.includes(key));
    const buttonEvent = document.querySelector(`[data-event="${eventKey}"]`);
    buttonEvent ? buttonEvent.click() : setCurrentEvent(null);
  }, [currentComponent?.path]);

  return (
    <ResizablePanel
      name="component"
      className={`h-100 ${!!currentComponent ? 'opened' : ''}`.trim()}
      axis="x"
      handle="w"
      minConstraints={[305, 0]}
      maxConstraints={[800, 0]}
      onResize={onResize}
    >
      <>
        {!!currentComponent && (
          <ComponentPanelContent
            {...currentComponent}
            close={handleComponentPanelClose}
          />
        )}
      </>
    </ResizablePanel>
  );
};

export default EditorWrapper;
