// @flow
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { HOST, PAGES } from '../../../_config';
import { DoyoFilesHeader } from '.';
import { Footer } from '../../../components';
import {
  balloon,
  edit,
  floppy,
  lock,
  security,
  ship,
  wallet,
} from '../../../assets/svg/doyofiles';
import { waves } from '../../../assets/svg/figures';

export const DoyoFilesPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.scrollingElement.scrollTop = 0;
  }, []);

  return (
    <main className="home kustodio">
      <Helmet>
        <title>{t('landings.kustodio.seo.title')}</title>
        <meta
          name="description"
          content={t('landings.kustodio.seo.description')}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${HOST.KUSTODIO}/favicon/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${HOST.KUSTODIO}/favicon/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${HOST.KUSTODIO}/favicon/favicon-16x16.png`}
        />
        <link
          rel="mask-icon"
          href={`${HOST.KUSTODIO}/favicon/safari-pinned-tab.svg`}
          color="#75b49c"
        />
        <link
          rel="shortcut icon"
          href={`${HOST.KUSTODIO}/favicon/favicon.ico`}
        />
        <script type="application/ld+json">
          {`{
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Kustodio",
                        "url": "https://kustod.io",
                        "alternateName": "Kustodio by Doyo",
                        "logo": "https://kustod.io/favicon/android-chrome-512x512.png",
                        "contactPoint": [
                            {
                                "@type": "ContactPoint",
                                "email": "support@doyo.email",
                                "contactType": "customer support"
                            }
                        ],
                        "founders": [
                            { "@type": "Person", "name": "Ignacio Roda Segrelles" },
                            { "@type": "Person", "name": "Victor Díaz Marco" },
                            { "@type": "Person", "name": "Carlos Hermoso Sierra" }
                        ]
                    }`}
        </script>
      </Helmet>
      <DoyoFilesHeader />
      <div className="position-relative">
        <Container className="position-relative">
          <Row className="align-items-lg-center">
            <Col lg={{ span: 5 }} className="mb-lg-0">
              <h1 className="display-4  text-white font-size-48--md-down font-weight-bold">
                {t('landings.kustodio.banner.title')}
              </h1>
              <p className="text-black-50">
                {t('landings.kustodio.banner.subtitle')}
              </p>
              <Link to={PAGES.SIGN_UP}>
                <Button color="white" size="lg" className="card-link">
                  {t('landings.kustodio.banner.button')}
                </Button>
              </Link>
            </Col>
            <Col
              xs={{ span: 7 }}
              className="banner-fluid text-end ml-auto px-2 px-lg-5"
            >
              <img src={security} alt="SVG Illustration" />
            </Col>
          </Row>
        </Container>
        <img className="waves" src={waves} alt="waves" />
      </div>
      <Container className="marketing">
        <Row>
          <Col lg={{ span: 4 }}>
            <img src={floppy} alt="Icon 1" width="140" height="140" />
            <h2>{t('landings.kustodio.block1.title1')}</h2>
            <p>{t('landings.kustodio.block1.text1')}</p>
          </Col>
          <Col lg={{ span: 4 }}>
            <img src={edit} alt="Icon 3" width="140" height="140" />
            <h2>{t('landings.kustodio.block1.title2')}</h2>
            <p>{t('landings.kustodio.block1.text2')}</p>
          </Col>
          <Col lg={{ span: 4 }}>
            <img src={ship} alt="Icon 3" width="140" height="140" />
            <h2>{t('landings.kustodio.block1.title3')}</h2>
            <p>{t('landings.kustodio.block1.text3')}</p>
          </Col>
        </Row>

        <hr className="featurette-divider" />

        <Row className="featurette">
          <Col md={{ span: 7 }}>
            <h2 className="featurette-heading">
              {t('landings.kustodio.features.title1')}
            </h2>
            <p className="lead">{t('landings.kustodio.features.text1')}</p>
          </Col>
          <Col md={{ span: 5 }}>
            <img
              alt="feature 2"
              className="featurette-image img-fluid mx-auto h-auto"
              src={balloon}
            />
          </Col>
        </Row>
        <hr className="featurette-divider" />
        <Row className="featurette">
          <Col md={{ span: 7 }}>
            <h2 className="featurette-heading">
              {t('landings.kustodio.features.title2')}
            </h2>
            <p className="lead">{t('landings.kustodio.features.text2')}</p>
          </Col>
          <Col md={{ span: 5, order: 1 }}>
            <img
              alt="feature 2"
              className="featurette-image img-fluid mx-auto h-auto"
              src={lock}
            />
          </Col>
        </Row>
        <hr className="featurette-divider" />
        <Row className="featurette">
          <Col md={{ span: 7 }}>
            <h2 className="featurette-heading">
              {t('landings.kustodio.features.title3')}
            </h2>
            <p className="lead">{t('landings.kustodio.features.text3')}</p>
          </Col>
          <Col md={{ span: 5 }}>
            <img
              alt="feature 3"
              className="featurette-image img-fluid mx-auto h-auto"
              src={wallet}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </main>
  );
};

export default DoyoFilesPage;
