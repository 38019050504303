import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SECRETS } from '../_config';
import { logo } from '../assets/svg/logos';
import banner from '../assets/svg/not-found.svg';

Sentry.init({
    dsn: SECRETS.SENTRY_DSN,
    environment: document.location.hostname === 'doyo.tech' ? 'production' : 'development',
    integrations: [new Integrations.BrowserTracing()],
});

const FallbackComponent = ({ eventId, resetError }) => {
    const { t } = useTranslation();
    return (
        <main className="not-found">
            <header className="position-absolute-top-0 z-index-2">
                <nav className="d-flex justify-content-between align-items-center">
                    <div className="col-lg-5 col-xl-4 text-lg-center px-0">
                        <Link className="d-inline-block p-3 p-sm-5" to="/" aria-label="Mobincube">
                            <img src={logo} height="42px" alt="Logo" />
                        </Link>
                    </div>
                </nav>
            </header>
            <div
                className="jumbotron jumbotron-fluid d-flex flex-column justify-content-center align-items-center mb-0 p-0"
                style={{ backgroundImage: `url(${banner})` }}
            >
                <div className="mt--5 d-flex flex-column justify-content-center align-items-center position-relative text-center z-index-2">
                    <h1 className="text-secondary rounded" style={{ background: '#f0f1f3' }}>
                        {t('error_found.title')}
                    </h1>
                    <div className="mt-4 d-flex rounded-pill" style={{ background: '#f0f1f3' }}>
                        <Button variant="primary" onClick={() => resetError()}>
                            {t('error_found.back')}
                        </Button>
                        <Button
                            className="ms-3"
                            variant="outline-primary"
                            onClick={() => Sentry.showReportDialog({ eventId })}
                        >
                            {t('error_found.button')}
                        </Button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export const ErrorBoundary = (props) => {
    return (
        <Sentry.ErrorBoundary fallback={(props) => <FallbackComponent {...props} />}>
            {props.children}
        </Sentry.ErrorBoundary>
    );
};

export default Sentry.withProfiler(ErrorBoundary);
