import React from 'react';
import Component, { Content } from '../components/Component';

export const Header = ({ background_color: color, content }) => {
  return (
    <Component background={{ type: 'solid', color }} component="header">
      <Content content={content} />
    </Component>
  );
};

export default Header;
