import React, { useEffect } from 'react';
import { Layout, message } from 'antd';
import { RegisterModal, VerificationModal } from '../../components';
import { useProjects } from '../../hooks';
import { MyProjectsContent, NewProjectContent } from '..';
import { Sidebar } from '.';

export const DashboardPage = () => {
  const { unselectProject } = useProjects();
  useEffect(() => {
    unselectProject();
    message.destroy();
  }, []);
  return (
    <>
      <Layout className="dashboard">
        <Layout.Sider className="sidebar-dashboard" width={260}>
          <Sidebar />
        </Layout.Sider>
        <Layout>
          <Layout.Content id="WorkingArea" className="bg-light">
            <NewProjectContent dashboard={true} className="bg-white" />
            <MyProjectsContent />
          </Layout.Content>
        </Layout>
      </Layout>
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default DashboardPage;
