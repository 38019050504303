import { languages, UI } from '../../_helpers';
import {
  _if,
  backgroundTypes,
  buttons,
  color,
  colors as defColors,
  data_source,
  events as defEvents,
  file,
  font,
  fontSize,
  halign,
  halignTypes,
  height,
  id,
  label,
  marginTypes,
  multiLanguageText,
  orientation,
  position,
  scale,
  source,
  text,
  title,
  valign,
  valignTypes,
  width,
} from './common';

export const componentTypes = {
  GROUP: 'group',
  IMAGE: 'image',
  INPUTTEXT: 'inputtext',
  LIST: 'list',
  PDFVIEWER: 'pdfviewer',
  TEXT: 'text',
  VIDEO: 'video',
  WEB: 'web',
  YOUTUBE: 'youtube',
};

export const colors = {
  ...defColors,
  required: [...defColors.required, 'darken', 'lighten', 'transparent'],
  properties: {
    ...defColors.properties,
    darken: { ...color, default: '#66000000' },
    lighten: { ...color, default: '#66ffffff' },
    transparent: { ...color, default: '#00000000' },
  },
};

export const numberAndPercentage = [{ type: 'number' }, { pattern: '[0-9]+%' }];

export const commonBackgroundProperties = {
  type: {
    enum: [
      backgroundTypes.TRANSPARENT,
      backgroundTypes.SOLID,
      backgroundTypes.GRADIENT,
      backgroundTypes.IMAGE,
    ],
    ui: UI.DROPDOWN,
  },
};

export const background = {
  title: 'Background',
  description: '{{type}}',
  default: {},
  required: ['type'],
  properties: {
    ...commonBackgroundProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [backgroundTypes.TRANSPARENT]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonBackgroundProperties,
      },
    },
    [backgroundTypes.SOLID]: {
      description: '{{type}} {{color}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['color'],
      properties: {
        ...commonBackgroundProperties,
        color,
      },
    },
    [backgroundTypes.GRADIENT]: {
      description: '{{type}} {{direction}} {{from}} {{to}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['direction', 'from', 'to'],
      properties: {
        ...commonBackgroundProperties,
        direction: {
          default: 'to_bottom',
          enum: ['to_bottom', 'to_right', 'to_bottom_right', 'to_bottom_left'],
        },
        from: { ...color, default: '@color.primaryLight' },
        to: { ...color, default: '@color.primaryDark' },
      },
    },
    [backgroundTypes.IMAGE]: {
      description: '{{type}} {{image}}',
      additionalProperties: false,
      removeAdditional: true,
      required: ['image'],
      properties: {
        ...commonBackgroundProperties,
        image: { type: 'string' },
      },
    },
  },
};

export const margin = {
  title: 'Margin',
  description: `{${[marginTypes.TOP]}} {{${[marginTypes.TOP]}}} {${[
    marginTypes.BOTTOM,
  ]}} {{${[marginTypes.BOTTOM]}}} {${[marginTypes.START]}} {{${[
    marginTypes.START,
  ]}}} {${[marginTypes.END]}} {{${[marginTypes.END]}}}`,
  default: 0,
  oneOf: [
    ...numberAndPercentage,
    {
      type: 'object',
      default: { [marginTypes.TOP]: 0 },
      properties: {
        [marginTypes.BOTTOM]: {
          oneOf: numberAndPercentage,
        },
        [marginTypes.END]: {
          oneOf: numberAndPercentage,
        },
        [marginTypes.START]: {
          oneOf: numberAndPercentage,
        },
        [marginTypes.TOP]: {
          oneOf: numberAndPercentage,
        },
      },
    },
  ],
};

export const padding = {
  ...margin,
  title: 'Padding',
};

export const commonFlowFunctionProperties = {
  function: {
    enum: [
      'add',
      'back',
      'browser',
      'delete',
      'finish',
      'goto',
      'next',
      'request',
      'set',
      'track',
    ],
    ui: UI.DROPDOWN,
    reset: true,
  },
  comments: { default: '' },
  if: _if,
};

export const _function = {
  title: 'Function',
  description: '{{function}}',
  required: ['function'],
  default: { function: '' },
  properties: {
    ...commonFlowFunctionProperties,
  },
  select: { $data: '0/function' },
  selectCases: {
    add: {
      description: '{{to}}',
      required: ['what', 'to'],
      properties: {
        ...commonFlowFunctionProperties,
        what: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
        key: {
          oneOf: [
            { pattern: '^[a-zA-Z]+[_a-zA-Z0-9]*$' },
            { pattern: '^@.+$' },
            { pattern: '^[a-z(@][a-zA-Z()@._0-9]*' },
          ],
        },
        to: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    back: {
      properties: {
        ...commonFlowFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    browser: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonFlowFunctionProperties,
        url: { type: 'string' },
        target: {
          default: '_self',
          oneOf: [{ type: 'string' }, { enum: ['_self', '_blank'] }],
        },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    delete: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonFlowFunctionProperties,
        what: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    finish: {
      properties: {
        ...commonFlowFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    goto: {
      description: '{{step}}',
      required: ['step'],
      properties: {
        ...commonFlowFunctionProperties,
        step: { type: 'string', key: 'step' },
        new_layer: { default: true, type: 'boolean' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    next: {
      properties: {
        ...commonFlowFunctionProperties,
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    request: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonFlowFunctionProperties,
        url: { type: 'string' },
        method: { enum: ['get', 'post'], default: 'get', ui: UI.DROPDOWN },
        response: {
          enum: ['plain', 'json-api'],
          default: 'plain',
          ui: UI.DROPDOWN,
        },
        headers: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
        params: {
          default: {},
          patternProperties: {
            '.*': {
              oneOf: [
                { type: 'string' },
                {
                  type: 'object',
                  patternProperties: {
                    '.*': { type: 'string' },
                  },
                  additionalProperties: true,
                },
              ],
              additionalProperties: true,
            },
          },
          additionalProperties: true,
        },
        into: { type: 'string' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    set: {
      description: '{{what}} = {{value}}',
      required: ['what', 'value'],
      properties: {
        ...commonFlowFunctionProperties,
        what: { type: 'string' },
        value: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    track: {
      description: '{{action}}',
      required: ['action'],
      properties: {
        ...commonFlowFunctionProperties,
        action: { type: 'string' },
        category: { type: 'string' },
        label: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
  },
};

export const flowArrayOfFunctions = {
  title: 'arrayOfFunctions',
  default: [],
  type: 'array',
  items: { ..._function },
};

export const commonAppFunctionProperties = {
  function: {
    enum: ['add', 'browser', 'delete', 'goto', 'request', 'set', 'track'],
    ui: UI.DROPDOWN,
  },
  if: _if,
};

export const appFunction = {
  title: 'Function',
  description: '{{function}}',
  required: ['function'],
  default: { function: '' },
  properties: {
    ...commonAppFunctionProperties,
  },
  select: { $data: '0/function' },
  selectCases: {
    add: {
      description: '{{to}}',
      required: ['what', 'to'],
      properties: {
        ...commonAppFunctionProperties,
        what: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
        key: {
          oneOf: [
            { pattern: '^[a-zA-Z]+[_a-zA-Z0-9]*$' },
            { pattern: '^@.+$' },
            { pattern: '^[a-z(@][a-zA-Z()@._0-9]*' },
          ],
        },
        to: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    browser: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonAppFunctionProperties,
        url: { type: 'string' },
        target: {
          default: '_self',
          oneOf: [{ type: 'string' }, { enum: ['_self', '_blank'] }],
        },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    delete: {
      description: '{{what}}',
      required: ['what'],
      properties: {
        ...commonAppFunctionProperties,
        what: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    goto: {
      description: '{{step}}',
      required: ['step'],
      properties: {
        ...commonAppFunctionProperties,
        step: { type: 'string', key: 'step' },
        new_layer: { default: true, type: 'boolean' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    request: {
      description: '{{url}}',
      required: ['url'],
      properties: {
        ...commonAppFunctionProperties,
        url: { type: 'string' },
        method: { enum: ['get', 'post'], default: 'get', ui: UI.DROPDOWN },
        response: {
          enum: ['plain', 'json-api'],
          default: 'plain',
          ui: UI.DROPDOWN,
        },
        headers: {
          default: {},
          additionalProperties: true,
          removeAdditional: true,
        },
        params: {
          default: {},
          patternProperties: {
            '.*': {
              oneOf: [
                { type: 'string' },
                {
                  type: 'object',
                  patternProperties: {
                    '.*': { type: 'string' },
                  },
                  additionalProperties: true,
                },
              ],
              additionalProperties: true,
            },
          },
          additionalProperties: true,
        },
        into: { type: 'string' },
        onsuccess: { default: [] },
        onerror: { default: [] },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    set: {
      description: '{{what}} = {{value}}',
      required: ['what', 'value'],
      properties: {
        ...commonAppFunctionProperties,
        what: { type: 'string' },
        value: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
    track: {
      description: '{{action}}',
      required: ['action'],
      properties: {
        ...commonAppFunctionProperties,
        action: { type: 'string' },
        category: { type: 'string' },
        label: { type: 'string' },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
  },
};

export const appFlowArrayOfFunctions = {
  default: [],
  type: 'array',
  items: { ...appFunction },
};

export const and = _if;
export const or = _if;
export const _else = flowArrayOfFunctions;
export const onchanged = flowArrayOfFunctions;
export const onclick = flowArrayOfFunctions;
export const onerror = flowArrayOfFunctions;
export const onfinished = flowArrayOfFunctions;
export const onsuccess = flowArrayOfFunctions;

export const events = {
  ...defEvents,
  properties: {
    ...defEvents.properties,
    else: _else,
    onchanged,
    onclick,
    onerror,
    onfinished,
    onsuccess,
  },
};

export const commonComponentProperties = {
  id,
  type: {
    enum: [
      componentTypes.GROUP,
      componentTypes.IMAGE,
      componentTypes.INPUTTEXT,
      componentTypes.LIST,
      componentTypes.PDFVIEWER,
      componentTypes.TEXT,
      componentTypes.VIDEO,
      componentTypes.WEB,
      componentTypes.YOUTUBE,
    ],
    ui: UI.DROPDOWN,
  },
  background,
  comments: { default: '' },
  halign,
  height: { ...height, default: 'wrap' },
  if: _if,
  margin,
  onclick,
  padding,
  position,
  valign,
  visible: { type: 'boolean', default: true },
  width: { ...width, default: '100%' },
  __expanded: { type: 'boolean' },
};

export const multilanguageUrl = {
  description: '{{default}}',
  oneOf: [
    { ...source },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: source,
        ...languages.reduce((lngs, key) => ({ ...lngs, [key]: source }), {}),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const multiLanguageImage = {
  description: '{{default}}',
  key: 'image',
  oneOf: [
    { ...file },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: { ...file, key: 'image' },
        ...languages.reduce(
          (lngs, key) => ({ ...lngs, [key]: { ...file, key: 'image' } }),
          {}
        ),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const multiLanguageVideo = {
  description: '{{default}}',
  key: 'video',
  oneOf: [
    { ...file },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: { ...file, key: 'video' },
        ...languages.reduce(
          (lngs, key) => ({ ...lngs, [key]: { ...file, key: 'video' } }),
          {}
        ),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const multiLanguagePdf = {
  description: '{{default}}',
  key: 'pdf',
  oneOf: [
    { ...file },
    {
      type: 'object',
      required: ['default'],
      properties: {
        default: { ...file, key: 'pdf' },
        ...languages.reduce(
          (lngs, key) => ({ ...lngs, [key]: { ...file, key: 'pdf' } }),
          {}
        ),
      },
      additionalProperties: true,
    },
  ],
  additionalProperties: true,
};

export const components = {
  title: 'Component',
  description: '{{type}} {{id}}',
  default: { id: 'component', type: '' },
  required: ['type'],
  properties: {
    ...commonComponentProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [componentTypes.GROUP]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'content', 'height', 'orientation', 'scroll', 'width'],
      properties: {
        ...commonComponentProperties,
        scroll: { default: false, type: 'boolean' },
        orientation,
        content: { default: [] },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.IMAGE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      properties: {
        ...commonComponentProperties,
        online: { default: false, type: 'boolean' },
        field: { type: 'string' },
        file: {
          ...multiLanguageImage,
          requiredGroup: true,
        },
        scale,
        source: { ...source, requiredGroup: true },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.INPUTTEXT]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      properties: {
        ...commonComponentProperties,
        border_color: { ...color, default: '@color.primaryText' },
        border_color_focus: { ...color, default: '@color.primary' },
        color,
        field: { type: 'string' },
        font,
        font_size: fontSize,
        format: { enum: ['plain', 'email', 'number', 'decimal', 'password'] },
        hint: multiLanguageText,
        hint_color: { ...color, default: '@color.secondaryText' },
        lines: { type: 'number' },
        max_length: { type: 'number' },
        source,
        text,
        text_align: halign,
        text_valign: valign,
      },
      events: {
        ...events,
        properties: {
          onchanged: { default: [] },
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.LIST]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'content', 'data_source', 'height', 'width'],
      properties: {
        ...commonComponentProperties,
        content: { default: [] },
        columns: { default: 2, type: 'number', minimum: 1 },
        data_source,
        orientation,
        selection_background: { ...color, default: '@color.primaryLight' },
        sticky: { default: true, type: 'boolean' },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.PDFVIEWER]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['file', 'id', 'height', 'width'],
      properties: {
        ...commonComponentProperties,
        file: multiLanguagePdf,
        height: { ...height, default: 'fill' },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.TEXT]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      properties: {
        ...commonComponentProperties,
        color: { ...color, default: '@color.primaryText' },
        decimals: { type: 'number' },
        field: { type: 'string' },
        font,
        font_size: fontSize,
        selectable: { type: 'boolean', default: true },
        text: { ...text, requiredGroup: true },
        source: { ...source, requiredGroup: true },
        text_align: halign,
        text_valign: valign,
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.VIDEO]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      properties: {
        ...commonComponentProperties,
        autoplay: { type: 'boolean', default: true },
        field: { type: 'string' },
        file: { ...multiLanguageVideo, requiredGroup: true },
        height: { ...height, default: 300 },
        loop: { type: 'boolean', default: true },
        scale,
        source: { ...source, requiredGroup: true },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onfinished: { default: [] },
          onready: { default: [] },
        },
      },
    },
    [componentTypes.WEB]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width'],
      properties: {
        ...commonComponentProperties,
        url: multilanguageUrl,
        height: { ...height, default: 'fill' },
        width: { ...width, default: 'fill' },
        zoom: { type: 'boolean', default: true },
        file: { ...file, key: 'zip' },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
        },
      },
    },
    [componentTypes.YOUTUBE]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['id', 'height', 'width', 'video_id'],
      properties: {
        ...commonComponentProperties,
        video_id: { ...multiLanguageText, default: 'PniHTw479uA' },
        allow_fullscreen: { type: 'boolean', default: true },
        autoplay: { type: 'boolean', default: true },
        controls: { enum: ['default', 'minimal', 'none'] },
      },
      events: {
        ...events,
        properties: {
          onclick: { default: [] },
          onerror: { default: [] },
          onfinished: { default: [] },
        },
      },
    },
  },
};

export const content = {
  title: 'Content',
  default: [],
  type: 'array',
  uniqueItemProperties: ['id'],
  items: {
    ...components,
  },
  removeNull: true,
};

export const button = {
  default: { label: '', onclick: [{ function: 'next' }] },
  required: ['label', 'onclick'],
  properties: {
    label,
    color,
    background,
    onclick,
  },
};

export const step = {
  title: 'Step',
  description: '{{id}} {{target}}',
  default: { id: 'step', text: '' },
  required: ['id'],
  properties: {
    id: { pattern: '^[a-zA-Z]+[_a-zA-Z0-9]*$' },
    target: {
      default: { selector: '' },
      required: ['selector'],
      properties: {
        selector: { type: 'string' },
        color: { ...color, default: '@color.accent' },
        shape: { default: 'rounded', enum: ['circle', 'rounded', 'square'] },
        onchanged: flowArrayOfFunctions,
        onclick: flowArrayOfFunctions,
      },
    },
    title,
    text,
    content,
    blocking: { default: true, type: 'boolean' },
    closable: { default: true, type: 'boolean' },
    button_positive: { default: true, type: 'boolean' },
    button_negative: { default: true, type: 'boolean' },
    buttons: {
      default: [],
      items: button,
    },
    style: {
      default: {},
      properties: {
        background,
        halign: { ...halign, default: halignTypes.CENTER },
        height: {
          ...height,
          default: 'auto',
          oneOf: [
            { enum: ['auto'] },
            { pattern: '[0-9]+%' },
            { type: 'number' },
          ],
        },
        max_height: {
          ...height,
          default: 520,
          oneOf: [{ pattern: '[0-9]+%' }, { type: 'number' }],
        },
        max_width: {
          ...width,
          default: 520,
          oneOf: [{ pattern: '[0-9]+%' }, { type: 'number' }],
        },
        offset: {
          default: { x: 0, y: 0 },
          properties: {
            x: { type: 'number' },
            y: { type: 'number' },
          },
        },
        padding: { ...padding, default: 24 },
        valign: { ...valign, default: valignTypes.MIDDLE },
        width: {
          ...width,
          default: 'auto',
          oneOf: [
            { enum: ['auto'] },
            { pattern: '[0-9]+%' },
            { type: 'number' },
          ],
        },
      },
      additionalProperties: false,
      removeAdditional: true,
    },
  },
};

export const steps = {
  title: 'Steps',
  default: [],
  type: 'array',
  uniqueItemProperties: ['id'],
  items: {
    ...step,
  },
};

export const flowButtons = {
  ...buttons,
  properties: {
    ...buttons.properties,
    negative: {
      ...buttons.properties.negative,
      properties: {
        ...buttons.properties.negative.properties,
        onclick: flowArrayOfFunctions,
      },
    },
    positive: {
      ...buttons.properties.positive,
      properties: {
        ...buttons.properties.positive.properties,
        onclick: flowArrayOfFunctions,
      },
    },
  },
};

export default {
  and,
  or,
  background,
  button,
  components,
  content,
  else: _else,
  events,
  flowButtons,
  margin,
  padding,
  step,
  steps,
};
