import React from 'react';
import Component from './Component';
import banner from '../../../assets/img/animation.gif';

export const BannerCompiler = (props) => {
  return <Banner {...props} />;
};

export const Banner = ({ format, ...props }) => {
  return (
    <Component {...props} alt="banner" className="banner" data-format={format}>
      <img src={banner} alt={props.id} />
    </Component>
  );
};

export default BannerCompiler;
