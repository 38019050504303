import React from 'react';
import Component from './Component';

export const PlayerCompiler = (props) => {
  return <Player {...props} />;
};

export const Player = (props) => {
  return <Component {...props} component="audio" />;
};

Player.displayName = 'Player';
PlayerCompiler.displayName = 'Player';
export default PlayerCompiler;
