import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose } from '@mdi/js';
import Helmet from 'react-helmet';
import { Alert, Button, Table } from 'react-bootstrap';
import { DOYO_PLANS, SECRETS } from '../../_config';
import { IDENTIFIER_TYPE } from '../../context';
import { useAccounts, useGeneral, useIdentifiers } from '../../hooks';
import features from './features.json';

function getClientReferenceId() {
  return (
    (window.Rewardful && window.Rewardful.referral) ||
    'checkout_' + new Date().getTime()
  );
}

const Feature = ({ feature, value }) => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <tr className="d-md-none small">
        <th colSpan="4">
          <span>{t(`features.${feature}`)}</span>
        </th>
      </tr>
      <tr>
        <th scope="row" className="d-none d-md-table-cell small fw-normal">
          <span>{t(`features.${feature}`)}</span>
        </th>
        {(Array.isArray(value) &&
          value.map((planValue, i) => {
            let str = i18n.exists(`features.${feature}_${DOYO_PLANS[i].id}`)
              ? t(`features.${feature}_${DOYO_PLANS[i].id}`, {
                  count: planValue,
                })
              : planValue;
            str = i18n.exists(`features.${planValue}`)
              ? t(`features.${planValue}`)
              : str;
            let icon =
              (planValue === true && (
                <Icon path={mdiCheck} className="text-success" size={1} />
              )) ||
              (planValue === false && (
                <Icon path={mdiClose} className="text-gray" size={1} />
              ));
            return (
              <td key={`Feature-${feature}-${i}`}>
                <span>{icon || str}</span>
              </td>
            );
          })) || (
          <>
            {DOYO_PLANS.map(({ id, name, price, quantity }, i) => {
              let str = i18n.exists(`features.${feature}_${id}`, {
                count: value,
              })
                ? t(`features.${feature}_${id}`, { count: value })
                : value;
              str = i18n.exists(`features.${value}`)
                ? t(`features.${value}`)
                : str;
              return (
                <td key={`${id}-${i}`}>
                  <span>{str}</span>
                </td>
              );
            })}
          </>
        )}
      </tr>
    </>
  );
};

const PricingTable = ({ clientReferenceId, customerEmail }) => {
  return (
    <stripe-pricing-table
      pricing-table-id={SECRETS.PRICING_TABLE_ID}
      publishable-key={SECRETS.PUBLISHABLE_KEY}
      client-reference-id={clientReferenceId}
      customer-email={customerEmail}
    ></stripe-pricing-table>
  );
};

export const Plans = () => {
  const { t } = useTranslation();
  const { location = {} } = useGeneral();
  let { country, currency } = location;
  if (currency) {
    currency = currency === 'EUR' ? 'EUR' : 'USD';
  }

  const { account = {} } = useAccounts();
  const { subscription = {} } = account;
  const { customer = {} } = subscription;
  const { id } = customer;
  const { identifiers = [] } = useIdentifiers();
  const { node: { subject: customerEmail = '' } = {} } =
    identifiers.find(
      ({ node }) => node.default && node.type === IDENTIFIER_TYPE.EMAIL
    ) || {};

  const clientReferenceId = id || getClientReferenceId();

  return (
    <>
      <Helmet>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>
      <div className="plans">
        <div className="mb-5">
          <PricingTable
            key={`${clientReferenceId}-${customerEmail}`}
            clientReferenceId={clientReferenceId}
            customerEmail={customerEmail}
          />
        </div>
        <Table className="border-bottom">
          <thead>
            <tr>
              <th className="d-none d-md-table-cell">
                <h5 className="m-0 text-primary">{t('pricing.features')}</h5>
              </th>
              {DOYO_PLANS.map(({ id, name, price, quantity }, i) => (
                <th key={`${id}-${i}`}>
                  <h5 className="text-primary">{name}</h5>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(features)
              .filter(([, value]) => Array.isArray(value))
              .map(([key, value]) => (
                <Feature key={key} feature={key} value={value} />
              ))}
            {Object.entries(features)
              .filter(([, value]) => !Array.isArray(value))
              .map(([key, value], i) => (
                <tr key={`Feature-${i}`}>
                  <td colSpan="5" className="p-0">
                    <Table key="features">
                      <thead>
                        <tr key={`Feature-${key}`}>
                          <th scope="row" colSpan="5">
                            <h6 className="m-0 text-primary">
                              {t(`features.${key}`)}
                            </h6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(value).map((subKey, i) => (
                          <Feature
                            key={`${key}-${i}`}
                            feature={subKey}
                            value={value[subKey]}
                          />
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Alert variant="primary" className="mt-5 rounded-sm">
          <h4 className="alert-heading">{t('pricing.custom_plan.header')}</h4>
          {t('pricing.custom_plan.body')}
          <div className="text-center">
            <a
              href="mailto:sales@doyo.email"
              className="d-inline-block mt-4 mb-3"
            >
              <Button color="primary">{t('pricing.custom_plan.button')}</Button>
            </a>
          </div>
        </Alert>
      </div>
    </>
  );
};

export default Plans;
