export const IDENTIFIER = {
    INITIAL_STATE: {
        identifiers: undefined,
        identifier: undefined,
    },

    CREATE: 'IDENTIFIER_CREATE',
    DELETE: 'IDENTIFIER_DELETE',
    ERROR: 'IDENTIFIER_ERROR',
    GET: 'IDENTIFIER_GET',
    LIST: 'IDENTIFIER_LIST',
    SET_IDENTIFIER_AS_DEFAULT: 'IDENTIFIER_SET_IDENTIFIER_AS_DEFAULT',
};

export const IdentifierReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case IDENTIFIER.DELETE:
            draft.identifiers = draft.identifiers.filter(({ node }) => node.id !== payload.id);
            break;

        case IDENTIFIER.ERROR:
            draft.error = payload.error;
            break;

        case IDENTIFIER.GET:
            draft.identifiers = draft.identifiers.map((identifier) => {
                let { node } = identifier;
                let { id } = node;
                return id !== payload.identifier.id ? identifier : payload.identifier;
            });
            draft.identifier = draft.identifiers.find(({ node }) => node.default);
            break;

        case IDENTIFIER.LIST:
            draft.identifiers = payload.identifiers.edges.map((identifierEdge) => ({
                ...identifierEdge,
                node: {
                    ...identifierEdge.node,
                    verifications: identifierEdge.node.verifications.edges,
                },
            }));
            draft.identifier = draft.identifiers.find(({ node }) => node.default);
            draft.pageInfo = payload.identifiers.pageInfo;
            break;

        case IDENTIFIER.SET_IDENTIFIER_AS_DEFAULT:
            draft.identifiers = draft.identifiers.map(({ node }) => ({
                node: {
                    ...node,
                    default: node.id === payload.id,
                },
            }));
            draft.identifier = draft.identifiers.find(({ node }) => node.default);
            break;

        default:
            return draft;
    }
};
