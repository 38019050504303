import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiClose, mdiDotsHorizontal } from '@mdi/js';
import { Button, Alert, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import QRCode from 'qrcode.react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getLanguage } from '../_helpers';
import { useAlerts } from '../hooks';
import { ALERT_TYPE } from '../context';
import { logoMin } from '../assets/svg/logos';

dayjs.extend(relativeTime);

const AlertsWrapper = ({ position, style }) => {
  const { t } = useTranslation();
  const lang = getLanguage();
  let { alertClear, alerts, getUnviewed } = useAlerts();
  alerts = alerts.map((alert) => ({ ...alert, close: true, dismiss: false }));

  useEffect(() => {
    import(`dayjs/locale/${lang}.js`).then(() => {
      dayjs.locale(lang);
    });
  }, [lang]);

  const handleClose = (event) => {
    const { dataset } = event.currentTarget.parentElement;
    const { id } = dataset;
    alertClear(parseInt(id, 10));
  };

  return (
    <div className="alert-wrapper" style={style}>
      {(!alerts.length && (
        <Empty description={t('common.no_notifications')} />
      )) || (
        <>
          {(getUnviewed().length && (
            <>
              <h6 className="new">{t('common.new_notifications')}</h6>
            </>
          )) ||
            null}
          {alerts.map((alert, i) => {
            let {
              id,
              message = '',
              type,
              description = '',
              qr = {},
              viewed = false,
            } = alert;
            type = type === 'danger' ? ALERT_TYPE.ERROR : type;
            const { value: qrValue = '' } = qr;
            const size = 100;
            let { progress } = alert;
            progress = isNaN(progress) ? 0 : progress;

            let _description = description && parse(description);
            _description = Array.isArray(_description)
              ? _description
              : [_description];
            _description = _description.map((desc, i) => {
              if (
                desc.props &&
                ['errors', 'warnings'].includes(desc.props.className)
              ) {
                const { props } = desc;
                let { children } = props;
                children = Array.isArray(children) ? children : [children];
                return {
                  ...desc,
                  key: `Alert-${id}-Description-${i}`,
                  props: {
                    ...desc.props,
                    children: children.map((li, i) => ({
                      ...li,
                      props: {
                        ...li.props,
                        children: (
                          <Link to={li.props.to}>{li.props.children}</Link>
                        ),
                      },
                    })),
                  },
                };
              }
              return (
                <React.Fragment key={`Alert-${id}-Description-${i}`}>
                  {desc}
                </React.Fragment>
              );
            });

            return (
              <Alert
                key={`Alert-${id}`}
                type={type}
                data-id={id}
                data-new={!viewed}
                data-progress={progress}
                toggle={handleClose}
                showIcon
                closable
                afterClose={() => {}}
                closeText={<Icon path={mdiClose} />}
                action={[
                  ...(description || qrValue
                    ? [
                        <Button
                          size="small"
                          type="text"
                          shape="circle"
                          key={`expand-button`}
                          className="expand-button"
                          onClick={(e) =>
                            e.currentTarget.parentElement.parentElement.classList.toggle(
                              'expanded'
                            )
                          }
                        >
                          <Icon path={mdiDotsHorizontal} size={1} />
                        </Button>,
                      ]
                    : []),
                ]}
                message={
                  <>
                    <div>
                      <span>{parse(message)}</span>
                    </div>
                    <span className="from-now">{dayjs(id).fromNow()}</span>
                  </>
                }
                description={
                  <>
                    {_description}
                    {qrValue && (
                      <QRCode
                        value={qrValue}
                        size={size}
                        includeMargin={false}
                        imageSettings={{
                          src: logoMin,
                          height: (size * 16) / 100,
                          width: (size * 16) / 100,
                          excavate: true,
                        }}
                      />
                    )}
                  </>
                }
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default AlertsWrapper;
export { AlertsWrapper };
