import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const useCountdown = (
  duration,
  { from = new Date().getTime(), unit }
) => {
  const countDownDate = new Date(dayjs(from).add(duration, unit)).getTime();
  const [countDown, setCountDown] = useState(countDownDate - from);

  useEffect(() => {
    const interval = setInterval(() => {
      const newCountDownDate = countDownDate - new Date().getTime();
      if (newCountDownDate < 0) {
        clearInterval(interval);
        return;
      }
      setCountDown(newCountDownDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return { countDown };
};

export default useCountdown;
