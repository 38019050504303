import ls from 'local-storage';
import { getUnique } from '../../_helpers';

export const PUBLICATION = {
  INITIAL_STATE: {
    deployConfig: {},
    publications: [],
  },

  CREATE: 'PUBLICATION_CREATE',
  GET: 'PUBLICATION_GET',
  DEPLOY: 'PUBLICATION_DEPLOY',
  LIST: 'PUBLICATION_LIST',
};

export const PublicationReducer = (draft, action) => {
  const user = ls('user') || {};
  const { payload, type } = action;
  switch (type) {
    case PUBLICATION.CREATE:
    case PUBLICATION.GET:
      draft.publications = getUnique(
        [
          {
            ...(draft.publications.find(
              ({ uid }) => uid === payload.publication.uid
            ) || {}),
            ...payload.publication,
          },
          ...draft.publications,
        ],
        'uid'
      ).sort((p1, p2) => {
        return p1.updated_at > p2.updated_at ? -1 : 1;
      });
      ls('user', { ...user, _publications: draft.publications });
      break;

    case PUBLICATION.DEPLOY:
      draft.deployConfig = {
        ...draft.deployConfig,
        [payload.deployConfig?.project?.uid]: payload.deployConfig,
      };
      break;

    case PUBLICATION.LIST:
      draft.publications = getUnique(
        [
          ...payload.publications.map((publication) => {
            return {
              ...(draft.publications.find(
                ({ uid }) => uid === publication.uid
              ) || {}),
              ...publication,
            };
          }),
          ...draft.publications,
        ],
        'uid'
      ).sort((p1, p2) => {
        return p1.updated_at > p2.updated_at ? -1 : 1;
      });
      ls('user', { ...user, _publications: draft.publications });
      break;

    default:
      return draft;
  }
};
