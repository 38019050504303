// @flow
import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

export const CustomModal = (props) => {
  let {
    button = false,
    buttonParameters = {},
    cancel,
    children,
    className,
    footer,
    id,
    isOpen: isOpenProp = false,
    onOpened = () => {},
    onClosed = () => {},
    size,
    title = '',
    toggle: toggleProp,
  } = props;
  const [visible, setVisible] = useState(isOpenProp);

  const toggle = toggleProp || (() => setVisible((visible) => !visible));
  buttonParameters.onClick = buttonParameters.onClick || toggle;

  const modalProps = { visible, toggle };
  id && (modalProps.id = id);
  size && (modalProps.size = size);
  className && (modalProps.className = className);

  children = Array.isArray(children) ? children : [children];

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      {button !== false && <Button {...buttonParameters}>{button}</Button>}

      <Modal
        {...modalProps}
        closeIcon={<Icon path={mdiClose} size={1} />}
        footer={footer || null}
        title={title || null}
        onOpened={onOpened}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={onClosed}
      >
        {children.map((child) => child)}
        {cancel && (
          <Button onClick={toggle} className="cancel">
            {cancel}
          </Button>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default CustomModal;
