import React from 'react';
import { useSource } from '../../hooks';
import Component, { Content } from './Component';

export const ListCompiler = ({
  columns,
  content = [],
  data_source: dataSource,
  filter,
  itemData,
  limit,
  order,
  orientation,
  scroll,
  ...props
}) => {
  let className = scroll ? 'scrollable' : '';
  return (
    <List
      {...props}
      className={className}
      columns={columns}
      direction={orientation}
      filter={filter}
      order={order}
      limit={limit}
      src={dataSource}
    >
      {content && <Content content={content} itemData={itemData} />}
    </List>
  );
};

export const List = ({
  children,
  className,
  columns,
  direction = 'vertical',
  filter,
  order,
  limit,
  selectable = false,
  src,
  ...props
}) => {
  const { id } = props;
  let { value } = useSource(src, { filter, isCollection: true, order, limit });
  let { value: selected } = useSource(`@element.${id}.value`);
  value = Object.values(value || []);
  children = Array.isArray(children) ? children : [children];
  let items = Array.isArray(value) ? value : [value];
  items = items.length ? items : [{}, {}, {}, {}, {}, {}];

  let columnClass = '';
  if (columns) {
    columnClass = columns === 'auto' ? 'list-columns-auto' : 'list-columns';
    direction = 'vertical';
  }

  return (
    <Component
      {...props}
      className={`list direction-${direction} ${columnClass} ${className} overflow-auto`.trim()}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridTemplateRows: 'max-content',
      }}
    >
      {items.map((item, i) => (
        <div
          key={`list-item-${item?.id}-${i}`}
          className={`${selectable ? 'cursor-pointer' : ''}${
            selected?.index === i ? ' selected' : ''
          }`.trim()}
        >
          {children.map(({ props, ...child }) => {
            const itemData = item;
            const itemDataSrc = props?.src ? item[props?.src] : item;
            return {
              ...child,
              props: { ...props, itemData, itemDataSrc, __listId__: id },
            };
          })}
        </div>
      ))}
    </Component>
  );
};

List.displayName = 'List';
ListCompiler.displayName = 'List';
export default ListCompiler;
