import gql from 'graphql-tag';

export const CREATE_TAG = gql`
  mutation CreateTag($accountId: ID!, $name: String!) {
    createTag(input: { accountId: $accountId, name: $name }) {
      id
      accountId
      name
      createdAt
      updatedAt
      taggings(first: 100) {
        edges {
          node {
            id
            name
            tag {
              id
            }
            file {
              id
              hash
              extension
              mimeType
              size
              url
              createdAt
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation UpdateTag($tagId: ID!, $name: String!) {
    updateTag(input: { tagId: $tagId, name: $name }) {
      id
      name
      updatedAt
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($id: ID!) {
    deleteTag(id: $id) {
      id
    }
  }
`;
