import { useEffect, useState } from 'react';
import { useFunctions, useRefs } from '.';
import { getKeys } from '../context/Refs';

export function useSource(
  what,
  { data, filter, isCollection, order, limit } = {}
) {
  const { get } = useFunctions();
  const refs = useRefs();
  const keys = getKeys(what);
  const { [keys[0]]: mainRef = {} } = refs;
  const { [keys[1]]: ref } = mainRef;
  const _value = keys.length
    ? keys.reduce((resp, key) => resp?.[key], refs)
    : what;
  const [value, setValue] = useState(_value);

  useEffect(() => {
    let mounted = true;
    (async () => {
      if (what) {
        const value = await get(
          { what, filter, isCollection, order, limit },
          { data }
        );
        mounted && setValue(value);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [what, ref, data, filter, order, limit]);

  return { refs, value };
}
