import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { DatabasesReducer, DATABASES } from './reducers';
import { useProject } from '../hooks';

export const DatabasesContext = createContext({});

export const DatabasesProvider = (props) => {
  const { project = {} } = useProject();
  const { data = {} } = project;
  const { app = {}, res = [] } = data;
  const { databases = [] } = app;
  const [state, dispatch] = useImmerReducer(
    DatabasesReducer,
    DATABASES.INITIAL_STATE
  );
  const { database } = state;

  useEffect(() => {
    let mounted = true;
    (async () => {
      let dbs = await Promise.all(
        databases.map(async ({ id, source }) => {
          const { url } = res.find(({ id }) => id === source);
          const data = await (await fetch(url)).json();
          return [id, data];
        })
      );
      dbs = dbs.reduce((dbs, [id, data]) => ({ ...dbs, [id]: data }), {});
      mounted &&
        !database._equals(dbs) &&
        dispatch({ type: DATABASES.SET, payload: { ...dbs } });
    })();
    return () => {
      mounted = false;
    };
  }, [databases, res]);

  const addToDatabase = async ({ key, to, what }) => {};

  const deleteFromDatabase = async (what) => {};

  const setToDatabase = async ({ what, value, doDispatch = true }) => {};

  return (
    <DatabasesContext.Provider
      value={{
        ...state,
        addToDatabase,
        deleteFromDatabase,
        setToDatabase,
      }}
    >
      {props.children}
    </DatabasesContext.Provider>
  );
};

export const DatabasesConsumer = DatabasesContext.Consumer;
export default DatabasesContext;
