import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { PAGES } from '../_config';
import { Language } from './';
import { logoMin } from '../assets/svg/logos';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <Container className="container border-top mt-5 py-5">
        <Row className="m-0">
          <Col className="pl-0">
            <img alt="logo Doyo" src={logoMin} height="42px" />
            <small>
              <small className="d-block mt-2 mb-3 text-muted">
                © {new Date().getFullYear()} Doyo
                <br />
                All rights reserved
              </small>
            </small>
          </Col>
          <Col>
            <h6>{t('footer.doyo_apps')}</h6>
            <Nav>
              <Nav.Item>
                <Nav.Link href={PAGES.KUSTODIO}>Kustod.io</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={PAGES.STORIES}>DoyoStories</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col>
            <h6>{t('footer.sections')}</h6>
            <Nav>
              <Nav.Item>
                <Link to={PAGES.HOME}>{t('header.home')}</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to={PAGES.PRICING}>{t('header.pricing')}</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to={PAGES.SIGN_IN}>{t('common.sign_in')}</Link>
              </Nav.Item>
              <Nav.Item>
                <Link to={PAGES.SIGN_UP}>{t('common.sign_up_long')}</Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col>
            <h6>{t('footer.make_money_with_us')}</h6>
            <Nav>
              <Nav.Item>
                <Nav.Link
                  href="https://doyo-tech.getrewardful.com/signup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.become_an_affiliate')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col className="pr-0 text-end">
            <Language menu={{ align: 'end' }} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
