import React from 'react';
import {
  Bar as BarChart,
  Doughnut as DoughnutChart,
  Line as LineChart,
  Pie as PieChart,
  PolarArea as PolarChart,
  Radar as RadarChart,
  Scatter as ScatterChart,
} from 'react-chartjs-2';
import Component from './Component';
import { useSource } from '../../hooks';

const getGradients = (datasets) => {
  return datasets.map((dataset) => {
    const { backgroundColor } = dataset;
    if (Array.isArray(backgroundColor)) {
      return {
        ...dataset,
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          let gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          backgroundColor.forEach((bgColor, index) => {
            const perc = index / backgroundColor.length;
            gradient.addColorStop(perc, bgColor);
          });
          return gradient;
        },
      };
    }
    return dataset;
  });
};

export const Bar = (props) => {
  let { data = {} } = props;
  let { datasets = [] } = data;
  datasets = getGradients(datasets);
  data = { ...data, datasets };
  return <Chart {...props} data={data} component={BarChart} />;
};

export const Line = (props) => {
  let { data = {} } = props;
  let { datasets = [] } = data;
  datasets = getGradients(datasets);
  data = { ...data, datasets };
  return <Chart {...props} data={data} component={LineChart} />;
};

export const Doughnut = (props) => {
  return <Chart {...props} component={DoughnutChart} />;
};

export const Scatter = (props) => {
  return <Chart {...props} component={ScatterChart} />;
};

export const Pie = (props) => {
  return <Chart {...props} component={PieChart} />;
};

export const Polar = (props) => {
  return <Chart {...props} component={PolarChart} />;
};

export const Radar = (props) => {
  return <Chart {...props} component={RadarChart} />;
};

export const ChartCompiler = ({ chart = {}, ...props }) => {
  const {
    chart_type: chartType = 'line',
    datasets = [],
    labels = [],
    options = {},
  } = chart;
  const data = { datasets, labels };
  let ChartType = Line;
  switch (chartType) {
    case 'bar':
      ChartType = Bar;
      break;
    case 'doughnut':
      ChartType = Doughnut;
      break;
    case 'line':
      ChartType = Line;
      break;
    case 'pie':
      ChartType = Pie;
      break;
    case 'polar':
      ChartType = Polar;
      break;
    case 'radar':
      ChartType = Radar;
      break;
    case 'scatter':
      ChartType = Scatter;
      break;
    default:
      ChartType = Line;
  }
  return <ChartType {...props} data={data} options={options} />;
};

export const Chart = ({
  className = '',
  component = BarChart,
  data: dataProps = {},
  src: dataSource,
  options = {},
  ...props
}) => {
  const defaultData = { ...dataProps };
  const { datasets } = defaultData;
  let datasetsSource;
  if (!datasets) {
    defaultData.datasets = [];
    defaultData.labels = [];
  }
  if (dataSource) {
    datasetsSource = `${dataSource}.datasets`;
  }
  let { value: data = defaultData } = useSource(dataSource);
  let { value: srcDatasets = data?.datasets } = useSource(datasetsSource);

  data = {
    ...data,
    datasets: srcDatasets,
  };

  return (
    <div className={`chart d-flex align-items-center ${className}`.trim()}>
      <Component
        {...props}
        data={data}
        options={{ responsive: true, maintainAspectRatio: false, ...options }}
        component={component}
      />
    </div>
  );
};

Chart.Bar = Bar;
Chart.Line = Line;
Chart.Doughnut = Doughnut;
Chart.Scatter = Scatter;
Chart.Pie = Pie;
Chart.Polar = Polar;
Chart.Radar = Radar;

Chart.displayName = 'Chart';
ChartCompiler.displayName = 'Chart';
export default ChartCompiler;
