import React from 'react';
import Component from './Component';

export const WebCompiler = ({ url, ...props }) => {
  return <Web {...props} src={url} />;
};

export const Web = (props) => {
  return <Component {...props} component="iframe" />;
};

Web.displayName = 'Web';
WebCompiler.displayName = 'Web';
export default WebCompiler;
