import useProject from './useProject';

export const useWrapper = (wrapperId) => {
  const { project = {} } = useProject();
  const { data = {} } = project;
  const { view_wrappers: viewWrappers = [] } = data;
  return viewWrappers.find(({ id }) => id === wrapperId) || {};
};

export default useWrapper;
