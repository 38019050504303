export const debug = window.location.hostname.startsWith('localhost');

export const HOST = {
  KUSTODIO: 'https://kustod.io',
  STORIES: 'https://stories.doyo.tech',
};

export const URL = {
  AI: 'https://api.doyo.ai',
  API: 'https://brain.doyo.tech/api',
  BILLING: 'https://billing.doyo.tech/api',
  DISCORD_EN: 'https://discord.gg/sYe3BQDwdz',
  DISCORD_ES: 'https://discord.gg/YuNpkeUG4C',
  DO_CDN: 'https://codoozer.ams3.cdn.digitaloceanspaces.com',
  DOYO: 'https://doyo.tech',
  DOYOSTORIES: 'https://stories.doyo.tech/',
  FACE: 'https://doyo.tech',
  GRAVATAR: 'https://secure.gravatar.com/avatar/',
  INTEGRATIONS: 'https://api.doyo.tech',
  KUSTODIO: 'https://kustod.io',
  KUSTODIO_API: 'https://brain.kustod.io',
  PASSPORT: 'https://passport.doyo.tech/',
  SERVER: 'https://doyo.tech',
  SUBSCRIPTION: 'https://billing.stripe.com/p/login/5kA4gwcgUe4YddC288',
  TWITTER: 'https://twitter.com/doyo_official',
  WELCOME: 'https://kustod.io/s/3jSEauSsaDuM5VPYVX9uqI',
  WISDOM: 'https://github.com/doyotech/wisdom',
  YOUTUBE: 'https://www.youtube.com/channel/UCZHJdk7B1hxAoVWOMvzOWOg',
  YOUTUBE_UNOFFICIAL: [
    {
      avatar:
        'https://yt3.ggpht.com/gYyTcgi7p-wnsF44k3tpL4pR83RB8-wvL9osdgtFJv3n5WSwP3fi_VBLl_L21VV8HQO2mtgklg=s88-c-k-c0x00ffffff-no-rj',
      name: 'LowCode Hispano (Español)',
      url: 'https://www.youtube.com/channel/UCAaE5YzTPBsvZvrwHxyxYSg',
    },
    {
      avatar:
        'https://yt3.ggpht.com/ytc/AMLnZu9d35kmH-od_Z_leBuOIxPBLUn1I7J9kquCZkPB4kyt3DkjDDKo5f-U3QoLUH0Q=s88-c-k-c0x00ffffff-no-rj',
      name: 'NO or LOW code (English)',
      url: 'https://www.youtube.com/channel/UCtskKtDXN4SmP0hU58BL0iw',
    },
    {
      avatar:
        'https://www.youtube.com/watch?v=7pMhQhDiMxs&list=PLNOKuM21lj0pik5V2lrs9KCTl4BXtzh_h',
      name: 'ApliArte Tutoriales',
      url: 'https://www.youtube.com/watch?v=3DsoaFNoDxc&list=PLNOKuM21lj0pik5V2lrs9KCTl4BXtzh_h&ab_channel=ApliArteTutoriales',
    },
  ],
};

export const SECRETS = {
  FLOW_HASH: '7Nz2zRUUU7PcKr8wpw4Ie',
  GRE_CAPTCHA_SITE_KEY: '6Ld95YcUAAAAAEhmBcUx8yAWmW-QVVAjykgQxwgT',
  INTERCOM_APP_ID: 'v08focu5',
  PEXELS_API_KEY: '563492ad6f91700001000001e2eae492a5d24219a294923b93bec73f',
  PRICING_TABLE_ID: 'prctbl_1LhxeJBUuteQqLrGWoqMFwa8',
  PUBLISHABLE_KEY: 'pk_live_ooYPyLCv15Axr8IlrYOYCjrq',
  SENTRY_DSN: 'https://7206514b5148440a80da070c72189d3f@sentry.io/1449672',
};

export const DOYO_PRICES = [
  {
    id: 'price_1JlszKBUuteQqLrGcXXeBCzE',
    currency: 'EUR',
    interval: 'month',
    quantity: 10,
  },
  {
    id: 'price_1Jlt0MBUuteQqLrGUEmJTles',
    currency: 'USD',
    interval: 'month',
    quantity: 10,
  },
  {
    id: 'price_1Jlt0qBUuteQqLrGdKR1msXG',
    currency: 'EUR',
    interval: 'year',
    quantity: 10,
  },
  {
    id: 'price_1Jlt1ABUuteQqLrGcuNcGAN3',
    currency: 'USD',
    interval: 'year',
    quantity: 10,
  },

  {
    id: 'price_1JlsyeBUuteQqLrGtEEaj5Ek',
    currency: 'EUR',
    interval: 'month',
    quantity: 30,
  },
  {
    id: 'price_1JltBZBUuteQqLrGzZRNuWWd',
    currency: 'USD',
    interval: 'month',
    quantity: 30,
  },
  {
    id: 'price_1JltC5BUuteQqLrGCxhME8Nc',
    currency: 'EUR',
    interval: 'year',
    quantity: 30,
  },
  {
    id: 'price_1Jlt1ABUuteQqLrGcuNcGAN3',
    currency: 'USD',
    interval: 'year',
    quantity: 30,
  },

  {
    id: 'price_1JltEWBUuteQqLrGn6Z1m3t4',
    currency: 'EUR',
    interval: 'month',
    quantity: 250,
  },
  {
    id: 'price_1JltF1BUuteQqLrGMCRzKgWc',
    currency: 'USD',
    interval: 'month',
    quantity: 250,
  },
  {
    id: 'price_1JltEnBUuteQqLrGXQeEXhHB',
    currency: 'EUR',
    interval: 'year',
    quantity: 250,
  },
  {
    id: 'price_1JltFKBUuteQqLrGeQPeecdi',
    currency: 'USD',
    interval: 'year',
    quantity: 250,
  },
];
