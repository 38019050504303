import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiFile } from '@mdi/js';
import { JsonEditor } from 'jsoneditor-react';
import AceEditor from 'react-ace';
import { Button, Image } from 'antd';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import Ajv from 'ajv';
import { isObject, isText, isTextFile } from '../_helpers';
import { useAlerts, useViewer } from '../hooks';

const ajv = new Ajv({
  $data: true,
  allErrors: true,
  jsonPointers: true,
  removeAdditional: true,
  uniqueItems: true,
});

const FilePreview = ({
  className = '',
  id = '',
  url = '',
  blob = {},
  onChange,
}) => {
  const { t } = useTranslation();
  const { alertError } = useAlerts();
  const { jsonEditorMode } = useViewer();
  const preview = useRef();
  const [value, setValue] = useState();
  const newValue = useRef(value);
  const ext = id.split('.').pop();

  const onLoad = () => {
    const parent = preview.current;
    const { firstElementChild: child } = parent;
    child.style.transform = '';
    if (child.offsetWidth > parent.offsetWidth) {
      child.style.transform = `scale(${
        parent.offsetWidth / child.offsetWidth - 0.05
      })`;
    }
    if (child.offsetHeight > parent.offsetHeight) {
      child.style.transform = `scale(${
        parent.offsetHeight / child.offsetHeight - 0.05
      })`;
    }
  };

  const Preview = useCallback(
    ({ ext, id, url, value }) => {
      switch (ext) {
        case 'mp3':
          return <audio controls src={url} />;

        case 'pdf':
          return <iframe title={id} src={url} />;
        case 'xml':
        case 'txt':
          value = isText(value) ? value : '';
          return (
            <>
              <AceEditor
                mode="text"
                value={value || ''}
                theme="github"
                onChange={(value) => {
                  newValue.current = value;
                  document
                    .querySelector('.actions .save')
                    .classList.remove('disabled');
                }}
                name="text-editor"
                width="100%"
                height="100%"
              />
              <Button
                className="save d-none"
                onClick={() => {
                  const value = newValue.current;
                  const file = new Blob([value], { type: 'text/plain' });
                  setValue(newValue.current);
                  onChange(id, file);
                }}
              ></Button>
            </>
          );
        case 'json':
          value = isObject(value) ? value : {};
          return (
            <>
              <JsonEditor
                name={id}
                value={value}
                mode={jsonEditorMode}
                htmlElementProps={{ className: 'json-editor' }}
                onChange={(json) => {
                  newValue.current = json;
                  document
                    .querySelector('.actions .save')
                    .classList.remove('disabled');
                }}
                ajv={ajv}
                ace={ace}
                theme="ace/theme/github"
                schema={{}}
              />
              <Button
                className="save d-none"
                onClick={() => {
                  const json = JSON.stringify(newValue.current);
                  const file = new Blob([json], { type: 'application/json' });
                  setValue(newValue.current);
                  onChange(id, file);
                }}
              ></Button>
            </>
          );

        case 'png':
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'svg':
          return <Image className="img" src={url} alt={id} />;

        case 'mp4':
          return <video controls src={url} />;

        case 'bin':
        case 'otf':
        case 'ttf':
          return (
            <div>
              <style>{`
                  @font-face {
                      font-family: ${id.replace(/[/-]/g, '').split('.')[0]};
                      src: url(${url});
                  }
              `}</style>
              <div
                style={{
                  fontFamily: id.replace(/[/-]/g, '').split('.')[0],
                  fontSize: '3rem',
                }}
              >
                Ag
              </div>
            </div>
          );

        default:
          return (
            <div className="no-preview">
              <Icon path={mdiFile} className="font-size-32 text-primary" />
              <h6 className="m-0">{t('files.no_preview')}</h6>
            </div>
          );
      }
    },
    [url, value, jsonEditorMode]
  );

  useEffect(() => {
    let mounted = true;
    if (!preview.current) {
      return;
    }

    if (isTextFile(ext)) {
      document.querySelector('.actions .save')?.classList.add('disabled');
      fetch(url)
        .then((resp) => resp.text())
        .then((text) => {
          try {
            const json = JSON.parse(text);
            mounted && setValue(json);
          } catch (e) {
            setValue(text);
          }
        })
        .catch(({ message }) => {
          alertError({
            message: t('files.error_loading'),
            description: message,
            open: true,
          });
        });
    } else if (url) {
      preview.current.firstElementChild &&
        preview.current.firstElementChild.addEventListener(
          'load',
          onLoad,
          false
        );
    }
    return () => {
      mounted = false;
      if (!preview.current) {
        return;
      }
      preview.current.firstElementChild &&
        preview.current.firstElementChild.removeEventListener(
          'load',
          onLoad,
          false
        );
    };
  }, [preview.current, url]);

  return (
    <div className={`file-preview ${className}`.trim()} ref={preview}>
      <Preview ext={ext} id={id} url={url} value={value} />
    </div>
  );
};

export default FilePreview;
export { FilePreview };
