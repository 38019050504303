import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'react-bootstrap';
import { PAGES } from '../../../_config';
import { useExpanded, useProjects } from '../../../hooks';
import Component from './Component';

export const GoogleMap = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { toggleExpanded } = useExpanded();
  const { project, updateProject } = useProjects();

  const { apiKey, center } = props;
  let { zoom } = props;
  let { lat, lng } = center;
  lat = isNaN(lat) ? 39.466667 : lat;
  lng = isNaN(lng) ? -0.375 : lng;
  zoom = isNaN(zoom) ? 12 : zoom;

  const { data } = project;
  const { app } = data;
  const { project_name: projectName } = app;

  const handleSetApiKey = () => {
    const _data = {
      ...data,
      app: {
        ...data.app,
        android: {
          ...(data.app.android || {}),
          maps_api_key: '',
        },
      },
    };
    updateProject({ data: _data });
    toggleExpanded({ path: `/${projectName}/app/android`, expanded: true });
    history.push(`${PAGES.APP}/${projectName}/app`);
  };

  return (
    <>
      {!apiKey && (
        <div className="dialog no-apikey">
          <Card>
            <Card.Body>
              <Card.Title>{t('common.attention')}</Card.Title>
              <Card.Text>
                {parse(t('projects.set_maps_apikey_description'))}
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Button variant="link" onClick={handleSetApiKey}>
                {t('projects.set_maps_apikey')}
              </Button>
            </Card.Body>
          </Card>
        </div>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        center={{ lat, lng }}
        zoom={zoom}
      />
    </>
  );
};

export const MapCompiler = (props) => {
  const { center: _center = '39.466667,-0.375', zoom = 12 } = props;
  const [center, setCenter] = useState({ lat: 39.466667, lng: -0.375 });
  const { lat, lng } = center;
  const { project } = useProjects();
  const { data = {} } = project;
  const { app = {} } = data;
  const { android = {} } = app;
  const { maps_api_key: apiKey = '' } = android;

  useEffect(() => {
    let mounted = true;
    if (_center === '@app.location') {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) =>
          mounted && setCenter({ lat, lng })
      );
    } else {
      let [_lat, _lng] = `${_center}`.includes(',')
        ? _center.split(',')
        : [39.466667, -0.375];
      _lat = parseFloat(_lat, 10);
      _lng = parseFloat(_lng, 10);
      mounted && setCenter({ lat: _lat, lng: _lng });
    }
    return () => (mounted = false);
  }, [_center]);
  return <Map apiKey={apiKey} center={{ lat, lng }} zoom={zoom} />;
};

export const Map = ({ apiKey, center, zoom }) => {
  return (
    <Component
      apiKey={apiKey}
      center={center}
      zoom={zoom}
      component={GoogleMap}
    />
  );
};

export default MapCompiler;
