import React, { useEffect, useState } from 'react';
import ReactLottie from 'react-lottie';
import JSZip from 'jszip';
import Component from './Component';
import { useFile, useSource } from '../../hooks';

export const LottieCompiler = ({
  autoplay = true,
  file,
  loop = false,
  source,
  speed = 1,
  ...props
}) => {
  const [animationData, setAnimationData] = useState();
  const { value = file } = useSource(source);
  const { id, blob } = useFile(value);

  useEffect(() => {
    let mounted = true;
    if (!blob || !mounted) {
      return;
    }
    const ext = id?.split?.('.').pop();
    const getDataFrom = {
      json: async (blob) => {
        if (!mounted) {
          return;
        }
        const text = await blob?.text?.();
        try {
          const data = JSON.parse(text);
          const { w, h, nm } = data;
          if (!w || !h || !nm) return;
          mounted && setAnimationData(data);
        } catch (e) {}
      },
      zip: async (zip) => {
        if (!mounted) {
          return;
        }
        const uncompressedZip = await JSZip.loadAsync(zip);
        const { files } = uncompressedZip;
        const file = Object.values(files)[0];
        const blob = await file.async('blob');
        const ext = file.name.split('.').pop();
        getDataFrom[ext]?.(blob);
      },
    };
    getDataFrom[ext]?.(blob);

    return () => {
      mounted = false;
    };
  }, [blob]);

  const options = {
    animationData,
    autoplay,
    loop,
    speed,
  };

  return <Lottie {...props} options={options} />;
};
export const Lottie = ({ onfinished, options = {}, ...props }) => {
  const { autoplay, loop, speed } = options;
  return (
    <Component {...props}>
      <ReactLottie
        key={JSON.stringify({ autoplay, loop, speed })}
        options={options}
        ref={(el) => el?.setSpeed?.()}
        speed={speed}
      />
    </Component>
  );
};

Lottie.displayName = 'Lottie';
LottieCompiler.displayName = 'Lottie';
export default LottieCompiler;
