import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import ls from 'local-storage';
import { PAGES } from '../../_config';
import { useReferrer, useViewer } from '../../hooks';
import { LoginForm } from '.';
import { logo, logoWhite } from '../../assets/svg/logos';
import { login } from '../../assets/svg/figures';

const LoginPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { refetch } = useViewer();
  const { match } = props;
  const { params } = match;
  const { referrer, token: externalToken } = params;
  const { base = '/', key, logo: referrerLogo = {}, pathname } = useReferrer();
  let user = ls('user') || {};
  let { auth: { token } = {} } = user;

  useEffect(() => {
    if (externalToken) {
      user = { ...user, auth: { token: externalToken } };
      ls('user', user);
      history.replace(PAGES.APP);
      return;
    }

    if (referrer) {
      ls('referrer', { key, url: referrer });
      history.push(PAGES.SIGN_IN);
      return;
    }

    if (token) {
      (async () => {
        await refetch();
        if (base !== '/') {
          window.location.href = `${base}/t/${token}/${pathname}`;
          return;
        }
        history.push(PAGES.DASHBOARD);
      })();
    }
  }, [externalToken, token, referrer]);

  return (
    <main className="login min-height-100vh--lg">
      <header className="position-absolute-top-0 z-index-2">
        <nav className="d-flex justify-content-between align-items-center">
          <div className="col-lg-5 col-xl-4 text-lg-center px-0">
            <a
              className="d-inline-block p-3 p-sm-5"
              href={base}
              aria-label="Doyo"
            >
              <img
                className="d-none d-lg-inline-block"
                src={referrerLogo.white || logoWhite}
                height="42px"
                alt="Logo"
              />
              <img
                className="d-inline-block d-lg-none"
                src={referrerLogo.color || logo}
                height="42px"
                alt="Logo"
              />
            </a>
          </div>
        </nav>
      </header>
      <Row>
        <Col lg={{ span: 6 }} xl={{ span: 5 }} className="d-none d-lg-flex">
          <img src={login} className="w-100" alt="welcome" />
          <div className="gradient-half-secondary" />
        </Col>
        <Col>
          <Container>
            <Row>
              <Col
                md={{ span: 8, offset: 2 }}
                lg={{ span: 7, offset: 2 }}
                xl={{ span: 6, offset: 3 }}
                className="space-3 space-0--lg"
              >
                <div className="my-5">
                  <h2 className="h3 text-primary font-weight-normal mb-0">
                    {t('login.title')}
                  </h2>
                  <p>{t('login.subtitle')}</p>
                </div>
                <LoginForm {...props} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </main>
  );
};

export { LoginPage };
