import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import ls from 'local-storage';
import { PAGES } from '../../_config';
import { useReferrer } from '../../hooks';
import { RegisterForm } from '.';
import { logo, logoWhite } from '../../assets/svg/logos';
import { register } from '../../assets/svg/figures';

export const RegisterPage = (props) => {
  const { t } = useTranslation();
  const { match } = props;
  const { params } = match;
  const { referrer } = params;
  const history = useHistory();
  const { base = '/', key, logo: referrerLogo = {} } = useReferrer();

  useEffect(() => {
    if (referrer) {
      ls('referrer', { key, url: referrer });
      history.push(PAGES.SIGN_UP);
    }
  }, [referrer]);

  return (
    <main className="register min-height-100vh--lg">
      <header className="position-absolute-top-0 z-index-2">
        <nav className="d-flex justify-content-between align-items-center">
          <div className="col-lg-5 col-xl-4 text-lg-center px-0">
            <a
              className="d-inline-block p-3 p-sm-5"
              href={base}
              aria-label="Doyo"
            >
              <img
                className="d-none d-lg-inline-block"
                src={referrerLogo.white || logoWhite}
                height="42px"
                alt="Logo"
              />
              <img
                className="d-inline-block d-lg-none"
                src={referrerLogo.color || logo}
                height="42px"
                alt="Logo"
              />
            </a>
          </div>
        </nav>
      </header>
      <Row>
        <Col lg={{ span: 6 }} xl={{ span: 5 }} className="d-none d-lg-flex">
          <img src={register} className="w-100" alt="welcome" />
          <div className="gradient-half-secondary" />
        </Col>
        <Col>
          <Container>
            <Row>
              <Col
                md={{ span: 8, offset: 2 }}
                lg={{ span: 7, offset: 2 }}
                xl={{ span: 6, offset: 3 }}
                className="space-3 space-0--lg"
              >
                <div className="my-5">
                  <h2 className="h3 text-primary font-weight-normal mb-0">
                    {t('register.title')}
                  </h2>
                  <p>{t('register.subtitle')}</p>
                </div>
                <RegisterForm {...props} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </main>
  );
};

export default RegisterPage;
