import React from 'react';
import ActionBar from './ActionBar';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { Group } from '../components/Group';
import { projectTypes } from '../../../schemas/project';

export const Wrapper = ({
  actionbar,
  children,
  className = '',
  header,
  footer,
  id,
  sidebar,
}) => {
  return (
    <main
      id={id}
      className={`${className} preview ant-layout-content d-flex flex-column flex-grow-1`.trim()}
      data-type={projectTypes.WEB}
    >
      {actionbar && (
        <ActionBar
          key={`ActionBar-${id}`}
          id={`${id}ActionBar`}
          {...actionbar}
        />
      )}
      {header && <Header key={`Header-${id}`} id={`${id}Header`} {...header} />}
      {(sidebar && (
        <Group
          id={`${id}WrapperWithSidebar`}
          direction="horizontal"
          className="h-fill"
        >
          <Sidebar id={`${id}Sidebar`} {...sidebar} />
          {(footer && (
            <div className="d-flex flex-column h-100 overflow-auto w-fill">
              <Group id={`${id}Content`} className="flex-grow-1">
                {children}
              </Group>
              <Footer key={`Footer-${id}`} id={`${id}Footer`} {...footer} />
            </div>
          )) || (
            <Group className="flex-grow-1 h-100 scrollable">{children}</Group>
          )}
        </Group>
      )) ||
        (footer && (
          <>
            <Group id={`${id}Content`} className="flex-grow-1">
              {children}
            </Group>
            <Footer key={`Footer-${id}`} id={`${id}Footer`} {...footer} />
          </>
        )) || (
          <Group id={`${id}Content`} className="flex-grow-1 scrollable">
            {children}
          </Group>
        )}
    </main>
  );
};

export default Wrapper;
