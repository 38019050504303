import gql from 'graphql-tag';

export const LIST_VIEWER_AUTHENTICATIONS = gql`
  query ListViewerAuthentications($first: Int!) {
    viewer {
      authentications(first: $first) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const SHOW_AUTHENTICATIONS = gql`
  query ShowAuthentication($id: ID!) {
    authentication(id: $id) {
      id
      token
    }
  }
`;
