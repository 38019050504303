import { projectTypes } from './project';
import { analytics, colors, events, fonts, theme } from './defs/common';
import { appFlowArrayOfFunctions, colors as flowColors } from './defs/flow';

const commonAppProperties = {
  type: {
    default: projectTypes.MOBILE_APP,
    enum: [
      projectTypes.BACKEND,
      projectTypes.MOBILE_APP,
      projectTypes.FLOW,
      projectTypes.WEB,
    ],
    readOnly: true,
  },
  fonts,
};

export const palettes = [
  {
    accent: '#facb3c',
    primary: '#99cc66',
    primaryDark: '#689b38',
    primaryLight: '#ccff96',
    textOnAccent: '#000000',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#ff1744',
    primary: '#2196f3',
    primaryDark: '#1769aa',
    primaryLight: '#4dabf5',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#ffc400',
    primary: '#03a9f4',
    primaryDark: '#0276aa',
    primaryLight: '#35baf6',
    textOnAccent: '#000000',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#ff9100',
    primary: '#00bcd4',
    primaryDark: '#008394',
    primaryLight: '#33c9dc',
    textOnAccent: '#ffffff',
    textOnPrimary: '#000000',
  },
  {
    accent: '#ffc400',
    primary: '#673ab7',
    primaryDark: '#482880',
    primaryLight: '#8561c5',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#00bcd4',
    primary: '#ff9800',
    primaryDark: '#b26a00',
    primaryLight: '#ffac33',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#ff5722',
    primary: '#8bc34a',
    primaryDark: '#618833',
    primaryLight: '#a2cf6e',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#fdd835',
    primary: '#4a148c',
    primaryDark: '#12005e',
    primaryLight: '#7c43bd',
    textOnAccent: '#000000',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#283593',
    primary: '#2962ff',
    primaryDark: '#0039cb',
    primaryLight: '#768fff',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#f57f17',
    primary: '#2962ff',
    primaryDark: '#0039cb',
    primaryLight: '#768fff',
    textOnAccent: '#000000',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#4caf50',
    primary: '#b71c1c',
    primaryDark: '#7f0000',
    primaryLight: '#f05545',
    textOnAccent: '#000000',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#ffd600',
    primary: '#c51162',
    primaryDark: '#8e0038',
    primaryLight: '#fd558f',
    textOnAccent: '#000000',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#212121',
    primary: '#616161',
    primaryDark: '#373737',
    primaryLight: '#8e8e8e',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#212121',
    primary: '#ffea00',
    primaryDark: '#c7b800',
    primaryLight: '#ffff56',
    textOnAccent: '#000000',
    textOnPrimary: '#000000',
  },
  {
    accent: '#6d4c41',
    primary: '#2e7d32',
    primaryDark: '#005005',
    primaryLight: '#60ad5e',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#1b5e20',
    primary: '#795548',
    primaryDark: '#4b2c20',
    primaryLight: '#a98274',
    textOnAccent: '#ffffff',
    textOnPrimary: '#ffffff',
  },
  {
    accent: '#d81b60',
    primary: '#bdbdbd',
    primaryDark: '#8d8d8d',
    primaryLight: '#efefef',
    textOnAccent: '#ffffff',
    textOnPrimary: '#000000',
  },
  {
    accent: '#80cbc4',
    primary: '#006064',
    primaryDark: '#00363a',
    primaryLight: '#428e92',
    textOnAccent: '#ffffff',
    textOnPrimary: '#000000',
  },
];

export const style = {
  title: 'Style',
  type: 'object',
  required: ['type'],
  properties: {
    ...commonAppProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [projectTypes.BACKEND]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonAppProperties,
      },
    },
    [projectTypes.FLOW]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonAppProperties,
        analytics,
        colors: flowColors,
        events: { ...events, properties: { onready: appFlowArrayOfFunctions } },
        firebase: {
          default: {},
          required: ['api_key', 'app_id', 'project_id'],
          properties: {
            api_key: { type: 'string' },
            app_id: { type: 'string' },
            project_id: { type: 'string' },
          },
        },
        hash: { type: 'string', readOnly: true },
      },
    },
    [projectTypes.MOBILE_APP]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonAppProperties,
        colors,
      },
    },

    [projectTypes.WEB]: {
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        ...commonAppProperties,
        colors,
        theme,
      },
    },
  },
};

export default style;
