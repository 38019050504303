import React, { useEffect } from 'react';
import Component from './Component';
import { useProjects } from '../../../hooks';

export const StripeCheckoutCompiler = (props) => {
  return <StripeCheckout {...props} />;
};
export const StripeCheckout = (props) => {
  const { project = {}, updateProject } = useProjects();
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { api = {} } = app;
  const { stripe = {} } = api;
  const { account_id: accountId = null } = stripe;

  useEffect(() => {
    if (accountId === null) {
      const data = {
        ...projectData,
        app: {
          ...app,
          api: {
            ...api,
            stripe: {
              ...stripe,
              account_id: '',
            },
          },
        },
      };
      updateProject({ data });
    }
  }, [accountId]);

  return (
    <Component
      {...props}
      className="LOADING-container LOADING-double"
      style={{ display: 'flex' }}
    >
      <div className="LOADING-checkoutContainer">
        <div>
          <div className="LOADING-checkoutOverview">
            <div className="LOADING-header">
              <div className="LOADING-backArrow">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.56262 5.2499H11.25C11.6642 5.2499 12 5.58569 12 5.9999C12 6.41412 11.6642 6.7499 11.25 6.7499H2.56262L6.00611 10.1934C6.29927 10.4866 6.29927 10.9619 6.00611 11.255C5.71294 11.5482 5.23763 11.5482 4.94447 11.255L0.21967 6.53023C-0.0732233 6.23734 -0.0732233 5.76247 0.21967 5.46957L4.94447 0.744776C5.23763 0.451611 5.71294 0.451611 6.00611 0.744776C6.29927 1.03794 6.29927 1.51325 6.00611 1.80642L2.56262 5.2499Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              <div className="LOADING-logo LOADING-loads"></div>
            </div>
            <div className="LOADING-productSummary">
              <div className="LOADING-productTitle LOADING-loads"></div>
              <div className="LOADING-productPrice LOADING-loads"></div>
              <div className="LOADING-productImageContainer">
                <div className="LOADING-productImage LOADING-loads"></div>
              </div>
              <div className="LOADING-lineItem">
                <div className="LOADING-lineItem-image LOADING-loads"></div>
                <div className="LOADING-lineItem-lines">
                  <div className="LOADING-lineItem-line LOADING-loads"></div>
                  <div className="LOADING-lineItem-line LOADING-loads"></div>
                </div>
              </div>
              <div className="LOADING-lineItem">
                <div className="LOADING-lineItem-image LOADING-loads"></div>
                <div className="LOADING-lineItem-lines">
                  <div className="LOADING-lineItem-line LOADING-loads"></div>
                  <div className="LOADING-lineItem-line LOADING-loads"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="LOADING-checkoutPayment">
            <div className="LOADING-payment-oneRow LOADING-mb38 LOADING-loads"></div>
            <div className="LOADING-payment-twoRow LOADING-mb38 LOADING-loads"></div>
          </div>
        </div>
        <div className="LOADING-footer">
          <span>Powered by</span>{' '}
          <svg width="33" height="15" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="evenodd">
              <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
            </g>
          </svg>
        </div>
      </div>
    </Component>
  );
};

export default StripeCheckoutCompiler;
