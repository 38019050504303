import React, { useEffect } from 'react';
import parseHtml from 'html-react-parser';
import Component from './Component';
import { camelToKebabCase } from '../../../_helpers';
import { useClasses, useColor, useSource } from '../../hooks';
import {
  getAmountFormat,
  getCurrencyFormat,
  getDateFormat,
  getTextToShow,
  isObject,
} from '../../util';

const styles = {
  title1: 'h1',
  title2: 'h2',
  title3: 'h3',
  paragraph: 'p',
  bold: 'b',
  italic: 'i',
  deleted: 'del',
  underlined: 'u',
  subscript: 'sub',
  superscript: 'sup',
  small: 'small',
  marked: 'mark',
};

const textAligns = {
  start: 'left',
  center: 'center',
  end: 'right',
};

export const TextCompiler = ({
  className = '',
  color,
  field,
  font,
  font_size: fontSize,
  format = {},
  source,
  style: styleProp,
  tag,
  text,
  text_align: textAlign,
  ...props
}) => {
  const { id, itemData } = props;
  const { addClass, style } = useClasses(id);
  const { key: textColor } = useColor(color);
  let classes = {};

  // color
  let textC;
  if (color) {
    const kebabKeyColor = camelToKebabCase(textColor);
    textC = { [`text-${kebabKeyColor}`]: `color: var(--${kebabKeyColor})` };
    classes = { ...classes, ...textC };
  }

  // font
  let ff;
  if (font) {
    ff = { [`ff-${font.replace('@font.', '')}`]: '' };
    classes = { ...classes, ...ff };
  }

  // font_size
  let f;
  if (typeof fontSize !== 'undefined') {
    if (typeof fontSize === 'number') {
      f = { [`f-${fontSize}px`]: `font-size: ${fontSize}px` };
    } else if (fontSize?.match(/%$/)) {
      f = { [`f-${fontSize.replace('%', '')}`]: `font-size: ${fontSize}` };
    } else if (fontSize?.match(/rem$/)) {
      f = { [`f-${fontSize.replace('.', '_')}`]: `font-size: ${fontSize}` };
    }
    classes = { ...classes, ...f };
  }

  // style
  if (styleProp) {
    tag = styles[styleProp];
  }

  // text_align
  let textA;
  if (textAlign) {
    textA = { [`text-${textAlign}`]: `text-align:${textAligns[textAlign]}` };
    classes = { ...classes, ...textA };
  }

  // text
  let textToShow = getTextToShow(text);

  useEffect(() => {
    addClass({ textC });
  }, [color]);
  useEffect(() => {
    addClass({ ff });
  }, [font]);
  useEffect(() => {
    addClass({ f });
  }, [fontSize]);
  useEffect(() => {
    addClass({ textA });
  }, [textAlign]);

  const { type } = format;
  if (type === 'amount') {
    textToShow = getAmountFormat(textToShow, format);
  } else if (type === 'currency') {
    textToShow = getCurrencyFormat(textToShow, format);
  } else if (type === 'date') {
    const dateFromText = new Date(parseInt(textToShow));
    textToShow = getDateFormat(dateFromText, format);
  } else if (type === 'html') {
    textToShow = parseHtml(textToShow);
  }

  return (
    <>
      <style>{style}</style>
      <Text
        {...props}
        className={`${className} ${Object.keys(classes).join(' ')}`.trim()}
        src={field ? itemData[field] : source}
        tag={tag}
      >
        {textToShow}
      </Text>
    </>
  );
};

export const Text = ({
  children: childrenProps = '',
  className = '',
  itemDataSrc,
  src,
  tag: Tag = 'div',
  ...props
}) => {
  let text = childrenProps;
  const { id, itemData: data } = props;
  const { value } = useSource(src, { data });
  text = `${text}`?.replace(
    /\(@field\.([^)]*)\)/g,
    (str, field) => data[field]
  );
  let children =
    text
      .match(/\((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*\)/gm)
      ?.reduce((children, entry, i) => {
        const src = entry.substring(1, entry.length - 1);
        const index = text.indexOf(src);
        const subtext = text.substring(0, index - 1);
        const plain = index > 0 ? [subtext] : [];
        text = text.substr(index + src.length + 1);
        return [
          ...children,
          ...plain,
          <Text key={`${id}-${i}`} src={src} tag="span" />,
        ];
      }, []) || [];

  if (text) {
    children = [...children, text];
  }

  let textToShow = children;
  if (src) {
    textToShow =
      (isObject(itemDataSrc) && `${JSON.stringify(itemDataSrc)}`) ||
      (typeof itemDataSrc !== 'undefined' &&
        itemDataSrc !== null &&
        `${`${itemDataSrc}`.replace(
          /\(@field\.([^)]*)\)/g,
          (str, field) => data[field]
        )}`) ||
      (isObject(value) && `${JSON.stringify(value)}`) ||
      (typeof value !== 'undefined' &&
        value !== null &&
        `${`${value}`.replace(
          /\(@field\.([^)]*)\)/g,
          (str, field) => data[field]
        )}`) ||
      children;
  }
  return (
    <Component
      {...props}
      className={`text ${className}`.trim()}
      component={Tag}
    >
      {textToShow}
    </Component>
  );
};

Text.displayName = 'Text';
TextCompiler.displayName = 'Text';
export default TextCompiler;
