import React, { useEffect, useState } from 'react';
import useFunctions from '../hooks/useFunctions';
import useWrapper from '../hooks/useWrapper';
import { Content } from './components/Component';
import Wrapper from './wrapper';

export const View = ({
  content,
  description,
  events: _events = {},
  history,
  icon,
  id,
  private: _private,
  title,
  wrapper,
}) => {
  const [functionsCalled, setFunctionsCalled] = useState([]);
  const [events, setEvents] = useState([]);
  const { clearMessages, run } = useFunctions();
  const wrapperData = useWrapper(wrapper);

  useEffect(() => {
    clearMessages();
    setFunctionsCalled([]);
  }, [id]);

  useEffect(() => {
    if (_events._equals(events)) {
      return;
    }
    clearMessages();
    setEvents(_events);
    const { onloaded = [] } = events;
    let functions = [...onloaded];
    functions = functions.filter(
      (f) => !functionsCalled.includes(JSON.stringify(f))
    );
    if (!functions.length) {
      return;
    }
    run(functions);
    setFunctionsCalled((functionsCalled) => [
      ...functionsCalled,
      ...functions.map((f) => JSON.stringify(f)),
    ]);
  }, [_events]);

  return (
    <Wrapper {...wrapperData} className="preview view">
      <Content content={content} />
    </Wrapper>
  );
};

export default View;
