import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'just-debounce-it';
import Icon from '@mdi/react';
import { mdiCropPortrait, mdiCropLandscape, mdiImage, mdiVideo } from '@mdi/js';
import { createClient } from 'pexels';
import { Button, message, Popover, Space, Tooltip } from 'antd';
import { SECRETS } from '../_config';
import { getUnique, getUniqueName } from '../_helpers';
import { CirclePreloader, Search } from '.';
import { useNearScreen, useProjects } from '../hooks';

const client = createClient(SECRETS.PEXELS_API_KEY);

const Photos = ({ filter, uploadFiles }) => {
  const { orientation, query } = filter;
  const externalRef = useRef();
  const [error, setError] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const { isNearScreen } = useNearScreen({
    externalRef: loading ? null : externalRef,
    once: false,
  });

  const debounceHandleNextPage = useCallback(
    debounce(() => !error && setPage((prevPage) => prevPage + 1), 200),
    [error, setPage]
  );

  useEffect(() => {
    if (!error && (!total || photos.length < total) && isNearScreen)
      debounceHandleNextPage();
  }, [debounceHandleNextPage, isNearScreen, loading, total, error]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  useEffect(() => {
    let mounted = true;
    page === 1 && setPhotos([]);
    setLoading(true);

    client.photos
      .search({ orientation, query, page, per_page: 10 })
      .then(({ error, total_results: totalResults, photos = [] }) => {
        if (mounted) {
          setLoading(false);
          if (error) {
            message.error('Error');
            setError(true);
            return;
          }
          setError(false);
          setTotal(totalResults);
          setPhotos((prevPhotos) => getUnique([...prevPhotos, ...photos]));
        }
      })
      .catch(() => {
        if (mounted) {
          message.error('Error');
          setLoading(false);
          setError(true);
        }
      });

    return () => {
      mounted = false;
    };
  }, [page, filter]);

  const handleClick = useCallback(
    (event, { id, link }) => {
      const { currentTarget } = event;
      const { parentElement } = currentTarget;
      const { parentElement: photoElement } = parentElement;
      const { firstElementChild: preloader } = photoElement;

      preloader.classList.remove('d-none');

      fetch(link)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const file = new File(
            [arrayBuffer],
            getUniqueName(`${query}_${id}.jpeg`, { isId: true }),
            {
              type: 'image/png',
            }
          );
          uploadFiles([file]);
        });
    },
    [query, uploadFiles]
  );

  return (
    <>
      <div className="d-grid">
        {photos.map((photo) => {
          const {
            height,
            id,
            photographer,
            photographer_url: photographerUrl,
            src,
            url,
            width,
          } = photo;
          const { medium } = src;
          return (
            <Popover
              key={`pexels-photo-${id}`}
              content={
                <Space direction="vertical">
                  {Object.keys(src).map((key) => {
                    let [, , dpr = 1, h = height, , w] =
                      src[key].match(/(dpr=(\d+))?&h=(\d+)(&w=(\d+))?/) || [];
                    w = parseInt((w || (h * width) / height) * dpr, 10);
                    h = parseInt(h * dpr, 10);
                    return (
                      <Button
                        key={`pexels-photo-file-${id}-${key}`}
                        block={true}
                        onClick={(event) =>
                          handleClick(event, { id, link: src[key] })
                        }
                      >
                        {<span>{`${key.toUpperCase()} (${w}x${h})`}</span>}
                      </Button>
                    );
                  })}
                </Space>
              }
            >
              <div className="text-center">
                <img className="w-100" src={medium} alt={`${query} ${id}`} />
                <footer>
                  This{' '}
                  <a
                    href={url}
                    target="_blank"
                    rel="Pexels noopener noreferrer"
                  >
                    photo
                  </a>{' '}
                  was taken by{' '}
                  <a
                    href={photographerUrl}
                    target="_blank"
                    rel="Pexels noopener noreferrer"
                  >
                    {photographer}
                  </a>{' '}
                  on Pexels.
                </footer>
              </div>
            </Popover>
          );
        })}
      </div>
      <div className="w-100 p-3 my-3" ref={externalRef}>
        {loading && <CirclePreloader className="p-3" />}
      </div>
    </>
  );
};

const Videos = ({ filter, uploadFiles }) => {
  const { orientation, query } = filter;
  const externalRef = useRef();
  const [error, setError] = useState(false);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const { isNearScreen } = useNearScreen({
    externalRef: loading ? null : externalRef,
    once: false,
  });

  const debounceHandleNextPage = useCallback(
    debounce(() => !error && setPage((prevPage) => prevPage + 1), 200),
    [error, setPage]
  );

  useEffect(() => {
    if (!error && (!total || videos.length < total) && isNearScreen)
      debounceHandleNextPage();
  }, [debounceHandleNextPage, isNearScreen, loading, total, error]);

  useEffect(() => {
    setPage(1);
  }, [filter]);

  useEffect(() => {
    let mounted = true;
    page === 1 && setVideos([]);
    setLoading(true);

    if (error) {
      return;
    }

    client.videos
      .search({ orientation, query, page, per_page: 10 })
      .then(({ error, total_results: totalResults, videos = [] }) => {
        if (mounted) {
          setLoading(false);
          if (error) {
            message.error('Error');
            setError(true);
            return;
          }
          setError(false);
          setTotal(totalResults);
          setVideos((prevVideos) => getUnique([...prevVideos, ...videos]));
        }
      })
      .catch(() => {
        if (mounted) {
          message.error('Error');
          setLoading(false);
          setError(true);
        }
      });

    return () => {
      mounted = false;
    };
  }, [page, filter]);

  const handleMouseEnter = (event) => {
    const { currentTarget } = event;
    currentTarget.play();
  };

  const handleMouseLeave = (event) => {
    const { currentTarget } = event;
    currentTarget.pause();
  };

  const handleClick = useCallback(
    (event, { id, link }) => {
      const { currentTarget } = event;
      const { parentElement } = currentTarget;
      const { parentElement: videoElement } = parentElement;
      const { firstElementChild: preloader } = videoElement;

      preloader.classList.remove('d-none');

      fetch(link)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          const file = new File(
            [arrayBuffer],
            getUniqueName(`${query}_${id}.mp4`, { isId: true }),
            {
              type: 'video/mp4',
            }
          );
          uploadFiles([file]);
        });
    },
    [query, uploadFiles]
  );

  return (
    <>
      <div className="d-grid">
        {videos.map((video) => {
          const { id, url, user = {}, video_files: videoFiles = [] } = video;
          const { name, url: userUrl } = user;
          const { link: linkSD } =
            videoFiles.find(({ quality }) => quality === 'sd') || videoFiles[0];
          return (
            <Popover
              key={`pexels-video-${id}`}
              content={
                <Space direction="vertical">
                  {videoFiles
                    .filter(({ width }) => width)
                    .sort((v1, v2) => (v1.width < v2.width ? -1 : 1))
                    .map(({ height, id, quality, link, width }) => (
                      <Button
                        key={`pexels-video-file-${id}`}
                        block={true}
                        type={quality === 'sd' ? 'default' : 'primary'}
                        onClick={(event) => handleClick(event, { id, link })}
                      >
                        {(quality === 'sd' && (
                          <span>{`${quality.toUpperCase()} (${width}x${height})`}</span>
                        )) || (
                          <strong>{`${quality.toUpperCase()} (${width}x${height})`}</strong>
                        )}
                      </Button>
                    ))}
                </Space>
              }
            >
              <div className="text-center">
                <video
                  src={linkSD}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
                <footer>
                  This{' '}
                  <a
                    href={url}
                    target="_blank"
                    rel="Pexels noopener noreferrer"
                  >
                    video
                  </a>{' '}
                  was taken by{' '}
                  <a
                    href={userUrl}
                    target="_blank"
                    rel="Pexels noopener noreferrer"
                  >
                    {name}
                  </a>{' '}
                  on Pexels.
                </footer>
              </div>
            </Popover>
          );
        })}
      </div>
      <div className="w-100 p-3 my-3" ref={externalRef}>
        {loading && <CirclePreloader className="p-3" />}
      </div>
    </>
  );
};

export const Pexels = ({ uploadFiles = () => {} }) => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const { data = {} } = project;
  const { app = {} } = data;
  const { name = 'Nature' } = app;
  const [orientation, setOrientation] = useState('landscape');
  const [query, setQuery] = useState(name);
  const [type, setType] = useState('image');
  const filter = { orientation, query, type };

  const handleSearch = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    setQuery(value);
  };

  const handleTypeClick = () => {
    setType((prevType) => (prevType === 'image' ? 'video' : 'image'));
  };

  const handleOrientationClick = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === 'landscape' ? 'portrait' : 'landscape'
    );
  };

  useEffect(() => {
    setQuery(name);
  }, [name]);

  return (
    <>
      <Space className="pexels-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32px"
          height="32px"
          viewBox="0 0 32 32"
          className="me-3"
          style={{
            borderRadius: '8px',
            height: '40px',
            width: '40px',
          }}
        >
          <path
            d="M2 0h28a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
            fill="#05A081"
          ></path>
          <path
            d="M13 21h3.863v-3.752h1.167a3.124 3.124 0 1 0 0-6.248H13v10zm5.863 2H11V9h7.03a5.124 5.124 0 0 1 .833 10.18V23z"
            fill="#fff"
          ></path>
        </svg>
        <Search
          defaultValue={query}
          icon={false}
          onBlur={handleSearch}
          visible={true}
        />
        <Tooltip
          title={t('dropwrapper.search_pexels', {
            context: `${type === 'image' ? 'video' : 'image'}_${orientation}`,
          })}
        >
          <Button shape="circle" type="text" onClick={handleTypeClick}>
            {type === 'image' ? (
              <Icon path={mdiVideo} size={1} />
            ) : (
              <Icon path={mdiImage} size={1} />
            )}
          </Button>
        </Tooltip>
        <Tooltip
          title={t('dropwrapper.search_pexels', {
            context: `${type}_${
              orientation === 'landscape' ? 'portrait' : 'landscape'
            }`,
          })}
        >
          <Button shape="circle" type="text" onClick={handleOrientationClick}>
            {orientation === 'landscape' ? (
              <Icon path={mdiCropPortrait} size={1} />
            ) : (
              <Icon path={mdiCropLandscape} size={1} />
            )}
          </Button>
        </Tooltip>
      </Space>
      <div
        className="pexels w-100"
        data-orientation={orientation}
        data-type={type}
      >
        {type === 'image' ? (
          <Photos filter={filter} uploadFiles={uploadFiles} />
        ) : (
          <Videos filter={filter} uploadFiles={uploadFiles} />
        )}
      </div>
    </>
  );
};
