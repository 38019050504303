import React, { useEffect } from 'react';
import QRCode from 'qrcode.react';
import { useProjects } from '../../../hooks';
import Component from './Component';

export const ScannerCompiler = (props) => {
  return <Scanner {...props} />;
};
export const Scanner = (props) => {
  const { project = {}, updateProject } = useProjects();
  const { data: projectData = {} } = project;
  const { app = {}, external = [] } = projectData;
  const { firebase } = app;
  const isFileUploaded = external.some(({ id }) =>
    ['google-services.json', 'GoogleService-Info.plist'].includes(id)
  );

  useEffect(() => {
    if (!firebase && !isFileUploaded) {
      const data = {
        ...projectData,
        app: {
          ...app,
          firebase: {},
        },
      };
      updateProject({ data });
    }
  }, [firebase, isFileUploaded]);

  return (
    <Component
      {...props}
      value={`${Date.now()}`}
      size={512}
      includeMargin={false}
      component={QRCode}
    />
  );
};

export default ScannerCompiler;
