import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Button, Card, Form, Input, Layout, List, Switch } from 'antd';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { PAGES } from '../../_config';
import { getTextToShow, isObject, mobileCheck } from '../../_helpers';
import { ProviderPanel } from '../../components/Integrations';
import {
  Actions,
  DropWrapper,
  RegisterModal,
  ResizablePanel,
  Sidebar,
  VerificationModal,
} from '../../components';
import { useApi, useProjects } from '../../hooks';
import { getSchema } from '../../schemas';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

const Provider = ({ provider, activeKeys, activate }) => {
  const {
    checkProvider,
    createProjectOnApi,
    currentProvider,
    setCurrentProvider,
    updateProviderConfig,
  } = useApi();
  const { project = {}, updateProject = () => {} } = useProjects();
  let { config, urlLogo, name, label, description, headline, ready } = provider;
  const isActive = activeKeys.includes(name);
  const descriptionToShow = getTextToShow(headline);
  config = JSON.parse(config);
  const [loading, setLoading] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isActive || !project.uid || typeof ready !== 'undefined') {
      return;
    }
    checkProvider(provider);
  }, [ready, checkProvider]);

  const handleActiveProvider = async (provider, isActived) => {
    setLoading((loading) => [...(loading || []), provider]);
    const { name } = provider;
    let _activeKeys = [...new Set([...activeKeys, name])];
    if (!isActived) {
      _activeKeys = _activeKeys.filter((i) => i !== name);
    }
    const { token } = (isActived && (await createProjectOnApi(project))) || {};
    activate(_activeKeys);
    let { data: projectData = {} } = project;
    let newProjectData = projectData._clone();
    newProjectData.app = {
      ...newProjectData.app,
      api: {
        ...(newProjectData?.app?.api || {}),
        ...(token ? { api_key: token } : {}),
        active_providers: _activeKeys,
      },
    };
    updateProject({ data: newProjectData });
    setLoading((loading) => [...(loading || []).filter((p) => p !== provider)]);
  };

  const handleSelectProvider = (event) => {
    setCurrentProvider(provider);
    event.stopPropagation();
  };

  const handleChange = (event, { provider }) => {
    const { target = {} } = event;
    const { name, value } = target;
    if (value.includes('*')) {
      return;
    }
    provider.data = {
      ...provider.data,
      [name]: value,
    };
    updateProviderConfig({
      provider: provider?.name,
      data: provider.data,
    });
  };

  const cardClassName =
    currentProvider === provider ? 'h-100 active-card' : 'h-100';

  return (
    <Card className={cardClassName} hoverable style={{ width: 300 }}>
      <Card.Meta
        avatar={<Avatar shape="square" src={urlLogo} />}
        title={
          <div className="d-flex align-items-center">
            <span className="flex-grow-1">{label}</span>
            <Switch
              loading={!!loading?.includes(provider)}
              checked={activeKeys.includes(name)}
              onChange={(isActived) =>
                handleActiveProvider(provider, isActived)
              }
            />
          </div>
        }
        className="provider-card-data"
        description={descriptionToShow}
      />
      <Button
        className="provider-edit-button"
        onClick={(event) => {
          handleSelectProvider(event);
        }}
      >
        {t('common.edit')}
      </Button>
    </Card>
  );
};

const IntegrationsContent = () => {
  let status = useState(0);
  const listWrapper = useRef();
  const { loadProviders, providers = [], setCurrentProvider } = useApi();
  const { project = {} } = useProjects();
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { api = {} } = app;
  const { active_providers: activeProviders = [] } = api;
  const [activeKeys, activate] = useState(activeProviders);

  /*
  const handlePanelResize = () => {
    const wrapper = listWrapper.current;
    const { width: wrapperWidth = 1000, height: wrapperHeight = 800 } =
      wrapper?.getBoundingClientRect?.() || {};
    let mainWidth = parseInt(resolution);
    let mainHeight = parseInt(resolution.replace(/\d*x/, ''));
    if (projectType === projectTypes.MOBILE_APP) {
      mainWidth = preview === PREVIEW.MOBILE_PORTRAIT ? 354 : 630;
      mainHeight = preview === PREVIEW.MOBILE_PORTRAIT ? 630 : 354;
    }
    const scaleW = Math.min((wrapperWidth - 64) / mainWidth, 1);
    const scaleH = Math.min((wrapperHeight - 64) / mainHeight, 1);
    const scale = Math.min(scaleW, scaleH);
    const main = wrapper?.children[0];
    if (main) {
      main.style.transform = `scale(${scale})`;
    }
  };
*/
  useEffect(() => {
    status = status + 1;
    !activeProviders._equals(activeKeys) && activate(activeProviders);
  }, [activeProviders]);

  useEffect(() => {
    loadProviders();
  }, []);

  const handleProviderPanelClose = () => {
    setCurrentProvider(null);
  };
  /*
  useEffect(() => {
    listWrapper.current && setTimeout(handlePanelResize, 300);
  }, [preview, resolution, value]);
*/
  return (
    <>
      <div className="d-flex h-fill">
        <IntegrationsPanel
          activate={activate}
          activeKeys={activeKeys}
          providers={providers}
          close={handleProviderPanelClose}
        />
        <ProviderPanel activeKeys={activeKeys} listWrapper={listWrapper} />
      </div>
    </>
  );
};

const IntegrationsPanel = ({
  activate,
  activeKeys,
  close: handleClose = () => {},
  providers,
  setCurrentProvider,
}) => {
  /*
  const {
    currentProvider,
    loadProviders,
    providers = [],
    setCurrentProvider,
  } = useApi();
  
  const { project = {} } = useProjects();
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { api = {} } = app;
  const { active_providers: activeProviders = [] } = api;
  const [activeKeys, activate] = useState(activeProviders);

  useEffect(() => {
    !activeProviders._equals(activeKeys) && activate(activeProviders);
  }, [activeProviders]);

  useEffect(() => {
    loadProviders();
  }, []);
  */

  return (
    <>
      <div
        className="providers-wrapper w-fill p-3"
        onClick={(event) => {
          handleClose();
        }}
      >
        <List
          className="project-integrations provider-list"
          grid={{}}
          dataSource={providers}
          renderItem={(provider) => (
            <div className="m-2 py-2 h-100">
              <Provider
                provider={provider}
                activeKeys={activeKeys}
                activate={activate}
              />
            </div>
          )}
        />
      </div>
    </>
  );
};

const ApiKeyInfo = ({ app }) => {
  const { t } = useTranslation();

  if (!(app.api && app.api.api_key)) {
    return null;
  }
  return (
    <div className="w-100 api-key-wrapper p-3">
      <div className="header border-0">
        <small className="ant-card-meta-title">{t('common.api_key')}</small>
      </div>
      <div className="p-3 api-key-value">{app.api.api_key}</div>
    </div>
  );
};

export const IntegrationsPage = ({ match }) => {
  const {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  } = match;
  let path = decodeURIComponent(`/${projectNamePath}${PAGES.INTEGRATIONS}`);
  const {
    project = {},
    projects = [],
    selectProject = () => {},
    unselectProject = () => {},
  } = useProjects();
  const { edges: allProjects = [] } = projects;
  let { data: projectData = {} } = project;
  const { app = {} } = projectData;

  useEffect(() => {
    const { node: projectToSelect } =
      allProjects.find(({ node: { data = {} } }) => {
        const { app = {} } = data;
        const { project_name: name } = app;
        return name === projectNamePath;
      }) || {};
    if (projectToSelect) {
      projectToSelect.id !== project.id && selectProject(projectToSelect);
    } else {
      unselectProject();
    }
  }, [projectNamePath, fileNamePath, allProjects]);

  return (
    <>
      <Layout className="integrations">
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar match={match} path={path} />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ height: '40px' }}>
            <Actions data={{}} />
          </Layout.Header>
          <Layout.Content
            id="WorkingArea"
            className="d-flex flex-column integrations"
          >
            <IntegrationsContent />
          </Layout.Content>
          <ApiKeyInfo app={app} />
        </Layout>
      </Layout>

      <DropWrapper />
      <RegisterModal />
    </>
  );
};

export default IntegrationsPage;
