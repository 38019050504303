import React from 'react';
import parse from 'html-react-parser';
import BootstrapButton from 'react-bootstrap/Button';
import {
  camelToKebabCase,
  getLanguage,
  getTextColor,
  isObject,
} from '../../../_helpers';
import Component from './Component';
import { useColor } from '../../hooks';

const sizes = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

export const ButtonCompiler = ({
  color = 'primary',
  outline = false,
  size: sizeProp,
  text,
  ...props
}) => {
  let { key: variant } = useColor(color);
  variant = camelToKebabCase(variant);
  let size = sizes[sizeProp];
  if (outline) {
    variant = `outline-${variant}`;
  }
  const language = getLanguage();
  let textToShow = text;
  if (isObject(textToShow)) {
    textToShow =
      typeof textToShow[language] !== 'undefined'
        ? textToShow[language]
        : textToShow.default;
  }
  if (typeof textToShow === 'undefined' || textToShow === '') {
    textToShow = '';
  }

  return (
    <Button {...props} color={color} size={size} variant={variant}>
      {textToShow}
    </Button>
  );
};

export const Button = ({ children, color = 'primary', ...props }) => {
  const { id, radius = 4 } = props;
  let { key: background, hex: _color } = useColor(color);
  background = camelToKebabCase(background);
  return (
    <>
      <style>{`
          #${id}.btn-${background} {
            color: ${getTextColor(_color)};
            background-color: var(--${background});
            border-color: var(--${background});
            border-radius: 0.25rem !important;
          }
          #${id}.btn-${background}:hover {
            color: ${getTextColor(_color)};
            background-color: var(--${background});
            border-color: var(--${background});
          }
          #${id}.btn-outline-${background} {
            color: ${_color};
            border-color: ${_color};
          }
          #${id}.btn-outline-${background}:hover {
            color: ${getTextColor(_color)};
            background-color: var(--${background});
          }
          #${id}.btn {
            border-radius: ${radius}px !important;
          }
        `}</style>
      <Component {...props} component={BootstrapButton}>
        {parse(children)}
      </Component>
    </>
  );
};

Button.displayName = 'Button';
ButtonCompiler.displayName = 'Button';
export default ButtonCompiler;
