import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Icon from '@mdi/react';
import { mdiUpload } from '@mdi/js';
import {
  Affix,
  Button,
  Card,
  Divider,
  Input,
  List,
  Space,
  Switch,
  Upload,
  message,
} from 'antd';
import { projectTypes } from '../../schemas/project';
import { useNewProject } from '../../hooks';

const FirebaseFiles = () => {
  const { t } = useTranslation();
  const { external = [], setData = () => {} } = useNewProject();
  const isAndroidUploaded = external.some(({ name }) =>
    name.includes('google-services')
  );
  const isIosUploaded = external.some(({ name }) =>
    name.includes('GoogleService-Info')
  );
  const props = {
    onRemove: (file) => {
      const index = external.indexOf(file);
      const newExternal = external.slice();
      newExternal.splice(index, 1);
      setData({ external: newExternal });
    },
    beforeUpload: (file) => {
      const { name } = file;
      if (
        !['google-services.json', 'GoogleService-Info.plist'].includes(name)
      ) {
        message.error(t('projects.upload_google_services_file_error'));
        return false;
      }
      if (external.some((f) => f.name === name)) {
        message.error(t('projects.upload_google_services_file_error'));
        return false;
      }
      setData({ external: [...external, file] });
      return false;
    },
    fileList: external,
  };

  return (
    <Space direction="vertical">
      <span>FIREBASE FILES</span>
      <small className="text-muted">
        {parse(t('projects.upload_google_services_file'))}
      </small>
      <Upload {...props}>
        <Space direction="vertical">
          {!isAndroidUploaded && (
            <Button icon={<Icon path={mdiUpload} size={0.7} />}>
              Android (google-services.json)
            </Button>
          )}
          {!isIosUploaded && (
            <Button icon={<Icon path={mdiUpload} size={0.7} />}>
              iOS (GoogleService-Info.plist)
            </Button>
          )}
        </Space>
      </Upload>
    </Space>
  );
};

const FirebaseWeb = () => {
  const { setData = () => {}, settings = {} } = useNewProject();
  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    const { json = {} } = settings;
    const { app = {} } = json;
    const { firebase = {} } = app;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            firebase: {
              ...firebase,
              [name]: value,
            },
          },
        },
      },
    });
  };
  const { json = {} } = settings;
  const { app = {} } = json;
  const { firebase = {} } = app;
  const { apiKey = '', appId = '', projectId = '' } = firebase;
  return (
    <Space direction="vertical">
      <span>FIREBASE API KEY</span>
      <Input
        name="apiKey"
        value={apiKey}
        placeholder="XXXXXXXXX"
        onChange={handleChange}
      />
      <span>FIREBASE APP ID</span>
      <Input
        name="appId"
        value={appId}
        placeholder="XXXXXXXXX"
        onChange={handleChange}
      />
      <span>FIREBASE PROJECT ID</span>
      <Input
        name="projectId"
        value={projectId}
        placeholder="XXXXXXXXX"
        onChange={handleChange}
      />
    </Space>
  );
};

const Analytics = () => {
  const { setData = () => {}, settings = {}, type } = useNewProject();

  useEffect(() => {
    if (type === projectTypes.MOBILE_APP) {
      const { json = {} } = settings;
      const { app = {} } = json;
      const { firebase = {} } = app;
      setData({
        settings: {
          ...settings,
          json: {
            ...json,
            app: {
              ...app,
              firebase: {
                ...firebase,
                analytics: true,
              },
            },
          },
        },
      });
    }
  }, []);

  let content = <></>;
  if (type === projectTypes.MOBILE_APP) {
    content = <FirebaseFiles />;
  } else if (type === projectTypes.WEB) {
    const handleChange = (event) => {
      const { target } = event;
      const { value = '' } = target;
      const { json = {} } = settings;
      const { app = {} } = settings;
      const { analytics = {} } = app;
      setData({
        settings: {
          ...settings,
          json: {
            ...json,
            app: {
              ...app,
              analytics: {
                ...analytics,
                tracking_id: value,
              },
            },
          },
        },
      });
    };
    content = (
      <Space direction="vertical">
        <span>TRACKING ID</span>
        <Input placeholder="XXXXXXXXX" onChange={handleChange} />
      </Space>
    );
  }

  return (
    <div className="mt-3">
      <Divider />
      {content}
    </div>
  );
};

const Auth = () => {
  const { setData = () => {}, settings = {}, type } = useNewProject();

  useEffect(() => {
    const { json = {} } = settings;
    const { app = {} } = json;
    const { firebase = {} } = app;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            firebase: {
              ...firebase,
              authentication: true,
            },
          },
        },
      },
    });
  }, []);

  let content = <></>;
  if (type === projectTypes.MOBILE_APP) {
    content = <FirebaseFiles />;
  } else {
    content = <FirebaseWeb />;
  }

  return (
    <div className="mt-3">
      <Divider />
      {content}
    </div>
  );
};

const CloudMessaging = () => {
  const { setData = () => {}, settings = {}, type } = useNewProject();

  useEffect(() => {
    const { json = {} } = settings;
    const { app = {} } = json;
    const { firebase = {} } = app;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            firebase: {
              ...firebase,
              messaging: true,
            },
          },
        },
      },
    });
  }, []);

  let content = <></>;
  if (type === projectTypes.MOBILE_APP) {
    content = <FirebaseFiles />;
  } else {
    content = <FirebaseWeb />;
  }

  return (
    <div className="mt-3">
      <Divider />
      {content}
    </div>
  );
};

const Databases = () => {
  const { setData = () => {}, settings = {} } = useNewProject();
  useEffect(() => {
    const { json = {} } = settings;
    const { app = {} } = json;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            databases: [],
          },
        },
      },
    });
  }, []);
  return <></>;
};

const Firestore = () => {
  const { setData = () => {}, settings = {}, type } = useNewProject();

  useEffect(() => {
    const { json = {} } = settings;
    const { app = {} } = json;
    const { firebase = {} } = app;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            firebase: {
              ...firebase,
              firestore: true,
            },
          },
        },
      },
    });
  }, []);

  let content = <></>;
  if (type === projectTypes.MOBILE_APP) {
    content = <FirebaseFiles />;
  } else {
    content = <FirebaseWeb />;
  }

  return (
    <div className="mt-3">
      <Divider />
      {content}
    </div>
  );
};

const Storage = () => {
  const { setData = () => {}, settings = {}, type } = useNewProject();

  useEffect(() => {
    const { json = {} } = settings;
    const { app = {} } = json;
    const { firebase = {} } = app;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            firebase: {
              ...firebase,
              storage: true,
            },
          },
        },
      },
    });
  }, []);

  let content = <></>;
  if (type === projectTypes.MOBILE_APP) {
    content = <FirebaseFiles />;
  } else {
    content = <FirebaseWeb />;
  }

  return (
    <div className="mt-3">
      <Divider />
      {content}
    </div>
  );
};

const Maps = ({ setSetting }) => {
  const { setData = () => {}, settings = {} } = useNewProject();

  const handleChange = (event) => {
    const { target } = event;
    const { value = '' } = target;
    const { json = {} } = settings;
    const { app = {} } = json;
    const { android = {} } = app;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            android: {
              ...android,
              maps_api_key: value,
            },
          },
        },
      },
    });
  };

  return (
    <div className="mt-3">
      <Divider />
      <Space direction="vertical">
        <span>API KEY</span>
        <Input placeholder="XXXXXXXXX" onChange={handleChange} />
      </Space>
    </div>
  );
};
const Stripe = ({ setSetting }) => {
  const { setData = () => {}, settings = {} } = useNewProject();

  const handleChange = (event) => {
    const { target } = event;
    const { value = '' } = target;
    const { json = {} } = settings;
    const { app = {} } = json;
    const { api = {} } = app;
    const { stripe = {} } = api;
    setData({
      settings: {
        ...settings,
        json: {
          ...json,
          app: {
            ...app,
            api: {
              ...api,
              stripe: {
                ...stripe,
                account_id: value,
              },
            },
          },
        },
      },
    });
  };

  return (
    <div className="mt-3">
      <Divider />
      <Space direction="vertical">
        <span>ACCOUNT ID</span>
        <Input placeholder="XXXXXXXXX" onChange={handleChange} />
      </Space>
    </div>
  );
};

const allSettings = {
  analytics: {
    cover:
      'https://www.gstatic.com/mobilesdk/160505_mobilesdk/discoverycards/2x/analytics.png',
    Component: Analytics,
  },
  auth: {
    cover:
      'https://www.gstatic.com/mobilesdk/160505_mobilesdk/discoverycards/2x/auth.png',
    Component: Auth,
  },
  cloud_messaging: {
    cover:
      'https://www.gstatic.com/mobilesdk/160921_mobilesdk/discoverycards/2x/cloudmessaging.png',
    Component: CloudMessaging,
  },
  databases: {
    cover:
      'https://www.gstatic.com/mobilesdk/200429_mobilesdk/realtime_database2x.png',
    Component: Databases,
  },
  firestore: {
    cover:
      'https://www.gstatic.com/mobilesdk/200429_mobilesdk/cloud_firestore2x.png',
    Component: Firestore,
  },
  maps: {
    cover:
      'https://www.gstatic.com/mobilesdk/160505_mobilesdk/discoverycards/2x/hosting.png',
    Component: Maps,
  },
  storage: {
    cover:
      'https://www.gstatic.com/mobilesdk/160505_mobilesdk/discoverycards/2x/storage.png',
    Component: Storage,
  },
  stripe: {
    cover:
      'https://images.ctfassets.net/fzn2n1nzq965/3AGidihOJl4nH9D1vDjM84/9540155d584be52fc54c443b6efa4ae6/homepage.png?q=80',
    Component: Stripe,
  },
};

export const Settings = () => {
  const { t } = useTranslation();
  const {
    settings = {},
    setData = () => {},
    setStep = () => {},
  } = useNewProject();

  const handleNext = () => {
    setStep((step) => step + 1);
  };

  return (
    <>
      <div className="px-5 py-3">
        <h3>{t('new_project.settings')}</h3>
        <p className="text-muted">{t('new_project.settings_subtitle')}</p>
        <List
          className="new-project-settings mt-4"
          dataSource={Object.entries(allSettings)}
          renderItem={([setting, { Component, cover }]) => {
            return (
              <Card
                style={{ width: 300 }}
                cover={<img src={cover} alt={setting} />}
              >
                <Card.Meta
                  title={
                    <div className="d-flex align-items-center">
                      <span className="flex-grow-1">
                        {t(`settings.${setting}`)}
                      </span>
                      <Switch
                        defaultChecked={settings[setting]}
                        onChange={(checked) => {
                          const _settings = {
                            ...settings,
                            [setting]: true,
                          };
                          if (!checked) {
                            delete _settings[setting];
                          }
                          setData({ settings: _settings });
                        }}
                      />
                    </div>
                  }
                  description={t(`settings.${setting}_description`)}
                />
                {settings[setting] && <Component />}
              </Card>
            );
          }}
        />
      </div>
      <Affix
        offsetBottom={0}
        target={() => document.getElementById('WorkingArea')}
      >
        <div className="bg-white">
          <Divider className="mt-0 mb-2" />
          <div className="px-5 py-3">
            <Button size="large" type="primary" onClick={handleNext}>
              {t('common.next')}
            </Button>
          </div>
        </div>
      </Affix>
    </>
  );
};

export default Settings;
