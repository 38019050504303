import React from 'react';
import ls from 'local-storage';
import { ResizableBox } from 'react-resizable';

export const ResizablePanel = ({
  children,
  className = '',
  handle,
  name,
  onResize,
  ...props
}) => {
  const { minConstraints = [] } = props;
  const [defaultWidth = 0, defaultHeight = 0] = minConstraints;
  const user = ls('user') || {};
  let { _settings = {} } = user;
  let { [`${name}Panel`]: panel = {} } = _settings;
  let { width = defaultWidth, height = defaultHeight } = panel;

  const handlePanelResize = () => {
    onResize?.();
  };

  return (
    <ResizableBox
      {...props}
      className={`${name}-panel ${className}`.trim()}
      width={width}
      height={height}
      handle={<div className={`resize-handler resize-handler-${handle}`}></div>}
      resizeHandles={[handle]}
      onResize={handlePanelResize}
      onResizeStart={(event, data) => {
        const { node: handle } = data;
        handle.classList.add('active');
      }}
      onResizeStop={(event, data) => {
        const { node: handle, size } = data;
        handle.classList.remove('active');
        const { height } = size;
        const user = ls('user') || {};
        let { _settings = {} } = user;
        let { [`${name}Panel`]: panel = {} } = _settings;
        _settings = {
          ..._settings,
          [`${name}Panel`]: { ...panel, height },
        };
        ls('user', { ...user, _settings });
      }}
    >
      {children}
    </ResizableBox>
  );
};
export default ResizablePanel;
