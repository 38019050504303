import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { SourcesForm } from '.';
import { PAGES } from '../../_config';
import { Sidebar } from '../../components';

export const SourcesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  history.replace(PAGES.SUBSCRIPTION);

  return (
    <main className="sources">
      <Sidebar />
      <div className="container-wrapper">
        <div className="position-relative">
          <h4 className="mt-2 mb-3">{t('header.payment_methods')}</h4>
          <hr className="mb-5" />
          <SourcesForm />

          <hr className="border-0 mb-5" />

          <Modal
            button={t('cards.new_card')}
            buttonParameters={{
              color: 'primary',
            }}
            className="sources"
            title={`${t('cards.new_card')}`}
          ></Modal>

          <hr className="my-5" />
        </div>
      </div>
    </main>
  );
};

export default SourcesPage;
