import React from 'react';
import Helmet from 'react-helmet';
import Component from './Component';

export const StripePricingTableCompiler = (props) => {
  return <StripePricingTable {...props} />;
};
export const StripePricingTable = ({
  className = '',
  pricing_table_id: pricingTableId,
  publishable_key: publishableKey,
  ...props
}) => {
  return (
    <Component {...props} className={`position-relative ${className}`.trim()}>
      <Helmet>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>
      <div className="position-absolute w-100 h-100" />
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
      ></stripe-pricing-table>
    </Component>
  );
};

export default StripePricingTableCompiler;
