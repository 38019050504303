import { projectTypes } from './project';
import {
  analytics,
  android,
  api,
  databases,
  events,
  firebase,
  ios,
  langs,
  monetization,
  purchases,
  title,
  text,
} from './defs/common';
import { appFlowArrayOfFunctions } from './defs/flow';
import {
  databases as webDatabases,
  firebase as webFirebase,
  react,
  redirects,
  scripts,
  server,
} from './defs/web';

const {
  properties: { onstart },
} = events;

const commonAppProperties = {
  type: {
    default: projectTypes.MOBILE_APP,
    enum: [
      projectTypes.BACKEND,
      projectTypes.MOBILE_APP,
      projectTypes.FLOW,
      projectTypes.WEB,
    ],
    readOnly: true,
  },
  project_name: { pattern: '^[a-z]+[_a-z0-9]*$' },
  name: title,
  description: text,
};

export const settings = {
  title: 'Settings',
  type: 'object',
  required: ['type'],
  properties: {
    ...commonAppProperties,
  },
  select: { $data: '0/type' },
  selectCases: {
    [projectTypes.BACKEND]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['project_name', 'name', 'description'],
      properties: {
        ...commonAppProperties,
        api,
      },
    },
    [projectTypes.FLOW]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['project_name', 'name', 'description'],
      properties: {
        ...commonAppProperties,
        analytics,
        events: { ...events, properties: { onready: appFlowArrayOfFunctions } },
        firebase: {
          default: {},
          required: ['api_key', 'app_id', 'project_id'],
          properties: {
            api_key: { type: 'string' },
            app_id: { type: 'string' },
            project_id: { type: 'string' },
          },
        },
        hash: { type: 'string', readOnly: true },
      },
    },
    [projectTypes.MOBILE_APP]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['project_name', 'name', 'description'],
      properties: {
        ...commonAppProperties,
        android,
        api,
        ios,
        events: { ...events, properties: { onstart } },
        firebase,
        langs,
        monetization,
        purchases,
        databases,
      },
    },

    [projectTypes.WEB]: {
      additionalProperties: false,
      removeAdditional: true,
      required: ['project_name', 'name', 'default_views', 'description'],
      properties: {
        ...commonAppProperties,
        default_views: {
          additionalProperties: false,
          removeAdditional: true,
          default: {},
          required: ['root'],
          properties: {
            root: { type: 'string', key: 'view' },
            forbidden: { type: 'string', key: 'view' },
            not_found: { type: 'string', key: 'view' },
          },
        },
        analytics,
        api,
        events: { ...events, properties: { onstart } },
        firebase: webFirebase,
        databases: webDatabases,
        langs,
        react,
        redirects,
        scripts,
        server,
      },
    },
  },
};

export default settings;
