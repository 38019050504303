import ls from 'local-storage';

export const EDIT_JSON_MODE = {
  CODE: 'code',
  TREE: 'tree',
};

export const EDIT_MODE = {
  EDIT: 'edit',
  SORT: 'sort',
};

export const PREVIEW = {
  MOBILE_LANDSCAPE: 'MOBILE_LANDSCAPE',
  MOBILE_PORTRAIT: 'MOBILE_PORTRAIT',
  TABLET_LANDSCAPE: 'TABLET_LANDSCAPE',
  TABLET_PORTRAIT: 'TABLET_PORTRAIT',
};

export const RESOLUTION = {
  R1920: '1920x1080',
  R1600: '1600x900',
  R1280: '1280x720',
  R1024: '1024x720',
};

const { welcome } = ls('user') || {};

export const VIEWER = {
  INITIAL_STATE: {
    autoSave: true,
    jsonEditorMode: EDIT_JSON_MODE.TREE,
    mode: EDIT_MODE.EDIT,
    showPreview: true,
    viewer: undefined,
    preview: PREVIEW.MOBILE_PORTRAIT,
    resolution: RESOLUTION.R1920,
    welcome,
  },

  AUTOSAVE: 'VIEWER_AUTOSAVE',
  CREATE: 'VIEWER_CREATE',
  GET: 'VIEWER_GET',
  UPDATE: 'VIEWER_UPDATE',
  ERROR: 'VIEWER_ERROR',
  JSON_EDITOR_MODE: 'VIEWER_JSON_EDITOR_MODE',
  MODE: 'VIEWER_MODE',
  PREVIEW: 'VIEWER_PREVIEW',
  SUBSCRIPTION_MODAL: 'VIEWER_SUBSCRIPTION_MODAL',
  WELCOME: 'VIEWER_WELCOME',
};

export const ViewerReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case VIEWER.AUTOSAVE:
      draft.autoSave = payload.autoSave || !draft.autoSave;
      break;

    case VIEWER.GET:
      draft.viewer = payload.viewer;
      delete draft.error;
      break;

    case VIEWER.ERROR:
      draft.error = payload.error;
      break;

    case VIEWER.JSON_EDITOR_MODE:
      draft.jsonEditorMode =
        payload.jsonEditorMode ||
        (draft.jsonEditorMode === EDIT_JSON_MODE.CODE
          ? EDIT_JSON_MODE.TREE
          : EDIT_JSON_MODE.CODE);
      break;

    case VIEWER.MODE:
      draft.mode =
        payload.mode ||
        (draft.mode === EDIT_MODE.EDIT ? EDIT_MODE.SORT : EDIT_MODE.EDIT);
      break;

    case VIEWER.PREVIEW:
      draft.showPreview =
        typeof payload.preview !== 'undefined'
          ? !!payload.preview
          : !draft.showPreview;
      draft.preview = payload.preview || draft.preview;
      break;

    case VIEWER.RESOLUTION:
      draft.resolution = payload.resolution;
      break;

    case VIEWER.SUBSCRIPTION_MODAL:
      draft.subscriptionModal = payload.show;
      break;

    case VIEWER.WELCOME:
      const user = ls('user') || {};
      ls('user', { ...user, welcome: payload.welcome });
      draft.welcome = payload.welcome;
      break;

    default:
      return draft;
  }
};
