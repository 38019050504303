import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Form } from 'antd';
import { PAGES, SECRETS } from '../../_config';
import { ViewerContext } from '../../context';
import { PasswordInput } from '../../components';

export const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const { viewer = {}, updateUserPassword } = useContext(ViewerContext);
  const { id } = viewer;
  const [state, setState] = useState({
    currentPassword: '',
    newPassword: '',
    captcha: '',
  });
  const recaptchaRef = useRef(false);
  const submitted = useRef(false);

  useEffect(() => {
    if (submitted.current && id) {
      window.$('.close').click();
      updateUserPassword({
        variables: {
          id,
          ...state,
        },
      });
    }
  }, [state]);

  const handleChange = (event) => {
    const target = event.currentTarget;
    const { name, value } = target;
    target.dataset.validate === 'false' && delete target.dataset.validate;
    target.setCustomValidity && target.setCustomValidity('');
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (submitted.current || form.reportValidity() === false) {
      return;
    }
    submitted.current = true;
    if (!state.captcha) {
      recaptchaRef.current.execute();
    }
  };

  return (
    <Form
      id="PasswordForm"
      className="js-validate mt-5"
      onSubmit={handleSubmit}
    >
      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <label
            htmlFor="InputPassword0"
            className="h6 small d-block text-uppercase"
          >
            {t('common.current_password')}
          </label>
          <div className="mb-2">
            <Link to={PAGES.FORGOT} className="small link-muted">
              {t('login.forgot_password')}
            </Link>
          </div>
        </div>
        <PasswordInput
          id="InputPassword0"
          name="currentPassword"
          placeholder="**********"
          onChange={handleChange}
          required
        />
      </Form.Item>

      <Form.Item>
        <label
          htmlFor="InputPassword1"
          className="h6 small d-block text-uppercase"
        >
          {t('common.new_password')}
        </label>
        <PasswordInput
          id="InputPassword"
          name="newPassword"
          onChange={handleChange}
          minLength="8"
          maxLength="72"
        />
      </Form.Item>

      <Form.Item className="mb-5">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
          size="invisible"
          onChange={(captcha) => setState({ ...state, captcha })}
        />
      </Form.Item>

      <Form.Item className="m-0 text-end">
        <Button type="primary" htmlType="submit">
          {t('common.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;
