import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { IdentifierReducer, IDENTIFIER } from './reducers';
import { useAccounts, useAlerts } from '../hooks';
import {
  CREATE_IDENTIFIER,
  SET_IDENTIFIER_AS_DEFAULT,
  DELETE_IDENTIFIER,
} from '../graphql/mutations';
import { LIST_ACCOUNT_IDENTIFIERS } from '../graphql/queries';

export const IDENTIFIER_TYPE = {
  ALIAS: 'ALIAS',
  DOMAIN: 'DOMAIN',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
};

export const VERIFICABLE_IDENTIFIER_TYPE = [
  IDENTIFIER_TYPE.DOMAIN,
  IDENTIFIER_TYPE.EMAIL,
  IDENTIFIER_TYPE.PHONE,
];

export const IdentifierContext = createContext(IDENTIFIER.INITIAL_STATE);

export const IdentifierProvider = ({ children }) => {
  const { account = {} } = useAccounts();
  const { alertError } = useAlerts();
  const { id: accountId } = account;
  const [state, dispatch] = useImmerReducer(
    IdentifierReducer,
    IDENTIFIER.INITIAL_STATE
  );
  const { pageInfo = {} } = state;
  const { endCursor: cursor, hasNextPage } = pageInfo;

  let { fetchMore, loading, refetch } = useQuery(LIST_ACCOUNT_IDENTIFIERS, {
    context: { passport: true },
    variables: { accountId, first: 10 },
    onCompleted: ({ account = {} }) =>
      dispatch({
        type: IDENTIFIER.LIST,
        payload: { identifiers: account.identifiers },
      }),
  });

  const fetchMoreIdentifiers = () => {
    fetchMore({
      context: { passport: true },
      variables: { accountId, cursor, first: 10 },
      updateQuery: ({ account }, { fetchMoreResult }) => {
        let { identifiers } = fetchMoreResult.account;
        dispatch({
          type: IDENTIFIER.LIST,
          payload: { identifiers },
        });
        return identifiers.edges.length
          ? {
              account: {
                ...account,
                identifiers: {
                  ...identifiers,
                  edges: [...account.identifiers.edges, ...identifiers.edges],
                },
              },
            }
          : { account };
      },
    });
  };

  const [createIdentifier, { loading: loadingCreateIdentifier }] = useMutation(
    CREATE_IDENTIFIER,
    {
      context: { passport: true },
      onCompleted: () => fetchMoreIdentifiers(),
      onError: ({ graphQLErrors }) => alertError(graphQLErrors),
    }
  );

  const [setIdentifierAsDefault, { loading: loadingSetIdentifierAsDefault }] =
    useMutation(SET_IDENTIFIER_AS_DEFAULT, {
      context: { passport: true },
      onCompleted: ({ setIdentifierAsDefault }) =>
        dispatch({
          type: IDENTIFIER.SET_IDENTIFIER_AS_DEFAULT,
          payload: { ...setIdentifierAsDefault },
        }),
      onError: (error) =>
        dispatch({ type: IDENTIFIER.ERROR, payload: { error } }),
    });

  const [deleteIdentifier, { loading: loadingDeleteIdentifier }] = useMutation(
    DELETE_IDENTIFIER,
    {
      context: { passport: true },
      onCompleted: ({ deleteIdentifier }) =>
        dispatch({ type: IDENTIFIER.DELETE, payload: { ...deleteIdentifier } }),
      onError: (error) =>
        dispatch({ type: IDENTIFIER.ERROR, payload: { error } }),
    }
  );

  useEffect(() => {
    hasNextPage && fetchMoreIdentifiers();
  }, [pageInfo]);

  loading =
    loading ||
    loadingCreateIdentifier ||
    loadingSetIdentifierAsDefault ||
    loadingDeleteIdentifier;

  if (process.env.NODE_ENV === 'development') {
    console.log('IDENTIFIERS >>>', state);
  }

  return (
    <IdentifierContext.Provider
      value={{
        ...state,
        loading,
        createIdentifier,
        dispatch,
        refetch,
        setIdentifierAsDefault,
        deleteIdentifier,
      }}
    >
      {children}
    </IdentifierContext.Provider>
  );
};

export const IdentifierConsumer = IdentifierContext.Consumer;
export default IdentifierContext;
