// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiBriefcaseOutline,
  mdiCardTextOutline,
  mdiCertificateOutline,
  mdiClipboardArrowDownOutline,
  mdiClose,
  mdiCodeJson,
  mdiDelete,
  mdiFileDelimitedOutline,
  mdiFileExcelBoxOutline,
  mdiFileReplace,
  mdiFolder,
  mdiFolderOpen,
  mdiFormatFont,
  mdiFormatListText,
  mdiApplicationBracesOutline,
  mdiImageOutline,
  mdiMenuDown,
  mdiMenuRight,
  mdiPlus,
  mdiVideoOutline,
  mdiZipBoxOutline,
} from '@mdi/js';
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  List,
  Modal,
  Space,
  Tooltip,
} from 'antd';
import ls from 'local-storage';
import { DropWrapper } from './';
import { PAGES } from '../_config';
import { getTextToShow } from '../_helpers';
import { projectTypes } from '../schemas/project';
import { useAlerts, useTemplates, useProjects } from '../hooks';

const clonableItems = ['dialogs', 'menus', 'view_wrappers', 'views'];
const fileFolders = [{ id: 'certificates' }, { id: 'external' }, { id: 'res' }];
const iconTypes = {
  certificates: mdiCertificateOutline,
  dialogs: mdiCardTextOutline,
  jobs: mdiBriefcaseOutline,
  menus: mdiFormatListText,
  view_wrappers: mdiApplicationBracesOutline,
  views: mdiCodeJson,
  png: mdiImageOutline,
  jpg: mdiImageOutline,
  jpeg: mdiImageOutline,
  mp4: mdiVideoOutline,
  csv: mdiFileDelimitedOutline,
  otf: mdiFormatFont,
  ttf: mdiFormatFont,
  xls: mdiFileExcelBoxOutline,
  xlsx: mdiFileExcelBoxOutline,
  zip: mdiZipBoxOutline,
};

const toggleExpanded = (props, state) => {
  const { parent = {} } = props;
  const { path: parentPath = '' } = parent;
  const { data, expanded, setExpanded } = state;
  const { key } = data;
  const path = `${parentPath}${key ? `/${key}` : ''}`;
  const user = ls('user') || {};
  let { _state = {} } = user;
  const pathData = _state[path] || {};
  _state = {
    ..._state,
    [path]: { ...pathData, expanded: !expanded },
  };
  ls('user', { ...user, _state });
  setExpanded(!expanded);
};

const handleKeyDownItemName = (event, props) => {
  const { currentTarget: input, key } = event;
  const { value } = input;
  if (key === ' ' || (/\d/.test(key) && !value)) {
    event.preventDefault();
    input.reportValidity();
  }
};

const createItem = (antInput, data, props) => {
  const { input } = antInput;
  const { dataset } = input;
  const { prefix = '' } = dataset;
  const { handleItemCreation, mainFolder } = props;

  if (input.reportValidity() === false) {
    return;
  }

  const { value } = input;
  handleItemCreation({
    data: { ...data, key: mainFolder },
    value: `${prefix}${value}`,
  });
};

const handleItemClone = (data, props) => {
  const { handleItemClone, parent } = props;
  const { path } = parent;
  const { value } = data;
  const { id } = value;
  handleItemClone({ id, path });
};

const handleItemDelete = (data, props) => {
  const { handleItemDelete, parent } = props;
  const { path } = parent;
  const { value } = data;
  const { id } = value;
  handleItemDelete({ id, path });
};

const handleItemReplace = (data, props) => {
  const { parent } = props;
  const { path } = parent;
  const { value } = data;
  const { id } = value;
  const input = document.createElement('input');
  input.type = 'file';
  input.onchange = (event) => {
    const { handleItemReplace } = props;
    const { currentTarget } = event;
    const { files } = currentTarget;
    const blob = files[0];
    const url = URL.createObjectURL(blob);
    handleItemReplace({ id, blob, path, url });
  };
  input.click();
};

const Templates = ({ handleClick, loading, templates = [] }) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <List
        className="w-100"
        grid={{
          gutter: 16,
        }}
        dataSource={[{ title: t('projects.from_scratch') }, ...templates]}
        loading={loading}
        renderItem={(item) => {
          const {
            title: titleObj,
            description: descriptionObj,
            screenshot: defaultScreenshot,
            screenshots: screenshotsObj,
          } = item;
          let title = t('templates.empty');
          let description = '';
          let screenshots = [];
          try {
            title = getTextToShow(JSON.parse(titleObj));
            description = getTextToShow(JSON.parse(descriptionObj));
            screenshots = JSON.parse(screenshotsObj);
          } catch (error) {}
          const screenshot = screenshots[0] || defaultScreenshot;
          return (
            <List.Item>
              <Card
                hoverable={!!templates.length}
                style={{ width: 280 }}
                cover={
                  (screenshot && <img alt={title} src={screenshot} />) || (
                    <div style={{ height: 150 }} />
                  )
                }
                onClick={() => templates.length && handleClick(item)}
              >
                <Card.Meta title={title} description={description} />
              </Card>
            </List.Item>
          );
        }}
      />
    </>
  );
};

const TreeItem = (props) => {
  const { data, objectProps, mainFolder } = props;
  const { count = 1, data: propsData, parent = {} } = objectProps;
  const { key: propsKey } = propsData;
  const { value } = data;
  const { id: key, icon: iconItem } = value;
  if (value.children) {
    return <Tree {...props} data={{ key, value: value.children }} />;
  }
  const { path: parentPath = '' } = parent;
  const path = `${parentPath}${propsKey ? `/${propsKey}` : ''}${
    key ? `/${key}` : ''
  }`;
  const parsedKey = isNaN(key) ? key : value.id;
  const ext = parsedKey.split('.').pop();
  const icon =
    iconItem || iconTypes[mainFolder] || iconTypes[ext] || mdiCodeJson;
  const parsedPath = `${parentPath}${propsKey ? `/${propsKey}` : ''}${
    parsedKey ? `/${parsedKey}` : ''
  }`;
  const user = ls('user') || {};
  const { _state = {} } = user;
  const pathData = _state[path] || {};
  const { current = false } = pathData;

  return (
    <div className={`tree-item ${current ? ' tree-item--current' : ''}`}>
      <Link
        className="tree-item-body"
        to={encodeURI(`${PAGES.APP}${parsedPath}`)}
        style={{ paddingLeft: `${count}rem` }}
      >
        <Space align="center">
          <Icon path={icon} className="text-primary" />
          <span>{parsedKey}</span>
        </Space>
      </Link>
      <div className="tree-item-actions">
        {fileFolders.some(({ id }) => path.includes(`${id}/`)) && (
          <Button
            type="link"
            icon={<Icon path={mdiFileReplace} />}
            onClick={(event) => handleItemReplace({ value }, props)}
          />
        )}
        {clonableItems.includes(mainFolder) && (
          <Button
            type="link"
            icon={<Icon path={mdiClipboardArrowDownOutline} />}
            onClick={(event) =>
              handleItemClone({ key: propsKey, value }, props)
            }
          />
        )}
        {propsKey && (
          <Button
            type="link"
            icon={<Icon path={mdiDelete} />}
            onClick={(event) =>
              handleItemDelete({ key: propsKey, value }, props)
            }
          />
        )}
      </div>
    </div>
  );
};

const Tree = (props) => {
  const {
    className,
    count = 1,
    current,
    data: propsData,
    handleItemClone = () => {},
    handleItemCreation = () => {},
    handleItemDelete = () => {},
    handleItemReplace = () => {},
    parent = {},
  } = props;
  const { alertError } = useAlerts();
  const { createProjectFromZip, project = {}, updateProject } = useProjects();
  const { data: projectData } = project;
  const { app = {} } = projectData;
  const { type = projectTypes.MOBILE_APP } = app;
  const { path: parentPath = '' } = parent;
  const [data, setData] = useState(propsData);
  const { key, value } = data;
  const path = `${parentPath}${key ? `/${key}` : ''}`;
  const pathArray = path.split('/').slice(2);
  const mainFolder = pathArray.shift();
  const user = ls('user') || {};
  const { _state = {} } = user;
  const pathData = _state[path] || {};
  let { expanded: localExpanded = false } = pathData;
  localExpanded = localExpanded || !key;
  const [expanded, setExpanded] = useState(localExpanded);
  const { t } = useTranslation();
  let icon = expanded ? mdiFolderOpen : mdiFolder;
  if (count === 2) {
    icon = expanded ? mdiMenuDown : mdiMenuRight;
  }

  useEffect(() => {
    const user = ls('user') || {};
    const { _state = {} } = user;
    const pathData = _state[path] || {};
    let { expanded: localExpanded = false } = pathData;
    localExpanded = localExpanded || !key;
    setExpanded(localExpanded);
  }, [current]);

  const isArray = Array.isArray(value);

  const AddModal = () => {
    const { getTemplates, loading, templates = [] } = useTemplates();

    const [visible, setVisible] = useState(false);
    const antInput = useRef();

    const handleClick = async (template) => {
      if (!template) {
        createItem(antInput.current, data, { ...props, mainFolder, path });
        return;
      }

      const { input } = antInput.current;
      const { dataset } = input;
      const { prefix = '' } = dataset;
      if (input.reportValidity() === false) {
        return;
      }
      const { value } = input;
      const { description, title, url } = template;

      try {
        const cdz = await (await fetch(url)).blob();
        const { data: _data } = await createProjectFromZip(cdz, {
          keyId: `${prefix}${value}`,
          merge: true,
          title,
          description,
        });
        updateProject({ data: _data });
      } catch ({ message }) {
        alertError({ message, open: true });
      }
    };

    return (
      <>
        <Tooltip title={t('projects.new', { context: key })}>
          <Button
            type="text"
            shape="circle"
            icon={<Icon path={mdiPlus} />}
            onClick={() => {
              getTemplates({ subject: mainFolder, type });
              setVisible(true);
            }}
          />
        </Tooltip>
        <Modal
          className={`add-item-modal${
            templates.length ? ' fullscreen-modal' : ''
          }`}
          closeIcon={<Icon path={mdiClose} size={1} />}
          open={visible}
          title={t('projects.new', { context: key })}
          footer={
            templates.length
              ? null
              : [
                  <Button
                    key={`${key}Cancel`}
                    onClick={() => setVisible(false)}
                  >
                    {t('common.cancel')}
                  </Button>,
                  <Button
                    key={`${key}Ok`}
                    type="primary"
                    onClick={() =>
                      createItem(antInput.current, data, {
                        ...props,
                        mainFolder,
                        path,
                      })
                    }
                  >
                    {t('common.create')}
                  </Button>,
                ]
          }
          onCancel={() => setVisible(false)}
        >
          <form>
            <Input
              addonBefore={
                <>
                  <span>{t('common.name')} </span>
                  <small className="text-muted">
                    ({t('projects.name_pattern')})
                  </small>
                </>
              }
              prefix={`${path.replace(/\/[^/]+\/[^/]+\/?/, '')}/`.replace(
                /^\/$/,
                ''
              )}
              data-prefix={`${path.replace(/\/[^/]+\/[^/]+\/?/, '')}/`.replace(
                /^\/$/,
                ''
              )}
              onPressEnter={() =>
                createItem(antInput.current, data, {
                  ...props,
                  mainFolder,
                  path,
                })
              }
              onKeyDown={(event) => handleKeyDownItemName(event, props)}
              required
              autoFocus
              ref={antInput}
            />
          </form>
          <Templates
            handleClick={handleClick}
            loading={loading}
            templates={templates}
          />
        </Modal>
      </>
    );
  };

  const AddFilesModal = () => {
    const [visible, setVisible] = useState(false);

    const _path = pathArray.join('/');
    return (
      <>
        <Tooltip title={t('projects.new', { context: key })}>
          <Button
            type="text"
            shape="circle"
            icon={<Icon path={mdiPlus} />}
            onClick={() => setVisible(true)}
          />
        </Tooltip>
        <Modal
          className="drop-wrapper-modal"
          closeIcon={<Icon path={mdiClose} size={1} />}
          destroyOnClose={true}
          open={visible}
          title={t('projects.new', { context: key })}
          footer={null}
          onCancel={() => setVisible(false)}
        >
          <DropWrapper
            className="object"
            mainFolder={mainFolder}
            folder={_path}
            createItem={createItem}
          />
        </Modal>
      </>
    );
  };

  useMemo(() => {
    setData(propsData);
  }, [propsData]);

  return (
    <div className={`tree${className ? ` ${className}` : ''}`}>
      {key && (
        <div
          className={`tree-item tree-item-folder${
            count === 2 ? ' tree-parent' : ''
          }`}
        >
          <Space
            align="center"
            className="tree-item-body"
            onClick={() =>
              toggleExpanded(props, { data, expanded, setExpanded })
            }
            style={{ paddingLeft: `${count - 1}rem` }}
          >
            <Icon path={icon} />
            <span>{key}</span>
          </Space>
          <div className="tree-item-actions">
            {(fileFolders.some(({ id }) => id === mainFolder) && (
              <AddFilesModal />
            )) || <AddModal />}
          </div>
        </div>
      )}
      {expanded && (
        <div className="tree-wrapper">
          {(Object.keys(value).length &&
            Object.keys(value)
              .sort((key1, key2) => {
                if (isArray) {
                  const value1 = value[key1];
                  const value2 = value[key2];
                  return (value1.children && !value2.children) ||
                    (typeof value1.children === typeof value2.children &&
                      value1.id < value2.id)
                    ? -1
                    : 1;
                }
                return key1 < key2 ? -1 : 1;
              })
              .map((itemKey, i) => (
                <TreeItem
                  key={`tree-item-${itemKey}-${i}`}
                  current={current}
                  data={{
                    key: itemKey,
                    value: value[itemKey],
                  }}
                  mainFolder={mainFolder}
                  count={count + 1}
                  handleItemClone={handleItemClone}
                  handleItemCreation={handleItemCreation}
                  handleItemDelete={handleItemDelete}
                  handleItemReplace={handleItemReplace}
                  parent={{
                    key,
                    value,
                    path: `${parentPath}${key ? `/${key}` : ''}`,
                  }}
                  objectProps={props}
                />
              ))) || (
            <span className="no-childs">
              {t('projects.no', { context: key })}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Tree;
export { Tree };
