import React, { createContext } from 'react';
import { getHeaders } from '.';

export const StripeContext = createContext({});

export const StripeProvider = ({ children }) => {
  const createSessionId = async (parameters) => {
    return {};
    const response = await fetch(`${URL.API}/create-checkout-session`, {
      method: 'POST',
      body: JSON.stringify(parameters),
      headers: getHeaders({ auth: true }),
    });
    const session = await response.json();
    return session;
  };

  return (
    <StripeContext.Provider
      value={{
        createSessionId,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export const StripeConsumer = StripeContext.Consumer;
export default StripeContext;
