import React, { useEffect, useState } from 'react';
import Component from './Component';
import { useFile, useSource } from '../../hooks';

export const ImageCompiler = ({
  className = '',
  file,
  scale,
  source,
  ...props
}) => {
  const { url: fallback = file } = useFile(file);
  className = scale ? `${className} ${scale}` : className;
  return (
    <Image
      {...props}
      className={className}
      src={source || fallback}
      fallback={fallback}
    />
  );
};

export const Image = ({
  className = '',
  itemDataSrc,
  fallback,
  src: propSrc,
  tag: Tag = 'img',
  ...props
}) => {
  const { itemData: data } = props;
  if (itemDataSrc?.[0] === '@') {
    propSrc = itemDataSrc;
  }
  const { value } = useSource(propSrc, { data });
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [value]);

  fallback = encodeURI(
    fallback?.replace(/\(@field\.([^)]*)\)/g, (str, field) => data[field])
  );
  let src =
    (itemDataSrc?.match(/^((blob:)?http|\/static\/)/) && itemDataSrc) ||
    (value?.match(/^((blob:)?http|\/static\/)/) && value) ||
    (propSrc?.match(/^((blob:)?http|\/static\/)/) && propSrc) ||
    fallback;

  if (hasError) {
    src = fallback;
  }

  return (
    <Component
      {...props}
      src={src}
      className={`image ${className}`.trim()}
      component={Tag}
      onError={(e) => {
        if (fallback && e.currentTarget.src !== fallback) {
          setHasError(true);
        }
      }}
    />
  );
};

Image.displayName = 'Image';
ImageCompiler.displayName = 'Image';
export default ImageCompiler;
