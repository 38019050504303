import React from 'react';
import { useSource } from '../../../hooks';
import { getLanguage } from '../../../_helpers';
import { useColor, useFile } from '../../hooks';

const PagerTab = ({ color, icon, id, title }) => {
  const language = getLanguage();
  const { url: iconUrl } = useFile(icon);
  return (
    <li data-tab-id={id} style={{ color }}>
      {iconUrl ? (
        <div
          className="icon"
          style={{
            maskImage: `url(${iconUrl})`,
            WebkitMaskImage: `url(${iconUrl})`,
          }}
        />
      ) : null}
      <span>
        {(title && (title[language] || title.default)) || title || id}
      </span>
    </li>
  );
};

export const Pager = (props) => {
  let {
    background_color: backgroundColorProp = '@color.primary',
    color: fontColor = '@color.textOnPrimary',
    tabs,
    tabs_position: tabsPosition,
    tab_style: tabStyle,
    viewId,
    views: idViews = [],
  } = props;
  const { hex: backgroundColor } = useColor(backgroundColorProp);
  const { hex: color = '' } = useColor(fontColor);
  const { value: viewsData = [] } = useSource('@views');
  const { value: accentColor = '' } = useSource('@color.accent');

  const views = idViews
    .map((idView) => viewsData.find(({ id }) => id === idView))
    .filter((view) => view);
  if (!tabs || !idViews.length) {
    return null;
  }

  return (
    <div
      className="pager"
      data-tabs-position={tabsPosition}
      data-tab-style={tabStyle}
    >
      <div
        className="pager-index"
        style={{ backgroundColor: accentColor }}
      ></div>
      <ul className="pager-tabs w-100" style={{ backgroundColor, color }}>
        {views.map(({ title, icon, id }, index) => (
          <PagerTab
            key={`${id}-${index}`}
            color={color}
            id={id}
            icon={icon}
            title={title}
          />
        ))}
      </ul>
      <script>
        {(() => {
          setTimeout(() => {
            let activeTab = document.querySelector(
              `.pager-tabs > [data-tab-id="${viewId}"`
            );
            if (!activeTab) {
              const firstTab = document.querySelector(
                '.pager-tabs > :first-child'
              );
              firstTab && firstTab.classList.add('active');
              activeTab = firstTab;
            }
            const index = document.querySelector('.pager-index');
            if (index && activeTab) {
              index.style.left = `${activeTab.offsetLeft}px`;
              index.style.width = `${activeTab.offsetWidth}px`;
            }
          }, 100);
        })()}
      </script>
    </div>
  );
};

export default Pager;
