import React from 'react';
import BootstrapLabel from 'react-bootstrap/FormLabel';
import Component from './Component';
import Text from './Text';

export const LabelCompiler = ({ label, ...props }) => {
  return <Label {...props}>{<Text text={label} />}</Label>;
};

export const Label = ({ children, className = '', ...props }) => {
  return (
    <Component
      {...props}
      className={`form-label ${className}`.trim()}
      component={BootstrapLabel}
    >
      {children}
    </Component>
  );
};

Label.displayName = 'Label';
LabelCompiler.displayName = 'Label';
export default LabelCompiler;
