import React from 'react';
import { Layout } from 'antd';
import {
  DropWrapper,
  RegisterModal,
  Sidebar,
  VerificationModal,
} from '../../components';
import { MyProjectsContent } from '.';

export const MyProjectsPage = ({
  match: {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  },
}) => {
  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar />
        </Layout.Sider>
        <Layout>
          <Layout.Content id="WorkingArea">
            <MyProjectsContent />
          </Layout.Content>
        </Layout>
      </Layout>

      <DropWrapper />
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default MyProjectsPage;
