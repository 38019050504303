import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import ls from 'local-storage';
import { message } from 'antd';
import { getHeaders } from '.';
import { PAGES, URL } from '../_config';
import { PublicationReducer, PUBLICATION } from './reducers';
import { useAlerts, useKustodio, useProjects } from '../hooks';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const PUBLICATION_STATE = {
  CANCELLED: 'cancelled',
  FAILED: 'failed',
  DEPLOYING: 'deploying',
  PROCESSING: 'processing',
  PUBLISHED: 'published',
  WAITING: 'waiting',
};

export const PublicationContext = createContext({});

export const getDescription = ({ publication = {}, t }) => {
  let { output = {}, project } = publication;
  try {
    output = JSON.parse(output);
  } catch (e) {}
  const { name: projectName } = project;
  const { validation = {} } = output || {};
  const { errors = [], warnings = [] } = validation;
  const errorTexts = errors.map(
    ({ at, file, type, params }) =>
      `<li to="${PAGES.APP}/${projectName}/${file}">${t(
        `validation.errors.${type}`,
        params
      )}<p>${file} > ${at
        .replace(file.replace(/\//g, '.'), '')
        .substr(1)}</p></li>`
  );
  const warningTexts = warnings.map(
    ({ at, file, type, params }) =>
      `<li to="${PAGES.APP}/${projectName}/${file}">${t(
        `validation.warnings.${type}`,
        params
      )}<p>${file} > ${at
        .replace(file.replace(/\//g, '.'), '')
        .substr(1)}</p></li>`
  );
  return `${
    errorTexts.length
      ? `<b>${t('common.errors')}:</b><ul class="errors">${errorTexts.join(
          ''
        )}</ul>`
      : ''
  }${
    warningTexts.length
      ? `<b>${t(
          'common.warnings'
        )}</b>:<ul class="warnings">${warningTexts.join('')}</ul>`
      : ''
  }`;
};

let init = true;
let timeout = [];
export const PublicationProvider = (props) => {
  const { t } = useTranslation();
  const { alertError, alertInfo, alertSuccess } = useAlerts();
  const { saveProjectToKustodio = () => {} } = useKustodio();
  const { project = {} } = useProjects();
  const [state, dispatch] = useImmerReducer(
    PublicationReducer,
    PUBLICATION.INITIAL_STATE
  );

  const getDeployConfig = async ({ project }) => {
    const { account_id: accountId, uid: projectId } = project;
    try {
      const result = await fetch(
        `${URL.API}/accounts/${accountId}/projects/${projectId}/deploy_config`,
        { headers: getHeaders({ auth: true }) }
      );
      const deployConfig = await result.json();
      if (!result.ok) {
        return;
      }
      dispatch({
        type: PUBLICATION.DEPLOY,
        payload: { deployConfig },
      });
      return deployConfig;
    } catch (error) {
      return {};
    }
  };

  const setDeployConfig = async (config, options = {}) => {
    config = { ...(config || {}), data: { ...(config.data || {}) } };
    const { project } = options;
    const { account_id: accountId, uid: projectId } = project;
    try {
      const result = await fetch(
        `${URL.API}/accounts/${accountId}/projects/${projectId}/deploy_config`,
        {
          body: JSON.stringify(config),
          headers: getHeaders({ auth: true }),
          method: config?.type === 'none' ? 'DELETE' : 'POST',
        }
      );
      const deployConfig = await result.json();
      dispatch({
        type: PUBLICATION.DEPLOY,
        payload: { deployConfig },
      });
      return deployConfig;
    } catch (error) {
      return {};
    }
  };

  const getPublication = async (uid, options = {}) => {
    const { format, project = {} } = options;
    const { account_id: accountId, name, uid: projectId } = project;
    const user = ls('user') || {};
    let { _publication = [] } = user;
    let currentPublication;

    let result;
    try {
      result = await fetch(
        `${URL.API}/accounts/${accountId}/projects/${projectId}/publications/${uid}`,
        { headers: getHeaders({ auth: true }) }
      );
      let { publication, estimations } = await result.json();
      publication = { ...options, ...publication };
      currentPublication = publication;
      publication = {
        ...publication,
        estimations,
        loading: [
          PUBLICATION_STATE.DEPLOYING,
          PUBLICATION_STATE.PROCESSING,
          PUBLICATION_STATE.WAITING,
        ].includes(publication.state),
      };
      dispatch({
        type: PUBLICATION.GET,
        payload: { publication },
      });

      let { deployment, state, result_url: url, version } = publication;

      if (state === PUBLICATION_STATE.FAILED) {
        throw new Error();
      }

      if (state === PUBLICATION_STATE.PUBLISHED) {
        const deployConfig = await getDeployConfig({ project });
        _publication = _publication.map((_publication) =>
          _publication.uid === publication.uid ? publication : _publication
        );
        _publication = _publication.filter(
          ({ hash, state }) => state !== PUBLICATION_STATE.PUBLISHED
        );
        ls('user', { ...user, _publication });
        const isDownload = deployment === 'none';
        if (!isDownload) {
          const { data = {} } = deployConfig || {};
          const { url: amplifyUrl, host = '', folder = '' } = data;
          url = amplifyUrl
            ? amplifyUrl
            : `https://${host}/${folder}`.replace(/\/\//g, '/');
        }
        alertSuccess({
          description: getDescription({ publication, t }),
          message: isDownload
            ? t('projects.exporting_success', {
                format,
                name,
                url,
                version,
              })
            : t(`publish.${deployment}_success`, { name, url, version }),
          open: true,
          qr: isDownload ? { value: url } : undefined,
        });
        try {
          const file = await (await fetch(url)).blob();
          file.name = `${name}.${format}`;
          saveProjectToKustodio({ project, file });
        } catch (e) {}
        return;
      }

      timeout[projectId] && clearTimeout(timeout[projectId]);
      timeout[projectId] = setTimeout(async () => {
        getPublication(options.uid, options);
      }, 5000);

      return;
    } catch (error) {
      if (result?.status === 500 || error.sent?.status === 500) {
        timeout[projectId] && clearTimeout(timeout[projectId]);
        timeout[projectId] = setTimeout(async () => {
          getPublication(options.uid, options);
        }, 5000);
      }
      _publication = _publication.filter(
        ({ hash, state }) => state !== PUBLICATION_STATE.PUBLISHED
      );
      ls('user', { ...user, _publication });
      alertError({
        description: getDescription({ publication: currentPublication, t }),
        message: t('projects.exporting_error', { format, name }),
        open: true,
      });
    }
  };

  const getPublications = async ({ project }) => {
    const { account_id: accountId, uid: projectId } = project;
    try {
      const result = await fetch(
        `${URL.API}/accounts/${accountId}/projects/${projectId}/publications`,
        {
          headers: getHeaders({ auth: true }),
        }
      );
      const resultJson = await result.json();
      const { data: publications } = resultJson;
      dispatch({
        type: PUBLICATION.LIST,
        payload: { publications },
      });
      return resultJson;
    } catch (error) {}
  };

  const getLastPublication = async ({ project }) => {
    const { account_id: accountId, uid: projectId } = project;
    try {
      const result = await fetch(
        `${URL.API}/accounts/${accountId}/projects/${projectId}/publications/last`,
        {
          headers: getHeaders({ auth: true }),
        }
      );
      let resultJson = await result.json();
      let { publication } = resultJson;
      if (
        [
          PUBLICATION_STATE.DEPLOYING,
          PUBLICATION_STATE.PROCESSING,
          PUBLICATION_STATE.WAITING,
        ].includes(publication.state)
      ) {
        getPublication(publication.uid, publication);
      } else {
        dispatch({
          type: PUBLICATION.GET,
          payload: { publication },
        });
      }
      return resultJson;
    } catch (error) {}
  };

  const cancelCurrentPublication = async ({ project }) => {
    const { account_id: accountId, uid: projectId } = project;
    try {
      const result = await fetch(
        `${URL.API}/accounts/${accountId}/projects/${projectId}/publications/current`,
        {
          headers: getHeaders({ auth: true }),
          method: 'DELETE',
        }
      );
      if (!result.ok) {
        throw new Error();
      }
      let resultJson = await result.json();
      let { publication } = resultJson;

      dispatch({
        type: PUBLICATION.GET,
        payload: { publication },
      });

      return resultJson;
    } catch (error) {}
  };

  const createPublication = async ({
    config,
    deploy,
    exportTo: format,
    project,
    sourceId,
  }) => {
    const { name } = project;
    try {
      const { account_id: accountId, data = {}, uid: projectId } = project;
      const { app = {} } = data;
      const map = {
        apk: 'android',
        aab: 'android',
        ipa: 'ios',
        web: 'react',
        react: 'react',
      };
      const { [map[format]]: exportConfig = {} } = app;
      const { version = 1 } = exportConfig;
      const body = JSON.stringify({
        config,
        deploy: deploy && deploy.type !== 'none' ? true : false,
        format,
        version: `${version}`,
        source_id: parseInt(sourceId),
      });

      let result = await fetch(
        `${URL.API}/accounts/${accountId}/projects/${projectId}/publications`,
        {
          body,
          headers: getHeaders({ auth: true }),
          method: 'POST',
        }
      );
      let resulJson;
      if (result?.status === 409) {
        // Conflict
        message.warning(t('errors.publications.conflict'));
        getLastPublication({ project });
        return;
      }

      if (!result.ok) {
        throw new Error();
      }
      resulJson = await result.json();

      let { publication, estimations } = resulJson;
      const { deployment } = publication;
      publication = {
        ...publication,
        estimations,
        loading: [
          PUBLICATION_STATE.DEPLOYING,
          PUBLICATION_STATE.PROCESSING,
          PUBLICATION_STATE.WAITING,
        ].includes(publication.state),
      };
      const isDownload = deployment === 'none';
      const time = dayjs.duration(estimations.time, 'minutes').humanize(false);
      alertInfo({
        dismiss: false,
        message: isDownload
          ? t('projects.exporting', {
              format,
              name,
              time,
            })
          : t(`publish.${deployment}_publishing`, { name, version, time }),
      });

      dispatch({
        type: PUBLICATION.CREATE,
        payload: { publication },
      });

      getPublication(publication.uid, publication);
      return;
    } catch (e) {
      alertError({
        message: t('projects.exporting_error', { format, name }),
        open: true,
      });
    }
    return;
  };

  useEffect(() => {
    const user = ls('user') || {};
    const { _publication: pendingPublications = [] } = user;

    if (pendingPublications.length && init) {
      init = false;
      pendingPublications.forEach((publication) =>
        getPublication(publication.uid, publication)
      );
    }
  }, []);

  useEffect(() => {
    project.uid && getLastPublication({ project });
  }, [project.uid]);

  if (process.env.NODE_ENV === 'development') {
    console.log('PUBLICATIONS >>>', state);
  }

  return (
    <PublicationContext.Provider
      value={{
        ...state,
        cancelCurrentPublication,
        createPublication,
        getDeployConfig,
        getPublication,
        getPublications,
        getLastPublication,
        setDeployConfig,
      }}
    >
      {props.children}
    </PublicationContext.Provider>
  );
};

export const PublicationConsumer = PublicationContext.Consumer;
export default PublicationContext;
