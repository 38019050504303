import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

export const Canceled = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-center py-3">
        <Alert color="danger" className="d-inline-block">
          <h5 className="m-0">
            <span role="img" aria-label="Worried Face">
              😟
            </span>{' '}
            {t('checkout.payment_canceled')}{' '}
            <span role="img" aria-label="Broken Heart">
              💔
            </span>
          </h5>
        </Alert>
      </div>
    </div>
  );
};

export default Canceled;
