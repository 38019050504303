import React, { useCallback, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiCheck,
  mdiClipboardArrowDownOutline,
  mdiClose,
  mdiContentCopy,
  mdiContentPaste,
  mdiDelete,
  mdiDotsVertical,
  mdiFormatQuoteOpen,
  mdiLightningBolt,
  mdiLink,
  mdiLocationEnter,
  mdiPlus,
  mdiSwapHorizontal,
} from '@mdi/js';
import {
  Breadcrumb,
  Button,
  Dropdown,
  Menu,
  Popover,
  Tooltip,
  Typography,
} from 'antd';
import { Sortable } from 'sortablejs';
import { JsonEditor } from '.';
import { getSchema } from '../schemas';
import { getBorderColor, getDescription, isObject } from '../_helpers';
import { ResizablePanel } from '../components';
import { EDIT_MODE } from '../context/reducers';
import { useClipboard, useEvents } from './../hooks';

const { Paragraph } = Typography;

const NodeItemBg = () => {
  return (
    <svg className="node-item-bg" viewBox="0 0 160 80">
      <g
        className="first-child"
        transform="matrix(0.398783,0,0,0.398783,-49.0085,-81.1931)"
      >
        <path d="M122.895,243.724C122.895,221.565 140.858,203.602 163.017,203.602C259.967,203.602 499.039,203.602 499.039,203.602L499.039,277.368L524.115,299.49L499.039,320.968L499.039,404.212L163.017,404.212C140.858,404.212 122.895,386.249 122.895,364.09C122.895,329.127 122.895,278.687 122.895,243.724Z" />
        <path d="M122.895,243.724C122.895,221.565 140.858,203.602 163.017,203.602C259.967,203.602 499.039,203.602 499.039,203.602L499.039,277.368L524.115,299.49L499.039,320.968L499.039,404.212L163.017,404.212C140.858,404.212 122.895,386.249 122.895,364.09C122.895,329.127 122.895,278.687 122.895,243.724Z" />
      </g>
      <g
        className="default"
        transform="matrix(0.398783,0,0,0.398783,-49.0085,-81.1931)"
      >
        <path d="M122.895,203.602L499.039,203.602L499.039,277.368L524.115,299.49L499.039,320.968L499.039,404.212L122.895,404.212L122.895,323.475L150.479,299.49L122.895,274.861L122.895,203.602Z" />
        <path d="M122.895,203.602L499.039,203.602L499.039,277.368L524.115,299.49L499.039,320.968L499.039,404.212L122.895,404.212L122.895,323.475L150.479,299.49L122.895,274.861L122.895,203.602Z" />
      </g>
      <g
        className="last-child"
        transform="matrix(0.398783,0,0,0.398783,-49.0085,-81.1931)"
      >
        <path d="M122.895,203.602L483.993,203.602C494.634,203.602 504.839,207.829 512.364,215.353C519.888,222.878 524.115,233.083 524.115,243.724C524.115,278.687 524.115,329.127 524.115,364.09C524.115,374.731 519.888,384.936 512.364,392.461C504.839,399.985 494.634,404.212 483.993,404.212C382.379,404.212 122.895,404.212 122.895,404.212L122.895,323.475L150.479,299.49L122.895,274.861L122.895,203.602Z" />
        <path d="M122.895,203.602L483.993,203.602C494.634,203.602 504.839,207.829 512.364,215.353C519.888,222.878 524.115,233.083 524.115,243.724C524.115,278.687 524.115,329.127 524.115,364.09C524.115,374.731 519.888,384.936 512.364,392.461C504.839,399.985 494.634,404.212 483.993,404.212C382.379,404.212 122.895,404.212 122.895,404.212L122.895,323.475L150.479,299.49L122.895,274.861L122.895,203.602Z" />
      </g>
      <g
        className="alone"
        transform="matrix(0.398783,0,0,0.398783,-49.0085,-81.1931)"
      >
        <path d="M122.895,243.724C122.895,233.083 127.122,222.878 134.646,215.353C142.171,207.829 152.376,203.602 163.017,203.602C239.891,203.602 407.119,203.602 483.993,203.602C494.634,203.602 504.839,207.829 512.364,215.353C519.888,222.878 524.115,233.083 524.115,243.724C524.115,278.687 524.115,329.127 524.115,364.09C524.115,374.731 519.888,384.936 512.364,392.461C504.839,399.985 494.634,404.212 483.993,404.212C407.119,404.212 239.891,404.212 163.017,404.212C152.376,404.212 142.171,399.985 134.646,392.461C127.122,384.936 122.895,374.731 122.895,364.09C122.895,329.127 122.895,278.687 122.895,243.724Z" />
        <path d="M122.895,243.724C122.895,233.083 127.122,222.878 134.646,215.353C142.171,207.829 152.376,203.602 163.017,203.602C239.891,203.602 407.119,203.602 483.993,203.602C494.634,203.602 504.839,207.829 512.364,215.353C519.888,222.878 524.115,233.083 524.115,243.724C524.115,278.687 524.115,329.127 524.115,364.09C524.115,374.731 519.888,384.936 512.364,392.461C504.839,399.985 494.634,404.212 483.993,404.212C407.119,404.212 239.891,404.212 163.017,404.212C152.376,404.212 142.171,399.985 134.646,392.461C127.122,384.936 122.895,374.731 122.895,364.09C122.895,329.127 122.895,278.687 122.895,243.724Z" />
      </g>
    </svg>
  );
};

const NodeItem = ({
  _key: key,
  getValue,
  getParent,
  changeValue: changeParent = () => {},
  deleteItem: parentDeleteItem = () => {},
  getProjectRefs,
  projectType,
  mode,
  color: parentColor,
}) => {
  const { t } = useTranslation();
  const { store } = useClipboard();
  const {
    addEventObject,
    currentEvent,
    setCurrentEvent,
    eventPopoverState,
    setEventPopoverState,
  } = useEvents();
  let value = getValue(key);
  const element = useRef();
  const parent = getParent();
  const {
    path: parentPath = '',
    value: parentValue = {},
    schema: parentSchema,
  } = parent;
  const { default: { id: defaultParentId } = {} } = parentSchema || {};
  const path = `${parentPath}/${key}`;
  const isParentArray = Array.isArray(parentValue);
  const schema = getSchema({ path: key, projectType, value }, parentSchema);
  const { description = '', properties = {}, visible = true } = schema;
  const { items = [] } = parentSchema;
  const { properties: itemProperties = {}, selectCases = {} } = items;
  const { function: { enum: functionsEnum = [] } = {} } = itemProperties;
  const options = functionsEnum.length
    ? functionsEnum
    : Object.keys(properties).filter(
        (key) => !Object.keys(value).includes(key)
      );
  const isArray = Array.isArray(value);
  const color =
    (isArray && !isNaN(key)) ||
    (key === 'what' && Array.isArray(value)) ||
    key === 'if' ||
    key === 'else' ||
    key.match(/^on/)
      ? parentColor
      : getBorderColor(
          value?.function
            ? `${value?.function}${value?.function}${value?.function}${value?.function}`
            : `${key}${key}${key}${key}`
        );

  const changeItem = useCallback(
    ({ key: newKey = key, value: newValue }, options = {}) => {
      const { changes } = options;

      if (key === 'if' && !newValue?.else.length) {
        delete newValue.else;
      }
      changeParent(
        { key, newKey, value: newValue },
        {
          ...(isObject(options) ? options : {}),
          changes: changes || {
            key,
            value,
            newValue,
            type: defaultParentId,
          },
        }
      );
    },
    [changeParent, defaultParentId, key, value]
  );

  const handleOpenPopoverChange = (open, key) => {
    let newEventPopoverState = eventPopoverState._clone();
    newEventPopoverState = {
      ...newEventPopoverState,
      [key]: { [path]: open },
    };
    if (!open) {
      delete newEventPopoverState[key][path];
    }
    setTimeout(() => {
      setEventPopoverState(newEventPopoverState);
    }, 0);
  };

  const changeComments = (
    { key: newKey = key, value: comments },
    options = {}
  ) => {
    let _value = value._clone();
    _value = { ..._value, ...comments };
    changeItem({ key, value: _value }, options);
  };
  const deleteComments = () => {
    let _value = value._clone();
    delete _value.comments;
    handleOpenPopoverChange(false, 'comments');
    changeItem({ value: _value });
  };

  const changeCondition = (
    { key: newKey = key, value: newValue },
    options = {}
  ) => {
    let _value = value._clone();
    if (options === 'delete') {
      _value = { ..._value, if: newValue };
    } else {
      _value = { ..._value, if: { ...(_value?.if || {}), ...newValue } };
    }
    changeItem({ key, value: _value }, options);
  };
  const deleteCondition = () => {
    let _value = value._clone();
    delete _value.if;
    handleOpenPopoverChange(false, 'condition');
    changeItem({ value: _value });
  };

  let params = Object.keys(properties).filter(
    (propKey) =>
      !['__expanded', 'comments', 'function', 'id', 'if'].includes(propKey)
  );
  const callbacks = Object.keys(properties).filter((propKey) =>
    propKey.match(/^on/)
  );
  const requiredGroupKeys = Object.keys(properties).filter(
    (propKey) => properties[propKey].requiredGroup
  );
  const requiredGroupValues = requiredGroupKeys.map(
    (propKey) => properties[propKey]
  );
  if (
    requiredGroupKeys.length &&
    !Object.keys(value).some((valueKey) => requiredGroupKeys.includes(valueKey))
  ) {
    const defaultKey = requiredGroupKeys[0];
    const { default: defaultValue = '' } = requiredGroupValues[0];
    value = {
      ...value,
      [defaultKey]: defaultValue,
    };
  }

  const cloneItem = useCallback(() => {
    const index = parentValue.indexOf(value);
    const newValue = value._clone();
    newValue._resetIds();
    const _value = [
      ...parentValue.slice(0, index + 1),
      newValue,
      ...parentValue.slice(index + 1),
    ];
    changeParent({ value: _value });
  }, [changeParent, parentValue, value]);

  const deleteItem = useCallback(() => {
    parentDeleteItem({ key });
  }, [key, parentDeleteItem]);

  let { id: eventId, function: func } = value || {};
  eventId = eventId || (func && `${func}${Date.now()}`);
  const active = currentEvent?.path === path;

  if (
    !visible ||
    (!eventId &&
      !key.match(/^on/) &&
      (key !== 'what' || !Array.isArray(value)) &&
      (!['if', 'else'].includes(key) || (key === 'if' && !value?.else)))
  ) {
    return null;
  }

  if (
    (key === 'what' && Array.isArray(value)) ||
    key === 'else' ||
    key.match(/^on/)
  ) {
    setTimeout(
      () =>
        addEventObject({
          data: { key, value: value || [] },
          parent: { path: parentPath, value: parentValue },
        }),
      0
    );
  }

  const comments = (isParentArray && value?.comments) || '';
  let condition = isParentArray && value?.if?._clone();
  let conditionDescription = t('common.condition');
  if (!!condition) {
    const conditionSchema = getSchema(
      { path: 'if', projectType, value: condition },
      schema
    );
    conditionDescription = getDescription({
      schema: conditionSchema,
      value: condition,
    });

    const getAndOrDescription = (condition) => {
      let description = '';
      if (condition?.and) {
        const andConditionSchema = getSchema(
          { path: 'and', projectType, value: condition.and },
          conditionSchema
        );
        let andDescription = getDescription({
          schema: andConditionSchema,
          value: condition.and,
        });
        const moreDescription = getAndOrDescription(condition.and);
        andDescription = moreDescription
          ? `(${andDescription} ${moreDescription})`.trim()
          : andDescription;
        description = `${description} ${andDescription}`.trim();
      }
      if (condition?.or) {
        const andConditionSchema = getSchema(
          { path: 'or', projectType, value: condition.or },
          conditionSchema
        );
        let orDescription = getDescription({
          schema: andConditionSchema,
          value: condition.or,
        });
        const moreDescription = getAndOrDescription(condition.or);
        orDescription = moreDescription
          ? `(${orDescription} ${moreDescription})`.trim()
          : orDescription;
        description = `${description} ${orDescription}`.trim();
      }
      return description;
    };
    conditionDescription += ` ${getAndOrDescription(condition)}`;

    if (condition?.else) {
      condition.else = condition.else.filter((item) => item);
      value = { ...value, if: condition };
    }
  }

  const functionsMenu = (
    <Menu
      onClick={({ key, keyPath, domEvent: event }) => {
        event.stopPropagation();
        const { default: def = { id: `${key}${Date.now()}`, function: key } } =
          properties[key] || selectCases[key] || {};
        let value = def._clone();
        changeItem({ value });
      }}
      items={options.map((key) => ({
        key,
        label: key,
      }))}
    />
  );
  const dropdownItems = [
    ...(callbacks.length
      ? [
          { type: 'divider' },
          {
            key: 'events',
            label: t('common.events'),
            type: 'group',
            children: callbacks.map((callback) => {
              setTimeout(
                () =>
                  addEventObject({
                    data: { key: callback, value: value[callback] || [] },
                    parent: { path, value },
                  }),
                0
              );
              return {
                key: callback,
                label: t(`events.${callback}`),
                icon: <Icon path={mdiLocationEnter} />,
                onClick: () =>
                  setCurrentEvent({
                    data: { key: callback, value: value[callback] || [] },
                    parent: { path, value },
                  }),
              };
            }),
          },
        ]
      : []),
    { type: 'divider' },
    {
      key: 'condition',
      label: t('common.condition'),
      type: 'group',
      children: [
        {
          key: 'if',
          label: t('functions.condition'),
          icon: <Icon path={mdiLightningBolt} />,
          onClick: () => handleOpenPopoverChange(true, 'condition'),
        },
      ],
    },
    { type: 'divider' },
    {
      key: 'edit',
      label: t('common.edit'),
      type: 'group',
      children: [
        {
          key: 'comments',
          label: t('common.comments'),
          icon: <Icon path={mdiFormatQuoteOpen} />,
          onClick: () => handleOpenPopoverChange(true, 'comments'),
        },
        {
          key: 'change',
          icon: <Icon path={mdiSwapHorizontal} className="visibility-hidden" />,
          label: (
            <Dropdown
              overlay={functionsMenu}
              overlayClassName="node-new-item-menu"
              placement="bottomLeft"
              trigger={['click']}
              onClick={(event) => event.stopPropagation()}
              arrow
            >
              <span>{t('common.change')}</span>
            </Dropdown>
          ),
        },
        {
          key: 'clone',
          label: t('common.clone'),
          icon: (
            <Icon
              path={mdiClipboardArrowDownOutline}
              className="visibility-hidden"
            />
          ),
          onClick: cloneItem,
        },
        {
          key: 'copy',
          label: t('common.copy'),
          icon: <Icon path={mdiContentCopy} className="visibility-hidden" />,
          onClick: () => {
            const _value = value._clone();
            store(_value);
          },
        },
        {
          key: 'delete',
          danger: true,
          label: t('common.delete'),
          icon: <Icon path={mdiDelete} className="visibility-hidden" />,
          onClick: deleteItem,
        },
      ],
    },
  ];
  const dropdownMenu = <Menu items={dropdownItems} />;

  const functionType = value?.function;
  let functionDescription = Object.keys(properties)
    .reduce((res, prop) => {
      const labelPattern = new RegExp(`\\{${prop}}`);
      const label = typeof value[prop] !== 'undefined' ? prop : '';
      const pattern = new RegExp(`\\{{${prop}}}`);
      const _value =
        typeof value[prop] !== 'undefined'
          ? (isObject(value[prop]) &&
              (value[prop].default || Object.values(value[prop])[0])) ||
            value[prop]
          : '';
      return res
        .replace(pattern, `${_value}`)
        .replace(labelPattern, `${label}`);
    }, description)
    .trim();
  if (
    functionType === functionDescription ||
    functionDescription === '[object Object]'
  ) {
    functionDescription = '';
  }

  const eventParams = params.filter((key) => key.match(/^on/));
  params = params.filter((key) => !key.match(/^on/));
  const keyBlacklist = ['comments', 'function', 'if', ...eventParams];
  if (value?.what && Array.isArray(value?.what)) {
    keyBlacklist.push('what');
    params = params.filter((key) => key !== 'what');
  }

  return (
    <div
      className={`node-item${active ? ' active' : ''}`}
      data-event-id={eventId}
      data-key={key}
      data-has-children={
        !!(
          condition?.else ||
          (value?.what && Array.isArray(value?.what)) ||
          eventParams.length
        )
      }
      ref={element}
      style={{ color }}
    >
      {value?.function && (
        <>
          <NodeItemBg />
          <div className="node-item-content">
            <div className="node-item-icons">
              <Popover
                title={
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{t('common.comments')}</span>
                    {!!comments && (
                      <Button
                        shape="circle"
                        type="text"
                        danger={true}
                        onClick={deleteComments}
                      >
                        <Icon path={mdiDelete} size={0.8} />
                      </Button>
                    )}
                  </div>
                }
                content={
                  <JsonEditor
                    _key={'comments'}
                    mode="edit"
                    value={{ comments }}
                    parent={{
                      ...parent,
                      path: `${path}/comments`,
                      schema: getSchema(
                        { path: 'comments', projectType, value: comments },
                        schema
                      ),
                    }}
                    onChange={changeComments}
                    getProjectRefs={getProjectRefs}
                    projectType={projectType}
                  />
                }
                trigger="click"
                open={eventPopoverState?.comments?.[path]}
                onOpenChange={(open) =>
                  handleOpenPopoverChange(open, 'comments')
                }
                overlayClassName="event-comments"
                overlayStyle={{ width: '300px' }}
                transitionName="none"
                maskTransitionName="none"
                destroyTooltipOnHide={false}
              >
                {!!comments && (
                  <Tooltip title={comments || t('common.comments')}>
                    <Button
                      className="node-item-comment-button"
                      shape="circle"
                      type="text"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Icon
                        className="node-item-comment-icon"
                        path={mdiFormatQuoteOpen}
                      />
                    </Button>
                  </Tooltip>
                )}
              </Popover>
              <Popover
                title={
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{t('common.condition')}</span>
                    {!!condition && (
                      <Button
                        shape="circle"
                        type="text"
                        danger={true}
                        onClick={deleteCondition}
                      >
                        <Icon path={mdiDelete} size={0.8} />
                      </Button>
                    )}
                  </div>
                }
                content={
                  <>
                    <JsonEditor
                      _key={'if'}
                      mode="edit"
                      value={condition || { what: '' }}
                      parent={{
                        ...parent,
                        path: `${path}/if`,
                        schema: getSchema(
                          { path: 'if', projectType, value: condition },
                          schema
                        ),
                      }}
                      onChange={changeCondition}
                      getProjectRefs={getProjectRefs}
                      projectType={projectType}
                      keyBlacklist={['else']}
                    />
                    {!!condition && (
                      <div className="mt-3 text-end">
                        <Button
                          icon={
                            <Icon
                              className="me-2"
                              path={mdiLocationEnter}
                              size={0.8}
                            />
                          }
                          onClick={() => {
                            handleOpenPopoverChange(false, 'condition');
                            setCurrentEvent({
                              data: {
                                key: 'else',
                                value: condition?.else || [],
                              },
                              parent: { path: `${path}/if` },
                              getProjectRefs,
                              projectType,
                              onChange: (params) =>
                                changeCondition({
                                  ...params,
                                  value: { else: params.value },
                                }),
                            });
                          }}
                        >
                          else
                        </Button>
                      </div>
                    )}
                  </>
                }
                trigger="click"
                open={eventPopoverState?.condition?.[path]}
                onOpenChange={(open) =>
                  handleOpenPopoverChange(open, 'condition')
                }
                overlayClassName="event-condition"
                transitionName="none"
                maskTransitionName="none"
                destroyTooltipOnHide={false}
              >
                {!!condition && (
                  <Tooltip title={conditionDescription}>
                    <Button
                      className="node-item-condition-button"
                      shape="circle"
                      type="text"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Icon
                        className="node-item-condition-icon"
                        path={mdiLightningBolt}
                      />
                    </Button>
                  </Tooltip>
                )}
              </Popover>
            </div>
            <Popover
              title={value?.function}
              content={
                <>
                  <JsonEditor
                    _key={key}
                    mode="edit"
                    value={value}
                    parent={{ ...parent, path, schema }}
                    onChange={changeItem}
                    getProjectRefs={getProjectRefs}
                    projectType={projectType}
                    keyBlacklist={keyBlacklist}
                  />
                  {value?.what && Array.isArray(value?.what) && (
                    <div className="mt-3 text-end">
                      <Button
                        icon={
                          <Icon
                            className="me-2"
                            path={mdiLocationEnter}
                            size={0.8}
                          />
                        }
                        onClick={() => {
                          handleOpenPopoverChange(false, 'params');
                          setCurrentEvent({
                            data: { key: 'what', value: value?.what || [] },
                            parent: { path, value },
                          });
                        }}
                      >
                        {t('functions.do_what')}
                      </Button>
                    </div>
                  )}
                </>
              }
              trigger="click"
              open={params.length && eventPopoverState?.params?.[path]}
              onOpenChange={(open) => {
                if (!params.length) {
                  if (value?.what && Array.isArray(value?.what)) {
                    setCurrentEvent({
                      data: { key: 'what', value: value?.what || [] },
                      parent: { path, value },
                    });
                  }
                  return;
                }
                handleOpenPopoverChange(open, 'params');
              }}
              overlayClassName="event-params"
              transitionName="none"
              maskTransitionName="none"
              destroyTooltipOnHide={false}
            >
              <small
                className={`node-type ${eventId ? 'drag-handler' : ''}`.trim()}
              >
                <span>{functionType}</span>
                <Tooltip title={functionDescription} placement="rightTop">
                  <small>{functionDescription}</small>
                </Tooltip>
              </small>
            </Popover>
            <Dropdown
              overlay={dropdownMenu}
              overlayClassName="node-item-menu"
              placement="bottomRight"
              trigger={['click']}
            >
              <Button
                className="node-item-menu-button"
                shape="circle"
                type="text"
                onClick={(event) => event.stopPropagation()}
              >
                <Icon path={mdiDotsVertical} size={1} />
              </Button>
            </Dropdown>
          </div>
        </>
      )}
      {(key === 'else' ||
        key.match(/^on/) ||
        (key === 'what' && Array.isArray(value))) && (
        <Tooltip title={key}>
          <Button
            className="node-event-wrapper-zoom"
            type="text"
            onClick={(event) => {
              event.stopPropagation();
              setCurrentEvent({
                data: { key, value },
                parent,
                getProjectRefs,
                projectType,
                onChange: changeItem,
              });
            }}
          >
            <Icon
              className="node-event-wrapper-zoom-icon"
              path={mdiLocationEnter}
              size={1}
            />
          </Button>
        </Tooltip>
      )}
      {isArray && (
        <NodeEventEditor
          _key={key}
          mode={mode}
          value={value}
          parent={{ ...parent, path, schema }}
          onChange={changeItem}
          getProjectRefs={getProjectRefs}
          projectType={projectType}
          color={color}
          newItem={!condition}
        />
      )}
    </div>
  );
};
NodeItem.displayName = 'NodeItem';

const NewEvent = ({
  parent,
  changeItem,
  createItem,
  projectType,
  value,
  color,
}) => {
  const { clipboard, reset } = useClipboard();
  const { schema } = parent;
  const { items = {}, properties = {} } = schema;
  const { properties: itemProperties = {}, selectCases = {} } = items;
  const { function: { enum: functionsEnum = [] } = {} } = itemProperties;
  const options = functionsEnum.length
    ? functionsEnum
    : Object.keys(properties).filter(
        (key) => !Object.keys(value).includes(key)
      );
  const canPaste =
    !!clipboard?.[0]?.function || !!clipboard?.[0]?.[0]?.function;

  const handlePaste = (event) => {
    let value = clipboard[0];
    value = value._clone();
    value._resetIds();
    event.stopPropagation();
    reset();
    createItem({ value });
  };

  const menu = (
    <Menu
      onClick={({ key, keyPath, domEvent: event }) => {
        event.stopPropagation();
        const { default: def = { id: `${key}${Date.now()}`, function: key } } =
          properties[key] || selectCases[key] || {};
        let value = def._clone();
        createItem?.({ key, value });
      }}
      items={options.map((key) => ({
        key,
        label: key,
      }))}
    />
  );

  return (
    <div className="node-item node-new-item" data-paste={canPaste}>
      <Dropdown
        overlay={menu}
        overlayClassName="node-new-item-menu"
        placement="bottomRight"
        trigger={['click']}
      >
        <Button className="node-new-item-plus" type="text">
          <Icon path={mdiPlus} size={1} />
        </Button>
      </Dropdown>
      <Button className="node-new-item-paste" type="text" onClick={handlePaste}>
        <Icon path={mdiContentPaste} size={1} />
      </Button>
    </div>
  );
};

export const LinkItem = ({ to }) => {
  return (
    <Link to={to}>
      <Icon path={mdiLink} />
    </Link>
  );
};

export const NodeEventEditor = ({
  _key,
  value,
  mode,
  getProjectRefs,
  projectType,
  onChange = () => {},
  parent = {},
  color = '#cccccc',
  newItem = true,
}) => {
  const { path = '', schema } = parent;
  const sortable = useRef();
  const wrapper = useRef();
  const getValue = useCallback((key) => value[key], [value]);

  const createItem = useCallback(
    ({ key: newKey, value: newValue }, type = 'create') => {
      let _value = value._equals({}) ? [] : value._clone() || [];
      const arrayValue = Array.isArray(newValue) ? newValue : [newValue];
      _value = [..._value, ...arrayValue];
      onChange({ value: _value }, type);
    },
    [onChange, path, value]
  );

  const changeValue = useCallback(
    ({ key, newKey, value: newValue }, options = {}) => {
      const { type = 'change' } = options;

      newKey = typeof newKey !== 'undefined' ? newKey : key;
      let _value = value._clone();
      if (newKey) {
        _value[newKey] =
          typeof newValue !== 'undefined' ? newValue : _value[key];
      } else if (newValue) {
        _value = newValue;
      }
      if (key !== newKey) {
        if (type === 'change') {
          delete _value[key];
        }
      }
      onChange({ value: _value }, options);
    },
    [value, onChange, path]
  );

  const deleteItem = useCallback(
    ({ key }, type = 'delete') => {
      let _value = value._clone();
      if (Array.isArray(_value)) {
        key = parseInt(key, 10);
        _value = _value.filter((item, i) => i !== key);
      } else {
        delete _value[key];
      }
      onChange({ value: _value }, type);
    },
    [onChange, value]
  );

  const getParent = useCallback(() => {
    return { ...parent, value, schema };
  }, [parent, value, schema]);

  useEffect(() => {
    if (!Array.isArray(value)) {
      return;
    }
    sortable.current = Sortable.create(wrapper.current, {
      multiDrag: false,
      delay: 100,
      handle: '.drag-handler',
      selectedClass: 'selected',
      fallbackTolerance: 3,
      filter: '.node-new-item',
      group: { name: 'actions', pull: true, put: ['actions'] },
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      onUpdate: (event) => {
        const { oldIndex, newIndex } = event;
        const _value = value._clone();
        _value._move(oldIndex, newIndex);
        onChange({ value: _value });
      },
    });

    return () => {
      sortable.current && sortable.current.destroy();
    };
  }, [value]);

  let valueKeys = value ? Object.keys(value) : [];

  return (
    <>
      <div className="node-event-wrapper" ref={wrapper} role="tree">
        {
          <>
            {valueKeys.map((itemKey, i) => (
              <NodeItem
                _key={itemKey}
                getValue={getValue}
                getParent={getParent}
                getProjectRefs={getProjectRefs}
                projectType={projectType}
                changeValue={changeValue}
                deleteItem={deleteItem}
                key={`NodeItem-${itemKey}-${i}-${Date.now()}`}
                mode={mode}
                color={color}
              />
            ))}
          </>
        }
      </div>
      {newItem && (
        <NewEvent
          parent={parent}
          createItem={createItem}
          projectType={projectType}
          value={value}
          color={color}
        />
      )}
    </>
  );
};
NodeEventEditor.displayName = 'NodeEventEditor';

const EventBreadcrumbs = ({ _key: key, mainValue, parentPath }) => {
  const { eventObjects, selectEventObject } = useEvents();
  const arrayPath = `${parentPath}/${key}`.split('/');
  return (
    <Breadcrumb separator=">">
      {arrayPath.map((key, index) => {
        const isLast = index === arrayPath.length - 1;
        const _parentPath = parentPath.split('/').splice(0, index).join('/');
        const isClickable = eventObjects.some(
          ({ data, parent }) =>
            data?.key === key && parent?.path === _parentPath
        );
        const parentValue = _parentPath
          .split('/')
          .reduce((value, key) => value?.[key] || {}, mainValue);

        const keyToShow = isNaN(key)
          ? key
          : parentValue?.[key]?.function ||
            parentValue?.[key]?.id ||
            parentValue?.[key]?.type ||
            key;

        if (key === 'content' && index) {
          return null;
        }
        return (
          <Breadcrumb.Item
            className={
              isClickable && !isLast ? 'cursor-pointer text-primary' : ''
            }
            key={key}
            onClick={() => selectEventObject({ path: _parentPath, key })}
          >
            {keyToShow}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

const BackToPrevEventButton = ({ _key: key }) => {
  const { backToPrevEvent, eventHistory } = useEvents();
  if (!eventHistory.length) {
    return null;
  }

  return (
    <div className="node-back-item">
      <Button type="text" onClick={() => backToPrevEvent()}>
        <Icon path={mdiArrowLeft} size={1} />
      </Button>
    </div>
  );
};

export const EventEditorWrapper = ({
  className = '',
  close: handleClose = () => {},
  data = {},
  mode = EDIT_MODE.SORT,
  parent = {},
  onChange = () => {},
  getProjectRefs,
  projectType,
  mainData,
}) => {
  const { t } = useTranslation();
  const { store } = useClipboard();
  const { value: mainValue } = mainData;
  const element = useRef();
  const { key, value: _value = {} } = data;
  let value = _value._clone();
  const { path: parentPath, schema: parentSchema } = parent;
  const schema = getSchema(
    {
      path: key,
      projectType,
      value,
    },
    parentSchema
  );
  parent = { ...parent, schema };

  const handleCopyTimeline = () => {
    const _value = value._clone();
    store(_value);
  };

  return (
    <>
      <div className="header">
        <EventBreadcrumbs
          _key={key}
          mainValue={mainValue}
          parentPath={parentPath}
        />
        <div className="flex-grow-1 ms-2">
          <Paragraph
            className="m-0"
            copyable={{
              icon: [
                <Button
                  key="timelineCopyIcon"
                  onClick={handleCopyTimeline}
                  shape="circle"
                  type="text"
                >
                  <Icon path={mdiContentCopy} size={0.8} />
                </Button>,
                <Icon
                  key="timelineCopiedIcon"
                  className="ms-1"
                  path={mdiCheck}
                  size={0.8}
                />,
              ],
              tooltips: [t('common.copy'), t('common.copied')],
            }}
          />
        </div>
        <div className="close" onClick={handleClose}>
          <Icon path={mdiClose} size={1} />
        </div>
      </div>
      <div
        className={`node-event-wrapper ${className}`.trim()}
        data-mode={mode}
        ref={element}
      >
        <BackToPrevEventButton _key={key} />
        <NodeEventEditor
          _key={key}
          value={value}
          mode={mode}
          parent={{ path: `${parentPath}/${key}`, schema, value }}
          onChange={onChange}
          getProjectRefs={getProjectRefs}
          projectType={projectType}
        />
      </div>
    </>
  );
};
EventEditorWrapper.displayName = 'EventEditorWrapper';

export const EventPanel = ({ data, previewWrapper, onResize }) => {
  const { currentEvent, eventObjects, setCurrentEvent, setEventHistory } =
    useEvents();
  const location = useLocation();

  const handleEventPanelClose = () => {
    document
      .querySelectorAll(`[data-event-id].active`)
      ?.forEach((el) => el.classList.remove('active'));
    eventObjects.current = [];
    setEventHistory([]);
    setCurrentEvent(null);
  };

  useEffect(() => {
    handleEventPanelClose();
  }, [location]);

  useEffect(() => {
    previewWrapper?.current && setTimeout(onResize, 300);
  }, [currentEvent]);

  return (
    <ResizablePanel
      name="event"
      className={`w-100 ${!!currentEvent ? 'opened' : ''}`.trim()}
      axis="y"
      handle="n"
      minConstraints={[0, 180]}
      maxConstraints={[0, 800]}
      onResize={onResize}
    >
      <>
        {!!currentEvent && (
          <EventEditorWrapper
            {...currentEvent}
            className="p-3"
            close={handleEventPanelClose}
            mainData={data}
          />
        )}
      </>
    </ResizablePanel>
  );
};

export default EventEditorWrapper;
