import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { PAGES, URL } from '../_config';
import { useAccounts, useViewer } from '../hooks';

export const ModalAlert = () => {
  const history = useHistory();
  const { account = {} } = useAccounts();
  const { customer } = account;
  const { email: customerEmail = '' } = customer || {};
  const { i18n, t } = useTranslation();
  const { showSubscriptionModal, subscriptionModal } = useViewer();
  const { message: msg } = subscriptionModal || {};
  const [open, setOpen] = useState(false);
  const title = <>{t('subscription.modal.title')}</>;
  const message =
    msg ||
    (i18n.exists('subscription.modal.description') && (
      <>{t('subscription.modal.description')}</>
    ));
  const button = i18n.exists('subscription.modal.button') && (
    <>{t('subscription.modal.button')}</>
  );

  useEffect(() => {
    setOpen(subscriptionModal);
  }, [subscriptionModal]);

  return (
    <Modal
      open={open}
      title={title}
      okText={button}
      onOk={() => {
        if (customer) {
          const a = document.createElement('a');
          a.href = `${URL.SUBSCRIPTION}?prefilled_email=${customerEmail}`;
          a.target = '_blank';
          a.click();
        } else {
          history.push(PAGES.UPGRADE_PLANS);
        }
        showSubscriptionModal(false);
      }}
      onCancel={() => showSubscriptionModal(false)}
    >
      {message}
    </Modal>
  );
};

export const SubscriptionAlerts = () => {
  return (
    <>
      <ModalAlert />
    </>
  );
};
export default SubscriptionAlerts;
