import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { message } from 'antd';
import { getHeaders } from '.';
import { PAGES, URL } from '../_config';
import { ApiReducer, API } from './reducers';
import { useProjects } from '../hooks';
import { loadApiFunctions } from '../schemas';

export const ApiContext = createContext({});

export const ApiProvider = (props) => {
  const { loadApiRefs = () => {}, project = {}, updateProject } = useProjects();
  const [state, dispatch] = useImmerReducer(ApiReducer, API.INITIAL_STATE);
  const [currentProvider, setCurrentProvider] = useState();

  const checkProvider = useCallback(
    async (provider) => {
      try {
        const { name } = provider;
        const result = await fetch(
          `${URL.INTEGRATIONS}/projects/${project?.uid}/config?provider=${name}`,
          {
            headers: getHeaders({ auth: true }),
          }
        );
        const { ready } = await result.json();
        dispatch({ type: API.CHECK_PROVIDER, payload: { provider, ready } });
      } catch (error) {}
    },
    [project]
  );

  const createApiToken = useCallback(
    async (project) => {
      try {
        const { uid: projectId } = project;
        const result = await fetch(
          `${URL.INTEGRATIONS}/projects/${projectId}/apikey`,
          {
            headers: getHeaders({ auth: true }),
            method: 'POST',
          }
        );
        const projectApi = await result.json();
        const { token } = projectApi;
        const { data } = project;
        const _data = {
          ...data,
          app: {
            ...data.app,
            api: {
              ...data.app.api,
              api_key: token,
            },
          },
        };
        updateProject({ data: _data, project });
      } catch (error) {}
    },
    [project]
  );

  const createProjectOnApi = useCallback(
    async (project) => {
      try {
        const { account_id: accountId, uid: projectId } = project;
        const result = await fetch(`${URL.INTEGRATIONS}/projects`, {
          headers: getHeaders({ auth: true }),
          method: 'POST',
          body: JSON.stringify({
            account_id: accountId,
            project_id: projectId,
          }),
        });
        const projectApi = await result.json();
        const { token } = projectApi;
        if (token) {
          const { data } = project;
          const _data = {
            ...data,
            app: {
              ...data.app,
              api: {
                ...data.app.api,
                api_key: token,
              },
            },
          };
          updateProject({ data: _data, project });
        }
        dispatch({ type: API.CREATE_PROJECT, payload: { projectApi } });
        return { token };
      } catch (error) {
        dispatch({
          type: API.CREATE_PROJECT,
          payload: { projectApi: undefined },
        });
      }
    },
    [project]
  );

  const loadProviders = useCallback(async () => {
    try {
      const result = await fetch(`${URL.INTEGRATIONS}/providers`, {
        headers: getHeaders({ auth: true }),
      });
      const providers = await result.json();
      dispatch({ type: API.PROVIDERS_LIST, payload: { providers } });
    } catch (error) {}
  }, []);

  const loadProjectApi = useCallback(
    async (project) => {
      const { data = {} } = project;
      const { app = {} } = data;
      const { api = {} } = app;
      const { active_providers: activeProviders = [] } = api;
      try {
        const result = await fetch(
          `${URL.INTEGRATIONS}/providers?expand=${activeProviders.join(',')}`,
          { headers: getHeaders({ auth: true }) }
        );
        const json = await result.json();
        const { refs, functions } = json.reduce((schema, provider) => {
          const { methods = [], name: providerName } = provider;
          return methods.reduce((result, method) => {
            const { functions = {}, refs = [] } = result;
            let { config, name: methodName, reference } = method;
            config = config && JSON.parse(config);
            return {
              functions: {
                ...functions,
                [`${providerName}.${methodName}`]: config || {},
              },
              refs: [
                ...refs,
                ...(reference ? [`${providerName}.${methodName}`] : []),
              ],
            };
          }, schema);
        }, {});
        loadApiFunctions(functions);
        loadApiRefs(refs);
      } catch (error) {
        loadApiFunctions();
        loadApiRefs();
      }
    },
    [project?.uid, project?.data?.app?.api?.active_providers]
  );

  const updateProviderConfig = async (body) => {
    await fetch(`${URL.INTEGRATIONS}/projects/${project?.uid}/config`, {
      headers: getHeaders({ auth: true }),
      method: 'PATCH',
      body: JSON.stringify(body),
    });
  };

  useEffect(() => {
    project.uid && loadProjectApi(project);
  }, [project.uid]);

  if (process.env.NODE_ENV === 'development') {
    console.log('API >>>', state);
  }

  return (
    <ApiContext.Provider
      value={{
        ...state,
        checkProvider,
        createApiToken,
        createProjectOnApi,
        currentProvider,
        loadProviders,
        updateProviderConfig,
        setCurrentProvider,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export const ApiConsumer = ApiContext.Consumer;
export default ApiContext;
