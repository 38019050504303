import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { useQuery } from '@apollo/react-hooks';
import { SHOW_LOCATION } from '../graphql/queries';
import { GeneralReducer, GENERAL } from './reducers';

export const GeneralContext = createContext(GENERAL.INITIAL_STATE);

export const GeneralProvider = ({ children }) => {
  const [state, dispatch] = useImmerReducer(
    GeneralReducer,
    GENERAL.INITIAL_STATE
  );

  useQuery(SHOW_LOCATION, {
    context: { passport: true },
    onCompleted: ({ location }) => {
      dispatch({ type: GENERAL.GET_LOCATION, payload: { location } });
    },
  });

  if (process.env.NODE_ENV === 'development') {
    console.log('GENERAL >>>', state);
  }

  return (
    <GeneralContext.Provider value={{ ...state }}>
      {children}
    </GeneralContext.Provider>
  );
};

export const GeneralConsumer = GeneralContext.Consumer;
export default GeneralContext;
