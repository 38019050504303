import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiTrayFull, mdiViewCarouselOutline } from '@mdi/js';
import { message } from 'antd';
import { URL } from '../_config';
import { getUnique } from '../_helpers';
import { getHeaders } from '../context';
import { templates } from '../schemas/defs/common';

export const templateSubjects = {
  PROJECT: 'project',
  VIEWS: 'views',
};
export const projectSubjectIcon = {
  [templateSubjects.PROJECT]: mdiTrayFull,
  [templateSubjects.VIEWS]: mdiViewCarouselOutline,
};

const { properties = {} } = templates;
const {
  subject: { enum: subjects },
  type: { enum: types },
} = properties;

export const useTemplates = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const { per_page: perPage, subject, data = [], type } = state;

  const createTemplate = async (template) => {
    const { title, description, screenshots, subject, type } = template;
    const body = JSON.stringify({
      ...template,
      title: JSON.stringify(title),
      description: JSON.stringify(description),
      screenshots: JSON.stringify(screenshots),
    });

    if (body.match(/(""|\\"\\")/)) {
      message.error(t('templates.empty_fields'));
      return;
    }
    if (!subjects.includes(subject)) {
      message.error(t('templates.invalid_subject'));
      return;
    }
    if (!types.includes(type)) {
      message.error(t('templates.invalid_type'));
      return;
    }
    setState((state) => ({ ...state, loading: true }));
    const result = await fetch(`${URL.API}/templates`, {
      method: 'POST',
      body,
      headers: {
        'content-type': 'application/json',
        ...getHeaders({ auth: true }),
      },
    });
    const json = await result.json();
    if (!result.ok) {
      message.error(t(`validation.errors.${data.type}`));
      setState((state) => ({ ...state, loading: false }));
      throw new Error();
    }
    setState((state) => {
      let { data } = state;
      data = [...data, json];
      return { ...state, data, loading: false };
    });
    message.success(t('templates.template_created'));
    return data;
  };

  const getTemplates = async ({
    cursor,
    mounted = true,
    subject,
    type,
    per_page = perPage,
  }) => {
    if (!mounted) {
      return;
    }
    setState((state) => ({ ...state, loading: true }));
    const parametersObj = {};
    if (typeof subject !== 'undefined' && subject !== '') {
      parametersObj.subject = subject;
    }
    if (typeof type !== 'undefined' && type !== '') {
      parametersObj.type = type;
    }
    if (cursor) {
      parametersObj.cursor = cursor;
    }
    if (per_page) {
      parametersObj.per_page = per_page;
    }
    const parameters = Object.entries(parametersObj)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    try {
      const result = await fetch(`${URL.API}/templates?${parameters}`, {
        headers: { accept: 'application/json' },
      });
      const json = await result.json();
      setState((state) => ({
        ...parametersObj,
        ...state,
        ...json,
        subject,
        type,
        data: getUnique([...(state.data || []), ...(json?.data || [])], 'uid'),
      }));
    } catch (error) {
      setState((state) => ({ ...state, loading: false }));
    }
    mounted && setState((state) => ({ ...state, loading: false }));
  };

  const updateTemplate = async (template) => {
    setState((state) => ({ ...state, loading: true }));
    const { title, description, screenshots, uid, url } = template;
    const body = {
      title: JSON.stringify(title),
      description: JSON.stringify(description),
      screenshots: JSON.stringify(screenshots),
      url,
    };
    const result = await fetch(`${URL.API}/templates/${uid}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
        ...getHeaders({ auth: true }),
      },
    });
    const json = await result.json();
    if (!result.ok) {
      message.error(t(`validation.errors.${json.type}`));
      setState((state) => ({ ...state, loading: false }));
      throw new Error();
    }
    setState((state) => {
      let { data } = state;
      data = data.map((template) =>
        template.uid === json.uid ? { ...template, ...json } : template
      );
      return { ...state, data, loading: false };
    });
    message.success(t('templates.template_saved'));
    return json;
  };

  const deleteTemplate = async (json) => {
    setState((state) => ({ ...state, loading: true }));
    const { uid } = json;
    await fetch(`${URL.API}/templates/${uid}`, {
      method: 'DELETE',
      headers: getHeaders({ auth: true }),
    });
    setState((state) => {
      let { data } = state;
      data = data.filter((template) => template.uid !== uid);
      return { ...state, data, loading: false };
    });
    message.success(t('templates.template_deleted'));
  };

  const filteredTemplates = data.filter((t) => {
    return (!subject || t.subject === subject) && (!type || t.type === type);
  });

  if (process.env.NODE_ENV === 'development') {
    console.log('TEMPLATES >>>', { ...state, templates: filteredTemplates });
  }

  return {
    ...state,
    templates: filteredTemplates,
    createTemplate,
    deleteTemplate,
    getTemplates,
    updateTemplate,
  };
};

export default useTemplates;
