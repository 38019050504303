import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { Button, Dropdown, Menu, Space, Switch, Tooltip } from 'antd';
import Icon from '@mdi/react';
import {
  mdiCellphone,
  mdiCheck,
  mdiContentSave,
  mdiContentSaveOffOutline,
  mdiContentSaveOutline,
  mdiFileTree,
  mdiImageSizeSelectLarge,
  mdiPhoneRotateLandscape,
  mdiPhoneRotatePortrait,
  mdiRedo,
  mdiTools,
  mdiUndo,
  mdiXml,
} from '@mdi/js';
import { downloadBlob, getLanguage, isObject, isTextFile } from '../_helpers';
import { EDIT_JSON_MODE, PREVIEW, RESOLUTION } from '../context/reducers';
import {
  useKustodio,
  useProjects,
  useSockets,
  useUser,
  useViewer,
} from '../hooks';
import { palettes } from '../schemas/app';
import { projectTypes } from '../schemas/project';

const jsonModeIcon = {
  [EDIT_JSON_MODE.CODE]: mdiXml,
  [EDIT_JSON_MODE.TREE]: mdiFileTree,
};

const SaveMenu = ({ path }) => {
  const { t } = useTranslation();
  const {
    project = {},
    projectToZip = () => {},
    updateProject = () => {},
  } = useProjects();
  const { showRegisterModal } = useUser();
  const { saveProjectToKustodio = () => {} } = useKustodio();
  const { data = {} } = project;
  const { app = {} } = data;
  const { project_name: projectName } = app;

  const saveToDevice = async () => {
    const { auth: { token: lsToken } = {} } = ls('user') || {};
    if (!lsToken) {
      showRegisterModal();
      return;
    }
    const zip = await projectToZip(project);
    downloadBlob(zip, `${projectName}.cdz`);
  };

  const saveToKustodio = async () => {
    const { auth: { token: lsToken } = {} } = ls('user') || {};
    if (!lsToken) {
      showRegisterModal();
      return;
    }
    const file = await projectToZip(project);
    file.name = `${projectName}.cdz`;
    const tagging = await saveProjectToKustodio({ project, file });
    const kustodioId = parseInt(tagging.id);
    if (project.kustodio_id !== kustodioId) {
      updateProject({ brain: { kustodio_id: kustodioId }, project });
    }
  };

  if (!project.id) {
    return null;
  }

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu>
          <Menu.Item onClick={() => saveToDevice()}>{`${t(
            'common.download'
          )} CDZ`}</Menu.Item>
          <Menu.Item onClick={() => saveToKustodio({ project })}>
            {t('projects.save_to_kustodio')}
          </Menu.Item>
        </Menu>
      }
      placement="bottomLeft"
    >
      <Tooltip title={t('common.save')} placement="left">
        <Button
          icon={<Icon path={mdiContentSave} />}
          type="link"
          shape="round"
          size="small"
          className="p-0"
        />
      </Tooltip>
    </Dropdown>
  );
};

const AUTOSAVE_INTERVAL = 60;

const AutoSave = () => {
  const { t } = useTranslation();
  const {
    project = {},
    projectToZip = () => {},
    status,
    updateProject = () => {},
  } = useProjects();
  const { saveProjectToKustodio = () => {} } = useKustodio();
  const { auth: { token: lsToken } = {} } = ls('user') || {};
  let { autoSave = true, toggleAutoSave } = useViewer();
  autoSave = !lsToken ? false : autoSave;
  const timeoutRef = useRef(null);
  const [projectData, setProjectData] = useState({});
  const [state, setState] = useState({});
  const {
    date = null,
    loading = false,
    hasUnsavedChanges = false,
    init = false,
    timeout = true,
  } = state;

  const handleSaveProject = useCallback(async () => {
    if (!lsToken) {
      return;
    }
    const { data = {} } = project;
    const { app = {} } = data;
    const { project_name: projectName } = app;
    setState((state) => ({ ...state, loading: true }));
    const file = await projectToZip(project);
    file.name = `${projectName}.cdz`;
    const tagging = await saveProjectToKustodio({ project, file, auto: true });
    if (!tagging) {
      setState((state) => ({
        ...state,
        init: true,
        date: null,
        loading: false,
        hasUnsavedChanges: false,
      }));
      return;
    }
    const kustodioId = parseInt(tagging.id);
    if (project.kustodio_id !== kustodioId) {
      updateProject({ brain: { kustodio_id: kustodioId }, project });
    }
    setState((state) => ({
      ...state,
      date: new Date(Date.now()),
      loading: false,
      hasUnsavedChanges: false,
    }));
  }, [project]);

  useEffect(() => {
    if (status === 'brain_loaded' && !project?.kustodio_id) {
      handleSaveProject();
    }
    if (
      (init && project?.kustodio_id) ||
      !autoSave ||
      loading ||
      !timeout ||
      !hasUnsavedChanges
    ) {
      return;
    }

    setState((state) => ({ ...state, timeout: false }));
    handleSaveProject();

    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      setState((state) => ({ ...state, timeout: Date.now() }));
    }, AUTOSAVE_INTERVAL * 1000);
  }, [autoSave, hasUnsavedChanges, status, timeout]);

  const showUnsavedChangesModal = () => true;

  useEffect(() => {
    if (loading || hasUnsavedChanges) {
      window.onbeforeunload = showUnsavedChangesModal;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [loading, hasUnsavedChanges]);

  useEffect(() => {
    setProjectData(project?.data || {});
    if (projectData?.app?.id !== project?.data?.app?.id) {
      setState((state) => ({
        ...state,
        date: null,
        loading: false,
      }));
      return;
    }
    setState((state) => ({ ...state, hasUnsavedChanges: Date.now() }));
  }, [project?.data]);

  if (!project.id) {
    return null;
  }

  return (
    <Tooltip
      title={`${t('common.autosaved')}${
        date ? `: ${date.toLocaleTimeString()}` : ''
      }`}
    >
      <Switch
        checkedChildren={
          <Icon path={hasUnsavedChanges ? mdiContentSaveOutline : mdiCheck} />
        }
        className="autosave-switch"
        unCheckedChildren={<Icon path={mdiContentSaveOffOutline} />}
        defaultChecked={autoSave}
        size="small"
        disabled={!lsToken}
        loading={loading}
        onClick={() => toggleAutoSave()}
      />
    </Tooltip>
  );
};

const SaveActions = () => {
  return (
    <>
      <SaveMenu />
      <AutoSave />
    </>
  );
};

const EditActions = () => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const {
    togglePreview,
    togglePreviewResolution = () => {},
    preview,
    resolution,
  } = useViewer();
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { type } = app;

  const { pathname: path } = window.location;
  const isActive =
    !!path.match(/\/.*\/(dialogs|jobs|menus|view_wrappers|views)\/.*$/) ||
    !!path.match(/^\/[^/]*$/) ||
    !!path.match(/^\/[^/]*\/$/);

  if (!isActive) {
    return null;
  }
  return (
    <>
      {type === projectTypes.WEB && (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu selectedKeys={[resolution]}>
              {Object.values(RESOLUTION).map((value) => (
                <Menu.Item
                  key={value}
                  onClick={() => togglePreviewResolution(value)}
                >
                  {value}
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottom"
        >
          <Tooltip title={t('projects.preview_resolution')} placement="right">
            <Button
              shape="circle"
              type="link"
              icon={<Icon path={mdiImageSizeSelectLarge} size={0.8} />}
            />
          </Tooltip>
        </Dropdown>
      )}
      {type === projectTypes.MOBILE_APP && (
        <Tooltip title={t('projects.preview_orientation')} placement="right">
          <Button
            shape="circle"
            type="link"
            icon={
              <Icon
                path={
                  preview === PREVIEW.MOBILE_PORTRAIT
                    ? mdiPhoneRotateLandscape
                    : mdiPhoneRotatePortrait
                }
                size={0.8}
              />
            }
            onClick={() => {
              togglePreview(
                preview === PREVIEW.MOBILE_PORTRAIT
                  ? PREVIEW.MOBILE_LANDSCAPE
                  : PREVIEW.MOBILE_PORTRAIT
              );
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export const Actions = ({ data = {} }) => {
  const { t } = useTranslation();
  const { jsonEditorMode, showPreview, toggleJsonEditorMode, togglePreview } =
    useViewer();
  const {
    project = {},
    now = {},
    past = [],
    future = [],
    undo,
    redo,
    updateProject,
  } = useProjects();
  const { applyJson } = useSockets();
  const actions = useRef();
  const [undoActionDisabled, setUndoActionDisabled] = useState(true);
  const [redoActionDisabled, setRedoActionDisabled] = useState(true);
  const { key = '', value = {} } = data;
  const { id } = value;
  const ext = `${id}`.split('.').pop();
  const fileEditor = ['certificates', 'external', 'res'].includes(key);
  const jsonEditor = fileEditor && ext === 'json';
  const onChangeJsonEditorActions = (entries) => {
    entries.forEach((mutation) => {
      const { target } = mutation;
      const { className } = target;
      if (className.includes('jsoneditor-undo')) {
        setUndoActionDisabled(target.hasAttribute('disabled'));
      }
      if (className.includes('jsoneditor-redo')) {
        setRedoActionDisabled(target.hasAttribute('disabled'));
      }
    });
  };
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { colors = {}, name = '' } = app;
  let nameToShow = name;
  const language = getLanguage();
  if (isObject(nameToShow)) {
    nameToShow =
      typeof nameToShow[language] !== 'undefined'
        ? nameToShow[language]
        : nameToShow.default;
  }
  if (typeof nameToShow === 'undefined' || nameToShow === '') {
    nameToShow = '';
  }
  const { accent, primary } = colors;
  const currentPaletteArray = [accent, primary];
  const currentPaletteIndex = palettes
    .map(({ accent, primary }) => `${accent}-${primary}`)
    .indexOf(`${colors.accent}-${colors.primary}`);

  const setCurrentPalette = useCallback(
    (index) => {
      const data = projectData._clone();
      data.app.colors = {
        ...data.app.colors,
        ...palettes[index],
      };
      updateProject({ data });
      applyJson(data);
    },
    [applyJson, projectData, updateProject]
  );

  useEffect(() => {
    const observer = new MutationObserver(onChangeJsonEditorActions);
    if (jsonEditor) {
      setTimeout(() => {
        const undoElement = document.querySelector(
          '.json-editor .jsoneditor-undo'
        );
        const redoElement = document.querySelector(
          '.json-editor .jsoneditor-redo'
        );
        undoElement && observer.observe(undoElement, { attributes: true });
        redoElement && observer.observe(redoElement, { attributes: true });
      }, 1000);
    }
    if (fileEditor) {
      setUndoActionDisabled(false);
      setRedoActionDisabled(false);
    }
    return () => {
      observer.disconnect();
    };
  }, [data, fileEditor, jsonEditor]);

  const onKeyDown = (event) => {
    const { metaKey, ctrlKey, shiftKey } = event;
    let { key = '' } = event;
    key = key.toLowerCase();

    if (!(metaKey || ctrlKey) || key !== 'z') {
      return;
    }

    if (shiftKey) {
      redo();
    } else {
      undo();
    }
  };

  const handleUndoClick = () => {
    if (jsonEditor) {
      document.querySelector('.json-editor .jsoneditor-undo')?.click();
      return;
    }
    if (fileEditor) {
      document.execCommand('undo', false, null);
      return;
    }
    undo();
  };
  const handleRedoClick = () => {
    if (jsonEditor) {
      document.querySelector('.json-editor .jsoneditor-redo')?.click();
      return;
    }
    if (fileEditor) {
      document.execCommand('redo', false, null);
      return;
    }
    redo();
  };

  const handleRepairClick = () =>
    document.querySelector('.json-editor .jsoneditor-repair').click();

  const handleSaveClick = () =>
    (
      document.querySelector('.json-editor + .save') ||
      document.querySelector('#text-editor + .save')
    ).click();

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [now]);

  return (
    <div className="actions" ref={actions}>
      <Space id="ModeActions" className="actions-mode">
        <small className="ms-2">{`${nameToShow} ${
          id ? `> ${key} > ${id}` : ''
        }`}</small>

        {jsonEditor &&
          ((
            <>
              <Button
                type="link"
                onClick={() => toggleJsonEditorMode()}
                icon={<Icon path={jsonModeIcon[jsonEditorMode]} />}
              >
                <span>{t('actions.mode', { context: jsonEditorMode })}</span>
              </Button>
            </>
          ) ||
            null)}
      </Space>
      <Space>
        <Button
          type="link"
          disabled={
            fileEditor ? undoActionDisabled : !project.id || !past.length
          }
          onClick={handleUndoClick}
        >
          <Tooltip title={t('actions.undo')} placement="left">
            <Icon path={mdiUndo} />
          </Tooltip>
        </Button>
        <Button
          type="link"
          disabled={
            fileEditor ? redoActionDisabled : !project.id || !future.length
          }
          onClick={handleRedoClick}
        >
          <Tooltip title={t('actions.redo')}>
            <Icon path={mdiRedo} />
          </Tooltip>
        </Button>
        <EditActions />
        {(jsonEditor && (
          <>
            <Button type="link" onClick={handleRepairClick}>
              <Tooltip title={t('actions.repair_description')}>
                <Icon path={mdiTools} />
                <span>{t('actions.repair')}</span>
              </Tooltip>
            </Button>
          </>
        )) ||
          null}
        {fileEditor && isTextFile(ext) && (
          <Button
            type="link"
            className="save disabled"
            onClick={handleSaveClick}
          >
            <Icon path={mdiContentSave} />
            <span>{t('common.save')}</span>
          </Button>
        )}
      </Space>
      <Space id="PreviewActions" className="actions-preview">
        {(!showPreview && (
          <Tooltip title={t('projects.preview')}>
            <Button type="link" onClick={() => togglePreview()}>
              <Icon path={mdiCellphone} />
            </Button>
          </Tooltip>
        )) ||
          null}
        {project.id && <SaveActions />}
        {(project.id && (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu className="palettes" selectedKeys={[currentPaletteIndex]}>
                {palettes.map(({ accent, primary }, index) => (
                  <Menu.Item
                    key={`Actions-Palette-${index}`}
                    disabled={!project.id}
                    value={index}
                    onClick={() => setCurrentPalette(index)}
                  >
                    <div className="palette">
                      <div>
                        {[accent, primary].map((color, colorIndex) => (
                          <div
                            key={`Actions-Palette-${index}-Colors-${colorIndex}`}
                            style={{ color }}
                          />
                        ))}
                      </div>
                    </div>
                  </Menu.Item>
                ))}
              </Menu>
            }
            placement="bottomRight"
          >
            <div className="palette">
              <div>
                {currentPaletteArray.map((color) => (
                  <div key={`Actions-Color-${color}`} style={{ color }} />
                ))}
              </div>
            </div>
          </Dropdown>
        )) ||
          null}
      </Space>
    </div>
  );
};
