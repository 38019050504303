import React from 'react';
import { circlePreloader } from '../assets/svg/preloaders';

export const CirclePreloader = ({ className = '', ...props }) => {
  return (
    <div {...props} className={`preloader preloader-circle ${className}`}>
      <img alt="preloader" src={circlePreloader} />
    </div>
  );
};
