import { useLocation } from 'react-router-dom';
import ls from 'local-storage';
import { HOST, PAGES } from '../_config';
import {
  logo as logoDoyo,
  logoWhite as logoDoyoWhite,
} from '../assets/svg/logos';

const logos = {
  [HOST.KUSTODIO]: logoDoyo,
};
const logosWhite = {
  [HOST.KUSTODIO]: logoDoyoWhite,
};

export const useReferrer = (props) => {
  const location = useLocation();
  let { key, pathname: locationPathname, state = {} } = location;
  let { key: stateKey = key } = state;
  let { url = '', key: referrerKey } = ls('referrer') || {};
  let base = '/';
  if (url) {
    base = url.split('/').slice(0, 3).join('/');
    if (!Object.values(HOST).includes(base)) {
      ls.remove('referrer');
      return {};
    }
    if (!referrerKey || stateKey) {
      ls('referrer', { url, key });
    } else if (
      key !== referrerKey &&
      !locationPathname.includes(PAGES.PASSWORD_RESET)
    ) {
      ls.remove('referrer');
      url = '';
      base = '/';
    }
  }
  const pathname = url.replace(base, '');
  const logo = {
    color: logos[base] || logoDoyo,
    white: logosWhite[base] || logoDoyoWhite,
  };
  return { logo, base, key, pathname, url };
};

export default useReferrer;
