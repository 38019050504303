import { useMemo, useState } from 'react';
import { useProjects } from '../hooks';

export function useAutocomplete(key) {
  const [autocomplete, setAutocomplete] = useState({});
  const { project = {} } = useProjects();

  useMemo(() => {
    const { data: projectData = {} } = project;
    const {
      app = {},
      certificates = [],
      dialogs = [],
      jobs = [],
      // external = [],
      menus = [],
      res = [],
      flows = [],
      views = [],
      view_wrappers: viewWrappers = [],
    } = projectData;
    const { colors = {}, fonts = {}, monetization = {}, purchases = {} } = app;
    const { ads = {} } = monetization;
    const { networks = {}, placements = [] } = ads;
    const products = Object.values(purchases).reduce(
      (all, { products = [] }) => [...all, ...products],
      []
    );

    const color = Object.keys(colors).map((key) => `@color.${key}`);
    const file = res
      .map(({ id }) => `${id}`.replace(/^res\//, ''))
      .sort((a, b) => (a > b ? 1 : -1));
    const certificate = [
      ...certificates.map(({ id }) => `${id}`.replace('certificates/', '')),
      ...file.filter((filename) =>
        ['keystore'].some((ext) => filename.includes(`.${ext}`))
      ),
    ].sort((a, b) => (a > b ? 1 : -1));
    const database = file.filter((filename) =>
      ['csv'].some((ext) => filename.includes(`.${ext}`))
    );
    const fontRes = file.filter((filename) =>
      ['otf', 'ttf'].some((ext) => filename.includes(`.${ext}`))
    );
    const font = [
      ...Object.keys(fonts).map((key) => `@font.${key}`),
      ...fontRes.filter((fontRes) => !Object.values(fonts).includes(fontRes)),
    ];
    const network = Object.keys(networks);
    const placement = Array.isArray(placements)
      ? placements.map(({ id }) => id)
      : Object.keys(placements);
    const product = products.map(({ id }) => id);

    // Media
    const image = file.filter((filename) =>
      ['png', 'jpg', 'jpeg', 'gif', 'svg'].some((ext) =>
        filename.includes(`.${ext}`)
      )
    );
    const audio = file.filter((filename) =>
      ['mp3'].some((ext) => filename.includes(`.${ext}`))
    );
    const video = file.filter((filename) =>
      ['mp4'].some((ext) => filename.includes(`.${ext}`))
    );
    const pdf = file.filter((filename) =>
      ['pdf'].some((ext) => filename.includes(`.${ext}`))
    );

    // Project
    const dialog = dialogs.map(({ id }) => id);
    const job = jobs.map(({ id }) => id);
    const menu = menus.map(({ id }) => id);
    const wrapper = viewWrappers.map(({ id }) => id);
    const view = views.map(({ id }) => id);
    const step = flows.reduce(
      (result, { steps = [] }) => [...result, ...steps.map(({ id }) => id)],
      []
    );

    setAutocomplete({
      audio,
      certificate,
      color,
      database,
      dialog,
      file,
      font,
      fontRes,
      image,
      job,
      menu,
      network,
      pdf,
      placement,
      product,
      video,
      view,
      views: view,
      step,
      steps: step,
      wrapper,
    });
  }, [project]);

  const { [key]: value, file = [] } = autocomplete;

  if (value) {
    return value;
  }

  const keyArray = Array.isArray(key) ? key : [key];
  return file.filter((filename) =>
    keyArray.some((ext) => filename.includes(`.${ext}`))
  );
}
