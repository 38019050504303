import gql from 'graphql-tag';

export const CREATE_SHARING = gql`
  mutation CreateSharing($taggingId: ID!) {
    createSharing(input: { taggingId: $taggingId }) {
      id
      token
      tagging {
        id
      }
    }
  }
`;

export const DELETE_SHARING = gql`
  mutation DeleteSharing($id: ID!) {
    deleteSharing(id: $id) {
      id
      tagging {
        id
      }
    }
  }
`;
