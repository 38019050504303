import { color, title } from './defs/common';
import { flowArrayOfFunctions, flowButtons, steps } from './defs/flow';

export const flows = {
  title: 'Flow',
  description: 'Each flow of the project',
  default: { id: 'flow' },
  additionalProperties: false,
  removeAdditional: true,
  required: ['id', 'title', 'steps'],
  properties: {
    id: { pattern: '^[a-z]+[_a-z0-9]*$' },
    active: { default: true, type: 'boolean' },
    title,
    steps,
    background: { ...color, default: '@color.darken' },
    buttons: flowButtons,
    events: {
      default: {},
      additionalProperties: false,
      removeAdditional: true,
      properties: {
        onstart: flowArrayOfFunctions,
      },
    },
  },
};

export default flows;
