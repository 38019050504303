// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ls from 'local-storage';
import { PAGES } from '../_config';

type PropsT = {
    component: React.Component,
};

export const PrivateRoute = ({ component: Component, ...rest }: PropsT) => {
    const { location } = rest;
    const { hash, search } = location;
    const { auth = {} } = ls('user') || {};
    const { token } = auth;
    let pathname = window.self === window.top ? PAGES.SIGN_IN : PAGES.ADDONS_SIGN_IN;
    return (
        <Route
            {...rest}
            render={(props) =>
                token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            hash,
                            pathname,
                            search,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};
