import Cookies from 'js-cookie';

export const REFS = {
  INITIAL_STATE: {
    cookie: Cookies.get(),
    element: {},
    firebase: {},
    property: {},
  },

  SET: 'REFS_SET',
};

export const RefsReducer = (draft, action) => {
  const { payload, type } = action;
  switch (type) {
    case REFS.SET:
      Object.keys(payload.refs.cookie || {}).forEach((key) =>
        Cookies.set(key, payload.refs.cookie[key])
      );
      draft.cookie = {
        ...draft.cookie,
        ...(payload.refs.cookie || {}),
      };
      draft.element = {
        ...draft.element,
        ...(payload.refs.element || {}),
      };
      draft.firebase = {
        ...draft.firebase,
        ...(payload.refs.firebase || {}),
      };
      draft.property = {
        ...draft.property,
        ...(payload.refs.property || {}),
      };
      break;

    default:
      return draft;
  }
};
