// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import {
  Alert,
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Space,
  Tabs,
} from 'antd';
import {
  getDirectories,
  getMimeType,
  getUniqueName,
  isExternalFile,
  isFont,
  isProjectFile,
  isRootFile,
} from '../_helpers';
import { useAlerts, useProjects, useSockets } from '../hooks';
import { FilePreview, Pexels } from '.';

const DropWrapper = ({
  className = '',
  mainFolder = 'res',
  folder: propsFolder = '',
}) => {
  const { alertError } = useAlerts();
  const dragging = useRef([]);
  const { t } = useTranslation();
  const { createProjectFromZip, project = {}, updateProject } = useProjects();
  const { applyJson } = useSockets();
  const [folder, setFolder] = useState(propsFolder);
  const [askingToOverwrite, setAskingToOverwrite] = useState(false);
  const [from, setFrom] = useState(false);
  const dropWrapper = useRef();
  const antInput = useRef();
  const antInputExt = useRef('json');
  const { data: projectData = {} } = project;
  let data = projectData._clone();
  data[mainFolder] = data[mainFolder] || [];
  const filesName = data[mainFolder].map(({ id }) => id);

  const handleDragEnter = useCallback(
    (event) => {
      const { items } = event.dataTransfer;
      if (
        !project.id ||
        !items.length ||
        Object.values(items).some(({ kind }) => kind !== 'file')
      ) {
        return;
      }
      event.preventDefault();
      const { effectAllowed } = event.dataTransfer;
      const isShown = effectAllowed === 'all';
      const draggingFiles = isShown ? Object.values(items) : [];
      dragging.current = draggingFiles;
      dropWrapper.current?.classList.add('show');
    },
    [project]
  );

  const handleDragLeave = useCallback(
    (event) => {
      event.preventDefault();
      dragging.current = [];
      dropWrapper.current?.classList.remove('show');
    },
    [project]
  );

  const askToOverwrite = (filesToOverwrite) =>
    new Promise((resolve, reject) => {
      setAskingToOverwrite({ ...filesToOverwrite, resolve, reject });
    });

  const uploadFiles = useCallback(
    async (files) => {
      const filesToUpload = Object.values(files).map((file) => {
        let _folder = folder;
        let { filepath, name: fileName } = file;
        if (isFont(file) && !folder) {
          _folder = 'fonts';
        }
        const name = _folder
          ? `${_folder}/${filepath || fileName}`
          : filepath || fileName;
        return { file, name };
      });
      const newFilesName = filesToUpload.map(({ name }) => name);
      const filesToOverwrite = {
        from: data[mainFolder].filter(({ id }) => newFilesName.includes(id)),
        to: filesToUpload.filter(({ name }) => filesName.includes(name)),
      };
      if (filesToOverwrite.from.length) {
        try {
          await askToOverwrite(filesToOverwrite);
        } catch (e) {
          return;
        }
      }
      await Promise.all(
        filesToUpload.map(async ({ file, name }) => {
          if (isProjectFile(file) && !folder) {
            try {
              data = (
                await createProjectFromZip(file, { merge: true, title: name })
              ).data;
              return;
            } catch ({ message }) {
              alertError({ message, open: true });
              return;
            }
          }

          const { overwrite } = file;

          if (filesName.includes(name)) {
            if (overwrite === true) {
              data[mainFolder] = data[mainFolder].filter(
                ({ id }) => id !== name
              );
            } else if (overwrite === false) {
              name = getUniqueName(name, { names: filesName });
            }
          }

          let fileData;
          if (
            ['dialogs', 'jobs', 'menus', 'views', 'view_wrappers'].includes(
              folder
            ) ||
            isRootFile(name)
          ) {
            let json = {};
            try {
              json = JSON.parse(await file.text());
            } catch (e) {}
            fileData = json;
            if (!isRootFile(name)) {
              fileData = {
                ...fileData,
                id: name,
              };
            }
          } else {
            if (isFont(file) && !folder) {
              const alias = name
                .replace('fonts/', '')
                .toLowerCase()
                .split('.')[0]
                .replace(/[ _-]/g, '');
              data.app.fonts = { ...(data.app.fonts || {}), [alias]: name };
            }
            fileData = {
              id: name,
              blob: new Blob([file], { type: file.type }),
              url: URL.createObjectURL(file),
            };
          }
          if (isExternalFile(name)) {
            fileData = {
              id: name.replace(
                /(google-services|GoogleService-Info)( *\(\d+\))*(\.json|\.plist)/,
                (str, name, index, ext) => {
                  return `${name}${ext}`;
                }
              ),
              blob: new Blob([file], { type: file.type }),
              url: URL.createObjectURL(file),
            };
            data.external = [...data.external, fileData];
            data.app = {
              ...data.app,
              firebase: {
                ...(data.app.firebase || {}),
              },
            };
          } else if (isRootFile(name)) {
            const nameWithNoExt = name.split('.');
            nameWithNoExt.pop();
            data[nameWithNoExt] = { ...data[nameWithNoExt], ...fileData };
          } else {
            data[mainFolder] = [...data[mainFolder], fileData];
          }
        })
      );
      updateProject({ data });
      applyJson(data);
    },
    [applyJson, folder, project]
  );

  const handleChange = (event) => {
    const { currentTarget } = event;
    const { files } = currentTarget;
    uploadFiles(files);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    dropWrapper.current.classList.remove('show');
    const { dataTransfer } = event;
    let { files, items } = dataTransfer;
    const directories = await getDirectories(items);
    if (directories.length) {
      const getDirectoryFiles = (directory) => {
        const { children } = directory;
        return children.reduce((files, childFile) => {
          if (childFile.children) {
            return [...files, ...getDirectoryFiles(childFile)];
          }
          return [...files, childFile];
        }, []);
      };
      files = directories.reduce((files, directory) => {
        return [...files, ...getDirectoryFiles(directory)];
      }, []);
    }

    uploadFiles(files);
  };

  useEffect(() => {
    if (!dropWrapper.current) {
      return;
    }
    const app = className.includes('object')
      ? dropWrapper.current
      : document.getElementById('app');
    app.addEventListener('dragenter', handleDragEnter, false);
    app.addEventListener('dragover', handleDragEnter, false);
    dropWrapper.current.addEventListener('dragleave', handleDragLeave, false);
    dropWrapper.current.addEventListener('drop', handleDrop, false);
    return () => {
      if (!dropWrapper.current) {
        return;
      }
      app.removeEventListener('dragenter', handleDragEnter);
      app.removeEventListener('dragover', handleDragEnter);
      dropWrapper.current.removeEventListener('dragleave', handleDragLeave);
      dropWrapper.current.removeEventListener('drop', handleDrop);
    };
  }, [folder, project]);

  useEffect(() => {
    setFolder(propsFolder);
  }, [propsFolder]);

  const handleInputKeyDown = (event) => {
    const { key, currentTarget } = event;
    const { value } = currentTarget;
    if (key === ' ' || (/\d/.test(key) && !value)) {
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    setFolder(`${propsFolder}${propsFolder && value ? '/' : ''}${value}`);
  };

  const handleKeyDownItemName = (event) => {
    const { currentTarget: input, key } = event;
    const { value } = input;
    if (key === ' ' || (/\d/.test(key) && !value)) {
      event.preventDefault();
      input.reportValidity();
    }
  };

  const handleTabChange = (tabKey) => {
    setFrom(tabKey);
    document
      .querySelector('.drop-wrapper-modal')
      .classList.toggle('fullscreen-modal', tabKey === 'pexels');
  };

  const createItem = (input, ext) => {
    const type = getMimeType(ext);
    const content = t('dropwrapper.default', { context: ext });
    let { value: name } = input;
    name = `${name}.${ext}`;
    const file = new File([content], name, { type });
    uploadFiles([file]);
  };

  let component = (
    <div
      className={`drop-wrapper${className ? ` ${className}` : ''}`}
      ref={dropWrapper}
    >
      <Alert
        type="info"
        message={
          <>
            <span className="me-1">
              {t('dropwrapper.directory', {
                context: !project ? 'unknown' : '',
                count: dragging.current.length,
              })}
            </span>
            <strong>
              {`${projectData.app?.project_name}${
                mainFolder ? `/${mainFolder}` : ''
              }${propsFolder ? `/${propsFolder}` : ''}`}
              /
              <Input
                size="small"
                onKeyDown={handleInputKeyDown}
                onKeyUp={handleInputKeyDown}
                onChange={handleInputChange}
              />
            </strong>
          </>
        }
      />
      <div className="bg" />
    </div>
  );

  if (className.includes('object')) {
    component = (
      <div
        className={`drop-wrapper${className ? ` ${className}` : ''}`}
        ref={dropWrapper}
      >
        <Tabs
          data-main-folder={mainFolder}
          onChange={handleTabChange}
          tabBarExtraContent={
            <Alert
              type="info"
              message={
                <>
                  <span className="me-1">
                    {t('dropwrapper.directory', {
                      context: !project ? 'unknown' : from,
                      count: dragging.current.length,
                    })}
                  </span>
                  <strong>
                    {`${projectData.app?.project_name}${
                      mainFolder ? `/${mainFolder}` : ''
                    }${propsFolder ? `/${propsFolder}` : ''}`}
                    /
                    <Input
                      size="small"
                      onKeyDown={handleInputKeyDown}
                      onKeyUp={handleInputKeyDown}
                      onChange={handleInputChange}
                    />
                  </strong>
                </>
              }
            />
          }
        >
          <Tabs.TabPane
            tab={t('dropwrapper.from_local')}
            key="local"
            className="droppable-zone"
          >
            <div className="input">
              <Space direction="vertical">
                <div>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    onClick={(event) =>
                      event.currentTarget.nextElementSibling.click()
                    }
                  >
                    {t('dropwrapper.select_files')}
                  </Button>
                  <Input
                    multiple
                    className="d-none"
                    onChange={handleChange}
                    tabIndex="-1"
                    type="file"
                  />
                </div>
                <Divider>{t('common.or')}</Divider>
                <div>
                  <form>
                    <Space direction="vertical">
                      <Input
                        addonAfter={
                          <Select
                            defaultValue="json"
                            onChange={(ext) => (antInputExt.current = ext)}
                          >
                            <Select.Option value="json">.json</Select.Option>
                            <Select.Option value="txt">.txt</Select.Option>
                          </Select>
                        }
                        onPressEnter={(event) =>
                          createItem(event.target, antInputExt.current)
                        }
                        onKeyDown={(event) => handleKeyDownItemName(event)}
                        pattern="^[a-z]+[_a-z0-9]*"
                        required
                        ref={antInput}
                      />
                      <Button
                        size="large"
                        shape="round"
                        onClick={() =>
                          createItem(
                            antInput.current.input,
                            antInputExt.current
                          )
                        }
                      >
                        {t('dropwrapper.create_new_file')}
                      </Button>
                    </Space>
                  </form>
                </div>
              </Space>
            </div>
          </Tabs.TabPane>
          {(mainFolder === 'res' && (
            <Tabs.TabPane
              tab={t('dropwrapper.from_pexels')}
              key="pexels"
              className="pexels-wrapper"
            >
              <Pexels uploadFiles={uploadFiles} />
            </Tabs.TabPane>
          )) ||
            null}
        </Tabs>
      </div>
    );
  }

  return (
    <>
      {component}
      <Modal
        open={!!askingToOverwrite}
        className="overwrite-modal"
        closeIcon={<Icon path={mdiClose} size={1} />}
        title={t('dropwrapper.overwrite_modal_title')}
        okText={t('common.accept')}
        onOk={() => {
          setAskingToOverwrite(false);
          askingToOverwrite.resolve();
        }}
        onCancel={() => {
          setAskingToOverwrite(false);
          askingToOverwrite.reject(new Error('cancel'));
        }}
      >
        {(askingToOverwrite &&
          askingToOverwrite.from.map((from) => {
            const { file, name } = askingToOverwrite.to.find(
              ({ name }) => name === from.id
            );
            file.overwrite = true;
            const to = {
              id: name,
              blob: file,
              url: URL.createObjectURL(file),
            };

            return (
              <div
                className="overwrite-preview"
                key={`Preview-${from.id}-${Date.now()}`}
                data-overwrite={true}
              >
                <div>
                  <div className="old-file">
                    <Icon path={mdiClose} className="delete-x" />
                    <FilePreview {...from} />
                    <span>{from.id}</span>
                  </div>
                  <div className="new-file">
                    <FilePreview {...to} />
                    <span className="overwrite-name">{to.id}</span>
                    <span className="keep-both-name">
                      {getUniqueName(to.id, { names: filesName })}
                    </span>
                  </div>
                </div>
                <div>
                  <input
                    id={`${to.id}KeepBoth`}
                    type="checkbox"
                    className="json-node-value-helper"
                    onChange={(event) => {
                      const { currentTarget } = event;
                      const { checked } = currentTarget;
                      event.currentTarget.parentNode.parentNode.dataset.overwrite =
                        !checked;
                      file.overwrite = !checked;
                    }}
                  />
                  <label htmlFor={`${to.id}KeepBoth`}>
                    {t('common.keep_both')}
                  </label>
                </div>
              </div>
            );
          })) ||
          null}
      </Modal>
    </>
  );
};

export default DropWrapper;
export { DropWrapper };
