import React from 'react';
import ReactLottie from 'react-lottie';
import Component from './Component';

export const LottieCompiler = ({ ...props }) => {
  return <Lottie {...props} />;
};

export const Lottie = ({
  autoplay = true,
  className = '',
  data,
  loop = true,
  speed = 1,
  ...props
}) => {
  const options = {
    animationData: data,
    autoplay,
    loop,
    speed,
  };
  return (
    <Component {...props} className={`lottie ${className}`.trim()}>
      <ReactLottie options={options} speed={speed} component={ReactLottie} />
    </Component>
  );
};

Lottie.displayName = 'Lottie';
LottieCompiler.displayName = 'Lottie';
export default LottieCompiler;
