import React from 'react';
import BootstrapDropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getTextColor } from '../../../_helpers';
import Component, { Content } from './Component';
import Icon from './Icon';
import Image from './Image';
import Text from './Text';
import { useColor, useMenu } from '../../hooks';

const sizes = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

const DropdownItemsCompiler = ({ items }) => {
  return items.map((item, index) => {
    const { icon, id, items, onclick: onClick, title } = item;
    const itemProps = {};

    if (icon?.match(/\./)) {
      itemProps.image = icon;
    } else if (icon) {
      itemProps.icon = icon;
    }
    if (title) {
      itemProps.title = <Text text={title} />;
    }
    return (
      <Dropdown.Item key={`${id}-${index}`} {...itemProps}>
        {items && <DropdownItemsCompiler items={items} />}
      </Dropdown.Item>
    );
  });
};

export const DropdownCompiler = ({
  arrow = false,
  color = '@color.primary',
  content,
  drop = 'down',
  menu,
  menu_align: menuAlign = 'start',
  outline,
  radius = 4,
  size: sizeProp,
  toggler = {},
  ...props
}) => {
  const { id, itemData } = props;
  let { icon, title } = toggler;
  let { key: variant, hex } = useColor(color);
  let size = sizes[sizeProp];
  if (outline) {
    variant = `outline-${variant}`;
  }
  const bgColor = color.split('.').pop();
  const textColor = getTextColor(hex);
  const { items } = useMenu(menu);
  let togglerContent = [];
  if (icon) {
    icon = { ...icon, id: `${id}Icon` };
    togglerContent = [...togglerContent, icon];
  }
  if (title) {
    title = { ...title, type: 'text', id: `${id}Title` };
    togglerContent = [...togglerContent, title];
  }
  return (
    <>
      <style>{`
    .btn-${bgColor},
      .btn-${bgColor}:hover,
      .btn-${bgColor}:active,
      .btn-${bgColor}:focus {
        color: ${textColor};
        background-color: var(--${bgColor});
        border-color: var(--${bgColor});
      }
      .btn-outline-${bgColor} {
          color: var(--${bgColor});
          border-color: var(--${bgColor});
      }
      .btn-outline-${bgColor}:hover,
      .btn-outline-${bgColor}:active,
      .btn-outline-${bgColor}:hover {
        color: ${textColor};
        background-color: var(--${bgColor});
        border-color: var(--${bgColor});
      }
      `}</style>
      <Dropdown
        {...props}
        align={menuAlign}
        arrow={arrow}
        drop={drop}
        radius={radius}
        size={size}
        title={<Content content={togglerContent} itemData={itemData} />}
        variant={variant}
      >
        <DropdownItemsCompiler items={items} />
        <Content content={content} />
      </Dropdown>
    </>
  );
};

const DropdownItem = ({
  children,
  onClick: handleClick = () => {},
  href = '',
  icon = '',
  image = '',
  title,
  type,
}) => {
  if (children) {
    children = Array.isArray(children) ? children : [children];
    return <Dropdown title={title}>{children}</Dropdown>;
  }

  const ItemIcon = image ? Image : Icon;
  const regexp = new RegExp(`^/(${href})$`);
  const isActive = href && window.location.pathname.match(regexp);
  const itemProps = {};
  if (isActive) {
    itemProps.active = true;
  }

  return (
    <BootstrapDropdown.Item onClick={handleClick} {...itemProps}>
      <>
        {(icon || image) && <ItemIcon src={icon || image} />}
        {title}
      </>
    </BootstrapDropdown.Item>
  );
};

export const Dropdown = ({ arrow, children, variant, title, ...props }) => {
  let toggleChildren = title?.props?.children;
  toggleChildren = Array.isArray(toggleChildren)
    ? toggleChildren.filter((child) => child)
    : toggleChildren;
  if (Array.isArray(toggleChildren) && toggleChildren.length === 1) {
    toggleChildren = toggleChildren[0];
  }
  if (arrow === false) {
    props['data-arrow'] = false;
  }
  if (
    title?.props?.content?.length === 1 &&
    ['icon', 'image'].includes(title.props.content[0].type)
  ) {
    props['data-image'] = true;
  }

  return (
    <Component
      {...props}
      variant={variant}
      component={DropdownButton}
      title={title}
    >
      {children}
    </Component>
  );
};

Dropdown.Item = DropdownItem;

Dropdown.displayName = 'Dropdown';
DropdownCompiler.displayName = 'Dropdown';
export default DropdownCompiler;
