import React from 'react';
import BootstrapAccordion from 'react-bootstrap/Accordion';
import Component, { Content } from './Component';
import Text from './Text';
import { useSource } from '../../hooks';

export const AccordionCompiler = ({
  data_source: dataSource,
  itemData,
  ...props
}) => {
  const { data_content: dataContent = {} } = props;
  const { header = '' } = dataContent;
  return <Accordion {...props} header={header} src={dataSource} />;
};

export const Accordion = ({
  data_content: dataContent = {},
  filter,
  items = [],
  order,
  limit,
  src,
  ...props
}) => {
  const { id, itemData: data } = props;
  const { header = '', content = [] } = dataContent;
  let { value: sourceData } = useSource(src, {
    data,
    filter,
    isCollection: true,
    order,
    limit,
  });

  let children = items.map((item, index) => {
    const { content, header } = item;
    return (
      <Accordion.Item key={`${id}-item-${index}`} eventKey={index}>
        <Accordion.Header>
          <Text itemData={item} src={header}>
            {header}
          </Text>
        </Accordion.Header>
        <Accordion.Body>
          <Content content={content} itemData={data} />
        </Accordion.Body>
      </Accordion.Item>
    );
  });

  if (sourceData) {
    sourceData = Object.entries(sourceData || []).map(([key, value]) => ({
      NO_ID_FIELD: key,
      ...value,
    }));
    let sourceItems = Array.isArray(sourceData) ? sourceData : [sourceData];

    children = [
      ...children,
      ...sourceItems.map((item, index) => {
        return (
          <Accordion.Item key={`${id}-sourceitem-${index}`} eventKey={index}>
            <Accordion.Header>
              <Text itemData={item} src={header}>
                {header}
              </Text>
            </Accordion.Header>
            <Accordion.Body>
              <Content content={content} itemData={data} />
            </Accordion.Body>
          </Accordion.Item>
        );
      }),
    ];
  }

  return (
    <Component {...props} component={BootstrapAccordion}>
      {children}
    </Component>
  );
};

Accordion.Body = BootstrapAccordion.Body;
Accordion.Header = BootstrapAccordion.Header;
Accordion.Item = BootstrapAccordion.Item;

Accordion.displayName = 'Accordion';
AccordionCompiler.displayName = 'Accordion';
export default AccordionCompiler;
