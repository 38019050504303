import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { Button, Modal } from 'antd';
import { PAGES } from '../_config';
import { useUser } from '../hooks';

export const RegisterModal = ({ className }) => {
  const { t } = useTranslation();
  const { registerTimestamp, hideRegisterModal } = useUser();
  const [visible, setVisible] = useState();

  useEffect(() => {
    registerTimestamp && setVisible(true);
  }, [registerTimestamp]);

  useEffect(() => {
    visible === false && hideRegisterModal();
  }, [visible]);

  return (
    <Modal
      title={t('register.should_register')}
      className={`verification-modal ${className || ''}`}
      closeIcon={<Icon path={mdiClose} size={1} />}
      open={visible}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setVisible(false)}
    >
      <div className="w-100 text-center">
        <Link to={PAGES.SIGN_UP}>
          <Button
            type="primary"
            size="large"
            shape="round"
            onClick={() => setVisible(false)}
          >
            {t('common.sign_up_long')}
          </Button>
        </Link>
      </div>
    </Modal>
  );
};

export default RegisterModal;
