import React from 'react';
import Icon from '@mdi/react';
import { mdiStarOutline } from '@mdi/js';
import { Tooltip } from 'antd';
import { URL } from '../_config';
import useAccounts from '../hooks/useAccounts';

export const PremiumTag = ({ feature }) => {
  const { account = {}, isAllowed } = useAccounts();
  const { customer } = account;
  const { email: customerEmail = '' } = customer || {};
  const { message } = isAllowed(feature);
  if (!message) {
    return null;
  }
  return (
    <Tooltip title={message}>
      <div className="premium-tag">
        <a
          href={`${URL.SUBSCRIPTION}?prefilled_email=${customerEmail}`}
          target="_blank"
          rel="noreferrer"
        >
          <Icon path={mdiStarOutline} size={1} />
        </a>
      </div>
    </Tooltip>
  );
};

export default PremiumTag;
