import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import ls from 'local-storage';
import { PAGES } from '../../_config';
import { getUniqueName, mobileCheck } from '../../_helpers';
import { ProjectTree } from '.';
import {
  Actions,
  DropWrapper,
  FilePreview,
  RegisterModal,
  Sidebar,
  VerificationModal,
} from '../../components';
import { useProjects, useSockets } from '../../hooks';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

function storeCurrent({ id, path }) {
  const user = ls('user') || {};
  let { _state = {} } = user;

  Object.keys(_state).forEach((path) => {
    const value = _state[path];
    const { current } = value;
    _state[path] = current
      ? { ..._state[path], expanded: true, current: false }
      : value;
  });

  let parentPaths = path.split('/');
  while (parentPaths.join('/')) {
    const parentPath = parentPaths.join('/');
    const currentPathData = parentPath === path ? { current: true, id } : {};
    _state[parentPath] = {
      ...(_state[parentPath] || {}),
      expanded: true,
      ...currentPathData,
    };
    parentPaths.pop();
  }
  ls('user', { ...user, _state });
}

export const ResourcesPage = ({ match }) => {
  const {
    params: { project: projectNamePath = '', key, file: fileNamePath = '' },
  } = match;
  const history = useHistory();
  const { applyJson } = useSockets();
  const {
    project = {},
    projects = [],
    selectProject = () => {},
    unselectProject = () => {},
    updateProject = () => {},
  } = useProjects();
  const { edges: allProjects = [] } = projects;
  let { data: projectData = {} } = project;
  const path = decodeURIComponent(`/${projectNamePath}/res/${fileNamePath}`);
  const filePath = fileNamePath.split('/');
  const file = filePath.join('/');
  let value = projectData[key] || [];

  let index;
  if (Array.isArray(value)) {
    const val =
      value.find(({ id }) => id === file) ||
      value.find(({ id }) => id === file.split('/').shift());
    index = value.indexOf(val);
    value = val;
  }
  let data = { key, value, index };

  const handleFileChange = useCallback(
    (id, blob) => {
      const value = {
        id,
        blob,
        url: URL.createObjectURL(blob),
      };
      const { key, index } = data;
      let { data: projectData = {} } = project;
      projectData = projectData._clone();
      if (index >= 0) {
        projectData[key][index] = value;
      } else {
        projectData[key] = value;
      }
      updateProject({ data: projectData });
      applyJson(projectData);
    },
    [key, index, history, project, value]
  );

  useMemo(() => {
    storeCurrent({ id: file, path });
  }, [path, file]);

  useEffect(() => {
    const { node: projectToSelect } =
      allProjects.find(({ node: { data = {} } }) => {
        const { app = {} } = data;
        const { project_name: name } = app;
        return name === projectNamePath;
      }) || {};
    if (projectToSelect) {
      projectToSelect.id !== project.id && selectProject(projectToSelect);
    } else {
      unselectProject();
    }
  }, [projectNamePath, fileNamePath, allProjects]);

  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar match={match} path={path} />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ height: '40px' }}>
            <Actions data={data} />
          </Layout.Header>
          <Layout.Content id="WorkingArea">
            {project.id && key && value && (
              <FilePreview
                {...value}
                className="p-3"
                onChange={handleFileChange}
              />
            )}
          </Layout.Content>
        </Layout>
      </Layout>

      <DropWrapper />
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default ResourcesPage;
