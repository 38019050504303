import React from 'react';
import { DatabasesProvider } from './Databases';
import { DialogsProvider } from './Dialogs';
import { FirebaseProvider } from './Firebase';
import { FunctionsProvider } from './Functions';
import { ProjectProvider } from './Project';
import { RefsProvider } from './Refs';

export const projectTypes = {
  BACKEND: 'backend',
  FLOW: 'flow',
  MOBILE_APP: 'mobile_app',
  WEB: 'web',
};

export const ContextProviders = ({ project, children }) => {
  return (
    <FirebaseProvider project={project}>
      <ProjectProvider>
        <DatabasesProvider>
          <RefsProvider refs={project?.refs}>
            <DialogsProvider>
              <FunctionsProvider>
                <>{children}</>
              </FunctionsProvider>
            </DialogsProvider>
          </RefsProvider>
        </DatabasesProvider>
      </ProjectProvider>
    </FirebaseProvider>
  );
};

export * from './Databases';
export * from './Dialogs';
export * from './Firebase';
export * from './Functions';
export * from './Project';
export * from './Refs';
