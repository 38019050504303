export const USER = {
  INITIAL_STATE: {},

  SHOW_REGISTER: 'USER_SHOW_REGISTER',
  HIDE_REGISTER: 'USER_HIDE_REGISTER',
};

export const UserReducer = (draft, action) => {
  const { type } = action;
  switch (type) {
    case USER.SHOW_REGISTER:
      draft.registerTimestamp = Date.now();
      break;
    case USER.HIDE_REGISTER:
      draft.registerTimestamp = undefined;
      break;

    default:
      return draft;
  }
};
