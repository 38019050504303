import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import { getUnique } from '../_helpers';
import { LIST_TAGS } from '../graphql/queries';
import {
  CREATE_FILE,
  CREATE_SHARING,
  CREATE_TAGGING,
  DELETE_SHARING,
  DELETE_TAGGING,
  REPLACE_TAGGING_FILE,
  CREATE_TAG,
  DELETE_TAG,
  UPDATE_TAG,
} from '../graphql/mutations';
import { useViewer } from '../hooks';

export function useKustodio(props) {
  const { viewer = {} } = useViewer();
  const { accounts: { edges: accounts = [{ node: {} }] } = {} } = viewer;
  let account = accounts[0].node;
  const tags = useRef({});
  const [projectFiles, setProjectFiles] = useState([]);

  // TAGS

  let [listTags, { data, loading, fetchMore }] = useLazyQuery(LIST_TAGS, {
    variables: { accountId: account.id || '-', first: 100 },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const { tags: dataTags = {} } = data;
    const projectEdges = dataTags.edges.filter(({ node }) => {
      const { name = '', taggings = {} } = node;
      if (!name.match(/^codoozer\//)) {
        return false;
      }
      const { edges = [] } = taggings;
      return edges.some(({ node }) => node.name.match(/\.cdz$/));
    });

    let { edges = [] } = tags.current;
    edges = [...edges, ...projectEdges];
    tags.current = {
      edges,
      pageInfo: dataTags.pageInfo,
    };

    if (dataTags.pageInfo.hasNextPage) {
      listMoreTags();
      return;
    }

    const newProjectFiles = edges
      .reduce((cdzTaggingFiles, { node }) => {
        const { taggings = {} } = node;
        const { edges = [] } = taggings;
        const { node: tagging } = edges.find(({ node }) =>
          /\.cdz$/.test(node.name)
        );
        return getUnique([...cdzTaggingFiles, tagging]);
      }, [])
      .map((tagging) => {
        const currentProjectFile = projectFiles.find(
          ({ id }) => id === tagging.id
        );
        const isNew =
          !currentProjectFile ||
          currentProjectFile.updatedAt < tagging.updatedAt;
        return { ...tagging, isNew };
      });

    setProjectFiles((prev) => getUnique([...newProjectFiles, ...prev]));
  }, [data]);

  const listMoreTags = useCallback(
    () =>
      fetchMore &&
      fetchMore({
        variables: { cursor: tags?.current?.pageInfo?.endCursor },
        updateQuery: (prev = {}, { fetchMoreResult }) => {
          let { tags: prevTags = { edges: [] } } = prev;
          let { tags = { edges: [] } } = fetchMoreResult;

          return tags.edges.length
            ? {
                ...prev,
                tags: {
                  ...prevTags,
                  ...tags,
                  edges: [...prevTags.edges, ...tags.edges],
                },
              }
            : prev;
        },
      }),
    [tags.current.pageInfo, fetchMore]
  );

  const [createTag, { loading: loadingCreateTag }] = useMutation(CREATE_TAG, {
    onError: ({ graphQLErrors }) =>
      graphQLErrors && message.error(graphQLErrors[0]?.message),
  });

  const deleteFromKustodio = async (project) => {
    const { name } = project;
    try {
      const response = await createTag({
        variables: {
          accountId: account.id,
          name: `codoozer/${name}`,
        },
      });
      const {
        data: { createTag: folder },
      } = response;
      await deleteTag({
        variables: {
          id: folder.id,
        },
      });
      return true;
    } catch (e) {}
  };

  const [deleteTag, { loading: loadingDeleteTag }] = useMutation(DELETE_TAG, {
    onCompleted: ({ deleteTag }) =>
      setProjectFiles((projectFiles) =>
        projectFiles.filter(({ tag }) => tag.id !== deleteTag.id)
      ),
    onError: ({ graphQLErrors }) =>
      graphQLErrors && message.error(graphQLErrors[0]?.message),
  });

  const [updateTag, { loading: loadingUpdateTag }] = useMutation(UPDATE_TAG, {
    onError: ({ graphQLErrors }) =>
      graphQLErrors && message.error(graphQLErrors[0]?.message),
  });

  // TAGGINGS

  const [createFile] = useMutation(CREATE_FILE, {
    onError: ({ graphQLErrors }) =>
      graphQLErrors && message.error(graphQLErrors[0]?.message),
  });

  const [createSharing, { loading: loadingCreateSharing }] = useMutation(
    CREATE_SHARING,
    {
      onError: ({ graphQLErrors }) =>
        graphQLErrors && message.error(graphQLErrors[0]?.message),
    }
  );

  const [createTagging, { loading: loadingCreateTagging }] = useMutation(
    CREATE_TAGGING,
    {
      onError: ({ graphQLErrors }) =>
        graphQLErrors && message.error(graphQLErrors[0]?.message),
    }
  );

  const [deleteSharing, { loading: loadingDeleteSharing }] = useMutation(
    DELETE_SHARING,
    {
      onError: ({ graphQLErrors }) =>
        graphQLErrors && message.error(graphQLErrors[0]?.message),
    }
  );

  const [deleteTagging, { loading: loadingDeleteTagging }] = useMutation(
    DELETE_TAGGING,
    {
      onError: ({ graphQLErrors }) =>
        graphQLErrors && message.error(graphQLErrors[0]?.message),
    }
  );

  const [replaceTaggingFile] = useMutation(REPLACE_TAGGING_FILE, {});

  loading =
    loading ||
    loadingCreateSharing ||
    loadingCreateTagging ||
    loadingCreateTag ||
    loadingDeleteSharing ||
    loadingDeleteTag ||
    loadingDeleteTagging ||
    loadingUpdateTag;

  const saveProjectToKustodio = useCallback(
    async ({ project, file, abortController }) => {
      if (!account.id) {
        return;
      }
      const { data = {} } = project;
      const { app = {} } = data;
      const { project_name: name } = app;
      await createTag({
        variables: { accountId: account.id, name: 'codoozer' },
        context: {
          fetchOptions: {
            signal: abortController?.signal,
          },
        },
      });
      const {
        data: { createTag: folder },
      } = await createTag({
        variables: { accountId: account.id, name: `codoozer/${name}` },
        context: {
          fetchOptions: {
            signal: abortController?.signal,
          },
        },
      });
      const { taggings } = folder;
      let { node: tagging = {} } =
        taggings.edges.find(({ node }) => node.name === file.name) || {};

      if (tagging.id) {
        const {
          data: { replaceTaggingFile: taggingReplaced },
        } = await replaceTaggingFile({
          variables: { file, taggingId: tagging.id },

          context: {
            fetchOptions: {
              signal: abortController?.signal,
            },
          },
        });
        tagging = taggingReplaced;
      } else {
        const {
          data: { createFile: taggingCreated },
        } = await createFile({
          variables: { file, tagId: folder.id },

          context: {
            fetchOptions: {
              signal: abortController?.signal,
            },
          },
        });

        tagging = taggingCreated;
      }

      return tagging;
    },
    [createFile, createTag, account.id, projectFiles, replaceTaggingFile]
  );

  if (process.env.NODE_ENV === 'development') {
    console.log('KUSTODIO >>>', { projectFiles });
  }

  return {
    createFile,
    createSharing,
    createTag,
    createTagging,
    data,
    deleteFromKustodio,
    deleteSharing,
    deleteTag,
    deleteTagging,
    hasNextPage: tags.current.pageInfo?.hasNextPage,
    listTags: fetchMore ? listMoreTags : listTags,
    loading,
    projectFiles,
    replaceTaggingFile,
    saveProjectToKustodio,
    updateTag,
  };
}
