import React from 'react';
import { getLanguage, isObject } from '../../../_helpers';
import { useColor, useFile, useMenu } from '../../hooks';

const MenuItem = ({ icon, items, title }) => {
  const language = getLanguage();
  const { url } = useFile(icon);
  let textToShow = title;
  if (isObject(textToShow)) {
    textToShow =
      typeof textToShow[language] !== 'undefined'
        ? textToShow[language]
        : textToShow.default;
  }
  if (typeof textToShow === 'undefined' || textToShow === '') {
    textToShow = '';
  }
  return (
    <div className="item">
      {icon && <img className="icon" src={url} alt={textToShow} />}
      <span className="title">{textToShow}</span>
      {items && <Menu items={items} />}
    </div>
  );
};

const Menu = ({ items }) => {
  return (
    (items && (
      <div className="items">
        {items.map((attrs, index) => (
          <MenuItem key={`menu-${index}-${Date.now()}`} {...attrs} />
        ))}
      </div>
    )) ||
    null
  );
};

export const Drawer = ({ isShown, toggleDrawer, ...props }) => {
  const language = getLanguage();
  const { hex: primary } = useColor('primary');
  const { hex: primaryLight } = useColor('primaryLight');
  const { hex: primaryDark } = useColor('primaryDark');
  const { hex: primaryText } = useColor('primaryText');
  const { hex: secondaryText } = useColor('secondaryText');
  let { icon, menu = '', subtitle, title } = props;
  const { url } = useFile(icon);
  const backgroundImage = `linear-gradient(45deg, ${primaryDark}, ${primary}, ${primaryLight})`;
  subtitle = (subtitle && (subtitle[language] || subtitle.default)) || subtitle;
  title = (title && (title[language] || title.default)) || title;
  const { items } = useMenu(menu);

  return (
    <div className={`drawer ${isShown ? 'drawer-shown' : ''}`.trim()}>
      <div className="drawer-menu">
        <header style={{ backgroundImage, color: primaryText }}>
          {icon && <img className="icon" src={url} alt="drawer icon" />}
          {title && <span className="title">{title}</span>}
          {title && (
            <span className="subtitle" style={{ color: secondaryText }}>
              {subtitle}
            </span>
          )}
        </header>
        <div className="drawer-content">
          <Menu items={items} />
        </div>
      </div>
      <div
        className="drawer-bg"
        onClick={() => toggleDrawer((isShown) => !isShown)}
      ></div>
    </div>
  );
};

export default Drawer;
