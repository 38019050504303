import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'just-debounce-it';

import { isObject } from '../_helpers';

export const EventContext = createContext({});

export const EventProvider = ({
  children,
  data,
  handleJsonChange,
  getProjectRefs,
  projectType,
}) => {
  const [eventHistory, setEventHistory] = useState([]);
  const [currentEvent, _setCurrentEvent] = useState();
  const [, update] = useState();
  const eventObjects = useRef([]);
  const [eventPopoverState, setEventPopoverState] = useState({});
  const { value } = data;

  const addEventToHistoy = (event) => {
    event && setEventHistory((history) => [...history, event]);
  };

  const selectEvent = useCallback(
    (event, saveToHistory = false) => {
      let { data, parent } = event || currentEvent || {};
      if (!data) {
        return;
      }
      const { key: dataKey } = data;
      data.value = `${parent.path}/${dataKey}`
        .split('/')
        .reduce((_value, key) => {
          return _value?.[key];
        }, value)
        ?._clone();

      setCurrentEvent({ data, parent }, saveToHistory);
    },
    [currentEvent, value]
  );

  const setCurrentEvent = useCallback(
    (event, saveToHistory = true) => {
      if (!event) {
        _setCurrentEvent(null);
        return;
      }
      if (
        saveToHistory &&
        currentEvent &&
        `${event?.parent?.path}/${event?.data?.key}` !==
          `${currentEvent?.parent.path}/${currentEvent?.data?.key}`
      ) {
        addEventToHistoy(currentEvent);
      }

      const handleEventsChange = ({ value: newValue }, options) => {
        newValue = Object.values(newValue);
        let _value = value._clone();
        _value = { ..._value, events: _value.events || {} };
        const { data, parent } = event;
        const { key } = data;
        const valuePath = `['${parent?.path.replace(
          /\//g,
          `']['`
        )}']['${key}']`;
        eval(`_value${valuePath}=newValue`);
        handleJsonChange({ value: _value }, isObject(options) ? options : {});
      };

      _setCurrentEvent({
        ...event,
        getProjectRefs,
        projectType,
        onChange: handleEventsChange,
      });
    },
    [currentEvent, value]
  );

  const backToPrevEvent = useCallback(() => {
    const history = eventHistory._clone();
    const prevEvent = history.pop();
    setEventHistory(history);
    selectEvent(prevEvent, false);
  }, [eventHistory, selectEvent]);

  const addEventObject = (obj) => {
    eventObjects.current = [
      ...eventObjects.current.filter(
        ({ data, parent }) =>
          `${parent?.path}/${data?.key}` !==
          `${obj.parent?.path}/${obj.data?.key}`
      ),
      obj,
    ];
    debounce(() => update(Date.now()), 200);
  };

  const selectEventObject = ({ path, key }) => {
    const history = eventHistory._clone();
    const prevEvent = history.pop();
    if (
      `${prevEvent?.parent?.path}/${prevEvent?.data?.key}` === `${path}/${key}`
    ) {
      backToPrevEvent();
      return;
    }
    let event = eventObjects.current.find(
      ({ data, parent }) => `${parent?.path}/${data?.key}` === `${path}/${key}`
    );
    setTimeout(() => {
      selectEvent(event);
    }, 0);
  };

  useEffect(() => {
    currentEvent && selectEvent();
  }, [value]);

  if (process.env.NODE_ENV === 'development') {
    console.log('EVENT >>>', currentEvent, eventObjects, eventHistory);
  }

  return (
    <EventContext.Provider
      value={{
        currentEvent,
        setCurrentEvent,
        addEventObject,
        selectEventObject,
        eventPopoverState,
        setEventPopoverState,
        eventObjects: eventObjects.current,
        eventHistory,
        setEventHistory,
        backToPrevEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const EventConsumer = EventContext.Consumer;
export default EventContext;
