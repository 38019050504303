import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import QRCode from 'qrcode.react';
import ls from 'local-storage';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiAndroid,
  mdiApple,
  mdiCancel,
  mdiCheck,
  mdiCheckCircle,
  mdiClock,
  mdiCloseCircle,
  mdiCog,
  mdiContentCopy,
  mdiLoading,
  mdiRocketLaunch,
  mdiSitemap,
  mdiReact,
  mdiWeb,
} from '@mdi/js';
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Layout,
  List,
  Popconfirm,
  Space,
  Steps,
  Typography,
} from 'antd';
import { getLanguage, isObject, mobileCheck } from '../../_helpers';
import {
  Actions,
  DropWrapper,
  PremiumTag,
  RegisterModal,
  ResizablePanel,
  Sidebar,
  VerificationModal,
} from '../../components';
import { getDescription, PUBLICATION_STATE } from '../../context/Publication';
import {
  useAccounts,
  useAlerts,
  useKustodio,
  useProjects,
  usePublications,
  useUser,
  useViewer,
} from '../../hooks';
import { projectTypes } from '../../schemas/project';
import { logoMin } from '../../assets/svg/logos';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const { Paragraph } = Typography;
const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

const CustomDomain = ({ show: showProp = false, settings, setSettings }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [show, setShow] = useState(showProp);
  const { type } = settings;
  const { [type]: { data = {} } = {} } = settings;
  const { dns_records: dnsRecords, domain = '' } = data;

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  const handleFormChange = (changes, { ...values }) => {
    let data = type === 'amplify' ? values : null;
    setSettings((settings) => ({ ...settings, [type]: { type, data } }));
  };

  return (
    <>
      <Button className="mt-3" onClick={() => setShow((show) => !show)}>
        {t('publish.set_custom_domain')}
      </Button>
      {show && (
        <div className="d-flex" style={{ width: 0 }}>
          <Form
            className="mt-3 me-2"
            layout="vertical"
            form={form}
            initialValues={data}
            onValuesChange={handleFormChange}
          >
            {type === 'amplify' && (
              <>
                <Form.Item label={t('common.domain')} name="domain">
                  <Input placeholder="doyo.tech" style={{ width: '310px' }} />
                </Form.Item>
                {dnsRecords && (
                  <Alert
                    message={
                      <>
                        {parse(
                          t('publish.domain_verification_text', {
                            domain,
                          })
                        )}
                        <div className="mt-3">
                          {dnsRecords?.map((dns) => (
                            <pre key={dns} className="m-0">
                              <code>
                                <Paragraph
                                  className="d-inline-block user-select-all bg-warning bg-opacity-25 px-2 py-1 rounded-1"
                                  text={dns}
                                  copyable={{
                                    icon: [
                                      <Icon
                                        key="dns1CopyIcon"
                                        path={mdiContentCopy}
                                        size={0.8}
                                      />,
                                      <Icon
                                        key="dns2CopyIcon"
                                        path={mdiCheck}
                                        size={0.8}
                                      />,
                                    ],
                                    tooltips: [
                                      t('common.copy'),
                                      t('common.copied'),
                                    ],
                                  }}
                                >
                                  {dns}
                                </Paragraph>
                              </code>
                            </pre>
                          ))}
                        </div>
                      </>
                    }
                    type="warning"
                  />
                )}
              </>
            )}
          </Form>
        </div>
      )}
    </>
  );
};

const PublishActions = ({
  loading,
  setController = () => {},
  setHash = () => {},
  setLoading = () => {},
}) => {
  const { t } = useTranslation();
  const { isAllowed } = useAccounts();
  const { alertInfo, alertSuccess } = useAlerts();
  const { createSharing, saveProjectToKustodio } = useKustodio();
  const {
    project = {},
    projectToZip = () => {},
    updateProject = () => {},
  } = useProjects();
  const {
    createPublication,
    deployConfig: brainDeployConfig = {},
    getDeployConfig = () => {},
    publications = [],
    setDeployConfig = () => {},
  } = usePublications();
  const { showRegisterModal = () => {} } = useUser();
  const { showSubscriptionModal, viewer = {} } = useViewer();
  const { data: projectData = {} } = project;
  const { app = {} } = projectData;
  const { android, ios, project_name: projectName, react, type } = app;
  const [settings, setSettings] = useState({});
  const [isConfirmOpened, setConfirmOpened] = useState({});

  const createFlowPublication = async (file) => {
    const { name } = app;
    const language = getLanguage();
    let nameToShow = name;
    if (isObject(nameToShow)) {
      nameToShow =
        typeof nameToShow[language] !== 'undefined'
          ? nameToShow[language]
          : nameToShow.default;
    }
    if (typeof nameToShow === 'undefined' || nameToShow === '') {
      nameToShow = '?';
    }
    alertInfo({
      message: t('projects.publishing', { name: nameToShow }),
      open: true,
    });
    file.name = `${projectName}.cdzt`;

    const { id: taggingId } = await saveProjectToKustodio({ project, file });
    const {
      data: {
        createSharing: { token },
      },
    } = await createSharing({
      variables: { taggingId },
    });

    const updatedData = {
      ...projectData,
      app: {
        ...app,
        hash: token,
      },
    };
    updateProject({ data: updatedData });
    alertSuccess({
      message: `${t('projects.published', { name: nameToShow })}`,
      description: `Hash: <strong class="user-select-all">${token}</strong>`,
      open: true,
    });
    setHash(token);
  };

  const compile = useCallback(
    async (exportTo = '', { deploy, deployConfig }) => {
      const { auth: { token: lsToken } = {} } = ls('user') || {};
      if (!lsToken) {
        showRegisterModal();
        return;
      }
      const { message } = isAllowed({ export: exportTo });
      if (message) {
        showSubscriptionModal({ message });
        return;
      }
      setLoading(true);
      if (deployConfig) {
        await setDeployConfig(deployConfig, { project });
      }
      const { data = {}, name } = project;
      const { app = {} } = data;
      const { android = {}, type } = app;
      const { package_name: packageName = '' } = android;

      window.gtag?.('event', 'export', {
        category: viewer.id,
        label: packageName,
        value: exportTo,
      });

      const abortController = new AbortController();
      try {
        setController(abortController);
        const file = await projectToZip(project, { exportTo });
        if (type === projectTypes.FLOW) {
          file.name = `${name}.cdz`;
          createFlowPublication(file, {
            createSharing,
            project,
            saveProjectToKustodio,
            updateProject,
          });
          return;
        }
        file.name = `${name}.cdzp`;
        const { id: sourceId } = await saveProjectToKustodio({
          project,
          file,
          abortController,
        });
        if (!abortController?.signal?.aborted) {
          await createPublication({
            config: JSON.stringify({ viewer }),
            deploy,
            exportTo,
            project,
            sourceId,
          });
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [createPublication, project, projectToZip, viewer.id]
  );

  useEffect(() => {
    if (!project.uid) {
      return;
    }
    (async () => {
      const config = await getDeployConfig({ project });
      if (!config) {
        return;
      }
      const { type, data } = config;
      setSettings({ type, [type]: { type, data } });
    })();
  }, [project.uid]);

  let list = [];
  const isPublishing = publications.some(
    ({ deployment, loading, project: p }) => loading && p.uid === project.id
  );
  if (type === projectTypes.MOBILE_APP) {
    list = [
      ...list,
      {
        button: t('projects.export_button', { context: 'apk' }),
        description: t('projects.export_text', { context: 'apk' }),
        disabled: isPublishing || loading || !android,
        download: true,
        exportTo: 'apk',
        icon: mdiAndroid,
        qr: true,
        title: t('projects.export', { context: 'apk' }),
      },
      {
        button: t('projects.export_button', { context: 'aab' }),
        description: t('projects.export_text', { context: 'aab' }),
        disabled: isPublishing || loading || !android,
        download: true,
        exportTo: 'aab',
        icon: mdiAndroid,
        qr: true,
        title: t('projects.export', { context: 'aab' }),
      },
      {
        button: t('projects.export_button', { context: 'ipa' }),
        description: t('projects.export_text', { context: 'ipa' }),
        disabled: isPublishing || loading || !ios,
        download: true,
        exportTo: 'ipa',
        icon: mdiApple,
        qr: true,
        title: t('projects.export', { context: 'ipa' }),
      },
    ];
  }
  if (type === projectTypes.FLOW) {
    list = [
      ...list,
      {
        exportTo: 'flow',
        icon: mdiSitemap,
      },
    ];
  }
  if (type === projectTypes.WEB) {
    const isPublishingOnAmplify = publications.some(
      ({ deployment, loading }) => deployment === 'amplify' && loading
    );
    list = [
      ...list,
      {
        button: t('publish.amplify_button'),
        deploy: { type: 'amplify' },
        description: t('publish.amplify_description'),
        disabled: loading || isPublishingOnAmplify,
        exportTo: 'web',
        extra: (
          <CustomDomain
            show={
              settings?.type === 'amplify' && settings?.amplify?.data?.domain
            }
            settings={{ ...settings, type: 'amplify' }}
            setSettings={setSettings}
          />
        ),
        icon: mdiRocketLaunch,
        title: t('publish.amplify'),
      },
      {
        button: t('publish.ftp_button'),
        deploy: { type: 'ftp' },
        description: t('publish.ftp_description'),
        disabled:
          loading ||
          !settings?.ftp?.data?.host ||
          !settings?.ftp?.data?.username ||
          !settings?.ftp?.data?.password,
        exportTo: 'web',
        extra: (
          <DeploySettings
            show={settings?.type === 'ftp'}
            settings={{ ...settings, type: 'ftp' }}
            setSettings={setSettings}
          />
        ),
        icon: mdiRocketLaunch,
        title: t('publish.ftp'),
      },
      {
        button: t('projects.export_button', { context: 'web' }),
        description: t('projects.export_text', { context: 'web' }),
        disabled: isPublishing || loading,
        download: true,
        exportTo: 'web',
        icon: mdiWeb,
        title: t('projects.export', { context: 'web' }),
      },
      {
        button: t('projects.export_button', { context: 'react' }),
        description: t('projects.export_text', { context: 'react' }),
        disabled: isPublishing || loading || !react,
        download: true,
        exportTo: 'react',
        icon: mdiReact,
        premium: true,
        title: t('projects.export', { context: 'react' }),
      },
    ];
  }

  const handleOpenChange = (open, { exportTo, deploy, deployConfig }) => {
    if (!open) {
      setConfirmOpened({ [deploy?.type]: open });
      return;
    }
    if (
      open &&
      (deploy?.type === 'none' ||
        (settings.type === deploy?.type &&
          settings?.[deploy?.type]?.data?.domain ===
            deployConfig?.data?.domain))
    ) {
      publishConfirm(exportTo, {
        deploy,
        deployConfig,
      });
    } else {
      setConfirmOpened({ [deploy?.type]: open });
    }
  };

  const publishConfirm = (exportTo, { deploy, deployConfig }) => {
    compile(exportTo, { deploy, deployConfig });
    setConfirmOpened({ [deploy?.type]: false });
  };

  return (
    <>
      <h4 className="mt-2 mb-3 pb-2 border-bottom">{t('common.publish')}</h4>
      <List
        dataSource={list}
        renderItem={({
          button,
          deploy = { type: 'none' },
          description,
          disabled,
          download,
          extra,
          exportTo,
          icon,
          premium,
          qr,
          title,
        }) => {
          const published =
            publications.find(
              ({
                deployment,
                format,
                project: p,
                project_uid: projectUid,
                state,
              }) =>
                (projectUid === project.id || p?.uid === project.id) &&
                deployment === deploy?.type &&
                format === exportTo &&
                state === PUBLICATION_STATE.PUBLISHED
            ) || {};
          const { deployment, result_url: url, version } = published;
          const { type: deployType } = deploy;
          const deployConfig =
            deployType !== 'none' &&
            (settings[deployType] || { type: deployType });
          let amplifyUrl = '';
          if (deployment === 'amplify' && deployConfig?.data?.url) {
            const {
              [project.uid]: { data = {} },
            } = brainDeployConfig;
            const { url } = data;
            amplifyUrl = url;
          }

          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Icon path={icon} size={1} />}
                title={
                  <Space>
                    {title}
                    {premium && <PremiumTag feature={{ export: exportTo }} />}
                  </Space>
                }
                description={
                  <>
                    {description}
                    <div>
                      <Space align="top">
                        {extra}
                        <Popconfirm
                          open={isConfirmOpened[deploy?.type]}
                          placement="bottom"
                          title={t('publish.confirm_publish', { deploy })}
                          onOpenChange={(open) =>
                            handleOpenChange(open, {
                              exportTo,
                              deploy,
                              deployConfig,
                            })
                          }
                          onConfirm={() =>
                            publishConfirm(exportTo, { deploy, deployConfig })
                          }
                          okText={`${t('common.yes')}, ${t('common.delete')}`}
                          okButtonProps={{ danger: true }}
                          cancelText={t('common.no')}
                        >
                          <Button
                            key={`export-to-${exportTo}`}
                            className="my-3"
                            disabled={disabled}
                            type={
                              !download && !amplifyUrl ? 'primary' : 'default'
                            }
                          >
                            {button}
                          </Button>
                        </Popconfirm>
                        {download && (
                          <>
                            <a href={url}>
                              <Button
                                key={`export-to-${exportTo}`}
                                className="my-3"
                                name={exportTo}
                                disabled={!url}
                                type="primary"
                              >
                                {t('common.download')}
                                {version && ` - v${version}`}
                              </Button>
                            </a>
                            {url && qr && (
                              <QRCode
                                className="my-3"
                                value={url}
                                size={100}
                                includeMargin={false}
                                imageSettings={{
                                  src: logoMin,
                                  height: 16,
                                  width: 16,
                                  excavate: true,
                                }}
                              />
                            )}
                          </>
                        )}
                        {deployType === 'amplify' && (
                          <a href={amplifyUrl} target="_blank" rel="noreferrer">
                            <Button
                              key={`export-to-${exportTo}`}
                              className="my-3"
                              name={exportTo}
                              disabled={!amplifyUrl}
                              type="primary"
                            >
                              {t('common.visit')}
                              {version && ` - v${version}`}
                            </Button>
                          </a>
                        )}
                      </Space>
                    </div>
                  </>
                }
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};

const DeploySettings = ({ show: showProp = false, settings, setSettings }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [show, setShow] = useState(showProp);
  const { type } = settings;
  const { [type]: { data = {} } = {} } = settings;

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  const handleFormChange = (changes, { ...values }) => {
    let data = type === 'ftp' ? values : null;
    setSettings((settings) => ({ ...settings, [type]: { type, data } }));
  };

  return (
    <>
      <Button className="mt-3" onClick={() => setShow((show) => !show)}>
        {t('publish.ftp_config')}
      </Button>
      {show && (
        <div className="d-flex" style={{ width: 0 }}>
          <Form
            className="mt-3 me-2"
            layout="vertical"
            form={form}
            initialValues={data}
            onValuesChange={handleFormChange}
          >
            {type === 'ftp' && (
              <>
                <Form.Item
                  label={t('publish.host')}
                  name="host"
                  rules={[
                    {
                      required: true,
                      message: t('publish.host_required_message'),
                    },
                  ]}
                >
                  <Input placeholder="doyo.tech" style={{ width: '310px' }} />
                </Form.Item>
                <Form.Item
                  label={t('publish.user')}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t('publish.user_required_message'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('common.email_placeholder')}
                    style={{ width: '310px' }}
                  />
                </Form.Item>
                <Form.Item
                  label={t('publish.password')}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('publish.password_required_message'),
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="******"
                    style={{ width: '310px' }}
                  />
                </Form.Item>
                <Form.Item label={t('publish.port')} name="port">
                  <InputNumber placeholder="21" style={{ width: '310px' }} />
                </Form.Item>
                <Form.Item label={t('publish.folder')} name="folder">
                  <Input placeholder="/" style={{ width: '310px' }} />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      )}
    </>
  );
};

const Publications = () => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const { getPublications = () => {}, publications = [] } = usePublications();
  const icon = {
    [PUBLICATION_STATE.WAITING]: (
      <Icon path={mdiClock} className="text-gray" size={1} />
    ),
    [PUBLICATION_STATE.PROCESSING]: (
      <Icon path={mdiCog} className="text-warning" size={1} spin />
    ),
    [PUBLICATION_STATE.DEPLOYING]: (
      <Icon path={mdiRocketLaunch} className="text-warning" size={1} />
    ),
    [PUBLICATION_STATE.PUBLISHED]: (
      <Icon path={mdiCheckCircle} className="text-primary" size={1} />
    ),
    [PUBLICATION_STATE.FAILED]: (
      <Icon path={mdiCloseCircle} className="text-danger" size={1} />
    ),
    [PUBLICATION_STATE.CANCELLED]: (
      <Icon path={mdiCancel} className="text-danger" size={1} />
    ),
  };

  useEffect(() => {
    project.id && getPublications({ project });
  }, [project.id]);

  return (
    <>
      <List
        dataSource={publications.filter(
          (publication) =>
            publication.project_uid === project?.uid ||
            publication.project?.uid === project?.uid
        )}
        renderItem={({
          created_at: createdAt,
          deployment,
          format,
          state,
          version,
        }) => {
          const createdAtDate = dayjs(createdAt * 1000);
          const publishDate = new Date(createdAtDate).getTime() - Date.now();
          const title =
            deployment === 'none'
              ? t('projects.export', {
                  context: format,
                })
              : t(`publish.${deployment}`);
          return (
            <List.Item>
              <List.Item.Meta
                avatar={icon[state]}
                title={`${title} - v${version}`}
                description={<>{dayjs.duration(publishDate).humanize(true)}</>}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};

const PublishContent = ({ loading, setController, setLoading }) => {
  return (
    <div className="p-3 flex-grow-1 overflow-auto">
      <PublishActions
        loading={loading}
        setController={setController}
        setLoading={setLoading}
      />
    </div>
  );
};

const PublishProgress = ({ controller, loading, setLoading }) => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const { cancelCurrentPublication = () => {}, publications = [] } =
    usePublications();
  const { id: projectId } = project;
  let publication = {};
  try {
    publication = publications.find(({ project }) => {
      return project?.uid === projectId;
    });
  } catch (error) {
    console.error(error);
  }
  let {
    created_at: createdAt = Date.now(),
    deployment = 'none',
    estimations = {},
    format,
    result_url: url,
    state,
  } = publication || {};
  if (
    loading &&
    (!state ||
      [PUBLICATION_STATE.PUBLISHED, PUBLICATION_STATE.FAILED].includes(state))
  ) {
    state = PUBLICATION_STATE.WAITING;
  }
  const { position, time = 0 } = estimations;

  const handleCancelCurrentPublication = () => {
    if (loading) {
      controller.abort();
      setLoading(false);
      return;
    }
    cancelCurrentPublication({ project });
  };

  const progressMapping = {
    [PUBLICATION_STATE.WAITING]: 0,
    [PUBLICATION_STATE.PROCESSING]: 1,
    [PUBLICATION_STATE.DEPLOYING]: deployment ? 2 : undefined,
    [PUBLICATION_STATE.PUBLISHED]: deployment ? 4 : 3,
    [PUBLICATION_STATE.FAILED]: url ? 2 : 1,
    [PUBLICATION_STATE.CANCELLED]: url ? 2 : 1,
  };
  let statusMapping = {
    [PUBLICATION_STATE.WAITING]: 'process',
    [PUBLICATION_STATE.PROCESSING]: 'process',
    [PUBLICATION_STATE.DEPLOYING]: 'process',
    [PUBLICATION_STATE.PUBLISHED]: 'finish',
    [PUBLICATION_STATE.FAILED]: '',
    [PUBLICATION_STATE.CANCELLED]: '',
  };
  let currentProgress = progressMapping[state];
  currentProgress =
    typeof currentProgress === 'undefined' ? -1 : currentProgress;
  const status = statusMapping[state] || '';
  const publishDate =
    new Date(dayjs(createdAt * 1000).add(time, 'minutes')).getTime() -
    Date.now();

  if (!publication && !loading) {
    return <List dataSource={[]} />;
  }
  return (
    <>
      <Steps
        direction="vertical"
        size="small"
        status={status}
        current={currentProgress}
        items={[
          {
            icon:
              ((loading || state === PUBLICATION_STATE.WAITING) && (
                <Icon
                  path={mdiLoading}
                  className="text-primary"
                  spin
                  size={1}
                />
              )) ||
              (currentProgress > progressMapping[PUBLICATION_STATE.WAITING] && (
                <Icon path={mdiCheckCircle} className="text-primary" size={1} />
              )),
            title: t('publish.waiting'),
            description:
              currentProgress === progressMapping[PUBLICATION_STATE.WAITING] &&
              ((position &&
                parse(t('publish.waiting_description', { position }))) ||
                parse(t('publish.generating_cdz'))),
          },
          {
            icon: (!loading &&
              state === PUBLICATION_STATE.CANCELLED &&
              !url && (
                <Icon path={mdiCancel} className="text-danger" size={1} />
              )) ||
              (state === PUBLICATION_STATE.FAILED && !url && (
                <Icon path={mdiCloseCircle} className="text-danger" size={1} />
              )) ||
              (!loading &&
                currentProgress ===
                  progressMapping[PUBLICATION_STATE.PROCESSING] && (
                  <Icon path={mdiCog} className="text-warning" size={1} spin />
                )) ||
              (currentProgress >
                progressMapping[PUBLICATION_STATE.PROCESSING] && (
                <Icon path={mdiCheckCircle} size={1} />
              )) || <Icon path={mdiClock} className="text-gray" size={1} />,
            title:
              (!loading &&
                state === PUBLICATION_STATE.CANCELLED &&
                !url &&
                t('common.cancelled')) ||
              t('publish.processing'),
            description:
              (state === PUBLICATION_STATE.FAILED &&
                !url &&
                parse(getDescription({ publication, t })).map((desc, i) => {
                  if (
                    desc.props &&
                    ['errors', 'warnings'].includes(desc.props.className)
                  ) {
                    const { props } = desc;
                    let { children } = props;
                    children = Array.isArray(children) ? children : [children];
                    return {
                      ...desc,
                      key: `Description-${i}`,
                      props: {
                        ...desc.props,
                        children: children.map((li, i) => ({
                          ...li,
                          props: {
                            ...li.props,
                            children: (
                              <Link to={li.props.to}>{li.props.children}</Link>
                            ),
                          },
                        })),
                      },
                    };
                  }
                  return (
                    <React.Fragment key={`Description-${i}`}>
                      {desc}
                    </React.Fragment>
                  );
                })) ||
              (currentProgress ===
                progressMapping[PUBLICATION_STATE.PROCESSING] && (
                <>
                  {parse(
                    t('publish.processing_description', {
                      format,
                    })
                  )}
                  <br />
                  {publishDate > 0 &&
                    t('projects.publish_time', {
                      time: dayjs.duration(publishDate).humanize(false),
                    })}
                </>
              )),
          },
          deployment === 'none' || loading
            ? null
            : {
                icon: (!loading &&
                  state === PUBLICATION_STATE.CANCEL &&
                  url && (
                    <Icon path={mdiCancel} className="text-danger" size={1} />
                  )) ||
                  (state === PUBLICATION_STATE.FAILED && url && (
                    <Icon
                      path={mdiCloseCircle}
                      className="text-danger"
                      size={1}
                    />
                  )) ||
                  (!loading &&
                    (state === PUBLICATION_STATE.CANCELLED ||
                      state === PUBLICATION_STATE.FAILED) && (
                      <Icon
                        path={mdiCancel}
                        className="bg-gray text-white rounded-circle"
                        size={1}
                      />
                    )) ||
                  (currentProgress >
                    progressMapping[PUBLICATION_STATE.DEPLOYING] && (
                    <Icon
                      path={mdiCheckCircle}
                      className="text-primary"
                      size={1}
                    />
                  )) ||
                  (!loading &&
                    currentProgress ===
                      progressMapping[PUBLICATION_STATE.DEPLOYING] && (
                      <Icon
                        path={mdiRocketLaunch}
                        className="text-warning"
                        size={1}
                      />
                    )) || (
                    <Icon path={mdiClock} className="text-gray" size={1} />
                  ),
                title:
                  (!loading &&
                    state === PUBLICATION_STATE.CANCELLED &&
                    url &&
                    t('common.cancelled')) ||
                  t('publish.deploying'),
                description:
                  (state === PUBLICATION_STATE.FAILED && url && <></>) ||
                  (currentProgress ===
                    progressMapping[PUBLICATION_STATE.DEPLOYING] &&
                    parse(t('publish.deploying_description'))),
              },

          {
            icon: (!loading &&
              (state === PUBLICATION_STATE.CANCELLED ||
                state === PUBLICATION_STATE.FAILED) && (
                <Icon
                  path={mdiCancel}
                  className="bg-gray text-white rounded-circle"
                  size={1}
                />
              )) ||
              (currentProgress >
                progressMapping[PUBLICATION_STATE.PUBLISHED] - 1 && (
                <Icon path={mdiCheckCircle} className="text-primary" size={1} />
              )) || <Icon path={mdiClock} className="text-gray" size={1} />,
            title: t('publish.published'),
            subTitle:
              (state === PUBLICATION_STATE.PUBLISHED &&
                publishDate &&
                dayjs.duration(publishDate).humanize(true)) ||
              '',
            description: state !== PUBLICATION_STATE.FAILED && (
              <>
                {currentProgress ===
                  progressMapping[PUBLICATION_STATE.PUBLISHED] &&
                  parse(t('publish.published_description', { format }))}
              </>
            ),
          },
        ]}
      />
      {(loading ||
        [PUBLICATION_STATE.WAITING, PUBLICATION_STATE.PROCESSING].includes(
          state
        )) && (
        <Button block danger onClick={handleCancelCurrentPublication}>
          {t('common.cancel')}
        </Button>
      )}
    </>
  );
};

const LeftPanel = ({ controller, loading, setLoading }) => {
  const { t } = useTranslation();
  return (
    <ResizablePanel
      name="publications"
      axis="x"
      handle="e"
      className="h-100 border-end"
      minConstraints={[280, 0]}
      maxConstraints={[window.outerWidth / 3, 0]}
    >
      <div className="overflow-auto h-100">
        <div className="header border-0">
          <small>{t('publish.last_publication')}</small>
        </div>
        <div className="p-3">
          <PublishProgress
            controller={controller}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <div
          className="header"
          style={{
            top: '40px',
          }}
        >
          <small>{t('publish.publications')}</small>
        </div>
        <div className="p-3">
          <Publications />
        </div>
      </div>
    </ResizablePanel>
  );
};

export const PublishPage = ({ match }) => {
  const {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  } = match;
  const {
    project = {},
    projects = [],
    selectProject = () => {},
    unselectProject = () => {},
  } = useProjects();
  const [controller, setController] = useState();
  const [loading, setLoading] = useState(false);
  const { edges: allProjects = [] } = projects;
  let path = decodeURIComponent(`/${projectNamePath}/${fileNamePath}/publish`);

  useEffect(() => {
    const { node: projectToSelect } =
      allProjects.find(({ node: { data = {} } }) => {
        const { app = {} } = data;
        const { project_name: name } = app;
        return name === projectNamePath;
      }) || {};
    if (projectToSelect) {
      projectToSelect.id !== project.id && selectProject(projectToSelect);
    } else {
      unselectProject();
    }
  }, [projectNamePath, fileNamePath, allProjects]);

  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar match={match} path={path} />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ height: '40px' }}>
            <Actions />
          </Layout.Header>
          <Layout.Content id="WorkingArea" className="d-flex">
            <LeftPanel
              controller={controller}
              loading={loading}
              setLoading={setLoading}
            />
            <PublishContent
              loading={loading}
              setController={setController}
              setLoading={setLoading}
            />
          </Layout.Content>
        </Layout>
      </Layout>
      <DropWrapper />
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default PublishPage;
