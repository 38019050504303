import React, { useCallback, useEffect, useRef } from 'react';
import Component from './Component';
import { useColor } from '../../hooks';

export const CanvasCompiler = (props) => {
  return <Canvas {...props} />;
};

export const Canvas = (props) => {
  const canvas = useRef();
  const ctx = useRef();
  const coords = useRef({ x: 0, y: 0 });
  let {
    color = '@color.primaryText',
    height,
    stroke_width: strokeWidth = 2,
    width,
  } = props;
  const { hex: _color = '' } = useColor(color);

  if (color && color.includes('@')) {
    color = _color;
  }

  const startPainting = useCallback(
    (event) => {
      const { left, top } = canvas.current.getBoundingClientRect();
      coords.current = {
        x: event.clientX - left,
        y: event.clientY - top,
      };
      ctx.current.beginPath();
      canvas.current.addEventListener('mousemove', sketch);
    },
    [canvas, ctx]
  );

  const stopPainting = useCallback(() => {
    canvas.current?.removeEventListener('mousemove', sketch);
  }, [canvas]);

  const sketch = useCallback(
    (event) => {
      const { left, top } = canvas.current.getBoundingClientRect();
      ctx.current.moveTo(coords.current.x, coords.current.y);
      coords.current = {
        x: event.clientX - left,
        y: event.clientY - top,
      };
      ctx.current.lineTo(coords.current.x, coords.current.y);
      ctx.current.stroke();
    },
    [canvas, color, strokeWidth]
  );

  useEffect(() => {
    if (!canvas.current) {
      return;
    }
    ctx.current = canvas.current.getContext('2d');
    const { height, width } =
      canvas.current.parentElement.getBoundingClientRect();
    ctx.current.canvas.width = width;
    ctx.current.canvas.height = height;
    ctx.current.lineWidth = strokeWidth;
    ctx.current.lineJoin = 'round';
    ctx.current.lineCap = 'round';
    ctx.current.strokeStyle = color;
    canvas.current.addEventListener('mousedown', startPainting);
    document.addEventListener('mouseup', stopPainting);

    return () => {
      document.removeEventListener('mousedown', startPainting);
      document.removeEventListener('mouseup', stopPainting);
      //canvas.current?.removeEventListener('mousemove', sketch);
    };
  }, [canvas, height, width]);

  return <Component {...props} ref={canvas} component="canvas" />;
};

export default Canvas;
