export const GENERAL = {
  INITIAL_STATE: {
    location: {},
  },
  GET_LOCATION: 'LOCATION_GET',
};

export const GeneralReducer = (draft, action) => {
  const { payload, type } = action;

  switch (type) {
    case GENERAL.GET_LOCATION:
      draft.location = payload.location;
      break;

    default:
      return draft;
  }
};
