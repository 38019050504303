import React, { createContext, useCallback, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { IdentifierProvider } from './Identifier';
import { getHeaders, IDENTIFIER_TYPE } from '.';
import { AccountReducer, ACCOUNT } from './reducers';
import { SECRETS, URL } from '../_config';
import { DELETE_ACCOUNT, UPDATE_ACCOUNT } from '../graphql/mutations';
import { LIST_VIEWER_ACCOUNTS } from '../graphql/queries';

export const ACCOUNT_TYPE = {
  ANONYMOUS: 'ANONYMOUS',
  PERSONAL: 'PERSONAL',
  TEAM: 'TEAM',
};

const premium = {
  export: { react: ['Reseller'] },
};

export const AccountContext = createContext(ACCOUNT.INITIAL_STATE);

export const AccountProvider = ({ children }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useImmerReducer(
    AccountReducer,
    ACCOUNT.INITIAL_STATE
  );
  const { account, accounts, pageInfo = {} } = state;
  const { customer = {}, identifiers: { edges: identifiers = [] } = {} } =
    account || {};
  const { subscription = {} } = customer || {};
  let { plan: { product: { name: subscriptionName } = {} } = {} } =
    subscription || {};
  if (customer === null) {
    subscriptionName = 'Free';
  }
  const { endCursor: cursor, hasNextPage } = pageInfo;
  const { node: { subject: accountEmail } = {} } =
    identifiers.find(
      ({ node }) => node.default && node.type === IDENTIFIER_TYPE.EMAIL
    ) || {};

  let { fetchMore, loading, refetch } = useQuery(LIST_VIEWER_ACCOUNTS, {
    context: { passport: true },
    variables: { first: 10 },
    onCompleted: (data) => {
      const { viewer } = data;
      dispatch({ type: ACCOUNT.LIST, payload: { accounts: viewer.accounts } });
    },
  });

  const fetchMoreAccounts = () => {
    fetchMore({
      context: { passport: true },
      variables: { cursor },
      updateQuery: ({ viewer }, { fetchMoreResult }) => {
        let { accounts } = fetchMoreResult.viewer;
        dispatch({
          type: ACCOUNT.LIST,
          payload: { accounts },
        });
        return accounts.edges.length
          ? {
              viewer: {
                ...viewer,
                accounts: {
                  ...accounts,
                  edges: [...viewer.accounts.edges, ...accounts.edges],
                },
              },
            }
          : { viewer };
      },
    });
  };

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    context: { passport: true },
    onCompleted: ({ deleteAccount }) =>
      dispatch({ type: ACCOUNT.DELETE, payload: { ...deleteAccount } }),
    onError: (error) => dispatch({ type: ACCOUNT.ERROR, payload: { error } }),
  });

  const getCustomer = useCallback(async () => {
    dispatch({
      type: ACCOUNT.SUBSCRIPTION,
      payload: { customer: { loading: true } },
    });
    try {
      const { data = {} } = await (
        await fetch(`${URL.BILLING}/accounts/${account.id}`, {
          headers: getHeaders({ auth: true }),
        })
      ).json();
      const { stripe_customer: customer } = data;
      dispatch({
        type: ACCOUNT.SUBSCRIPTION,
        payload: { customer },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ACCOUNT.SUBSCRIPTION,
        payload: { customer: null },
      });
    }
  }, [account]);

  const selectAccount = ({ id }) => {
    dispatch({
      type: ACCOUNT.SELECT,
      payload: { account: accounts.find(({ node }) => node.id === id) },
    });
  };

  const [updateAccount, { loading: updateAccountLoading }] = useMutation(
    UPDATE_ACCOUNT,
    {
      context: { passport: true },
      onCompleted: () =>
        refetch().then(
          ({
            data: {
              viewer: { accounts },
            },
          }) => dispatch({ type: ACCOUNT.LIST, payload: { accounts } })
        ),
      onError: (error) => dispatch({ type: ACCOUNT.ERROR, payload: { error } }),
    }
  );

  const isAllowed = (params) => {
    const entries = Object.entries(params);
    let message = null;
    entries.forEach(([key, value]) => {
      const allowedPlans = premium[key]?.[value];
      if (value && allowedPlans && !allowedPlans?.includes(subscriptionName)) {
        message = t(`validation.${key}.${value}`);
      }
    });

    return { ok: !message, message };
  };

  useEffect(() => {
    hasNextPage && fetchMoreAccounts();
  }, [pageInfo]);

  useEffect(() => {
    if (!account?.id) {
      return;
    }
    getCustomer();
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: SECRETS.INTERCOM_APP_ID,
      'Account Id': account?.id,
      name: account?.name,
      email: accountEmail,
      creation_date: account?.createdAt,
    });
    window.Intercom('update');
  }, [account?.id]);

  useEffect(() => {
    if (typeof subscriptionName === 'undefined') {
      return;
    }
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: SECRETS.INTERCOM_APP_ID,
      plan: subscriptionName,
    });
    window.Intercom('update');
  }, [subscriptionName]);

  loading = loading || updateAccountLoading;

  if (process.env.NODE_ENV === 'development') {
    console.log('ACCOUNT >>>', state);
  }

  return (
    <AccountContext.Provider
      value={{
        ...state,
        isAllowed,
        loading,
        deleteAccount,
        getCustomer,
        selectAccount,
        updateAccount,
      }}
    >
      {(account && <IdentifierProvider>{children}</IdentifierProvider>) ||
        children}
    </AccountContext.Provider>
  );
};

export const AccountConsumer = AccountContext.Consumer;
export default AccountContext;
