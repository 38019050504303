import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import Icon from '@mdi/react';
import {
  mdiAccountAlert,
  mdiAccount,
  mdiAccountGroup,
  mdiCheckDecagram,
  mdiClose,
  mdiDotsVertical,
} from '@mdi/js';
import {
  Alert,
  Button,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  Radio,
  Tag,
  Tooltip,
} from 'antd';
import { Container, Table } from 'react-bootstrap';
import { ChangePasswordForm, NewIdentifierWrapper } from '.';
import { SECRETS } from '../../_config';
import { fromNow, getAvatar } from '../../_helpers';
import {
  ACCOUNT_TYPE,
  IDENTIFIER_TYPE,
  VERIFICABLE_IDENTIFIER_TYPE,
} from '../../context';
import {
  CirclePreloader,
  CustomModal,
  Language,
  PasswordInput,
  Sidebar,
} from '../../components';
import {
  useAccounts,
  useIdentifiers,
  useVerifications,
  useViewer,
} from '../../hooks';

const AccountType = () => {
  const { t } = useTranslation();
  const { account = {} } = useAccounts();
  const { type } = account;
  const color = {
    [ACCOUNT_TYPE.ANONYMOUS]: 'info',
    [ACCOUNT_TYPE.PERSONAL]: 'success',
    [ACCOUNT_TYPE.TEAM]: 'warning',
  };
  const icon = {
    [ACCOUNT_TYPE.ANONYMOUS]: mdiAccountAlert,
    [ACCOUNT_TYPE.PERSONAL]: mdiAccount,
    [ACCOUNT_TYPE.TEAM]: mdiAccountGroup,
  };

  return (
    (type && (
      <>
        <Alert
          type={color[type]}
          icon={<Icon path={icon[type]} size={1} />}
          showIcon
          message={t(`account.text_type_${type.toLowerCase()}`)}
        />
        <hr className="mt-0 mb-5 border-0" />
      </>
    )) ||
    ''
  );
};

const BasicAspects = () => {
  const { t } = useTranslation();
  const { viewer = {} } = useViewer();
  const { account = {} } = useAccounts();
  const { fullName = '' } = viewer;
  const { name = fullName, type } = account;
  let { avatar: hash } = account;

  return (
    <>
      <h4 className="mt-2 mb-3 pb-2 border-bottom">
        {t('account.basic_aspects')}
      </h4>
      <Table className="mb-0">
        <tbody>
          <tr>
            <td className="align-middle">{t('common.avatar')}</td>
            <td
              className="align-middle d-none d-sm-table-cell"
              data-cy="avatar"
            >
              <img
                width="128"
                alt="avatar"
                src={getAvatar({ hash })}
                className="rounded-circle border-primary"
              />
            </td>
            <td className="align-middle text-end">
              <CustomModal
                button={t('common.change')}
                buttonParameters={{
                  type: 'link',
                  size: 'small',
                }}
                title={`${t('common.change')} ${t('common.avatar')}`}
              >
                <p>{t('account.gravatar_description')}</p>
                <Button
                  type="primary"
                  href="https://es.gravatar.com/emails/"
                  target="_blank"
                >
                  {`${t('common.go_to')} Gravatar`}
                </Button>
              </CustomModal>
            </td>
          </tr>
          <tr>
            <td className="align-middle">{t('common.name')}</td>
            <td className="align-middle d-none d-sm-table-cell" data-cy="name">
              {name}
            </td>
            <td className="align-middle text-end">
              {(type === ACCOUNT_TYPE.PERSONAL ||
                type === ACCOUNT_TYPE.TEAM) && (
                <CustomModal
                  button={t('common.change')}
                  buttonParameters={{
                    'data-cy': 'change-name',
                    type: 'link',
                    size: 'small',
                  }}
                  title={`${t('common.change')} ${t('common.name')}`}
                >
                  <UserName />
                </CustomModal>
              )}
            </td>
          </tr>
          <ChangePassword />
        </tbody>
      </Table>
      <hr className="mt-0 mb-5 border-0" />
    </>
  );
};

const UserName = () => {
  const { t } = useTranslation();
  const { viewer = {}, updateUser } = useViewer();
  let { id, name, surname } = viewer;
  name = name || '';
  surname = surname || '';
  const attributes = useRef({ id, name, surname });

  const handleChange = (value) => {
    attributes.current = { ...attributes.current, ...value };
  };

  const handleSubmit = (event) => {
    updateUser({ variables: { ...attributes.current } });
    window.$('.close').click();
  };

  return (
    <Form
      id="ChangeForm"
      initialValues={{ name, surname }}
      onValuesChange={handleChange}
      onFinish={handleSubmit}
    >
      <Form.Item label={t('common.name')} name="name">
        <Input placeholder={t('common.name_placeholder')} maxLength="50" />
      </Form.Item>
      <Form.Item label={t('common.surname')} name="surname">
        <Input placeholder={t('common.surname_placeholder')} maxLength="50" />
      </Form.Item>
      <Form.Item className="m-0 text-end">
        <Button type="primary" data-cy="save" htmlType="submit">
          {t('common.save')}
        </Button>
      </Form.Item>
    </Form>
  );
};

const ChangePassword = () => {
  const { t } = useTranslation();

  return (
    <tr>
      <td className="align-middle">{t('common.password')}</td>
      <td className="align-middle d-none d-sm-table-cell pb-0">**********</td>
      <td className="align-middle text-end">
        <CustomModal
          button={t('common.change')}
          buttonParameters={{
            type: 'link',
            size: 'small',
          }}
          title={`${t('common.change')} ${t('common.password')}`}
        >
          <ChangePasswordForm />
        </CustomModal>
      </td>
    </tr>
  );
};

const Identifiers = ({
  setIdentifierToDelete,
  types = Object.values(IDENTIFIER_TYPE),
}) => {
  const { t } = useTranslation();
  const { identifiers = [], setIdentifierAsDefault } = useIdentifiers();
  const { createVerification } = useVerifications();

  return types.map((type, i) => (
    <React.Fragment key={i}>
      <h4 className="mt-2 mb-3 pb-2 border-bottom">
        {t(`common.${type.toLowerCase()}`)}
      </h4>

      <Table className="mb-0">
        <tbody>
          {identifiers
            .filter(({ node }) => node.type === type)
            .map(({ node: identifier }, i) => {
              const {
                default: isDefault,
                id,
                subject,
                type,
                verifiedAt,
              } = identifier;
              const isTheOneofType =
                identifiers.filter((i) => i.type === type).length === 1;
              const isVerificable = VERIFICABLE_IDENTIFIER_TYPE.includes(type);
              const areManyDefaultsVerified =
                identifiers.filter((i) => i.default && !!i.verified_at).length >
                1;

              return (
                <tr key={i}>
                  <td className="align-middle" data-cy={type}>
                    <span className="position-relative">{subject}</span>
                    {verifiedAt && (
                      <>
                        <Tooltip
                          title={`${t('common.verified')} ${fromNow(
                            verifiedAt
                          )}`}
                        >
                          <Icon
                            id={`Identifier${id}`}
                            className="text-success ml-2"
                            path={mdiCheckDecagram}
                            size={1}
                          />
                        </Tooltip>
                      </>
                    )}
                  </td>
                  <td className="align-middle text-end ">
                    {isDefault && (
                      <Tag
                        color="success"
                        className="align-middle ml-2 line-height-1 my-1 border-bottom border-success"
                      >
                        {t('common.default').toUpperCase()}
                      </Tag>
                    )}

                    {(!isDefault ||
                      (!verifiedAt && isVerificable) ||
                      (isTheOneofType && !verifiedAt) ||
                      areManyDefaultsVerified) && (
                      <div className="align-middle ml-2 d-inline-block">
                        <Dropdown
                          trigger="click"
                          placement="bottomRight"
                          overlay={
                            <Menu
                              items={[
                                {
                                  key: '1',
                                  label: t('common.set_as_default'),
                                  onClick: () =>
                                    setIdentifierAsDefault({
                                      variables: { id },
                                    }),
                                  disabled: isDefault || !verifiedAt,
                                },
                                !isVerificable
                                  ? null
                                  : {
                                      key: '2',
                                      label: t('common.verificate'),
                                      onClick: () =>
                                        createVerification({
                                          variables: {
                                            identifierId: id,
                                          },
                                        }),
                                      disabled: verifiedAt,
                                    },
                                {
                                  key: '3',
                                  label: t('common.delete'),
                                  onClick: () => {
                                    setIdentifierToDelete(identifier);
                                  },
                                  danger: true,
                                  disabled:
                                    isDefault || (isTheOneofType && verifiedAt),
                                },
                              ]}
                            />
                          }
                        >
                          <Button
                            type="white"
                            className="bg-transparent border-0 square badge-pill p-0"
                            style={{ width: '30px' }}
                          >
                            <Icon
                              path={mdiDotsVertical}
                              size={1}
                              className="d-flex"
                            />
                          </Button>
                        </Dropdown>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          <tr>
            <td colSpan={2}>
              <CustomModal
                id="NewIdentifierWrapper"
                button={t(`account.add_new_${type.toLowerCase()}`)}
                buttonParameters={{
                  className: 'text-uppercase font-weight-bold',
                  type: 'primary',
                  size: 'small',
                }}
                title={`${t('account.create_identifier')}`}
              >
                <NewIdentifierWrapper type={type} />
              </CustomModal>
            </td>
          </tr>
        </tbody>
      </Table>

      <hr className="mt-0 mb-5 border-0" />
    </React.Fragment>
  ));
};

const Preferences = () => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { announcements } = viewer || {};

  const setAnnouncements = () => {
    //dispatch();
  };

  return (
    <>
      <h4 className="mt-2 mb-3 pb-2 border-bottom">
        {t('account.preferences')}
      </h4>
      <Table className="mb-0">
        <tbody>
          <tr>
            <td className="align-middle">{t('common.language')}</td>
            <td className="align-middle text-end">
              <Language />
            </td>
          </tr>
          <tr>
            <td className="align-middle">
              {t('common.recieve_communications')}
            </td>
            <td className="align-middle text-end" data-cy="communications">
              <Radio.Group
                buttonStyle="solid"
                onChange={(value) => setAnnouncements(value === '1')}
              >
                <Radio.Button value="1" checked={announcements}>
                  {t('common.yes')}
                </Radio.Button>
                <Radio.Button value="0" checked={announcements === false}>
                  {t('common.no')}
                </Radio.Button>
              </Radio.Group>
            </td>
          </tr>
        </tbody>
      </Table>
      <hr className="mt-0 mb-5 border-0" />
    </>
  );
};

const DangerZone = ({ currentPassword, setCurrentPassword }) => {
  const { t } = useTranslation();
  const { account = {}, accounts = [], deleteAccount } = useAccounts();
  const { viewer = {}, deleteUser, logout } = useViewer();
  const recaptchaRef = useRef();
  const submitted = useRef(false);
  const [captcha, setCaptcha] = useState('');
  const { id } = account;
  const { id: idViewer } = viewer;

  useEffect(() => {
    if (submitted.current) {
      if (accounts.length === 1) {
        deleteUser({
          variables: { id: idViewer, currentPassword, captcha },
        });
        logout();
        return;
      }
      deleteAccount({
        variables: { id, currentPassword, captcha },
      });
    }
  }, [captcha]);

  return (
    <>
      <h4 className="mt-2 mb-3 pb-2 border-bottom text-danger">
        {t('common.danger_zone')}
      </h4>
      <div className="border rounded border-danger mb-5" data-cy="danger-zone">
        <Table className="mb-0">
          <tbody>
            <tr>
              <td className="align-middle border-0">
                {t('account.delete_account')}
                <p className="small m-0">
                  {t('account.delete_account_description')}
                </p>
              </td>
              <td className="align-middle border-0">
                <CustomModal
                  button={t('account.delete_account')}
                  buttonParameters={{
                    type: 'link',
                    size: 'small',
                    className: 'text-danger',
                  }}
                  cancel={t('common.cancel')}
                >
                  <p>{t('account.delete_account_confirm')}</p>
                  <Form.Item className="mb-3">
                    <label
                      htmlFor="InputDeleteAccountPassword"
                      className="h6 small d-block text-uppercase"
                    >
                      {t('common.current_password')}
                    </label>
                    <PasswordInput
                      id="InputDeleteAccountPassword"
                      name="currentPassword"
                      placeholder="**********"
                      onChange={(event) =>
                        setCurrentPassword(event.currentTarget.value)
                      }
                      required
                    />
                  </Form.Item>
                  <Form.Item className="mb-5">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
                      size="invisible"
                      onChange={(captcha) => setCaptcha(captcha)}
                    />
                  </Form.Item>
                  <Button
                    type="danger"
                    className="mr-2"
                    disabled={!currentPassword}
                    onClick={() => {
                      submitted.current = true;
                      recaptchaRef.current.execute();
                    }}
                  >
                    {t('account.delete_account')}
                  </Button>
                </CustomModal>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export const AccountPage = () => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [identifierToDelete, setIdentifierToDelete] = useState({});
  //const [announcements, setAnnouncements] = useState({});
  const { deleteIdentifier } = useIdentifiers();
  const { loading } = useViewer();

  return (
    <>
      <Layout className="account">
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar />
        </Layout.Sider>
        <Layout>
          <Layout.Content className="p-3 position-relative">
            {loading && (
              <div className="preloader">
                <CirclePreloader />
              </div>
            )}
            <Container>
              <AccountType />

              <BasicAspects />

              <Identifiers setIdentifierToDelete={setIdentifierToDelete} />

              <Preferences />

              <DangerZone
                currentPassword={currentPassword}
                setCurrentPassword={setCurrentPassword}
              />
            </Container>
          </Layout.Content>
        </Layout>
      </Layout>
      <Modal
        closeIcon={<Icon path={mdiClose} size={1} />}
        cancelText={t('common.cancel')}
        okText={t('common.delete')}
        okButtonProps={{ danger: true }}
        onOk={async () => {
          (await deleteIdentifier({
            variables: { id: identifierToDelete.id },
          })) && setIdentifierToDelete({});
        }}
        onCancel={() => setIdentifierToDelete({})}
        open={identifierToDelete.id}
      >
        <p>
          {t('account.delete_identifier_confirm', {
            subject: identifierToDelete.subject,
          })}
        </p>
      </Modal>
    </>
  );
};

export default AccountPage;
