import { useCallback } from 'react';
import Dexie from 'dexie';

const db = new Dexie('Doyo');

db.version(1).stores({ projects: 'id,project' });
export const useDexie = () => {
  const addToDB = useCallback(
    async (project) => {
      db.transaction('rw', db.projects, async () => {
        if (await db.projects.get(project.id)) {
          return await updateDB(project.id, project);
        }
        await db.projects.add(project);
      });
    },
    [db]
  );

  const deleteFromDB = useCallback(
    async (project) => {
      const { id } = project;
      await db.transaction('rw', db.projects, async () => {
        await db.projects.delete(id);
      });
    },
    [db]
  );

  const updateDB = useCallback(
    async (id, project = {}) => {
      return await db.transaction('rw', db.projects, async () => {
        if (id !== project.id) {
          await deleteFromDB({ id });
          await addToDB(project);
          return;
        }
        if (!(await db.projects.get(id))) {
          await addToDB(project);
          return;
        }
        await db.projects.update(id, project);
      });
    },
    [db]
  );

  return { addToDB, db, deleteFromDB, updateDB };
};
