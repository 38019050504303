import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { Button } from 'react-bootstrap';
import { PAGES } from '../../_config';
import { useReferrer, useViewer } from '../../hooks';
import { logo } from '../../assets/svg/logos';
import banner from '../../assets/svg/not-found.svg';

const ExitPage = (props) => {
  const { t } = useTranslation();
  const { match } = props;
  const { params } = match;
  const { referrer } = params;
  const history = useHistory();
  const { key, url } = useReferrer();
  const {
    logout = () => {
      ls.remove('user');
      window.location.href = url || '/';
    },
  } = useViewer();

  useEffect(() => {
    if (referrer) {
      ls('referrer', { key, url: referrer });
      history.push(PAGES.EXIT);
      return;
    }
    logout();
  }, []);

  return (
    <main className="exit not-found">
      <header className="position-absolute-top-0 z-index-2">
        <nav className="d-flex justify-content-between align-items-center">
          <div className="col-lg-5 col-xl-4 text-lg-center px-0">
            <Link
              className="d-inline-block p-3 p-sm-5"
              to="/"
              aria-label="Doyo"
            >
              <img src={logo} height="42px" alt="Logo" />
            </Link>
          </div>
        </nav>
      </header>
      <div
        className="d-flex flex-column justify-content-center align-items-center mb-0 p-0"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="mt--5 d-flex flex-column justify-content-center align-items-center position-relative text-center z-index-2">
          <h1
            className="text-secondary rounded"
            style={{ background: '#f0f1f3' }}
          >
            {t('exit.title')}
          </h1>
          <p className="rounded" style={{ background: '#f0f1f3' }}>
            {t('exit.subtitle')}
          </p>
          <Link to="/" aria-label="Doyo">
            <Button color="primary">{t('exit.button')}</Button>
          </Link>
        </div>
      </div>
    </main>
  );
};

export { ExitPage };
