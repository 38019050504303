import React, { createContext, useCallback, useState } from 'react';

export const ProjectContext = createContext({});

export const ProjectProvider = ({ children }) => {
  const [project = {}, setProject] = useState({});

  const get = useCallback(
    (jsonId) => {
      const { data = {} } = project;
      const jsons = Object.keys(data).reduce((jsons, key) => {
        let main = data[key];
        main = Array.isArray(main) ? main : [main];
        main = main.map((group) => ({ ...group, __type: key }));
        return [...jsons, ...main];
      }, []);
      return jsons.find(({ id }) => id === jsonId) || {};
    },
    [project]
  );

  return (
    <ProjectContext.Provider
      value={{
        get,
        project,
        setProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const ProjectConsumer = ProjectContext.Consumer;
export default ProjectContext;
