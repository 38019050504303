import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { mobileCheck } from '../../_helpers';
import { EventProvider } from '../../context/Events';
import { getSchema } from '../../schemas';
import {
  Actions,
  DropWrapper,
  EventPanel,
  JsonEditorWrapper,
  RegisterModal,
  Sidebar,
  VerificationModal,
} from '../../components';
import { useProjects, useSockets } from '../../hooks';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

const brainMap = {
  name: 'title',
  project_name: 'name',
};

const SettingsContent = ({
  data,
  getProjectRefs,
  handleJsonChange,
  path,
  project,
  projectType,
}) => {
  const { key, value } = data;
  if (!project?.id || !key || !value) {
    return null;
  }

  return (
    <>
      <div className="h-fill p-3">
        <JsonEditorWrapper
          parent={{ path }}
          data={data}
          onChange={handleJsonChange}
          getProjectRefs={getProjectRefs}
          projectType={projectType}
        />
      </div>
      <EventPanel data={data} />
    </>
  );
};

export const SettingsPage = ({ match }) => {
  const {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  } = match;
  const history = useHistory();
  const { applyJson, socket } = useSockets();
  const {
    project = {},
    projects = [],
    getProjectRefs,
    selectProject = () => {},
    unselectProject = () => {},
    updateProject = () => {},
  } = useProjects();
  const { edges: allProjects = [] } = projects;
  let { data: projectData = {} } = project;
  let path = decodeURIComponent(`/${projectNamePath}/${fileNamePath}`);
  const filePath = fileNamePath.split('/');
  filePath.shift();
  let key = 'app';
  const file = filePath.join('/');
  const { app = {} } = projectData;
  const { type: projectType } = app;
  let value = projectData[key] || {};
  let index;
  if (Array.isArray(value)) {
    const val =
      value.find(({ id }) => id === file) ||
      value.find(({ id }) => id === file.split('/').shift());
    index = value.indexOf(val);
    value = val;
  }
  let data = { key, value, index };
  const schema = getSchema({
    path: 'settings',
    projectType,
    value,
  });
  const { properties = {} } = schema;

  const handleJsonChange = useCallback(
    (json, options = {}) => {
      const { value } = json;
      const { changes = {} } = options;
      const { key: keyChanged, newValue } = changes;
      let { data: projectData = {} } = project;
      const newProjectData = projectData._clone();
      newProjectData.app = Object.entries(newProjectData.app)
        .filter(([key, value]) => !Object.keys(properties).includes(key))
        .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
      newProjectData.app = { ...newProjectData.app, ...value };
      if (newProjectData._equals(projectData)) {
        return;
      }
      let brain;
      if (['description', 'name', 'project_name'].includes(keyChanged)) {
        const brainKey = brainMap[keyChanged] || keyChanged;
        brain = { [brainKey]: newValue };
      }
      updateProject({ data: newProjectData, brain });
      applyJson(projectData);
    },
    [key, index, history, project, socket, value]
  );

  useEffect(() => {
    const { node: projectToSelect } =
      allProjects.find(({ node: { data = {} } }) => {
        const { app = {} } = data;
        const { project_name: name } = app;
        return name === projectNamePath;
      }) || {};
    if (projectToSelect) {
      projectToSelect.id !== project.id && selectProject(projectToSelect);
    } else {
      unselectProject();
    }
  }, [projectNamePath, fileNamePath, allProjects]);

  // Momentaneo
  //------------

  const _value = value._clone();
  value = Object.entries(_value)
    .filter(([key, value]) => !['id', 'colors', 'fonts', 'theme'].includes(key))
    .reduce((all, [key, value]) => ({ ...all, [key]: value }), {});
  path = `${path}settings`;
  data = { ...data, value };
  // -----------

  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar match={match} path={path} />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ height: '40px' }}>
            <Actions data={data} />
          </Layout.Header>
          <Layout.Content id="WorkingArea" className="d-flex flex-column">
            <EventProvider
              data={data}
              handleJsonChange={handleJsonChange}
              getProjectRefs={getProjectRefs}
              projectType={projectType}
            >
              <SettingsContent
                data={data}
                getProjectRefs={getProjectRefs}
                handleJsonChange={handleJsonChange}
                path={path}
                project={project}
                projectType={projectType}
              />
            </EventProvider>
          </Layout.Content>
        </Layout>
      </Layout>

      <DropWrapper />
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default SettingsPage;
