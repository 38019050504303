import React, { useRef } from 'react';
import BootstrapToast from 'react-bootstrap/Toast';
import Component from './Component';
import Icon from './Icon';
import Text from './Text';
import { useDialogs } from '../../hooks';

const ToastIcon = ({ icon }) => {
  const Type = icon.includes('.') || icon.includes(':') ? 'img' : Icon;
  return <Type src={icon} className="rounded me-2" width="20" />;
};

export const ToastCompiler = ({ ...props }) => {
  return <Toast {...props}></Toast>;
};

export const Toast = ({ children, icon, title, ...props }) => {
  const { dialogs = {}, hide } = useDialogs();
  const { [props.id]: dialog = {} } = dialogs;
  const { isShown = false } = dialog;
  const date = useRef();
  date.current = isShown ? Date.now() : date.current;

  const handleHide = () => {
    hide(props.id);
  };

  return (
    <Component
      {...props}
      show={isShown}
      onClose={handleHide}
      component={BootstrapToast}
    >
      <Toast.Header closeButton>
        {icon && <ToastIcon icon={icon} />}
        {title && (
          <Text tag="strong" className="me-auto">
            {title}
          </Text>
        )}
      </Toast.Header>
      <Text tag={Toast.Body}>{children}</Text>
    </Component>
  );
};

Toast.Body = BootstrapToast.Body;
Toast.Container = BootstrapToast.Container;
Toast.Header = BootstrapToast.Header;

Toast.displayName = 'Toast';
ToastCompiler.displayName = 'Toast';
export default ToastCompiler;
