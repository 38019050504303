import React from 'react';
import Component from './Component';
import useFile from '../../hooks/useFile';

export const VideoCompiler = ({ file, ...props }) => {
  const { url: src } = useFile(file);
  return <Video {...props} src={src} />;
};

export const Video = (props) => {
  return <Component {...props} component="video" />;
};

Video.displayName = 'Video';
VideoCompiler.displayName = 'Video';
export default VideoCompiler;
