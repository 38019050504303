import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiDelete, mdiPencil, mdiPlus } from '@mdi/js';
import {
  Button,
  Popconfirm,
  Divider,
  Drawer,
  Layout,
  List,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getTextToShow, mobileCheck } from '../../_helpers';
import {
  DropWrapper,
  JsonEditorWrapper,
  RegisterModal,
  Sidebar,
  VerificationModal,
} from '../../components';
import useTemplates, { templateSubjects } from '../../hooks/useTemplates';
import { templates as templatesSchema } from '../../schemas/defs/common';
import { projectTypes } from '../../schemas/project';

const isMobile = mobileCheck();
document.body.dataset.mobile = isMobile;

const defaultTemplate = {
  url: '',
  type: 'mobile_app',
  subject: 'project',
  title: {
    en: '',
    es: '',
  },
  description: {
    en: '',
    es: '',
  },
  screenshots: [''],
};

const NewTemplate = ({ createTemplate }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultTemplate);

  const handleParameterChange = ({ value }) => {
    setValue(value);
  };

  const handleCreateTemplate = async () => {
    await createTemplate(value);
    setValue(defaultTemplate);
  };

  return (
    <div>
      <JsonEditorWrapper
        parent={{ path: 'templates', schema: templatesSchema }}
        data={{ value }}
        onChange={handleParameterChange}
      />
      <Button
        type="primary"
        className="mt-3 ms-4"
        onClick={handleCreateTemplate}
      >
        {t('common.create')}
      </Button>
    </div>
  );
};

const EditTemplate = ({ template, updateTemplate }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(template);

  const handleParameterChange = ({ value }) => {
    setValue(value);
  };

  const handleUpdateTemplate = () => {
    updateTemplate(value);
  };

  const editTemplateSchema = {
    ...templatesSchema,
    properties: {
      ...templatesSchema.properties,
      subject: { ...templatesSchema.properties.subject, readOnly: true },
      type: { ...templatesSchema.properties.type, readOnly: true },
      preview_url: { type: 'string', readOnly: true },
      uid: { type: 'string', readOnly: true },
    },
  };

  return (
    <div>
      <JsonEditorWrapper
        parent={{ path: 'templates', schema: editTemplateSchema }}
        data={{ value }}
        onChange={handleParameterChange}
      />
      <Button
        type="primary"
        className="mt-3 ms-4"
        onClick={handleUpdateTemplate}
      >
        {t('common.save')}
      </Button>
    </div>
  );
};

const Templates = () => {
  const { t } = useTranslation();
  const {
    createTemplate,
    deleteTemplate,
    getTemplates,
    loading,
    templates = [],
    next_cursor: cursor,
    updateTemplate,
  } = useTemplates();
  const [openCreate, setOpenCreate] = useState(false);
  const [filter, setFilter] = useState({});
  const [template, setTemplate] = useState();

  const handleChangeSubject = (subject) => {
    setFilter((filter) => {
      filter = { ...filter, subject };
      if (subject === 'all') {
        delete filter.subject;
      }
      return filter;
    });
  };

  const handleChangeType = (type) => {
    setFilter((filter) => {
      filter = { ...filter, type };
      if (type === 'all') {
        delete filter.type;
      }
      return filter;
    });
  };

  const handleDeleteTemplate = (template) => {
    deleteTemplate(template);
  };

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    getTemplates({ ...filter, cursor });
  };

  useEffect(() => {
    let mounted = true;
    getTemplates({ ...filter, cursor: null, mounted });
    return () => {
      mounted = false;
    };
  }, [filter]);

  return (
    <>
      <div className="px-5 pt-5 d-flex flex-column">
        <div className="mb-2 w-100 d-flex align-items-center">
          <h3 className="m-0 me-3">{t('templates.templates')}</h3>
          <Button
            type="primary"
            shape="circle"
            onClick={() => setOpenCreate(true)}
          >
            <Icon path={mdiPlus} size={1} />
          </Button>
          <div className="w-fill d-flex align-end">
            <Select
              defaultValue="all"
              onChange={handleChangeSubject}
              options={[
                {
                  value: 'all',
                  label: t('templates.all_subjects'),
                },
                ...Object.values(templateSubjects).map((subject) => ({
                  value: subject,
                  label: t(`templates.${subject}`),
                })),
              ]}
            />
            <Select
              className="ms-2"
              defaultValue="all"
              onChange={handleChangeType}
              options={[
                {
                  value: 'all',
                  label: t('templates.all_types'),
                },
                ...Object.values(projectTypes).map((type) => ({
                  value: type,
                  label: t(`projects.${type}`),
                })),
              ]}
            />
          </div>
        </div>
        <div
          id="templatesWrapper"
          className="border-top flex-grow-1 overflow-auto"
          style={{ height: window.innerHeight - 90 }}
        >
          <InfiniteScroll
            dataLength={templates.length}
            next={loadMoreData}
            hasMore={!!cursor}
            loader={
              <div className="w-100 d-flex border-top py-4">
                <div className="flex-grow-1">
                  <Skeleton paragraph={{ rows: 1 }} active />
                  <Space>
                    <Skeleton.Button active size="small" />
                    <Skeleton.Button active size="small" />
                  </Space>
                </div>
                <Skeleton.Image active />
              </div>
            }
            endMessage={<Divider>{t('templates.end')}</Divider>}
            scrollableTarget="templatesWrapper"
          >
            <List
              key={`templates-${JSON.stringify(filter)}`}
              itemLayout="vertical"
              dataSource={templates}
              loading={loading}
              renderItem={(item) => {
                let {
                  id,
                  title: titleObj,
                  description: descriptionObj,
                  screenshot: defaultScreenshot,
                  screenshots: screenshotsObj,
                  subject,
                  type,
                } = item;
                let title = titleObj;
                let description = '';
                let screenshots = [];
                try {
                  titleObj = JSON.parse(titleObj);
                  descriptionObj = JSON.parse(descriptionObj);
                  title = getTextToShow(titleObj);
                  description = getTextToShow(descriptionObj);
                  screenshots = JSON.parse(screenshotsObj);
                } catch (error) {}
                const screenshot = screenshots[0] || defaultScreenshot;
                return (
                  <List.Item
                    key={`Template-${id}`}
                    extra={
                      <img
                        src={screenshot}
                        alt={title}
                        style={{ height: '120px' }}
                      />
                    }
                  >
                    <List.Item.Meta
                      title={
                        <>
                          {title}
                          <span className="template-actions ms-2">
                            <Tooltip title={t('common.edit')}>
                              <Button
                                type="link"
                                size="small"
                                onClick={() =>
                                  setTemplate({
                                    ...item,
                                    title: titleObj,
                                    description: descriptionObj,
                                    screenshots,
                                  })
                                }
                              >
                                <Icon path={mdiPencil} size={0.75} />
                              </Button>
                            </Tooltip>
                            <Popconfirm
                              placement="bottom"
                              title={t('templates.confirm_delete', { title })}
                              onConfirm={() => handleDeleteTemplate(item)}
                              okText={`${t('common.yes')}, ${t(
                                'common.delete'
                              )}`}
                              okButtonProps={{ danger: true }}
                              cancelText={t('common.no')}
                            >
                              <Tooltip title={t('common.delete')}>
                                <Button type="link" size="small" danger>
                                  <Icon path={mdiDelete} size={0.75} />
                                </Button>
                              </Tooltip>
                            </Popconfirm>
                          </span>
                        </>
                      }
                      description={description}
                    />
                    <Tag>{subject}</Tag>
                    <Tag>{type}</Tag>
                  </List.Item>
                );
              }}
            />
          </InfiniteScroll>
        </div>
        <Drawer
          title={t('templates.new_template')}
          placement="right"
          onClose={() => setOpenCreate(false)}
          open={openCreate}
        >
          <NewTemplate createTemplate={createTemplate} />
        </Drawer>
        <Drawer
          title={t('templates.edit_template')}
          placement="right"
          onClose={() => setTemplate()}
          open={!!template}
        >
          <EditTemplate
            key={`EditTemplate-${template?.uid}`}
            template={template}
            updateTemplate={updateTemplate}
          />
        </Drawer>
      </div>
    </>
  );
};

export const TemplatesPage = ({ match }) => {
  const {
    params: { project: projectNamePath = '', file: fileNamePath = '' },
  } = match;
  let path = decodeURIComponent(`/${projectNamePath}/${fileNamePath}`);

  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar match={match} path={path} />
        </Layout.Sider>
        <Layout>
          <Layout.Content id="WorkingArea">
            <Templates />
          </Layout.Content>
        </Layout>
      </Layout>

      <DropWrapper />
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default TemplatesPage;
