import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { getLanguage } from '../../_helpers';
import { Content } from './components/Component';
import Button from './components/Button';

const Step = ({ text, title, ...props }) => {
  const { buttons = {}, closable = true, content, id } = props;
  const headerProps = {};
  if (closable) {
    headerProps.closeButton = true;
  }
  return (
    <>
      <div className="fade modal show">
        <div className="modal-dialog">
          <div className="modal-content">
            <BootstrapModal.Header {...headerProps}>
              {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
            </BootstrapModal.Header>
            {(text || content) && (
              <BootstrapModal.Body>
                {text}
                {content && <Content content={content} />}
              </BootstrapModal.Body>
            )}
            {Object.keys(buttons) && (
              <BootstrapModal.Footer>
                {buttons.custom?.map((attributes, i) => (
                  <Button
                    key={`ModalButton${id}${i}`}
                    id={`ModalButton${id}${i}`}
                    {...attributes}
                  />
                ))}
                {buttons.negative && (
                  <Button
                    key={`ModalButton${id}negative`}
                    id={`ModalButton${id}negative`}
                    text={buttons.negative.label}
                  />
                )}
                {buttons.positive && (
                  <Button
                    key={`ModalButton${id}positive`}
                    id={`ModalButton${id}positive`}
                    color="primary"
                    text={buttons.positive.label}
                  />
                )}
              </BootstrapModal.Footer>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const Steps = (props) => {
  const language = getLanguage();
  let { steps = [] } = props;

  return (
    <main className="preview dialog">
      <div className="modal-backdrop fade show position-absolute w-100 h-100" />
      <div className="steps">
        {steps.map((step, index) => {
          let { text, title } = step;
          text = (text && (text[language] || text.default)) || text;
          title = (title && (title[language] || title.default)) || title;
          return (
            <Step
              key={`Step-${index}`}
              {...step}
              backdrop={index === 0}
              text={text}
              title={title}
            />
          );
        })}
      </div>
    </main>
  );
};

export default Steps;
