import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import { ClipboardReducer, CLIPBOARD } from './reducers';

export const ClipboardContext = createContext({});

export const ClipboardProvider = (props) => {
  const [state, dispatch] = useImmerReducer(
    ClipboardReducer,
    CLIPBOARD.INITIAL_STATE
  );

  const reset = () => {
    dispatch({ type: CLIPBOARD.RESET });
  };

  const store = (object) => {
    dispatch({ type: CLIPBOARD.STORE, payload: { object } });
  };

  return (
    <ClipboardContext.Provider value={{ ...state, reset, store }}>
      {props.children}
    </ClipboardContext.Provider>
  );
};

export const ClipboardConsumer = ClipboardContext.Consumer;
export default ClipboardContext;
