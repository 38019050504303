import gql from 'graphql-tag';

export const SHOW_VIEWER = gql`
  query ShowViewerUser {
    viewer {
      announcements
      fullName
      id
      language
      name
      surname
      accounts(first: 1) {
        edges {
          node {
            avatar
            id
            name
            type
            createdAt
            identifiers(first: 1) {
              edges {
                node {
                  default
                  id
                  subject
                  type
                  verifiedAt
                }
              }
            }
          }
        }
      }
    }
  }
`;
