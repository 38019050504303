import React, { useState } from 'react';
import ActionBar from './ActionBar';
import Drawer from './Drawer';
import Footer from './Footer';
import Header from './Header';
import Pager from './Pager';
import { Group } from '../components/Group';
import { projectTypes } from '../../../schemas/project';

export const Wrapper = ({
  actionbar,
  children,
  className = '',
  drawer,
  footer,
  header,
  id,
  pager,
  view,
}) => {
  const [isDrawerShown, toggleDrawer] = useState();
  return (
    <main
      id={id}
      className={`${className} preview ant-layout-content d-flex flex-column flex-grow-1`.trim()}
      data-type={projectTypes.MOBILE_APP}
    >
      {actionbar && (
        <ActionBar
          key={`ActionBar-${id}`}
          {...actionbar}
          hasDrawer={!!drawer}
          toggleDrawer={toggleDrawer}
          viewTitle={view?.title}
        />
      )}
      {header && <Header {...header} />}
      {pager && <Pager {...pager} viewId={view?.id} />}
      {drawer && (
        <Drawer
          {...drawer}
          isShown={isDrawerShown}
          toggleDrawer={toggleDrawer}
        />
      )}
      <div className="main-content">
        <div>
          <Group>{children}</Group>
        </div>
      </div>
      {footer && <Footer {...footer} />}
    </main>
  );
};

export default Wrapper;
