import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  Avatar,
  Button,
  Badge,
  Collapse,
  Form,
  Input,
  List,
  Menu,
  Popover,
  Space,
  Switch,
  Typography,
  Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import {
  mdiApplicationEditOutline,
  mdiArchiveOutline,
  mdiBellOutline,
  mdiBellRing,
  mdiBullhornOutline,
  mdiChevronRight,
  mdiClockAlertOutline,
  mdiClockOutline,
  mdiCheck,
  mdiChevronLeft,
  mdiClose,
  mdiCogOutline,
  mdiContentCopy,
  mdiLicense,
  mdiNotificationClearAll,
  mdiOpenInNew,
  mdiPaletteOutline,
  mdiPuzzleOutline,
} from '@mdi/js';
import { PAGES, URL } from '../_config';
import { getAvatar, getLanguage, getUniqueName, isObject } from '../_helpers';
import { projectTypes } from '../schemas/project';
import { AlertsWrapper, PremiumTag } from '.';
import { ProjectTree as EditTree } from '../pages/MainPage';
import { ProjectTree as ResourcesTree } from '../pages/ResourcesPage';
import {
  useAccounts,
  useAlerts,
  useCountdown,
  useKustodio,
  useProjects,
  usePublications,
  useUser,
  useViewer,
} from '../hooks';
import { logoMin } from '../assets/svg/logos';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const { Paragraph } = Typography;

const ResourcesMenu = ({ path = '', match = {} }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params: { project: projectNamePath = '' } = {} } = match;
  const { project = {}, updateProject = () => {} } = useProjects();
  const { data = {} } = project;
  const { app = {}, res = [] } = data;
  const { project_name: projectName, type } = app;
  const user = ls('user') || {};
  const { _state = {} } = user;
  const resource = res[0]?.id;

  const current =
    Object.keys(_state)
      .filter((key) => key.indexOf(`/${projectName}/res`) === 0)
      .find((key) => _state[key].current) || `/${projectName}/res/${resource}`;
  const isActive = !!path.match(/\/.*\/(certificates|external|res)\/.*$/);

  const handleItemClone = useCallback(
    ({ id, path }) => {
      const pathArray = path.split('/').slice(2);
      const mainFolder = pathArray.shift();
      const _path = pathArray.length ? `${pathArray.join('/')}/${id}` : id;
      let { data: projectData = {} } = project;
      const data = projectData._clone();
      const clonedItem = data[mainFolder]
        .find(({ id }) => id === _path)
        ._clone();

      const itemNames = data[mainFolder].map(({ id }) => id);
      clonedItem._resetIds();
      clonedItem.id = getUniqueName(_path, {
        names: itemNames,
        isId: true,
      });
      data[mainFolder] = [...data[mainFolder], clonedItem];
      updateProject({ data });
      //applyJson(data);
    },
    [history, project]
  );

  const handleItemCreation = useCallback(
    ({ value: id, data }) => {
      const { key } = data;
      let { data: projectData = {} } = project;
      projectData = projectData._clone();
      projectData[key] = [...(projectData[key] || []), { id }];
      updateProject({ data: projectData });
      //applyJson(projectData);
      history.push(`${PAGES.APP}/${projectNamePath}/${key}/${id}`);
    },
    [history, project]
  );

  const handleItemDelete = useCallback(
    ({ id, path }) => {
      const pathArray = path.split('/').slice(2);
      const mainFolder = pathArray.shift();
      const _path = pathArray.length ? `${pathArray.join('/')}/${id}` : id;
      let { data: projectData = {} } = project;
      const data = projectData._clone();
      data[mainFolder] = data[mainFolder].filter((item) => item.id !== _path);
      updateProject({ data });
      //applyJson(projectData);
    },
    [history, project]
  );

  const handleItemReplace = useCallback(
    ({ id, blob, path, url }) => {
      const pathArray = path.split('/').slice(2);
      const mainFolder = pathArray.shift();
      const _path = pathArray.length ? `${pathArray.join('/')}/${id}` : id;
      let { data: projectData = {} } = project;
      const data = projectData._clone();
      data[mainFolder] = data[mainFolder].map((item) =>
        item.id === _path ? { ...item, blob, url } : item
      );
      updateProject({ data });
      //applyJson(data);
    },
    [history, project]
  );

  if (type === projectTypes.BACKEND) {
    return null;
  }

  return (
    <Popover
      placement="right"
      trigger="click"
      content={
        <ResourcesTree
          match={match}
          path={path}
          handleItemClone={handleItemClone}
          handleItemCreation={handleItemCreation}
          handleItemDelete={handleItemDelete}
          handleItemReplace={handleItemReplace}
        />
      }
      overlayClassName="resources-popover"
    >
      <Link to={`${PAGES.APP}${current}`}>
        <Button
          id="Resources"
          type={isActive ? 'primary' : 'text'}
          icon={<Icon path={mdiArchiveOutline} />}
          size="large"
          disabled={!projectName}
        >
          <small>{t('common.resources')}</small>
        </Button>
      </Link>
    </Popover>
  );
};

const EditMenu = ({ path = '', match = {} }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params: { project: projectNamePath = '' } = {} } = match;
  const { project = {}, updateProject = () => {} } = useProjects();
  const { data = {} } = project;
  const { app = {}, flows = [], views = [] } = data;
  const { project_name: projectName, type } = app;
  const user = ls('user') || {};
  const { _state = {} } = user;
  let key = 'views';
  let currentKey = views[0]?.id;
  if (type === projectTypes.FLOW) {
    key = 'flows';
    currentKey = flows[0]?.id;
  }
  const current =
    Object.keys(_state)
      .filter(
        (key) =>
          key.indexOf(`/${projectName}`) === 0 &&
          key.indexOf(`/${projectName}/res`) === -1
      )
      .find((key) => _state[key].current) ||
    `/${projectName}/${key}/${currentKey}`;
  const isActive =
    !!path.match(/\/.*\/(dialogs|flows|jobs|menus|view_wrappers|views)\/.*$/) ||
    !!path.match(/^\/[^/]*$/) ||
    !!path.match(/^\/[^/]*\/$/);

  const handleItemClone = useCallback(
    ({ id, path }) => {
      const pathArray = path.split('/').slice(2);
      const mainFolder = pathArray.shift();
      const _path = pathArray.length ? `${pathArray.join('/')}/${id}` : id;
      let { data: projectData = {} } = project;
      const data = projectData._clone();
      const clonedItem = data[mainFolder]
        .find(({ id }) => id === _path)
        ._clone();

      const itemNames = data[mainFolder].map(({ id }) => id);
      clonedItem._resetIds();
      clonedItem.id = getUniqueName(_path, {
        names: itemNames,
        isId: true,
      });
      data[mainFolder] = [...data[mainFolder], clonedItem];
      updateProject({ data });
      //applyJson(data);
    },
    [history, project]
  );

  const handleItemCreation = useCallback(
    ({ value: id, data }) => {
      const { key } = data;
      let { data: projectData = {} } = project;
      projectData = projectData._clone();
      projectData[key] = [...(projectData[key] || []), { id }];
      updateProject({ data: projectData });
      //applyJson(projectData);
      history.push(`${PAGES.APP}/${projectNamePath}/${key}/${id}`);
    },
    [history, project]
  );

  const handleItemDelete = useCallback(
    ({ id, path }) => {
      const pathArray = path.split('/').slice(2);
      const mainFolder = pathArray.shift();
      const _path = pathArray.length ? `${pathArray.join('/')}/${id}` : id;
      let { data: projectData = {} } = project;
      const data = projectData._clone();
      data[mainFolder] = data[mainFolder].filter((item) => item.id !== _path);
      updateProject({ data });
      //applyJson(projectData);
    },
    [history, project]
  );

  const handleItemReplace = useCallback(
    ({ id, blob, path, url }) => {
      const pathArray = path.split('/').slice(2);
      const mainFolder = pathArray.shift();
      const _path = pathArray.length ? `${pathArray.join('/')}/${id}` : id;
      let { data: projectData = {} } = project;
      const data = projectData._clone();
      data[mainFolder] = data[mainFolder].map((item) =>
        item.id === _path ? { ...item, blob, url } : item
      );
      updateProject({ data });
      //applyJson(data);
    },
    [history, project]
  );

  if (type === projectTypes.BACKEND) {
    return null;
  }

  return (
    <Popover
      placement="right"
      trigger="click"
      content={
        <EditTree
          match={match}
          path={path}
          handleItemClone={handleItemClone}
          handleItemCreation={handleItemCreation}
          handleItemDelete={handleItemDelete}
          handleItemReplace={handleItemReplace}
        />
      }
      overlayClassName="edit-popover"
    >
      <Link to={`${PAGES.APP}${current}`}>
        <Button
          id="Design"
          type={isActive ? 'primary' : 'text'}
          icon={<Icon path={mdiApplicationEditOutline} />}
          size="large"
          disabled={!projectName}
        >
          <small>{t('common.edit')}</small>
        </Button>
      </Link>
    </Popover>
  );
};

const StyleMenu = ({ path = '' }) => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const { data = {} } = project;
  const { app = {} } = data;
  const { project_name: projectName, type } = app;
  const isActive = !!path.match(/\/.*\/style$/);
  if (type === projectTypes.BACKEND) {
    return null;
  }
  return (
    <Link to={`${PAGES.APP}/${projectName}${PAGES.STYLE}`}>
      <Button
        id="Style"
        type={isActive ? 'primary' : 'text'}
        icon={<Icon path={mdiPaletteOutline} />}
        size="large"
        disabled={!projectName}
      >
        <small>{t('common.style')}</small>
      </Button>
    </Link>
  );
};

const SettingsMenu = ({ path = '' }) => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const { data = {} } = project;
  const { app = {} } = data;
  const { project_name: projectName } = app;
  const isActive = !!path.match(/\/.*\/settings(\/integrations)?$/);
  return (
    <Popover
      placement="right"
      trigger="click"
      content={
        <Space direction="vertical">
          <Link to={`${PAGES.APP}/${projectName}${PAGES.SETTINGS}`}>
            <Button
              id="Settings"
              className="w-100 text-start"
              type="text"
              icon={<Icon path={mdiCogOutline} size={1} className="me-2" />}
              size="large"
              disabled={!projectName}
            >
              <small>{t('settings.general')}</small>
            </Button>
          </Link>
          <Link to={`${PAGES.APP}/${projectName}${PAGES.INTEGRATIONS}`}>
            <Button
              id="Settings"
              className="w-100 text-start"
              type="text"
              icon={<Icon path={mdiPuzzleOutline} size={1} className="me-2" />}
              size="large"
              disabled={!projectName}
            >
              <small>{t('settings.integrations')}</small>
            </Button>
          </Link>
        </Space>
      }
      overlayClassName="settings-popover"
    >
      <Button
        id="Settings"
        type={isActive ? 'primary' : 'text'}
        icon={<Icon path={mdiCogOutline} />}
        size="large"
        disabled={!projectName}
      >
        <small>{t('common.settings')}</small>
      </Button>
    </Popover>
  );
};

const PublishEstimation = () => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const { publications = [] } = usePublications();
  const { created_at: createdAt, estimations = {} } =
    publications.find((publication) => {
      return publication?.project?.uid === project.id && publication?.loading;
    }) || {};
  const { time } = estimations;
  const { countDown } = useCountdown(time, {
    from: createdAt * 1000,
    unit: 'minutes',
  });

  const duration = t('projects.publish_time', {
    time: dayjs.duration(countDown).humanize(false),
  });

  return (
    <Tooltip placement="right" title={time && !isNaN(countDown) && duration}>
      <Badge dot={time && !isNaN(countDown)}>
        <Icon path={mdiBullhornOutline} />
      </Badge>
    </Tooltip>
  );
};

const PublishMenu = ({ path = '' }) => {
  const { t } = useTranslation();
  const { project = {} } = useProjects();
  const { data = {} } = project;
  const { app = {} } = data;
  const { project_name: projectName, type } = app;

  const isActive = !!path.match(/\/.*\/publish$/);
  if (type === projectTypes.BACKEND) {
    return null;
  }
  return (
    <Link to={`${PAGES.APP}/${projectName}${PAGES.PUBLISH}`}>
      <Button
        key="ExportProjectSubMenu"
        id="Publish"
        type={isActive ? 'primary' : 'text'}
        icon={<PublishEstimation />}
        size="large"
        disabled={!projectName}
      >
        <small>{t('common.publish')}</small>
      </Button>
    </Link>
  );
};

export const Notifications = () => {
  const { t } = useTranslation();
  const { alertClear, alerts, getUnviewed, setViewed } = useAlerts();
  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const [unviewed, setUnviewed] = useState([]);
  const button = useRef();

  const handleToggleNotifications = useCallback(() => {
    const unviewed = getUnviewed();
    const _notificationsOpened = !notificationsOpened;
    if (notificationsOpened && unviewed.length) {
      setViewed(unviewed);
    }
    setNotificationsOpened(_notificationsOpened);
  }, [alerts, unviewed]);

  useEffect(() => {
    setUnviewed(getUnviewed());
  }, [alerts]);

  useEffect(() => {
    setUnviewed(getUnviewed());
    notificationsOpened && button.current.click();
  }, [notificationsOpened]);

  return (
    <Menu
      triggerSubMenuAction="click"
      selectable={false}
      onOpenChange={handleToggleNotifications}
    >
      <Menu.SubMenu
        key="NotificationsSubMenu"
        placement="bottomLeft"
        icon={
          <Tooltip placement="right" title={t('common.notifications')}>
            <Button
              id="Notifications"
              icon={
                <Badge count={unviewed.length}>
                  {unviewed.length ? (
                    <Icon path={mdiBellRing} />
                  ) : (
                    <Icon path={mdiBellOutline} />
                  )}
                </Badge>
              }
              type="text"
              shape="round"
              size="large"
              ref={button}
            />
          </Tooltip>
        }
      >
        <Menu.ItemGroup
          title={
            <Space className="justify-content-between">
              {t('common.notifications')}
              <Button type="text" shape="circle" onClick={() => alertClear()}>
                <Icon path={mdiNotificationClearAll} size={1} />
              </Button>
            </Space>
          }
        >
          <AlertsWrapper position="sidebar" />
        </Menu.ItemGroup>
      </Menu.SubMenu>
    </Menu>
  );
};

export const UserMenu = () => {
  const { t } = useTranslation();
  const { account = {} } = useAccounts();
  const { showRegisterModal } = useUser();
  const { logout, viewer = {} } = useViewer();
  const { accounts: { edges: accounts = [{ node: {} }] } = {} } = viewer;
  const { avatar: hash } = account;
  const { customer } = account;
  const { email: customerEmail = '' } = customer || {};
  const planName = customer?.subscription?.plan?.product?.name;

  return (
    <Menu className="user-menu" triggerSubMenuAction="click" selectable={false}>
      <Menu.SubMenu
        key="UserSubMenu"
        popupClassName="sidebar-main-menu"
        icon={
          <Button
            id="UserMenu"
            shape="circle"
            type="text"
            icon={
              <img
                height="36"
                width="36"
                alt="avatar"
                src={getAvatar({ hash })}
                className="rounded-circle"
              />
            }
            onClick={() => {
              const { auth: { token: lsToken } = {} } = ls('user') || {};
              if (!lsToken) {
                showRegisterModal();
                return;
              }
            }}
          />
        }
      >
        {(account.id && (
          <Menu.ItemGroup
            title={
              <>
                {t('common.account', {
                  count: accounts.length,
                })}
                <div>
                  {accounts.map(({ node }, i) => {
                    const {
                      identifier,
                      identifiers: { edges: identifiers = [] } = {},
                      name,
                    } = node;
                    const { subject } =
                      identifiers
                        .map(({ node }) => node)
                        .filter(({ default: d }) => d)[0] || {};
                    const nameToShow = name || identifier || subject;
                    return (
                      <strong key={`Account-${i}`} className="text-primary">
                        {nameToShow}
                        {planName && (
                          <Tooltip title={planName}>
                            <Icon
                              className="mb-1 ms-2"
                              path={mdiLicense}
                              size={0.8}
                            />
                          </Tooltip>
                        )}
                      </strong>
                    );
                  })}
                </div>
                <div>
                  {(customer && (
                    <a
                      href={`${URL.SUBSCRIPTION}?prefilled_email=${customerEmail}`}
                      className="d-flex align-items-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="mt-2" type="primary" block>
                        {t('header.upgrade_account')}
                      </Button>
                    </a>
                  )) || (
                    <Link to={PAGES.UPGRADE_PLANS} rel="Doyo plans">
                      <Button className="mt-2" type="primary" block>
                        {t('header.upgrade_account')}
                      </Button>
                    </Link>
                  )}
                </div>
              </>
            }
            // eslint-disable-next-line react/no-children-prop
            children={[
              <Menu.Divider key="divider1" />,
              <Menu.Item key="DoyoAccount">
                <Link to={PAGES.ACCOUNT} rel="Doyo account">
                  {t('header.view_profile')}
                </Link>
              </Menu.Item>,
              <Menu.Item key="DoyoSubscription">
                <a
                  href={`${URL.SUBSCRIPTION}?prefilled_email=${customerEmail}`}
                  className="d-flex align-items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="flex-grow-1">
                    {t('header.subscription')}
                  </span>
                  <Icon path={mdiOpenInNew} size={0.6} />
                </a>
              </Menu.Item>,
              <Menu.Divider key="divider2" />,
              <Menu.Item key="logout" onClick={() => logout()}>
                {t('common.sign_out')}
              </Menu.Item>,
            ]}
          />
        )) ||
          ''}
      </Menu.SubMenu>
    </Menu>
  );
};

const Sidebar = ({ children, path, match }) => {
  const { t } = useTranslation();
  const { getProjectIcon = () => {}, project = {} } = useProjects();
  const { data = {} } = project;
  const { app = {} } = data;
  const { project_name: projectName } = app;
  const icon = getProjectIcon(projectName);
  return (
    <>
      <Space direction="vertical" className="px-3">
        <Tooltip placement="right" title={t('dashboard.back_to_dashboard')}>
          <Link to={PAGES.DASHBOARD} className="link-to-root">
            <Icon path={mdiChevronLeft} />
            <img alt="logo Doyo" src={icon || logoMin} height="32px" />
          </Link>
        </Tooltip>
      </Space>
      <Menu triggerSubMenuAction="click" selectable={false}>
        <Menu.Item key="Resources" className="sidebar-menu-item">
          <ResourcesMenu match={match} path={path} />
        </Menu.Item>

        <Menu.Item key="Design" className="sidebar-menu-item">
          <EditMenu match={match} path={path} />
        </Menu.Item>

        <Menu.Item key="Style" className="sidebar-menu-item">
          <StyleMenu path={path} />
        </Menu.Item>

        <Menu.Item key="Settings" className="sidebar-menu-item">
          <SettingsMenu path={path} />
        </Menu.Item>

        <Menu.Item key="Publish" className="sidebar-menu-item">
          <PublishMenu path={path} />
        </Menu.Item>

        <Menu.Divider className="border-0 flex-grow-1" />

        <Menu.Item key="Notifications">
          <Notifications />
        </Menu.Item>

        <Menu.Item key="UserMenu">
          <UserMenu />
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Sidebar;
export { Sidebar };
