import React from 'react';
import BootstrapTable from 'react-bootstrap/Table';
import Component from './Component';
import { useSource } from '../../hooks';

const sizes = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
};

export const TableCompiler = ({
  data_source: dataSource,
  header,
  size,
  ...props
}) => {
  return <Table {...props} size={sizes[size]} src={dataSource}></Table>;
};

export const Table = ({ children, header, indexed, src, ...props }) => {
  const { value } = useSource(src);

  if (!header && value?.length) {
    header = Object.keys(value[0]);
  }

  return (
    <Component {...props} component={BootstrapTable}>
      {header && (
        <thead>
          <tr>
            {indexed && <th>#</th>}
            {header.map((h) => (
              <th key={`${props.id}-${h}`}>{h}</th>
            ))}
          </tr>
        </thead>
      )}
      {(value && (
        <tbody>
          {value.map((row, index) => (
            <tr key={`${props.id}-row-${index}`}>
              {indexed && <td>{index}</td>}
              {header.map((key) => (
                <td key={`${props.id}-row-${index}-${key}`}>{row[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      )) ||
        children}
    </Component>
  );
};

Table.displayName = 'Table';
TableCompiler.displayName = 'Table';
export default TableCompiler;
