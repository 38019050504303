import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Input, message, Space, Upload } from 'antd';
import { useNewProject } from '../../hooks';

const { TextArea } = Input;

export const Info = () => {
  const { t } = useTranslation();
  const {
    icon = null,
    info = {},
    setData = () => {},
    setStep = () => {},
  } = useNewProject();
  const { name, description } = info;

  const handleNext = () => {
    setStep((step) => step + 1);
  };

  const props = {
    onRemove: () => {
      setData({ icon: null });
    },
    beforeUpload: (file) => {
      const { type } = file;
      if (type !== 'image/png') {
        message.error(t('new_project.error_png_icon'));
        return false;
      }
      const img = new Image();
      img.onload = function () {
        if (this.width !== 512 || this.height !== 512) {
          message.error(t('new_project.error_size_icon'));
          return false;
        }
        setData({ icon: file });
      };
      img.src = URL.createObjectURL(file);
      return false;
    },
    listType: 'picture-card',
    maxCount: 1,
    showUploadList: false,
    fileList: [icon].filter((icon) => icon),
  };

  const handleChangeName = (event) => {
    const { target } = event;
    const { value: name } = target;
    setData({ info: { ...info, name } });
  };

  const handleChangeDescription = (event) => {
    const { target } = event;
    const { value: description } = target;
    setData({ info: { ...info, description } });
  };

  return (
    <>
      <div className="px-5 py-3">
        <h3>{t('new_project.name')}</h3>
        <Space align="start" size="large">
          <Space direction="vertical">
            <label className="text-uppercase">{t('common.icon')}</label>
            <Upload {...props}>
              {icon ? (
                <img src={URL.createObjectURL(icon)} alt="icon" />
              ) : (
                <div className="icon-placeholder">
                  <svg viewBox="0 0 256 256">
                    <line x1="0" y1="16" x2="256" y2="16" />
                    <line x1="0" y1="240" x2="256" y2="240" />
                    <line x1="16" y1="0" x2="16" y2="256" />
                    <line x1="240" y1="0" x2="240" y2="256" />
                    <line x1="0" y1="128" x2="256" y2="128" />
                    <line x1="128" y1="0" x2="128" y2="256" />
                    <line x1="0" y1="0" x2="256" y2="256" />
                    <line x1="0" y1="256" x2="256" y2="0" />
                    <line x1="0" y1="88" x2="256" y2="88" />
                    <line x1="0" y1="168" x2="256" y2="168" />
                    <line x1="88" y1="0" x2="88" y2="256" />
                    <line x1="168" y1="0" x2="168" y2="256" />
                    <circle cx="128" cy="128" r="112" />
                    <circle cx="128" cy="128" r="56" />
                    <circle cx="128" cy="128" r="40" />
                  </svg>
                  <span>{t('new_project.icon_requirements')}</span>
                </div>
              )}
            </Upload>
          </Space>
          <Space direction="vertical">
            <label className="text-uppercase">
              {t('common.name')}
              <small className="ms-2 text-muted">{t('common.mandatory')}</small>
            </label>
            <Input
              defaultValue={name}
              size="large"
              maxLength={60}
              onChange={handleChangeName}
              style={{ width: '350px' }}
            />
            <label className="text-uppercase mt-3">
              {t('common.description')}
            </label>
            <TextArea
              defaultValue={description}
              autoSize={true}
              maxLength={100}
              onChange={handleChangeDescription}
              style={{ width: '350px', minHeight: '156px' }}
            />
          </Space>
        </Space>
      </div>
      <div className="bg-white">
        <Divider className="mt-0 mb-2" />
        <div className="px-5 py-3">
          <Button
            size="large"
            type="primary"
            disabled={!name}
            onClick={handleNext}
          >
            {t('projects.create_new_project')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Info;
