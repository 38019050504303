import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { logo } from '../../assets/svg/logos';
import banner from '../../assets/svg/not-found.svg';
import { parse } from 'query-string';

const IdentifierRequirement = () => {
  const { t } = useTranslation();

  return (
    <div className="mt--5 px-5 d-flex flex-column justify-content-center align-items-center position-relative text-center z-index-2">
      <h2
        className="text-secondary rounded mx-5"
        style={{ background: '#f0f1f3' }}
      >
        {t('unsubscribe.requirement_title')}
      </h2>
      <p className="rounded" style={{ background: '#f0f1f3' }}>
        {t('unsubscribe.requirement_subtitle')}
      </p>
      <Link to="/" aria-label="Doyo">
        <Button color="primary">{t('unsubscribe.button')}</Button>
      </Link>
    </div>
  );
};

const Unsubscribed = () => {
  const { t } = useTranslation();

  return (
    <div className="mt--5 px-5 d-flex flex-column justify-content-center align-items-center position-relative text-center z-index-2">
      <h1
        className="text-primary rounded mx-5 font-size-48--md-down"
        style={{ background: '#f0f1f3' }}
      >
        {t('unsubscribe.title')}
      </h1>
      <p className="rounded" style={{ background: '#f0f1f3' }}>
        {t('unsubscribe.subtitle')}
      </p>
    </div>
  );
};

export const UnsubscribePage = () => {
  const location = useLocation();
  const { search } = location;
  const { identifier = {} } = parse(search);

  if (identifier) {
    //dispatch(identifiersActions.unsubscribe(identifier));
  }

  const { unsubscribed } = identifier;
  return (
    <main className="not-found">
      <header className="position-absolute-top-0 z-index-2">
        <nav className="d-flex justify-content-between align-items-center">
          <div className="col-lg-5 col-xl-4 text-lg-center px-0">
            <Link
              className="d-inline-block p-3 p-sm-5"
              to="/"
              aria-label="Doyo"
            >
              <img src={logo} height="42px" alt="Logo" />
            </Link>
          </div>
        </nav>
      </header>
      <div
        className="d-flex flex-column justify-content-center align-items-center mb-0 p-0"
        style={{ backgroundImage: `url(${banner})` }}
      >
        {!identifier && <IdentifierRequirement />}
        {unsubscribed && <Unsubscribed />}
      </div>
    </main>
  );
};

export default UnsubscribePage;
