import gql from 'graphql-tag';

export const CREATE_IDENTIFIER = gql`
  mutation CreateIdentifier(
    $accountId: ID!
    $type: IdentifierType!
    $subject: String!
  ) {
    createIdentifier(
      input: { accountId: $accountId, type: $type, subject: $subject }
    ) {
      id
    }
  }
`;

export const SET_IDENTIFIER_AS_DEFAULT = gql`
  mutation SetIdentifierAsDefault($id: ID!) {
    setIdentifierAsDefault(id: $id) {
      id
    }
  }
`;

export const DELETE_IDENTIFIER = gql`
  mutation DeleteIdentifier($id: ID!) {
    deleteIdentifier(id: $id) {
      id
    }
  }
`;
