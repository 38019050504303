import React, { useEffect } from 'react';
import {
  camelToKebabCase,
  highlightComponent,
  isObject,
} from '../../../_helpers';
import {
  useClasses,
  useColor,
  useFile,
  useFunctions,
  useVisibility,
} from '../../hooks';
import { AccordionCompiler } from './Accordion';
import { AlertCompiler } from './Alert';
import { ButtonCompiler } from './Button';
import { CardCompiler } from './Card';
import { CarouselCompiler } from './Carousel';
import { ChartCompiler } from './Chart';
import { DropdownCompiler } from './Dropdown';
import { FormCompiler } from './Form';
import { GroupCompiler } from './Group';
import { IconCompiler } from './Icon';
import { ImageCompiler } from './Image';
import { InputCompiler } from './Input';
import { LabelCompiler } from './Label';
import { ListCompiler } from './List';
import { LottieCompiler } from './Lottie';
import { ModalCompiler } from './Modal';
import { NavCompiler } from './Nav';
import { OffcanvasCompiler } from './Offcanvas';
import { PdfViewerCompiler } from './PdfViewer';
import { PlayerCompiler } from './Player';
import { ProgressBarCompiler } from './ProgressBar';
import { StripePricingTable } from './StripePricingTable';
import { TableCompiler } from './Table';
import { TextCompiler } from './Text';
import { TimelineCompiler } from './Timeline';
import { ToastCompiler } from './Toast';
import { VideoCompiler } from './Video';
import { WebCompiler } from './Web';
import { YoutubeCompiler } from './Youtube';

export const componentByType = {
  accordion: AccordionCompiler,
  alert: AlertCompiler,
  button: ButtonCompiler,
  card: CardCompiler,
  carousel: CarouselCompiler,
  chart: ChartCompiler,
  checkbox: InputCompiler,
  colorpicker: InputCompiler,
  datepicker: InputCompiler,
  dropdown: DropdownCompiler,
  form: FormCompiler,
  group: GroupCompiler,
  icon: IconCompiler,
  image: ImageCompiler,
  inputfile: InputCompiler,
  inputtext: InputCompiler,
  label: LabelCompiler,
  list: ListCompiler,
  lottie: LottieCompiler,
  modal: ModalCompiler,
  nav: NavCompiler,
  offcanvas: OffcanvasCompiler,
  pdfviewer: PdfViewerCompiler,
  player: PlayerCompiler,
  progress_bar: ProgressBarCompiler,
  radio: InputCompiler,
  range: InputCompiler,
  select: InputCompiler,
  stripe_pricing_table: StripePricingTable,
  switch: InputCompiler,
  table: TableCompiler,
  text: TextCompiler,
  timeline: TimelineCompiler,
  toast: ToastCompiler,
  video: VideoCompiler,
  web: WebCompiler,
  youtube: YoutubeCompiler,
};

const valignMap = {
  top: 'start',
  middle: 'center',
  bottom: 'end',
};
const radiusMap = {
  top_left: { key: 'tl', value: 'top-left' },
  top_right: { key: 'tr', value: 'top-right' },
  bottom_left: { key: 'bl', value: 'bottom-left' },
  bottom_right: { key: 'br', value: 'bottom-right' },
};
const marginMap = {
  top: 'top',
  end: 'right',
  bottom: 'bottom',
  start: 'left',
};
const paddingMap = {
  top: 'top',
  end: 'right',
  bottom: 'bottom',
  start: 'left',
};

export const Content = ({ content = [], itemData = {} }) => {
  return (
    <>
      {content
        ?.filter((component) => component)
        .map((props, index) => {
          const { id, type } = props;
          const ChildComponent = componentByType[type] || 'div';
          props = {
            ...props,
            'data-key': index,
            itemData,
            itemDataSrc: props?.source
              ? itemData?.[props?.source]
              : props?.source,
          };
          return <ChildComponent key={`${id}-${index}`} {...props} />;
        })}
    </>
  );
};

export const ComponentCompiler = ({
  background,
  border: borderProp,
  children,
  className = '',
  halign,
  height,
  if: condition,
  margin,
  max_height: maxHeight,
  max_width: maxWidth,
  min_height: minHeight,
  min_width: minWidth,
  onclick: onClick,
  padding,
  position,
  radius,
  valign,
  width,
  itemData: data,
  itemDataSrc,
  visible: isVisible = true,
  ...props
}) => {
  const { id } = props;
  const { addClass, style } = useClasses(id);
  const { key: backgroundColor } = useColor(background?.color);
  const { key: fromColor } = useColor(background?.from);
  const { key: toColor } = useColor(background?.to);
  const { id: imageId, url: backgroundImage } = useFile(background?.image);
  let classes = {};

  // background
  let bg;
  if (background) {
    const { direction = 'to_bottom', type } = background;
    if (type === 'solid') {
      const kebabKeyBgColor = camelToKebabCase(backgroundColor);
      bg = {
        [`bg-${kebabKeyBgColor}`]: `background-color:var(--${kebabKeyBgColor})!important`,
      };
    } else if (type === 'gradient') {
      const kebabKeyFromColor = camelToKebabCase(fromColor);
      const kebabKeyToColor = camelToKebabCase(toColor);
      bg = {
        [`bg-${kebabKeyFromColor}-to-${kebabKeyToColor} ${direction.replace(
          /_/g,
          '-'
        )}`.trim()]: `background:linear-gradient(${`${direction}`.replace(
          /_/g,
          ' '
        )},var(--${kebabKeyFromColor}),var(--${kebabKeyToColor}))!important;`,
      };
    } else if (type === 'image') {
      bg = {
        [`bg-${`${imageId}`.replace(
          /[./]/g,
          ''
        )}`]: `background: url('${backgroundImage}');background-size:cover;`,
      };
    }
    classes = { ...classes, ...bg };
  }

  // position
  let pos;
  if (position) {
    pos = { [`position-${position}`]: `position:${position}` };
    classes = { ...classes, ...pos };
  }

  // halign
  if (halign) {
    classes[`align-${halign}`] = '';
  }

  // valign
  if (valign) {
    classes[`valign-${valignMap[valign]}`] = '';
  }

  // width
  let w;
  if (typeof width !== 'undefined') {
    if (typeof width === 'number') {
      w = { [`w-${width}px`]: `width:${width}px!important` };
    } else if (width?.match(/%$/)) {
      w = { [`w-${width.replace('%', '')}`]: `width:${width}!important` };
    } else if (width?.match(/vh$/)) {
      w = { [`w-${width}`]: `width:${width}!important` };
    } else if (width === 'fill') {
      w = { [`w-fill`]: `width:0;flex-grow:1!important` };
    } else if (width === 'wrap') {
      w = { [`w-auto`]: `width:auto!important` };
    }
    classes = { ...classes, ...w };
  }

  // height
  let h;
  if (typeof height !== 'undefined') {
    if (typeof height === 'number') {
      h = { [`h-${height}px`]: `height:${height}px` };
    } else if (height?.match(/%$/)) {
      h = { [`h-${height.replace('%', '')}`]: `height:${height}!important` };
    } else if (height?.match(/vh$/)) {
      h = { [`h-${height}`]: `height:${height}!important` };
    } else if (height === 'fill') {
      h = { [`h-fill`]: `height:0;flex-grow:1!important` };
    } else if (height === 'wrap') {
      h = { [`h-auto`]: `height:auto!important` };
    }
    classes = { ...classes, ...h };
  }

  // margin
  let m;
  if (typeof margin !== 'undefined') {
    m = {};
    if (typeof margin === 'object') {
      const { bottom, end, start, top } = margin;
      if (top === bottom) {
        if (typeof top === 'number') {
          m[`py-${top}px`] = `margin-top:${top}px;margin-bottom:${bottom}px`;
        } else if (top?.match(/%$/)) {
          m[
            `py-${top.replace('%', '')}`
          ] = `margin-top:${top};margin-bottom:${bottom}`;
        }
      }
      if (start === end) {
        if (typeof start === 'number') {
          m[`px-${bottom}px`] = `margin-left:${start}px;margin-right:${end}px`;
        } else if (start?.match(/%$/)) {
          m[
            `px-${bottom.replace('%', '')}`
          ] = `margin-left:${start};margin-right:${end}`;
        }
      }
      Object.keys(margin).forEach((key) => {
        if (typeof margin[key] === 'number') {
          m[
            `p${key[0]}-${margin[key]}px`
          ] = `margin-${marginMap[key]}:${margin[key]}px`;
        } else if (margin[key]?.match(/%$/)) {
          m[
            `p${key[0]}-${margin[key].replace('%', '')}`
          ] = `margin-${marginMap[key]}:${margin[key]}`;
        }
      });
    } else if (typeof margin === 'number') {
      m[`m-${margin}px`] = `margin:${margin}px`;
    } else if (margin?.match(/%$/)) {
      m[`m-${margin.replace('%', '')}`] = `margin: ${margin}`;
    }
    classes = { ...classes, ...m };
  }

  // padding
  let p;
  if (typeof padding !== 'undefined') {
    p = {};
    if (typeof padding === 'object') {
      const { bottom, end, start, top } = padding;
      if (top === bottom) {
        if (typeof top === 'number') {
          p[`py-${top}px`] = `padding-top:${top}px;padding-bottom:${bottom}px`;
        } else if (top?.match(/%$/)) {
          p[
            `py-${top.replace('%', '')}`
          ] = `padding-top:${top};padding-bottom:${bottom}`;
        }
      }
      if (start === end) {
        if (typeof start === 'number') {
          p[
            `px-${bottom}px`
          ] = `padding-left:${start}px;padding-right:${end}px`;
        } else if (start?.match(/%$/)) {
          p[
            `px-${bottom.replace('%', '')}`
          ] = `padding-left:${start};padding-right:${end}`;
        }
      }
      Object.keys(padding).forEach((key) => {
        if (typeof padding[key] === 'number') {
          p[
            `p${key[0]}-${padding[key]}px`
          ] = `padding-${paddingMap[key]}:${padding[key]}px`;
        } else if (padding[key]?.match(/%$/)) {
          p[
            `p${key[0]}-${padding[key].replace('%', '')}`
          ] = `padding-${paddingMap[key]}:${padding[key]}`;
        }
      });
    } else if (typeof padding === 'number') {
      p[`p-${padding}px`] = `padding:${padding}px`;
    } else if (padding?.match(/%$/)) {
      p[`p-${padding.replace('%', '')}`] = `padding: ${padding}`;
    }
    classes = { ...classes, ...p };
  }

  // max_height
  let maxh;
  if (typeof maxHeight === 'number') {
    maxh = { [`max-h-${maxHeight}px`]: `max-height:${maxHeight}px` };
    classes = { ...classes, ...maxh };
  }

  // max_width
  let maxw;
  if (typeof maxWidth === 'number') {
    maxw = { [`max-h-${maxWidth}px`]: `max-width:${maxWidth}px` };
    classes = { ...classes, ...maxw };
  }

  // min_height
  let minh;
  if (typeof minHeight !== 'undefined') {
    if (typeof minHeight === 'number') {
      minh = { [`min-h-${minHeight}px`]: `min-height:${minHeight}px` };
    } else if (minHeight?.match(/%$/)) {
      minh = {
        [`min-h-${minHeight.replace(
          '%',
          ''
        )}`]: `min-height:${minHeight}!important`,
      };
    } else if (minHeight?.match(/vh$/)) {
      minh = { [`min-h-${minHeight}`]: `min-height:${minHeight}!important` };
    } else if (minHeight === 'fill') {
      minh = { [`min-h-fill`]: `min-height:0;flex-grow:1!important` };
    }
    classes = { ...classes, ...minh };
  }
  // min_width
  let minw;
  if (typeof minWidth !== 'undefined') {
    if (typeof minWidth === 'number') {
      minw = { [`min-w-${minWidth}px`]: `min-width:${minWidth}px` };
    } else if (minWidth?.match(/%$/)) {
      minw = {
        [`min-w-${minWidth.replace(
          '%',
          ''
        )}`]: `min-width:${minWidth}!important`,
      };
    } else if (minWidth === 'fill') {
      minw = { [`min-w-fill`]: `min-width:0;flex-grow:1!important` };
    }
    classes = { ...classes, ...minw };
  }

  // border
  let border;
  if (typeof borderProp !== 'undefined') {
    border = {};
    const borderColor = borderProp.color?.replace('@color.', '');
    const borderObj = borderProp._clone();
    delete borderObj.color;
    Object.entries(borderObj).forEach(([key, value]) => {
      if (value) {
        border[`border-${key}`] = ``;
      }
    });
    if (borderColor) {
      border[`border-${borderColor}`] = ``;
    }
    classes = { ...classes, ...border };
  }

  // radius
  let borderRadius;
  if (typeof radius !== 'undefined') {
    borderRadius = {};
    if (typeof radius === 'object') {
      Object.keys(radius).forEach((key) => {
        if (typeof radius[key] === 'number') {
          borderRadius[
            `border-radius-${radiusMap[key]?.key}${radius[key]}px`
          ] = `border-${radiusMap[key].value}-radius:${radius[key]}px`;
        } else if (radius[key].match(/%$/)) {
          borderRadius[
            `border-radius-${radiusMap[key].key}${radius[key].replace('%', '')}`
          ] = `border-${radiusMap[key].value}-radius:${radius[key]}`;
        }
      });
    } else if (typeof radius === 'number') {
      borderRadius[`border-radius-${radius}px`] = `border-radius:${radius}px`;
    }
    classes = { ...classes, ...borderRadius };
  }

  useEffect(() => {
    addClass({ bg });
  }, [background]);
  useEffect(() => {
    addClass({ h });
  }, [height]);
  useEffect(() => {
    addClass({ m });
  }, [margin]);
  useEffect(() => {
    addClass({ maxh });
  }, [maxHeight]);
  useEffect(() => {
    addClass({ maxw });
  }, [maxWidth]);
  useEffect(() => {
    addClass({ minh });
  }, [minHeight]);
  useEffect(() => {
    addClass({ minw });
  }, [minWidth]);
  useEffect(() => {
    addClass({ p });
  }, [padding]);
  useEffect(() => {
    addClass({ pos });
  }, [position]);
  useEffect(() => {
    addClass({ borderRadius });
  }, [radius]);
  useEffect(() => {
    addClass({ w });
  }, [width]);

  const { run } = useFunctions();
  const handleClick = () => {
    run(onClick);
  };
  const conditionVisible = useVisibility(condition, { data, id, isVisible });
  className = `${className} ${onClick ? 'cursor-pointer' : ''}`.trim();

  if (!conditionVisible) {
    return null;
  }
  return (
    <>
      <style>{style}</style>
      <Component
        data-component-id={id}
        className={`${className} ${Object.keys(classes).join(' ')}`.trim()}
        onClick={handleClick}
        {...props}
      >
        {children}
      </Component>
    </>
  );
};

function getProps(props) {
  try {
    if (typeof props === 'function') {
      return;
    }
    if (Array.isArray(props)) {
      props = props.map((prop) => getProps(prop));
    }
    if (!isObject(props)) {
      return props;
    }
    const { $$typeof, children } = props;
    if ($$typeof) {
      return { ...(props.props || {}), children: getChildrenProps(children) };
    }

    return Object.entries(props)
      .map(([key, value]) => ({ [key]: getProps(value) }))
      .reduce((props, prop) => ({ ...props, ...prop }), {});
  } catch (error) {
    console.error(error);
    return null;
  }
}
function getChildrenProps(children = []) {
  const _children = Array.isArray(children) ? children : [children];
  return _children.map(({ props } = {}) => {
    return {
      ...props,
      children: getChildrenProps(props?.children),
    };
  });
}

export const Component = ({
  __expanded,
  __listId__,
  children,
  className = '',
  component: Cmp = 'div',
  type,
  ...props
}) => {
  const handleComponentClick = (event) => {
    const { currentTarget } = event;
    const { dataset } = currentTarget;
    const { componentId } = dataset;
    const node = document.querySelector(
      `.node-item[data-component-id="${componentId}"] > .node-external-expand-button`
    );
    if (!node) {
      return;
    }
    event.stopPropagation();
    node.click();
    setTimeout(() => highlightComponent(event, 'check'), 10);
  };
  return (
    <Cmp {...props} className={className} onClick={handleComponentClick}>
      {children}
    </Cmp>
  );
};

Component.displayName = 'Component';
ComponentCompiler.displayName = 'Component';
export default ComponentCompiler;
