import React from 'react';
import ls from 'local-storage';
import { AlertProvider } from './Alert';
import { AiProvider } from './Ai';
import { ApiProvider } from './Api';
import { ClipboardProvider } from './Clipboard';
import { GeneralProvider } from './General';
import { ProjectProvider } from './Project';
import { PublicationProvider } from './Publication';
import { SocketProvider } from './Socket';
import { StripeProvider } from './Stripe';
import { VerificationProvider } from './Verification';
import { UserProvider } from './User';

const headers = {
  'content-type': 'application/json',
};

export const getHeaders = ({ auth } = {}) => {
  if (auth) {
    const { auth = {} } = ls('user') || {};
    const { token } = auth;

    return {
      ...headers,
      authorization: `Bearer ${token}`,
    };
  }

  return headers;
};

export const ContextProviders = ({ children }) => {
  return (
    <GeneralProvider>
      <StripeProvider>
        <AlertProvider>
          <UserProvider>
            <VerificationProvider>
              <ClipboardProvider>
                <ProjectProvider>
                  <ApiProvider>
                    <AiProvider>
                      <PublicationProvider>
                        <SocketProvider>
                          <>{children}</>
                        </SocketProvider>
                      </PublicationProvider>
                    </AiProvider>
                  </ApiProvider>
                </ProjectProvider>
              </ClipboardProvider>
            </VerificationProvider>
          </UserProvider>
        </AlertProvider>
      </StripeProvider>
    </GeneralProvider>
  );
};

export * from './Account';
export * from './Ai';
export * from './Api';
export * from './Alert';
export * from './Clipboard';
export * from './Components';
export * from './Events';
export * from './Identifier';
export * from './General';
export * from './Project';
export * from './Publication';
export * from './Socket';
export * from './Stripe';
export * from './User';
export * from './Verification';
export * from './Viewer';
