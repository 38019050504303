import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { Button, Input, Tooltip } from 'antd';

function Search({
  className,
  icon = true,
  visible: visibleProp = false,
  ...props
}) {
  const { t } = useTranslation();
  const searchWrapper = useRef();
  const [visible, setVisible] = useState(visibleProp);

  const toggle = useCallback(() => {
    if (visibleProp) {
      return;
    }
    setVisible((visible) => {
      !visible &&
        searchWrapper.current &&
        searchWrapper.current.querySelector('input').focus();
      return !visible;
    });
  }, [searchWrapper]);

  const handleKeyDown = (event) => {
    const { currentTarget, key } = event;
    if (key === 'Enter') {
      currentTarget.blur();
      return false;
    }
    return true;
  };

  return (
    <div
      className={`search${className ? ` ${className}` : ''}`}
      ref={searchWrapper}
    >
      <div className="input-wrapper" data-visible={visible}>
        <Input
          name="search"
          placeholder={t('common.search')}
          aria-describedby="search"
          onKeyDown={handleKeyDown}
          {...props}
        />
      </div>
      {icon && (
        <Tooltip key="Search" placement="bottom" title={t('common.search')}>
          <Button variant="link" onClick={toggle}>
            <Icon path={mdiMagnify} size={1} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

export default Search;
export { Search };
