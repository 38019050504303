import gql from 'graphql-tag';

export const CREATE_RECOVERY = gql`
  mutation CreateRecovery($email: String!, $captcha: String!) {
    createRecovery(input: { email: $email, captcha: $captcha })
  }
`;

export const APPLY_RECOVERY = gql`
  mutation ApplyRecovery(
    $token: String!
    $password: String!
    $captcha: String!
  ) {
    applyRecovery(
      input: { token: $token, password: $password, captcha: $captcha }
    )
  }
`;
