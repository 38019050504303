import React, { useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiInboxArrowDownOutline } from '@mdi/js';
import { Card, List, message, Space, Upload } from 'antd';
import { PAGES } from '../../_config';
import { projectTypes } from '../../schemas/project';
import projectTypesSvg from '../../assets/svg/projects';
import { useAlerts, useProjects, useViewer } from '../../hooks';

const { Dragger } = Upload;

export const Types = ({ dashboard: isFromDashboard }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { alertError } = useAlerts();
  const { addProjects, createProjectFromZip } = useProjects();
  const { viewer = {} } = useViewer();
  const [fileList, setFileList] = useState([]);
  const hasToOpenProject = useRef();

  const createProject = async (zip, properties = {}) => {
    try {
      const node = await createProjectFromZip(zip, properties);
      addProjects({ edges: [{ node }] });
      const { data = {} } = node;
      const { app = {}, flows = [], views = [] } = data;
      const { project_name: projectName, type } = app;
      let key = 'views';
      let currentKey = views[0]?.id;
      if (type === projectTypes.FLOW) {
        key = 'flows';
        currentKey = flows[0]?.id;
      }

      window.gtag?.('event', 'createProject', {
        category: viewer.id,
        label: projectName,
      });

      hasToOpenProject.current &&
        history.push(`${PAGES.APP}/${projectName}/${key}/${currentKey}`);
    } catch ({ message }) {
      message.error(message);
      alertError({ message });
    }
  };

  const props = {
    name: 'file',
    multiple: isFromDashboard,
    beforeUpload: (file, files) => {
      hasToOpenProject.current = files.length === 1;
      return false;
    },
    onChange({ event, file, fileList }) {
      if (!file?.name?.match(/.cdz$/)) {
        message.error({
          key: 'onlyCdzFiles',
          content: t('new_project.only_cdz_files'),
        });
        return false;
      }
      setFileList(fileList.map((file) => ({ ...file, status: 'uploading' })));
      (async () => {
        await createProject(file);
        setFileList((fileList) =>
          fileList.filter(({ name }) => name !== file.name)
        );
      })();
    },
    fileList,
  };

  return (
    <div className="px-5 py-3">
      <h3>{t('new_project.start_new_project')}</h3>
      <Space align={null} className="position-relative">
        <List
          className="new-project-types"
          dataSource={[...Object.values(projectTypes), 'cdz']}
          renderItem={(type) => {
            if (type === 'cdz') {
              return (
                <Dragger {...props} style={{ width: 240 }}>
                  <Space direction="vertical">
                    <Icon path={mdiInboxArrowDownOutline} size={2} />
                    <div className="p-4">
                      {t('new_project.create_from_cdz')}
                    </div>
                  </Space>
                </Dragger>
              );
            }
            return (
              <Link to={`${PAGES.NEW_PROJECT}/${type}`}>
                <Card
                  hoverable
                  style={{ width: 240 }}
                  cover={<img src={projectTypesSvg[type]} alt={type} />}
                >
                  <Card.Meta
                    title={t(`projects.${type}`)}
                    description={t(`projects.${type}_description`)}
                  />
                </Card>
              </Link>
            );
          }}
        />
      </Space>
    </div>
  );
};

export default Types;
