import React from 'react';
import BootstrapNav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { getLanguage, isObject } from '../../../_helpers';
import Component from './Component';
import Icon from './Icon';
import Image from './Image';
import useMenu from '../../hooks/useMenu';

const menuStyles = {
  icon: 'only-icons',
  title: 'only-titles',
  icon_title: null,
};

export const NavItemCompiler = ({
  items,
  onclick: handleClick = () => {},
  drop = 'bottom',
  href = '',
  icon = '',
  image = '',
  isDropDownChild = false,
  title,
  type,
}) => {
  // title
  const language = getLanguage();
  let titleToShow = title;
  if (isObject(titleToShow)) {
    titleToShow =
      typeof titleToShow[language] !== 'undefined'
        ? titleToShow[language]
        : titleToShow.default;
  }
  if (typeof titleToShow === 'undefined' || titleToShow === '') {
    titleToShow = '';
  }
  if (items) {
    return (
      <NavDropdown title={titleToShow} drop={drop}>
        {items.map((child, index) => (
          <NavItemCompiler
            key={`NavItem-${index}`}
            {...child.props}
            drop="end"
            isDropDownChild={true}
          />
        ))}
      </NavDropdown>
    );
  }

  const Item = isDropDownChild ? NavDropdown.Item : BootstrapNav.Item;
  const ItemIcon = image ? Image : Icon;
  const regexp = new RegExp(`^/(${href})$`);
  const isActive = href && window.location.pathname.match(regexp);

  return (
    <Item>
      {isDropDownChild ? (
        <>
          {(icon || image) && <ItemIcon src={icon || image} />}
          <span>{titleToShow}</span>
        </>
      ) : (
        <BootstrapNav.Link className={isActive ? 'active' : ''}>
          {(icon || image) && <ItemIcon src={icon || image} />}
          <span>{titleToShow}</span>
        </BootstrapNav.Link>
      )}
    </Item>
  );
};

const NavItems = ({ items }) => {
  return (
    <>
      {items?.map((props, i) => (
        <NavItemCompiler key={`${props.id}-${i}`} {...props} />
      ))}
    </>
  );
};

export const NavCompiler = ({
  menu,
  menu_style: menuStyle,
  menu_type: menuType,
  orientation,
  ...props
}) => {
  const { id } = props;
  const { items } = useMenu(menu);
  let menuStyleClass = '';
  if (menuStyle) {
    menuStyleClass = menuStyles[menuStyle];
    props.className = menuStyleClass;
  }
  if (menuType) {
    props.variant = menuType;
  }

  return (
    <Nav {...props} direction={orientation}>
      <NavItems items={items} />
    </Nav>
  );
};

const NavItem = ({
  children,
  onClick: handleClick = () => {},
  drop = 'bottom',
  href = '',
  icon = '',
  image = '',
  isDropDownChild = false,
  title,
  type,
}) => {
  if (children) {
    children = Array.isArray(children) ? children : [children];
    return (
      <NavDropdown title={title} drop={drop}>
        {children.map((child) => ({
          ...child,
          props: {
            ...child.props,
            drop: 'end',
            isDropDownChild: true,
          },
        }))}
      </NavDropdown>
    );
  }

  const Item = isDropDownChild ? NavDropdown.Item : BootstrapNav.Item;
  const ItemIcon = image ? Image : Icon;
  const regexp = new RegExp(`^/(${href})$`);
  const isActive = href && window.location.pathname.match(regexp);

  return (
    <Item>
      {isDropDownChild ? (
        <>
          {(icon || image) && <ItemIcon src={icon || image} />}
          <span>{title}</span>
        </>
      ) : (
        <BootstrapNav.Link className={isActive ? 'active' : ''}>
          {(icon || image) && <ItemIcon src={icon || image} />}
          <span>{title}</span>
        </BootstrapNav.Link>
      )}
    </Item>
  );
};

export const Nav = ({ children, className, direction, ...props }) => {
  const directionClass = direction === 'vertical' ? 'flex-column' : '';
  return (
    <Component
      {...props}
      className={`${className} ${directionClass}`.trim()}
      component={BootstrapNav}
    >
      {children}
    </Component>
  );
};

Nav.Item = NavItem;

Nav.displayName = 'Nav';
NavCompiler.displayName = 'Nav';
export default NavCompiler;
