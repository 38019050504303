import React from 'react';
import Component from './Component';
import { getRgba } from '../../../_helpers';
import { useColor } from '../../hooks';
import beach from '../../../assets/img/beach.jpg';

export const CropperCompiler = (props) => {
  return <Cropper {...props} />;
};

export const Cropper = (props) => {
  let {
    color_frame: colorFrameProp = '@color.primaryDark',
    color_guide: colorGuideProp = '@color.primary',
    color_handle: colorHandleProp = '@color.primaryDark',
    color_overlay: colorOverlayProp = '#96000000',
    mode,
  } = props;
  let { hex: colorFrame = '' } = useColor(colorFrameProp);
  let { hex: colorGuide = '' } = useColor(colorGuideProp);
  let { hex: colorHandle = '' } = useColor(colorHandleProp);
  let { hex: colorOverlay = '' } = useColor(colorOverlayProp);
  colorFrame = getRgba(colorFrame);
  colorGuide = getRgba(colorGuide);
  colorHandle = getRgba(colorHandle);
  colorOverlay = getRgba(colorOverlay);

  return (
    <Component {...props} className="cropper" data-mode={mode}>
      <div className="img" style={{ backgroundImage: `url(${beach})` }} />
      <div className="frame-wrapper" style={{ color: colorOverlay }}>
        <div className="frame" style={{ color: colorFrame }}>
          <div className="guides" style={{ color: colorGuide }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="handlers" style={{ color: colorHandle }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </Component>
  );
};

export default CropperCompiler;
