import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import useColor from '../../hooks/useColor';
import Image from '../components/Image';
import Nav from '../components/Nav';
import Group from '../components/Group';

export const ActionBar = ({
  background_color: backgroundColor,
  bordered,
  content = [],
  id,
  logo,
  menu = '',
  width,
}) => {
  const { key: bg } = useColor(backgroundColor);
  const NavbarContainer = width === 'fill' ? React.Fragment : Container;
  const borderBottomClass = bordered ? 'border-bottom' : '';
  return (
    <Navbar
      bg={bg}
      expand="lg"
      sticky="top"
      className={`z-index-10 px-3 ${borderBottomClass}`.trim()}
    >
      <NavbarContainer>
        {logo && (
          <Navbar.Brand>
            <Image
              id={`${id}_actionbar_logo`}
              file={logo}
              width={'auto'}
              height={40}
              min_height={40}
            />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            id={`${id}_actionbar_menu`}
            className="flex-grow-1"
            halign="end"
            valign="middle"
            menu={menu}
          />
          {content && (
            <Group content={content} direction="horizontal" width="wrap" />
          )}
        </Navbar.Collapse>
      </NavbarContainer>
    </Navbar>
  );
};

export default ActionBar;
