import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { Container } from 'react-bootstrap';
import { Sidebar } from '../../components';
import { Plans } from '../../pages';

export const UpgradePage = () => {
  const { t } = useTranslation();

  return (
    <Layout className="upgrade">
      <Layout.Sider className="sidebar-main" width={100}>
        <Sidebar />
      </Layout.Sider>
      <Layout>
        <Layout.Content className="p-3">
          <Container>
            <h4 className="mt-2 mb-3">{t('header.pricing')}</h4>
            <hr className="mb-5" />
            <Plans />
            <hr className="border-0 mb-5" />
          </Container>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default UpgradePage;
