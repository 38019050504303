import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import { CustomerForm } from '.';
import { PAGES } from '../../_config';
import { Sidebar } from '../../components';

export const CustomerPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  history.replace(PAGES.SUBSCRIPTION);
  return (
    <main className="account">
      <Sidebar />
      <div className="container-wrapper">
        <Container>
          <h4 className="mt-2 mb-3">{t('header.billing_data')}</h4>
          <CustomerForm />
        </Container>
      </div>
    </main>
  );
};

export default CustomerPage;
