import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ls from 'local-storage';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { PAGES, SECRETS } from '../../_config';
import { scrollToInvalid } from '../../_helpers';
import { useReferrer, useUser } from '../../hooks';
import { CirclePreloader, PasswordInput } from '../../components';
import { logo } from '../../assets/svg/logos';
import banner from '../../assets/svg/not-found.svg';

export const ResetPasswordPage = ({ match: { params: { token } = {} } }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { applyRecovery, createAuthentication, loading } = useUser();
  const { base = '/', logo: referrerLogo = {} } = useReferrer();
  const [state, setState] = useState({ captcha: '', password: '', token });
  const { password } = state;
  const { identifier } = ls('user') || {};
  const recaptchaRef = useRef();
  const recoveryForm = useRef();
  const submitted = useRef();

  useEffect(() => {
    if (submitted.current) {
      applyRecovery({ variables: { ...state } }).then(
        ({ data: { applyRecovery } = {} } = {}) => {
          if (!applyRecovery) {
            return;
          }
          if (base !== '/') {
            window.location.href = base;
            return null;
          }
          if (identifier) {
            createAuthentication({ variables: { identifier, password } });
            history.replace(PAGES.DASHBOARD);
            return null;
          }
          history.replace(base);
        }
      );
    }
  }, [state]);

  const handleSubmit = (event) => {
    event && event.preventDefault();
    if (submitted.current) {
      return;
    }

    submitted.current = true;
    const form = recoveryForm.current;

    if (form.reportValidity() === false) {
      scrollToInvalid(form);
      return;
    }

    if (!state.captcha) {
      recaptchaRef.current.execute();
    }
  };

  return (
    <main
      className="reset-password min-height-100vh--lg"
      style={{ backgroundImage: `url(${banner})` }}
    >
      <header className="position-absolute-top-0 z-index-2">
        <nav className="d-flex justify-content-between align-items-center">
          <Col className="px-0">
            <a
              className="d-inline-block px-3 py-5 p-sm-5"
              href={base}
              aria-label="Doyo"
            >
              <img
                className="d-inline-block"
                src={referrerLogo.color || logo}
                height="42px"
                alt="Logo"
              />
            </a>
          </Col>
        </nav>
      </header>
      <Container>
        <Row>
          <Col
            md={{ span: 8, offset: 2 }}
            lg={{ span: 7, offset: 2 }}
            xl={{ span: 6, offset: 3 }}
            className="space-3"
          >
            <div className="my-5">
              <h2 className="h3 text-primary font-weight-normal mb-0">
                {t('reset_password.title')}
              </h2>
              <p>{t('reset_password.subtitle')}</p>
            </div>
            <form
              className="js-validate mt-5"
              onSubmit={handleSubmit}
              ref={recoveryForm}
            >
              <Form.Group>
                <Form.Label
                  htmlFor="InputPassword"
                  className="h6 small d-block text-uppercase"
                >
                  {t('common.new_password')}
                </Form.Label>
                <PasswordInput
                  id="InputPassword"
                  onChange={(event) =>
                    setState((state) => ({
                      ...state,
                      password: event.target.value,
                    }))
                  }
                />
              </Form.Group>
              <Form.Group className="mb-5">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={SECRETS.GRE_CAPTCHA_SITE_KEY}
                  size="invisible"
                  onChange={(captcha) =>
                    setState((state) => ({ ...state, captcha }))
                  }
                />
              </Form.Group>
              <div className="text-end mb-5">
                <Button
                  color="primary"
                  className="position-relative text-uppercase font-weight-bold"
                  disabled={submitted.current}
                  data-cy="submit"
                  size="lg"
                  type="submit"
                >
                  {loading && <CirclePreloader />}
                  {t('forgot.reset_password')}
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default ResetPasswordPage;
