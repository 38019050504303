// @flow
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { i18n, languages } from '../i18n';

function getLanguageTextFromCode(code) {
  code = code.split('-')[0];
  return (languages.filter((lang) => lang.code === code)[0] || {}).text;
}

function handleLanguageChange(event) {
  const opt = event.currentTarget;
  const language = opt.dataset.value;

  i18n.changeLanguage(language);

  /* authentication.loggedIn &&
        dispatch(
            usersActions.update(user, {
                language,
            })
        ); */
}

function Language(props) {
  const {
    className,
    drop,
    language = i18n.language,
    menu,
    onChange,
    toggleClassName = '',
    toggleColor = 'link',
  } = props;

  const [currentLanguage, setCurrentLanguage] = useState({
    code: language,
    text: getLanguageTextFromCode(language),
  });

  i18n.on('languageChanged', (lng) =>
    setCurrentLanguage({
      code: lng,
      text: getLanguageTextFromCode(lng),
    })
  );

  return (
    <Dropdown
      drop={drop}
      className={`d-inline-block ${className}`.trim()}
      data-cy="language"
    >
      <Dropdown.Toggle variant={toggleColor} className={toggleClassName}>
        {currentLanguage.text}
      </Dropdown.Toggle>
      <Dropdown.Menu nav {...menu}>
        {languages.map((lang) => (
          <Dropdown.Item
            key={lang.code}
            active={currentLanguage.code === lang.code}
            data-value={lang.code}
            onClick={onChange || handleLanguageChange}
          >
            {lang.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Language;
export { Language };
