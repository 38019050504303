import React, { createContext, useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { Button, Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PAGES } from '../../_config';
import { RegisterModal, Sidebar, VerificationModal } from '../../components';
import { Info, NewProjectCreation, Settings, Style, Templates, Types } from '.';

export const NewProjectContext = createContext({});

let dashboard;
export const NewProjectContent = ({
  className = '',
  dashboard: dashboardProp = true,
  type: typeProp,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [step, setStep] = useState(typeProp ? 1 : 0);
  const [data, setData] = useState({});
  const { type } = data;

  if (step === 0) {
    dashboard = dashboardProp;
  }

  const handleBack = () => {
    if (dashboard && step - 1 === 0) {
      history.push(PAGES.DASHBOARD);
    }
    if (type === 'backend') {
      setStep(0);
      return;
    }
    setStep((step) => step - 1);
  };

  const handleSetData = (newData) => {
    setData((data) => ({ ...data, ...newData }));
  };

  console.log('Step:' + step);
  useEffect(() => {
    if (!type) {
      setData({});
    } else if (step === 0) {
      history.push(PAGES.NEW_PROJECT);
    }
  }, [step]);

  useEffect(() => {
    console.log(
      'use effect ' + step + ' . type:' + type + ' .  typeProp:' + typeProp
    );
    setStep(typeProp ? (typeProp === 'backend' ? 4 : 1) : 0);
    typeProp && setData((data) => ({ ...data, type: typeProp }));
  }, [typeProp]);

  const content = [
    <Types key="newProjectStep1" dashboard={dashboard} />,
    <Templates key="newProjectStep2" />,
    <Style key="newProjectStep3" />,
    <Settings key="newProjectStep4" />,
    <Info key="newProjectStep5" />,
    <NewProjectCreation key="newProjectCreation" />,
  ];

  return (
    <NewProjectContext.Provider
      value={{
        ...data,
        setData: handleSetData,
        setStep,
        step,
      }}
    >
      <div className={`new-project ${className}`.trim()}>
        {step > 0 && step < content.length && (
          <Button
            className="mt-3 ms-4"
            type="link"
            icon={<Icon path={mdiArrowLeft} size={0.8} />}
            onClick={handleBack}
          >
            {t('common.back')}
          </Button>
        )}
        {content[step]}
      </div>
    </NewProjectContext.Provider>
  );
};

export const NewProjectPage = ({ match: { params: { type } = {} } = {} }) => {
  return (
    <>
      <Layout>
        <Layout.Sider className="sidebar-main" width={100}>
          <Sidebar />
        </Layout.Sider>
        <Layout>
          <Layout.Content id="WorkingArea">
            <NewProjectContent dashboard={false} type={type} />
          </Layout.Content>
        </Layout>
      </Layout>
      <RegisterModal />
      <VerificationModal />
    </>
  );
};

export default NewProjectPage;
